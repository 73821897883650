import React from "react";
import './EligibilityCalculator.css'
import RangeSlider from '../PersonalLoan/RangeSlider';
import { useSelector, useDispatch } from 'react-redux';
import { setCalGen } from "../../Redux/action";
import DropDownIcon from '../../Icons/DropDownIcon.svg'
import DateCalender from '../DateCalender/DateCalender';
import calender from '../../Icons/calender.svg'
import circle from '../PersonalLoan/images/circle.webp'
import pipes from '../PersonalLoan/images/pipes.webp'

function EligibilityCalculator() {
    const dispatch = useDispatch();
    const { monthly_income, monthly_expense, max_payable_loan } = useSelector(state=>state);
    const [date, setDate] = React.useState("");
    const elig_foir = 60/100;
    const elig_roi = 20/1200;
    const elig_tenure = 3;
    const elig_nth_multiplier = 8;
    const timeLimit = {
        minTime: new Date(`01-01-${new Date().getFullYear() - 60}`),
        maxTime: new Date()
    }
    let cal_gen, calculation_gen, cal_gen_pre_final, cal_gen_final, cal_gen_prenth_final;
    React.useEffect(() => {
        cal_gen = 1 - Math.pow((1+elig_roi), -(elig_tenure*12));
        calculation_gen = (monthly_income*elig_foir)-monthly_expense;
        cal_gen_pre_final = Math.round(calculation_gen*(cal_gen/elig_roi));
        cal_gen_prenth_final = monthly_income*elig_nth_multiplier;
        cal_gen_final = Math.min(cal_gen_pre_final, cal_gen_prenth_final);
        dispatch( setCalGen(cal_gen_final) );
    }, [monthly_income, monthly_expense, max_payable_loan])

    const handleChange = () => {
        
    }
    return (

        <>
            <div className="eligib-head-main">
                <div className="elgib-heading">
                    <h3>MoneyWide Personal Loan<b> Eligibility Calculator</b></h3>
                    <p>Skip the manual calculations and use the MoneyWide Personal Loan Eligibility calculator<br/> the maximum amount you are eligible for.</p>
                </div>
            </div>
            <div className="eligibility-main">
                <div className="eligib-circle">
                    <img src={circle} alt={"NA"} />
                </div>
                <div className="eligibility-left">
                    <div className="eligb-head">
                        <span>Eligibility Calculator</span>
                    </div>
                    <div className={"date-box eligib-calender"}>
                        <img src={calender} alt={"NA"} />
                        <DateCalender startDate={date} setStartDate={setDate} minYear={new Date().getFullYear() - 60} maxYear={new Date().getFullYear() - 18}timeLimit={timeLimit} placeHolder={"Date of Birth"} />
                        <img className="small-the-icon" src={DropDownIcon} alt="NA" />
                    </div>
                    <div>
                        <div className="eligib-slide-div">
                            <RangeSlider heading="Net Monthly Income" symbol={"(₹)"} text1="₹ " text2="" text3="15000" text4="10 L" id="id4" uid="uid4" min="15000" max="1000000" step="10000" handleChange={(e) => handleChange(e)} />
                            <RangeSlider heading="EMI's & Other Expenses" symbol={"(₹)"} text1="₹ " text2="" text3="0" text4="10 L" id="id5" uid="uid5" min="0" max="1000000" step="1000" handleChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                </div>
                <div className="eligibility-right">
                    {date ? (max_payable_loan > 0 ? (<div className="eligib-result">
                        <h3>Your Result</h3>
                        <p>You are eligible for <br />maximum loan amount of upto</p>
                        <b>₹ {max_payable_loan?.toLocaleString('hi-IN')}</b>
                        <div className="eligib-apply">
                            <a href={"/personal-loan"}>Apply for a Loan</a>
                        </div>

                    </div>) :
                        (monthly_expense < monthly_income ? (<div className="eligib-result">
                            <h3>Your Result</h3>
                            <p>You are eligible for loan amount of upto <b>₹0</b></p>
                        </div>) :
                            (<div className="eligib-result">
                                <h3>Your Result</h3>
                                <p>Your monthly expense should not be <br /> greater than your monthly income</p>
                            </div>))) :
                        <div className="eligib-result">
                            <h3>Your Result</h3>
                            <span>Set Date of Birth First</span>
                        </div>}
                </div>
                <div className="eligib-pipe">
                    <img src={pipes} alt={"NA"} />
                </div>
            </div>
        </>

    )
}

export default EligibilityCalculator;