import React from 'react'
import PartnerForm from './PartnerForm'
import LeftSide from '../../Icons/LeftSide.svg';
const PartnerFormCall = () => {
  return (
    <div className='journey-part'>
    <div className="journey-content">
          <div className="left-side">
            <h2 className="journey-heading">Partner Form</h2>
            <p className="journey-con"><b>Moneywide is a digital lending app which provides you instant loans through a completely paperless process.</b></p>
            <div className="left-img">
              <img src={LeftSide} alt="" />
              </div>
          </div>
          <div className="right-side-partner-form">
            <PartnerForm/>
          </div>
        </div>
        </div>
  )
}

export default PartnerFormCall