import React, { useState } from 'react';
import Lottie from 'react-lottie-player';
import { useSelector } from 'react-redux';
import lottieJson from "../assests/lootie-files/successful.json";

const LinkEmailThankyou = () => {
   const {setService } = useSelector(
      (state) => state
    );
    const [continueNextSection, setcontinueNextSection] = useState(false);
  return (
    <>
     <div className={continueNextSection ? "loaderbackground remove-block" : "loaderbackground"}>
            <div className='loadertransparent'>
               <div className='thank-container'>
                  <div className='thank-lotie'>
                     <Lottie loop animationData={lottieJson} play />
                  </div>
                  <p className='thank-p'>{setService.thankMessageEmail}</p>
               </div>
            </div>
         </div>
    </>
  )
}

export default LinkEmailThankyou