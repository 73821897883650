import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { react_base_url } from '../Secret_Credentials/Secret_Credential';

function CustomDropdown() {
  const [selectedValue, setSelectedValue] = useState("Cancellation Policy");
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const app = searchParams.get("app");
  const navigate = useNavigate();

  const options = [
    { value: "Cancellation Policy", label: "Cancellation Policy",url: "cancellation-and-refund-policy"},
    { value: "Privacy Policy", label: "Privacy Policy",url: "privacy-policy", },
    { value: "Terms and Conditions", label: "Terms and Conditions",url: "terms-and-conditions", },
  ];

  const handleSelectOption = (option) => {
    setSelectedValue(option.value);
    setShowDropdown(false);
    if(app) {
      // window.location.href = `${react_base_url}${option.url}/?app=MQ==`
      navigate(`/${option.url}/?app=MQ==`)
    }
    else {
      // window.location.href = `${react_base_url}${option.url}`;
      navigate(`/${option.url}`)
    }
  };

  React.useEffect(() => {
    // const qry = window.location.href;
    const { pathname } = location;
    if (pathname === "/cancellation-and-refund-policy") {
      setSelectedValue('Cancellation Policy');
    } else if (pathname === "/privacy-policy") {
      setSelectedValue('Privacy Policy');
    } else if (pathname === "/terms-and-conditions") {
      setSelectedValue('Terms and Conditions');
    }
    if(app){
      if (pathname === "/cancellation-and-refund-policy/") {
        setSelectedValue('Cancellation Policy');
      } else if (pathname === "/privacy-policy/") {
        setSelectedValue('Privacy Policy');
      } else if (pathname === "/terms-and-conditions/") {
        setSelectedValue('Terms and Conditions');
      }
    }
  }, [location]);

  return (
    <div>
      {/* Dropdown display */}
      <div
        className={showDropdown ? "dropdown-display-selected":"dropdown-display"}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <div className="selected-value" style={{fontFamily:"poppinsmedium",color:"#015EF7"}}>{selectedValue}</div>
        <div className="toggle-button-arrow">{showDropdown ? <img style={{width:"28px"}} src={`${react_base_url}assets/images/DropDownArrow.png`}/>  : <img style={{width:"28px",transform:"rotate(180deg)"}} src={`${react_base_url}assets/images/DropDownArrow.png`}/>}</div>
      </div>
      {/* Dropdown menu */}
      {showDropdown && (
        <ul className="dropdown-menu">
          {options.filter((item,idx)=>{
            return item.value !== selectedValue
          }).map((option) => (
            <li key={option.value} onClick={() => handleSelectOption(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
export default CustomDropdown;