import React, { useEffect } from 'react';
import './TypesOfPersonalLoan.css';
import '../PersonalLoan/PersonalLoan.css'
import { Link } from 'react-router-dom';
// import FAQSection from '../FAQSection/FAQSection';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import MobileFooter from '../Footer/MobileFooter';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';

const TypesOfPersonalLoan = () => {
    var loan_type_id_page = 56;
    const [data, setData] = React.useState({ heading: "", data: [],sub_bread: "Types Of Personal Loan " })
    const dispatch = useDispatch()
    const { PLLoader, formStates, mwpages } = useSelector(state => state)
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY <= 200 || window.scrollY >= 1717) {
            sety(false);
        }
        else {
            sety(true);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])
    return (
        <>
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi/>
            <div className='journey-stop'>
                <NewLoginForm pageType={loan_type_id_page} mapData={data} />
                <div>
                    {y && <RightSidePopUp />}
                    <section className="ploan-content1 mob-margin-top web-margin-top">
                        <div className="ploan-container">
                            <div className="">
                                <h4 className="heading-h2">What are the Different <b>Types of Personal Loans?</b></h4>
                                <p className="heading-p">A MoneyWide personal loan is an unsecured loan that you can avail for multiple purposes including covering wedding expenses, a medical emergency, travel etc. You can get a personal loan upto ₹ 3,00,000 at affordable interest rates with a flexible loan repayment tenure of upto 36 months.<br />Here are different types of <Link className='send-lnk' to='/personal-loan'>Personal Loans</Link> available at MoneyWide:</p>
                            </div>
                        </div>
                    </section>
                    <section className="ploan-content1 mob-margin-top web-margin-top">
                        <div className="ploan-container">
                            <div className="">
                                <h3 className="heading-h2"><b>Education</b>Loan</h3>
                                <p className="heading-p">A personal loan for education helps you to get funds for educational expenses like high fees of the institutions, transportation, uniform, stationery, etc. To mitigate the financial burden, parents take education loans for their children for higher studies.</p>
                            </div>
                        </div>
                    </section>
                    <section className="ploan-content1 mob-margin-top web-margin-top">
                        <div className="ploan-container">
                            <div className="">
                                <h3 className="heading-h2"><b>Home renovation</b> loan</h3>
                                <p className="heading-p">Renovation of an existing property is an expensive affair and home renovation loans are used to take care of any expenses related to home repair, be it labor charges, cost of buying new material, etc. With MoneyWide home renovation loan, you can upgrade your current place into a modern structure and improve your lifestyle.</p>
                            </div>
                        </div>
                    </section>
                    <section className="ploan-content1 mob-margin-top web-margin-top">
                        <div className="ploan-container">
                            <div className="">
                                <h3 className="heading-h2"><b>Travel</b> Loan</h3>
                                <p className="heading-p">Travelling is one of the most refreshing and exciting activities for explorers. Some people travel to take rest from their mundane life routine, while some travel to learn about different places, their culture, tradition, food, etc. For that purpose, MoneyWide offers personal loans for travel that will help you get enough funds for your vacations. You can get an instant travel loan upto ₹ 3,00,000 at affordable interest rates, online with an easy and quick loan approval process.</p>
                            </div>
                        </div>
                    </section>
                    <section className="ploan-content1 mob-margin-top web-margin-top">
                        <div className="ploan-container">
                            <div className="">
                                <h3 className="heading-h2"><b>Wedding</b> Loan</h3>
                                <p className="heading-p">From decoration to reception, a wedding event involves a huge cost. Borrowers can apply for a personal loan for wedding for amounts ranging from ₹ 25,000 - ₹ 3,00,000 at affordable interest rates. Also, the loan comes with no hidden charges and can be availed online with a quick loan approval process that involves the submission of a scanned copy of your documents and a v-KYC.</p>
                            </div>
                        </div>
                    </section>
                    <section className="ploan-content1 mob-margin-top web-margin-top">
                        <div className="ploan-container">
                            <div className="eligib-top">
                                <h3 className="heading-h2"><b>Pension </b>loan</h3>
                                <p className="heading-p">These are the types of personal loans taken by retired personnel in case of financial emergencies. Pension loans have different eligibility criteria than any other personal loan. The amount of loan a person can avail of is based on the pension income of the borrower. Also, as per the eligibility criteria for a pension loan, a borrower must provide pension proof to get the loan approval.</p>
                            </div>
                        </div>
                    </section>
                    {/* <section className="FAQs">
                        <FAQSection />
                    </section> */}
                    {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                    <FAQSectionChk data={mwpages.faqdata} />
                </section> : ""}
                </div>
            </div>
            <div className='journey-start' style={{ display: "none" }}>
                {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                {(!formStates.IsIframe) && <MobileFooter />}
            </div>
        </>
    )
}

export default TypesOfPersonalLoan