import React from "react";
import Lottie from "react-lottie-player";
import lottieJson from "../../assests/lootie-files/successful.json";
import { useDispatch, useSelector } from "react-redux";
import './congoesign.css';
import { prodbase_url, source, verification_source, verified_by, verifier_name, react_base_url } from "../../Secret_Credentials/Secret_Credential";
import axios from "axios";
import { getToken } from "../../EverCalledFunctions/EverCalledApis";
import { GenerateToken } from "../../../Redux/action";
import CKYCWaiting from "../../LoginPopUp/CKYCWaiting";
import { apiInstance } from "../../Config/APIConfig";
const CongraulationEsign = ({ number }) => {
  const { credentialState, token } = useSelector(state => state)
  const [store, setStore] = React.useState();
  const [state, setState] = React.useState(true);
  const [msg, setMsg] = React.useState("");
  const [updatedMsg, setUpdatedMsg] = React.useState("");
  const dispatch = useDispatch();


  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, [])

  const thankyouScreen = async () => {
    let decThank = await apiInstance.post(`thankyou-screen-message`, {
      source,
      "template_id": number,
      "customer_id": credentialState.cust_id,
      verifier_name,
      verification_source,
      verified_by
    }, {
      "token": token,
      "log": 1
    })
    // .then((res) => {
      if ((decThank.status == 1) && (decThank.status_code == 200)) {
        setMsg(decThank.data.msg);
        setState(false)
        setStore(decThank.data.url);
      }
      else {
        window.location.href = `${react_base_url}`;
      }
    // })
  }

  React.useEffect(() => {
    if (token !== "") {
      thankyouScreen();
    }
    //  })
  }, [token])

  React.useEffect(() => {
    if (msg !== "") {
      let newMsg = msg;
      if (newMsg.includes("Thank You")) {
        newMsg = newMsg.split("Thank You!!");
        newMsg = newMsg[1];
        if (newMsg.includes("<<")) {
          newMsg = newMsg.replace("<<", "");
          newMsg = newMsg.replace(">>", "");
          setUpdatedMsg(newMsg);
        }
        else {
          setUpdatedMsg(newMsg);
        }

      }
    }
  }, [msg])


  return (
    <>
      {
        state ?
          <CKYCWaiting />
          :
          <div className="thank-cont">
            <div className="thank-popup-lottie">
              <Lottie
                loop
                animationData={lottieJson}
                play
              />
            </div>
            {((number == "7") || (number == "12")) && <p className="congo-p">Thank you</p>}
            {((number !== "7") && (number !== "12")) && <p className="congo-p">Congratulations</p>}
            {/* <p className="congo-text"> Our team will contact you via call, email or SMS for next steps. You can check the status of your application in <a href={store}>{store}</a>.</p> */}
            {(((number == "6") || (number == "15")) && !state) && (<p className="congo-text"> Our team will contact you via call, email or SMS for next steps. You can check the status of your application in <a href={store}>myaccount</a>.</p>)}
            {((number == "7") && !state && (updatedMsg !== "")) && (<p className="congo-text">{updatedMsg}</p>)}
            {((number == "12") && !state && (updatedMsg !== "")) && (<p className="congo-text">{updatedMsg}</p>)}
          </div>
      }
    </>
  );
};

export default CongraulationEsign;
