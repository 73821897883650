import React, { useEffect, useState } from 'react'
import LoanDetails from './LoanDetails';
import back from '../../Icons/back.svg'
import RecentTransaction from './RecentTransaction';
import "./LoanDetails.css"
import { DownloadPDFFunc, GetRepaymentDetailsAPI, getLoginData, getTransactionsDownload } from './MyAccountAPIs';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../EverCalledFunctions/EverCalledApis';
import { GenerateToken } from '../../Redux/action';
import { useNavigate } from 'react-router-dom';
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';
import { HandleDownloadButton } from './AccountStatement';

const TransactionHistory = () => {
  const [data, setData] = useState({})
  const [loader, setLoader] = useState(true)
  const [APIErr, setAPIErr] = useState(false)
  const [download, setDownload] = useState(1)
  const [mw, setMw] = useState(1)
  const { token } = useSelector(state => state)
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const getUserData = async (mw_app_id) => {
    const APIResponse = await GetRepaymentDetailsAPI(mw_app_id, token);
    if (APIResponse.status_code === "200" && APIResponse.status === "1") {
      setData(APIResponse.data)
      setLoader(false)
    } else {
      setLoader(false)
      setAPIErr(true)
    }
  }


  useEffect(() => {
    if (token !== "") {
      let payLoad = getLoginData()
      if (!payLoad && !payLoad.mw_app_id) {
        navigate('/login')
      } else if (payLoad.mw_app_id !== undefined && payLoad.mw_app_id !== "") {
        setMw(payLoad.mw_app_id)
        getUserData(payLoad.mw_app_id);
      }
    }
  }, [token])


  const myToken = async () => {
    const T = await getToken();
    dispatch(GenerateToken(T))
  }

  useEffect(() => {
    if (token === "") {
      myToken()
    }
  }, [])

  const manageRefresh = () => {
    setLoader(true)
    setAPIErr(false)
    let payLoad = getLoginData()
    if (!payLoad && !payLoad.mw_app_id) {
      navigate('/login')
    } else if (payLoad.mw_app_id != undefined || payLoad.mw_app_id !== "") {
      getUserData(payLoad.mw_app_id)
      setMw(payLoad.mw_app_id)
    }
  }

  const RemoveSessionData = () => {
  }

  const handleDownload = async () => {
    setDownload(2)
    const res = await getTransactionsDownload(mw, token)
    if (res.status_code == "200" && res.status == "1") {
      if (res?.data?.Pdf_data) {
        DownloadPDFFunc(res?.data?.Pdf_data, "Recent Transactions")
        setDownload(3)
      } else {
        setDownload(1)
      }
    } else {
      APIErr(true)
      setDownload(1)
    }
  }
  return (
    <>
      {APIErr && !loader && <NewErrorCase handleClick={manageRefresh} />}
      {loader && <CKYCWaiting />}
      {!loader && !APIErr && <div className='transaction-main'>
        <div className='trasaction-head'>
          <a href="/myaccount/myloan" onClick={() => RemoveSessionData()}><img className="" src={back} alt="back-img" /></a>
          <span>Transaction History</span>
        </div>
        <LoanDetails data={data} />
        <div className='as-head'>
          <span className='loan-details-heading'>Recent Transactions</span>
          <div className='has-download-btn'>
            {data.transactions.length > 0 && <HandleDownloadButton download={download} handleDownload={handleDownload} text={"Export Transaction History"} />}
          </div>
        </div>
        {data.transactions && data.transactions.map((row, ind) => {
          return (!row.type.accrual) && (row.type.disbursement || row.type.repayment || row.type.repaymentAtDisbursement) &&
            <RecentTransaction key={ind} rowData={row} text={row?.type?.repaymentAtDisbursement ? "Processing fees and other charges" : (row?.type?.disbursement ? "Disbursement" : "Repayment")}/>
        })}
      </div>}
    </>
  )
}

export default TransactionHistory;