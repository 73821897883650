import React, { useState } from 'react'
import { source, prodbase_url, verification_source, verified_by, verifier_name, react_base_url } from "../../Secret_Credentials/Secret_Credential";
import { useSelector, useDispatch } from "react-redux";
import { GenerateToken } from "../../../Redux/action";
import Base64 from "base-64";
import { useEffect } from 'react';
import { Check_utm_source, encryptLeadIdCustIdLevelId, gettingerror } from "../../EverCalledFunctions/EverCalledFunctions";
import { GetFinbitToken, getToken } from '../../EverCalledFunctions/EverCalledApis';
import CKYCWaiting from '../../LoginPopUp/CKYCWaiting';
import NewErrorCase from '../Form/All_Forms/NewErrorCase';
import { AxiosThirty } from '../../AxiosConfiguration/AxiosConfig';
import { FetchCustomerPayload } from '../../EverCalledFunctions/EverCalledPayload';
import { apiInstance } from '../../Config/APIConfig';

const FinbitScreen = () => {
    const [qryStr, setQryStr] = React.useState("");
    const [tkn, setTkn] = React.useState("");
    const [state, setState] = React.useState(false);
    const [rocket, setRocket] = useState(false);
    const dispatch = useDispatch();
    const { mw_application_id, credentialState, token } = useSelector(state => state);
    const encData = React.useRef(encryptLeadIdCustIdLevelId(credentialState.lead_id, credentialState.cust_id, 1));

    const hitOfferApi = async (id) => {
        let decOffer = await apiInstance
            .post(
                `offer-eligibility`,
                {
                    "customer_id": credentialState.cust_id,
                    "mw_application_id": mw_application_id,
                    "offer_type": 1,
                    "verifier_name": verifier_name,
                    "verification_source": verification_source,
                    "verified_by": verified_by,
                    "product_id": 56,
                    "stage_name": "Bank Statement Analyser",
                    "program_id": id,
                    "level_id": 1,
                    "source": source,
                },
                {
                    "token": ""
                }
            )
        // .then((res) => {
        if (decOffer.status_code) {
            window.location.href = `${react_base_url}analyzer/thank-you`;
        }

        // }).catch((err) => {
        //     setRocket(true);
        // });
    }

    const hitFetchCustomer = async () => {
        let decFetch = await apiInstance
            .post(
                `fetch-customer-details`,
                FetchCustomerPayload(mw_application_id, 5),
                {
                    token
                }
            )
            // .then((res) => {
                if ((decFetch.status == 1) && (decFetch.status_code == 200)) {
                    let id = "";
                    if (decFetch?.data?.selected_program_id.toString()) {
                        id = decFetch.data.selected_program_id;
                    }
                    window.sessionStorage.setItem("selected_program_id", id);
                    if ((decFetch.data.new_journey_lead_source_flag == 1) || (decFetch.data.new_journey_customer_id_flag == 1)) {
                        window.location.href = `${react_base_url}analyzer/thank-you`;
                    }
                    else {
                        hitOfferApi(id);
                    }
                }
                else {
                    let qryStr = Base64.encode(mw_application_id);
                    qryStr = Base64.encode(qryStr);
                    window.location.href = `${react_base_url}analyzer/?fid=${qryStr}&app=MQ==`;
                }
            // }).catch((err) => {
            //     setRocket(true);
            // });
    }

    if (window.addEventListener) {
        window.addEventListener('message', (event) => {
            const myJSON = JSON.stringify(event.data);
            localStorage.setItem("testJSON", myJSON);

            let text = localStorage.getItem("testJSON");
            let obj = JSON.parse(text);
            if (obj.status == "SUCCESS") {
                let cur_url = window.location.href;
                if (cur_url.includes("app")) {
                    if (window.sessionStorage.getItem("count") == 1) {
                        window.sessionStorage.setItem("count", 2);
                        if ((window.sessionStorage.getItem("selected_program_id")) && (window.sessionStorage.getItem("selected_program_id") !== undefined) && (window.sessionStorage.getItem("selected_program_id") !== null)) {
                            let id = ""
                            id = window.sessionStorage.getItem("selected_program_id");
                            if ((credentialState.step_5.new_journey_lead_source_flag == 1) || (credentialState.step_5.new_journey_customer_id_flag == 1)) {
                                window.location.href = `${react_base_url}analyzer/thank-you`;
                            }
                            else {
                                hitOfferApi(id);
                            }

                        }
                        else {
                            hitFetchCustomer();
                        }
                    }
                }
                else {
                    gettingerror('bsa redirect web', mw_application_id)
                    let checkCondition = Check_utm_source()
                    if (checkCondition && checkCondition.length != 0) {
                        window.location.href = `${react_base_url}analyzer/redirect/?fid=${qryStr}${checkCondition}`;
                    }
                    else {
                        window.location.href = `${react_base_url}analyzer/redirect/?fid=${qryStr}`;
                    }
                }
            }
        });
    } else {
        window.onmessage = (event) => {
            const myJSON = JSON.stringify(event.data);
            localStorage.setItem("testJSON", myJSON);

            let text = localStorage.getItem("testJSON");
            let obj = JSON.parse(text);
            if (obj.status == "SUCCESS") {

                let cur_url = window.location.href;
                if (cur_url.includes("app")) {
                    if (window.sessionStorage.getItem("count") == 1) {
                        window.sessionStorage.setItem("count", 2);
                        if ((window.sessionStorage.getItem("selected_program_id")) && (window.sessionStorage.getItem("selected_program_id") !== undefined) && (window.sessionStorage.getItem("selected_program_id") !== null)) {
                            const id = window.sessionStorage.getItem("selected_program_id");

                            if ((credentialState.step_5.new_journey_lead_source_flag == 1) || (credentialState.step_5.new_journey_customer_id_flag == 1)) {
                                window.location.href = `${react_base_url}analyzer/thank-you`;
                            }
                            else {
                                hitOfferApi(id);
                            }
                        }
                        else {
                            hitFetchCustomer();
                        }
                    }
                }
                else {
                    gettingerror('bsa redirect web', mw_application_id)
                    let checkCondition = Check_utm_source()
                    if (checkCondition && checkCondition.length != 0) {
                        window.location.href = `${react_base_url}analyzer/redirect/?fid=${qryStr}${checkCondition}`;
                    }
                    else {
                        window.location.href = `${react_base_url}analyzer/redirect/?fid=${qryStr}`;
                    }
                }

            }
        };
    }

    const encryptingRedirection = () => {
        let query_id = Base64.encode(mw_application_id);
        query_id = Base64.encode(query_id);
        let cust_id = Base64.encode(credentialState.cust_id);
        cust_id = Base64.encode(cust_id);
        let qry = Base64.encode(`query_id=${query_id}&cust_id=${cust_id}`);
        qry = Base64.encode(qry);
        setQryStr(qry);
    }

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }

    React.useEffect(() => {
        if (token == "") {
            myToken();
        }
        encryptingRedirection()
    }, [])

    const finbit = async () => {
        const payLoad = {
            "customer_id": credentialState.cust_id,
            "partner_id": 3,
            "level_id": 1,
            "lead_id": mw_application_id,
        }

        const APIDATA = await GetFinbitToken(payLoad, "")
        if (APIDATA.status === "1" && APIDATA.status_code === "200") {
            setTkn(APIDATA.data.access_token);
            setState(true);
            gettingerror('finbit token web', mw_application_id)
        }
    }
    useEffect(() => {
        if ((token !== "")) {
            finbit();
        }

    }, [token])

    const manageHeight = () => {
        const frame = document.getElementById('aa-client-frame');

        const postData = {
            status: "IFRAME_CONTENT_DIMENSIONS",
            location: window.location.href
        }
        frame.contentWindow.postMessage(postData, "https://unified.finbit.io");

        window.addEventListener('message', function (event) {
            if (event.data && event.data.DIMENSIONS) {
                setTimeout(function () {
                    frame.style.height = event.data.DIMENSIONS.height + 300 + 'px';
                }, 500);
            }
        })
    }

    const handleError = (e) => {
        window.location.reload();
    }

    return (
        <>
            {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
            {state ? <div >
                <iframe
                    onLoad={() => manageHeight()}
                    src={`https://unified.finbit.io/web/?accessToken=${tkn}&callbackurl=https://api.mlc-finvest.com/finbit/callback_${encData.current}&locationurl=${react_base_url}&mobileNumber=${credentialState.step_5.custPhoneNo}`}
                    style={{ height: "500px" }} id="aa-client-frame" frameBorder="0" ></iframe>

            </div> : <CKYCWaiting heading={"We are initiating bank account check to validate your salary credits."} />}
        </>
    )
}

export default FinbitScreen