
import { PRINCIPAL_AMOUNT,ORIGINAL_OUT_TENURE_LT, LOAN_TENURE, RATE_OF_INTEREST, EMI, SET_HEADER_NAVIGATION, MENU_BAR, APPLYFORJOB, SET_MONTHLY_INCOME, SET_MONTHLY_EXPENSE, SET_CAL_GEN, SHOWFORM, UPDATE_STEP_BAR, PERSONAL_LOAN_EVENT, FORM_JOURNEY_ACTION, LOGIN_DETAILS, SORRY_POPUP, UPDATE_POP_UP, SET_PREAPPROVED_OFFER, CKYC_UPDATE, FORM_STATES, CREDENTIALS_STATE, SELECTED_SERVICE_REQUEST_QUERY, UPDATE_MASTER_STATE, CHECK_MODIFY,OFFICE_EMAIL, SET_PREAPPROVED_OFFER_DETAILS, SET_MW_APPLICATION_ID, SET_CHOOSEN_AMOUNT, SET_RESIDENTIAL_ADDRESS,SERVICE_SHOW, SERVICE_LOGIN, SERVICE_CREDENTIAL, RESUME_JOURNEY,KFS_DATA, APP_STATUS,PL_LOADER,SUGGESTION_DROPDOWN, DECISION_DEMOG, GENERATE_TOKEN, TEMPLET_ID, BLOG_DATA,OFFICE_PENNY_DROP,PRODUCT_ID,PART_PAYMENT_TENURE, Part_PAYMENT_CHECKBOX,EMI_PAID,PART_PAYMENT_AMT, MW_PAGES,PARTNER_STATES,PARTNER_DATA,MAKE_PAYMENT, MYACCHEADERDATA,SUGGESTION_DROPDOWN2,SUGGESTION_DROPDOWN3,PARTNER_DOC_POPUP } from "./actionType";

const initState = {
    pa: 10000,
    lt: 50,
    roi: 32,
    emi: 364,
    header: {
        home: true,
        pl: true,
        sr: true,
        cu: true
    },
    modify: 0,
    menubar: true,
    jobs: false,
    monthly_income: 15000,
    monthly_expense: 0,
    max_payable_loan: 120000,
    showform: false,
    step_bar: {
        profile: true,
        offer: false,
        verification: false,
        disbursal: false,
        final: false
    },
    personal_loan: {
        profile: true,
        otp: false,
        changemobile: true,
        changeemail: true,
        servicemobile:true
    },
    loginDetails: {
        mobile: "",
        whatsapp: "",
        name: "",
        pan: "",
        email: "",
        dob: "",
        gender: "",
        isPan: false,
        pin: "",
        firstName: "",
        lastName: "",
        equifax_phone_no: []
    },

    ckycDetails: {
        isCkyc: false,
        person_name: "",
        person_fathername: "",
        person_mothername: "",
        person_gender: "",
        person_dob: "",
        person_address: "",
        cust_eligible: ""
    },

    sorry_popup: false,



    PopUpState: {
        OVDPopUp: {
            show: false,
            value: ""
        },
        DOBPopUp: {
            isBubbling: false,
            show: false,
            day: new Date().getDate(),
            year: new Date().getFullYear() - 21,
            month: new Date().getMonth() + 1,
            date: "",
            firstClick: false
        },
        LoanPurposePopUp: {
            show: false,
            value: ""
        },
        MaritalStatusPopUp: {
            show: false,
            value: ""
        },
        HigherQulificationPopUp: {
            show: false,
            value: ""
        },
        WorkExperiencePopUp: {
            show: false,
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            firstClick: false,
            isBubbling: false
        },
        TotalWorkExperiencePopUp: {
            show: false,
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            firstClick: false,
            isBubbling: false
        }
        ,
        EmpolyeTypePopUp: {
            show: false,
            value: ""
        },
        InvalidDocument:{
            show:false
        }
    },
    pre_approved_offer: false,

    formStates: {
        StartJourney:false,
        YourProfile: false,
        EmployeeDetailsTwoTag: false,
        EmployeeDetails: false,
        LoanDetails: false,
        AipOffer: false,
        KycVerification: false,
        ResidentialAddress: false,
        OfficeAddress: false,
        AdditionalDetails:false,
        FinbitScreen: false,
        DOB: false,
        CurrentAddress: false,
        ResidentAddress: false, 
        MailingAddress: false,
        ReferenceDetails: false,
        Emandate: false,
        IsIframe: false,
        Vcip: false,
        FinalOffer: false,
        Kyc: false,
        Email:false,
        LoanAgreement: false,
        KFS: false,
        Terms: false,
        ChangeEmail: false,
        showThank:false,
        BankDetails:false,
        Esign:false,
        congo:false,
        HunterHold: false,
        error:false,
        BSASuccessful: false,
        dashBoard:false,
        numberVerification: false,
        OfferNew:false,
        Offerselection:false,
        offernot:false,
        numberVerificationotp:false,
        UpiJourneyOTP:false
    },
    partnerStates:{
        PartnerLogin:true,
        PartnerOtp:false,
        PartnerProfile:false,
        PartnerPan:false,
        PartnerEmployer:false,
        PartnerOffer:false,
        PartnerWrong:false,
        AccountLogin:false,
        PartnerSelection:false,
        Show:false,
        Sorry:false,
        PartnerUtm:false,
        numberVerification: false,
        Partnerverification:false
    },
    partnerData:{

    },
    credentialState: {
        otpId: "",
        cust_id: "",
        mw_application_id: "",
        message: "",
        address_as_ckyc: 1
    },

    selectedServiceQuery: {
        show: false,
        value: ""
    },
    master_state: {
        values: [],
        status: false,
        heading: "",
    },
    OfficeEmailDetails: {
        show: false,
        value: ""
    },
    preApprovedOfferDetails: {
        final_data: [],
        state: false
    },
    mw_application_id: "",
    choosen_amount: {
        state: false
    },
    residentialAddress: {
        address_line1: "",
        address_line2: "",
        landmark: "",
        cityname: "",
        pincode: ""
    },
    setService: {
        serviceLogin: true,
        serviceWrong:false,
        serviceOtp: false,
        complain: false,
        selected: false,
        newService: false,
        thanks: false,
        showchatbot:false,
        open:[false,false,false,false],
        name:""
    },
    serviceNumber: {
        mobile: ""
    },
    serviceCred: {
        Otp_id: "",
        customer_id: ""
    },
    resumeJourney: {
        productId: ""
    },
    KFSData:{

    },
    App_status: false,
    PLLoader:{
        show:false
    },
    suggestionDropDown:{
        show:false,
        values:[]
    },
    suggestionDropDown2:{
        showc:false,
        values:[]
    },
    suggestionDropDown3:{
        showp:false,
        values:[]
    },
    decisionDemog: {
        is_ntc: "",
        empverification: "",
        pan_status: "",
        is_junk: ""
    },
    token: "6bf4b0fe-d412-4b42-8d2e-625c2ec20bc5", //production token
    // token:"",
    templet_id: "",
    blogData: [],
    office_action_penny:{},
    Product_Id:"",
    emiPaid:"",
    PartPayAmt: "",
    outstandingLoanAmt: "",
    PartPayTenure: "",
    partpaymentcheckbox: 1,
    originalOutsTenlt:"",
    mwpages:{
        bannerdata: [],
        faqdata: [],
        metatagsdata:{},
        animationdata:{},
        subBread:{}
    },
    makePayment: {
        show: false
    },
    myaccheaderdata:{data:[]},
    partnerdocupdate: {
        status: false,
        data: [],
        val: ""
    }


}


const reducer = (state = initState, { type, payLoad }) => {
    switch (type) {
        case ORIGINAL_OUT_TENURE_LT: {
            return {
                ...state,
                originalOutsTenlt: +payLoad
            }
        }
        case PARTNER_DOC_POPUP: {
            return {
                ...state,
                partnerdocupdate: payLoad
            }
        }
        case MYACCHEADERDATA:{
            return{
                ...state,
                myaccheaderdata: payLoad
            }
        }
        case PART_PAYMENT_TENURE: {
            return {
                ...state,
                PartPayTenure: +payLoad
            }
        }
        case Part_PAYMENT_CHECKBOX: {
            return {
                ...state,
                partpaymentcheckbox: +payLoad
            }
        }
        case EMI_PAID: {
            return {
                ...state,
                emiPaid: +payLoad
            }
        }
        case PART_PAYMENT_AMT: {
            return {
                ...state,
                PartPayAmt: +payLoad
            }
        }
        case PRINCIPAL_AMOUNT: {
            return {
                ...state,
                pa: +payLoad
            }
        }
        case LOAN_TENURE: {
            return {
                ...state,
                lt: +payLoad
            }
        }
        case RATE_OF_INTEREST: {
            return {
                ...state,
                roi: +payLoad
            }
        }
        case EMI: {
            return {
                ...state,
                emi: +payLoad
            }
        }
        case SET_HEADER_NAVIGATION: {
            return {
                ...state,
                header: payLoad
            }
        }
        case MENU_BAR: {
            return {
                ...state,
                menubar: !state.menubar
            }
        }
        case APPLYFORJOB: {
            return {
                ...state,
                jobs: payLoad
            }
        }
        case SET_MONTHLY_INCOME: {
            return {
                ...state,
                monthly_income: +payLoad
            }
        }
        case SET_MONTHLY_EXPENSE: {
            return {
                ...state,
                monthly_expense: +payLoad
            }
        }
        case SET_CAL_GEN: {
            return {
                ...state,
                max_payable_loan: +payLoad

            }
        }
        case SHOWFORM: {
            return {
                ...state,
                showform: payLoad
            }
        }
        case UPDATE_STEP_BAR: {
            return {
                ...state,
                step_bar: payLoad
            }
        }
        case PERSONAL_LOAN_EVENT: {
            return {
                ...state,
                personal_loan: payLoad
            }
        }
        case FORM_JOURNEY_ACTION: {
            return {
                ...state,
                form_journey: payLoad
            }
        }
        case LOGIN_DETAILS: {
            return {
                ...state,
                loginDetails: payLoad
            }
        }
        case CKYC_UPDATE: {
            return {
                ...state,
                ckycDetails: payLoad
            }
        }
        case SORRY_POPUP: {
            return {
                ...state,
                sorry_popup: payLoad
            }
        }
        case SUGGESTION_DROPDOWN2:{
            return {
                ...state,
                suggestionDropDown2:payLoad
            }
        }
        case SUGGESTION_DROPDOWN3:{
            return {
                ...state,
                suggestionDropDown3:payLoad
            }
        }
        case UPDATE_POP_UP: {
            return {
                ...state,
                PopUpState: payLoad
            }
        }
        case SET_PREAPPROVED_OFFER: {
            return {
                ...state,
                pre_approved_offer: !state.pre_approved_offer
            }
        }
        case FORM_STATES: {
            return {
                ...state,
                formStates: payLoad
            }
        }
        case PARTNER_STATES: {
            return {
                ...state,
                partnerStates: payLoad
            }
        }
        case PARTNER_DATA: {
            return {
                ...state,
                partnerData: payLoad
            }
        }
        case CREDENTIALS_STATE: {
            return {
                ...state,
                credentialState: payLoad
            }
        }
        case SELECTED_SERVICE_REQUEST_QUERY: {
            return {
                ...state,
                selectedServiceQuery: payLoad
            }
        }
        case UPDATE_MASTER_STATE: {
            return {
                ...state,
                master_state: payLoad
            }
        }
        case CHECK_MODIFY: {
            return {
                ...state,
                modify: state.modify + 1
            }
        }
        case OFFICE_EMAIL: {
            return {
                ...state,
                OfficeEmailDetails: payLoad
            }
        }
        case SET_PREAPPROVED_OFFER_DETAILS: {
            return {
                ...state,
                preApprovedOfferDetails: payLoad
            }
        }
        case SET_MW_APPLICATION_ID: {
            return {
                ...state,
                mw_application_id: payLoad
            }
        }
        case SET_CHOOSEN_AMOUNT: {
            return {
                ...state,
                choosen_amount: payLoad
            }
        }
        case SET_RESIDENTIAL_ADDRESS: {
            return {
                ...state,
                residentialAddress: payLoad
            }
        }
        case SERVICE_SHOW: {
            return {
                ...state,
                setService: payLoad
            }
        }
        case SERVICE_LOGIN: {
            return {
                ...state,
                serviceNumber: payLoad
            }
        }
        case SERVICE_CREDENTIAL: {
            return {
                ...state,
                serviceCred: payLoad
            }
        }
        case RESUME_JOURNEY: {
            return {
                ...state,
                resumeJourney: payLoad
            }
        }
        case KFS_DATA:{
            return {
                ...state,
                KFSData:payLoad
            }
        }
        case APP_STATUS: {
            return {
                ...state,
                App_status: payLoad
            }
        }
        case PL_LOADER:{
            return {
                ...state,
                PLLoader:payLoad
            }
        }
        case SUGGESTION_DROPDOWN:{
            return {
                ...state,
                suggestionDropDown:payLoad
            }
        }
        case DECISION_DEMOG: {
            return {
                ...state,
                decisionDemog: payLoad
            }
        }
        case GENERATE_TOKEN: {
            return {
                ...state,
                token: payLoad
            }
        }
        case TEMPLET_ID: {
            return {
                ...state,
                templet_id: payLoad
            }
        }
        case BLOG_DATA: {
            return {
                ...state,
                blogData: payLoad
            }
        }
        case OFFICE_PENNY_DROP:{
            return {
                ...state,
                office_action_penny: payLoad
            }
        }
        case PRODUCT_ID:{
            return {
                ...state,
                Product_Id:payLoad
            }
        }
        case MW_PAGES: {
            return {
                ...state,
                mwpages: payLoad
            }
        }
        case MAKE_PAYMENT: {
            return {
                ...state,
                makePayment: payLoad
            }
        }
        default:
            return state;
    }
}

export { reducer };