import React, { useEffect, useState } from "react";
import "../Popups/PANCARDPopUP/Pancardpopup.css";
import Lottie from "react-lottie-player";
import animationData from "../assests/lootie-files/pan-confirm";
import "../Loader/Loader.css";
import { useDispatch, useSelector } from "react-redux";
import {
  checkModify,
  GenerateToken,
  updateCkycDetails,
  updateDecisionDemog,
  updateFormState,
  updateLoginDetails,
  updatePartnerState,
} from "../../Redux/action";
import DropDownIcon from "../../Icons/DropDownIcon.svg";
import {
  base_url,
  source,
  client_id,
  client_key,
  prodbase_url,
  verification_source,
  verified_by,
  verifier_name,
} from "../Secret_Credentials/Secret_Credential";
import axios from "axios";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { AxiosThirty } from "../AxiosConfiguration/AxiosConfig";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
import { DecisionDemogOffer } from "../EverCalledFunctions/EverCalledPayload";
import { gettingerror } from "../EverCalledFunctions/EverCalledFunctions";
import DateCalender from "../DateCalender/DateCalender";
import { formatDate } from "../MyAccount/MyAccountAPIs";

function PartnerPanCard() {
  const {
    loginDetails,
    modify,
    formStates,
    credentialState,
    mw_application_id,
    decisionDemog,
    token,
    partnerStates,
    ckycDetails
  } = useSelector((state) => state);
  const [startDate, setStartDate] = useState(null)
  const timeLimit = {
    minTime: new Date(`${new Date().getFullYear() - 60}`),
    maxTime: new Date(`${new Date().getFullYear() - 21}`)
  }
  const [panData, setPanData] = useState({
    pancard: loginDetails.pan,
    name: loginDetails.name,
    dob: loginDetails.dob,
    gender: loginDetails.gender ? loginDetails.gender : "",
  });
  const [genderOptions, setGenderOptions] = useState({
    Val: [],
    show: false,
    values: {
      value: "",
      id: "",
    },
  });
  const [rocket, setRocket] = useState(false);

  const payLoad = {
    client_id,
    client_key,
    source,
  };

  const dispatch = useDispatch();
  const [continueNextSection, setcontinueNextSection] = useState(false);

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, [])

  useEffect(() => {
    if (panData.gender === "") {
      setTimeout(() => {
        axios
          .post(
            `${prodbase_url}master`,
            {
              source,
              api_type: "gender",
              verification_source: verification_source,
              verified_by: verified_by,
              verifier_name: source,
            },
            {
              headers: {
                token
              },
            }
          )
          .then((res) => {
            setGenderOptions({
              ...genderOptions,
              Val: res.data.data,
              show: false,
            });
            // console.log(res.data.data);
          });
      }, 500)
      // });
    }
  }, [panData]);

  const handleClick = () => {
    let cur_year = new Date().getFullYear();
    let user_dob = formatDate(loginDetails.dob ? loginDetails.dob : startDate)
    let DOB = new Date(user_dob)
    let user_age = cur_year - DOB.getFullYear();
    // console.log(user_age);
    if (user_age >= 21 && user_age <= 60) {
      const updatedGender = loginDetails.gender
        ? loginDetails.gender
        : genderOptions.values.value;
      AxiosThirty.post(`${prodbase_url}confirm-pan-submit`, {
        "mw_application_id": mw_application_id,
        "customer_id": credentialState.cust_id,
        "verified_by": verified_by,
        "verification_source": verification_source,
        "verifier_name": verifier_name,
        "source": source,
        "dob": loginDetails.dob,
        "gender": updatedGender === "Male"
          ? 1
          : (updatedGender === "Female" ? 2 : 3),
      }, {
        headers: {
          "token": token
        }
      }).then((res) => {
        gettingerror('confirm pan web', mw_application_id)
        setcontinueNextSection(true);
        // dispatch(updateLoginDetails({ ...loginDetails, isPan: false }));
        dispatch(updatePartnerState({...partnerStates,PartnerPan:false,PartnerEmployer:true,PartnerProfile:false}))

        if (res?.data?.data?.income_as_per_rule === "pass") {
          // console.log(loginDetails);
          const updatedGender = loginDetails.gender
            ? loginDetails.gender
            : genderOptions.values.value;
          axios
            .post(
              `${prodbase_url}decision-demog-api`,
              {
                "customer_id": credentialState.cust_id,
                "product_id": 56,
                "gender":
                  updatedGender === "Male"
                    ? 1
                    : updatedGender === "Female"
                      ? 2
                      : 3,
                "username": loginDetails.name,
                "dob": loginDetails.dob,
                "mw_application_id": mw_application_id,
                "mobile_no": loginDetails.equifax_no ? loginDetails.equifax_no : loginDetails.mobile,
                "pincode": loginDetails.pin.toString(),
                "city_id": decisionDemog.city_id ? decisionDemog.city_id : "",
                "pancard": loginDetails.pan,
                "email": loginDetails.email,
                "source": source,
                "verification_source": verification_source,
                "verified_by": verified_by,
                "verifier_name": verifier_name,
              },
              {
                headers: {
                  "token": token
                },
              }
            )
            .then((res) => {
              // console.log(res);
              if ((res.data.status == 1) && (res.data.status_code == 200)) {
                if (res.data.data.Response.BUREAU.is_phone_update_required == 1) {
                  if (res.data.data.Response.BUREAU.hint.includes("|")) {
                    let pipe = res.data.data.Response.BUREAU.hint.split("|");
                    dispatch(updateLoginDetails({ ...loginDetails, equifax_phone_no: pipe }));
                    dispatch(updatePartnerState({ ...partnerStates, PartnerEmployer: false, numberVerification: true,PartnerProfile:false,PartnerPan:false }));
                  }
                  else {
                    let pipe = [];
                    pipe.push(res.data.data.Response.BUREAU.hint)
                    dispatch(updateLoginDetails({ ...loginDetails, equifax_phone_no: pipe }));
                    dispatch(updatePartnerState({ ...partnerStates, PartnerEmployer: false, numberVerification: true,PartnerProfile:false,PartnerPan:false }));
                  }
                }
                else if (res.data.data && res.data.data.Response && res.data.data.Response.BUREAU && (res.data.data.Response.BUREAU.is_phone_update_required != 2)) {
                  gettingerror('hit demog web',mw_application_id)
                  if(((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))){
                    dispatch(updateDecisionDemog({ ...decisionDemog, is_ntc: res.data.data.Response.BUREAU.is_ntc_revised, empverification: res.data.data.Response.empverification, pan_status: res.data.data.Response.pan_status }));
                  }
                  else{
                    dispatch(updateDecisionDemog({ ...decisionDemog, is_ntc: res.data.data.Response.BUREAU.is_ntc_revised, empverification: res.data.data.Response.empverification, pan_status: res.data.data.Response.pan_status }));
                  }
                  if (((res.data.data.Response.BUREAU.is_ntc_revised == 1) && (res.data.data.Response.BUREAU.is_ntc == 1)) || ((res.data.data.Response.BUREAU.is_ntc_revised == 0) && (res.data.data.Response.BUREAU.is_ntc == 1))) {
                    setTimeout(() => {
                      if(((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))){
                       let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
                        axios.post(`${prodbase_url}${url}`,DecisionDemogOffer(mw_application_id,"CIBIL Check",credentialState.cust_id),{
                          headers: {
                              token
                          }
                       })
                        .then((res)=>{
                          if((res.data.status == 1) && (res.data.status_code == 200)){
                            dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible:res.data.data.cust_eligible }));
                            gettingerror('cibil check web',mw_application_id)
                          }
                        })
                      }
                      else{
                        axios.post(`${prodbase_url}offer-eligibility`,DecisionDemogOffer(mw_application_id,"CIBIL Check",credentialState.cust_id),{
                          headers: {
                              token
                          }
                        })
                        .then((res)=>{
                          if((res.data.status == 1) && (res.data.status_code == 200)){
                            dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible:res.data.data.cust_eligible }));
                          }
                        })
                      }
                    }, 10000)
                  }
                  else if ((res.data.data.Response.BUREAU.is_ntc_revised == 1) && (res.data.data.Response.BUREAU.is_ntc == 0)) {
                    setTimeout(() => {
                      if(((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))){
                       let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
                        axios.post(`${prodbase_url}${url}`,DecisionDemogOffer(mw_application_id,"CIBIL Check NTC",credentialState.cust_id),{
                          headers: {
                              token
                          }
                         })
                        .then((res)=>{
                          if((res.data.status == 1) && (res.data.status_code == 200)){
                            dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible:res.data.data.cust_eligible }));
                            gettingerror('cibil check NTC web',mw_application_id)
                          }
                        })
                      }
                      else{
                        axios.post(`${prodbase_url}offer-eligibility`,DecisionDemogOffer(mw_application_id,"CIBIL Check NTC",credentialState.cust_id),{
                          headers: {
                              token
                          }
                         })
                        .then((res)=>{
                          if((res.data.status == 1) && (res.data.status_code == 200)){
                            dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible:res.data.data.cust_eligible }));
                          }
                        })
                      }
                    }, 10000)
                  }
                  else if((res.data.data.Response.BUREAU.is_ntc_revised == 0) && (res.data.data.Response.BUREAU.is_ntc == 0)){
                    setTimeout(() => {
                      if(((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))){
                       let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
                        axios.post(`${prodbase_url}${url}`,DecisionDemogOffer(mw_application_id,"Soft Bureau Check",credentialState.cust_id),{
                          headers: {
                              token
                          }
                        })
                        .then((res)=>{
                          if((res.data.status == 1) && (res.data.status_code == 200)){
                            dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible:res.data.data.cust_eligible }));
                            gettingerror('soft bureau web',mw_application_id)
                          }
                        })
                      }
                      else{
                        axios.post(`${prodbase_url}offer-eligibility`,DecisionDemogOffer(mw_application_id,"Soft Bureau Check",credentialState.cust_id),{
                          headers: {
                              token
                          }
                        })
                        .then((res)=>{
                          if((res.data.status == 1) && (res.data.status_code == 200)){
                            dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible:res.data.data.cust_eligible }));
                          }
                        })
                      }
                    }, 10000)
                  }
                }

              }

              AxiosThirty
                .post(
                  `${prodbase_url}mobile-detection`,
                  {
                    "source": source,
                    "customer_id": credentialState.cust_id,
                    "product_id": 56,
                    "partner_id": 5,
                    "level_id": 1,
                    "mw_application_id": mw_application_id,
                    "verified_by": verified_by,
                    "verifier_name": verifier_name,
                    "verification_source": verification_source,
                    "mobile_no": loginDetails.mobile,
                  },
                  {
                    headers: {
                      "token": token
                    },
                  }
                )
                .then((res) => {
                  // console.log(res);
                });
            }).catch((err) => {
              setRocket(true);
            });

        }
      }).catch((err) => {
        setRocket(true);
      })
    } else {
      // console.log(user_age);
    }

    // setcontinueNextSection(true);
    // dispatch(updateLoginDetails({ ...loginDetails, isPan: false }));
    // dispatch(
    //   updateFormState({
    //     ...formStates,
    //     YourProfile: false,
    //     EmployeeDetails: true,
    //   })
    // );
  };
  const handleModify = () => {
    if (modify < 1) {
        // dispatch(updatePartnerState({ ...partnerStates, PartnerOtp: false, PartnerProfile: true }))
      dispatch(checkModify());
      setcontinueNextSection(true);
    }
    dispatch(updatePartnerState({...partnerStates,PartnerPan:false,PartnerProfile:true}))
  };

  const openGenderOption = () => {
    setGenderOptions({ ...genderOptions, show: true });
  };

  const ReturnThisValue = (row) => {
    setGenderOptions({ ...genderOptions, show: false, values: row });
  };

  const handleError = (e) => {
    // window.location.reload();
    setRocket(false);
    handleClick();
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      <div
        className={
          continueNextSection
            ? "loaderbackground remove-block"
            : "loaderbackground"
        }
      >
        <div className="loadertransparent">
          <div className="pancardpopup login-area-popup">
            <div className="pancardcontent">
              <h2>Please confirm your details as per PAN record</h2>
              <Lottie
                loop
                animationData={animationData}
                play
                style={{
                  width: "50%",
                  marginLeft: "26%",
                  marginTop: "-5%",
                  marginBottom: "-30px",
                }}
              />
              <div className="Pandetails">

                <div className="pancarddata">
                  <p className="pan-name">PAN Number :</p>
                  <span className="pan-content">{panData.pancard}</span>
                </div>

                <div className="pancarddata">
                  <p className="pan-name">Name :</p>
                  <span className="pan-content">{panData.name}</span>
                </div>

                <div className="pancarddata">
                  <p className="pan-name">Date Of Birth :</p>
                  <span className="pan-content">
                    {
                      panData.dob ? panData.dob :
                        <div className="input-tag add-border pan-input">
                          <DateCalender startDate={startDate} setStartDate={setStartDate} placeHolder={"Select DOB"} minYear={new Date().getFullYear() - 60} maxYear={new Date().getFullYear() - 21} timeLimit={timeLimit} />
                        </div>}
                  </span>
                </div>

                <div className="pancarddata">
                  <p className="pan-name">Gender :</p>
                  {panData.gender && (
                    <span className="pan-content">{panData.gender}</span>
                  )}
                  {!panData.gender && (
                    <div className="input-tag add-border pan-input">
                      <input
                        type="text"
                        style={{ cursor: "pointer" }}
                        value={genderOptions.values.value}
                        readOnly
                        placeholder="Select Gender"
                        onClick={() => openGenderOption()}
                      />
                      <img
                        src={DropDownIcon}
                        style={{ cursor: "pointer" }}
                        onClick={() => openGenderOption()}
                        className="small-the-icon"
                        alt=""
                      />
                      {genderOptions.show && (
                        <div className="drop-option-box ">
                          {genderOptions.Val.map((row, ind) => {
                            return (
                              <div
                                key={ind}
                                className="drop-option"
                                onClick={() => ReturnThisValue(row)}
                              >
                                {row.value}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="pancard-button">
                {/* {modify < 1 && (
                  <button onClick={() => handleModify()} className="modifybuton">
                    Modify PAN
                  </button>
                )} */}

                <button
                  disabled={!panData.gender || !panData.dob}
                  onClick={() => handleClick()}
                  className={
                    (panData.gender || genderOptions.values.value) && (panData.dob || startDate)
                      ? "contbuton activate-ctn-btn"
                      : "contbuton"
                  }
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default PartnerPanCard;
