import React, { useEffect, useState } from 'react';
import '../Form.css';
import { useForm } from 'react-hook-form';
import loan_icon from './../../../../Icons/loan_icon.svg';
import bag from './../../../../Icons/bag.svg';
import ThisIsAnError from './../../../../Icons/error.svg';
import Education from './../../../../Icons/Education.svg';
import Maritial_Icon from './../../../../Icons/Maritial_Icon.svg';
import DropDownIcon from '../../../../Icons/DropDownIcon.svg';
import man from './../../../../Icons/man.svg';
import email from './../../../../Icons/email.svg';
import MaritalStatusPopUp from '../../../Popups/MaritalStatusPopUp/MaritalStatusPopUp';
import QualificationPopUp from '../../../Popups/QualificationPopUp/QualificationPopUp';
import { useDispatch, useSelector } from 'react-redux';
import FormHead from '../FormHead';
import { GenerateToken, updateAdditionalDetailsPopUp, updateFormState, updateMasterState, updatePopUp, updateStepBar } from '../../../../Redux/action';
import ExperienceYearPopup from '../../../Popups/ExperienceYearpopup/ExperienceYearPopup';
import { prodbase_url, base_url, source, client_id, client_key, verification_source, verified_by, verifier_name, react_base_url } from '../../../Secret_Credentials/Secret_Credential';
import axios from 'axios';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../../../assests/lootie-files/continue.json';
import MainLoader from '../../../MainLoader/MainLoader';
import { AdditionalDetailsAPI, getMasterData, getToken } from '../../../EverCalledFunctions/EverCalledApis';
import { FetchCustomerPayload } from '../../../EverCalledFunctions/EverCalledPayload';
import Base64 from "base-64";
import { gettingerror } from '../../../EverCalledFunctions/EverCalledFunctions';
import { apiInstance } from '../../../Config/APIConfig';

const AdditonalFM = () => {
    const [resp, setResp] = useState(false)
    const dispatch = useDispatch();
    const [loaderTime, setLoaderTime] = useState(false)
    const { step_bar, additionalDetailsContent, PopUpState, master_state, formStates, mw_application_id, credentialState, token } = useSelector(state => state)
    const [parent, setParent] = React.useState({
        mother: false,
        father: false
    })
    const { register, handleSubmit, reset, formState: { errors }, watch, setValue, setError, clearErrors } = useForm({
        defaultValues: {
            maritalStatus: "",
            displayMS: "",
            highestQualification: "",
            displayHQ: "",
            fatherName: "",
            motherName: ""
        }, mode: "all"
    })
    const data = watch();



    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn))
    }

    React.useEffect(() => {
        dispatch(updateStepBar({ ...step_bar, profile: true, offer: true, verification: true }));
        if (token == "") {
            myToken();
        }
    }, [])

    const hitFetchCustomer = async () => {
        let decFetch = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 24), {
            token
        })
            // .then((res) => {
                if ((decFetch.status == 1) && (decFetch.status_code == 200)) {
                    if (decFetch.data.thank_you_flag == 1) {
                        gettingerror("get thank you flag", mw_application_id)
                        if (decFetch.data.thank_you_url !== "") {
                            window.location.href = decFetch.data.thank_you_url;
                            return
                        }
                        else {
                            let qry = Base64.encode(`${mw_application_id}`);
                            qry = Base64.encode(qry);
                            window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18`;
                            return
                        }

                    }
                }
            // })
    }

    useEffect(() => {
        if ((token !== "") && (mw_application_id !== "")) {
            hitFetchCustomer();
        }
    }, [token, mw_application_id])

    useEffect(() => {
        if (master_state.ms) {
            setValue('maritalStatus', master_state.ms.id)
            setValue('displayMS', master_state.ms.value)
        }
        if (master_state.hq) {
            setValue('highestQualification', master_state.hq.id)
            setValue('displayHQ', master_state.hq.value)
        }
    }, [master_state])



    {/* -------------------------------Submit Function------------------------ */ }
    const onSubmit = async (result) => {
        setResp(true)
        const payLoad = {
            "mw_application_id": mw_application_id,
            "customer_id": credentialState.cust_id,
            "marital_status": result.maritalStatus,
            "highest_qualification": result.highestQualification,
            "mother_name": result.motherName,
            "father_name": result.fatherName,
        }

        const APIDATA = await AdditionalDetailsAPI(payLoad, "")

        if (APIDATA.status_code = "200" && APIDATA.status === "1") {
            dispatch(updateFormState({ ...formStates, AdditionalDetails: false, ReferenceDetails: true }));
            gettingerror('submit additional address web', mw_application_id)
        } else {
            dispatch(updateFormState({ ...formStates, error: true }))
            setResp(false)
        }
    }


    const handleChar = (event) => {
        if (!/[A-Za-z ]/.test(event.key)) {
            event.preventDefault();
        }
    }


    // ---------------------Marital PopUP-----------
    const ShowMaritalPopUp = async () => {
        setLoaderTime(true)
        const APIDATA = await getMasterData("marital_status", token)
        await dispatch(updateMasterState({ ...master_state, values: APIDATA, status: true, heading: "Select your marital status", ms: null }))
        setLoaderTime(false)

    }

    // ---------------------Qualification PopUP-----------
    const showQulificationPopUp = async () => {
        setLoaderTime(true)

        const APIDATA = await getMasterData("highest_qualification", token)
        await dispatch(updateMasterState({ ...master_state, values: APIDATA, status: true, heading: "Select your highest qualification", hq: null }))
        setLoaderTime(false)

    }



    const SpaceManage = (value, field) => {
    }

    const Blured = (field, value) => {
        // var newVal = value.trimEnd();
        var newVal = value.replace(/\s+$/, '');
        setValue(field, newVal);
        if (newVal.length < 3) {
            setError(field)
        } else {
            clearErrors([field])
        }
    }

    const handleAlphaOnly = (value, field) => {
        var regexp = /[^a-zA-Z ]/g;
        if (value.match(regexp)) {
            setValue(field, value.replace(regexp, ''))
        } else {
            setValue(field, value.replace(/^\s+/, ''));
        }
    }



    return (
        <>
            {loaderTime && <MainLoader text={"please wait..."} />}
            <FormHead formName="Additional Details" formSubHead={"Add a few more details to help us know you better"} />

            <div className="tag-for-input">
                {/* -------------------------------Form Fields----------------------------------- */}
                <form autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>


                    {/* ---------------------------Matrial Stauts Input Field------------------------------- */}
                    <div className={errors.displayMS && !data.displayMS ? "input-tag error-border remove-bottom" : data.maritalStatus ? "input-tag add-border remove-bottom" : "input-tag remove-bottom"}>
                        <img className="tag-icon" src={Maritial_Icon} />
                        <input disabled={resp} className="input-tag-box pointer-cur" type="text" placeholder='Marital Status'
                            {...register('displayMS', { required: true })} autoComplete="off"
                            onClick={() => ShowMaritalPopUp()} onFocus={() => ShowMaritalPopUp()} readOnly />
                        <img className="small-the-icon pointer-cur" src={DropDownIcon} onClick={() => ShowMaritalPopUp()} alt="" />
                        {/* {errors.maritalStatus && <img className="error-img-show" src={ThisIsAnError} alt="" />} */}
                    </div>
                    {!data.displayMS && errors.displayMS && <div className='err-text'>Select your marital status</div>}


                    {/* -------------------------Highest Qulification input Field------------------------- */}
                    <div className={errors.displayHQ && !data.displayHQ ? "input-tag error-border remove-bottom" : data.highestQualification ? "input-tag add-border remove-bottom" : "input-tag remove-bottom"}>
                        <img className="tag-icon" src={Education} />
                        <input disabled={resp} className="input-tag-box pointer-cur" type="text" placeholder='Highest Qualification'
                            {...register('displayHQ', { required: true })} autoComplete="off"
                            onClick={() => showQulificationPopUp()} onFocus={() => showQulificationPopUp()} readOnly />
                        <img className="small-the-icon pointer-cur" src={DropDownIcon} onClick={() => showQulificationPopUp()} alt="" />
                        {/* {errors.highestQualification && <img className="error-img-show" src={ThisIsAnError} alt="" />} */}
                    </div>
                    {!data.displayHQ && errors.displayHQ && <div className='err-text'>Select your Highest Qualification</div>}


                    {/* ---------------------------Father Name Input Field------------------------------- */}
                    {<div className={errors.fatherName ? "input-tag error-border remove-bottom" : data.fatherName ? "input-tag add-border remove-bottom" : "input-tag remove-bottom"}>
                        <img className="tag-icon" src={man} />
                        <input disabled={resp} className="input-tag-box" type="text" placeholder="Father’s Name"
                            {...register('fatherName', { required: true, minLength: 3 })} autoComplete="off" onKeyUp={() => handleAlphaOnly(data.fatherName, "fatherName")} onBlur={() => Blured("fatherName", data.fatherName)} />
                        {/* {errors.fatherName && <img className="error-img-show" src={ThisIsAnError} alt="" />} */}
                    </div>}
                    {errors.fatherName && <div className='err-text'>Enter At least 3 Character</div>}


                    {/* -------------------------Mother Name input Field------------------------- */}
                    {<div className={errors.motherName ? "input-tag error-border remove-bottom" : data.motherName ? "input-tag add-border remove-bottom" : "input-tag remove-bottom"}>
                        <img className="tag-icon" src={man} />
                        <input disabled={resp} className="input-tag-box" type="text" placeholder='Mother’s Name'
                            {...register('motherName', { required: true, minLength: 3 })} autoComplete="off" onKeyUp={() => handleAlphaOnly(data.motherName, "motherName")} onBlur={() => Blured("motherName", data.motherName)} />
                        {/* {errors.motherName && <img className="error-img-show" src={ThisIsAnError} alt="" />} */}
                    </div>}
                    {errors.motherName && <div className='err-text'>Enter At least 3 Character</div>}



                    {/* ----------------------------Submit button----------------------------------- */}
                    <button type='submit' disabled={resp} className={(data.maritalStatus && data.highestQualification && data.fatherName.length > 2 && data.motherName.length > 2) ? 'footer-btn footer-btn-activate Tmargin Bmargin' : "footer-btn Tmargin Bmargin"}  >
                        {!resp ?
                            "Continue" :
                            <div className="continue-lottie">
                                <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                            </div>}
                    </button>
                </form>
            </div>
        </>
    )
}

export default AdditonalFM
