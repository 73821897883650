import React, { useState } from 'react';
import { useEffect } from 'react';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import queryString from "query-string";
import Base64 from "base-64";
import CongratulationPopup from '../OfferSection/CongratulationPopup';
import { useSelector, useDispatch } from 'react-redux';
import { setPreApprovedOfferDetails, updateCredentialsState, updateFormState, updateMwApplicationId, updateChoosenAmount, GenerateToken } from '../../Redux/action';
import { prodbase_url, source, client_id, client_key, verification_source, verified_by, verifier_name, react_base_url } from "../Secret_Credentials/Secret_Credential";
import axios from 'axios';
import { getCustId, getToken } from '../EverCalledFunctions/EverCalledApis';
import { decryptQueryIdOfferId } from '../EverCalledFunctions/EverCalledFunctions';
import { AxiosThirty } from '../AxiosConfiguration/AxiosConfig';
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';
import { apiInstance } from '../Config/APIConfig';


const FinalOffer = () => {
  const { preApprovedOfferDetails, formStates, credentialState, token } = useSelector(state => state)
  const [state, setState] = useState({
    loading: true,
    finalOffer: false
  })
  const [rocket, setRocket] = useState(false);
  const dispatch = useDispatch();

  const getCustIdHere = async (mw_app_id) => {
    // if (window.sessionStorage.getItem("cust_id") && (window.sessionStorage.getItem("cust_id") !== "undefined")) {
    //   dispatch(updateCredentialsState({ ...credentialState, cust_id: window.sessionStorage.getItem("cust_id") }));
    // }
    // else {
      // const APIDATA = await getCustId(mw_app_id, token);
      // window.sessionStorage.setItem("cust_id", APIDATA);
      // dispatch(updateCredentialsState({ ...credentialState, cust_id: APIDATA }))

      let decFetch = await apiInstance.post(`fetch-customer-details`, {
        mw_application_id: mw_app_id,
        verifier_name,
        verification_source,
        verified_by,
        step: 7,
        source,
        page_url: window.location.href
      }, {
        token
      })
      // .then((res) => {
        if ((decFetch.status == "1") && (decFetch.status_code == "200")) {
          if (decFetch.data.thank_you_flag == 1) {
            if (decFetch.data.thank_you_url !== "") {
                window.location.href = decFetch.data.thank_you_url;
                return
            }
            else {
                let qry = Base64.encode(`${mw_app_id}`);
                qry = Base64.encode(qry);
                window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18`;
                return
            }

        }
          else {
            // window.sessionStorage.setItem("cust_id", decFetch.data.customer_id);
            dispatch(updateCredentialsState({ ...credentialState, cust_id: decFetch.data.customer_id }))
          }
        }
      // }).catch((err) => {
      //   setRocket(true);
      // })
    // }
  };

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }

  }, [])

  const decryptFid = async () => {
    const details = await decryptQueryIdOfferId();
    dispatch(updateMwApplicationId(details[0]));
    getCustIdHere(details[0]);
  }

  React.useEffect(() => {
    if (token !== "") {
      decryptFid()
    }
  }, [token])



  useEffect(() => {
    const query = queryString.parse(window.location.search);

    if ((query.fid) && credentialState.cust_id && (credentialState.cust_id !== "")) {
      dispatch(updateFormState({ ...formStates, AipOffer: false, FinalOffer: true }));
      setState({ ...state, loading: false, finalOffer: true });
      setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: 2 })
    }
    else {
      // window.location.href = `${react_base_url}personal-loan`;
    }
  }, [credentialState.cust_id])

  const handleError = (e) => {
    window.location.reload();
  }


  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)}/>}
      {state.loading && <h1>Loading.....</h1>}
      {state.finalOffer && <div>
        <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
        {/* {<CongratulationPopup condition={true}/>} */}
      </div>}
    </>
  )
}

export default FinalOffer;