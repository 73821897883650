import React from 'react'

const SorryApp = () => {
  return (
   <>
   <p>Sorry Moneywide App</p>
   </>
  )
}

export default SorryApp