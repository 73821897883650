import React from "react";
import InfographCard from "./InfographCard";
import "./Infographics.css";
import '../AboutUs/about.css';
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken } from "../../Redux/action";
import { prodbase_url, react_base_url } from "../Secret_Credentials/Secret_Credential";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Helmet from "react-helmet";

function Infographics() {
    const { token } = useSelector(state => state);
    const dispatch = useDispatch();
    const [InfoData, setInfoData] = React.useState([]);
    const [keyWords, setKeyWords] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [arrowCount, setArrowCount] = React.useState(-1);
    const [sub, setSub] = React.useState("");
    const [value, setValue] = React.useState("");
    const [spanState, setSpanState] = React.useState(false);
    const params = useParams();

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }

    const handleChange = (e) => {
        setValue(e.target.value);
        const searchedData = InfoData.filter((data, idx) => {
            let main_heading = data.main_heading.toLowerCase();
            if ((main_heading.includes(e.target.value.toLowerCase())) && (e.target.value !== "")) {
                return data
            }
        })

        if (searchedData.length) {
            setKeyWords(searchedData);
        }
        else {
            setKeyWords([]);
        }
    }

    const handleClick = (idx) => {
        window.location.href = `${react_base_url}infographics/${keyWords[idx].url}`;
    }

    const handleView = (e, idx) => {
        window.location.href = `${react_base_url}infographics/${InfoData[idx].url}`
    }

    const handleKeyPress = (e, no) => {
        if (keyWords.length > 0) {
            if (e.keyCode === 38) {
                setArrowCount(arrowCount => arrowCount - no);
            }
            else if (e.keyCode === 40) {
                setArrowCount(arrowCount => arrowCount + no);
            }
            else if (e.keyCode === 13) {
                window.location.href = `${react_base_url}infographics/${keyWords[arrowCount].url}`
                // blogUsingUrl(searchData[arrowCount].url);
            }
        }
    }

    const handleSearch = () => {
        for (let i = 0; i < InfoData.length; i++) {
            if (InfoData[i].main_heading === value) {
                window.location.href = `${react_base_url}infographics/${InfoData[i].url}`
            }
        }
    }

    //   const handleSpan = (e, idx) => {
    //     if(spanState) {
    //         document.getElementById(`${idx}-info-cont-p`).innerHTML = `${InfoData[idx].sub_heading.substr(0, 74)}...`;
    //         document.getElementById(`${idx}-info-cont-span`).innerText = "Read More";
    //     }
    //     else {
    //         document.getElementById(`${idx}-info-cont-p`).innerHTML = `${InfoData[idx].sub_heading}`;
    //         document.getElementById(`${idx}-info-cont-span`).innerText = "Read Less";
    //     }
    //     setSpanState(!spanState);

    //   }

    React.useEffect(() => {
        if (token == "") {
            myToken()
        }
    }, [])

    React.useEffect(() => {
        if (token !== "") {
            axios.post(`${prodbase_url}api/fetch-infographic`, {
                "source": "MWide"
            }, {
                headers: {
                    token
                }
            }).then((res) => {
                if ((res?.data?.status == "1") && (res?.data?.status_code == "200") && (res?.data?.data?.length !== 0)) {
                    setInfoData(res.data.data);
                }
                else {
                    window.location.href = `${react_base_url}`
                }
            }).catch((err) => {
                // console.log(err);
            })
        }
    }, [token])

    React.useEffect(() => {
        if (arrowCount >= 0) {
            let dynamicEle = document.getElementsByClassName("search-keywords");

            if ((arrowCount >= 0) && (arrowCount < keyWords.length)) {
                for (let i = 0; i < keyWords.length; i++) {
                    dynamicEle[i].classList.remove("additional-css-blog");

                }
                if (arrowCount == 0) {
                    dynamicEle[arrowCount].classList.add("additional-css-blog");
                    document.querySelector(".search-keywords-div").scrollTo(0, 0);
                }
                else {
                    dynamicEle[arrowCount].classList.add("additional-css-blog");
                }
                setValue(keyWords[arrowCount].main_heading);
                document.querySelector(".info-search-box").value = keyWords[arrowCount].main_heading;
                let pos1 = window.scrollY + dynamicEle[0].getBoundingClientRect().top;
                let pos2 = window.scrollY + document.querySelector(".additional-css-blog").getBoundingClientRect().top;
                let result = Math.round(pos2 - pos1);
                setSub(result);
                if ((result >= 370) && (result > sub)) {
                    document.getElementsByClassName("search-keywords-div")[0].scrollBy(0, 55);
                }
                else if (result < sub) {
                    document.getElementsByClassName("search-keywords-div")[0].scrollBy(0, -55);
                }
            }
            else {
                setArrowCount(0);
            }

        }
        else if (keyWords.length > 0) {
            setArrowCount(0);
        } else {
            setArrowCount(-1);
        }
    }, [arrowCount]);

    return (
        <>
            <div className="faq-page-main">
                <section className="faqpage">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                            <div className="left-top infographics">
                                <Link to={`/${window.location.search}`}>Home</Link>  &gt;{" "}
                                <span>Infographics</span>
                            </div>
                                <div className="infograph-heading">
                                    <span className="graphhead1">MoneyWide</span>
                                    <p className="graphhead2">Infographics</p>
                                    <p>Making Your Financial Life More Simplified!</p>
                                </div>
                                <div className="infograph-search-box">
                                    <div className="infograph-inner-search-box">
                                        <div className="infoinput"><input type="text" placeholder="Search by keywords" className="info-search-box" onChange={(e) => handleChange(e)} onKeyDown={(e) => handleKeyPress(e, 1)} /></div>
                                        <div><button onClick={() => handleSearch()} className="search-btn">Search</button></div>


                                    </div>
                                    <div className="search-keywords-div">
                                        {keyWords.length ? keyWords.map((row, idx) => <div key={`${idx}-search-key`} className="search-keywords" onClick={() => handleClick(idx)}  >
                                            <p>{row.main_heading} </p>
                                        </div>) : ((value && !keyWords.length) ? "No results found!!" : "")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="infosection">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="infograph-cards-main">
                                    {InfoData.length ? InfoData.map((row, idx) => <InfographCard key={`${idx}-infodata`} id={`${idx}-infodata`} img={"https://www.moneywide.com/" + row.image} subhead={row.main_heading} description={row.sub_heading} viewdate={row.date} handleView={(e) => handleView(e, idx)} info_cont_p={`${idx}-info-cont-p`} info_cont_span={`${idx}-info-cont-span`} />) : ""}
                                </div>
                                {/* <div className="info-readmore">
                                <button>
                                    Read More
                                </button>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default Infographics;