import React, { useEffect, useState } from 'react'
import './LoanDetails.css'
import repaymoney from '../../Icons/repaymoney.svg'
import { formatDate } from './MyAccountAPIs'

function RecentTransaction({ rowData, text }) {
  const [openREPay, setOpenREPay] = useState({
    repayDetails: false
  })




  const openCloseREpay = () => {
    setOpenREPay({ ...openREPay, repayDetails: !openREPay.repayDetails });
  }
  return (
    <>
      <div>
        <div className="recenttransaction-main-box">
          <div className={'recenttrans-boxes' + (openREPay.repayDetails ? " reapyborder" : "")} onClick={() => openCloseREpay()}>
            <div className='recenttrans-left'>
              <img src={repaymoney} alt='repaymoney' />
              <div className='recenttrans-box-heading'>
                <span>{text ? text : "Repayment"}</span>
                <p>{rowData.date ? formatDate(rowData.date) : (rowData.transactionDate ? formatDate(rowData.transactionDate) : "")}</p>
              </div>
            </div>
            <div className='recenttrans-right'>
              <span>{rowData.amount ? "Rs. " + rowData.amount.toLocaleString("en-IN") : "106,281.73"}</span>
              <div style={{ display: "flex", alignItems: "center", gap: "40px" }}><i className={"arrow " + (openREPay.repayDetails ? "up" : "down")} style={!openREPay.repayDetails ? { marginTop: "-6px" } : {}} ></i></div></div>
          </div>
          {openREPay.repayDetails && <div className='recenttrans-bottom-dwn'>
            <div className='break-head'>
              <span>Break Down</span>
            </div>
            <div className='breakdown-main-content'>
              <div className='bdcont'>
                <span>Principal</span>
                <p>{rowData.principalPortion}</p>
              </div>
              <div className='bdcont'>
                <span>Interest</span>
                <p>{rowData.interestPortion}</p>
              </div>
              <div className='bdcont'>
                <span>Fees</span>
                <p>{rowData.feeChargesPortion}</p>
              </div>
              <div className='bdcont'>
                <span>Penalties</span>
                <p>{rowData.penaltyChargesPortion}</p>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </>
  )
}

export default RecentTransaction