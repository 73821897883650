import React, { useState } from 'react'
import './ChatBot.css'
import Lottie from 'react-lottie-player'
import mwChatBot from '../assests/lootie-files/catbot-color.json'
import crossicon from '../../Icons/cross-icon.svg'
import ChatPopUpNew from './ChatPopUpNew';
import { useEffect } from 'react';


function ChatBot() {

    const [isOpen, setIsOpen] = useState(false);
    const togglepopup = () => {
        setIsOpen(!isOpen)
    };



    return (
        <>  <div className={isOpen ? "loaderbackground-right" : ""}>
            <div className="chat-bot-logo" onClick={togglepopup}>
                {!isOpen && (
                    <Lottie
                        loop
                        animationData={mwChatBot}
                        play
                        className='chat-bot-icon'
                    />
                )}
            </div>
            {isOpen && <div className="chat-popup-open">
                <ChatPopUpNew setIsOpen={setIsOpen} onClose={togglepopup} />
            </div>}
        </div>
        </>
    )
}

export default ChatBot