import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import file from './offer-not-valid.svg';
import '../Popups/Selfie verification popup/SelfieVerificationpopup.css';
import { react_base_url } from '../Secret_Credentials/Secret_Credential';
import { useSelector } from 'react-redux';
import { RelocateUTMCheck, encryptPhoneAndCustomer } from '../EverCalledFunctions/EverCalledFunctions';

function PartnerSorry() {
  const { mw_application_id, credentialState, loginDetails } = useSelector(state => state);
  const [continueNextSection,setcontinueNextSection]=useState(false);
  const [timer,setTimer] = useState(8);

  useEffect(() => {
    timer > 0 && setTimeout(() => {
      setTimer(timer - 1)
    }, 1000)
  }, [timer])
  

    useEffect(()=>{
       setTimeout(() => {
       let qry_str = encryptPhoneAndCustomer(loginDetails.mobile, credentialState.cust_id);
       RelocateUTMCheck(`${react_base_url}personal-loan/?jid=${qry_str}`)
       }, 8000);
    },[])
  
  return (
    <div className={continueNextSection?"loaderbackground remove-block":"loaderbackground"}>
      <div className="loadertransparent">
         <div className="SelfieVerificationPopup">
            <div className="SelfieVerificationPopup-main">
               <img className='pa-img-sorry' src={file}/>
               <h2>Hi, Looks like Pre-Approved Offer is not valid for your registered mobile number. You can still check Personal Loan eligibility from MoneyWide.</h2>
               <p style={{textAlign:"center",paddingBottom:"30px",marginTop:"10px"}}>Automatically Redirect after {timer} Seconds</p>
            </div>
         </div>
      </div>
    </div>
  )
}

export default PartnerSorry;