import React from 'react'
import "./ourproducts.css"
import halfcircle from "../../Icons/halfcircle.svg"


function OurProducts() {

    const handleclick = ()=>{
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return (
        <div className="ourproducts-main">
            <div className="product-bg-image">
                <img src={halfcircle} alt='half-circle'/>
            </div>
            <div className="ourproducts-sub">
                <div className="product-heading">
                    <p>Our</p>
                    <span>Products</span>
                    <p>We offer a wide range of loans starting from Rs 20,000 to Rs 3,00,000 with a repayment tenure of upto 36 months.</p>
                </div>
                <div className="instant-box">
                    <div className="instant-head-cont">
                        <div className="instant-head">
                            <span>Instant Personal Loan</span>
                        </div>
                        <div className="instant-contnt">
                            <div className="instant-inner">
                                <span>Loan Amount: </span>
                                <p>₹ 20,000-3 Lakhs</p>
                            </div>
                            <div className="instant-inner">
                                <span>Loan Tenure:</span>
                                <p>6 to 36 months</p>
                            </div>
                            <div className="instant-inner">
                                <span>Credit Score:</span>
                                <p>675 or more</p>
                            </div>
                            <div className="instant-inner">
                                <span>Interest Rates:</span>
                                <p>18%- 26%</p>
                            </div>
                            <div className="instant-inner">
                                <span>Processing Fees: </span>
                                <p>3.75% of loan amount</p>
                            </div>
                            <div className="instant-inner">
                                <span>Foreclosure Charges:</span>
                                <p> 4%</p>
                            </div>
                        </div>
                    </div>
                    <div className="instant-bottom">
                        <a onClick={handleclick}>Apply Now</a>
                    </div>
                </div>
                <div className="instant-box green-instant-box">
                    <div className="instant-head-cont">
                        <div className="instant-head">
                            <span>New to Credit Personal Loan</span>
                        </div>
                        <div className="instant-contnt">
                            <div className="instant-inner">
                                <span>Loan Amount: </span>
                                <p>₹ 20,000-1 Lakhs</p>
                            </div>
                            <div className="instant-inner">
                                <span>Loan Tenure:</span>
                                <p>3 to 12 months</p>
                            </div>
                            <div className="instant-inner">
                                <span>Credit Score:</span>
                                <p>NIL</p>
                            </div>
                            <div className="instant-inner">
                                <span>Interest Rates:</span>
                                <p>30 to 35 %</p>
                            </div>
                            <div className="instant-inner">
                                <span>Processing Fees: </span>
                                <p>4% of loan amount</p>
                            </div>
                            <div className="instant-inner">
                                <span>Foreclosure Charges:</span>
                                <p> 4%</p>
                            </div>
                        </div>
                    </div>
                    <div className="instant-bottom">
                        <a onClick={handleclick}>Apply Now</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurProducts