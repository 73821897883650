import React, { useState } from "react";
import "./App.css";
// import Header from "./Components/Header/Header";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Contact from "./Components/Contactus/Contact";
import HomePage from "./Components/HomePage/HomePage";
// import Footer from "./Components/Footer/Footer";
import PersonalLoan from "./Components/PersonalLoan/PersonalLoan";
import PrivacyPolicyApp from "./Components/PrivacyPolicy/PrivacyPolicyApp";
import PLEligibilityCalculator from "./Components/PLEligibilityCalculator/PLEligibilityCalculator";
import PersonalLoan_Ans_EMI from "./Components/Personal Loan And EMI Calculator/PersonalLoan_Ans_EMI";
import NewCreditPL from "./Components/NewCreditPersonalLoan/NewCreditPL";
import TravelLoan from "./Components/TravelLoan/TravelLoan";
import PersonalLoanRepaymentOption from "./Components/Personal Loan Repayment Option/PersonalLoanRepaymentOption";
import TypesOfPersonalLoan from "./Components/Types Of Personal Loan/TypesOfPersonalLoan";
import InstantPersonalLoan from "./Components/Instant Personal Loan/InstantPersonalLoan";
import HomeRenovation from "./Components/PLforHomeRenovation/HomeRenovation";
import PLIRFeeAndCharges from "./Components/PLIRF&C/PLIRFeeAndCharges";
import PLForeclouserCalculator from "./Components/Personal Loan Foreclosure Calculator/PLForeclouserCalculator";
import OurPartner from "./Components/OurPartner/OurPartner";
import UserConsentForm from "../src/Components/UserConsent/UserConsentForm";
import PANCARDPopUP from "./Components/Popups/PANCARDPopUP/PANCARDPopUP";
import NotFound from "./Components/NotFound/NotFound";
import CongratulationPopup from "./Components/OfferSection/CongratulationPopup";
import PurposeLoanPopUp from "./Components/Popups/PurposeLoanPopUp/PurposeLoanPopUp";
import IncorrectBankDocumentPopUp from "./Components/Popups/IncorrectBankDocumentPopUp/IncorrectBankDocumentPopUp";
import { useSelector } from "react-redux";
import PLWithoutDocuments from "./Components/Instant Personal Loan Without Documents/PLWithoutDocuments";
import PersonalLoanforMarriage from "./Components/Marriage loan/PersonalLoanforMarriage";
import AllPolicy from "./Components/Loanpolicy/AllPolicy";
import PLonAdhaar from "./Components/PLonAAdharCard/PLonAdhaar";
import { useEffect } from "react";
import PopUp from "./Components/LoginPopUp/PopUp";
import MyAccount from "./Components/MyAccount/MyAccount";
import ThankyouPopup from "./Components/Popups/Thankyou/ThankyouPopup";
import NewServiceRequest from "./Components/ServiceRequest/NewServiceRequest";
import FinalOffer from "./Components/FinalOffer/FinalOffer";
import MobileFooter from "./Components/Footer/MobileFooter";
import Emandate_Setup from "./Components/Analyzer/Emandate_Setup";
import Agreement from "./Components/Analyzer/Agreement";
import Emandate from "./Components/Analyzer/Emandate";
import ThankYouApp from './Components/Analyzer/ThankYouApp';
import Sign from './Components/Analyzer/Sign';
import ErrorCase from './Components/Journey Form/Form/All_Forms/ErrorCase';
import FinalThankyou from './Components/Analyzer/FinalThankyou';
import EmandateRazDig from './Components/Analyzer/EmandateRazDig';
import PartnerMandate from './Components/Analyzer/PartnerMandate';
import PartnerRedirect from './Components/Analyzer/PartnerRedirect';
import money from './Components/assests/favicon.png';
import CorporateDisclosures from './Components/CorporateDisclosures/CorporateDisclosures';
// import AadhaarCard from './Components/AadhaarCard/AadhaarCard';
import Nomine from "./Components/Nominee/Nomine";
import LoginArea from "./Components/MyAccount/LoginArea";
import CustomerReviewForm from "./Components/CustomerReview/CustomerReviewForm";
import MoneyWidePartner from "./Components/BecomePartner/MoneyWidePartner";
import BlogMain from "./Components/Blog/BlogMain";
import MainFaq from "./Components/MoneyWideFaq/MainFaq";
import SiteMap from './Components/SiteMap/SiteMap';
import CustomerReviewMain from "./Components/CustomerReview/CustomerReviewMain";
import Infographics from "./Components/Infographics/Infographics";
import Infographpage from "./Components/Infographics/Inphographpage";
import Careers from "./Components/AboutUs/Careers";
import LinkEmailThankyou from "./Components/LinkEmailVerify/LinkEmailThankyou";
import LinkEmailSorry from "./Components/LinkEmailVerify/LinkEmailSorry";
import LinkEmailMain from "./Components/LinkEmailVerify/LinkEmailMain";
import BsaAnalyzer from "./Components/EMANDATE & ENACH/BsaAnalyzer";
import BsaRedirect from "./Components/Analyzer/BsaRedirect";
import ScrollTop from "./Components/ScrollTop/ScrollTop";
import PersonalLoan_Prepayment_Cal from "./Components/Personal Loan Prepayment Calculator/PersonalLoan_Prepayment_Calculator";
import PartnerWithUs from "./Components/PartnerWithUs/PartnerWithUs";
import PartnerSorry from "./Components/PartnerWithUs/PartnerSorry";
import MakePayment from './Components/MyAccount/MakePayment'
import Selfie from "./Components/Analyzer/Selfie";
import NewVkyc from "./Components/Analyzer/NewVkyc";
import NewVkycRedirect from "./Components/Analyzer/NewVkycRedirect";
import SorryApp from "./Components/Analyzer/SorryApp";
import About from "./Components/About/About";
import ShortTermPl from "./Components/ShortTermPersonalLoan/ShortTermPersonalLoan";
import PreApprovedPl from "./Components/PreApprovedPersonalLoan/PreApprovedPersonalLoan";
import LegalNotice from "./Components/LegalNotice/LegalNotice";
import { environment } from "./Components/Secret_Credentials/Secret_Credential";
// import queryString from "query-string";
// import Base64 from 'base-64';
import ChatBot from "./Components/ChatBot/ChatBot";
import PartnerFormCall from "./Components/PartnerForm/PartnerFormCall";
import LoanAgreementPartnerForm from "./Components/Journey Form/Form/All_Forms/LoanAgreementPartnerForm";
import EsignPartnerForm from "./Components/Popups/esign/EsignPartnerForm"
import ThankyouPopupPartner from "./Components/PartnerForm/ThankyouPopupPartner";
import { SubmitUrlApi } from "./Components/EverCalledFunctions/EverCalledApis";
import PartneDocPopUp from "./Components/PartnerForm/PartneDocPopUp";
import NewHeader from "./Components/NewHeader/NewHeader";
import NewFooter from "./Components/NewFooter/NewFooter";
import EmbeddedOfferings from "./Components/EmbeddedOfferings/EmbeddedOfferings";

function App() {
  const { App_status, loginDetails, PopUpState, master_state, preApprovedOfferDetails, formStates, partnerStates, makePayment, token, partnerdocupdate, setService } = useSelector(state => state);

  const [chatBotState, setChatBotState] = useState(true);

  const submitUrl = async () => {
    await SubmitUrlApi(token);
  }

  useEffect(() => {
    if ((window.location.protocol !== 'https:') && (environment == 'production')) {
      window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
    }
    let url = window.location.pathname;
    if (url.includes('/analyzer') || url.includes('/vkyc') || url.includes('/e-sign') || url.includes('/loan-agreement-partner-form') || url.includes('/loan-agreement') || url.includes('/emandate-setup') || url.includes('/emandate') || url.includes('/lp') || url.includes('/partner-form') || url.includes('/e-sign-partner-form') || url.includes('/partnermandate') || url.includes("/myaccount")) {
      setChatBotState(false);
    }
    submitUrl();
  }, []);

  return (
    <div className="App" id="app">
      <ScrollTop />
      {partnerdocupdate.status && <PartneDocPopUp />}
      {setService.showchatbot && formStates.StartJourney == false && chatBotState && <ChatBot />}
      {partnerStates.Sorry && <PartnerSorry />}
      {formStates.EmailVerifyLink && <LinkEmailThankyou />}
      {formStates.EmailVerifyLinkSorry && <LinkEmailSorry />}
      {preApprovedOfferDetails.state === 1 && (
        <CongratulationPopup condition={false} />
      )}
      {preApprovedOfferDetails.state === 2 && (
        <CongratulationPopup condition={true} />
      )}
      {PopUpState.InvalidDocument.show && <IncorrectBankDocumentPopUp />}
      {formStates.showThank && (
        <ThankyouPopup heading="Your email has been verified successfully" />
      )}
      {master_state.status && <PopUp />}
      {formStates.error && <ErrorCase />}
      {PopUpState.LoanPurposePopUp.show && <PurposeLoanPopUp />}
      {loginDetails.isPan && <PANCARDPopUP />}
      {makePayment.show && <MakePayment />}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet>
                <title>
                  MoneyWide - One Stop Solution to Get Instant Personal Loan
                </title>
                <meta name="description" content="Apply for an instant personal loan online at low-interest rates, minimum KYC, instant money transfer in your bank account, completely digital & no paperwork." />
                <meta name="keywords" content="MoneyWide, MoneyWide Loan" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <HomePage />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/nominee"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  MoneyWide - One Stop Solution to Get Instant Personal Loan
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <Nomine />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          path="/everify"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              <NewHeader />
              <LinkEmailMain />
              <NewFooter />
            </>
          }
        />

        <Route
          exact path="/infographics"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>MoneyWide Infographics</title>
                <meta name="description" content="Financial Jargons Simplified" />
                <meta name="keywords" content="Infographics" />
              </Helmet>
              <NewHeader />
              <Infographics />
              <NewFooter />
            </>
          }
        />

        <Route
          path="/infographics/:url"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              <NewHeader />
              <Infographpage />
              <NewFooter />
            </>
          }
        />

        <Route
          exact path="/reviews"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  MoneyWide - One Stop Solution to Get Instant Personal Loan
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <CustomerReviewForm />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          path="/customer-reviews"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  MoneyWide - One Stop Solution to Get Instant Personal Loan
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <CustomerReviewMain />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/personal-loan/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <PersonalLoan />
            </>
          }
        />

        <Route
          path="/analyzer"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  MoneyWide - One Stop Solution to Get Instant Personal Loan
                </title>
                <link rel="icon" href="moneyWide.png" />
                <link rel="apple-touch-icon" href="moneyWide.png" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
              </Helmet>
              {!App_status && <NewHeader />}
              <div className="remain-down">
                <BsaAnalyzer />
              </div>
              {!App_status && <MobileFooter />}
            </>
          }
        />

        <Route
          exact path="/analyzer/thank-you"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              <div className="remain-down">
                <ThankYouApp />
              </div>
            </>
          }
        />

        <Route
          path="/analyzer/sorry"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              <div className="remain-down">
                <SorryApp />
              </div>
            </>
          }
        />

        <Route
          path="/lp"
          element={
            <>
              <Helmet>
                <title></title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
              </Helmet>
              <PartnerWithUs />
            </>
          }
        />

        <Route
          exact path="/final-offer"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  MoneyWide - One Stop Solution to Get Instant Personal Loan
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <FinalOffer />
              {!App_status && <MobileFooter />}
            </>
          }
        />

        <Route
          exact path="/sitemap"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              <NewHeader />
              <SiteMap />
              <NewFooter />
            </>
          }
        />

        <Route
          exact path="/partnermandate"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              <PartnerMandate />
            </>
          }
        />

        <Route
          exact path="/partnermandate/pat"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              <PartnerRedirect />
            </>
          }
        />

        <Route
          exact path="/analyzer/redirect"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              <BsaRedirect />
            </>
          }
        />

        <Route
          exact path="/partner-with-us"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <MoneyWidePartner />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/blog"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>MoneyWide Blog for Latest Financial Updates</title>
                <meta name="description" content="One-stop solution to clear your financial doubts for topics such as instant personal loans, digital loans and online lending." />
                <meta name="keywords" content="moneywide blog" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              <NewHeader />
              <BlogMain />
              <NewFooter />
            </>
          }
        />

        <Route
          path="/blog/:id"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              <NewHeader />
              <BlogMain />
              <NewFooter />
            </>
          }
        />

        <Route
          exact path="/faqs"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  Frequently Asked Questions
                </title>
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <MainFaq />
              {!App_status && <NewFooter />}
            </>
          }
        />


        <Route
          exact path="/partner-form"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <PartnerFormCall />
              {!App_status && <NewFooter />}
            </>
          }

        />
        <Route
          exact path="/loan-agreement-partner-form"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <LoanAgreementPartnerForm />
              {!App_status && <MobileFooter />}
            </>
          }

        />
        <Route
          exact path="/e-sign-partner-form"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <EsignPartnerForm />
              {!App_status && <NewFooter />}
            </>
          }

        />

        <Route
          exact path="/partner-thank-you"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <ThankyouPopupPartner />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/thank-you"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <FinalThankyou />
              {!App_status && <MobileFooter />}
            </>
          }
        />

        <Route
          exact path="/selfie"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  MoneyWide - One Stop Solution to Get Instant Personal Loan
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <Selfie />
              {!App_status && <MobileFooter />}
            </>
          }
        />

        <Route
          exact path="/vkyc"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  MoneyWide - One Stop Solution to Get Instant Personal Loan
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              {/* <Vkyc /> */}
              <NewVkyc />
              {!App_status && <MobileFooter />}
            </>
          }
        />

        <Route
          exact path="/vkyc/redirect"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {/* <Redirect /> */}
              <NewVkycRedirect />
            </>
          }
        />

        <Route
          exact path="/emandate-setup"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  MoneyWide - One Stop Solution to Get Instant Personal Loan
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <Emandate_Setup />
              {!App_status && <MobileFooter />}
            </>
          }
        />

        <Route
          exact path="/emandate"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <EmandateRazDig />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/emandate/redirect"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <Emandate />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/loan-agreement"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  MoneyWide - One Stop Solution to Get Instant Personal Loan
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <Agreement />
              {!App_status && <MobileFooter />}
            </>
          }
        />

        <Route
          exact path="/e-sign"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  MoneyWide - One Stop Solution to Get Instant Personal Loan
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <Sign />
              {!App_status && <MobileFooter />}
            </>
          }
        />

        <Route
          path="/embedded-offerings"
          element={
            <>
              <Helmet>
                <title> Embedded Finance Offerings- A complete credit solution for all your financial needs</title>
                <meta name="description" content="Unlock all your financial needs in the edtech, health, travel, and insurance sectors with MoneyWide embedded offerings" />
                <meta name="keywords" content=" Embedded Solutions, MoneyWide Embedded Solutions, MoneyWide Embedded Offerings" />
              </Helmet>
              {!App_status && <NewHeader />}
              <EmbeddedOfferings />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/service-request"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  Service Request - Submit Query Online at MoneyWide
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <NewServiceRequest
                title={"We're Here To Help"}
                content={
                  "Submit your Service Request or online and we'll reach out to you to answer/resolve your queries."
                }
              />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/contact-us"
          element={
            <>
              <Helmet>
                <title>
                  Contact Us | MoneyWide Customer Care and Address Details
                </title>
                <meta name="description" content="Contact MoneyWide regarding personal loan, home loan, loan against property, gold loan, loan advisory services" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <Contact />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/about-us"
          element={
            <>
              <Helmet>
                <title>
                  About Us | MoneyWide | RBI Registered Digital NBFC
                </title>
                <meta name="description" content="MoneyWide offers 100% digital loans with instant paperless disbursal." />
                <meta name="keywords" content="About MoneyWide, about myloan" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <About />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/careers"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>
                  About Us | MoneyWide | RBI Registered Digital NBFC
                </title>
                <meta name="description" content="MoneyWide offers 100% digital loans with instant paperless disbursal." />
                <meta name="keywords" content="Careers MoneyWide, about myloan" />
                <link rel="canonical" href={window.location.href} />
              </Helmet>
              {!App_status && <NewHeader />}
              <Careers />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/policies"
          element={
            <>
              <Helmet>
                <title>Legal Requirements for Moneywide Personal Loan</title>
                <meta name="description" content="Check refund, cancellation, interest rate, and terms and conditions of MoneyWide instant personal loan." />
                <meta name="keywords" content="refund and cancellation policy, privacy policy, moneywide terms and conditions" />
                <link rel="canonical" href={window.location.href} />
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <AllPolicy />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/cancellation-and-refund-policy"
          element={
            <>
              <Helmet>
                <title>Refund and Cancellation Policies | MoneyWide</title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <AllPolicy />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/privacy-policy"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>Privacy Policy - Moneywide</title>
                <meta
                  name="description"
                  content="Privacy Policy – All about the use, confidentiality and security of the information of our users"
                />
                <meta name="keywords" content="Privacy Policy – MoneyWide" />
                <link rel="canonical" href={window.location.href} />
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <AllPolicy />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          path="/privacy-policy/app"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
                <title>Privacy Policy - Moneywide</title>
                <meta
                  name="description"
                  content="Privacy Policy – All about the use, confidentiality and security of the information of our users"
                />
                <meta name="keywords" content="Privacy Policy – MoneyWide" />
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <PrivacyPolicyApp />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/terms-and-conditions"
          element={
            <>
              <Helmet>
                <title>Terms and Conditions - Moneywide</title>
                <meta
                  name="description"
                  content="MyLoanCare Ventures Pvt Ltd., or the website https://moneywide.com/ (together called the Company)terms and conditions of us"
                />
                <meta
                  name="keywords"
                  content="Terms and Conditions, My Loan Terms, MoneyWide Terms"
                />
                <link rel="canonical" href={window.location.href} />
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <AllPolicy />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/disclaimer"
          element={
            <>
              <Helmet>
                <title>User Consent Form - Moneywide</title>
                <meta
                  name="description"
                  content="Terms and conditions are broad guidelines that govern the contractual relationship between the borrower and services provided by MoneyWide."
                />
                <meta
                  name="keywords"
                  content="User Consent form, MoneyWide Terms"
                />
                <link rel="canonical" href={window.location.href} />
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <UserConsentForm />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/personal-loan-eligibility-calculator/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <PLEligibilityCalculator />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          path="/personal-loan-partpayment-calculator/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <PersonalLoan_Prepayment_Cal />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          path="/short-term-personal-loan"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <ShortTermPl />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          path="/pre-approved-personal-loan"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <PreApprovedPl />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/personal-loan-emi-calculator/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <PersonalLoan_Ans_EMI />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/mw-personal-loan-repayment-options/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <PersonalLoanRepaymentOption />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/types-of-personal-loan/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <TypesOfPersonalLoan />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/new-credit-personal-loan-mw-first/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <NewCreditPL />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/travel-loan/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <TravelLoan />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/instant-personal-loan/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <InstantPersonalLoan />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/personal-loan-for-home-renovation/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <HomeRenovation />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/personal-loan-interest-rate-fees-and-charges/"
          element={
            <><Helmet>
              <link rel="icon" href={money} />
            </Helmet>
              {!App_status && <NewHeader />}
              <PLIRFeeAndCharges />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/personal-loan-foreclosure-calculator/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <PLForeclouserCalculator />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/instant-personal-loan-without-documents"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <PLWithoutDocuments />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/personal-loan-for-marriage/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <PersonalLoanforMarriage />
              {!App_status && <NewFooter />}
            </>
          }
        />

        <Route
          exact path="/personal-loan-on-aadhar-card/"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader />}
              <PLonAdhaar />
              {!App_status && <NewFooter />}
            </>
          }
        />
        <Route
          path="/login"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              <>
                <NewHeader />
                <LoginArea />
              </>
            </>
          }
        />
        <Route
          path="/myaccount"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              <>
                <NewHeader />
                <LoginArea />
              </>
            </>
          }
        />

        <Route
          path="/myaccount/profile"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              <NewHeader />
              <MyAccount />
            </>
          }
        />

        <Route
          path="/myaccount/settings"
          element={
            <>
              <NewHeader />
              <MyAccount />
            </>
          }
        />

        <Route
          path="/myaccount/myloan"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              <NewHeader />
              <MyAccount />
            </>
          }
        />
        <Route
          path="/myaccount/track-application"
          element={
            <>
              <>
                <Helmet>
                  <link rel="icon" href={money} />
                </Helmet>
              </>
              <NewHeader />
              <MyAccount />
            </>
          }
        />

        <Route
          path="/myaccount/loan-foreclosure-details"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              <NewHeader />
              <MyAccount />
            </>
          }
        />
        <Route
          path="/myaccount/overdue-payment"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              <NewHeader />
              <MyAccount />
            </>
          }
        />

        <Route
          path="/myaccount/loan-wise-emi-schedule"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              <NewHeader />
              <MyAccount />
            </>
          }
        />
        <Route
          path="/myaccount/loan-wise-statements"
          element={
            <><Helmet>
              <link rel="icon" href={money} />
            </Helmet>
              <NewHeader />
              <MyAccount />
            </>
          }
        />
        <Route
          path="/myaccount/loan-account-details"
          element={
            <>
              <Helmet>
                <link rel="icon" href={money} />
              </Helmet>
              <NewHeader />
              <MyAccount />
            </>
          }
        />
        <Route
          path="/myaccount/deletion"
          element={
            <>
              <NewHeader />
              <MyAccount />
            </>
          }
        />

        {/* <Route
          exact path="/aadhaar-card"
          element={
            <>
              <Helmet>
                <title>

                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
                <link rel="icon" href={money} />
              </Helmet>
              {!App_status && <NewHeader/>}
              <AadhaarCard />
              {!App_status && <NewFooter/>}
            </>
          }
        /> */}

        <Route

          path="/legal-notice/"
          element={
            <>
              <Helmet>
                <title>

                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href={window.location.href} />
                <link rel="icon" href={money} />
              </Helmet>
              <LegalNotice />
            </>
          }
        />



        <Route
          path="*"
          element={
            <>
              <Helmet>
                <title>Page Not Found</title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
              </Helmet>
              <NotFound />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
