import React, { useEffect } from 'react';
import '../PersonalLoan/PersonalLoan.css';
import './PLForeclouserCalculator.css';
import Calculator from '../PersonalLoan/ForeclosureCalculator';
import EligibilityCriteria from '../PersonalLoan/images/eligibility-criteria.webp';
// import FAQSection from '../FAQSection/FAQSection';
import greenticknew from '../../Icons/greenticknew.svg';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import { updateProductId } from '../../Redux/action';
import { useDispatch, useSelector } from 'react-redux';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';

const PLForeclouserCalculator = () => {
    var loan_type_id_page = 56;
    const { mwpages } = useSelector(state => state);
    const dispatch = useDispatch();
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY >= 2400) {
            sety(false);
        }
        else {
            sety(true);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])
    return (
        <>
            {/* <NewLoginForm pageType={loan_type_id_page} mapData={data}/> */}
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi />
            <div style={{ marginTop: "100px" }}>
                {y && <RightSidePopUp />}
                <section style={{ marginBottom: "100px" }}>
                    <Calculator />
                </section>
                <section className="">
                    <div className="ploan-container">
                        <div className="ploan-content1">
                            <div className="eligibility-criteria-box fceligible">
                                <h2 className="heing bot-6 add-btom-line cetnred">Personal Loan <b>Foreclosure Calculator</b></h2>
                                <p className="heading-p">A <b>personal loan foreclosure calculator</b> is an online calculator that helps you calculate the foreclosure charges against a personal loan. Factors like loan amount, repayment tenure, rate of interest, EMIs paid and month of foreclosure are taken into account to determine the actual personal loan foreclosure charges. In addition, you can also evaluate the benefit of saved interest that you can avail with Personal Loan Foreclosure.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="eligibility-criteria">
                    <div className="ploan-container">
                        <div className="box-sh">
                            <div className="eligibility-criteria-box" style={{ paddingBottom: "20px", paddingTop: "24px" }}>
                                <h2 className="heing lefting bot-6 add-btom-line" style={{ marginBottom: "20px" }}>How to Use MoneyWide Personal Loan <b>Foreclosure Calculator?</b></h2>
                                <div className="ploan-row-criteria elgib-row-criteria">
                                    <div className="colm-2">
                                        <div className="steps-to-loan">
                                            <p>By entering the following details, you will be able to calculate the foreclosure amount:</p>
                                        </div>
                                        <div className="mlc-pl-list mlc-pl-mrgin-top">
                                            <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span>Loan amount</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span>Repayment tenure</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span>The effective rate of interest</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span>Number of EMIs already paid</span>
                                            </div>
                                        </div>
                                        <div className="steps-to-loan">
                                            <p>The calculator will consider all the factors and demonstrate the final amount needed to be paid by the borrower in the foreclosure process.</p>
                                        </div>
                                    </div>
                                    <div className="colm-1">
                                        <img src={EligibilityCriteria} alt="Not Found" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                    <FAQSectionChk data={mwpages.faqdata} />
                </section> : ""}
            </div>
        </>
    )
}

export default PLForeclouserCalculator;