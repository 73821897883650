import React from 'react';
import FormHead from '../../FormHead';
import './KYCVerification.css';
import aadhar from '../../../../../Icons/aadhar.svg'
import DigiLocker from '../../../../../Icons/DigiLocker.svg'
import aadhar_Img from '../../../../../Icons/aadhar_Img.svg'
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from "react-helmet";
import axios from 'axios';
import Base64 from 'base-64';
import { useSelector, useDispatch } from 'react-redux';
import { prodbase_url, client_id, client_key, source, react_base_url, verification_source, verified_by, verifier_name } from '../../../../Secret_Credentials/Secret_Credential';
import { useNavigate } from 'react-router-dom';
import { Check_utm_source, encryptAGRID, encryptOnlyLeadId, encryptQueryIdOfferId, encryptQueryIdStepId, gettingerror } from '../../../../EverCalledFunctions/EverCalledFunctions';
import { GenerateToken, setPreApprovedOfferDetails, updateFormState, updateMwApplicationId, updateResidentialAddress, updateStepBar } from '../../../../../Redux/action';
import { getAddressData, getToken } from '../../../../EverCalledFunctions/EverCalledApis';
import CKYCWaiting from '../../../../LoginPopUp/CKYCWaiting';
import queryString from 'query-string';
import AlertIcon from '../../../../../Icons/alert_icon_blue.svg';

const KYCVerification = () => {
  const [id, setId] = React.useState(uuidv4())
  const [state, setState] = React.useState(true);
  const navigate = useNavigate();
  const { loginDetails, mw_application_id, credentialState, resumeJourney, step_bar, formStates, token, preApprovedOfferDetails, residentialAddress } = useSelector(state => state);
  const dispatch = useDispatch();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  React.useEffect(() => {
    dispatch(updateStepBar({ ...step_bar, profile: true, offer: true, verification: true }));

    window.sessionStorage.clear();
    if (token == "") {
      myToken();
    }
  }, [])

  const getAddress = async () => {
    const payLoad = {
      mw_application_id,
      "customer_id": credentialState.cust_id,
      "level_id": 1,
      "product_id": 56,
    }

    const APIDATA = await getAddressData(payLoad, "")
    console.log(APIDATA);
    if (APIDATA.status === "1" && APIDATA.status_code === "200") {
      if (APIDATA.data.address_data[0] && APIDATA.data.address_data[0].address) {
        gettingerror('get address web',mw_application_id)
        dispatch(updateResidentialAddress({...residentialAddress, 
          address_line1: APIDATA.data.address_data[0].address_line1,
          address_line2: APIDATA.data.address_data[0].address_line2,
          landmark: APIDATA.data.address_data[0].landmark,
          cityname: APIDATA.data.address_data[0].cityname,
          pincode: APIDATA.data.address_data[0].pincode}))
        setState(false);
        dispatch(updateFormState({ ...formStates, KycVerification: false, ResidentialAddress: true, FinalOffer: false }));
      }
      else {
        setState(false);
      }
    }
  }

  React.useEffect(() => {
    let retry = credentialState?.pretry == 1 ? true :false
    if (token !== "" && !retry) {
      getAddress();
    }else{      
      setState(false);
    }
  }, [token])

  const utmCheckFunction = () =>{
    let check = Check_utm_source()
    if(credentialState?.pretry == 1){
      if(check && check.length !=0 ){
        check += "&pretry=TVE9PQ=="
      }else{
        check = "pretry=TVE9PQ=="
      }
    }
    return check
  }


  const handleOKYC = async () => {
    gettingerror('choose okyc web',mw_application_id)
    window.sessionStorage.setItem("cust_id", credentialState.cust_id);
    const qry = encryptQueryIdStepId(mw_application_id, 1);
    let checkCondition = utmCheckFunction();
    if(checkCondition && checkCondition.length !=0 ){
      window.location.href = `${react_base_url}vkyc/?fid=${qry}${checkCondition}`;                
     }
      else{
         window.location.href = `${react_base_url}vkyc/?fid=${qry}`;
      }


  }

  const handleDigilocker = () => {
    gettingerror('choose digilocker web',mw_application_id)
    window.sessionStorage.setItem("cust_id", credentialState.cust_id);
    const qry = encryptQueryIdStepId(mw_application_id, 3);
    let checkCondition = utmCheckFunction();
    if(checkCondition && checkCondition.length !=0 ){
      window.location.href = `${react_base_url}vkyc/?fid=${qry}${checkCondition}`;                
     }
      else{
         window.location.href = `${react_base_url}vkyc/?fid=${qry}`;
      }


  }

  return (
    <>
      {state ? < CKYCWaiting heading={"We are redirecting"} /> :
        <div style={{ paddingBottom: "8%" }}>
          <FormHead formName="KYC Verification" />
          <div className="alert-in-kyc">
            <img src={AlertIcon} alt='alerticon'/>
            <p>Your KYC Verification has been initiated. Keep documents like Aadhar and PAN handy to speed up the process.</p>
          </div>
          <div className="boxing remove-bottom" onClick={handleDigilocker} >
            <div className="KYC-img"><img src={DigiLocker} alt="" /></div>
            <span className='heading-KYC'>DigiLocker KYC</span>
            <p className='KYC-content'>Verify by sharing your Aadhar details directly from DigiLocker.</p>
            <div className='KYC-btn'><button className='pointer-cur'>Verify Now<i style={{ borderColor: "#525660" }} className={"arrow left"} /></button></div>
          </div>
          <div className="boxing Tmargin" onClick={() => handleOKYC()}>
            <div className="KYC-img"><img src={aadhar} alt="" /></div>
            <span className='heading-KYC'>Paperless Aadhaar Verification</span>
            <p className='KYC-content'>Paperless offline Aadhaar is a secure and shareable document that can be used to verify KYC details.</p>
            <div className='KYC-btn'><button className='pointer-cur'>Verify Now<i style={{ borderColor: "#525660" }} className={"arrow left"} /></button></div>
          </div>
        </div>
      }
    </>
  )
}

export default KYCVerification;