import React from 'react'
import '../PersonalLoan/PersonalLoan.css';
import EligibilityCriteria from '../PersonalLoan/images/eligibility-criteria.webp'
import greenticknew from '../../Icons/greenticknew.svg';


const EligibilityCriteriaSection = ({ elgdata }) => {

    return (
        <>
            <section className="eligibility-criteria">
                <div className="ploan-container">
                    <div className="eligibale-area">
                        <div className="eligibility-criteria-box">
                            {elgdata?.heading?.length !== 0 && elgdata?.heading?.map((el, index) => {
                                return <h2 key={index} className="heading-h2 underline-heading">{el.left} <strong> {el.right}</strong></h2>
                            })}
                            <div className="ploan-row-criteria pl-gap">
                                <div className="colm-1">
                                    <img src={EligibilityCriteria} alt="Not Found" loading="lazy" />
                                </div>
                                <div className="colm-2">
                                    <div class="mlc-pl-list mlc-pl-mrgin-top">
                                        {elgdata?.subhead?.length !== 0 && elgdata?.subhead?.map((el, index) => {

                                            return <div key={index} class="mlc-pl-list-cont wd-font">
                                                <img src={greenticknew} alt="tick" loading="lazy" />
                                                <span>{el.head && <strong>{el.head} : </strong>}{el?.content}</span>
                                            </div>

                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EligibilityCriteriaSection