import React, { useState } from 'react';
import './RightSidePopUp.css';
import Clipboard from '../../Icons/Clipboard.webp'
// import { Link } from 'react-router-dom'
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setHeaderNavigation } from '../../Redux/action';

const RightSidePopUp = () => {
    const dispatch = useDispatch();
    const { header } = useSelector(state => state);
    const [showRightBox, setShowRightBox] = useState(false)

    const handleClick = () => {
        setShowRightBox(false);
        dispatch( setHeaderNavigation({
            home: true,
            pl: true,
            sr: true,
            cu: true
        }) )
    }

    useEffect(() => {
        if (showRightBox) {
            if (window.screen.width < 500) {
                document.getElementById('close').style.width = "320px";
                document.getElementById('close').style.right = "0";
            } else {
                document.getElementById('close').style.width = "320px";
                document.getElementById('close').style.right = "24px";
            }
        } else {
            document.getElementById('close').style.width = "0px";
            document.getElementById('close').style.right = "0";
        }
        document.querySelector(".open-get-loan-btn").addEventListener("click",()=>{
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        })
    }, [showRightBox])
    const handleSidePopUp = () => {
        setShowRightBox(!showRightBox)

    }
   
    return (
        <div className={showRightBox ? 'loaderbackground-right' : ""}>
            <div className={showRightBox ? "loadertransparent-righ" : ""}>
                <div className='right-side-popUp' id="close">
                    <div className="close-loan-option">
                        {showRightBox ? <div className="get-loan" style={{ left: '-59.5px' }} onClick={() => handleSidePopUp()} >Close<i className={'arrow-btn right-arr'}></i></div>
                            :
                            <div className="get-loan" style={{ left: '-72.5px' }} onClick={() => handleSidePopUp()} >Get loan<i className={'arrow-btn left-arr'}></i></div>}
                    </div>
                    <div className="open-get-loan" >
                        <span className="open-get-loan-heading">Apply for Instant Personal Loan</span>
                        <div className="get-loan-displaying-row">
                            <div className="left-content-1">
                                <ul>
                                    <li>Same Day Disbursement</li>
                                    <li>No Document Upload</li>
                                </ul>
                                <div className="open-get-loan-btn get_loan_apply_button" style={{ marginTop: "20px" }}><a href={`/personal-loan${window.location.search}`} className={header.pl ? "get_loan_apply_button" : "activated-head get_loan_apply_button"} onClick={() => handleClick()}>Apply Now</a></div>
                            </div>
                            <div className="right-popUp-image">
                                <img src={Clipboard} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RightSidePopUp
