import React, { useState } from "react";
import "../Form.css";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  updateStepBar,
  updateFormState,
  GenerateToken,
} from "../../../../Redux/action";
import phone from "./../../../../Icons/phone.svg";
import familyPlus from "./../../../../Icons/familyPlus.svg";
import FormHead from "../FormHead";
import {
  react_base_url,
} from "../../../Secret_Credentials/Secret_Credential";
import Lottie from "react-lottie-player";
import ctaAnimation from "../../../assests/lootie-files/continue.json";
import { useEffect } from "react";
import {
  encryptAGRID,
  encryptLeadIdStepId,
  encryptOnlyLeadId,
  encryptQueryIdStepId,
  gettingerror,
  RelocateUTMCheck,
} from "../../../EverCalledFunctions/EverCalledFunctions";
import {
  ReferenceDetailsAPI, getToken
} from "../../../EverCalledFunctions/EverCalledApis";
import Base64 from "base-64";
// import { AxiosThirty } from "../../../AxiosConfiguration/AxiosConfig";
import NewErrorCase from "./NewErrorCase";
import { FetchCustomerPayload } from "../../../EverCalledFunctions/EverCalledPayload";
import { apiInstance } from "../../../Config/APIConfig";
import CKYCWaiting from "../../../LoginPopUp/CKYCWaiting";

const ReferenceDetails = () => {
  const [numberErr, setNumberErr] = useState(false);
  const [colleagueSame, setColleagueSame] = useState(false);
  const [relativeSame, setRelativeSame] = useState(false);
  const [loginNumber, setLoginNumber] = useState("");
  const [resp, setResp] = useState(false);
  const [qryStr, setQryStr] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [bankVerified, setBankVerified] = useState(false);
  const [rocket, setRocket] = useState(false);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const {
    step_bar,
    mw_application_id,
    credentialState,
    formStates,
    token
  } = useSelector((state) => state);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      relativeName: "",
      relativeNumber: "",
      colleagueName: "",
      colleagueNumber: "",
    },
    mode: "all",
  });

  const data = watch();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, [])

  const NumberOnly = (value) => {
    var lastseven = value.substr(value.length - 7);
    var num_charstring_number = value.substr(4, 4);
    var last_digit = value.slice(0, 2);
    var cr_str = Array(6).join(last_digit);
    var result = false;
    if (/^[6789]\d{9}$/i.test(value)) {
      result = true;
    }
    if (value === '9876543210') {
      result = false;
    }
    if (/^(\d)\1+$/.test(lastseven)) {
      result = false;
    }
    if (value === cr_str) {
      result = false;
    }
    if (num_charstring_number == 'XXXX') {
      result = true;
    }
    if (/(\d)\1{6,}/.test(value)) {
      result = false;
    }
    return result;

  }

  useEffect(() => {
    window.sessionStorage.clear();
    dispatch(
      updateStepBar({
        ...step_bar,
        profile: true,
        offer: true,
        verification: true,
      })
    );
    // GetPreData();
    const qry = encryptOnlyLeadId(mw_application_id);
    setQryStr(qry);
    setTimeout(() => {
      setLoader(false);
    }, 8000);
  }, []);

  const getUserData = async () => {
    let decFetch = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 21), {
      token
    })
    // .then((res) => {
    if ((decFetch.status === "1") && (decFetch.status_code === "200")) {
      if (decFetch.data.thank_you_flag == 1) {
        gettingerror("get thank you flag", mw_application_id)
        if (decFetch.data.thank_you_url !== "") {
          window.location.href = decFetch.data.thank_you_url;
          return
        }
        else {
          let qry = Base64.encode(`${mw_application_id}`);
          qry = Base64.encode(qry);
          RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=18`)
          return
        }

      }
      if (decFetch.data.cust_phone_no) {
        setLoginNumber(decFetch.data.cust_phone_no);
      }
      if (decFetch.data.is_account_ok == 0) {
        setBankVerified(true);
      }
      if (decFetch?.data?.email_verified) {
        if (decFetch.data.email_verified.toString() === "1") {
          setEmailVerified(true);
        }
      }
      if ((decFetch?.data?.lead_source_flag) && (decFetch?.data?.lead_source_flag == 1)) {
        if (decFetch.data.is_account_ok == 0) {
          dispatch(
            updateFormState({
              ...formStates,
              ReferenceDetails: false,
              BankDetails: true
            })
          );
        }
        else if (decFetch.data.email_verified.toString() === "1") {
          let qry = encryptAGRID(mw_application_id);
          RelocateUTMCheck(`${react_base_url}loan-agreement/?agrid=${qry}`)
        }
        else {
          const qry = encryptLeadIdStepId(mw_application_id, 23);
          RelocateUTMCheck(`${react_base_url}personal-loan/?fid=${qry}`)
        }

      }
    }
    else {
      dispatch(
        updateFormState({ ...formStates, error: true })
      );
    }
  }

  useEffect(() => {
    if (token !== "") {
      getUserData()
    }
  }, [token])


  const onSubmit = async (result, e) => {
    setResp(true);
    const payLoad = {
      "mw_application_id": mw_application_id,
      "customer_id": credentialState.cust_id,
      "reference_name1": result.relativeName,
      "reference_name2": result.colleagueName,
      "reference_mobile1": result.relativeNumber,
      "reference_mobile2": result.colleagueNumber,
    };

    const APIDATA = await ReferenceDetailsAPI(payLoad, "");
    if ((APIDATA.status_code === "200") && (APIDATA.status === "1")) {
      gettingerror('reference details submit web', mw_application_id)
      if(APIDATA.data["banking_req"] && APIDATA.data["banking_req"] == 1){
        let qryStr = encryptOnlyLeadId(mw_application_id);
          // RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
          window.location.href = `${react_base_url}analyzer/?fid=${qryStr}`
      }
     else if (APIDATA.data.fraud_check_ok == "0") {
        dispatch(
          updateFormState({
            ...formStates,
            HunterHold: true,
            ReferenceDetails: false,
          })
        );
      } else if ((APIDATA.data.fraud_check_ok == "1") && (APIDATA.data.vkyc_status == "1")) {
        window.sessionStorage.setItem("cust_id", credentialState.cust_id);
        const qry = encryptQueryIdStepId(mw_application_id, 2);
        RelocateUTMCheck(`${react_base_url}vkyc/?fid=${qry}`)
      }
      else if (bankVerified) {
        gettingerror('bank open web', mw_application_id)
        dispatch(
          updateFormState({
            ...formStates,
            ReferenceDetails: false,
            BankDetails: true
          })
        );
      }
      else if (emailVerified) {
        gettingerror('email open web', mw_application_id)
        let qry = encryptAGRID(mw_application_id);
        RelocateUTMCheck(`${react_base_url}loan-agreement/?agrid=${qry}`)
      }
      else {
        const qry = encryptLeadIdStepId(mw_application_id, 23);
        RelocateUTMCheck(`${react_base_url}personal-loan/?fid=${qry}`)
      }
    }
    else if ((APIDATA.status_code == "429") && (APIDATA.status == "0")) {

      dispatch(
        updateFormState({
          ...formStates,
          HunterHold: true,
          ReferenceDetails: false,
        })
      );
    }
    else {
      dispatch(updateFormState({ ...formStates, error: true }));
      setResp(false);
    }
  };

  useEffect(() => {
    if (data.colleagueNumber.length === 10 || data.relativeNumber.length === 10) {
      if (data.colleagueNumber == loginNumber) {
        setColleagueSame(true);
      } else {
        setColleagueSame(false);
      }
      if (data.relativeNumber == loginNumber) {
        setRelativeSame(true);
      } else {
        setRelativeSame(false);
      }
      if (
        data.colleagueNumber === data.relativeNumber && !colleagueSame
      ) {
        setNumberErr(true);
      } else {
        setNumberErr(false);
      }
    }
  }, [data]);

  const handleUpKeyNumber = (value, field) => {
    var regexp = /[^0-9]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""));
    }
  };

  const SpaceManage = (value, field) => {
    setValue(field, value.replace(/^\s+/, ''));
  };
  const Blured = (field, value) => {
    // var newVal = value.trimEnd();
    var newVal = value.replace(/\s+$/, '');
    setValue(field, newVal);
    if (newVal.length < 3) {
      setError(field);
    } else {
      clearErrors([field]);
    }
  };

  const handleAlphaOnly = (value, field) => {
    var regexp = /[^a-zA-Z ]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""));
    } else {
      setValue(field, value.replace(/^\s+/, ''));
    }
  };

  const handleError = (e) => {
    window.location.reload();
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      {/* <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
      </Helmet> */}
      {loader && <CKYCWaiting />}
      <FormHead
        formName="Reference Details"
        formSubHead={
          "Provide details of your personal references for loan verification"
        }
      />

      <div className="tag-for-input">
        {/* -------------------------------------------Form Field----------------------------------------- */}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          <span className="form-sub-heading">Family / Relative</span>
          {/* -------------------------------------Relative Name Field----------------------------------------- */}
          <div
            className={
              errors.relativeName
                ? "input-tag manage-top error-border remove-bottom"
                : data.relativeName
                  ? "input-tag manage-top add-border remove-bottom"
                  : "input-tag manage-top remove-bottom"
            }
          >
            <img className="tag-icon" src={familyPlus} alt=""/>
            <input
              disabled={resp}
              type="text"
              placeholder="Family / Relative Name"
              {...register("relativeName", { required: true, minLength: 3 })}
              autoComplete="off"
              onKeyUp={() => handleAlphaOnly(data.relativeName, "relativeName")}
              onBlur={() => Blured("relativeName", data.relativeName)}
            />
            {/* {errors.relativeName && <img src={ThisIsAnError} alt="" id="pointer-cur" />} */}
          </div>
          {errors.relativeName && (
            <div className="err-text">Enter At least 3 Characters</div>
          )}

          {/* ---------------------------------------Relative Number Field------------------------------------ */}
          <div
            className={
              errors.relativeNumber || relativeSame
                ? "input-tag error-border remove-bottom"
                : data.relativeNumber
                  ? "input-tag add-border remove-bottom"
                  : "input-tag  remove-bottom"
            }
          >
            <img className="tag-icon" src={phone} alt=""/>
            <input
              disabled={resp}
              type="tel"
              placeholder="Contact Number"
              minLength={10}
              maxLength={10}
              {...register("relativeNumber", {
                required: true,
                minLength: 10,
                maxLength: 10,
                validate: NumberOnly,
                pattern: { value: /^[6-9]\d{9}$/gi },
              })}
              onKeyUp={() =>
                handleUpKeyNumber(data.relativeNumber, "relativeNumber")
              }
              autoComplete="off"
            />
            {/* {errors.relativeNumber && <img src={ThisIsAnError} alt="" />} */}
          </div>
          {errors.relativeNumber &&
            <div className="err-text">Invalid Number</div>
          }
          {relativeSame && data.relativeNumber &&
            <div className="err-text">
              Enter a different number then registered number
            </div>
          }

          <div className="form-sub-heading tag-for-input">
            Colleague / Friend
          </div>
          {/* -----------------------------------------Colleague Name Field----------------------------------- */}
          <div
            className={
              errors.colleagueName
                ? "input-tag manage-top error-border remove-bottom"
                : data.colleagueName
                  ? "input-tag manage-top add-border remove-bottom"
                  : "input-tag manage-top remove-bottom"
            }
          >
            <img className="tag-icon" src={familyPlus} alt=""/>
            <input
              disabled={resp}
              className="input-tag-box"
              type="text"
              placeholder="Colleague / Friend Name"
              {...register("colleagueName", { required: true, minLength: 3 })}
              autoComplete="off"
              onKeyUp={() =>
                handleAlphaOnly(data.colleagueName, "colleagueName")
              }
              onBlur={() => Blured("colleagueName", data.colleagueName)}
            />
            {/* {errors.colleagueName && <img className="error-img-show" src={ThisIsAnError} alt="" />} */}
          </div>
          {errors.colleagueName && (
            <div className="err-text">Enter At least 3 Characters</div>
          )}
          { }

          {/* -----------------------------------------colleague Number Field--------------------------------- */}
          <div
            className={
              errors.colleagueNumber || numberErr || colleagueSame
                ? "input-tag error-border remove-bottom"
                : data.colleagueNumber
                  ? "input-tag add-border remove-bottom"
                  : "input-tag remove-bottom "
            }
          >
            <img className="tag-icon" src={phone} alt=""/>
            <input
              disabled={resp}
              type="tel"
              placeholder="Contact Number"
              minLength={10}
              maxLength={10}
              {...register("colleagueNumber", {
                required: true,
                minLength: 10,
                maxLength: 10,
                validate: NumberOnly,
                pattern: { value: /^[6-9]\d{9}$/gi },
              })}
              autoComplete="off"
              onKeyUp={() =>
                handleUpKeyNumber(data.colleagueNumber, "colleagueNumber")
              }
            />
            {/* {errors.colleagueNumber && <img src={ThisIsAnError} alt="" />}   */}
          </div>

          {errors.colleagueNumber && (
            <div className="err-text">Invalid Number</div>
          )}
          {numberErr && !errors.colleagueNumber && (
            <div className="err-text">
              Same Number Entered in Both References.
            </div>
          )}
          {colleagueSame && data.colleagueNumber &&
            <div className="err-text">
              Enter a different number then registered number
            </div>
          }

          {/* -------------------------------------------Submit button----------------------------------------- */}
          <button
            type="submit"
            disabled={resp || numberErr || relativeSame || colleagueSame}
            className={
              "footer-btn Tmargin Bmargin" +
              (Object.keys(errors).length === 0 &&
                data.relativeName &&
                data.relativeNumber &&
                data.colleagueName &&
                data.colleagueNumber &&
                !numberErr &&
                !colleagueSame &&
                !relativeSame
                ? " footer-btn-activate"
                : "")
            }
          >
            {!resp ? (
              "Continue"
            ) : (
              <div className="continue-lottie">
                <Lottie
                  loop
                  animationData={ctaAnimation}
                  play
                  className="ctn-loader"
                />
              </div>
            )}
          </button>
        </form>
      </div>
    </>
  );
};

export default ReferenceDetails;
