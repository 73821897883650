import React from "react";
import queryString from 'query-string';
import Base64 from 'base-64';
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";

function PartnerRedirect () {

    React.useEffect(() => {
        let qry = queryString.parse(window.location.search);
        if(qry.pat_id) {
            let red_url = Base64.decode(qry.pat_id);
            window.location.href = red_url;
        }
    },[])
    return(
        <div>
            <CKYCWaiting heading={"we are initiating your enach process."} />
        </div>
    )
}

export default PartnerRedirect;