import React, { useEffect, useState } from 'react';
import Base64ToPdf from './Base64ToPdf';
import download from '../../Icons/download.svg'
import './LegalNotice.css'
import queryString from "query-string";
import { react_base_url } from '../Secret_Credentials/Secret_Credential';
import Base64 from "base-64";
import { NoticeAPi } from '../EverCalledFunctions/EverCalledApis';
import { useSelector } from 'react-redux'
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';
import { DownloadPDFFunc } from '../MyAccount/MyAccountAPIs';

const LegalNotice = () => {
    let urlParamteres = queryString.parse(window.location.search);
    const { token } = useSelector(state => state)
    const [state, setState] = useState({ loader: true, error: false, data: "", cid: "", doc: "" })

    if (!urlParamteres?.cid || !urlParamteres?.doc) {
        window.location.href = `${react_base_url}`
    }

    const callNoticeApi = async (data) => {
        const response = await NoticeAPi(data, token)
        if (response.status_code == "200" && response.status == "1") {
            setState({ ...state, data: response.data.pdfLink, loader: false, error: false, cid: data.id, doc: data.doc_id })
        } else {
            setState({ ...state, data: "", loader: false, error: true, cid: data.id, doc: data.doc_id })
        }
    }

    useEffect(() => {
        let id = ""
        let document_id = ""
        if (!urlParamteres?.cid || !urlParamteres?.doc) {
            window.location.href = `${react_base_url}`
        } else {
            try {
                id = Base64.decode(urlParamteres?.cid)
                id = Base64.decode(id)
                document_id = Base64.decode(urlParamteres?.doc)
                document_id = Base64.decode(document_id)
                callNoticeApi({ id, doc_id: document_id })
            } catch (err) {
                setState({ ...state, data: "", loader: false, error: true, cid: "", doc: "" })
            }
        }
    }, [])
    const Download = () => {
        DownloadPDFFunc(state.data, "legal-notice")
    }
    const reloadPage = () => {
        window.location.reload()
    }
    return (
        <div className='pdf-back'>
            {state.error ? <NewErrorCase handleClick={() => reloadPage()} /> :
                (state.loader ? <CKYCWaiting /> : <>
                    <div className="pdf-header">
                        <div></div>
                        <h1>Legal Notice</h1>
                        <button onClick={() => Download()}><img src={download} alt=''/></button>
                    </div>
                    <div className='pdf-box'>
                        <Base64ToPdf base64pdf={state.data} />
                    </div>
                </>)}

        </div>
    )
}

export default LegalNotice;