import React, { useEffect } from 'react';
import FormHead from '../FormHead';
import './../Form.css';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../../../assests/lootie-files/continue.json';
import phone from "../../../../Icons/phone.svg";
import { prodbase_url, source, verification_source, verified_by, verifier_name } from '../../../Secret_Credentials/Secret_Credential';
import { useDispatch, useSelector } from 'react-redux';
//import { getToken } from '../../../EverCalledFunctions/EverCalledApis';
import { GenerateToken, updateCkycDetails, updateDecisionDemog, updateFormState, updateLoginDetails, updatePartnerState, updateservicecredential, updateservicelogin} from '../../../../Redux/action';
import { gettingerror } from '../../../EverCalledFunctions/EverCalledFunctions';
import { DecisionDemogOffer } from '../../../EverCalledFunctions/EverCalledPayload';
import AlertIcon from '../../../../Icons/alert_icon_blue.svg';
import { apiInstance } from '../../../Config/APIConfig';
import { SendOTP } from '../../../EverCalledFunctions/EverCalledApis';

const NumberVerification = () => {
    const [resp, setResp] = React.useState(false);
    const { credentialState, mw_application_id, token, loginDetails, decisionDemog, formStates, ckycDetails, partnerStates,serviceCred,serviceNumber  } = useSelector(state => state);
    const [Phone, setPhone] = React.useState("");
    const [error, setError] = React.useState(false);
    const [rocket, setRocket] = React.useState(false);
    const [checked, setChecked] = React.useState(true);
    const dispatch = useDispatch();

    const validationCheck = (compare_num) => {
        let flag = false
        for (let i = 0; i < loginDetails.equifax_phone_no.length; i++) {
            let phone_no = loginDetails.equifax_phone_no[i];
            if(phone_no.includes("x")) {
                phone_no = phone_no.replaceAll("x", "");
            }
            if(phone_no.includes("X")) {
                phone_no = phone_no.replaceAll("X", "");
            }
            if(phone_no.length >= compare_num.length) {
                if (phone_no === compare_num) {
                    flag = true;
                    return flag
                }
                else if(phone_no.length > compare_num.length) {
                    let len = phone_no.length - compare_num.length;
                    phone_no = phone_no.substring(len, (len + compare_num.length));
                    if (phone_no === compare_num) {
                        flag = true;
                        return flag
                    }
                }
            }
            else {
                let len = Math.abs(compare_num.length - phone_no.length);
                compare_num = compare_num.substring(len, (len + phone_no.length))
                if (phone_no === compare_num) {
                    flag = true;
                    return flag
                }
            }
            
        }
        return flag;
    }

    // const condition = ((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1));

    // let endpoint = "";
    // let checkType = "";

    const handleChange = (e) => {
        if (loginDetails?.journeyAPI == 2) {
            let text = e.target.value
            text = text.replace(/[^0-9]/g, '');
            const hasSixConsecutiveSameDigits = /(.)\1{5}/.test(text);
            if (text.length <= 10) {
                if (text.length === 10 || /^[0-5]/.test(text) || text !== "9876543210" || hasSixConsecutiveSameDigits) {
                    setPhone(text);
                }
            }
            if (text.length != 0) {
                setError(text.length !== 10 || /^[0-5]/.test(text) || text === "9876543210" || hasSixConsecutiveSameDigits);
            }
            else {
                setError(false);
            }
        }
        else{
            let regexp = /[^0-9]/g;
            if ((!e.target.value.match(regexp)) && (Phone.length <= 9)) {
                setPhone(e.target.value);
            }
        }
    }

    const handleKeyUp = (e) => {
        if ((e.keyCode === 8) && (Phone.length === 10)) {
            setPhone(Phone.substring(0, 9))
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])
    

    React.useEffect(() => {
        if (loginDetails?.journeyAPI == 2) {

        }
        else{
            if (Phone.length > 5) {
                let phone_substr = Phone.substring(5);
                let flag = validationCheck(phone_substr);
                if (!flag) {
                    setError(true);
                }
                else {
                    setError(false);
                }
            }
            else {
                setError(false);
            }
        }
    }, [Phone])

    // const handleSubmit = async () => {
    //     setResp(true);
    //     let dec = await apiInstance.post('equifax-phone-submit', {
    //         "source": source,
    //         "mw_application_id": mw_application_id,
    //         "customer_id": credentialState.cust_id,
    //         "equifax_mobile_no": Phone,
    //         "verified_by": verified_by,
    //         "verifier_name": verifier_name,
    //         "verification_source": verification_source
    //     }, {
    //         token
    //     })
    //     // .then((res) => {
    //         if ((dec.status == "1") && (dec.status_code == "200")) {
    //             let payload={
    //                 "customer_id": credentialState.cust_id,
    //                 "product_id": 56,
    //                 "gender":
    //                     loginDetails.gender === "Male"
    //                         ? 1
    //                         : loginDetails.gender === "Female"
    //                             ? 2
    //                             : 3,
    //                 "username": loginDetails.name,
    //                 "dob": loginDetails.dob,
    //                 "mw_application_id": mw_application_id,
    //                 "mobile_no": Phone,
    //                 "pincode": loginDetails.pin.toString(),
    //                 "city_id": decisionDemog.city_id ? decisionDemog.city_id : "",
    //                 "pancard": loginDetails.pan,
    //                 "email": loginDetails.email,
    //                 "source": source,
    //                 "verification_source": verification_source,
    //                 "verified_by": verified_by,
    //                 "verifier_name": verifier_name
    //             }
    //             const decDecisionDemog= await apiInstance.post('decision-demog-api',payload,{token})
                
    //                     if ((decDecisionDemog.status == 1) && (decDecisionDemog.status_code == 200)) {
    //                         if (decDecisionDemog.data && decDecisionDemog.data.Response && decDecisionDemog.data.Response.BUREAU && (decDecisionDemog.data.Response.BUREAU.is_phone_update_required != 2) && (decDecisionDemog.data.Response.BUREAU.is_phone_update_required != 1)) {
    //                             gettingerror('hit demog web', mw_application_id)
    //                                 dispatch(updateDecisionDemog({ ...decisionDemog, is_ntc: decDecisionDemog.data.Response.BUREAU.is_ntc_revised, empverification: decDecisionDemog.data.Response.empverification, pan_status: decDecisionDemog.data.Response.pan_status }));
    //                             if (((decDecisionDemog.data.Response.BUREAU.is_ntc_revised == 1) && (decDecisionDemog.data.Response.BUREAU.is_ntc == 1)) || ((decDecisionDemog.data.Response.BUREAU.is_ntc_revised == 0) && (decDecisionDemog.data.Response.BUREAU.is_ntc == 1))) {
    //                                     if (condition) {
    //                                         endpoint =  decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
    //                                         checkType = "CIBIL Check";
    //                                     }
    //                                     else {
    //                                         endpoint = "offer-eligibility";
    //                                         checkType = "CIBIL Check";
    //                                     }
    //                             }
    //                             else if ((decDecisionDemog.data.Response.BUREAU.is_ntc_revised == 1) && (decDecisionDemog.data.Response.BUREAU.is_ntc == 0)) {
    //                                     if (condition) {
    //                                         endpoint =  decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
    //                                         checkType = "CIBIL Check NTC";
    //                                     }
    //                                     else {
    //                                         endpoint = "offer-eligibility";
    //                                         checkType = "CIBIL Check NTC";
    //                                     }
    //                             }
    //                             else if ((decDecisionDemog.data.Response.BUREAU.is_ntc_revised == 0) && (decDecisionDemog.data.Response.BUREAU.is_ntc == 0)) {
    //                                     if (condition) {
    //                                         endpoint =  decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
    //                                         checkType = "Soft Bureau Check";
    //                                     }
    //                                     else {
    //                                         endpoint = "offer-eligibility";
    //                                         checkType = "Soft Bureau Check";
    //                                     }
    //                             }

    //                             ///API HIT///
    //                             setTimeout(async ()=>{
    //                                 let decOffer = await apiInstance.post(endpoint, DecisionDemogOffer(mw_application_id, checkType, credentialState.cust_id), {
    //                                     token
    //                                 })
    //                                 if ((decOffer.status == 1) && (decOffer.status_code == 200)) {
    //                                         dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOffer.data.cust_eligible }));
    //                                         gettingerror(checkType, mw_application_id)
    //                                             }
    //                             },10000)
                                
    //                         }


    //                     }
    //                     let pathname = window.location.pathname;
    //                     if (pathname.includes("/lp")) {
    //                         dispatch(updatePartnerState({ ...partnerStates, numberVerification: false, PartnerEmployer: true }))
    //                     }
    //                     else {
    //                         dispatch(updateFormState({ ...formStates, numberVerification: false, EmployeeDetails: true }))
    //                     }
    //                 // }).catch((err) => {
    //                 //     setRocket(true);
    //                 // });
    //         }
    //     // })
    // }

    const handleSubmit = async () => {
        setResp(true);
        dispatch(updateservicelogin({ ...serviceNumber, mobile: Phone }))
        const pay = {
            "mobile": Phone,
            "api_mode": "LIVE",
            "login_from": loginDetails?.journeyAPI == 2 ? 14 : 11,
            "verification_source": verification_source,
            "verified_by": verified_by,
            "verifier_name": verifier_name,
            "source": source
        }
        let res = await SendOTP(pay);
        // console.log(res);
        if (res.status == "1" && res.status_code == "200") {
            if (res.data.otp_id) {
                dispatch(updateservicecredential({ ...serviceCred, Otp_id: res.data.otp_id }))
                let url = window.location.pathname;
                if(url.includes('/lp')){
                    dispatch(updatePartnerState({ ...partnerStates, numberVerification: false, Partnerverification: true }))
                }
                else{
                    if(loginDetails.journeyAPI == 2){
                        dispatch(updateFormState({ ...formStates, numberVerification: false, UpiJourneyOTP: true }))
                    }
                    else{
                    dispatch(updateFormState({ ...formStates, numberVerification: false,numberVerificationotp : true }))
                    }
                }
                
            }
        }

    }

    const handleCheckBox = () => {
        if (!resp) {
          setChecked(!checked)
        }
      }

    return (
        <div className='numberVerification-main'>
            <FormHead formName="Profile" formSubHead="Complete your profile to know loan eligibility" />
            <div className='numberVerification-head'>
                <div style={{ padding: "12px 10px" }}>
                    <img src={AlertIcon} alt="AlertIcon" />
                </div>
                <div>
                <p>{loginDetails?.journeyAPI == 2 ? "Please share an alternate number that you use as we are unable to identify you with your current mobile number." : "we are not able to identify you with this mobile number. Is there an alternate mobile number that you use ?"}</p>
                </div>
            </div>
            <div style={{height: "25px"}}>
                <div className={error ? 'numberVerification-body numberVerification-body-error-border' : "numberVerification-body"}>
                    <img src={phone} alt="phone-img" />
                    <input type="text" onKeyUp={(e) => handleKeyUp(e)} onChange={(e) => handleChange(e)} value={Phone} placeholder='Alternate Mobile Number' maxLength={10}/>
                </div>
                {error ? <div className='numberVerification-body-error'>
                <p>Not Identified By this Number</p>
                </div> : ""}
            </div>
            <p className="login-form-content-under-whatsapp" onClick={() => handleCheckBox()}>
              <div className="login-whatsapp-check">
                <div><input type="checkbox" checked={checked} readOnly /></div>
                <div className="login-auth-text">
                  I authorize MoneyWide to verify my PAN, mobile no., contact via call/SMS/WhatsApp/Email &amp; access my credit report. I agree to the terms of the {" "}
                  <a href="/disclaimer" className="TandC-link" target={"_blank"}>
                    User Consent Form
                  </a>{", "}
                  <a href="/policies" className="TandC-link" target={"_blank"}>
                    Privacy Policy
                  </a>{" & "}
                  <a href="/terms-and-conditions" className="TandC-link" target={"_blank"}>
                    Terms and Conditions
                  </a>
                  .
                </div>
              </div>
            </p>
            <div className='numberVerification-footer'>
                <button type='submit' disabled={error || Phone.length < 10 || !checked} onClick={() => handleSubmit()} className={(!error && Phone.length >= 10 && checked) ? 'footer-btn Tmargin footer-btn-activate Bmargin' : "footer-btn Tmargin Bmargin"}>
                    {!resp ?
                        "Get OTP" :
                        <div className="continue-lottie">
                            <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                        </div>}
                </button>
            </div>
        </div>
    )
}

export default NumberVerification
