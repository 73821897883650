import React, { useEffect } from "react";
import './LoanPolicy.css';
import Cancellation from "../Cancellationpolicy/Cancellation";
import Privacypolicy from "../PrivacyPolicy/Privacypolicy";
import TermsAndCondition from "../TermsandCondition/TermsAndCondition";
import PolicyMenuAll from "./PolicyMenuAll";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
const AllPolicy = () => {
  const [openSection, setOpenSection] = React.useState(0);
  const { App_status } = useSelector((state) => state);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const app = searchParams.get("app");

  useEffect(() => {
    window.scrollTo(0, 0);
    // const qry = window.location.href;
    const { pathname } = location;
    if (pathname === "/cancellation-and-refund-policy") {
      setOpenSection(0);
    } else if (pathname === "/privacy-policy") {
      setOpenSection(1);
    } else if (pathname === "/terms-and-conditions") {
      setOpenSection(2);
    }
    if(app){
      if (pathname === "/cancellation-and-refund-policy/") {
        setOpenSection(0);
      } else if (pathname === "/privacy-policy/") {
        setOpenSection(1);
      } else if (pathname === "/terms-and-conditions/") {
        setOpenSection(2);
      }
    }
  }, [location]);

  return (
    <>
    <div style={{backgroundColor:"#FAF9FF",paddingBottom:"50px"}}>
    <div className={App_status ? "policy-img-mobile" : "policy-img"}>
        {/* <img src={Banner} /> */}
        <h1 className="policy-head">
          Moneywide <strong>Legal Policies</strong>
        </h1>
      </div>
      <div style={{backgroundColor:"#FAF9FF"}}>
        <div className="policycontDesk">
          {/* <h2>MoneyWide Legal Policies</h2> */}
          <div className="policycontmenu">
            <PolicyMenuAll />
          </div>
          <div className="policycontent-show">
            {openSection == 0 && <Cancellation />}
            {openSection == 1 && <Privacypolicy />}
            {openSection == 2 && <TermsAndCondition />}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AllPolicy;
