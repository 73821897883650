import React, { useState, useEffect } from 'react';
import './Nomine.css';
import '../Journey Form/FormLeftPart/NewLeftPart.css';
import LeftSide from '../../Icons/LeftSide.svg';
import NomineForm from './NomineForm';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import queryString from "query-string";
import Base64 from "base-64";
import NotFound from '../NotFound/NotFound';
import NomineSorry from './NomineSorry';
import NomineeThanku from './NomineeThank';
import { getCustId, getToken } from '../EverCalledFunctions/EverCalledApis';
import { useSelector, useDispatch } from 'react-redux';
import { GenerateToken } from '../../Redux/action';

const Nomine = () => {
    const [status, setStatus] = useState()
    const [loading, setLoading] = useState(true)
    const [redirect, setRedirect] = useState(false)
    const [leadId, setLeadId] = useState("")
    const dispatch = useDispatch()
    const updateStatus = (data) => {
        setStatus(data)
    }

    useEffect(() => {
        setLoading(true)
        const qry = queryString.parse(window.location.search);
        try {
            let decodeData = Base64.decode(qry.fid);
            decodeData = Base64.decode(decodeData)
            // console.log(decodeData);
            if (!decodeData) {
                setRedirect(true)
            } else {
                setRedirect(false)
                setLeadId(decodeData)
                setStatus(1);
            }
        } catch {
            setRedirect(true)
        }
        setLoading(false)
    }, [])

    return (
        <>
            {redirect && <NotFound />}
            {loading && <CKYCWaiting />}
            {!redirect && <div className={"journey-part-mobile nominee"}>
                <div className="journey-content">
                    <div className="left-side">
                        <h2 className="journey-heading">Get your Free Insurance
                            Cover now</h2>
                        <p className="journey-con">MoneyWide offers you Free Insurance Cover on your active Personal Loan. Share a few nominee details to avail the offer.</p>
                        <div className="left-img"><img src={LeftSide} alt="" /></div>
                    </div>
                    <div className="right-side">
                        <div className="form-box">
                            <div className="form-body">
                                {status !== 1 && status !== 2 && <NomineSorry />}
                                {status === 1 && <NomineForm updateStatus={updateStatus} leadId={leadId}/>}
                                {status === 2 && <NomineeThanku msg="Thanks for sharing the nominee details. We'll share the policy over email once enrolled."/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}


export default Nomine;