import React, { useState } from 'react';
import "./ComplainServiceRequest.css";
import '../Journey Form/Form/Form.css';
import FormHead from '../Journey Form/Form/FormHead';
import { useForm } from 'react-hook-form';
import chat from '../../Icons/chat.svg';
import ThisIsAnError from '../../Icons/error.svg';
import DropDownIcon from '../../Icons/DropDownIcon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedServiceRequestQuery, updateserviceshow,updateservicecredential, GenerateToken, updateFormState } from '../../Redux/action';
import { useEffect } from 'react';
import axios from 'axios';
import { prodbase_url,client_id, client_key, source,verification_source,verified_by,verifier_name } from '../Secret_Credentials/Secret_Credential';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../assests/lootie-files/continue.json';
import { getToken } from '../EverCalledFunctions/EverCalledApis';

const SelectedComplainServiceRequest = () => {
    const [resp,setResp] = useState(false)
    const [submit,setSubmit] = useState(false)
    // const [open, setOpen] = useState([false, false, false])
    const { selectedServiceQuery,setService,serviceCred, serviceNumber,token,formStates} = useSelector(state => state)
    const dispatch = useDispatch();
    // const [first,setFirst]=useState([]);
    const [showone,setShowone]=useState(false); 
    // const [second,setSecond]=useState([]);
    const [showtwo,setShowtwo]=useState(false);
    const [firstid,setFirstId]=useState("");
    // const [three,setThree]=useState([]);
    const [showthree,setShowthree]=useState(false);
    const [showfour,setShowfour] = useState(false);
    const [secondid,setSecondId]=useState("");
    const [thirdid,setThirdId]=useState("");
const [dropDown,setDropDown]=useState({
    first:[],
    second:[],
    third:[],
    fourth:[]
})



    const { register, handleSubmit, formState: { errors }, watch, setValue, reset, clearErrors, setError} = useForm({
        defaultValues: {
            complaintType: "",
            subComplaint: "",
            subsubComplaint:"",
            comment: "",
        }
    })

    const myToken = async () => {
      const tkn = await getToken();
      dispatch(GenerateToken(tkn));
    }
  
    React.useEffect(() => {
      if(token == "") {
        myToken();
      }
      window.scrollTo(0,0);
      // knowdevice();
      // getData()
    }, [])

    // console.log(errors)

    const data = watch();

    
  const Blured = (field, value) => {
    // var newVal = value.trimEnd();
    var newVal = value.replace(/\s+$/, '');
    setValue(field, newVal);
    if (newVal.length < 3) {
      setError(field)
    } else {
      clearErrors([field])
    }
  }

  const SpaceManage = (field, value) => {
    setValue(field, value.replace(/^\s+/, ''));
  }

    const onSubmit = data => {
        setResp(true)
        // const pay = {
        //     "client_id": client_id,
        //     "client_key": client_key,
        //     "source": source
        //   }
        //   axios.post(`${prodbase_url}token`,pay).then((res)=>{
        //     let token=res.data.token;
            axios.post(`${prodbase_url}complaint-submission`,{
                "source":"Mwide",
                "phone_no":serviceNumber.mobile,
                "product_id":selectedServiceQuery.value.prod_id,
                "category":firstid,
                "subcategory":secondid,
                "subsubcategory":showfour ? thirdid.toString() : "",
                "relationship_id":serviceCred.customer_type,
                "complaintDetails":data.comment,
                "consent":1,
                "subsubcategory_reason":"xyz",
                "query_id":selectedServiceQuery.queryId,
                verified_by,
                verifier_name,
                verification_source
                },{
                    headers:{
                        token
                    }
                }).then((res)=>{
                  if((res.data.status_code === "200") && (res.data.status === "1")) {
                    dispatch(updateserviceshow({ ...setService, selected: false, thanks: true,thankMessage:res.data.data.message}))
                  }
                  else{
                    dispatch(updateFormState({ ...formStates, error: true }));
                  }
                    // console.log(res);
                })
          // })
    }

    useEffect(()=>{
      if(token !== ""){
        const payload={
          "source": "MWide",
          "type": "Product_complaint",
          "parent_id":0,
          "customer_type":serviceCred.customer_type,
          verified_by,
          verifier_name,
          verification_source
      }
      // const pay = {
      //     "client_id": client_id,
      //     "client_key": client_key,
      //     "source": source
      // }
      // axios.post(`${prodbase_url}token`,pay).then((res)=>{
      //     const token=res.data.token;
    axios.post(`${prodbase_url}cms-dropdown`,payload,{
      headers:{
          token
      }
    }).then((res)=>{  
      if((res.data.status_code === "200") && (res.data.status === "1")){
        setDropDown({...dropDown,second:res.data.data})
      }
      else{
        dispatch(updateFormState({ ...formStates, error: true }));
      }
    }) 
      } 
    },[token])

    useEffect(()=>{
        if(showtwo){
            const payload={
                "source": "MWide",
                "type": "Product_complaint",
                "parent_id":firstid,
                "customer_type":serviceCred.customer_type,
                verified_by,
                verifier_name,
                verification_source
            }
            // const pay = {
            //     "client_id": client_id,
            //     "client_key": client_key,
            //     "source": source
            // }
            // axios.post(`${prodbase_url}token`,pay).then((res)=>{
            //     const token=res.data.token;
          axios.post(`${prodbase_url}cms-dropdown`,payload,{
            headers:{
                token
            }
          }).then((res)=>{ 
            if((res.data.status_code === "200") && (res.data.status === "1")){
              setShowthree(true);
            setDropDown({...dropDown,third:res.data.data})
            }
            else{
              dispatch(updateFormState({ ...formStates, error: true }));
            }
          }) 
            // })
        }
    },[showtwo,firstid])

    const RemoveDropdown = () =>{
        dispatch(updateserviceshow({...setService,open:[false,false,false,false]}));
      }

    const LockThisValue = (num, data,e) => {
        if (num === 0) { 
            setValue('relatedTo', data.value);
            setValue('complaintType', "");
            setValue('subComplaint', "");
        }
        else if (num === 1) { 
            setValue('complaintType', data.value);
            setValue('subComplaint', "");
            setValue('subsubComplaint',"");
            setFirstId(data.id);
            setShowtwo(true);
            setShowfour(false);
        }
        else if (num === 2) { 
            setValue('subComplaint', data.value);
            setSecondId(data.id);
            setValue('subsubComplaint',"");
            console.log(secondid);
        }
        else if (num === 3){
            setValue('subsubComplaint',data.value);
            setThirdId(data.id);
        }
        dispatch(updateserviceshow({...setService,open:[false,false,false,false]}));
        e.stopPropagation();
    }

   useEffect(()=>{
     if((secondid === 18 || secondid === 19 || secondid === 23  || secondid === 33 || secondid === 34 || secondid === 40 )){
        const payload={
            "source": "MWide",
            "type": "Product_complaint",
            "parent_id":secondid,
            "customer_type":1,
            verified_by,
            verifier_name,
            verification_source
        }
        // const pay = {
        //     "client_id": client_id,
        //     "client_key": client_key,
        //     "source": source
        // }
        // axios.post(`${prodbase_url}token`,pay).then((res)=>{
        //     const token=res.data.token;
      axios.post(`${prodbase_url}cms-dropdown`,payload,{
        headers:{
            token
        }
      }).then((res)=>{ 
        // console.log(res);
        if((res.data.status_code === "200") && (res.data.status === "1")){
            if(res.data.data.length !== 0){
            setShowfour(true);
            setDropDown({...dropDown,fourth:res.data.data})
            
            }
            else if(res.data.data.length === 0){
                setShowfour(false);
            }
        }
        else{
          dispatch(updateFormState({ ...formStates, error: true }));
        }
      }) 
        // })
     }
     else{
        setShowfour(false);
     }
     if((secondid === 99 || secondid === 100 || secondid === 114  || secondid === 115 || secondid === 116 )){
        const payload={
            "source": "MWide",
            "type": "Product_complaint",
            "parent_id":secondid,
            "customer_type":3,
            verified_by,
            verifier_name,
            verification_source
        }
        // const pay = {
        //     "client_id": client_id,
        //     "client_key": client_key,
        //     "source": source
        // }
        // axios.post(`${prodbase_url}token`,pay).then((res)=>{
        //     const token=res.data.token;
      axios.post(`${prodbase_url}cms-dropdown`,payload,{
        headers:{
            token
        }
      }).then((res)=>{ 
        if((res.data.status_code === "200") && (res.data.status === "1")){
          if(res.data.data.length !== 0){
          setShowfour(true);
          setDropDown({...dropDown,fourth:res.data.data})
          
          }
          else if(res.data.data.length === 0){
              setShowfour(false);
          }
      }
      else{
        dispatch(updateFormState({ ...formStates, error: true }));
      }
      }) 
        // })
     }
     else{
        setShowfour(false);
     }
   },[secondid])

   useEffect(()=>{
    if(data.complaintType && errors.complaintType){
      clearErrors(['complaintType'])
    }
    if(data.subComplaint && errors.subComplaint){
      clearErrors(['subComplaint'])
    }
    if(data.relatedTo && errors.relatedTo){
      clearErrors(['relatedTo'])
    }
    if(data.subsubComplaint && errors.subsubComplaint){
      clearErrors(['subsubComplaint'])
    }
    // if(data.name && data.email && data.pincode && data.relatedTo && data.complaintType && data.subComplaint  &&  data.comment &&  Object.keys(errors).length===0){
    //   setSubmit(true)
    // }else if(data.name && data.email && data.pincode && data.relatedTo && data.complaintType && data.subComplaint && data.subsubComplaint && data.comment &&  Object.keys(errors).length===0){
    //   setSubmit(true)
    // }
    if(showfour){
      if( data.complaintType && data.subComplaint && data.subsubComplaint && data.comment &&  Object.keys(errors).length===0){
        setSubmit(true);
      } 
      else{
        setSubmit(false);
      }
    }
    else if(!showfour){
      if( data.complaintType && data.subComplaint && data.comment &&  Object.keys(errors).length===0){
        setSubmit(true);
      }
      else{
        setSubmit(false);
      }
    }
    else{
      setSubmit(false);
    }
  },[data,showfour])

  useEffect(()=>{
    console.log(selectedServiceQuery)
  },[selectedServiceQuery])


    const OpenThis = (id,e) => {
        var newA = [false, false, false,false]
        newA[id] = true;
        // setOpen(newA)
        dispatch(updateserviceshow({...setService,open:newA}))
        e.stopPropagation();
    } 
    // const OpenThisOne = () => {

    //     dispatch(updateSelectedServiceRequestQuery({ ...selectedServiceQuery, show: !selectedServiceQuery.show, value: "" }))
    //     // console.log("working");
    // }

    const CloseThisServiceRequest=(e)=>{
        dispatch(updateSelectedServiceRequestQuery({ ...selectedServiceQuery, show: false, value: {} }))
        dispatch(updateserviceshow({...setService,complain:true,selected:false}))
        e.stopPropagation();
    } 

    return (
        <div>
            <FormHead formName={"Service Request"} formSubHead={"Get quick customer support by sharing your query"} handleBack={CloseThisServiceRequest} progressBar={true} backBtn={true}/>
            <div className="complain-service-request-main">
                <div className="complain-service-request-product">
                    {/* <p>Get quick customer support by sharing your query</p> */}
                </div>
                <div className="complain-service-request-all-products" onClick={(e)=>CloseThisServiceRequest(e)}>
                    <div className="complain-service-request-all-products-type">
                        <p>{selectedServiceQuery.value.prod_name}</p>
                        <input style={{width:"20px",height:"20px"}} type="radio" name="radio" checked={true} readOnly />
                    </div>
                    <hr />
                    <div className="complain-service-request-all-products-date-amount">
                        <div className="complain-service-request-all-products-date">
                            <p>Application Date:</p>
                            <p>{selectedServiceQuery.value.applicationdate?selectedServiceQuery.value.applicationdate.slice(5,16):""}</p>
                        </div>
                        <div className="complain-service-request-all-products-amount">
                            <p>Loan Amount:</p>
                            <p>{selectedServiceQuery.value.loan_amount}</p>
                        </div>
                    </div>
                    <div className="complain-service-request-all-products-status">
                        <p>Status</p>
                        <p >{selectedServiceQuery.value.status}</p>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} style={{marginTop:"37px"}}>

{/* 
                <div className={"input-tag "+(errors.relatedTo && !data.relatedTo ? " error-border" : data.relatedTo ? " add-border" : "")} >
                    <img className="tag-icon" src={chat} />
                    <input className="input-tag-box" type="text" placeholder='Select Query Regarding'
                        {...register('relatedTo', { required: true })} autoComplete="off" readOnly style={{ cursor: "pointer" }} onClick={(e) => OpenThis(0,e)} onFocus={(e)=>OpenThis(0,e)} />
                    <img src={DropDownIcon} className="small-the-icon" alt="" />
                    {setService.open[0] &&
                    <div className='input-dropdown'> 
                        {dropDown.first && dropDown.first.map((row) => {
                            return <div onClick={(e) => LockThisValue(0,row,e)} key={row.id} className="dropdown-content">{row.value}</div>
                        })}
                    </div>
                }

                </div> */}





                <div className={"input-tag"+(errors.complaintType && !data.complaintType ? " error-border " : data.complaintType ? " add-border" : "")} >
                    <img className="tag-icon" src={chat} />
                    <input className="input-tag-box" type="text" placeholder='Product Related To'
                        {...register('complaintType', { required: true })} autoComplete="off" readOnly style={{ cursor: "pointer" }} onClick={(e) => OpenThis(1,e)} onFocus={(e)=>OpenThis(1,e)}    />
                    <img src={DropDownIcon} className="small-the-icon" alt="" />
                    {setService.open[1] && <div className='input-dropdown' style={{ height: "320px" }}>
                        {dropDown.second && dropDown.second.map((row) => {
                            return <div onClick={(e) => LockThisValue(1, row,e)} key={row.id} className="dropdown-content">{row.value}</div>
                        })}
                    </div>}
                </div>


                { showtwo &&
                    <div className={"input-tag "+(errors.subComplaint && !data.subComplaint ? " error-border" : data.subComplaint ? " add-border" : "")}>
                        <img className="tag-icon" src={chat} />
                        <input className="input-tag-box" type="text" placeholder='Complaint Type'
                            {...register('subComplaint', { required: true })} autoComplete="off" readOnly style={{ cursor: "pointer" }} onClick={(e) => OpenThis(2,e)} onFocus={(e)=>OpenThis(2,e)} />
                        <img src={DropDownIcon} className="small-the-icon" alt="" />
                        {setService.open[2] && <div className='input-dropdown'>
                            {dropDown.third && dropDown.third.map((row) => {
                                return <div onClick={(e) => LockThisValue(2, row,e)} key={row.id} className="dropdown-content">{row.value}</div>
                            })}
                        </div>}
                    </div>
                } 

                
              { showfour &&
                    <div className={"input-tag "+(errors.subComplaint && !data.subComplaint ? " error-border" : data.subComplaint ? " add-border" : "")}>
                        <img className="tag-icon" src={chat} />
                        <input className="input-tag-box" type="text" placeholder='Sub-Complaint Type'
                            {...register('subsubComplaint', { required: true })} autoComplete="off" readOnly style={{ cursor: "pointer" }} onClick={(e) => OpenThis(3,e)} onFocus={(e)=>OpenThis(3,e)} />
                        <img src={DropDownIcon} className="small-the-icon" alt="" />
                        {setService.open[3] && <div className='input-dropdown'>
                            {dropDown.fourth && dropDown.fourth.map((row) => {
                                return <div onClick={(e) => LockThisValue(3, row,e)} key={row.id} className="dropdown-content">{row.value}</div>
                            })}
                        </div>}
                    </div>
                }


                <div className={"input-tag"+( data.comment ? " add-border" : "")}>
                    {/* <input className="input-tag-box" type="text-area" style={{ marginBottom: "2px" }} placeholder='Write your comment'
                        {...register('comment', { required: true })} autoComplete="off" /> */}
                        <textarea className="input-tag-box" type="text"  onFocus={RemoveDropdown} style={{ marginBottom: "2px" }}  onBlur={() => Blured("comment", data.comment)} onKeyUp={() => SpaceManage("comment", data.comment)} placeholder='Write your comment' {...register('comment', { required: true })} autoComplete="off"></textarea>
                </div>

{/* //disabled={(Object.keys(errors).length === 0 && data.complaintType && data.subComplaint && data.relatedTo && data.comment) || resp} */}
<button disabled={resp || !submit} className={'footer-btn' + ((submit) ? ' footer-btn-activate' : "")}>
        {!resp ?
              "Submit" :
              <div className="continue-lottie">
                <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
              </div>}
        </button>
            </form>
        </div>
    )
}

export default SelectedComplainServiceRequest;