import React from "react";
import "../footermorelinkspages.css";
import RightSidePopUp from "../Right Side PopUp/RightSidePopUp";
const UserConsentForm = () => {
  const [y, sety] = React.useState(true);
  window.addEventListener("scroll", (event) => {
    if (window.scrollY >= 450) {
      sety(false);
    }
    else {
      sety(true);
    }
  });
  return (
    <div className="disclaimer-box">
      {y && <RightSidePopUp />}
      <div>
        <div >
          {" "}
          <h1 className="sachhead">User Consent form</h1>
        </div>
      </div>
      <div className="sachmain privacyapp-bg">
        <p>
          I acknowledge that I have opened an account with MoneyWide by applying
          for a loan/healthcare credit facility. I will be bound by the Terms &amp; Conditions, Privacy
          Policy and any and all documentation entered into between MoneyWide
          and me in relation to my account.
        </p>
        <p>
          I authorize MoneyWide or its designated financial institutions or
          partners to access and obtain both hard and soft copies of all my
          documents, reports, and other such information ("Credit Information")
          maintained with the Credit Information Bureau (India) Limited
          ("CIBIL"), Experian, Equifax or any other Credit Agencies. Therefore,
          I hereby authorize MoneyWide to access my Credit Information Report
          from CIBIL, Experian, Equifax or other Credit Agencies.
        </p>
        <p>
          I hereby authorize MoneyWide and its business partners to send me
          communications regarding loans/healthcare credit facility and other products from MoneyWide
          and/or third parties through calls/SMS/WhatsApp/emails/notifications
          communication etc. irrespective of rejection of my loan/healthcare credit facility application.
        </p>
        <p>
          I authorize MoneyWide to validate my employment details using Mobile
          No, Email ID and Employee Name via EPFO portal.
        </p>
        <p>
          I hereby authorize MoneyWide, its partners or third parties to read
          and verify my demographic/profile details using a PAN, Aadhaar,
          Passport, Voter ID, Driving License, Utility Bill like Electricity
          Bill, Piped Gas, Mobile Postpaid, broadband bill or any other valid
          identity or address proof.
        </p>
        <p>
         I hereby authorize and give my explicit consent to MoneyWide and its lending partner(s) to conduct search/download/Update/disclose my KYC records from/to Central KYC registry by using my KYC identifier.
        </p>
        <p>
          I also authorize MoneyWide and its partners to access and verify any
          other information required for processing my loan/healthcare credit facility application
          including but not limited to bank account verification, banking
          transactions, social media accounts, other income documents, emails
          and address verification as required at various stages of the
          application.
        </p>
        <p>
          I acknowledge that the Credit Information obtained from the Credit
          Agencies shall be maintained and used only in the manner provided in
          MoneyWide's Terms &amp; Conditions and Privacy Policy.
        </p>
        <p>
          I shall be solely liable and hold harmless MoneyWide for and against
          any third-party claims and/or damages of any kind, arising from or in
          connection with: (a) any misrepresentations, incorrect information or
          omission of any critical information in my application; or (b)
          facilitating access to, obtaining copies of or furnishing my Credit
          Information based on this consent form, and shall not at any time hold
          MoneyWide or its affiliates and their respective officers, directors,
          employees or representatives liable in this respect.
        </p>
        <p>
          I hereby also appoint MoneyWide to be my authorized
          agent/representative to collect my Credit Information and submit my
          application form(s) to appropriate financial agencies.
        </p>
        <p>
          I shall at all times co-operate with MoneyWide and execute and deliver
          to them such instruments and documents and take such other actions as
          may be required by them, from time to time, in connection with this
          consent form.
        </p>
        <p>
          I hereby acknowledge and accept the terms of the consent mentioned
          above.
        </p>
      </div>
    </div>
  );
};

export default UserConsentForm;
