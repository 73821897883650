import React from 'react';
import Lottie from "react-lottie-player";
import lottieJson from "../assests/lootie-files/successful.json";


const NomineeThanku = ({msg}) => {
  return (
    <div className="thank-cont">
      <div className="thank-popup-lottie">
        <Lottie
          loop
          animationData={lottieJson}
          play
        />
      </div>
      <p className="thank-p">Thank You</p>
      <p className="thank-p1" style={{color:'#525660'}}>{msg}</p>
      {/* <p className="thank-regards">Regards<br />Team MoneyWide</p> */}
      {/* <p className="thank-regards"></p> */}
    </div>

  )
}

export default NomineeThanku;