import React, { useEffect, useState } from "react";
import "./MyAccount.css";
import "./Settings.css";
import phone from "../../Icons/phone.svg";
import DropDownIcon from "../../Icons/DropDownIcon.svg";
import greenticknew from "../../Icons/greenticknew.svg";
import UnsubscribePopup from "./UnsubscribePopup";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import { useDispatch, useSelector } from "react-redux";
import { GenerateToken } from "../../Redux/action";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
import {
    getLabelData,
    getLoginData,
    getLoginMaster,
    updateToogleButton,
} from "./MyAccountAPIs";
import { useNavigate, useSearchParams } from "react-router-dom";
import Base64 from "base-64";

function Settings() {
    // const [searchParams, setSearchParams] = useSearchParams();
    // const elg = searchParams.get("cust_id");
    const [dropDownContent, setDropDownContent] = useState([]);
    const [labelData, setLabelData] = useState([]);
    const [selected, setSelected] = useState("");
    const [loader, setLoader] = useState(true);
    const [userData, setuserData] = useState({
        phone: "",
        cust_id: "",
    });
    const [sub, setSub] = useState({
        given: false,
        subed: false,
    });
    const [err, setErr] = useState(false);
    const [popUpContent, setPopUpContent] = useState(0);
    const [timer, setTimer] = useState(0);

    const { token } = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    };

    useEffect(() => {
        if (token == "") {
            myToken();
        }
    }, []);

    const url_new = window.location.href;
    const shouldRender = !url_new.includes("cust_id");
    const condition_check = url_new.includes("cust_id");

    const getPreData = async (userNo, cust_id) => {
        const Response = await getLoginMaster(cust_id, token);
        try {
            if (Response.status_code == "200" && Response.status == "1") {
                if (condition_check) {
                    const filteredData = Response.data.filter(
                        (item) => item?.email_flag == 1
                    );
                    if (filteredData.length > 0) {
                        setuserData({ ...userData, cust_id: +cust_id });
                        setDropDownContent(filteredData);
                        setSelected(filteredData[0]);
                        getLabelDataFromAPI(filteredData[0].id, cust_id);
                    } else {
                        navigate("/login");
                    }
                } else {
                    setDropDownContent(Response.data);
                    setSelected(Response.data[0]);
                    getLabelDataFromAPI(Response.data[0].id, cust_id);
                }
            } else if (Response.status_code == "429" && Response.status == "0") {
                navigate("/login");
            } else {
                setErr(false);
                setLoader(false);
            }
        } catch (err) {
            navigate("/login");
        }
    };

    const getLabelDataFromAPI = async (val, cust_id, time) => {
        setLoader(true);
        const LabelResponse = await getLabelData(val, cust_id, token);
        if (LabelResponse.status_code == "200" && LabelResponse.status == "1") {
            setLabelData(LabelResponse.data);
            LabelResponse.data.map((item, index) => {
                if (item["subscribe"]) {
                    setSub({ ...sub, given: item.subscribe, subed: false });
                }
            });
            setLoader(false);
            if (time) {
                setTimer(time);
            }
        } else {
            setErr(false);
            setLoader(false);
        }
    };

    const getUserDataFromSession = () => {
        let relocate = url_new.includes("cust_id") ? "NO" : "";
        let payLoad = getLoginData(relocate); //CHECK
        if (url_new.includes("cust_id")) {
            try {
                let url = window.location.href;
                let cust = "";
                const parsedUrl = new URL(url);
                const searchParams = parsedUrl.searchParams;
                const cust_id = searchParams.get("cust_id");
                const dcrypt_customer_id = Base64.decode(cust_id);
                const customer_id = Base64.decode(dcrypt_customer_id);
                cust = customer_id;
                setuserData({
                    ...userData,
                    cust_id: customer_id,
                });
                getPreData("", +cust);
            } catch (err) {
                navigate("/login");
            }
        } else if (!payLoad && !payLoad.userNo) {
            navigate("/login");
        } else if (payLoad.userNo != undefined && payLoad.userNo !== "") {
            getPreData(payLoad.userNo, payLoad.cust_id);
            setuserData({
                ...userData,
                phone: payLoad.userNo,
                cust_id: payLoad.cust_id,
            });
        }
    };

    useEffect(() => {
        if (token != "") {
            getUserDataFromSession();
        }
    }, [token]);

    const PopOpen = (data) => {
        setPopUpContent(data);
    };

    const handleSelectChange = (e) => {
        setSelected(JSON.parse(e.target.value));
        getLabelDataFromAPI(JSON.parse(e.target.value).id, userData.cust_id);
    };

    const subscribeClick = (val) => {
        setPopUpContent(0);
        if (val != 0) {
            setSub({ ...sub, subed: true });
        }
    };

    const toggleValReverse = (val) => {
        let Checklable = [];
        val = val ? 1 : 2;
        if (selected.id == 1) {
            Checklable = ["sms", "bpn", "whatsapp", "ivr"];
        } else {
            Checklable = ["email"];
        }
        let lab = [...labelData];
        lab.map((row, index) => {
            Checklable.map((item, inx) => {
                row[item] = val;
            });
        });
        setLabelData(lab);
    };

    const reload = () => {
        window.location.reload();
    };

    const handleToggleChange = async (row, label) => {
        let payLoad = {
            ...row,
            [label]: row[label] == 1 ? 2 : 1,
        };
        let res = [...labelData];
        res[row.label - 1] = payLoad;
        setLabelData(res);
        setSub({ ...sub, subed: false });
    };

    const subScribeChange = (row, label) => {
        if (sub.given == 1 && sub.subed == false) {
            PopOpen(2);
        } else {
            setSub({ ...sub, subed: !sub.subed });
        }
    };

    const SavePreferences = async () => {
        let data = {
            customer_id: userData.cust_id,
            comm_preference: selected.id,
        };

        if (sub.given == 1 && sub.subed == true) {
            data["subscribe"] = 2;
            data["unsubscribe"] = 1;
        } else if (sub.given == 2 && sub.subed == true) {
            data["subscribe"] = 1;
            data["unsubscribe"] = 2;
        } else {
            data["subscribe"] = 2;
            data["unsubscribe"] = 2;
        }

        let check_labels = [];
        if (selected.id == 1) {
            check_labels = ["sms", "bpn", "ivr", "whatsapp"];
        } else {
            check_labels = ["email"];
        }
        for (let i = 0; i < labelData.length; i++) {
            for (let j = 0; j < check_labels.length; j++) {
                if (labelData[i][`${check_labels[j]}`]) {
                    data[`label${i + 1}_${check_labels[j]}`] =
                        labelData[i][`${check_labels[j]}`];
                }
            }
        }
        setLoader(true);
        const APIResponse = await updateToogleButton(data, token);
        if (APIResponse.status_code == "200" && APIResponse.status == "1") {
            getLabelDataFromAPI(selected.id, userData.cust_id, 2);
        } else {
            setErr(true);
        }
    }

    useEffect(() => {
        if (sub.subed) {
            if (sub.given == 1) {
                toggleValReverse(false);
            } else {
                toggleValReverse(true);
            }
        }
    }, [sub]);

    useEffect(() => {
        if (timer > 0) {
            setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        }
    }, [timer]);
    return (
        <>
            {loader ? <CKYCWaiting /> : ""}
            {err && <NewErrorCase handleClick={reload} />}
            {popUpContent == 2 ? (
                <UnsubscribePopup subscribeClick={subscribeClick} />
            ) : (
                ""
            )}
            <h3 className="myAccountHeading">Communication Preferences</h3>
            <div className="setting-back-bg">
                {shouldRender && (
                    <>
                        <div className="setting-header-span">
                            <span>
                                Select a mobile number/email address to set communication
                                preferences
                            </span>
                        </div>

                        <div className="add-number-settings">
                            <div className="add-number-head">
                                <span>Select mobile number or email address</span>
                                <div className="add-number-selection">
                                    <img src={phone} className="tag-icon" />
                                    <select
                                        name="userData"
                                        id="setting-select"
                                        onChange={(e) => handleSelectChange(e)}
                                    >
                                        {dropDownContent.map((row, index) => {
                                            return (
                                                <option key={index} value={JSON.stringify(row)}>
                                                    {row.value}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <img
                                        style={{ marginLeft: "10px" }}
                                        src={DropDownIcon}
                                        className="small-the-icon"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="prefrence-no">
                    <p>
                        Select communication preferences for{" "}
                        <span>
                            {selected?.value} <img src={greenticknew} />
                        </span>
                    </p>
                </div>
                <div className="toggle-approvel">
                    <div className="toggle-approve-sub">
                        <div className="toggle-approve-label-main">
                            <div className="toggle-approve-label">
                                <span>Label</span>
                            </div>
                            {labelData.length > 0 &&
                                (selected.id == 1 ? (
                                    <div className="toggle-approve-right-main-sms">
                                        <div className="toggle-approve-sms">
                                            <span>SMS</span>
                                        </div>
                                        <div className="toggle-approve-sms">
                                            <span>Whatsapp</span>
                                        </div>
                                        <div className="toggle-approve-sms">
                                            <span>IVR</span>
                                        </div>
                                        <div className="toggle-approve-sms">
                                            <span>BPN</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="toggle-approve-right-main-sms">
                                        <div className="toggle-approve-sms">
                                            <span>Email</span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div className="toggle-approve-right">
                            <div className="toggle-cont-all">
                                {labelData.length != 0 &&
                                    labelData.map((row, ind) => {
                                        return (
                                            row.label != 4 && (
                                                <div key={ind} className="toggle-cont-sub">
                                                    <div className="toggle-approve-label toggle-left-cont">
                                                        <span>{row?.head_desc}</span>
                                                        <p>{row?.sub_head_desc}</p>
                                                    </div>
                                                    {selected.id == 1 ? (
                                                        <div className="toggle-approve-right-main-sms toggle-left-btn-main">
                                                            <div className="toggle-left-btn">
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={() =>
                                                                            handleToggleChange(row, "sms")
                                                                        }
                                                                        checked={row.sms == 1}
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <p>sms</p>
                                                            </div>
                                                            <div className="toggle-left-btn">
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={row.whatsapp == 1}
                                                                        onChange={() =>
                                                                            handleToggleChange(row, "whatsapp")
                                                                        }
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <p>Whatsapp</p>
                                                            </div>
                                                            <div className="toggle-left-btn">
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={() =>
                                                                            handleToggleChange(row, "ivr")
                                                                        }
                                                                        readOnly
                                                                        checked={row.ivr == 1}
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <p>IVR</p>
                                                            </div>
                                                            <div className="toggle-left-btn">
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={() =>
                                                                            handleToggleChange(row, "bpn")
                                                                        }
                                                                        readOnly
                                                                        checked={row.bpn == 1}
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <p>BPN</p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="toggle-approve-right-main-sms toggle-left-btn-main">
                                                            <div className="toggle-left-btn">
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={() =>
                                                                            handleToggleChange(row, "email")
                                                                        }
                                                                        checked={row.email == 1}
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <p>Email</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
                {labelData.length > 0 &&
                    labelData.map((row) => {
                        return (
                            row.label == 4 && (
                                <div className="toggle-approve-cont-main">
                                    <div className="toggle-cont-all unsubscribe-toggle-cont-all">
                                        <div className="toggle-cont-sub unsubscribe-toggle">
                                            <span>{row.head_desc}</span>
                                            <p>{row.sub_head_desc}</p>
                                            {row.sub_desc && (
                                                <span className="subdesc">{row.sub_desc}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="toggle-buttons-main">
                                        <div className="tgle-btn-sub">
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    onChange={() => subScribeChange()}
                                                    checked={sub.subed}
                                                    readOnly
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )
                        );
                    })}

                <div className="account-state-button od-button preferences">
                    {timer > 0 && (
                        <div className="pref-success-msg">
                            <p>Communication preferences has been updated successfully.</p>
                        </div>
                    )}
                    <button
                        type="submit"
                        onClick={() => SavePreferences()}
                        className={"footer-btn od-tn-footer activate"}
                    >
                        Save Preferences
                    </button>
                </div>
            </div>
        </>
    );
}

export default Settings;
