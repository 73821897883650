import React from 'react';
import { useEffect, useState } from 'react';
import '../Form.css';
import { useDispatch, useSelector } from 'react-redux'
import { GenerateToken, updateFormState } from '../../../../Redux/action';
import { react_base_url } from '../../../Secret_Credentials/Secret_Credential';
import CKYCWaiting from '../../../LoginPopUp/CKYCWaiting';
import { getToken, LoanAgreementFetch } from '../../../EverCalledFunctions/EverCalledApis';
import Base64 from "base-64";
import ctaAnimation from '../../../assests/lootie-files/continue.json';
import Lottie from 'react-lottie-player';
import back from '../../../../Icons/back.svg'
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { RelocateUTMCheck, encryptOnlyLeadId, gettingerror } from '../../../EverCalledFunctions/EverCalledFunctions';
import Base64ToPdf from '../../../LegalNotice/Base64ToPdf';



const LoanAgreementBack = () => {
  const [device_id, setDevice_id] = useState("");
  const [pdfCode, setPdfCode] = useState("");
  const [resp, setResp] = useState(false);
  const [ip, setIP] = useState('');
  const { formStates, credentialState, mw_application_id, token, decisionDemog } = useSelector(state => state)
  const dispatch = useDispatch()
  const [imTag, setImtag] = useState('')
  const [flag, setFlag] = useState('');
  const [loader, setLoader] = useState(true)



  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

  useEffect(() => {
    if (token == "") {
      myToken();
    }
    if (decisionDemog.stamphdb == "") {
      setFlag(0);
    }
    else {
      setFlag(decisionDemog.stamphdb)
    }
    knowdevice();
    getData();
  }, [])

  useEffect(() => {
    if ((ip !== "") && (device_id !== "") && (token !== "")) {
      if (pdfCode.length === 0) {
        ApiCallPdf();
      }
    }
  }, [ip, device_id, token])

  const moveAhead = () => {
    gettingerror('e-sign initiate web', mw_application_id)
    setResp(true)
    let qry = Base64.encode(`${mw_application_id}`);
    qry = Base64.encode(qry);
    qry = `lead_id=${qry}`
    qry = Base64.encode(qry);
    qry = Base64.encode(qry);
    let fl = Base64.encode(flag)
    fl = Base64.encode(fl)
    RelocateUTMCheck(`${react_base_url}e-sign/?fid=${qry}&stamp_flag=${fl}`)
  }



  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://api.ipify.org?format=json')
    setIP(res.data.ip);
  }
  function knowdevice() {
    if (window.innerWidth > 1024) {
      setDevice_id("1");
    }
    else if (window.innerWidth > 480 && window.innerWidth < 1024) {
      setDevice_id("3")
    }
    else {
      setDevice_id("2");
    }
  }

  const ApiCallPdf = async () => {
    const payload = {
      "mw_application_id": mw_application_id,
      "customer_id": credentialState.cust_id,
      "ip": ip,
      "device_type": +(device_id),
      "stampflag": 0,
      "financial_protection": decisionDemog.stamphdb
    }
    const APIDATA = await LoanAgreementFetch(payload, "");
    if ((APIDATA.status == "1") && (APIDATA.status_code == "200")) {
      gettingerror('fetch loan agreement web', mw_application_id)
      if(APIDATA.data["banking_req"] && APIDATA.data["banking_req"] == 1){
        let qryStr = encryptOnlyLeadId(mw_application_id);
          // RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
          window.location.href = `${react_base_url}analyzer/?fid=${qryStr}`
      }
      else if ((APIDATA.data.redirect_url) && (APIDATA.data.redirect_url !== "")) {
        window.location.href = APIDATA.data.redirect_url;
      } else if (credentialState['skip_flag'] == 1) {
        if ((APIDATA.data.pdfLink) && (APIDATA.data.pdfLink !== "")) {
          setPdfCode(APIDATA.data.pdfLink)
          appendRestCode(APIDATA.data.pdfLink)
        } else {
          gettingerror('agreement api error web', mw_application_id)
          let qry = Base64.encode(`${mw_application_id}`);
          qry = Base64.encode(qry);
          window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18`;
        }
      } else {
        if ((APIDATA.data.pdfLink) && (APIDATA.data.pdfLink !== "") && (APIDATA.data.stamp_content) && (APIDATA.data.stamp_content !== "")) {
          setPdfCode(APIDATA.data.pdfLink)
          setImtag(APIDATA.data.stamp_content)
          setTimeout(() => {
            appendRestCode(APIDATA.data.pdfLink)
          }, [100])
        } else {
          gettingerror('agreement api error web', mw_application_id)
          let qry = Base64.encode(`${mw_application_id}`);
          qry = Base64.encode(qry);
          window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18`;
        }
      }
    } else {
      gettingerror('agreement api error web', mw_application_id)
      let qry = Base64.encode(`${mw_application_id}`);
      qry = Base64.encode(qry);
      window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18`;
    }

  }

  const appendRestCode = (baseCode) => {
    var doc = document.getElementById("pdf-viewer-user");

    var div = document.createElement('div')
    div.innerHTML = decodeURIComponent(escape(window.atob(baseCode)));
    doc.append(div)
    setLoader(false)
  }

  const getPage = async (da, basePDF) => {
    var pdfjsLib = window['pdfjs-dist/build/pdf'];
    pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
    var loadingTask = await pdfjsLib.getDocument({ data: atob((da)) });
    loadingTask.promise.then(function (pdf) {
      pdf.getPage(1).then(function (page) {
        var scale = 1.5;
        var viewport = page.getViewport({ scale: scale });
        var canvas = document.createElement("canvas")
        var context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = 1000;
        var renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        var renderTask = page.render(renderContext);
        renderTask.promise.then(function (res) {

        });

        var pdfBox = document.getElementById('pdf-viewer-user')
        pdfBox.append(canvas)
        appendRestCode(basePDF)
      });
    });
  }

  const BackToTAndC = () => {
    dispatch(updateFormState({ ...formStates, LoanAgreement: false, Terms: true }))
  }


  return (
    <>
      <Helmet>
        <script src="//mozilla.github.io/pdf.js/build/pdf.js">
        </script>
        {/* <link href="http://fonts.googleapis.com/earlyaccess/lohitdevanagari.css" rel="stylesheet"></link> */}
      </Helmet>
      {loader && <CKYCWaiting heading={"We are generating Agreement"} />}
      <div className='loan-agreement-open'>

        <div>
          <div className='loan-agree-back' >
            <span onClick={() => BackToTAndC()}>
              <img src={back} alt="na" /> {credentialState['skip_flag'] == 1 ? "Credit Facility Agreement" : "Loan Agreement"}
            </span>
          </div>
          <div className="entire-reader" id='pdf-viewer-user' >
          {imTag && <Base64ToPdf base64pdf={imTag} />}
          </div>
          <button onClick={moveAhead} className="footer-btn footer-btn-activate loan-btn">{!resp ?
            "Continue" :
            <div className="continue-lottie">
              <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
            </div>}</button>
        </div>
      </div>
    </>
  )
}

export default LoanAgreementBack;
