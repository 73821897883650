import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GenerateToken, updateCredentialsState, updateDecisionDemog, updateFormState, updateKFSData, updateLoginDetails, updateStepBar } from '../../../Redux/action';
import './Form.css';
import FormHead from './FormHead';
import axios from 'axios';
import { react_base_url, client_id, client_key, prodbase_url, source, verification_source, verified_by, verifier_name } from '../../Secret_Credentials/Secret_Credential'
import { useEffect } from 'react';
import CKYCWaiting from '../../LoginPopUp/CKYCWaiting';
import { getToken } from '../../EverCalledFunctions/EverCalledApis';
import ctaAnimation from '../../assests/lootie-files/continue.json';
import Lottie from 'react-lottie-player';
import Base64 from 'base-64';
import { encryptLeadIdStepId, encryptOnlyLeadId, gettingerror, RelocateUTMCheck } from '../../EverCalledFunctions/EverCalledFunctions';
import { useNavigate } from 'react-router-dom';
import { AxiosThirty } from '../../AxiosConfiguration/AxiosConfig';
import NewErrorCase from './All_Forms/NewErrorCase';
import { FetchCustomerPayload } from '../../EverCalledFunctions/EverCalledPayload';
import { apiInstance } from '../../Config/APIConfig';
const TandC = () => {
  const { formStates, credentialState, step_bar, mw_application_id, token, KFSData, decisionDemog, loginDetails } = useSelector(state => state);
  const dispatch = useDispatch();
  const [data, setData] = useState({})
  const [resp, setResp] = useState(false)
  const [loader, setLoader] = useState(true);
  const [currentSaving, setCurrentSaving] = useState()
  const [show, setShow] = useState();
  const [kfs, setKfs] = useState();
  const [ip, setIP] = useState('');
  const [esignCheck, setEsignCheck] = useState(false)
  const [rocket, setRocket] = useState(false);
  // const [show,setShow] = useState(false);
  const [pristyn,setPristyn] = useState(false);
  const navigate = useNavigate()
  
  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

  const getData = async () => {
    const res = await axios.get('https://api.ipify.org?format=json')
    setIP(res.data.ip);
  }

  React.useEffect(() => {
    dispatch(updateStepBar({ ...step_bar, profile: true, offer: true, verification: true, disbursal: true }))
    dispatch(updateFormState({ ...formStates, congo: false, LoanAgreement: false, Terms: true }))
    getData()
    if (token == "") {
      myToken();
    }
    if (loginDetails.YesNo) {
      setCurrentSaving(loginDetails.YesNo)
    } else if (loginDetails?.YesNo == 0) {
      setCurrentSaving(loginDetails.YesNo)
    }
  }, [])

  const hitFetchCustomer = async () => {
    let decFetch = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 8,ip), {
      token:""
    })
    // .then((res) => {
    if ((decFetch.status == "1") && (decFetch.status_code == "200")) {
      setKfs(decFetch.data.kfs_send);
      setShow(decFetch.data.financial_protection_display)
      if (decFetch.data.financial_protection_display != 1) {
        setCurrentSaving(0)
      }
      if(decFetch.data.skip_screen_flag == 1){
        setPristyn(true);
      }
      if (decFetch.data.thank_you_flag == 1) {
        gettingerror("get thank you flag", mw_application_id)
        if (decFetch.data.thank_you_url !== "") {
          window.location.href = decFetch.data.thank_you_url;
          return;
        }
        else {
          let qry = Base64.encode(`${mw_application_id}`);
          qry = Base64.encode(qry);
          RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=16`)
          return;
        }

      }
      else {
        if (decFetch.data.redirection_url !== "" && !decFetch.data.redirection_url.includes('loan-agreement')) {
          window.location.href = decFetch.data.redirection_url;
          return;
        }
        if (decFetch.data.is_account_ok == 0) {
          let qry = encryptLeadIdStepId(mw_application_id, 22)
          RelocateUTMCheck(`${react_base_url}personal-loan/?fid=${qry}`)
          return;
        }
        if (decFetch.data.otp_id === 1) {
          setEsignCheck(true)
          sessionStorage.setItem("esignCheck", decFetch.data.otp_id)
        } else {
          setEsignCheck(false)
          sessionStorage.setItem("esignCheck", 0)
        }

        window.sessionStorage.setItem("cust_id", decFetch.data.customer_id);
        dispatch(updateCredentialsState({ ...credentialState, cust_id: decFetch.data.customer_id, skip_flag: decFetch?.data['skip_screen_flag'] ? 1 : 0 }))
      }
    }
    else {
      let qry = Base64.encode(`${mw_application_id}`);
      qry = Base64.encode(qry);
      RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=16`)
    }

    // }).catch((err) => {
    //   setRocket(true);
    // })
  }

  React.useEffect(() => {
    if (token !== "" && ip != "") {
      hitFetchCustomer();
    }
  }, [token,ip])

  const hitKfsData = async (cur_saving) => {
    let decKfs = await apiInstance.post(`web/loan-agreement-kfs`, {
      "verifier_name": verifier_name,
      "verification_source": verification_source,
      "verified_by": verified_by,
      "source": source,
      "lead_id": mw_application_id,
      "customer_id": credentialState.cust_id,
      'financial_protection': cur_saving
    }, {
      "token": "",
      "log": 1
    })
    // .then((res) => {
    if ((decKfs.status == "1") && (decKfs.status_code == "200")) {
      setData(decKfs.data)
      setLoader(false);
      dispatch(updateKFSData(decKfs.data))
      gettingerror('loan kfs hit web', mw_application_id)
    }
    else {
      setRocket(true);
      setLoader(false);
      gettingerror('loan kfs api error web', mw_application_id)
    }

    // }).catch((err) => {
    //   setRocket(true);
    //   setLoader(false);
    // })
  }

  useEffect(() => {
    if (credentialState.cust_id !== "") {
      if (Object.keys(KFSData).length === 0) {
        hitKfsData(0);
      } else {
        setData(KFSData)
        setLoader(false);
      }
    }
  }, [credentialState])

  React.useEffect(() => {
    if ((credentialState.cust_id !== "") && ((show == 1) && ((currentSaving == 1) || (currentSaving == 0)))) {
      setLoader(true);
      hitKfsData(currentSaving);
    }
  }, [currentSaving, credentialState])

  const handleLoanAgreement = () => {
    if (esignCheck) {
      dispatch(updateDecisionDemog({ ...decisionDemog, stamphdb: currentSaving }));
      dispatch(updateFormState({ ...formStates, Terms: false, LoanAgreement: true }))
      gettingerror('view loan agreement web', mw_application_id)
    } else {
      let qry = encryptOnlyLeadId(`${mw_application_id}`);
      qry = Base64.encode(`lead_id=${qry}`)
      qry = Base64.encode(qry);
      window.location.href = `${react_base_url}e-sign/?fid=${qry}`;
      window.location.href = `https://external.moneywide.com/loan-agreement/${window.location.search}`;
    }
  }

  const handleError = (e) => {
    window.location.reload();
  }

  const handleAccountType = (e, value) => {
    e.preventDefault();
    setCurrentSaving(value);
    dispatch(updateLoginDetails({ ...loginDetails, YesNo: value }))
  };

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      {loader ?
        <CKYCWaiting heading={"We are Generating your Key Fact Statement."} /> :
        <>
          <FormHead formName={"Terms & Conditions"} formSubHead={"Read all the terms & conditions applied"} />
          <div className="loan-box-TandC">
            <div className="T-C-heading">{pristyn ? "HealthCare Credit Details":"Loan Details"}</div>
            <div className="T-C-Box">
              <div className="T-C-subBoxes">
                <div className="TCHead">{pristyn ? "Facility Type":"Loan Type"}</div>
                <div className="TCContent">{data.program_name}</div>
              </div>
              <div className="T-C-subBoxes">
                <div className="TCHead">{pristyn ? "Facility Provider":"Lender Name"}</div>
                <div className="TCContent">{data.name_of_the_regulated_entity}</div>
              </div>
              <div className="T-C-subBoxes">
                <div className="TCHead">Name</div>
                <div className="TCContent">{data.applicant_name}</div>
              </div>
              <div className="T-C-subBoxes">
                <div className="TCHead">Sanctioned Amount</div>
                <div className="TCContent">{data.loan_amount}</div>
              </div>
              <div className="T-C-subBoxes">
                <div className="TCHead">Tenure</div>
                <div className="TCContent">{data.loan_tenure}</div>
              </div>
              <div className="T-C-subBoxes">
                <div className="TCHead">Interest Rate</div>
                <div className="TCContent">{data.interest_rate_pm}</div>
              </div>
            </div>
            {show == 1 ? <>
              <div className="account-type-option">Do you want to opt for Financial Protection?</div>
              <div className="options-confirm">
                <button
                  className={
                    "yes-option-confirm" +
                    (currentSaving === 1 ? " activated-option" : "")
                  }
                  onClick={(e) => handleAccountType(e, 1)}
                >
                  <input
                    onClick={(e) => handleAccountType(e, 1)}
                    readOnly
                    checked={currentSaving === 1}
                    type="radio"
                  />
                  Yes
                </button>
                <button
                  className={
                    "no-option-confirm " +
                    (currentSaving === 0 ? " activated-option" : "")
                  }
                  onClick={(e) => handleAccountType(e, 0)}
                >
                  <input
                    onClick={(e) => handleAccountType(e, 0)}
                    readOnly
                    checked={currentSaving === 0}
                    type="radio"
                  />
                  No
                </button>
              </div>
            </> : ''}
            {((show != 1) || ((currentSaving == 1) || (currentSaving == 0))) ? <div className="TCLinks">
              <div className="pointer-cur" onClick={() => handleLoanAgreement()} ><a className='send-lnk' >{pristyn ? "View Agreement":"View Agreement"}
              </a></div>
              <div className="pointer-cur" onClick={() => dispatch(updateFormState({ ...formStates, Terms: false, KFS: true }))} ><a className='send-lnk' >{pristyn ? "View Key Fact Statement":"View Key Fact Statement"}
              </a></div>
            </div> : <div className="TCLinks">
              <div className="pointer-cur-one" >{pristyn ? "View Agreement":"View Agreement"}</div>
              <div className="pointer-cur-one">{pristyn ? "View Key Fact Statement":"View Key Fact Statement"}
              </div>
            </div>}
            <div className="TC-confirmText">By continuing, you acknowledge that you have read & agreed to the terms and conditions contained in the agreement</div>
            <button
              disabled={show == 1 ? ((currentSaving == 1 || currentSaving == 0) ? false : true) : ((currentSaving == 0) ? false : true)} onClick={() => handleLoanAgreement()}
              className={"footer-btn Bmargin " + (show == 1 ? ((currentSaving == 1 || currentSaving == 0) ? " footer-btn-activate" : "") : ((currentSaving == 0) ? " footer-btn-activate" : ""))}>{!resp ?
                "Continue" :
                <div className="continue-lottie">
                  <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                </div>}</button>
          </div>
        </>}
    </>

  )
}

export default TandC;