import React, { useEffect } from 'react'
import "./Cancellation.css";
import "../footermorelinkspages.css";
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';

const Cancellation = () => {
  const [y, sety] = React.useState(true);
  window.addEventListener("scroll", (event) => {
    if (window.scrollY >= 157) {
        sety(false);
    }
    else {
        sety(true);
    }
});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      {y && <RightSidePopUp />}
     <div  className='policymain-container-head'> <h2 className='sachhead'>CANCELLATION AND REFUND POLICY</h2></div>
    <div className='sachmain'>
    <p className='sachp'>MoneyWide strongly recommends that the borrowers read, understand and familiarize themselves with our cancellation &amp; refund policy prior to using any product of MoneyWide. </p>
    <p className='sachp'>In the event of cancellation of services due to any unavoidable reasons we must be notified of the same in writing at care@moneywide.com. Cancellation request should be placed within 24 hours of agreement. Cancellation will be as per terms of the loan agreement and be effective from the date we receive and accept the cancellation request advice in writing;</p>
    <p className='sachp'>On cancelation of loan during the cooling off/ look up period, the customer would be required to pay back the principal amount and applicable APR as disclosed in the KFS and / or loan agreement and no other charges would be applicable.</p>
    <p className='sachp'>Any refunds, if applicable within the purview of our refund policy, will be processed over a period of 7 working days. The refund policy is subject to change from time to time by MoneyWide. The changes will apply prospectively and will be notified on the Website. In case borrowers do not wish to accept the revised policy, borrowers should not continue to use the services and inform us about the same on care@moneywide.com. If borrowers continue to use the services after the date on which the change comes into effect, the use of the services indicates your agreement to be bound by the revised refund policy.</p>
    <p className='sachp'>Please note that there may be certain instructions to remit payments or seek rendition of services that MoneyWide is unable to accept. We reserve the right, at our sole discretion, to refuse or cancel any order for any reason. We may also require additional verifications or information before accepting any instruction or registering you on our portal as a borrower. </p>
    <p className='sachp'><b>Effective date: 7th September 2022</b></p>
    </div>
    </>
  )
}

export default Cancellation;