
import React, { useEffect, useState } from 'react';
import './career.css';
import Form from './Form';
import { useSelector, useDispatch } from 'react-redux';
import { applyForJob, GenerateToken } from '../../Redux/action';
import CareerSlider from './CareerSlider';
import { BlueArr } from '../../Icons/CodeIcons/codeicons';
import { getCareerFunctions, getCareerIdData, getCareerSliderData, getToken } from '../EverCalledFunctions/EverCalledApis';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import { prodbase_url, react_base_url } from '../Secret_Credentials/Secret_Credential';
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';
import { Link } from 'react-router-dom';


const Careers = () => {
    const [noOpening, setNoOpening] = React.useState(0);
    const [openForm, setOpenForm] = useState(false)
    const [loader, setLoader] = useState(true)
    const [newErrCase, setNewErrCase] = useState({
        show: false, data: ""
    })
    const [selectedForm, setSelecteedForm] = useState({
        data: {},
        id: ""
    })
    const [AllSliderData, setAllSliderData] = useState({})
    const [showSliderData, setShowSliderData] = useState({})
    const [functionValues, setFunctionValues] = useState([])

    const dispatch = useDispatch()
    const { token } = useSelector(state => state)

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        if (token === "") {
            myToken()
        }
    }, [])

    const preLoadData = async () => {
        const APIDATA = await getCareerFunctions(token)
        if (APIDATA.status === "1" && APIDATA.status_code === "200") {
            setFunctionValues(APIDATA.data.function_details);
            const SliderResponse = await getCareerSliderData("", token);
            if (SliderResponse.status === "1" && SliderResponse.status_code === "200") {
                setAllSliderData(SliderResponse.data)
                setShowSliderData(SliderResponse.data)
                setLoader(false)
                window.scrollTo(0, 0)
                setNoOpening(2)
            } else {
                setLoader(false)
                setNoOpening(1)
            }
        } else {
            setLoader(false)
            setNoOpening(1)
        }
    }

    useEffect(() => {
        if (token !== "") {
            preLoadData()
        }
    }, [token])

    const updateSelectedData = async (data) => {
        setLoader(true)
        const APIDATA = await getCareerIdData(data.id, token)
        if (APIDATA.status === "1" && APIDATA.status_code === "200") {
            setOpenForm(true);
            setShowSliderData(AllSliderData)
            setSelecteedForm({ ...selectedForm, id: data.id, data: APIDATA.data })
            setLoader(false)
            var jobDEsCheck = document.getElementById('job-des-open');
            if (jobDEsCheck) {
                jobDEsCheck.scrollIntoView({ behavior: "smooth" })
            }
        } else {
            setNewErrCase({ show: true, data: data })
        }
    }


    const handleChange = (e) => {
        if (e.target.value) {
            setLoader(true)
            const filteredData = AllSliderData.filter((row) => {
                return row.function.toLowerCase() === e.target.value.toLowerCase();
            })
            setShowSliderData(filteredData)
            setLoader(false)
        } else {
            setShowSliderData(AllSliderData)
        }
    }

    const managereCase = async () => {
        var data = newErrCase.data;
        setNewErrCase({ ...newErrCase, show: false })
        setLoader(true)
        const APIDATA = await getCareerIdData(data.id, token)
        if (APIDATA.status === "1" && APIDATA.status_code === "200") {
            setOpenForm(true);
            setShowSliderData(AllSliderData)
            setSelecteedForm({ ...selectedForm, id: data.id, data: APIDATA.data })
            setLoader(false)
            var jobDEsCheck = document.getElementById('job-des-open');
            if (jobDEsCheck) {
                jobDEsCheck.scrollIntoView({ behavior: "smooth" })
            }
        } else {
            setNewErrCase({ show: true, data: data })
        }
    }


    return (

        <>
            {newErrCase.show && <NewErrorCase handleClick={managereCase} />}
            {openForm && <Form data={selectedForm} setOpenForm={setOpenForm} />}
            {loader ? <CKYCWaiting /> : <div className={"career-section" + (openForm ? " remove_back" : "")}>
                {!openForm && <div className="career-banner">
                    <div className={"partner-boxing car-box"}>
                        <div className="career-top-content">
                            <div className="left-top">
                                <Link to={`/${window.location.search}`}>Home</Link>  &gt;{" "}
                                <span>Career</span>
                            </div>
                            <h3 className="career-heading">Join Us</h3>
                            <p>Together, we provide funds beyond top segments and
                                locations that improve people's lives. Want to be part of a
                                change? Find your role.</p>
                        </div>
                    </div>
                </div>}
                <div className="career-slider" style={openForm ? { marginTop: 0 } : {}}>
                    <div className="partner-boxing change-dir">
                        <div className="manage-Width">
                            {<div className="join-us-content" style={noOpening === 1 ? { justifyContent: "center", marginTop: "20px", lineHeight: "50px" } : {}}>
                                {noOpening === 1 ? <div style={{
                                    display: "flex",
                                    flexDirection: "column", gap: "20px"
                                }}><span className="section-heading-career">No Current Job Openings</span>
                                    <p style={{ lineHeight: "140%" }}>Want to be part of the MoneyWide family but could not find a matching job profile? Drop a line at <a href="mailto:hr@moneywide.com">hr@moneywide.com</a>. We will surely contact you in case there is any opening.</p></div> : noOpening === 2 ? <span className="section-heading-career">Open <b>Positions</b></span> : <></>}
                                {noOpening === 2 && <div className="filter-box">
                                    <div className="select-arr">
                                        <BlueArr h={28} w={28} />
                                    </div>
                                    <select onChange={(e) => handleChange(e)}>
                                        <option value="">Select Function</option>
                                        {functionValues && functionValues.length !== 0 && functionValues.map((row, ind) => {
                                            return <option key={ind} value={row.value}>
                                                {row.department_name}
                                            </option>
                                        })}
                                    </select>
                                </div>}
                            </div>}
                            {noOpening === 2 && <CareerSlider updateSelectedData={updateSelectedData} data={showSliderData} />}
                        </div>
                    </div>
                </div>
                <div className={"perks" + (noOpening === 1 ? " manage-no-case" : "")} >
                    <div className="partner-boxing">
                        <div className="manage-Width">
                            <div className="put-in-row">
                                <div className="in-left">
                                    <h3>Perks &</h3 >
                                    <h3> Benefits</h3>
                                    <p>Top 5 Reasons You Would Want to Work with Us</p>

                                    <div className="perks-left-ig">
                                        <img src={`${react_base_url}assets/images/Careers/PerksLeft.svg`} alt="NA" />
                                    </div>
                                    <div className="careers-reasons rs-5">
                                        <img src={`${react_base_url}assets/images/Careers/rs_5.svg`} alt="na" />
                                        <p>
                                            <span>Health Benefits</span>
                                            <span>Comprehensive Health Insurance Policy</span>
                                        </p>
                                    </div>
                                    <div className="mob-reasons-display">
                                        <MobileReasons />

                                        <img src={`${react_base_url}assets/images/Careers/PerksRight.svg`} alt="NA" />
                                    </div>

                                </div>
                                <div className="in-right">
                                    <img src={`${react_base_url}assets/images/Careers/PerksRight.svg`} alt="NA" />
                                    <div className="careers-reasons rs-1">
                                        <img src={`${react_base_url}assets/images/Careers/rs_1.svg`} alt="na" />
                                        <p>
                                            <span>ESOPs</span>
                                            <span>Employee Stock Ownership Plan</span>
                                        </p>
                                    </div>
                                    <div className="careers-reasons rs-2">
                                        <img src={`${react_base_url}assets/images/Careers/rs_2.svg`} alt="na" />
                                        <p>
                                            <span>Growth</span>
                                            <span>Exciting learning opportunities</span>
                                        </p>
                                    </div>
                                    <div className="careers-reasons rs-3">
                                        <img src={`${react_base_url}assets/images/Careers/rs_3.svg`} alt="na" />
                                        <p>
                                            <span>Location</span>
                                            <span>Central Location for Easy Commute</span>
                                        </p>
                                    </div>
                                    <div className="careers-reasons rs-4">
                                        <img src={`${react_base_url}assets/images/Careers/rs_4.svg`} alt="na" />
                                        <p>
                                            <span>Vouchers</span>
                                            <span>Attractive food & shopping Vouchers</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hr-box">
                    <div className="partner-boxing">
                        <div className="manage-Width">
                            <div className="hr-boxing">
                                <div className="hr-cont">
                                    <span>Contact HR</span>
                                    <span>In a quest to fund wider smiles</span>
                                </div>
                                <div className="hr-intro">
                                    <span>Email: hr@moneywide.com</span>
                                </div>
                                <div className="hr-left-des">
                                    <img src={`${react_base_url}assets/images/Careers/hrBottomRight.svg`} alt="na" />
                                </div>
                                <div className="hr-right-des">
                                    <img src={`${react_base_url}assets/images/Careers/hrTopLeft.svg`} alt="na" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mw-about">
                    <div className="partner-boxing">
                        <div className="manage-Width">
                            <div className="put-in-row">
                                <div className="in-left">
                                    <div className="mob-career-im">
                                        <img src={`${react_base_url}assets/images/Careers/mobile-moneywide-img.webp`} alt="NA" />
                                    </div>
                                    <div className="mw-cont">
                                        <div className="mw-heading">
                                            About <b>MoneyWide</b>
                                        </div>
                                        <p>MoneyWide is a digital lending application that offers 100% digital, paperless and instant personal loans using cutting-edge proprietary technology. We are a part of the PB Fintech Group that runs India’s leading marketplaces, Policybazaar and Paisabazaar. All loans are offered with 100% transparency and strict adherence to RBI’s Digital Lending Guidelines. MoneyWide is a registered trademark.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Careers;

const MobileReasons = () => {

    const Values = [
        {
            img: `${react_base_url}assets/images/Careers/rs_1.svg`,
            heading: "ESOPs",
            content: "Employee Stock Ownership Plan"
        }, {
            img: `${react_base_url}assets/images/Careers/rs_2.svg`,
            heading: "Growth",
            content: "Exciting learning opportunities"
        }, {
            img: `${react_base_url}assets/images/Careers/rs_3.svg`,
            heading: "Location",
            content: "Central Location for Easy Commute"
        }, {
            img: `${react_base_url}assets/images/Careers/rs_4.svg`,
            heading: "Vouchers",
            content: "Attractive food & shopping Vouchers"
        }, {
            img: `${react_base_url}assets/images/Careers/rs_5.svg`,
            heading: "Health Benefits",
            content: "Comprehensive Health Insurance Policy"
        }
    ]
    return <>
        {Values.map((res, ind) => {
            return <div className="mob-reasons" key={ind}>
                <img src={res.img} alt="NA" />
                <p>
                    <span>{res.heading}</span>
                    <span>{res.content}</span>
                </p>
            </div>
        })}
    </>
}