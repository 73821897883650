import React from 'react';
import { useState, useEffect } from 'react';
import './MyAccount.css';
import MyProfile from './MyProfile';
import MyAccountMenuBox from './MyAccountMenuBox';
import MyLoan from './MyLoan';
import TrackApplication from './TrackApplication';
import TransactionHistory from './TransactionHistory';
import Repayment from './Repayment';
import AccountStatement from './AccountStatement';
import ForeClosure from './ForeClosure';
import queryString from "query-string";
import OverDueStatement from './OverDueStatement';
import PartPayment from './PartPayment';
import { useDispatch, useSelector } from 'react-redux';
import { updateSuggestion_DropDown, updateheaderdata } from '../../Redux/action';
import { useNavigate } from 'react-router';
import NotFound from '../NotFound/NotFound';
import { HeaderApi, getLoginData } from './MyAccountAPIs';
import Settings from './Settings';
import DataDeleteForm from './DataDeleteForm'
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';

const MyAccount = () => {
    const [loader, setLoader] = useState(true)
    const [APIErr, setAPIErr] = useState(false)
    const [parentId, setParentId] = useState("")
    const [userName, setUserName] = useState("")
    const [currentPage, setCurrentPage] = useState("")
    const [pageNotFound, setPageNotFound] = useState(false)
    const dispatch = useDispatch()
    const { suggestionDropDown, token } = useSelector(state => state)
    const navigate = useNavigate();

    const GetUserName = () => {
        let preData = getLoginData("NO");
        if (preData && preData?.userName) {
            setUserName(preData?.userName)
        }
    }

    const url = window.location.href
    const shouldRender = !url.includes('cust_id');

    const ApiHeader = async () => {
        const APIResponse = await HeaderApi(0, token)
        if (APIResponse.status == "1" && APIResponse.status_code == "200") {
            //if (checkUrlExist(APIResponse.data)) {
                dispatch(updateheaderdata({ data: APIResponse.data }));
                setLoader(false)
            // } else {
            //     // sessionStorage.clear()
            //     window.location.href = `${react_base_url}login`
            // }
        } else {
            setLoader(false)
            setAPIErr(true)
        }
    }

    useEffect(() => {
        ApiHeader();
        GetUserName();
        var currentURL = window.location.href;
        
        if (currentURL.includes('/profile')) {
            setCurrentPage('profile');
        }
        else if (currentURL.includes('/track-application')) {
            setCurrentPage('track-application');
        }
        else if (currentURL.includes('/loan-account-details')) {
            setCurrentPage('Transaction History');
        }
        else if (currentURL.includes('/myloan')) {
            setCurrentPage('myloan');
        } else if (currentURL.includes('loan-wise-emi-schedule')) {
            setCurrentPage('repayment');
        } else if (currentURL.includes('loan-wise-statements')) {
            setCurrentPage('Account Statement');
        } else if (currentURL.includes('loan-foreclosure-details')) {
            var URLSTRING = queryString.parse(window.location.search)
            if (URLSTRING.req_type === "Mg==") {
                setCurrentPage('Part Payment');
            } else if (URLSTRING.req_type === "MQ==") {
                setCurrentPage('Foreclosure');
            } else {
                setPageNotFound(true)
            }
        } else if (currentURL.includes('overdue-payment')) {
            setCurrentPage('Overdue Payment');
        }
        else if (currentURL.includes('/settings')) {
            setCurrentPage('settings');
        } else if (currentURL.includes('deletion')) {
            setCurrentPage('deletion')
        }
    }, [])

    const handleBack = () => {
        window.location.reload();
    }



    const handleAllDropDown = (e) => {
        if (suggestionDropDown.show) {
            dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: false }))
        }
    }


    return (
        <>
            {APIErr && <NewErrorCase handleClick={handleBack} />}
            {loader && <CKYCWaiting />}
            {pageNotFound && <NotFound />}
            { !loader && !APIErr && <div className='account-menu' onClick={(e) => handleAllDropDown(e)}>
                <div className="myAccountSection" >
                    <span className="myAccountName">{userName}</span>
                    <div className="myAccountContent">
                        {shouldRender && <MyAccountMenuBox setParentId={setParentId} />}
                        <div className="myProfileRightSide" >
                            {currentPage === "myloan" && <MyLoan setUserName={setUserName} parentid={parentId} />}
                            {currentPage === "profile" && <MyProfile setUserName={setUserName} />}
                            {currentPage === "track-application" && <TrackApplication />}
                            {currentPage === "Transaction History" && <TransactionHistory />}
                            {currentPage === "repayment" && <Repayment />}
                            {currentPage === "Account Statement" && <AccountStatement />}
                            {currentPage === "Foreclosure" && <ForeClosure />}
                            {currentPage === "Part Payment" && <PartPayment />}
                            {currentPage === "Overdue Payment" && <OverDueStatement />}
                            {currentPage === "settings" && <Settings />}
                            {currentPage === "deletion" && <DataDeleteForm />}
                        </div>
                    </div>
                </div>
            </div> }
        </>
    )
}

export default MyAccount
