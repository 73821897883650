import React, { useState } from 'react';
import RangeSlider from './RangeSlider';
import { useDispatch, useSelector } from 'react-redux';
import circle from '../PersonalLoan/images/circle.webp'
import pipes from '../PersonalLoan/images/pipes.webp'
import EmiIcon from "../../Icons/emi.svg";
import PartpayIcon from "../../Icons/partpayment.svg"
import PartpaymentTenure from "../../Icons/partpayment-tenure.svg";

import PrePaymentCalculation from './PrePaymentCalculation';
import { EmiPaid, partPatmentAmt, partPaymentTenure, updatepartpaymentcheckbox,updaterevisedoutstenurelt } from '../../Redux/action';
import { react_base_url } from '../Secret_Credentials/Secret_Credential';

function PrePaymentCalculator() {
    const { pa, lt, roi, emi, emiPaid, PartPayAmt, PartPayTenure, partpaymentcheckbox,originalOutsTenlt } = useSelector((state) => state);
    const [state, setState] = React.useState([])
    const dispatch = useDispatch()

    const handleInputChangee = (e) => {
        if(e.target.value < lt-emiPaid){
            dispatch(partPaymentTenure(e.target.value))
        }  
    };

    const handleInputChange = (event) => {
        if (event.target.value <= lt ) {
            dispatch(EmiPaid(event.target.value))
        }
    };

    // const handleChangeBlur =(event)=>{
    //     if (event.target.value <= lt && event.target.value>=3) {
    //         dispatch(EmiPaid(event.target.value))
    //     }else if(event.target.value<3){
    //         dispatch(EmiPaid(0))            
    //     }
    // }

    const handleInputChange2 = (event) => {
        if (event.target.value <= pa) {
            dispatch(partPatmentAmt(event.target.value))
        }
    };

    React.useEffect(()=>{
        if(lt<=emiPaid){
            dispatch(EmiPaid(lt))
        }
        if(((originalOutsTenlt - PartPayTenure) <= 1) && ((originalOutsTenlt - PartPayTenure) != 0) && (PartPayTenure >= 1)) {
            dispatch(partPaymentTenure(PartPayTenure - 1));
        }

    },[lt])
    React.useEffect(()=>{
        if(pa<PartPayAmt){
            dispatch(partPatmentAmt(pa))
        }

    },[pa])




    React.useEffect(() => {
        let prin_cf = pa;
        let arr = [];
        for (let i = 0; i < lt; i++) {
            let int_payable = (roi * prin_cf / 1200);
            let principal_repaid = (emi - int_payable);
            prin_cf = (prin_cf - principal_repaid);
            let payload = {
                key: i + 1,
                interest: Math.round(int_payable),
                principal: Math.round(principal_repaid),
                balance: Math.abs(Math.round(prin_cf))
            }
            arr = [...arr, payload];
        }
        setState(arr)
    }, [pa, lt, roi, emi])

    const handleScroll = () => {
        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        window.location.href = `${react_base_url}personal-loan`
    }

    const handleChange = (e) => {

    }

    const checkboxClick = (ind) => {
        if (ind == 1 && partpaymentcheckbox == 2) {
            dispatch(updatepartpaymentcheckbox(1))
        } else if (ind == 2 && partpaymentcheckbox == 1) {
            dispatch(updatepartpaymentcheckbox(2))           
        }

    }

    return (
        <div className="calculator-main">
            <div className="calculator-head">
                <h3>MoneyWide Personal Loan <b>Part Payment Calculator</b></h3>
                <p>Use the MoneyWide Part Prepayment calculator to calculate the impact of part prepayment and determine how much money you can save while reducing the EMI amount or loan tenure.</p>
            </div>
            <div className="calculator-body">
                <div className="calc-bg-first">
                    <img src={circle} alt="circle" />
                </div>
                <div className="calculator-body-left">
                    <h2>Part Payment Calculator</h2>
                    <RangeSlider heading="Loan Amount" symbol={"(₹)"} text1="₹ " text2="" text3={"10000"} text4={"25L"} id="id1" uid="uid1" min="10000" max="2500000" step="10000" id_in_div="uid-1" value={pa} handleChange={(e) => handleChange(e)} />

                    <RangeSlider heading="Original Loan Tenure (Months)" symbol={""} text1="" text2=" Months" text3={"3 Months"} text4={"84 Months"} id="id2" uid="uid2" min="3" max="84" step="1" id_in_div="uid-2" value={lt} handleChange={(e) => handleChange(e)} />

                    <RangeSlider heading="Current Rate of Interest" symbol={"(%)"} text1="" text2=" %" text3={"5%"} text4={"32%"} id="id3" uid="uid3" min="5" max="32" step="1" id_in_div="uid-3" value={roi} handleChange={(e) => handleChange(e)} />

                    <div className="">
                        <div className="foreclose-emi-paid">
                            <img className="tag-icon" src={EmiIcon} style={{ width: "28px" }} />
                            <input className="input-tag-box" id="input-tag-box" type="text" placeholder='Number of EMIs Paid' value={emiPaid} onChange={handleInputChange} autoComplete="off" />
                        </div>
                        <div className="foreclose-emi-paid">
                            <img className="tag-icon" src={PartpayIcon} style={{ width: "28px" }} />
                            <input className="input-tag-box2" id="input-tag-box2" type="text" placeholder='Part Payment Amount' value={PartPayAmt} onChange={handleInputChange2} autoComplete="off" />
                        </div>
                    </div>
                    <div className="options-confirm party-pay-radio">
                        <div className="partpay-radio-head">
                            <span>Want to reduce?</span>
                        </div>
                        <div className="part-pay-radio-box">
                            <label>
                                <input type="radio" name="radioButton" onClick={() => checkboxClick(1)} value="option1" checked={partpaymentcheckbox === 1} />Loan EMI</label>
                            <label>
                                <input type="radio" onClick={() => checkboxClick(2)} name="radioButton" value="option2" checked={partpaymentcheckbox === 2} /> Loan Tenure </label>
                        </div>
                        {partpaymentcheckbox === 2 && (
                            <div className="foreclose-emi-paid">
                                <img className="tag-icon" src={PartpaymentTenure} style={{ width: "28px" }} />
                                <input className="input-tag-box3" id="input-tag-box3" type="text" placeholder='Partpayment Tenure' value={PartPayTenure} onChange={handleInputChangee} autocomplete="off" />
                                
                            </div>
                        )}
                    </div>

                </div>
                <div className="calculator-body-right">
                <h3>Your Result</h3>
                    <PrePaymentCalculation />
                    <div className="calculator-btns">
                        <button className="apply-now-btn" onClick={handleScroll}>Apply Now</button>
                    </div>
                </div>
                <div className="calc-bg-second">
                    <img src={pipes} alt="pipes" />
                </div>
            </div>

        </div>
    )
}

export default PrePaymentCalculator;