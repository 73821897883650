import React, { useEffect, useState } from "react";
import "./eMandate.css";
import FormHead from "../Journey Form/Form/FormHead";
import bank from "../../Icons/bank.svg";
import npci from "../../Icons/NPCI.webp";
import { useDispatch, useSelector } from "react-redux";
import { prodbase_url, source, react_base_url, verification_source, verified_by, verifier_name } from "../Secret_Credentials/Secret_Credential";
import axios from "axios";
import { updateCredentialsState, updateFormState, updateSuggestion_DropDown } from "../../Redux/action";
import ctaAnimation from "../assests/lootie-files/continue.json";
import Lottie from "react-lottie-player";
import Base64 from "base-64";
import { getMasterData } from "../EverCalledFunctions/EverCalledApis";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
import { useForm } from "react-hook-form";
import queryString from "query-string";
import AlertIcon from '../../Icons/alert_icon_blue.svg';
import { AAAPIInstance, apiInstance } from "../Config/APIConfig";
import { useSearchParams } from "react-router-dom";

const NewAnalyzer = () => {
    const dispatch = useDispatch();
    const [via, setVia] = useState("");
    const { credentialState, mw_application_id, token, suggestionDropDown,formStates } = useSelector((state) => state);
    const [resp, setResp] = useState(false);
    const [supportFlag, setSupportFlag] = useState({
        digitap_institution_id: 0,
        option: ""
    });
    const [rocket, setRocket] = useState(false);
    const [show, setShow] = useState(true);
    const [masterData, setMasterData] = useState([]);
    const [bankName, setBankName] = useState({ show: false, data: [] });
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        clearErrors,
    } = useForm({
        defaultValues: {
            bank_name: "",
        },
        mode: "all",
    });
    const data = watch();
    const [selected, setSelected] = useState(-1);
    const [statement_upload, setstatement_upload_val] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const elg = searchParams.get("is_statement_upload");

    useEffect(() => {
        if (elg) {
            setstatement_upload_val(true)
        }
    }, [])


    const encryptingRedirection = () => {
        let query_id = Base64.encode(mw_application_id);
        query_id = Base64.encode(query_id);
        let cust_id = Base64.encode(credentialState.cust_id);
        cust_id = Base64.encode(cust_id);
        let qry = Base64.encode(`query_id=${query_id}&cust_id=${cust_id}`);
        qry = Base64.encode(qry);
        return qry;
    }

    const Lead = async(id,cust)=>{
        let pay = {
        "source": "MWide",
        "verification_source": 1,
        "verified_by": 2,
        "verifier_name": "system",
        "mw_application_id" : id,
        "customer_id" : cust
        }
        let res = await apiInstance.post("lead-status-update",pay,{token});
        }
        
        useEffect(()=>{
        if(mw_application_id != "" && credentialState.cust_id != ""){
        Lead(mw_application_id,credentialState.cust_id);
        }
        },[mw_application_id,credentialState.cust_id])

    const onSubmit = async () => {
        if ((via == "1") || (via == "2")) {

            setResp(true);

            let obj = {
                url: "bsadigi-generate-url",
                data: {
                    customer_id: credentialState.cust_id,
                    destination: via == 1 ? "statementupload" : "netbanking",
                    mw_application_id,
                    level_id: 1,
                    partner_id: 5,
                    product_id: 0,
                    redirect_url: `${react_base_url}analyzer/redirect/?fid=${encryptingRedirection()}${window.location.search.includes("app=MQ==") ? "&app=MQ==" : ""}&transID=%s&status=%s`,
                    source: "MWide",
                    verification_source: 1,
                    verified_by: 2,
                    verifier_name: "System",
                },
                token: token
            }

            // let pay = {
            //     customer_id: credentialState.cust_id,
            //     destination: via == 1 ? "statementupload" : "netbanking",
            //     mw_application_id,
            //     level_id: 1,
            //     partner_id: 5,
            //     product_id: 0,
            //     redirect_url: `${react_base_url}analyzer/redirect/?fid=${encryptingRedirection()}${window.location.search.includes("app=MQ==") ? "&app=MQ==" : ""}&transID=%s&status=%s`,
            //     source: "MWide",
            //     verification_source: 1,
            //     verified_by: 2,
            //     verifier_name: "System",
            // }

            // if (supportFlag.digitap_institution_id != 0) {
            //     pay = {
            //         ...pay,
            //         digitap_institution_id: supportFlag.digitap_institution_id
            //     }
            // }

            if(supportFlag.digitap_institution_id != 0) {
                let { data } = obj;
                data = {...data, digitap_institution_id: supportFlag.digitap_institution_id}
                obj = {...obj, data: data}
            }
            let decBsaDigi = await AAAPIInstance(obj)
            // let decBsaDigi = await apiInstance
            //     .post(
            //         `bsadigi-generate-url`, pay, {
            //             "token": token,
            //             "log": 1
            //           }
            //     )
                // .then((res) => {

                    if (decBsaDigi.status === "1" && decBsaDigi.status_code === "200") {
                        window.location.href = decBsaDigi.data.digitap_url
                    } else {
                        setRocket(true)
                    }
                // }).catch((err) => {
                //     setRocket(true);
                // });
        }
        else if (via == 3) {

            setResp(true);
            let qry = encryptingRedirection();
            let obj = {
                url:"finpro-consent-data",
                data:{
                    "product_id": 0,
                    "partner_id": credentialState?.step_5?.finpro_partner_id ? credentialState?.step_5?.finpro_partner_id : 425,
                    "customer_id": credentialState.cust_id,
                    "level_id": 1,
                    "lead_id": mw_application_id,
                    "redirection_url": `${react_base_url}analyzer/redirect/?fid=${qry}${window.location.search.includes("app=MQ==") ? "&app=MQ==" : ""}`,
                    "verified_by": verified_by,
                    "verifier_name": verifier_name,
                    "verification_source": verification_source,
                    "source": source
                },
                token:""
            }
            let decFinpro = await AAAPIInstance(obj)
            // .then((res) => {
                if ((decFinpro.status == "1") && ((decFinpro.status_code == "200"))) {
                    window.location.href = decFinpro.data
                }
            // }).catch((err) => {
            //     setRocket(true);
            // })
        }
    };

    const handleError = (e) => {
        window.location.reload()
    }

    const getbankname = async () => {
        const APIDATA = await getMasterData("bank_name", "");
        setMasterData(APIDATA);
    };

    useEffect(() => {
        if (token != "") {
            if (masterData.length === 0) {
                getbankname()

            }
        }
    }, [token])

    useEffect(() => {
        let qry = queryString.parse(window.location.search);
        if(qry?.aa_eligibility){
            setShow(false);
        }
    }, [])

    useEffect(()=>{
        if(data.bank_name == ""){
            setVia("");
        }
    },[data.bank_name])

    const handleBankName = (e) => {
        if (data.bank_name && e.keyCode !== 13) {
            if (masterData.length > 0) {
                let filt = []
                var newData = masterData.filter((row) => {
                    let bnk_name = row.value.toLowerCase();
                    if (bnk_name == data.bank_name.toLocaleLowerCase().trim()) {
                        filt.push(row);
                    }
                    return bnk_name.includes(data.bank_name.toLowerCase());
                });
                if (filt.length == 1) {
                    setSupportFlag({ ...supportFlag, option: filt[0].is_aa_support_flag, digitap_institution_id: filt[0].digitap_inst_id })
                    if(filt[0].is_aa_support_flag == 0 && !elg){
                        dispatch(updateCredentialsState({ ...credentialState, newState: true }))
                        dispatch(updateFormState({ ...formStates, BsaNewScreen: false }))
                    }
                } else {
                    setSupportFlag({ ...supportFlag, option: 0, digitap_institution_id: 0 })
                }
                if (newData.length !== 0) {
                    if (newData.length == 1 && data.bank_name.toLocaleLowerCase() == newData[0].value.toLowerCase()) {
                        clearErrors("bank_name");
                        setBankName({ ...bankName, show: false });
                        dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: false }));
                    } else {
                        setBankName({ ...bankName, data: newData, show: true });
                        dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: true }));
                    }
                } else {
                    setBankName({ ...bankName, data: newData, show: false });
                }
            }
        }
        if(data.bank_name && e.keyCode == 13){
            if (masterData.length > 0) {
                let filt = []
                var newData = masterData.filter((row) => {
                    let bnk_name = row.value.toLowerCase();
                    if (bnk_name == data.bank_name.toLocaleLowerCase().trim()) {
                        filt.push(row);
                    }
                    return bnk_name.includes(data.bank_name.toLowerCase());
                });
                if(filt.length == 0 && !elg){
                    dispatch(updateCredentialsState({ ...credentialState, newState: true }))
                    dispatch(updateFormState({ ...formStates, BsaNewScreen: false }))
                }
            }
        }
    };

    const handleArrows = (e) => {
        if (bankName.show) {
            var key = e.keyCode;
            if (key != 40 && key != 38 && key != 13) {
                return;
            }
            if (key == 13) {
                e.stopPropagation();
                if (selected > -1 && selected < bankName.data.length) {
                    setThisValue(bankName.data[selected]);
                    setSelected(-1);
                } else {
                    setSelected(-1);
                }
            } else if (key == 38) {
                if (selected > 0) {
                    setSelected(selected - 1);
                }
            } else if (key == 40) {
                if (selected < bankName.data.length - 1) {
                    setSelected(selected + 1);
                }
            }
        }
    };

    const setThisValue = (data) => {
        setValue("bank_name", data.value);
        clearErrors("bank_name");
        setBankName({ ...bankName, show: false });
        setSupportFlag({ ...supportFlag, option: data.is_aa_support_flag, digitap_institution_id: data.digitap_inst_id })
        if(data.is_aa_support_flag == 0 && !elg){
            dispatch(updateCredentialsState({ ...credentialState, newState: true }))
            dispatch(updateFormState({ ...formStates, BsaNewScreen: false }))
        }
        dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: false }));
    };

    const bankNameExist = () => {
        return bankName.data.some(item => item.value.toLocaleLowerCase() === data.bank_name.toLocaleLowerCase())
    }

    return (
        <>
            {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
            <div>
                <FormHead formName="Confirm Salary Credits" formSubHead={"Share following details to validate salary credits"} />
                <div className="emandate-cont">
                    <form autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                        {!show && <div className='numberVerification-head'>
                            <div style={{ padding: "12px 10px" }}>
                                <img src={AlertIcon} alt="AlertIcon" />
                            </div>
                            <div>
                                <p style={{ textAlign: "left" }}>Sorry!! We are unable to process your application through OTP. Please try again with NetBanking.</p>
                            </div>
                        </div>}
                        <p className="emandate-setup">
                            Provide details using:
                        </p>
                        <div className="">
                            <div className={errors.bank_name ? "input-tag error-border" : data.bank_name ? "input-tag add-border" : "input-tag "}>
                                <img className="tag-icon" src={bank} />
                                <input disabled={resp} className="input-tag-box " type="text" placeholder={"Bank Name"} {...register("bank_name", { required: true })} autoComplete="off" onKeyDown={(e) => handleArrows(e)} onKeyUp={(e) => handleBankName(e)} />
                                {suggestionDropDown.show && bankName.show && data.bank_name && bankName.data.length !== 0 && bankName.data.length && (<div className="dropDownBox">
                                    {bankName.data.map((row, ind) => {
                                        return (
                                            <div className={"dropDownValue" + (selected === ind ? " select" : "")} key={ind} onClick={() => setThisValue(row)} >
                                                {row.value}
                                            </div>
                                        );
                                    })}
                                </div>
                                )}
                            </div>
                        </div>
                        {data.bank_name && <div className="emandate-option">
                            {supportFlag.option == 1 && show && <><div className="emandate-option-1" onClick={() => setVia(3)}>
                                <div className="emandate-radio">
                                    <input readOnly disabled={resp} name="otp" onClick={() => setVia(3)} checked={via.toString() === "3"} value="otp" style={{ height: "20px", width: "20px", accentColor: "#0FA82E", }} type="radio" />
                                </div>
                                <div className="emandate-option-1-info recommend-emandate">
                                    <div>
                                        <p className="emandate-option-p-1">OTP</p>
                                        <p className="emandate-option-p-2">
                                            Verify Bank Details using OTP
                                        </p></div>
                                    <div className="analyzer-recomended">
                                        <span>(Recommended)</span>
                                    </div>
                                </div>
                            </div>
                                {supportFlag.option == 1 && statement_upload &&
                                    <hr className="emandate-line" />}
                            </>}
                            {((supportFlag.option == 1) || (supportFlag.option == 0 && elg && bankNameExist())) && statement_upload && <div className="emandate-option-1" onClick={() => setVia(1)}>
                                <div className="emandate-radio">
                                    <input readOnly disabled={resp} name="debitcard" onClick={() => setVia(1)} checked={via.toString() === "1"} value="statementupload" style={{ height: "20px", width: "20px", accentColor: "#0FA82E", }} type="radio" />
                                </div>
                                <div className="emandate-option-1-info">
                                    <p className="emandate-option-p-1">Statement Upload</p>
                                    <p className="emandate-option-p-2">
                                    Latest 3 months Bank Statement in PDF
                                    </p>
                                </div>
                            </div>}
                            {((supportFlag.option == 1) || (supportFlag.option == 0 && elg && bankNameExist())) && show && <hr className="emandate-line" />}
                            {((supportFlag.option == 1) || (supportFlag.option == 0 && elg && bankNameExist())) && <div className="emandate-option-1" onClick={() => setVia(2)}>
                                <div className="emandate-radio">
                                    <input readOnly disabled={resp} name="netbanking" onClick={() => setVia(2)} checked={via.toString() === "2"} value="netbanking" style={{ height: "20px", width: "20px", accentColor: "#0FA82E", }} type="radio" />
                                </div>
                                <div className="emandate-option-1-info">
                                    <p className="emandate-option-p-1">Net Banking</p>
                                    <p className="emandate-option-p-2">
                                        Active net banking and credentials will be <br />needed
                                    </p>
                                </div>
                            </div>}
                        </div>}
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p style={{ fontSize: "14px", color: "#1C1939" }}>
                                Secured Verified by
                            </p>
                            <img className="npci-logo" src={npci} alt="notfound" />
                        </div>
                        <div style={{ marginTop: "-5px" }}>
                            <p className="emandate-other">
                                Note:-  We will only access banking transaction data. Your funds remain 100% safe with no access to any third party, including us.
                                MoneyWide
                            </p>
                        </div>
                        <button type="submit" disabled={resp} className={"footer-btn Bmargin" + (via && data.bank_name ? " footer-btn-activate" : "")} >
                            {!resp ? ("Authorize") : (<div className="continue-lottie">
                                <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                            </div>
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default NewAnalyzer