import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import { set } from "react-hook-form";
import "../Journey Form/Form/otp.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  prodbase_url,
  base_url,
  client_id,
  client_key,
  source,
  verification_source,
  verified_by,
  verifier_name
} from "../Secret_Credentials/Secret_Credential";
import { GenerateToken, updateFormState, updateservicecredential, updateserviceshow } from "../../Redux/action";
import {change_personal_loan_page } from "../../Redux/action";
import { getToken, SendOTP } from "../EverCalledFunctions/EverCalledApis";
import { handleKeyPress } from "../EverCalledFunctions/EverCalledFunctions";

const OTP = () => {
  const [timer, setTimer] = useState(30);
  const [show, setShow] = useState(true);
  const {
    loginDetails,
    serviceCred,
    serviceNumber,
    setService,
    personal_loan,
    token,
    formStates
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [firstSubmit, setFirstSubmit] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    },
  });
  const data = watch();
  const [ip, setIP] = useState('');
  const [device_id, setDevice_id] = useState();
  const [count,setCount] = useState(1);

  // function knowdevice() {
  //   if (window.innerWidth > 1024) {
  //     setDevice_id("1");
  //   }
  //   else if (window.innerWidth > 480 && window.innerWidth < 1024) {
  //     setDevice_id("3")
  //   }
  //   else {
  //     setDevice_id("2");
  //   }
  // }

  // const getData = async () => {
  //   const res = await axios.get('https://geolocation-db.com/json/')
  //   setIP(res.data.IPv4)
  // }

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }


  useEffect(() => {
    if(token == "") {
      myToken();
    }
    if(setService.count === 5){
      setShow(false);
    }
    // knowdevice();
    // getData()
  }, [])

  useEffect(() => {
    if(token !== ""){
      if (data.otp4) {
        document.getElementById("forth-tag").blur();
      }
      if ((data.otp1 && data.otp2 && data.otp3 && data.otp4) && (firstSubmit == false) && (count == 1)) {
        const otp = data.otp1 + data.otp2 + data.otp3 + data.otp4;
        const result = {
          source: source,
          otpId: serviceCred.Otp_id,
          otp,
          mobile: serviceNumber.mobile,
          source_from: 1,
          whatsapp_consent: loginDetails.whatsaap ? "1" : "0",
          "device_type":+(""),
          verification_source,
          verified_by,
          verifier_name,
          // device_type: +(device_id),
          // IP: ip,
          // lead_id: 0,
          // customer_id: 0,
          login_from: 2
        };
        const payLoad = {
          client_id: client_id,
          client_key: client_key,
          source: source,
        };
        // axios.post(`${base_url}/token`, payLoad).then((res) => {
        // axios.post(`${prodbase_url}token`, payLoad).then((res) => {
        //   const token = res.data.token;
  
          // axios.post(`${base_url}/verify-otp`, result, {
          axios
            .post(`${prodbase_url}verify-otp`, result, {
              headers: {
                token,
              },
            })
            .then((response) => {
              if ((response.data.status_code === "200") && (response.data.status === "1")) {
                // const pay = {
                //   client_id: client_id,
                //   client_key: client_key,
                //   source: source,
                // }
                // axios.post(`${prodbase_url}token`, pay)
                //   .then((res) => {
                //     const token = res.data.token;
                if(count == 1){
                  setCount(2);
                  const payload = {
                    "source": source,
                    verified_by,
                    verifier_name,
                    verification_source,
                    mobile: serviceNumber.mobile
                  }
                  axios.post(`${prodbase_url}cms-customer`, payload, {
                    headers: {
                      token
                    }
                  }).then((res) => {
                    console.log(serviceNumber.mobile);
                    if(res.data.data && res.data.data.customer_type === 2){                        
                      dispatch(updateservicecredential({...serviceCred,customer_id: res.data.data.cust_id,customer_type:res.data.data.customer_type}));
                      dispatch(updateserviceshow({...setService,newRequest: true,serviceOtp: false}));
                    }else if(res.data.data && res.data.data.customer_type===1 || res.data.data.customer_type===3){
                      dispatch(updateservicecredential({...serviceCred,customer_id: res.data.data.cust_id,customer_type:res.data.data.customer_type}));
                      dispatch(updateserviceshow({...setService,complain: true,serviceOtp: false}));
                    }
                    else{
                      dispatch(updateFormState({ ...formStates, error: true }));
                    }
                  })
                    .catch((err) => {
                      console.log(err);
                    })
                }
                  // })
              } else {
                setFirstSubmit(true);
              }
              // console.log(response);
            });
        // });
      }
    }
  }, [data,token, count]);

  const onSubmit = (data) => {
    // console.log(data);
  };

  const handleChange = (nextLocation, prevLocation, event, value) => {
    setValue(value, event.target.value);
    if (event.target.value.length > 0) {
      document.getElementById(nextLocation).focus();
    }
  };

  const handleKeyDown = (event, prevLocation) => {
    const key = event.keyCode || event.charCode;
    if (key == 8 || key == 46) {
      // console.log("Backspace Working");
      if (event.target.value.length === 0) {
        document.getElementById(prevLocation).focus();
      }
    }
  };

  const handleClick = (e) => {
    if (firstSubmit) {
      setValue("otp1", "");
      setValue("otp2", "");
      setValue("otp3", "");
      setValue("otp4", "");
      setFirstSubmit(false);
      document.getElementById("first-tag").focus();
    }
  };

  const handleotp = async () => {
    // setTimer(30)
    setShow(false);
    const resendotp = {
      mobile: serviceNumber.mobile,
      login_type: "OTP",
      // source_from: 1,
      api_mode: "LIVE",
      // verified_by,
      // verification_source,
      // verifier_name,
      login_from: 2,
      // source: source,
      // device_type: +(device_id),
      // IP: ip,
      // lead_id: 0,
      // customer_id: 0,
      // login_from: 1
    }; 

    const APIDATA = await SendOTP(resendotp,token);
    console.log(APIDATA);
    dispatch(
          updateserviceshow({
            ...setService,
            serviceLogin: false,
            serviceOtp: true,
            count:APIDATA.data.count 
          })
        );
        dispatch(
          updateservicecredential({
            ...serviceCred,
            Otp_id: APIDATA.data.otp_id,
          })
        );
    setValue("otp1", "");
    setValue("otp2", "");
    setValue("otp3", "");
    setValue("otp4", "");
    setFirstSubmit(false);
    document.getElementById("first-tag").focus();
  };

  // if (res.data.data.otp_id) {
  //   dispatch(
  //     updateserviceshow({
  //       ...setService,
  //       serviceLogin: false,
  //       serviceOtp: true,
  //       count: res.data.data.count 
  //     })
  //   );
  //   dispatch(
  //     updateservicecredential({
  //       ...serviceCred,
  //       Otp_id: res.data.data.otp_id,
  //     })
  //   );
  // }

  // const handleKeyPress = (event) => {
  //   if (!/[0-9]/.test(event.key)) {
  //     event.preventDefault();
  //   }
  // };

  const handleNavigation = (e) => { 
    dispatch(updateserviceshow({ ...setService,serviceOtp: false, serviceLogin: true}));
    dispatch(
      change_personal_loan_page({ ...personal_loan, otp: false,servicemobile:false})
    )
    e.stopPropagation();
  };

  useEffect(() => {
    timer > 0 &&
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
  }, [timer]);
  return (
    <>
      <div style={{ padding: "30px 0" }}>
        {/* write code here */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete={"off"}
          autoCorrect="false"
        >
          <div className="otp-form-body">
            <div className="otp-form-top">
              <p className="otp-head">Enter OTP</p>
              <p className="otp-head-p">
                Please type the verification code sent on <br />
                +91-{serviceNumber.mobile} 
                 {personal_loan.servicemobile && <span className="sp-otp-content" onClick={(e) => handleNavigation(e)} >(change)</span>}
              </p>
            </div>
            <div className="enter-otp ">
              <div
                className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
              >
                <input maxLength={1}
                  id="first-tag"
                  type="tel"
                  {...register("otp1", { required: true })}
                  onKeyPress={(event) => handleKeyPress(event)}
                  onChange={(event) =>
                    handleChange("second-tag", "first-tag", event, "otp1")
                  }
                  onKeyDown={(event) => handleKeyDown(event, "first-tag")}
                  onClick={(e) => handleClick(e)}
                  autoFocus
                />
              </div>
              <div
                className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
              >
                <input maxLength={1}
                  id="second-tag"
                  type="tel"
                  {...register("otp2", { required: true })}
                  onKeyPress={(event) => handleKeyPress(event)}
                  onChange={(event) =>
                    handleChange("third-tag", "first-tag", event, "otp2")
                  }
                  onKeyDown={(event) => handleKeyDown(event, "first-tag")}
                  onClick={(e) => handleClick(e)}
                />
              </div>
              <div
                className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
              >
                <input maxLength={1}
                  id="third-tag"
                  type="tel"
                  {...register("otp3", { required: true })}
                  onKeyPress={(event) => handleKeyPress(event)}
                  onChange={(event) =>
                    handleChange("forth-tag", "second-tag", event, "otp3")
                  }
                  onKeyDown={(event) => handleKeyDown(event, "second-tag")}
                  onClick={(e) => handleClick(e)}
                />
              </div>
              <div
                className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
              >
                <input maxLength={1}
                  id="forth-tag"
                  type="tel"
                  {...register("otp4", { required: true })}
                  onKeyPress={(event) => handleKeyPress(event)}
                  onChange={(event) =>
                    handleChange("forth-tag", "third-tag", event, "otp4")
                  }
                  onKeyDown={(event) => handleKeyDown(event, "third-tag")}
                  onClick={(e) => handleClick(e)}
                />
              </div>
            </div>
            {serviceCred.Otp_id ? <>{firstSubmit ? <div className="otp-timer-slot err">
            <span >Invalid OTP </span>
          </div> : show && <div className="otp-timer-slot">
            {timer !== 0 && <span>RESEND OTP : </span>}
            {timer !== 0 ? <span className="resend-option" >{timer} Seconds</span>
              : <span className="resend-option" id="pointer-cur" onClick={handleotp}>RESEND OTP</span>}
          </div>}</>
            : <div className="limit-over err-text">You have exhausted daily OTP limit. <br />
              Please try after 24 hours.</div>}
            <div>
              {" "}
              <button
                id="last"
                className={
                  "otp-btn " +
                  (data.otp1 &&
                    data.otp2 &&
                    data.otp3 &&
                    data.otp4 &&
                    !firstSubmit
                    ? "activate-btn"
                    : "")
                }
                onClick={() => setFirstSubmit(true)}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default OTP;
