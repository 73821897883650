import React from 'react';
 
function StepsliderContentLeft (props) {
    return (
        <div onClick={props.onClick} className={props.className} id={props.id}>
            <div className="stepcheck"></div>
            <div className="stepcontent">
                <span>{props.stepnumber}</span>
                <p>{props.steppera} {props.steplink && <a target="_blank" href='https://play.google.com/store/apps/details?id=com.app.mlcfinotech'>Download Moneywide App</a>}</p>
            </div>
        </div>
    );
}
export default StepsliderContentLeft;