import React, { useEffect, useState } from 'react'
import './EmbeddedSlider.css'
import offerhand from '../../Icons/offer-hand.svg'
import integration from '../../Icons/intergration.svg'
import disburse from '../../Icons/disbursment.svg'
import repayment from '../../Icons/repayment.svg'
import handdisable from '../../Icons/hand-money-disable.svg'
import repaydisable from '../../Icons/repayment-disable.svg'
import disbursedisable from '../../Icons/disbursment-disable.svg'
import integratedisable from '../../Icons/intergration-disable.svg'

function EmbeddedSlider() {

    const [activeStep, setActiveStep] = useState(1);

    useEffect(() => {
        // Function to handle scroll event with requestAnimationFrame
        const handleScroll = () => {
            requestAnimationFrame(() => {
                // Get the Y position of the scroll
                const scrollY = window.scrollY || window.pageYOffset;

                // Calculate the height of the viewport
                const viewportHeight = window.innerHeight;

                // Calculate the position of each step
                const step1 = document.querySelector('.simple-steps:nth-child(1)').offsetTop;
                const step2 = document.querySelector('.simple-steps:nth-child(2)').offsetTop;
                const step3 = document.querySelector('.simple-steps:nth-child(3)').offsetTop;
                const step4 = document.querySelector('.simple-steps:nth-child(4)').offsetTop;

                // Determine the active step based on scroll position
                if (scrollY < step2 - viewportHeight / 2) {
                    setActiveStep(1);
                } else if (scrollY < step3 - viewportHeight / 2) {
                    setActiveStep(2);
                } else if (scrollY < step4 - viewportHeight / 2) {
                    setActiveStep(3);
                } else {
                    setActiveStep(4);
                }
            });
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getStepImage = (step) => {
        switch (step) {
            case 1:
                return activeStep === 1 ? offerhand : handdisable;
            case 2:
                return activeStep === 2 ? integration : integratedisable;
            case 3:
                return activeStep === 3 ? disburse : disbursedisable;
            case 4:
                return activeStep === 4 ? repayment : repaydisable;
            default:
                return activeStep === 1 ? offerhand : handdisable; // Default image (change as needed)
        }
    };

    return (
        <>
            <div className="simple-step-main">
                <div className="simple-steps-heading">
                    <h2>Get Started in 4 Simple Steps</h2>
                </div>
                <div className="simple-step-slider">
                    <div className={`simple-steps ${activeStep === 1 ? 'active' : ''}`}>
                        <div className="simple-step-left">
                            <div className="simple-cont-side">
                                <div className="top-cont-simple">
                                    <span className='simple-count'>1</span>
                                    <span>Offer Eligibility Check</span>
                                </div>
                                <div className="botm-cont-simpl">
                                    <p>Checking the eligibility of users based on data shared by a partner.</p>
                                </div>
                            </div>
                        </div>
                        <div className="simple-step-right">
                            <div className="simple-image-show">
                            <img src={getStepImage(1)} alt="Step 1" />
                            </div>
                        </div>
                    </div>
                    <div className={`simple-steps ${activeStep === 2 ? 'active' : ''}`}>
                        <div className="simple-step-left">
                            <div className="simple-image-show-left">
                            <img src={getStepImage(2)} alt="Step 2" />
                            </div>
                        </div>
                        <div className="simple-step-right">
                            <div className="simple-cont-side">
                                <div className="top-cont-simple">
                                    <span className='simple-count'>2</span>
                                    <span>Onboarding & Integration</span>
                                </div>
                                <div className="botm-cont-simpl">
                                    <p>If eligible, complete the KYC verification and address verification.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`simple-steps ${activeStep === 3 ? 'active' : ''}`}>
                        <div className="simple-step-left">
                            <div className="simple-cont-side">
                                <div className="top-cont-simple">
                                    <span className='simple-count'>3</span>
                                    <span>Disbursement</span>
                                </div>
                                <div className="botm-cont-simpl">
                                    <p>Credit is finally disbursed to the bank account without any hassle.</p>
                                </div>
                            </div>
                        </div>
                        <div className="simple-step-right">
                            <div className="simple-image-show">
                            <img src={getStepImage(3)} alt="Step 3" />
                            </div>
                        </div>
                    </div>
                    <div className={`simple-steps ${activeStep === 4 ? 'active' : ''}`}>
                        <div className="simple-step-left">
                            <div className="simple-image-show-left">
                            <img src={getStepImage(4)} alt="Step 4" />
                            </div>
                        </div>
                        <div className="simple-step-right">
                            <div className="simple-cont-side">
                                <div className="top-cont-simple">
                                    <span className='simple-count'>4</span>
                                    <span>Flexible Repayment</span>
                                </div>
                                <div className="botm-cont-simpl">
                                    <p>The amount is paid off by a smart repayment set-up digitally.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmbeddedSlider