import React, { useEffect } from "react";
import {
  decryptOnlyLeadId,
  encryptLeadIdStepId,
  encryptQueryIdStepId,
  gettingerror,
} from "../EverCalledFunctions/EverCalledFunctions";
import MainLoader from "../MainLoader/MainLoader";
import queryString from "query-string";
import {
  prodbase_url,
  react_base_url,
  client_id,
  client_key,
  verification_source,
  verified_by,
  verifier_name,
  source,
} from "../Secret_Credentials/Secret_Credential";
import {
  GenerateToken,
  updateCredentialsState,
  updateFormState,
  updateMwApplicationId,
} from "../../Redux/action";
import { useDispatch, useSelector } from "react-redux";
import { getCustId, getToken } from "../EverCalledFunctions/EverCalledApis";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Base64 from "base-64";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";

function Emandate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { credentialState, formStates, mw_application_id, token } = useSelector(
    (state) => state
  );

  const decryptFid = async () => {
    try {
      const qry = queryString.parse(window.location.search);
      let decodeData = Base64.decode(qry.fid);
      decodeData = Base64.decode(decodeData);
      decodeData = decodeData.split("&");
      dispatch(updateMwApplicationId(decodeData[0]));
      dispatch(
        updateCredentialsState({ ...credentialState, cust_id: decodeData[1] })
      );
    } catch {
      window.location.href = `${react_base_url}personal-loan`;
    }
  };

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, []);

  React.useEffect(() => {
    if (token !== "") {
      const qry = queryString.parse(window.location.search);
      if (qry.fid) {
        decryptFid();
      }
      else if (qry.status) {
        if (qry.status === "success") {
          if (
            window.sessionStorage.getItem("mw_app_id") &&
            window.sessionStorage.getItem("mw_app_id") !== undefined &&
            window.sessionStorage.getItem("mw_app_id") !== null
          ) {
            dispatch(
              updateMwApplicationId(window.sessionStorage.getItem("mw_app_id"))
            );
          } else {
            window.location.href = `${react_base_url}personal-loan`;
          }
        } else {
          if (
            window.sessionStorage.getItem("mw_app_id") &&
            window.sessionStorage.getItem("mw_app_id") !== undefined &&
            window.sessionStorage.getItem("mw_app_id") !== null
          ) {
            let mw_app_id = window.sessionStorage.getItem("mw_app_id");
            let checkCondition = window.sessionStorage.getItem('utm')
            mw_app_id = Base64.encode(mw_app_id);
            mw_app_id = Base64.encode(mw_app_id);
            if(!checkCondition){
              window.location.href = `${react_base_url}emandate-setup/?fid=${mw_app_id}`;
            }else if(checkCondition != null && checkCondition != undefined && checkCondition != ""){
              window.location.href = `${react_base_url}emandate-setup/?fid=${mw_app_id}${checkCondition}`;
            }else{
              window.location.href = `${react_base_url}emandate-setup/?fid=${mw_app_id}`;              
            }
          } else {
            window.location.href = `${react_base_url}personal-loan`;
          }
        }
      } else {
        window.location.href = `${react_base_url}personal-loan`;
      }
    }
  }, [token]);

  React.useEffect(() => {
    if (mw_application_id !== "") {
      gettingerror('enach success web',mw_application_id)
      let qry = Base64.encode(mw_application_id);
      qry = Base64.encode(qry);
      let checkCondition = window.sessionStorage.getItem('utm')
      if(!checkCondition){
        window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=12`;
      }else if(checkCondition != null && checkCondition != undefined && checkCondition != ""){
        window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=12${checkCondition}`;
      }else{
        window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=12`;
      }
    }
  }, [mw_application_id]);

  return (
    <div>
      <CKYCWaiting heading={"We are redirecting you to the next page."} />
    </div>
  );
}

export default Emandate;
