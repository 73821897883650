import React from "react";
import queryString from "query-string";
import Base64 from "base-64";
import { useDispatch, useSelector } from "react-redux";
import { getCustId, getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken, updateCredentialsState, updateFormState, updateMwApplicationId, SetTempId, setSorryPopup } from "../../Redux/action";
import { prodbase_url, react_base_url } from "../Secret_Credentials/Secret_Credential";
import axios from "axios";
import NewLeftPart from "../Journey Form/FormLeftPart/NewLeftPart";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import { FetchCustomerPayload } from "../EverCalledFunctions/EverCalledPayload";
import { apiInstance } from "../Config/APIConfig";

function FinalThankyou() {
    const { token, credentialState, mw_application_id, formStates, templet_id } = useSelector(state => state);
    const dispatch = useDispatch();
    const [state, setState] = React.useState(true);
    const [lend, setLend] = React.useState('');
    const [query, setQuery] = React.useState('');

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }

    React.useEffect(() => {
        if (token == "") {
            myToken();
        }

        const qry = queryString.parse(window.location.search);
        if (qry.__temp_id) {
            dispatch(SetTempId(qry.__temp_id.toString()));
        }
    }, [])

    React.useEffect(() => {
        if (token !== "") {
            const qry = queryString.parse(window.location.search);
            if (qry.fid) {
                try {
                    let fid = Base64.decode(qry.fid);
                    fid = Base64.decode(fid);
                    dispatch(updateMwApplicationId(fid));

                } catch (error) {
                    window.location.href = `${react_base_url}`;
                }
            }
            else {
                window.location.href = `${react_base_url}`;
            }

        }
    }, [token])

    const pgLeadStatus = async () => {
        const qry = queryString.parse(window.location.search);
            if (qry.fid) {
                let temp = qry.__temp_id;
                if (temp == 12) {
                    let pgDec = await apiInstance
                        .post(
                            `pg-lead-status-fetch`,
                            {
                                lead_id: mw_application_id,
                                lenderId: lend,
                            },
                            {
                                token,
                            }
                        )
                        // .then((res) => { });
                }
            }
    }

    React.useEffect(() => {
        if ((token !== "") && (mw_application_id !== "") && (lend !== "")) {
            pgLeadStatus();
        }
    }, [token, mw_application_id, lend])

    const getCustomerId = async () => {
        let decFetch = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 16), {
            token
        })
        // .then((res) => {
            if (decFetch?.data?.lead_source == 434) {
                if (decFetch?.data?.redirection_url != "") {
                    window.location.href = decFetch?.data?.redirection_url;
                    return;
                }
            }
            if (decFetch?.data?.query_status) {
                setQuery(decFetch?.data?.query_status);
            }
            if (decFetch?.data.customer_id) {
                dispatch(updateCredentialsState({ ...credentialState, cust_id: decFetch.data.customer_id }));
            }
            if ((decFetch?.data?.lenderId.toString())) {
                setLend(decFetch.data.lenderId);
            }
            if (decFetch?.data?.lead_source == 105) {
                // let qry = Base64.encode(mw_application_id);
                // qry = Base64.encode(qry);
                if (typeof window.Android !== "undefined") {
                    // window.location.href = `https://uat-react.moneywide.in/thank-you/?fid=${qry}&__temp_id=12`;
                    return window.Android.closeMwWebView(
                        JSON.stringify({
                            mwApplicationId: decFetch.data.mw_application_id,
                            leadStatus: "",
                        })
                    );
                } else if (typeof window.ReactNativeWebView) {
                    // window.location.href = `https://uat-react.moneywide.in/thank-you/?fid=${qry}&__temp_id=12`;
                    return window.ReactNativeWebView.postMessage(
                        JSON.stringify({
                            method: "closeMwWebView",
                            data: {
                                mwApplicationId: decFetch.data.mw_application_id,
                                leadStatus: "",
                            },
                        })
                    );
                }
            }

        // })
    }

    React.useEffect(() => {
        if (mw_application_id !== "") {
            // if ((window.sessionStorage.getItem("cust_id")) && (window.sessionStorage.getItem("cust_id") !== undefined) && (window.sessionStorage.getItem("cust_id") !== null)) {
            //     dispatch(updateCredentialsState({ ...credentialState, cust_id: window.sessionStorage.getItem("cust_id") }));
            // }
            // else {
            //     getCustomerId();
            // }
            getCustomerId();
        }
    }, [mw_application_id])

    // React.useEffect(() => {
    //     if ((mw_application_id !== "") && (lend !== "") && (query !== "")) {
    //         const qry = queryString.parse(window.location.search);
    //         if (((qry.__temp_id == 10) || (qry.__temp_id == 11) || (qry.__temp_id == 12)) && (query !== 19)) {
    //             axios.post("https://api.moneywide.com/v2/e-sign-documentstatus", {
    //                 "lead_id": mw_application_id,
    //                 "lenderId": lend
    //             }, {
    //                 headers: {
    //                     "token": "dvcdcvudv"
    //                 }
    //             }).then((res) => {
    //                 console.log("success");
    //             })
    //         }
    //     }
    // }, [mw_application_id, lend,query])

    React.useEffect(() => {
        if ((credentialState.cust_id !== "") && (credentialState.cust_id !== undefined) && (credentialState.cust_id !== null)) {
            const qry = queryString.parse(window.location.search);
            if (qry.__temp_id) {
                dispatch(SetTempId(qry.__temp_id));
                if (qry.__temp_id == 16) {
                    dispatch(setSorryPopup(true));
                    setState(false)
                }
                else if (qry.__temp_id == 12) {
                    dispatch(updateFormState({ ...formStates, congo: true }));
                    setState(false)
                }
                else if (qry.__temp_id == 7) {
                    dispatch(updateFormState({ ...formStates, BSASuccessful: true }));
                    setState(false)
                }
                else if (qry.__temp_id == 18) {
                    dispatch(updateFormState({ ...formStates, HunterHold: true }));
                    setState(false);
                }
                else if (qry.__temp_id == 17) {
                    dispatch(updateFormState({ ...formStates, HunterHold: true }));
                    setState(false);
                }
                else if ((qry.__temp_id == 11) || (qry.__temp_id == 5) || (qry.__temp_id == 8) || (qry.__temp_id == 9) || (qry.__temp_id == 10) || (qry.__temp_id == 17)) {
                    dispatch(updateFormState({ ...formStates, enashSorry: true }));
                    setState(false)
                }
            }
            else {
                window.location.href = `${react_base_url}`;
            }
        }
    }, [credentialState])



    return (
        <>
            {
                state ?
                    <CKYCWaiting /> :
                    <div>
                        <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                    </div>
            }
        </>
    )
}

export default FinalThankyou;