import React, { useEffect } from 'react';
import './InstantPersonalLoan.css';
import '../PersonalLoan/PersonalLoan.css';
import benefitIcno1 from '../PersonalLoan/images/benefiticon1.webp'
import benefitIcno2 from '../PersonalLoan/images/benefiticon2.webp'
import benefitIcno3 from '../PersonalLoan/images/benefiticon3.webp'
import benefitIcno4 from '../PersonalLoan/images/benefiticon4.webp'
import greenticknew from '../../Icons/greenticknew.svg';
import EligibilityCriteria from '../PersonalLoan/images/eligibility-criteria.webp'
import FAQSection from '../FAQSection/FAQSection'
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import MobileFooter from '../Footer/MobileFooter';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import FeaturesBenefits from '../PersonalLoan/FeaturesBenefits';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import StepSlider from '../HowToApplyPLSLIDER/StepSlider';
import HittingMetaTagApi from './HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';
import EligibilityCriteriaSection from '../PersonalLoan/EligibilityCriteria';


const InstantPersonalLoan = () => {
    var loan_type_id_page = 56;
    const [data, setData] = React.useState({ sub_bread: "Instant Personal Loan" });
    const dispatch = useDispatch()
    const { formStates, PLLoader, mwpages } = useSelector(state => state)
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY <= 200 || window.scrollY >= 2313) {
            sety(false);
        }
        else {
            sety(true);
        }
    });
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])
    const dataa = {
        heading: [
            {
                left: "Personal Loan",
                right: "Eligibility Criteria"
            }

        ],
        subhead: [
            {
                head: "Age",
                content: "You must be between 23-55 years of age."
            },
            {
                head: "Employer",
                content: "All salaried employees working at a private sector company or public undertaking."
            },
            {
                head: "Credit Score",
                content: "At Moneywide, you can get an instant personal loan with a minimum credit score of 675 and above."
            },
            {
                head: "Minimum Income",
                content: "The minimum income for an instant personal loan is ₹ 20,000"
            }
        ]
    }
    const datafandb= {
        givendata:[
        {
        span:"Minimum salary required",
        para:" Rs.20,000 ",
    },
    {
        span:"Quick approval",
        para:"and disbursement",
    },
    {
        span:"Repayment tenure is ",
        para:"6 to 36 months",
    },
    {
        span:"No paperwork required",
        para:"to get a loan",
    },
    {
        span:"100% digital process",
        para:"for approval",
    },
    {
        span:"No hidden",
        para:"charges on loan",
    }
    ]
    }
    return (
        <>
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi />
            <div className='journey-stop'>
                {<NewLoginForm pageType={loan_type_id_page} mapData={data} />}
                <div>
                    {y && <RightSidePopUp />}
                    <section className="IPL">
                        <div className='web-margin-top mob-margin-top what-is-extra'>
                            <div className="ploan-content1">
                                <h2 className="heing bot-6 add-btom-line cetnred" style={{ marginBottom: "18px" }}>What is Instant <b>Personal Loan?</b></h2>
                                <p className="heading-p">The term ‘instant’ in Instant Personal Loans signifies the quick approval process in availing of loans. It takes 30 seconds for in-principle approval as you apply for a loan digitally through our website or app. You can get an instant personal loan online for a loan amount of upto ₹ 3,00,000.</p>

                            </div>
                        </div>
                    </section>

                    <EligibilityCriteriaSection elgdata={dataa} />
                    <FeaturesBenefits features={datafandb}/>
                    <section className="GetInstantLoanIm">
                        <StepSlider />
                    </section>
                    {/* <section style={{ marginTop: "-20px" }}>
                        <FAQSection />
                    </section> */}
                    {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                        <FAQSectionChk data={mwpages.faqdata} />
                    </section> : ""}
                </div>
            </div>
            <div className='journey-start' style={{ display: "none" }}>
                {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                {/* <FormLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." /> */}
                <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                {(!formStates.IsIframe) && <MobileFooter />}
            </div>
        </>
    )
}

export default InstantPersonalLoan;