import React, { useState, useEffect } from 'react'
import deleteForm from '../../Icons/deleteForm.svg'
import deleteSorry from '../../Icons/deleteSorry.svg'
import AccountDeleted from '../../Icons/AccountDeleted.svg'
import { ReasonDeleteAPI, commonPayload, confirmDeleteAPI, getLoginData } from './MyAccountAPIs'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CKYCWaiting from '../LoginPopUp/CKYCWaiting'
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase'
import { GenerateToken } from '../../Redux/action'
import { getToken } from '../EverCalledFunctions/EverCalledApis'
import { react_base_url } from '../Secret_Credentials/Secret_Credential'


const DataDeleteForm = () => {
    const [pageNo, setPageNo] = useState(1)
    const [value, setValue] = useState("")
    const [handlers, setHandlers] = useState({
        loader: true,
        err: false
    })
    const [userData, setUserData] = useState({
        cust_id: "",
        name: "",
        userNo: ""
    })
    const navigate = useNavigate()
    const { token } = useSelector(state => state)

    const dispatch = useDispatch()

    const myToken = async () => {
        const T = await getToken();
        dispatch(GenerateToken(T))
    }

    useEffect(() => {
        if (token === "") {
            myToken()
        }
    }, [])

    useEffect(() => {
        let payLoad = getLoginData()

        if (payLoad && payLoad?.userNo) {
            const existed = sessionStorage.getItem("existed")
            if (existed != 1) {
                window.location.href = `${react_base_url}myaccount/profile`
            }
            setUserData({
                ...userData,
                userName: payLoad?.userName,
                userNo: payLoad?.userNo,
                cust_id: payLoad?.cust_id
            })
            setHandlers({
                loader: false,
                err: false
            })
        } else {
            navigate('/login')
            setHandlers({
                loader: false,
                err: false
            })
        }
    }, [])

    return (
        <>

            <div className='myAccountHeading'>Data Delete Form</div>
            {handlers.loader && <CKYCWaiting />}
            {handlers.err && <NewErrorCase handleClick={() => window.location.reload()} />}
            {pageNo == 1 ? <DeleteForm setPageNo={setPageNo} value={value} setValue={setValue} userData={userData} myToken={myToken} setHandlers={setHandlers} />
                : (pageNo == 2 ? <AskAgain setPageNo={setPageNo} userData={userData} setHandlers={setHandlers} /> :
                    (pageNo == 3 ? <Success userData={userData} /> : <SorryCase />))}

        </>
    )
}

export default DataDeleteForm;

const SorryCase = () => {
    return <div className='setting-back-bg data-deletion delete-opt' style={{ paddingBottom: "80px" }}>
        <img className='wid-180' src={deleteSorry} alt="sorry" />
        <div className='delete-box'>
            <span>Sorry!</span>
            <p>Your loan is already booked with us and as per RBI guidelines we need to store your data for 8 years.</p>
        </div>
    </div>
}

const AskAgain = ({ setPageNo, userData, setHandlers }) => {

    const { token } = useSelector(state => state)

    const handleClick = async () => {
        setHandlers({
            loader: true,
            err: false
        })
        const payLoad = {
            ...commonPayload,
            "customer_id": userData?.cust_id,
            "is_delete": 1
        }
        const response = await confirmDeleteAPI(payLoad, token)
        if (response.status_code == "200" && response.status == "1") {
            setHandlers({
                loader: false,
                err: false
            })
            setPageNo(3)
            sessionStorage.clear()
        } else {
            setHandlers({
                loader: false,
                err: true
            })
        }
    }

    return <div className='setting-back-bg data-deletion delete-opt'>
        <img className="wid-180" src={deleteForm} alt="delete" />
        <div className='delete-box'>
            <span>Are you sure you want to delete your account?</span>
            <p>Once you confirm account deletion, your MoneyWide account data will be deleted permanently.</p>
            <div className="delete-btns">
                <button className='delete' onClick={() => handleClick()}>Delete</button>
                <button className='cancel' onClick={() => setPageNo(1)}>Cancel</button>
            </div>
        </div>
    </div>
}

const DeleteForm = ({ setPageNo, value, setValue, userData, myToken, setHandlers }) => {
    const { token } = useSelector(state => state)


    const [err, setErr] = useState(false)


    const formSubmited = async (e) => {
        e.preventDefault()
        if (token == "") {
            myToken()
            setValue("")
        } else {
            if (value.length < 3) {
                setErr(true)
            } else {
                setErr(false)
                const payLoad = {
                    ...commonPayload,
                    "customer_id": userData?.cust_id,
                    "reason": value
                }
                setHandlers({
                    loader: true,
                    err: false
                })
                const response = await ReasonDeleteAPI(payLoad, token);
                if (response.status_code == "200" && response.status == "1") {
                    if (response.data.disbursed_flag == 0) {
                        setPageNo(2)
                    } else {
                        setPageNo(4)
                    }
                    setHandlers({
                        loader: false,
                        err: false
                    })
                } if (response.status_code == "429" && response.status == "0") {
                    setPageNo(4)
                    setHandlers({
                        loader: false,
                        err: false
                    })
                }
                else {
                    setErr(true)
                }
            }
        }
    }
    return <>
        <div className="setting-back-bg data-deletion">
            <p>As per the new data deletion policy of RBI, the apps that enable account creation need to provide an option to initiate account and data deletion from within the app and online.</p>
            <p>If you wish to proceed with the data deletion, please submit the below information.</p>
            <div className="data-deletionform">
                <label htmlFor="reason">Reason for deletion</label>
                <input type="text" value={value} onChange={(e) => setValue(e.target.value)} autoComplete='off' name="reason" placeholder='Please specify the reason for deletion of account...' />
                {err && <span className="err-text" style={{ marginTop: "0px" }}>Minimum 3 characters required</span>}
                <div className="account-state-button od-button preferences">
                    <button type="submit" className="footer-btn od-tn-footer activate" onClick={formSubmited}>Submit Request</button>
                </div>
            </div>
        </div>
    </>
}

const Success = ({ userData }) => {
    const [timer, setTimer] = useState(15)

    useEffect(() => {
        if (timer > 0) {
            setTimeout(() => {
                setTimer(timer - 1)
            }, 1000)
        } else {
            window.location.href = react_base_url
        }
    }, [timer])
    return <div className='blur-background '>
        <div className="pop-content">
            <div className='setting-back-bg data-deletion delete-opt' style={{ paddingBottom: "25px" }}>
                <img className='wid-180' src={AccountDeleted} alt="sorry" />
                <div className='delete-box'>
                    <span>Account Data Deleted</span>
                    <p className='center'>Dear <b>{userData?.userName ? userData.userName : "Customer"}</b>, your data has been successfully deleted.</p>
                    <p className='center' style={{ marginTop: "20px",color:"#8A91A1" }}>You will be redirected in {timer} seconds</p>
                </div>
            </div>
        </div>
    </div>
}