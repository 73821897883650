import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick';
import "./TextSlider.css"

function TextSlider() {
const sliderRef = useRef(null);

    var sliderblog = {
        infinite: true,
        autoplay: true,
        cssEase: "linear",
        speed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 0,
        swipe: false,
        easing:'linear',
        variableWidth:true,
        pauseOnHover: true,
        pauseOnFocus: true,
        responsive: [
            {
                breakpoint: 750,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    dots: false,
                    autoplaySpeed: 0,
                    speed: 5000,
                }
            },
        ],
    };

    return (
        <>  
        <div className="text-slide-bg">
            <div className="text-slider">
                <Slider ref={sliderRef} {...sliderblog}>
                    <div className="text-slides">
                        <span>• </span>
                        <span>100% paperless approval and disbursement process.</span>
                    </div>
                    <div className="text-slides">
                        <span>• </span>
                        <span>Minimum KYC & income documents required for a loan.</span>
                    </div>
                    <div className="text-slides">
                        <span>• </span>
                        <span>Instant money transfer in your account the same day.</span>
                    </div>
                    <div className="text-slides">
                        <span>• </span>
                        <span>Flexibility to partpay or foreclose loan after 1st EMI.</span>
                    </div>
                </Slider>
            </div>
        </div>
        </>
    )
}

export default TextSlider