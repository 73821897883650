import axios from 'axios';
import React from 'react';
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { GenerateToken } from '../../../../Redux/action';
import lottieJson from "../../../assests/lootie-files/hold.json";
import { getToken } from '../../../EverCalledFunctions/EverCalledApis';
import { prodbase_url, source, client_id, client_key, verification_source, verified_by, verifier_name } from '../../../Secret_Credentials/Secret_Credential';
import { apiInstance } from '../../../Config/APIConfig';

const HoldCase = () => {
  const { credentialState, token } = useSelector(state => state)
  const dispatch = useDispatch();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, [])

  const thankyouScreen = async () => {
    let decThank = await apiInstance.post(`thankyou-screen-message`, {
      source,
      "template_id": "17",
      "customer_id": credentialState.cust_id,
      verifier_name,
      verification_source,
      verified_by
    }, {
      token
    })
    // .then((res) => {
    // })
  }

  React.useEffect(() => {
    //  axios.post(`${prodbase_url}token`,{
    //   client_id,
    //   client_key,
    //   source
    //  }).then((res)=>{
    //   let token = res.data.token;
    if (token !== "") {
      thankyouScreen();
    }
    //  })
  }, [token])
  return (
    <div>
      <div className='manage-sry-screen'>
        <div className="lotie-div">
          <Lottie loop animationData={lottieJson} play className="sry-popup-lottie" />
        </div>
        <div className="sry-heading">Please Wait !</div>
        <div className="sry-text" style={{ paddingBottom: "30px" }}>Your Application is currently under review</div>
      </div>
    </div>
  )
}

export default HoldCase;
