import React, { useEffect } from 'react'
// import FAQSection from '../FAQSection/FAQSection';
// import "./TravelLoan.css";
// import EligibilityCriteria from '../PersonalLoan/images/eligibility-criteria.webp';
// import greenticknew from '../../Icons/greenticknew.svg';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import MobileFooter from '../Footer/MobileFooter';
import FeaturesBenefits from '../PersonalLoan/FeaturesBenefits';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import StepSlider from '../HowToApplyPLSLIDER/StepSlider';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';
import EligibilityCriteriaSection from '../PersonalLoan/EligibilityCriteria';
const NewCreditPL = () => {
    var loan_type_id_page = 56;
    const [data, setData] = React.useState({ sub_bread: "Travel Loan" })
    const dispatch = useDispatch()
    const { PLLoader, formStates, mwpages } = useSelector(state => state)
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY <= 200 || window.scrollY >= 3400) {
            sety(false);
        }
        else {
            sety(true);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])

    const dataa = {
        heading: [
            {
                left: "Eligibility Criteria for",
                right: "Travel Loan"
            }
        ],
        subhead: [
            {
                content: "You must be between 21 to 45 years."
            },
            {
                content: "All salaried employees working at a private sector company or public undertaking."
            },
            {
                content: "At Moneywide, you can get an instant personal loan for travel with a minimum credit score of 600."
            }
        ]
    }
    const datafandb= {
        givendata:[
        {
        span:"Convenient online",
        para:"application for your travel",
    },
    {
        span:"Flexible repayment",
        para:" terms on your loan",
    },
    {
        span:"Quick Approval",
        para:"and Disbursement",
    },
    {
        span:"No Collateral Required",
        para:"for your dream vacation",
    },
    {
        span:"Flexible repayment tenure",
        para:" upto 36 months",
    },
    {
        span:"No hidden charges ",
        para:"on Personal Loan for travel.",
    }
    ]
    }

    return (
        <>
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi />
            <div className='journey-stop'>
                <NewLoginForm pageType={loan_type_id_page} mapData={data} />
                {y && <RightSidePopUp />}
                <div className='ploan-content1 mob-margin-top what-is web-margin-top'>
                    <h2 className="heading-h2" style={{ marginBottom: "18px" }}><b>Travel</b> Loan</h2>
                    <p className="heading-p">A travel loan is a type of personal loan taken specifically for the purpose of traveling. The loan can be availed for domestic as well as foreign travel. At MoneyWide, you can avail a hassle-free personal loan for travel digitally with the flexibility to part pay or foreclose the loan. MoneyWide personal loans can be availed instantly once you pass the loan approval process. The loan can be availed with an amount ranging between ₹ 1,00,000 to ₹ 3,00,000 at affordable interest rates with a flexible repayment tenure of 6 to 36 months.</p>
                </div>

                 <EligibilityCriteriaSection elgdata={dataa} />

                <FeaturesBenefits features={datafandb}/>

                <section className="pl-charges newcredtopmargin">
                    <div className="ploan-contanier ourproducts-main">
                        <div className="box-sh">
                            <div className="eligibility-criteria-box new-cred-main">
                                <div className="product-heading nwprod-heading">
                                    <p></p>
                                    <span>Travel Loan</span>
                                    <p>Interest Rates and Other Charges</p>
                                </div>
                                <div className="instant-box nw-cred-box">
                                    <div className="instant-head-cont">
                                        <div className="instant-head">
                                            <span>Personal Loan for Travel </span>
                                        </div>
                                        <div className="instant-contnt">
                                            <div className="instant-inner nwinstant">
                                                <span>Interest Rates :</span>
                                                <p>16.5% to 32% P.A</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Processing Fees:</span>
                                                <p>3% to 3.5% of loan amount</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Partpayment Charges:</span>
                                                <p>3% of the prepaid amount after 1st EMI</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Foreclosure Charges:</span>
                                                <p>4% of the outstanding amount after 1st EMI</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="instant-bottom">
                                        <a href='#'>Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <StepSlider />
                {/* <section className='FAQs'>
                    <FAQSection />
                </section> */}
                {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                    <FAQSectionChk data={mwpages.faqdata} />
                </section> : ""}
            </div>
            <div className='journey-start' style={{ display: "none" }}>
                {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                {(!formStates.IsIframe) && <MobileFooter />}
            </div>
        </>
    )
}

export default NewCreditPL;