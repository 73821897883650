import React, { useEffect } from 'react';
import "../footermorelinkspages.css";
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import MobileFooter from '../Footer/MobileFooter';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import FeaturesBenefits from '../PersonalLoan/FeaturesBenefits';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import StepSlider from '../HowToApplyPLSLIDER/StepSlider';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';
import EligibilityCriteriaSection from '../PersonalLoan/EligibilityCriteria';

const PersonalLoanforMarriage = () => {
    var loan_type_id_page = 56;
    const [data, setData] = React.useState({ sub_bread: "Personal Loan For Marriage" });
    const dispatch = useDispatch()
    const { PLLoader, formStates, mwpages } = useSelector(state => state)
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY <= 200 || window.scrollY >= 2638) {
            sety(false);
        }
        else {
            sety(true);
        }
    });
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])

    const dataa = {
        heading: [
            {
                left: "Eligibility Criteria for",
                right: "Personal Loan for Marriage"
            }
        ],
        subhead: [
            {
                head: "Age",
                content: "An eligible candidate must be between 21 years to 45 years."
            },
            {
                head: "Net Monthly Income",
                content: "INR 20,000 & above"
            },
            {
                head: "Credit Score",
                content: "At Moneywide, you can get an instant personal loan with a minimum credit score of 625."
            },
            {
                head: "Total Work Experience",
                content: "Minimum 2 months or above."
            }
        ]
    }
    const datafandb= {
        givendata:[
        {
        span:"Quick approval",
        para:"and disbursal",
    },
    {
        span:"No collateral required",
        para:" to get a marriage loan",
    },
    {
        span:"Competitive interest rates",
        para:"based on your eligibility",
    },
    {
        span:" Easy Repayment ",
        para:"Options",
    },
    {
        span:"No restrictions ",
        para:" on usages",
    },
    {
        span:"No hidden charges",
        para:"on Personal Loan",
    }
    ]
    }


    return (
        <>
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi />
            <div className='journey-stop'>
                {y && <RightSidePopUp />}
                <NewLoginForm pageType={loan_type_id_page} mapData={data} />
                <div >
                    <section className="newpl-main  manage-top-margin-main web-margin-top marr what-is-extra">
                        <div className="ploan-container">
                            <div className="re-sh-box ploan-content1">
                                <h4 className="heading-h2" style={{ marginBottom: "18px" }}><b>Marriage</b> Loan</h4>
                                <p className="heading-p">A <a className='marriage-loan-a' href='/personal-loan'>personal loan</a> for marriage is an unsecured financial tool that will help you by providing funds to fulfill your dream wedding wishlist. Expenses including decoration, ornaments, ceremonies, clothing, etc are covered under such loans.</p>
                                <p className="heading-p">Also, benefits like quick approval, affordable interest rate, flexible repayment tenure, and same-day disbursal on loans are among the top reasons why MoneyWide personal loan for marriage is the best option for you to fund wedding-related expenses and make your marriage even more memorable.</p>
                            </div>
                        </div>
                    </section>
                     <EligibilityCriteriaSection elgdata={dataa} />
                    <FeaturesBenefits features={datafandb}/>
                    <StepSlider />
                    {/* <section className="FAQs">
                        <FAQSection />
                    </section> */}
                    {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                        <FAQSectionChk data={mwpages.faqdata} />
                    </section> : ""}
                </div>
            </div>
            <div className='journey-start' style={{ display: "none" }}>
                {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                {(!formStates.IsIframe) && <MobileFooter />}
            </div>
        </>
    )
}

export default PersonalLoanforMarriage