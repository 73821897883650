import './NewLoginForm.css';
import React from "react";
import "./login.css";
import NumberLogIn from './NumberLogIn';
import OTP from './OTP';
import { GetProductName, getAnimationData } from '../../EverCalledFunctions/EverCalledFunctions';
import { useSelector } from 'react-redux';

const NewLoginForm = ({ pageType, mapData }) => {
  const [productName, setProductName] = React.useState(GetProductName(pageType))
  const [visible, setVisibile] = React.useState(true)
  const [data, setData] = React.useState({ heading: "", data: [] })
  const { mwpages } = useSelector(state => state);

  React.useEffect(() => {
    if (!productName) {
      setProductName(GetProductName(pageType))
    }
    let cur_url = window.location.href;
    if ((cur_url.includes("utm_medium") || (cur_url.includes("utm_source")))) {
      setVisibile(false);
    }
  }, [])

  React.useEffect(() => {
    if (mwpages.bannerdata.length != 0) {
      setData({
        ...data,
        heading: mwpages.bannerdata[0].firstfold_main,
        data: getAnimationData(mwpages.bannerdata[0],2)
      })
    }
  }, [mwpages])

  const { personal_loan } = useSelector(state => state);
  return (
    <>
      <div className="loginform">
        <div className="manage-loginform">
          <div className="left-top">
            <a href={`/${window.location.search}`}>Home</a>  &gt;{" "}
            {mapData.sub_bread && <><a href={`/personal-loan${window.location.search}`}>{productName} </a> &gt;{" "}</>}
            {<span>{mapData.sub_bread ? mapData.sub_bread : productName}</span>}
          </div>
          <div className="displaying-row">
            <div className="left-content-login-form">
              <div className="pl-heading">{data.heading ? data.heading : ""} <br />{data.sub_heading ? data.sub_heading : ""}</div>
              <div className="words">
                {data.data.length !== 0 && data.data.map((row, ind) => {
                  return <span key={ind}>{row}</span>
                })}
              </div>
              {visible ? <a target={"_blank"} href="https://play.google.com/store/apps/details?id=com.app.mlcfinotech" className="get-app">Get the App</a> : ""}
            </div>
            {productName.length !== 0 && <div className="right-content-login-form">
              {(personal_loan.profile && personal_loan.otp) ? <OTP /> : <NumberLogIn loan_type={productName} />}
            </div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default NewLoginForm;