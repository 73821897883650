import React, { useEffect } from 'react';
import fandb from '../../Icons/fandb.svg';
import "./featuresandbenefits.css";

const FeaturesBenefits = ({ features }) => {
  const [y, setY] = React.useState(false);

  function ImgLoad() {
    setTimeout(() => {
      var element = document.getElementById("fb1");
      element.style.visibility = "visible";
      element.style.animation = "zoom .6s 1 0s";
    }, 200);
    setTimeout(() => {
      var element = document.getElementById("fb2");
      element.style.visibility = "visible";
      element.style.animation = "zoom .6s 1 0s";
    }, 600);
    setTimeout(() => {
      var element = document.getElementById("fb3");
      element.style.visibility = "visible";
      element.style.animation = "zoom .6s 1 0s";
    }, 1000);
    setTimeout(() => {
      var element = document.getElementById("fb4");
      element.style.visibility = "visible";
      element.style.animation = "zoom .6s 1 0s";
    }, 1400);
    setTimeout(() => {
      var element = document.getElementById("fb5");
      element.style.visibility = "visible";
      element.style.animation = "zoom .6s 1 0s";
    }, 1800);
    setTimeout(() => {
      var element = document.getElementById("fb6");
      element.style.visibility = "visible";
      element.style.animation = "zoom .6s 1 0s";
    }, 2200);
  }

  window.addEventListener("scroll", (event) => {
    if (window.scrollY >= 700) {
      setY(true);
    }
  });

  React.useEffect(() => {
    if (y) {
      ImgLoad();
    }
  }, [y]);

  // useEffect(() => {
  //   console.log(features.givendata.length)
  // })

  return (
    <>
      <div className="featuresbenefits">
        <h2>Features and <b>Benefits</b></h2>
        <div className="featurebenefit-main">
          <img className="fbimg" src={fandb} alt="fandb" />
          <div className="all-bubble">
            <div className="left-bubbles">
              {features?.givendata?.length !== 0 && features?.givendata?.slice(0, 3).map((el, index) => {
                return (
                  <div className={`fandb-sides fb${index + 1}` + (el?.img ? "" : ` fandb-sides-new`)} id={`fb${index + 1}`}>
                    {el?.img && <img className="fb-inr-img" src={el?.img} alt='NA' />}
                    <div className="inner-fb">
                      <span>{el.span}</span>
                      <p>{el.para}</p>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="right-bubbles">
              {features?.givendata?.length !== 0 && features?.givendata?.slice(3, 6).map((el, index) => {
                return (
                  <div className={`fandb-sides fb${index + 4}` + (el?.img ? "" : ` fandb-sides-new`)} id={`fb${index + 4}`}>
                    {el?.img && <img className="fb-inr-img" src={el?.img} alt='NA' />}
                    <div className="inner-fb">
                      <span>{el.span}</span>
                      <p>{el.para}</p>
                    </div>
                  </div>
                )
              })}
            </div>

          </div>
        </div>
      </div >
    </>
  );
}

export default FeaturesBenefits;
