import React from 'react'
import "./loanslider.css"
import Slider from 'react-slick'
import ipl from '../PersonalLoan/images/ipl.svg'
import newcreditloan from '../PersonalLoan/images/newcreditloan.svg'
import plaadhar from '../PersonalLoan/images/plaadhar.svg'
import pldoc from '../PersonalLoan/images/pldoc.svg'
import plmarriage from '../PersonalLoan/images/plmarriage.svg'
import plrenovation from '../PersonalLoan/images/plrenovation.svg'
import pltravel from '../PersonalLoan/images/pltravel.svg'

const DA = [
  {
    img: ipl,
    programe_name: "Instant Personal Loan",
    desc: "An Instant Personal Loan have a quick approval process. It takes 30 seconds for in-principle approval for loans upto Rs. 3 Lakhs.",
    button: "Apply Now",
    url: "/instant-personal-loan",
    open: false

  },
  {
    img: newcreditloan,
    programe_name: "New to Credit Loan",
    desc: "A New Credit Personal Loan is an unsecured loan for borrowers with no credit history, and a credit score is 0 or -1.",
    button: "Apply Now",
    url: "/new-credit-personal-loan-mw-first",
    open: false

  },
  {
    img: pltravel,
    programe_name: "Personal Loan for travel",
    desc: "A travel loan is personal loan taken specifically for the purpose of traveling. The loan can be availed for domestic as well as foreign travel.",
    button: "Apply Now",
    url: "/travel-loan",
    open: false

  },
  {
    img: plmarriage,
    programe_name: "Personal Loan for marriage",
    desc: "A personal loan for marriage helps you to fulfill your dream wedding wishlist. Expenses, including decoration, ornaments, ceremonies, clothing, etc are covered under such loans.",
    button: "Apply Now",
    url: "/personal-loan-for-marriage",
    open: false

  },
  {
    img: plrenovation,
    programe_name: "Loan for home renovation",
    desc: "A personal loan for home renovation helps you get funds for expenses related to home repairs and renovations of an existing house.",
    button: "Apply Now",
    url: "/personal-loan-for-home-renovation",
    open: false

  },
  {
    img: pldoc,
    programe_name: "Personal Loan without documents",
    desc: "MoneyWide offers instant personal loans that involve a paperless loan approval process with electronic verification and submission of documents online without any hassle.",
    button: "Apply Now",
    url: "/instant-personal-loan-without-documents",
    open: false

  },
  {
    img: plaadhar,
    programe_name: "Personal Loan on Aadhaar",
    desc: "At MoneyWide, you can get a quick Aadhaar-based personal loan of upto INR ₹ 3,00,000 at affordable rates with multiple facilities that facilitate instant access to funds in times of need.",
    button: "Apply Now",
    url: "/personal-loan-on-aadhar-card",
    open: false

  }
]

function LoansSlider() {
  const [data, setData] = React.useState(DA)

  var sliderblog = {
    infinite: true,
    autoplay: true,
    cssEase: "ease-in-out",
    speed: 1000,
    // centerMode:true,
    // CenterPadding:'100px',
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          speed: 500,
          infinite: true
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          infinite: true
        },
      },
    ],
  };



  const ReadMoreFunction = (data) => {
    if (data.open) {
      return data.desc
    } else {
      return data.desc.slice(0, 110) + "...";
    }
  }

  const OpenClick = (index) => {
    let newval = [...data]
    newval[index].open = !data[index].open
    setData(newval)
  }


  return (
    <>
      <div className="loanslider-bg">
        <div className="ls-head add-btom-line">
          <h2>
            Choose the Right <b>Personal</b> Loan for You!
          </h2>
        </div>
        <div className="blog-card loan-card">
          <Slider {...sliderblog}>
            {data.map((el, index) => (
              <div key={index} className="nesting">
                <div className="loancard">
                  <img src={el.img} alt="Image Not Found" />
                  <p className="loanp">{el.programe_name}</p>
                  <p className="loanp2" >{el.desc ? (el.desc.length > 110 ? <>{ReadMoreFunction(el)} <span onClick={() => OpenClick(index)} style={{ color: "#011149", cursor: "pointer", fontFamily: "poppinsmedium" }}> {el.open ? "Read Less" : "Read More"}</span> </> : el.desc) : ""}</p>
                  <button><a className='lb-apply-btn' href={`${el.url}${window.location.search}`} target="_self">{el.button}</a></button>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  )
}

export default LoansSlider