import React, { useState } from 'react'
import './MyAccount.css';
import Profile from '../../Icons/Profile.jpg';
import { useEffect } from 'react';
import { base_url, client_id, client_key, source } from '../Secret_Credentials/Secret_Credential'
import axios from 'axios';
import { ProfileAPI, SaveLoginData, getLoginData } from './MyAccountAPIs';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../EverCalledFunctions/EverCalledApis';
import { GenerateToken } from '../../Redux/action';
import { useNavigate } from 'react-router-dom';
import { gettingerror } from '../EverCalledFunctions/EverCalledFunctions';
const MyProfile = ({ setUserName }) => {
    const [openSection, setOpenSection] = useState([true, false, false, false])
    const [data, setData] = useState({})
    const [loader, setLoader] = useState(true)
    const [userNo, setUserNo] = useState("")
    const { token } = useSelector(state => state)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const OpenNewSection = (ind) => {
        var newSection = [false, false, false, false]
        newSection[ind] = !openSection[ind];
        setOpenSection(newSection)
    }

    const getAPIData = async (num) => {
        const APIResponse = await ProfileAPI(num, token);
        if (APIResponse.status_code === "200" && APIResponse.status === "1") {
            gettingerror('hit profile deatils web',num)
            setData(APIResponse.data)
            setLoader(false)
            if(APIResponse.data.personal_details && APIResponse.data.personal_details.customer_name){
                let preData = getLoginData("NO");
                preData = {...preData,userName:APIResponse.data.personal_details.customer_name}
                SaveLoginData(preData)
                setUserName(APIResponse.data.personal_details.customer_name)
            }
        } else if (APIResponse.status_code === "429" && APIResponse.status === "0") {
            var newData = {
                personal_details: {},
                employment_details: {},
                office_address: {},
                permanent_address: {},
                current_address: {}
            }
            setData(newData)
            setLoader(false)
            let preData = getLoginData("NO");
            preData = {...preData,userName:""}
            SaveLoginData(preData)
            setUserName("")
        }else{            
            navigate('/login')
        }
    }

    useEffect(() => {
        if (token !== "") {
            let preData = getLoginData()
            if (!preData && !preData.userNo) {
                navigate('/login')
            } else if (preData.userNo !== undefined || preData.userNo !== "" || preData.userNo !== null) {
                setUserNo(preData.userNo)
                getAPIData(preData.userNo)
            }
        }
        window.scroll(0,0)
    }, [token])

    const myToken = async () => {
        const T = await getToken();
        dispatch(GenerateToken(T))
    }

    useEffect(() => {
        if (token === "") {
            myToken()
        }
        window.scroll(0,0)
    }, [])


    const ManageRows = (values) => {
        return values.row.map((row, ind) => {
            return <div key={ind} className="userData">
                <div className="dataHead">{row.name}</div>
                <div className="dataContent">{row.value ? row.value : "-"}</div>
            </div>
        })
    }

    return (
        <>
            {loader ? <CKYCWaiting /> :
                <div className='myProfile'>
                    <h3 className='myAccountHeading'>My Profile</h3>
                    <div className="myProfileHeading">
                        <div className="myProfileImg">
                            <img src={Profile} alt="" />
                        </div>
                        <div className="myProfileIntroPart">
                            <span className="personName">{data.personal_details.customer_name ? data.personal_details.customer_name : ""}</span>
                            <span className="personNumber">+91-{userNo}</span>
                        </div>
                    </div>
                    <div className="myAccountBoxes">
                        <div className={"myAccountBox" + (openSection[0] ? " activateBox" : "")} >
                            <div className="boxheading" onClick={() => OpenNewSection(0)}><span>Personal Details</span><div style={{ display: "flex", alignItems: "center", gap: "40px" }}><i className={"arrow " + (openSection[0] ? "up" : "down")}  ></i></div></div>
                            {openSection[0] && <div className="myAccountBoxContent">
                                <div className="userData">
                                    <div className="dataHead">PAN Card</div>
                                    <div className="dataContent">{data.personal_details.pan_card ? data.personal_details.pan_card : "-"}</div>
                                </div>
                                <div className="userData">
                                    <div className="dataHead">Date of Birth</div>
                                    <div className="dataContent">{data.personal_details.dob ? data.personal_details.dob : "-"}</div>
                                </div>
                                <div className="userData">
                                    <div className="dataHead">Gender</div>
                                    <div className="dataContent">{data.personal_details.gender ? data.personal_details.gender : "-"}</div>
                                </div>
                                <div className="userData">
                                    <div className="dataHead">Highest Qualification</div>
                                    <div className="dataContent">{data.personal_details.highest_qualification ? data.personal_details.highest_qualification : "-"}</div>
                                </div>
                                <div className="userData">
                                    <div className="dataHead">Marital Status</div>
                                    <div className="dataContent">{data.personal_details.maritial_status ? data.personal_details.maritial_status : "-"}</div>
                                </div>
                                <div className="userData">
                                    <div className="dataHead">Father’s Name</div>
                                    <div className="dataContent">{data.personal_details.father_name ? data.personal_details.father_name : "-"}</div>
                                </div>
                                <div className="userData">
                                    <div className="dataHead">Mother’s Name</div>
                                    <div className="dataContent">{data.personal_details.mother_name ? data.personal_details.mother_name : "-"}</div>
                                </div>
                                <div className="userData">
                                    <div className="dataHead">Email Address</div>
                                    <div className="dataContent">{data.personal_details.email ? data.personal_details.email : "-"}</div>
                                </div>
                            </div>}
                        </div>

                    </div>

                    <div className="myAccountBoxes">
                        <div className={"myAccountBox" + (openSection[1] ? " activateBox" : "")} >
                            <div className="boxheading" onClick={() => OpenNewSection(1)}><span>Employment Details</span><div style={{ display: "flex", alignItems: "center", gap: "40px" }}><i className={"arrow " + (openSection[1] ? "up" : "down")} ></i></div></div>
                            {openSection[1] && <div className="myAccountBoxContent">
                                <div className="userData">
                                    <div className="dataHead">Employer Type</div>
                                    <div className="dataContent">{data.employment_details.employer_name ? data.employment_details.employer_name : "-"}</div>
                                </div>
                                <div className="userData">
                                    <div className="dataHead">Employer Name</div>
                                    <div className="dataContent">{data.employment_details.employer_type ? data.employment_details.employer_type : "-"}</div>
                                </div>
                            </div>}
                        </div>

                    </div>


                    <div className="myAccountBoxes">
                        <div className={"myAccountBox" + (openSection[2] ? " activateBox" : "")} >
                            <div className="boxheading" onClick={() => OpenNewSection(2)}><span>Residential Address</span><div style={{ display: "flex", alignItems: "center", gap: "40px" }}><i className={"arrow " + (openSection[2] ? "up" : "down")} ></i></div></div>
                            {openSection[2] && <div className='res-address'>
                                <div className="subDataHead remove-bottom">Current Address</div>
                                <div className="myAccountBoxContent managing-res-address" >
                                    <div className="userData">
                                        <div className="dataHead">Building / Block Number</div>
                                        <div className="dataContent">{data.current_address.curr_address ? data.current_address.curr_address : "-"}</div>
                                    </div>
                                    <div className="userData">
                                        <div className="dataHead">Area/ Sector</div>
                                        <div className="dataContent">{data.current_address.curr_area_sector ? data.current_address.curr_area_sector : "-"}</div>
                                    </div>
                                    <div className="userData">
                                        <div className="dataHead">Landmark</div>
                                        <div className="dataContent">{data.current_address.landmark ? data.current_address.landmark : "-"}</div>
                                    </div>
                                    <div className="userData">
                                        <div className="dataHead">City</div>
                                        <div className="dataContent">{data.current_address.city ? data.current_address.city : "-"}</div>
                                    </div><div className="userData">
                                        <div className="dataHead">Pincode</div>
                                        <div className="dataContent">{data.current_address.pincode ? data.current_address.pincode : "-"}</div>
                                    </div>
                                </div>
                                <div className="subDataHead remove-bottom">Permanent Address</div>
                                <div className="myAccountBoxContent managing-res-address" >
                                    <div className="userData">
                                        <div className="dataHead">Building / Block Number</div>
                                        <div className="dataContent">{data.permanent_address.perm_address ? data.permanent_address.perm_address : "-"}</div>
                                    </div>
                                    <div className="userData">
                                        <div className="dataHead">Area/ Sector</div>
                                        <div className="dataContent">{data.permanent_address.perm_area_sector ? data.permanent_address.perm_area_sector : "-"}</div>
                                    </div>
                                    <div className="userData">
                                        <div className="dataHead">Landmark</div>
                                        <div className="dataContent">{data.permanent_address.perm_landmark ? data.permanent_address.perm_landmark : "-"}</div>
                                    </div>
                                    <div className="userData">
                                        <div className="dataHead">City</div>
                                        <div className="dataContent">{data.permanent_address.perm_city ? data.permanent_address.perm_city : "-"}</div>
                                    </div><div className="userData">
                                        <div className="dataHead">Pincode</div>
                                        <div className="dataContent">{data.permanent_address.perm_pincode ? data.permanent_address.perm_pincode : "-"}</div>
                                    </div>
                                </div>
                            </div>}
                        </div>

                    </div>

                    <div className="myAccountBoxes">
                        <div className={"myAccountBox" + (openSection[3] ? " activateBox" : "")} >
                            <div className="boxheading" onClick={() => OpenNewSection(3)}><span>Office Address</span><div style={{ display: "flex", alignItems: "center", gap: "40px" }}><i className={"arrow " + (openSection[3] ? "up" : "down")} ></i></div></div>
                            {openSection[3] && <div className="myAccountBoxContent">
                                <div className="userData">
                                    <div className="dataHead">Office / Building / Block Number</div>
                                    <div className="dataContent">{data.office_address.office_address ? data.office_address.office_address : "-"}</div>
                                </div>
                                <div className="userData">
                                    <div className="dataHead">Area/ Sector</div>
                                    <div className="dataContent">{data.office_address.area_sector ? data.office_address.area_sector : "-"}</div>
                                </div>
                                <div className="userData">
                                    <div className="dataHead">Landmark</div>
                                    <div className="dataContent">{data.office_address.office_landmark ? data.office_address.office_landmark : "-"}</div>
                                </div>
                                <div className="userData">
                                    <div className="dataHead">City</div>
                                    <div className="dataContent">{data.office_address.office_city ? data.office_address.office_city : "-"}</div>
                                </div><div className="userData">
                                    <div className="dataHead">Pincode</div>
                                    <div className="dataContent">{data.office_address.office_pincode ? data.office_address.office_pincode : "-"}</div>
                                </div>
                            </div>}
                        </div>

                    </div>

                    {/* <div className="updateSection">
                        <p><a href="/service-request" target={'_blank'} className="send-lnk">Click here</a> to request a update in profile details</p>
                    </div> */}
                </div>}
        </>
    )
}

export default MyProfile;