import React, { useEffect } from "react";
import "./Contact.css";
import "../footermorelinkspages.css";
import RightSidePopUp from "../Right Side PopUp/RightSidePopUp";
import banner from './contactbanner.svg';
import { Link } from "react-router-dom";

const Contact = () => {
  const [y, sety] = React.useState(true);
  window.addEventListener("scroll", (event) => {
    // console.log(window.scrollY);
    if (window.scrollY >= 437) {
      sety(false);
    } else {
      sety(true);
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {y && <RightSidePopUp />}
      <div style={{ backgroundColor: "#f9fafe", marginTop: "0px" }}>
        <div className="contact-bann">
          <div className="contact-homebanner">
            <div className="left-top">
              <Link to={`/${window.location.search}`}>Home</Link>  &gt;{" "}
              <span>Contact Us</span>
            </div>
            <div className="contact-left-content">
              <h1>Contact Moneywide</h1>
              <hr />
              <div className="contact-home-words">
                <p>To help us answer all your inquiries and complaints</p>
              </div>
              <div className="contact-service">
                <a href="/service-request">
                  Submit Complaint or Service Request
                </a>
              </div>
            </div>
            <div className="contact-right-content">
              {/* <img className="right-img" src={banner} alt="image not fount" /> */}
              <img
                src={banner}
                alt=""
              />
            </div>
          </div>
          <div className="contact-card">
            <div className="contact-card-people">
              <div>
              <p className="contact-bold">Customer Care</p>
              <hr />
              </div>
              <div className="contact-people-no">
                <strong>Contact No:</strong> <a className="contact-tel" href="tel:9911862226">9911862226</a><br/>
                <span className="contact-time">
                (Monday to Friday- 10 AM to 6:30 PM & Saturday-10 AM to 5:00 PM)
              </span>
              </div>
              <div className="contact-people-no-1">
              <strong>Email:</strong> <a className="contact-a"
                    href="mailto:care@moneywide.com"
                    style={{ textDecoration: "none" }}
                  >
                    care@moneywide.com
                  </a><br/>
              </div>
            </div>
            {/* <div className="contact-card-people">
              <div>
              <p className="contact-bold">DSA / Connector / Partnership</p>
              <hr />
              </div>
              <p><strong>Mr.Vijay Kumar</strong></p> */}
              {/* <div className="contact-people-no">
                <strong>Contact No:</strong> <a className="contact-tel" href="tel:9873397329">9873397329</a><br/>
                <span className="contact-time">
                (Monday to Saturday: 10 AM to 6:30 PM)
              </span>
              </div> */}
              {/* <div className="contact-people-no-1">
              <strong>Email:</strong> <a className="contact-a"
                    href="mailto:vijay@moneywide.com"
                    style={{ textDecoration: "none" }}
                  >
                    vijay@moneywide.com
                  </a><br/>
              </div> */}
            {/* </div> */}
            <div className="contact-card-people">
              <div>
              <p className="contact-bold">Grievance Redressal Officer</p>
              <hr />
              </div>
              <p><strong>Ghanshyam Soni</strong></p>
              <div className="contact-people-no-1">
              <strong>Email:</strong> <a className="contact-a"
                    href="mailto:gro@moneywide.com"
                    style={{ textDecoration: "none" }}
                  >
                    gro@moneywide.com
                  </a><br/>
              </div>
              <div className="contact-people-no-1">
              <strong>Office Address:</strong><br/>
              <p className="grievance-address">
                <a className="contact-a"
                    href="https://goo.gl/maps/K4YdqNDbwhaUigXy7"
                    style={{ textDecoration: "none" }}
                  >
                    Plot no. 131, Ground Floor, Sector-44, Gurgaon-122003
                  </a>
              </p> 
              </div>
            </div>
          </div>
          <div className="contact-address">
          <div className="contact-card-people">
              <div>
              <p className="contact-bold">General Corporate Enquires</p>
              <hr />
              </div>
              <div className="contact-people-no">
              <p><strong>MyLoanCare Ventures Private Limited</strong></p>
                <strong>Corporate Office:</strong><br/> <p><a className="contact-tel" target="_blank" href="https://goo.gl/maps/K4YdqNDbwhaUigXy7">Plot no. 131, Ground Floor, Sector-44, Gurgaon-122003</a></p>
              </div>
              <div className="contact-people-no-1">
              <strong>Email:</strong> <a className="contact-a"
                    href="mailto:corporate@moneywide.com"
                    style={{ textDecoration: "none" }}
                  >
                    corporate@moneywide.com
                  </a><br/>
              </div>
            </div>
            <div className="contact-card-people" style={{ padding: "0px", backgroundColor: "none" }}>
              <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.9566221499244!2d77.07054631488903!3d28.45072398248985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d2286cb5fb0b3%3A0x6af1d47adaa1a731!2sMoneyWide!5e0!3m2!1sen!2sin!4v1671254140333!5m2!1sen!2sin" allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
