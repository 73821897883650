import React, { useEffect, useRef, useState } from 'react';
import './ChatBot.css';
import refreshchat from '../../Icons/refresh-chat.svg';
// import ChatbotIcon from '../../Icons/Chatbot-Icon.svg';
import sendarrow from '../../Icons/sendarrow.svg';
import crossicon from '../../Icons/cross-icon.svg'
import activeArrow from '../../Icons/activeArrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { apiInstance } from '../Config/APIConfig';
import { DesiredLoanAmt, EmailValidation, NumberOnly, PanRunTimeValidation, panValidation, phnNumberValidation, PincodeValidation, Salary } from "./ChatBotValidationFunctions";
import { getCompanyName, getPinOption } from '../EverCalledFunctions/EverCalledApis';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../assests/lootie-files/Chatbot Typing animation.json';
import DateCalender from '../DateCalender/DateCalender';
import { formatDate, formmonthonly } from '../MyAccount/MyAccountAPIs';
import DateMonthOnly from '../DateCalender/DateMonthOnly';
import mwChatBot from '../assests/lootie-files/catbot-color.json'
import axios from 'axios';

function ChatPopUpNew({ setIsOpen }) {
  const { token } = useSelector((state) => state);
  const [startDate, setStartDate] = useState('');
  let currentDate = new Date()
  const paddedMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const paddedDay = currentDate.getDate().toString().padStart(2, '0');
const [timeLimit, setTimeLimit] = React.useState({
  minTime: new Date(`${new Date().getFullYear() - 60}-${paddedMonth}-${paddedDay}`),
  maxTime: new Date(`${new Date().getFullYear() - 21}-${paddedMonth}-${paddedDay}`)
});
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const [messages, setMessages] = useState([
    {
      type: 'bot', text: {
        data: {
          responseToUser: ["Hii, Welcome to Moneywide 😇,  I am MeoW, your virtual assistant. I am here to assist you with your Personal Loan queries.", "Please Enter Your Mobile Number"],
          error_flag: 0,
          option: []
        }
      }
    }
  ]);
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [question, setQuestion] = useState('Valid Mobile');
  const [usenumber, setUsenumber] = useState('');
  const [otpid, setOtpid] = useState("")
  const [visitorId, setVisitorid] = useState('');
  const dispatch = useDispatch();
  const [state, setState] = useState({
    error1: "Please Enter a valid Mobile Number",
    error2: "Please Enter a valid Pancard",
    error3: "Pleae Enter Correct Email Address",
    error4: "Amount Should be greater than 10,000",
    error5: "Salary Should be greater than 10,000",
    error6: "Please enter a valid Pincode"
  })
  // const [isValidation, setisValidation] = useState();
  const [pincodeOptions, setPincodeOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [img, setImg] = useState(false);
  const [loader, setLoader] = useState(false);
  const [inputMaxLength, setInputMaxLength] = useState(10);
  const [inputMinLength, setInputMinLength] = useState(3);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [timer, setTimer] = useState(30);
  const [redirect, setRedirect] = useState('');
  const [redirectjou, setRedirectjou] = useState('');
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 480);
  const [isMouseHover, setIsMouseHover] = useState("");
  const [rawid, setRawid] = useState('');
  const [disbursed, setDisbursed] = useState(false);
  // const [imageLoaded, setImageLoaded] = useState(false);
  const [thankyou, setThankyou] = useState(false);
  const [type, setType] = useState('tel');
  const [pincodeValication, setPincodeValidation] = useState(true);
  const [timerIndex, setTimerIndex] = useState("");
  const [ip,setIP] = useState('');
  const [device_id, setDevice_id] = useState('');
  // const [timer, setTimer] = useState(30);

  const inputRef = useRef(null);
  const refreshRef = useRef(null);
  const chatMessagesRef = useRef(null);

  const getPincodeOption = async (val) => {
    const res = await getPinOption(val, token)
    return res || [];

  }
  ////
  const getData = async () => {
    const res = await axios.get('https://api.ipify.org?format=json')
    setIP(res.data.ip);
  }

  function knowdevice() {
    if (window.innerWidth > 1024) {
      setDevice_id("1");
    } else if (window.innerWidth > 480 && window.innerWidth < 1024) {
      setDevice_id("3");
    } else {
      setDevice_id("2");
    }
  }

  useState(()=>{
    if(ip == ""){
      getData();
    }
    if(device_id == ""){
      knowdevice();
    }
  },[ip,device_id])

  useEffect(() => {
    if (messages?.text?.data?.option.length > 0) {

    }
    else if ((question === 'dob') || (question === 'current_work_experience')) {

    }
    else {
      if (window.innerWidth <= 480) {
        return;
      }
      else {
        inputRef.current.focus();
      }

    }

  }, [messages]);

  const handleInputChange = async (e) => {
    if (e.target.value !== "") {
      setImg(true);
    }
    else {
      setImg(false);
    }
    if (question === 'Valid Mobile' || question === 'loan amount') {
      const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
      if (onlyNumbers === '') {
        setInputValue('');
      } else {
        setInputValue(onlyNumbers);
      }
    } else {
      setInputValue(e.target.value);
    }
    // if((question === 'pincode') && ((e.target.value.length < 3) || ((e.target.value >= 110) && (e.target.value.length >= 3 && e.target.value.length < 6)) || (e.target.value <= 110000))) {

    // }
    if (question === 'pincode') {
      if (e.target.value.length >= 3) {
        setInputValue(e.target.value);
        const pincodeOptions = await getPincodeOption(e.target.value);
        if (pincodeOptions.length == 0) {
          if((e.target.value.length === 6) && (PincodeValidation(e.target.value))) {
            setPincodeValidation(true);
          }
          else {
            setPincodeValidation(false);
          }
        }
        else {
          // console.log(pincodeOptions)
          setPincodeValidation(true);
          setPincodeOptions(pincodeOptions);
        }

      }
      else {
        setPincodeOptions([]);
      }
    }
    if (question === 'employer_name') {
      if (e.target.value.length >= 3) {
        setInputValue(e.target.value);
        const companyOptions = await getCompanyName(e.target.value,token);
        // console.log(companyOptions)
        setCompanyOptions(companyOptions.data);
      }
      else {
        setCompanyOptions([]);
      }
    }
    if (question === 'Pancard') {
      let panVal = PanRunTimeValidation(e.target.value);
      if (panVal) {
        if((e.target.value.length >= 5) && (e.target.value.length < 9)) {
          setType("tel")
        }
        else {
          setType("text")
        }
        setInputValue(e.target.value.toUpperCase());
      }
      else {
        if (inputValue.length === 1) {
          setInputValue("")
        }
        else {
          setInputValue(inputValue);
        }

      }

    }
    else {
      setInputValue(e.target.value);
    }
    if ((question === "salary") || (question === "loan amount")) {
      let v = e.target.value
      if (v.includes(",")) {
        v = v.replaceAll(",", "");
      }
      v = +v;
      if (v === 0) {
        setInputValue("");
      }
      else {
        setInputValue(v.toLocaleString("en-IN"));
      }
    }
  };

  useEffect(() => {
    if (question == 'Pancard') {
      setInputMaxLength(10);
      setInputMinLength(3);
      setType('text');
    }
    else if ((question == 'verify otp') || (question == 'Re verify otp')) {
      setInputMaxLength(4);
      setInputMinLength(4);
      setType('tel');
    }
    else if (question == 'email_id') {
      setInputMaxLength(30);
      setInputMinLength(3);
      setType('text');
    }
    else if (question == 'loan amount' || question == 'salary') {
      setInputMaxLength(7);
      setInputMinLength(4);
      setType('tel');
    }
    else if (question == 'pincode') {
      setInputMaxLength(6);
      setInputMinLength(3);
      setType('tel');
    }
    else if (question == 'loader') {
      setLoader(true);
      setTimeout(() => {
        apicallfxn("");
      }, 10000);
    }
    else if (question == 'Valid Updated Mobile') {
      setInputMaxLength(10);
      setInputMinLength(3);
      setType('tel');
    }
    else if (question === "dob" || question === "current_work_experience") {
      setImg(true);
    }
    else if (question === "Follow Up" && redirect !== "https://moneywide.com/service-request") {
      // timer > 0 && setTimeout(() => {
      //   setTimer(timer - 1)
      // }, 1000)
      if (redirect !== "") {

        setTimeout(() => {
          window.open(redirect, '_blank');
        }, 5000);
      }


      setTimeout(() => {
        apicallfxn("");
      }, 10000);


    }

    else if (question === "view Account Details" || question == "Raise Query Url" || question === "FAQ" || question === "Disbursed Follow Up" || question === "Thank You") {
      if (redirect !== "https://play.google.com/store/search?q=moneywide&c=apps&hl=en-IN" && redirect !== "") {
        if (thankyou) {
          apicallfxn("");
          setThankyou(false);
        }
      }

    }
    else if (question === "sorry") {
      setDisbursed(true);
    }
    else if (question === "loan Purpose others") {
      setInputMaxLength(30);
      setInputMinLength(3);
      setType('text');
    }
  }, [question, thankyou])


  useEffect(() => {
    if (inputValue != "") {
      if (question === "dob" || question === "current_work_experience" || ((question === "Valid Mobile" || question === "Valid Updated Mobile") && (inputValue.length === 10)) || ((question === "verify otp" || question === "Re verify otp") && (inputValue.length === 4)) || ((question === "Pancard") && (inputValue.length === 10))) {
        const buttonElement = document.querySelector('.chat-button--auto');
        if (buttonElement) {
          buttonElement.click();
        }
      }
    }
  }, [inputValue, question])

  const handleSubmit = (e) => {
    let val = inputValue;
    if (question === "dob") {
      val = formatDate(inputValue)
      setImg(true)
    }
    else if (question === 'current_work_experience') {
      val = formmonthonly(inputValue);
    }
    else {
      val = inputValue
    }
    e.preventDefault();
    setImg(false);
    if (val.toString().includes(" ")) {
      setInputValue(val.trim())
    }
    let obj = {
      data: {
        error_flag: 0,
        responseToUser: [val],
        option: []
      }
    }
    setMessages((prevMessages) => [...prevMessages, { type: 'user', text: obj }]);
    setInputValue('');
    setIsInputEmpty(false);
  };

  const handleRefreshChat = () => {
    setMessages([
      {
        type: 'bot',
        text: {
          data: {
            responseToUser: ["Hii, Welcome to Moneywide 😇,  I am MeoW, your virtual assistant. I am here to assist you with your Personal Loan queries.", "Please Enter Your Mobile Number"],
            error_flag: 0,
            option: []
          }
        }
      }
    ]);
    setInputValue('');
    setError(false);
    setIsInputEmpty(true);
    setQuestion('Valid Mobile');
    setRedirect('');
    setImg(false);
    setInputMaxLength(10);
    setInputMinLength(3);
    setDisbursed(false);
    setLoader(false);
    setPincodeOptions([]);
    setCompanyOptions([]);
    setType('tel');
    setThankyou(false);

    refreshRef.current.classList.add('rotate-360');

    setTimeout(() => {
      refreshRef.current.classList.remove('rotate-360');
    }, 3000);

  };

  useEffect(() => {
    if (inputValue.length == 10 && (question == "Valid Mobile" || question == "Valid Updated Mobile")) {
      setUsenumber(inputValue)
    }
  }, [inputValue])

  const handleBotResponse = (userInput, qstn, isValidation) => {

    if (isValidation) {
      let IsValide = qstn == "Valid Mobile" ? NumberOnly(userInput) :
        (qstn == 'Pancard' ? panValidation(userInput) : (qstn == 'email_id' ? EmailValidation(userInput) : (qstn == 'loan amount' ? DesiredLoanAmt(userInput) : (qstn == "salary" ? Salary(userInput) : (qstn == 'Valid Updated Mobile' ? NumberOnly(userInput) : (qstn == "pincode" ? pincodeValication : ""))))));

      let errorMsg = qstn == "Valid Mobile" ? state.error1 : (qstn == 'Pancard' ? state.error2 : (qstn == "email_id" ? state.error3 : (qstn == "loan amount" ? state.error4 : (qstn == 'salary' ? state.error5 : (qstn == 'Valid Updated Mobile' ? state.error1 : (qstn == 'pincode' ? state.error6 : ""))))));
      if (IsValide) {
        apicallfxn(userInput)
      }
      else {
        setError(true)
        let obj = {
          data: {
            error_flag: 1,
            responseToUser: [errorMsg],
            option: []
          }
        }
        setMessages((prevMessages) => [...prevMessages, { type: 'bot', text: obj }]);
        return;
      }

    }
    else {
      apicallfxn(userInput)
    }

  };

  useEffect(() => {
    let lastMessage = messages[messages.length - 1].text.data.responseToUser[0];
    const type = messages[messages.length - 1].type;
    if (lastMessage && type === 'user') {
      let qstn = question
      let isValidation = false;
      if ((qstn == "Valid Mobile" || qstn == 'Valid Updated Mobile') || (qstn == "Pancard") || (qstn == "email_id") || (qstn == "loan amount") || (qstn == "salary") || (qstn == 'pincode')) {
        isValidation = true
      }
      if ((question === "loan amount") || (question === "salary")) {
        lastMessage = lastMessage.replaceAll(",", "");
      }
      handleBotResponse(lastMessage, qstn, isValidation);
    }
    chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
  }, [messages]);

  const apicallfxn = async (val) => {
    setLoader(true);
    const questionValue = question.length > 0 ? question : 'Valid Mobile';
    const payload = {
      "verification_source": 6,
      "verified_by": 2,
      "verifier_name": "System",
      "source": "Mwide",
      "user_input": val.toString(),
      "question": ((questionValue == "verify otp") && (val == 1)) ? "Resend OTP" : ( ((questionValue == "Resend OTP") && (val != 1)) ? "verify otp" : questionValue),
      "otpId": otpid ? otpid : 0,
      'user_number': usenumber ? usenumber : 0,
      "visitor_id": visitorId ? visitorId : 0,
      "page_url": window.location.href,
      "selected_loan_id": rawid,
      'ip':ip,
      'device_id':device_id
    }

    const res = await apiInstance.post('chatbot', payload, { token })
    if ((res.status === "1") && (res.status_code === "200")) {
      if (res?.data?.next_question != "" && res?.data?.responseToUser) {
        clearTimeout(timerIndex);
        setTimer(30);
        setLoader(false);
        setQuestion(res?.data?.next_question)
        
        setVisitorid(res?.data?.visitor_id);
        setRedirect(res?.data?.redirect_link);
        setRedirectjou(res?.data?.redirection_url);
        if (res?.data?.next_question == "verify otp") {
          setOtpid(res?.data?.otpId)
          setTimer(30)
        }
        if (res?.data?.is_disbursed == 1) {
          setDisbursed(true);
        }
        setMessages((prevMessages) => [...prevMessages, { type: 'bot', text: res }]);
      }
      else {
        setLoader(true);
      }
    }

  }

  function f1(event, Option) {
    event.preventDefault();
    let optionName = Option.Name;
    let obj = {
      type: "user",
      text: {
        data: {
          error_flag: 0,
          responseToUser: [],
          option: [Option]
        }
      }

    }
    let msgs = messages;
    msgs.push(obj);
    for (let i = 0; i < msgs.length; i++) {
      let { text: { data: { option } } } = msgs[i];
      if (option.length > 0) {
        let k = -1;
        for (let j = 0; j < option.length; j++) {
          if ((optionName === option[j]["Name"])) {
            k = j
          }
          if (option[j]["clicked"]) {
            k = -1;
          }
        }
        if (k !== -1) {
          option[k] = { ...option[k], "clicked": true }
        }
      }
    }
    setMessages(msgs);

    if ((optionName === "Resume Application") && (redirectjou !== "") && (question === "Resume Journey")) {
      window.open(redirectjou, '_blank');
    }
    if ((question === "Fail" || optionName === "Read FAQS" || question === "Follow Up" || question === "FAQ" || question === "Thank You" || question === "Disbursed Follow Up") && (redirect !== "")) {
      setThankyou(true)
      window.open(redirect, '_blank');
    }
    if (optionName === "View Account Details") {
      setLoader(true);
      setTimeout(() => {
        apicallfxn(2);
      }, 10000);
    }
    else {
      if (question === "verify otp") {
        apicallfxn(Option.ID);
      }
      else {
        if(question === 'Disbursed Follow Up' || question === "sorry" || question === "Thank You"){
         
        }
        else {
          apicallfxn(Option.ID);
        }

      }


    }


  }

  useEffect(()=>{
    if(question === "Disbursed Follow Up" && disbursed && redirect == ""){
      setTimeout(() => {
        apicallfxn("");
      }, 5000);
    }
  },[disbursed,question])

  function f2(event, option) {
    event.preventDefault();
    if (option.toString().includes(" ")) {
      option = option.toString().trim()
    }
    let obj = {
      data: {
        error_flag: 0,
        responseToUser: [option],
        option: []
      }
    }
    setMessages((prevMessages) => [...prevMessages, { type: 'user', text: obj }]);
    setInputValue('');
    setIsInputEmpty(true);
  }

  function f3(event, option) {
    event.preventDefault();
    setInputValue(option.company_name)
    if (option.toString().includes(" ")) {
      option = option.toString().trim()
    }
    let obj = {
      data: {
        error_flag: 0,
        responseToUser: [option],
        option: []
      }
    }
    setMessages((prevMessages) => [...prevMessages, { type: 'user', text: obj }]);
    setInputValue('');
    setIsInputEmpty(true);
  }

  const LoanClickedFunction = (loan) => {
    let loanName = loan.lead_id;
    let obj = {
      type: "user",
      text: {
        data: {
          error_flag: 0,
          responseToUser: [],
          option: [],
          loan_details: [loan]
        }
      }

    }
    let msgs = messages;
    msgs.push(obj);
    for (let i = 0; i < msgs.length; i++) {
      if (msgs[i]?.text?.data?.loan_details) {
        let { text: { data: { loan_details } } } = msgs[i];
        if (loan_details.length > 0) {
          let k = -1;
          for (let j = 0; j < loan_details.length; j++) {
            if ((loanName === loan_details[j]["lead_id"])) {
              k = j
            }
            if (loan_details[j]["clicked"]) {
              k = -1;
            }
          }
          if (k !== -1) {
            loan_details[k] = { ...loan_details[k], "clicked": true }
          }
        }
      }
    }
    setMessages(msgs);
  }

  const f4 = (e, loan) => {
    // let obj = {
    //   type: "user",
    //   text: {
    //     data: {
    //       error_flag: 0,
    //       responseToUser: [],
    //       option: [],
    //       loan_details: [loan]
    //     }
    //   }

    // }
    // let msgs = messages;
    // msgs.push(obj);
    // setMessages(msgs);
    LoanClickedFunction(loan);
    setRawid(loan.lead_id);
    apicallfxn(loan.lead_id);
  }

  const handleKeyPress = (e) => {
    if (question === 'Valid Mobile' || question === 'loan amount' || question === 'Valid Updated Mobile' || question === 'verify otp' || question === 'salary' || question == "Valid Updated Mobile") {
      const charCode = e.which ? e.which : e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      }
    }
    else if (question === 'pincode') {
      const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Enter'];

      if (!allowedKeys.includes(e.key)) {
        e.preventDefault();
      }
    }
  };

  // const handleKeyDown = (event) => {
  //   const { key } = event;
  //   console.log(key)
  //   if (key === 'ArrowDown') {
  //     setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
  //   }
  //   else if (key === 'ArrowUp') {
  //     setSelectedIndex((prevIndex) =>
  //       Math.min(prevIndex + 1, pincodeOptions.length - 1)
  //     );
  //   }
  //   else if (key === 'Enter') {
  //     if (selectedIndex >= 0 && selectedIndex < pincodeOptions.length) {
  //       handleSelection(pincodeOptions[selectedIndex]);
  //     }
  //   }
  // };

  // const handleSelection = (selectedResult) => {
  // };

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 480);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = (e, option) => {
    if (option.Name) {
      setIsMouseHover(option.Name);
    }
    else {
      setIsMouseHover(option.lead_id)
    }
  }

  const handleMouseLeave = (e, option) => {
    setIsMouseHover("");
  }

  const setstateopen = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if ((question === "verify otp") || (question === "Resend OTP")) {
      timer > 0 && setTimerIndex(setTimeout(() => {
        setTimer(timer - 1);
      }, 1000))
    }
  }, [question, timer])

  return (
    <>
      <div className="mw-chat-bot">
        <div className="chat-head">
        <div className="chat-head-left">
          <Lottie
                        loop
                        animationData={mwChatBot}
                        play
                        className='chat-bot-icon-main-div'
                    />
            <span>MeoW -</span><span style={{fontFamily:'poppinsregular',marginLeft:"-7px"}}>Your Virtual Assistant</span>
          </div>
          <div className="chat-head-right">
            <img src={refreshchat} alt="Refresh Chat" ref={refreshRef} onClick={handleRefreshChat} />
            <img onClick={setstateopen} src={crossicon} />
          </div>
        </div>
        <div className="chat-mid-area chat-messages" ref={chatMessagesRef}>
          {messages.map((mess, index) => (
            <div key={index} className={mess.type === 'user' ? 'usermsgdiv' : 'botmsgdiv'}
            style={
              isMobileView && mess.type == 'bot' && index == 0
                ? messages.length == 1
                  ? { marginTop: '398px' }
                  : messages.length == 2
                    ? { marginTop: '178px' }
                    : messages.length == 3
                      ? { marginTop: '178px' }
                      : messages.length == 5
                        ? { marginTop: '0px' }
                        : {}
                : mess.type == 'bot' && index == 0
                  ? messages.length == 1
                    ? { marginTop: '221px' }
                    : messages.length == 2
                      ? { marginTop: '155px' }
                      : messages.length == 3
                        ? { marginTop: '2px' }
                        // : messages.length == 4
                        //   ? { marginTop: '54px' }
                        : messages.length == 5
                          ? { marginTop: '0px' }
                          : messages.length == 6
                            ? { marginTop: '0px' }
                            : {}
                  : {}
            }
            >
              {(mess?.text?.data?.error_flag === 1) ? (mess.text.data.responseToUser.map((item, idx) => (
                <p className='error-chat'>{item}</p>
              ))) : (mess.text.data.responseToUser.map((item, idx) => (
                <p className={`message ${mess.type === 'user' ? 'user-message' : 'bot-message'}`}>{item}</p>
              )))}
              {mess?.text?.data?.option?.length > 0 && (
                <div>
                  {mess?.text?.data?.option.map((option, buttonIndex) => (
                    ((messages.length - 1 == index) && (option.Name == "Resend OTP") && ((question === "verify otp") || (question === "Resend OTP")) && (timer != 0)) ? <div className='timer-bot'><p className='bot-message' style={{ marginLeft: "0px" }}>Resend OTP : {timer} sec</p></div> : <button
                      className={((isMouseHover === option.Name) && (index == messages.length - 1)) ? "box-hover" : ""}
                      onMouseEnter={(e) => handleMouseEnter(e, option)}
                      onMouseLeave={(e) => handleMouseLeave(e, option)}
                      style={(option["clicked"]) ? { background: "#4D3ECC", color: "#fff" } : { background: "white", color: "#4D3ECC" }}
                      key={buttonIndex}
                      disabled={index !== messages.length - 1 ? true : false}
                      type="submit"
                      value={option.ID}
                      onClick={(event) => f1(event, option)}
                    >
                      {option.Name}
                    </button>
                  ))}
                </div>
              )}
              {mess?.text?.data?.loan_details?.length > 0 && (
                <div
                  disabled={(mess.type !== "user") ? true : false}
                  className={`message ${mess.type === 'user' ? 'user-message chat-disburse-loan-main' : 'bot-message chat-disburse-loan-main'}`}>
                  {mess?.text?.data?.loan_details.map((loan, buttonIndex) => {
                    return (
                      <button
                        disabled={index !== messages.length - 1 ? true : false}
                        className={(isMouseHover === loan.lead_id) ? "chat-disburse-loan-sub-hover" : "chat-disburse-loan-sub"}
                        onMouseEnter={(e) => handleMouseEnter(e, loan)}
                        onMouseLeave={(e) => handleMouseLeave(e, loan)}
                        style={(loan["clicked"]) ? { background: "#4D3ECC", color: "#fff", marginLeft: "0px", textAlign: "left" } : { marginLeft: "0px" }}
                        onClick={(event) => f4(event, loan)}>
                        <p style={(loan["clicked"]) ? { color: "#fff", marginLeft: "0px", marginBottom: "6px" } : { marginBottom: "6px" }}>Loan Amount: <span >{loan.loan_amount}</span></p>
                        <p style={(loan["clicked"]) ? { color: "#fff", marginLeft: "0px", marginBottom: "6px" } : { marginBottom: "6px" }}>Disbursement Date: <span>{loan.disbursement_date}</span></p>
                        <p style={(loan["clicked"]) ? { color: "#fff", marginLeft: "0px" } : {}}>Status: <span style={(loan["clicked"]) ? { color: "#fff" } : {}}>{loan.Status}</span></p>
                      </button>
                    )
                  })}
                </div>
              )
              }
            </div>
          ))}
          {question === "Follow Up" && redirect != "" && redirect !== "https://moneywide.com/service-request" && <div className='botmsgdiv'><p className='bot-message'>Redirecting Within few seconds</p></div>}
          {/* {question === "verify otp" && timer > 0 ? <div className='botmsgdiv'><p className='bot-message'>Resend otp : {timer} seconds</p></div> : (question === "verify otp" && timer === 0 && <button
                      onClick={resendotp}
                    >
                     Resend OTP
                    </button>)} */}
        </div>
        {question === 'pincode' && pincodeOptions.length > 0 && (
          <div className="pincode-dropdown">
            <ul className='chatbot-dropdown-ul'>
              {pincodeOptions.map((option, keyi) => (
                <li key={keyi}>
                  <button onClick={(event) => f2(event, option)} style={(selectedIndex) === keyi ? { backgroundColor: "red" } : {}}>{option}</button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {question === 'employer_name' && companyOptions.length > 0 && (
          <div className="pincode-dropdown">
            <ul className='chatbot-dropdown-ul'>
              {companyOptions.map((option, keyi) => (
                <li key={keyi}>
                  <button onClick={(event) => f3(event, option.company_name)}>{option.company_name}</button>
                </li>
              ))}
            </ul>
          </div>
        )
        }

        {loader && <div className="chatbot-loader-cta" >
          <Lottie loop animationData={ctaAnimation} play />
        </div>}

        <div className="chat-bottom">
          <form onSubmit={handleSubmit}>
            {question === 'dob' ? <DateCalender
              setStartDate={setInputValue}
              startDate={inputValue}
              placeHolder={"Click Here"}
              minYear={timeLimit.minTime.getFullYear()}
              maxYear={timeLimit.maxTime.getFullYear()}
              timeLimit={timeLimit}
              format="1"
            /> : (question === 'current_work_experience' ? <div className='cwo-chatbot'><DateMonthOnly startDate={inputValue} setStartDate={setInputValue} /></div> : <input
              type={type}
              placeholder="Type your message here"
              value={inputValue}
              onChange={handleInputChange}
              ref={inputRef}
              onKeyPress={handleKeyPress}
              disabled={loader || (messages.length > 0 && messages[messages.length - 1]?.text?.data?.option.length && question !== "verify otp" && question !== "Re verify otp" && question !== "Resend OTP") || disbursed || question === "current_work_experience" || question === "Sorry"}
              maxLength={inputMaxLength}
              minLength={inputMinLength}
            />)}
            <button disabled={inputValue.length == 0} type="submit" className='chat-button--auto'>
              {img ? <img src={activeArrow} alt='' /> : <img src={sendarrow} alt='' />}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ChatPopUpNew;
