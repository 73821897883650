import React, { useEffect, useState } from 'react'
import "./NotFound.css"
import notfound from "./Error-Alien-Spaceship.webp";
import { useNavigate } from 'react-router-dom';
const NotFound = () => {
    const navigate=useNavigate();
    const [counter, setCounter] = useState(8)
    useEffect(() => {
      const timer =
        counter > 0 && setInterval(() => {
          setCounter(counter - 1); 
        }, 1000);
        setTimeout(()=>{
          navigate("/");
        },8000)
      return () => clearInterval(timer);
    }, [counter,navigate]);
  return (
     <>
     <div className='notfound-cont'>
      <h1 style={{color:"#011149"}}>Looks like you have landed on the wrong page!!</h1>
      <img className='notfound-img' src={notfound} alt=''/>
      <div> <button className='notfound-btn'><a href='/'>BACK TO HOMEPAGE</a></button></div>
      <p>Automatically Redirect after {counter} Seconds…</p>
     </div>
     </>
  )
}

export default NotFound