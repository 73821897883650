import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { storeEmi,updaterevisedoutstenurelt } from "../../Redux/action";

function PrePaymentCalculation() {
    const dispatch = useDispatch();
    const {pa, lt, roi,emiPaid,PartPayAmt, PartPayTenure,partpaymentcheckbox,PartpaymentTenure} = useSelector((state) => state);
    const [emi, setEmi] = React.useState(0);
    const [tpa, setTpa] = React.useState(0);
    const [R_emi, setRevEmi] = React.useState(0);
    const [R_emi_loan_tenure, setRevEmiLoanTenure] = React.useState(0);
    const [R_tenure,  setRevTen] = React.useState(0);
    const [R_tenure_loan_tenure,setRevTenLoanTenure] = React.useState(0);
    const [ti, setTi] = React.useState(0);

    const TotalPayableAmount = () => {
        let r = roi / (12 * 100);
        let Emi = (pa * r * Math.pow((1 + r), lt)) / (Math.pow((1 + r), lt) - 1);
        let t_p_a = Emi * lt;
        let t_i = t_p_a - pa;
        let int_per = Math.round((t_i * 100) / t_p_a);
        let rem_per = 100 - int_per;
        let revised_outs_tenure = lt-emiPaid;
        let revised_outs_tenure_loan_tenure = revised_outs_tenure- PartPayTenure;
        if(revised_outs_tenure_loan_tenure <= 1) {
            revised_outs_tenure_loan_tenure = 1
        }
        
        let outstanding_loan_amt = pa - PartPayAmt;        
        let part_payment_tenure = R_tenure - PartPayTenure;
        let Rev_Emi = (outstanding_loan_amt * r * Math.pow((1 + r), lt)) / (Math.pow((1 + r), lt) - 1);

       let Rev_Emi_loan_tenure= (outstanding_loan_amt * r * Math.pow((1 + r), revised_outs_tenure_loan_tenure)) / (Math.pow((1 + r), revised_outs_tenure_loan_tenure) - 1);
            
        setRevEmi(Math.round((Rev_Emi > 0)?(Rev_Emi):0));
        setRevEmiLoanTenure(Math.round((Rev_Emi_loan_tenure >= 0)?(Rev_Emi_loan_tenure):0).toLocaleString('hi-IN'));
        setRevTen(revised_outs_tenure);
        dispatch(updaterevisedoutstenurelt(revised_outs_tenure));
        setRevTenLoanTenure(revised_outs_tenure_loan_tenure);
        dispatch(storeEmi(Emi));
        setEmi(Math.round(Emi));
        setTpa(Math.round(t_p_a).toLocaleString('hi-IN'));
        setTi(Math.round(t_i).toLocaleString('hi-IN'));
    }

    React.useEffect(() => {
        TotalPayableAmount();

    }, [pa, lt, roi,emiPaid,PartPayAmt,PartPayTenure,partpaymentcheckbox,PartpaymentTenure])

    return (
        <div>
            <div className="calculation-main">
                <div className="calculation-first-half">
                    <div className="calculation-first">
                        <p>Original EMI</p>
                        <h1>₹ {emi?.toLocaleString('hi-IN')}</h1>
                    </div>
                    <div className="calculation-second">
                        <p>Original Outstanding Tenure</p>
                        <h1>{R_tenure} Months</h1>
                    </div>
                    <div className="calculation-first new-one">
                        <p>Loan Amount Paid</p>
                       {emi && emiPaid?(<h1>₹ {(emi*emiPaid).toLocaleString('hi-IN')} </h1>):(<h1>₹ 0 </h1>)}
                    </div>
                </div>
                
                <div className="calculation-first-half">
                    <div className="calculation-first">
                        <p>Revised emi</p>                     
                       { partpaymentcheckbox==1? (<h1>₹ {(PartPayAmt )?(R_emi).toLocaleString('hi-IN'):0} </h1>):(
                        <h1>₹ {( PartPayTenure)?(R_emi_loan_tenure):0} </h1>)}

                    </div>
                    <div className="calculation-second">
                        <p>Revised Outstanding Tenure </p>
                        {partpaymentcheckbox==1? (<h1> {(emiPaid )?R_tenure:0} Months</h1>):(
                        // <h1> {(PartPayTenure && R_tenure_loan_tenure>0)?R_tenure_loan_tenure:0} Months</h1>)}
                        <h1> {(PartPayTenure && R_tenure_loan_tenure>0)?R_tenure_loan_tenure:0} Months</h1>)}

                    </div>
                   {partpaymentcheckbox ===1 &&( <div className="calculation-second new-one">
                        <p>Savings in EMI</p>
                        { partpaymentcheckbox==1 && emi && R_emi? (<h1>₹ {(emi-R_emi).toLocaleString('hi-IN')} </h1>):(
                        <h1>₹ 0 </h1>)}
                </div>
                   )}
                </div>
            </div>
        </div>
    )
}

export default PrePaymentCalculation;