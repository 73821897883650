import React from "react";
import "./moneywidepartner.css";
import partner from "../../Icons/MoneywidePartner.svg";
import FormHead from "../Journey Form/Form/FormHead";
import { useForm } from "react-hook-form";
import familyPlus from "../../Icons/man.svg";
import email from "../../Icons/email.svg";
import phone from "../../Icons/phone.svg";
import building from "../../Icons/building.svg";
import right from '../../Icons/moneypartnertick.svg';
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken } from "../../Redux/action";
import NomineeThanku from "../Nominee/NomineeThank";
import { source,verification_source,verified_by,verifier_name } from "../Secret_Credentials/Secret_Credential";
import NomineeSorryExist from "../Nominee/NomineeSorryExist";
import { apiInstance } from "../Config/APIConfig";

const MoneyWidePartner = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      Name: "",
      email: "",
      mobile: "",
      company: "",
      comment: "",
    },
    mode: "all",
  });

  const data = watch();
  const [submit, setSubmit] = React.useState(false);
  const {token} = useSelector((state) => state);
  const dispatch = useDispatch();
  const [state,setState] = React.useState(true);
  const [sorry,setSorry] = React.useState(false)

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, []);

  const money=[
    {
        name:"100% Digital NBFC that offers Instant Personal Loans in less than 2 hours.",
        img:right
    },
    {
        name:"In-partnership with lenders like Muthoot Finance & HDB Financial Services.",
        img:right
    },
    {
        name:"Works with partners such as PaisaBazaar, Karza, Unicus, Banksathi, etc.",
        img:right
    },
    {
        name:"Registered as an Account Aggregator with Finbit, OneMoney & Sahamati.",
        img:right
    }
]

  const handleAlphaOnly = (value, field) => {
    var regexp = /[^a-zA-Z ]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""));
    } else {
      setValue(field, value.replace(/^\s+/, ''));
    }
  };

  const Blured = (field, value) => {
    // var newVal = value.trimEnd();
    var newVal = value.replace(/\s+$/, '');
    setValue(field, newVal);
    if (newVal.length < 3) {
      setError(field);
    } else {
      clearErrors([field]);
    }
  };

  const SpaceManage = (field, value) => {
    if (value) {
      setValue(field, value.replace(/^\s+/, ''));
    }
  };

  const handleUpKeyNumber = (value, field) => {
    var regexp = /[^0-9]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""));
    }
  };

  React.useEffect(() => {
    if (
      data.Name &&
      data.email &&
      data.company &&
      data.mobile &&
      data.comment
    ) {
      if (Object.keys(errors).length === 0) {
        setSubmit(true);
      } else {
        setSubmit(false);
      }
    } else {
      setSubmit(false);
    }
  }, [data]);

  const onSubmit = async () => {
    let payLoad = {
      source: source,
      "name":data.Name,
      "email":data.email,
      "mobile":data.mobile,
      "company":data.company,
      "desc":data.comment,
      verification_source: verification_source,
      verified_by: verified_by,
      verifier_name: verifier_name
      }
      // let payload = await encryptWithHybrid(JSON.stringify(payLoad));
    let dec = await apiInstance.post(`insert-partner-details`,
    payLoad,{
      token
  })
  // .then((res)=>{
          // let dec_data = decryptWithHybrid(res.data.encryptedMessage, res.data.encryptedAesKey, res.data.iv);
            if((dec.status === "1") && (dec.status_code === "200")){
              if(dec.data.partner_exists == 1){
                // console.log("hello");
                setSorry(true);
              }
              setState(false);
            }
        // })
  };

  // React.useEffect(()=>{
  //   console.log(sorry);
  // },[sorry])

  return (
    <>
      <div className="money-partner-bg">
        <img src={partner} alt=""/>
        <h1>Partner With MoneyWide</h1>
        <div className="money-partner-back"><p>Join hands with 100% digital fintech lending platform registered with RBI</p></div>
      </div>
    {state ? <div className="money-partner-form">
     <div className="right-side">
          <div className="form-box">
            <div className="form-body op-form-body">
              <FormHead formName={"Become Our Partner"} progressBar={true} />
              <div className="tag-for-input">
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                  <div
                    className={
                      errors.Name
                        ? "input-tag error-border remove-bottom"
                        : data.Name
                        ? "input-tag  add-border remove-bottom"
                        : "input-tag  remove-bottom"
                    }
                  >
                    <img className="tag-icon" src={familyPlus} alt=""/>
                    <input
                      type="text"
                      placeholder="Full Name"
                      {...register("Name", { required: true, minLength: 3 })}
                      autoComplete="off"
                      onKeyUp={() => handleAlphaOnly(data.Name, "Name")}
                      onBlur={() => Blured("Name", data.Name)}
                    />
                    {/* {errors.Name && <img src={ThisIsAnError} alt="" id="pointer-cur" />} */}
                  </div>
                  {errors.Name && (
                    <div className="err-text">Enter At least 3 Characters</div>
                  )}

                  {/* Email input */}
                  <div
                    className={
                      "input-tag remove-bottom" +
                      (errors.email
                        ? " error-border"
                        : data.email
                        ? " add-border"
                        : "")
                    }
                  >
                    <img className="tag-icon" src={email} alt=""/>
                    <input
                      className="input-tag-box"
                      type="text"
                      placeholder="Email Address"
                      maxLength={50}
                      {...register("email", {
                        required: true,
                        pattern: {
                          value:
                            /^([a-zA-Z0-9_.+-]{3,64})+\@(([a-zA-Z0-9-]{2,255})+\.)+([a-zA-Z0-9]{2,8})+$/,
                        },
                      })}
                      autoComplete="off"
                      onKeyUp={() => SpaceManage("email", data.email)}
                    />
                  </div>
                  {errors.email && data.email && (
                    <div className="err-text remove-bottom">
                      Invalid Email Address
                    </div>
                  )}
                  {errors.email && !data.email && (
                    <div className="err-text remove-bottom">
                      Enter Email Address
                    </div>
                  )}

                  <div
                    className={
                      errors.mobile
                        ? "input-tag error-border remove-bottom"
                        : data.mobile
                        ? "input-tag add-border remove-bottom"
                        : "input-tag  remove-bottom"
                    }
                  >
                    <img className="tag-icon" src={phone} alt=""/>
                    <input
                      type="tel"
                      placeholder="Contact Number"
                      minLength={10}
                      maxLength={10}
                      {...register("mobile", {
                        required: true,
                        minLength: 10,
                        maxLength: 10,
                        pattern: { value: /^[6-9]\d{9}$/gi },
                      })}
                      onKeyUp={() => handleUpKeyNumber(data.mobile, "mobile")}
                      autoComplete="off"
                    />
                    {/* {errors.mobile && <img src={ThisIsAnError} alt="" />} */}
                  </div>
                  {errors.mobile && (
                    <div className="err-text">Invalid Number</div>
                  )}

                  <div
                    className={
                      errors.company
                        ? "input-tag error-border remove-bottom"
                        : data.company
                        ? "input-tag  add-border remove-bottom"
                        : "input-tag  remove-bottom"
                    }
                  >
                    <img className="tag-icon" src={building} alt=""/>
                    <input
                      type="text"
                      placeholder="Company Name"
                      {...register("company", { required: true, minLength: 3 })}
                      autoComplete="off"
                      onKeyUp={() => handleAlphaOnly(data.company, "company")}
                      onBlur={() => Blured("company", data.company)}
                    />
                    {/* {errors.company && <img src={ThisIsAnError} alt="" id="pointer-cur" />} */}
                  </div>
                  {errors.company && (
                    <div className="err-text">Enter At least 5 Characters</div>
                  )}

                  <div
                    className={
                      "input-tag remove-bottom" +
                      (errors.comment
                        ? " error-border"
                        : data.comment
                        ? " add-border"
                        : "")
                    }
                  >
                    <textarea
                      className="input-tag-box"
                      type="text"
                      placeholder="Message for us ..."
                      {...register("comment", { required: true, minLength: 3 })}
                      autoComplete="off"
                    ></textarea>
                  </div>
                  {errors.comment && (
                    <div className="err-text">
                      Please Enter at least 3 Characters.
                    </div>
                  )}

                  <button
                    className={
                      "footer-btn Tmargin manage-nomine-bottom" +
                      (submit ? " footer-btn-activate" : "")
                    }
                  >
                    Continue
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> : 
      <div className="money-partner-form">
        <div className="right-side">
                        <div className="form-box">
                            <div className="form-body">
                              {sorry ? <NomineeSorryExist msg="We have already received your information. We will contact you shortly."/> : <NomineeThanku msg="for expressing your interest. We will contact you shortly."/> }                      
                         </div>
                    </div>
             </div>
      </div>
      }
      <div className="money-para">
            <p className="money-para-1">Why to Join <b>MoneyWide</b>?</p>
            {
                money.map((el,index)=>{
                    return(
                        <div key={index} className="money-map">
                            <img src={el.img} />
                            <p>{el.name}</p>
                        </div>
                    )
                })
            }
           <button><a href="/our-partner">Our Valued Partner</a></button>
           
      </div>
    </>
  );
};

export default MoneyWidePartner;
