import React from "react";
import './ApplicationStatus.css';
import greentick from '../../Icons/greentick.svg';
import yellowcircle from '../../Icons/yellowcircle.svg';
import graycircle from '../../Icons/graycircle.svg';
import FormHead from "../Journey Form/Form/FormHead";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GenerateToken } from "../../Redux/action";
import { ApplicationStatusAPI, getToken } from "../EverCalledFunctions/EverCalledApis";
import { useState } from "react";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
import { prodbase_url, react_base_url } from "../Secret_Credentials/Secret_Credential";
import { Check_utm_source, decryptLeadIdStepId, encryptLeadIdStepId, encryptOnlyLeadId } from "../EverCalledFunctions/EverCalledFunctions";
import { AxiosThirty } from "../AxiosConfiguration/AxiosConfig";
import { FetchCustomerPayload } from "../EverCalledFunctions/EverCalledPayload";
import { useSearchParams } from "react-router-dom";
import { apiInstance } from "../Config/APIConfig";

function ApplicationStatus() {
    const { mw_application_id, token, loginDetails } = useSelector(state => state)
    const [loader, setLoader] = useState(true)
    const [errCase, setErrCase] = useState(false)
    const [redirectURL, setRedirectURL] = useState("")
    const [thankuFlag, setThankuFlag] = useState(false)
    const [OfferData, setOfferData] = useState({
        lender: "",
        amount: 0,
        tenure: 0,
        emi: 0
    })
    const [active, setActive] = useState(-1)
    const [headContent, setHeadContent] = useState({
        BoxHead: "",
        BoxSubhead: "",
        DownHead: ""
    })
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams();
    const s = searchParams.get("s");
    const [StaticData, setStaticData] = useState([]);

    useEffect(() => {
        if (mw_application_id != "") {
            setLoader(true)
            window.scrollTo(0, 0);
        }
    }, [mw_application_id])

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }

    useEffect(() => {
        if (token == "") {
            myToken()
        }
    }, [])


    const LoadPreData = async () => {
        let details = await decryptLeadIdStepId();
        // console.log(details);
        if (loginDetails.dashBoardData.thank_you_flag == 1 || loginDetails.dashBoardData.thank_you_url != "") {
            setThankuFlag(true)
        }
         else if (loginDetails.dashBoardData.redirection_url && loginDetails.dashBoardData.redirection_url != "") {
            if (s) {
                let red_url = loginDetails.dashBoardData.redirection_url + "&s=" + s
                setRedirectURL(red_url);
            }
            else {
                setRedirectURL(loginDetails.dashBoardData.redirection_url)
            }

        }
        else if (+details[1] === 4) {
            let checkCondition = Check_utm_source()
            let qry = encryptLeadIdStepId(mw_application_id, 4);
            if (checkCondition && checkCondition.length != 0) {
                setRedirectURL(`${react_base_url}personal-loan/?fid=${qry}${checkCondition}`)               
            }
            else{
                setRedirectURL(`${react_base_url}personal-loan/?fid=${qry}`)
            }
        }
        else if (loginDetails.dashBoardData.is_account_ok == 0) {
            let checkCondition = Check_utm_source()
            let qry = encryptLeadIdStepId(mw_application_id, 22);
            if (checkCondition && checkCondition.length != 0) {
                setRedirectURL(`${react_base_url}personal-loan/?fid=${qry}${checkCondition}`)               
            }
            else{
                setRedirectURL(`${react_base_url}personal-loan/?fid=${qry}`)
            }
        }
         else {
            setThankuFlag(true)
        }
        setOfferData({
            ...OfferData,
            amount: loginDetails.dashBoardData.selected_loan_amount,
            tenure: loginDetails.dashBoardData.selected_tenure_months,
            emi: loginDetails.dashBoardData.monthly_emi,
            lender: loginDetails.dashBoardData.lenderName
        })
        setActive(loginDetails.dashBoardData.block_id)
        manageContent(loginDetails.dashBoardData)
    }
    useEffect(() => {
        if (mw_application_id != "" && token != "") {
            if (!loginDetails.dashBoardData) {
                getUserData()
            } else {
                LoadPreData()
            }
        }
    }, [mw_application_id, token])

    const manageContent = (data) => {
        setHeadContent({
            BoxHead: data.head,
            BoxSubhead: data.head_desc,
            DownHead: data.stage_head
        })
        setStaticData([...data.headlabels])
        setLoader(false)
    }
    const getUserData = async () => {

        let details = decryptLeadIdStepId();

        let decFetch = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(details[0], details[1]), {
            token
        })
        // .then((res) => {
            if (decFetch.status_code == "200" && decFetch.status == "1") {
                if (decFetch.data.thank_you_flag == 1 || decFetch.data.thank_you_url != "") {
                    setThankuFlag(true)
                } else if (decFetch.data.redirection_url && decFetch.data.redirection_url != "") {
                    setRedirectURL(decFetch.data.redirection_url)
                    if (s) {
                        let red_url = decFetch.data.redirection_url + "&s=" + s
                        setRedirectURL(red_url);
                    }
                    else {
                        setRedirectURL(decFetch.data.redirection_url);
                    }
                }
                else if (+details[1] === 4) {
                    let checkCondition = Check_utm_source()
                    let qry = encryptLeadIdStepId(mw_application_id, 4);
                    if (checkCondition && checkCondition.length != 0) {
                        setRedirectURL(`${react_base_url}personal-loan/?fid=${qry}${checkCondition}`)               
                    }
                    else{
                        setRedirectURL(`${react_base_url}personal-loan/?fid=${qry}`)
                    }
                }
                else if (decFetch.data.is_account_ok == 0) {
                    let checkCondition = Check_utm_source()
                    let qry = encryptLeadIdStepId(mw_application_id, 22);
                    if (checkCondition && checkCondition.length != 0) {
                        setRedirectURL(`${react_base_url}personal-loan/?fid=${qry}${checkCondition}`)               
                    }
                    else{
                        setRedirectURL(`${react_base_url}personal-loan/?fid=${qry}`)
                    }
                }
                else {
                    setThankuFlag(true);
                }
                setOfferData({
                    ...OfferData,
                    amount: decFetch.data.selected_loan_amount,
                    tenure: decFetch.data.selected_tenure_months,
                    emi: decFetch.data.monthly_emi,
                    lender: decFetch.data.lenderName
                })
                setActive(decFetch.data.block_id)
                manageContent(decFetch.data)
            } else {
                setErrCase(true)
                setLoader(false)
            }
        // }).catch((res) => {
        //     setErrCase(true)
        //     setLoader(false)
        // })



    }

    const NewErr = () => {
        setErrCase(false)
        getUserData()
    }



    return (
        <>
            {loader && <CKYCWaiting />}
            {!loader && errCase && <NewErrorCase handleClick={NewErr} />}
            {!loader && !errCase && <div style={{ paddingBottom: "20px" }}>
                <FormHead formName={"My Dashboard"} progressBar={true} />
                <div className="approved-box">
                    <div>
                        <div className="content">
                            <span>{headContent.BoxHead}</span>
                            <p>{headContent.BoxSubhead}{active == 2 ? ` Rs. ${OfferData?.amount?.toLocaleString('hi-IN')}` : ""}</p>
                        </div>
                    </div>
                </div>
                <div className="subheading-status">
                    <span className="heading-2 " id="dynamic-cont">{headContent.DownHead}</span>
                </div>

                {StaticData.length != 0 && StaticData.map((row, index) => {
                    return <div key={index} className={"content-boxes" + ((index + 1 > active) ? " show-disabled" : "")}>
                        <div className="box-head">
                            <img src={(index + 1) == active ? yellowcircle : ((index + 1) < active ? greentick : graycircle)} alt="na" />
                            <div>
                                <span>{row.stage_sub_head}</span>
                                <div className="box-content">{row.stage_sub_head_desc}</div>
                            </div>
                        </div>
                        {active == 2 && index == 1 && <div className="offer-data">
                            {OfferData.lender && <div>
                                <spna>Lender</spna>
                                <span>{OfferData.lender ? OfferData.lender : ""}</span>
                            </div>}
                            {OfferData.amount != 0 && <div>
                                <spna>Loan Amount</spna>
                                <span>₹ {OfferData.amount ? (OfferData.amount?.toLocaleString('hi-IN')) : "0"}/-</span>
                            </div>}
                            {OfferData.tenure != 0 && <div>
                                <spna>Loan Tenure</spna>
                                <span>{OfferData.tenure ? OfferData.tenure : "0"} Months</span>
                            </div>}
                            {OfferData.emi != 0 && <div>
                                <spna>Monthly EMI</spna>
                                <span>₹ {OfferData.emi ? (OfferData.emi?.toLocaleString('hi-IN')) : "0"}/-</span>
                            </div>}
                        </div>}
                        {((index + 1)) == active ?
                            <>
                                {thankuFlag ?
                                    <p className="dashboard-sorry-message">Your application is under review. Our Executive will contact you Shortly.</p> :
                                    (redirectURL ?
                                        <div className="con-btn">
                                            <a href={redirectURL}>
                                                <button id="lp-btn">Continue</button>
                                            </a>
                                        </div> :
                                        <p className="dashboard-sorry-message">Your application is under review. Our Executive will contact you Shortly.</p>)

                                }
                            </>
                            : ""}
                    </div>

                })}
            </div>}
        </>
    )
}

export default ApplicationStatus;