import React, { useState } from 'react'
import { react_base_url } from '../Secret_Credentials/Secret_Credential'


const NewFaq = ({ faqquestion }) => {
    const [FAQPage, setFAQPage] = useState(false);

    React.useEffect(() => {
        let CurrentUrl = window.location.href;
        if (CurrentUrl.includes('faqs')) {
            setFAQPage(true)
        }
    }, []);

    const apppendactive = (fa) => {
        let sendingQuestion = fa.map((row) => {
            return { ...row, isActive: false }
        })
        return [...sendingQuestion]
    }

    const [faqquestions, setFAQquestions] = useState(faqquestion ? apppendactive(faqquestion?.question_list) : []);
    const [open, setOpen] = useState(false);

    const ChangeActive = (index) => {
        var val = [...faqquestions];
        val.forEach((item, ind) => {
            if (ind !== index) {
                item.isActive = false;
            } else {
                item.isActive = !faqquestions[index].isActive;
            }
        })

        setFAQquestions(val);
    }

    return (
        <>
            {
                <div>
                    <div className="FAQ-container">
                        <div className="box-shape">
                            <h2 className="heading-h2">Frequently asked questions <b>(FAQ’s)</b></h2>
                            <div className={(!FAQPage && faqquestions.length > 5) ? ('manage-div-limit' + (open ? " open-div" : "")) : ""}>
                                {faqquestions && faqquestions.map((row, index) => {
                                    return <div key={index}><div className={'question-box ' + (row.isActive ? "active" : "notactive")} >
                                        <div className="question" onClick={() => ChangeActive(index)}>
                                            <div className="question-left">{row.title}</div>
                                            <div>{row.isActive ? <img className='faq-arr' src={`${react_base_url}assets/images/headerlogo/BlueArr.svg`} alt="na" /> : <img src={`${react_base_url}assets/images/headerlogo/downarrow.svg`} alt="" />}</div>
                                        </div>
                                        {row.isActive && <div className="FAQ-Question-Content"><span className='heading-p'>{row.desc}</span></div>}
                                    </div>
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>
                </div>

            }

        </>
    )
}

export default NewFaq