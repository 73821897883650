import React from 'react'
import './ovdpopup.css'
import '../../Loader/Loader.css';
import {useDispatch,useSelector} from 'react-redux'
import { updatePopUp } from '../../../Redux/action';

function OVDPopUp() {
    const dispatch = useDispatch();
    const {PopUpState} = useSelector(state=>state)


    const returnThisValue =(value)=>{
        const updatedOVDPopUp = {...PopUpState.OVDPopUp,show:false,value:value}
        dispatch(updatePopUp({...PopUpState,OVDPopUp:updatedOVDPopUp}))
    }
    return (
        <div className='loaderbackground'>
            <div className='loadertransparent'>
                <div className='ovdpopup'>
                    <h2>Select OVD Proof</h2>
                    <div className='ovdpopup-content'>
                        <ul>
                            <li onClick={()=>returnThisValue("Passport")}>Passport</li>
                            <li onClick={()=>returnThisValue("Driving License")}>Driving License</li>
                            <li onClick={()=>returnThisValue("Voter ID")}>Voter ID</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    
    )
}

export default OVDPopUp;