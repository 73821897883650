import React, { useEffect, useRef, useState } from 'react';
import './LoginPopUp.css';
import { useForm } from 'react-hook-form';
// import reload from '../../Icons/reload.svg';
// import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, SendOTP, VerifyOTP } from '../EverCalledFunctions/EverCalledApis';
import { GenerateToken } from '../../Redux/action';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../assests/lootie-files/continue.json';
import { useNavigate } from 'react-router-dom';
import { SaveLoginData, getLoginData } from '../MyAccount/MyAccountAPIs';
import queryString from 'query-string'
import Base64 from "base-64";
import refersh from "../../Icons/refresh-chat.svg"
import { handleKeyPress } from '../EverCalledFunctions/EverCalledFunctions';

const LoginOTPScreen = ({ setCurrentPage, state, setState }) => {
    // const [captchaText, setCaptchaText] = useState("")
    const canvasRef = useRef(null);
    // const [invalidCap, setInvalidCap] = useState(false)
    const [captchaText, setInputValue] = useState("");
    const [captchaCode, setcaptchaCode] = useState("")
    const [firstSubmit, setFirstSubmit] = useState(false);
    const [match, setmatch] = useState(false)
    const [resp, setResp] = useState(false)
    const { token } = useSelector(state => state)
    const [device_id, setDevice_id] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [timer, setTimer] = useState(30)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
        setValue
    } = useForm({
        defaultValues: {
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: ""
        },
    });
    const data = watch();



    function knowdevice() {
        if (window.innerWidth > 1024) {
            setDevice_id("1");
        }
        else if (window.innerWidth > 480 && window.innerWidth < 1024) {
            setDevice_id("3")
        }
        else {
            setDevice_id("2");
        }
    }

    const myToken = async () => {
        const T = await getToken();
        dispatch(GenerateToken(T))
    }

    function generateRandomCode() {
        const length = 6;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let code = '';

        for (let i = 0; i < length; i++) {
            code += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setcaptchaCode(code)
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.font = "45px Arial";
        context.textBaseline = "middle";
        context.textAlign = "center";
        context.fillText(code, canvas.width / 2, canvas.height / 2);
    }

    useEffect(() => {
        if (token === "") {
            myToken()
        }
        generateRandomCode()
        knowdevice()
    }, [])

    const onSubmit = async (result) => {
        var URLSTRING = queryString.parse(window.location.search)
        if (captchaText === captchaCode) {
            // if (validateCaptcha(captchaText, false) === true) {
                setResp(true)
                setmatch(false)
                const payLoad = {
                    "otpId": state.otpId,
                    "otp": result.otp1 + result.otp2 + result.otp3 + result.otp4,
                    "mobile": state.userNo,
                    "whatsapp_consent": state.whatsappToggel ? 1 : 0,
                    "login_from": 3,
                    "deviceId": "",
                    "device_type": +(device_id)
                }
                const APIResponse = await VerifyOTP(payLoad, token)
                if (APIResponse.status_code === "200" && APIResponse.status === "1") {
                    sessionStorage.setItem("isLogin", 1);
                    sessionStorage.setItem("existed", APIResponse?.data?.data_exists_flag == 1 ? 1 : 0)
                    if (window.location.href.includes('utm_source=')) {
                        // let payLoad = getLoginData()
                        try {
                            let qry = window.location.search.split('utm_source=');
                            qry = Base64.decode(qry[1])
                            navigate(`/myaccount/loan-foreclosure-details/?req_type=${qry}`, { replace: true })
                        } catch {
                            let payload = {
                                "userNo": state.userNo,
                                "cust_id": APIResponse.data.cust_id
                            }
                            SaveLoginData(payload)
                            navigate(`/myaccount/profile`, { replace: true })
                        }
                    } else {
                        let payload = {
                            "userNo": state.userNo,
                            "cust_id": APIResponse.data.cust_id
                        }
                        SaveLoginData(payload)
                        if (window.location.href.includes('medium=') && APIResponse?.data?.data_exists_flag == 1) {
                            let qry = window.location.search.split('medium=');
                            if (qry[1] && qry[1] == "TVE9PQ==") {
                                navigate(`/myaccount/deletion`, { replace: true })
                            } else {
                                navigate(`/myaccount/profile`, { replace: true })
                            }
                        } else {
                            navigate(`/myaccount/profile`, { replace: true })
                        }
                    }
                } else if (APIResponse.status_code === "429" && APIResponse.status === "0") {
                    setFirstSubmit(true)
                    setResp(false)
                }
            // }
            // else {
            //     setInvalidCap(true)
            //     setCaptchaText("")
            // }
        }
        else{
            setmatch(true)
        }
    }

    // const handleKeyPress = (event) => {
    //     if (!/[0-9]/.test(event.key)) {
    //         event.preventDefault();
    //     }
    // }
    const handleClick = (e) => {
        if (firstSubmit) {
            reset()
            setFirstSubmit(false)
            document.getElementById("first-tag").focus()
        }
    }

    function autotab(original, destination, e) {
        if (`${e.target.value.length}` === e.target.getAttribute("maxlength"))
            destination.focus();
    }
    const handleChange = (nextLocation, prevLocation, event, value) => {
        setValue(value, event.target.value);
        if (event.target.value.length > 0) {
            document.getElementById(nextLocation).focus();
        }
    }

    const handleKeyDown = (event, prevLocation) => {
        const key = event.keyCode || event.charCode;
        if (key == 8 || key == 46) {
            if (event.target.value.length === 0) {
                document.getElementById(prevLocation).focus();
            }
        }
    }

    const handleCha = () => {
        //setChanged(false)
        setState(state => ({
            ...state, 
            changed: false 
          }));
        setCurrentPage(1)
    }

    useEffect(() => {
        if (timer !== 0) {
            setTimeout(() => {
                setTimer(timer - 1);
            }, 1000)
        }
    }, [timer])

    const ResendOTPOption = async () => {
        setTimer(-1)
        const payLoad = {
            "mobile": state.userNo,
            "api_mode": "LIVE",
            "login_from": 3
        }
        const APIResponse = await SendOTP(payLoad, token);
    }

    return (
        <>
            <div className="popup-form-head">
                <span>Enter OTP</span>
            </div>
            <div className="change-opt">
                Please type the verification code sent on<br /> +91-{state.userNo} {state.changed && <span onClick={() => handleCha()}>(Change)</span>}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                <div className="enter-otp ">
                    <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                        <input maxLength={1} id="first-tag" type="tel" {...register('otp1', { required: true })} onKeyPress={(event) => handleKeyPress(event)} onChange={(event) => handleChange("second-tag", "first-tag", event, "otp1")} onKeyDown={(event) => handleKeyDown(event, "first-tag")} onClick={(e) => handleClick(e)} autoFocus />
                    </div>
                    <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                        <input maxLength={1} id="second-tag" type="tel" {...register('otp2', { required: true })} onKeyPress={(event) => handleKeyPress(event)} onChange={(event) => handleChange("third-tag", "first-tag", event, "otp2")} onKeyDown={(event) => handleKeyDown(event, "first-tag")} onClick={(e) => handleClick(e)} />
                    </div>
                    <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                        <input maxLength={1} id="third-tag" type="tel" {...register('otp3', { required: true })} onKeyPress={(event) => handleKeyPress(event)} onChange={(event) => handleChange("forth-tag", "second-tag", event, "otp3")} onKeyDown={(event) => handleKeyDown(event, "second-tag")} onClick={(e) => handleClick(e)} />
                    </div>
                    <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                        <input maxLength={1} id="forth-tag" type="tel" {...register('otp4', { required: true })} onKeyPress={(event) => handleKeyPress(event)} onChange={(event) => handleChange("forth-tag", "third-tag", event, "otp4")} onKeyDown={(event) => handleKeyDown(event, "third-tag")} onClick={(e) => handleClick(e)} />
                    </div>
                </div>
                {timer >= 0 && !firstSubmit && <div className="resend-opt" >
                    Resend OTP : {timer === 0 ? <span onClick={() => ResendOTPOption()}>RESEND OTP</span> : <span>{timer} Seconds</span>}
                </div>}
                {firstSubmit && <div className="popUp-invalid">Invalid OTP</div>}

                {/* <div className="capcha-section">
                    <div className="capcha-box">
                        <span>Capcha Code </span>
                        <div className="capcha-code-box">
                            <LoadCanvasTemplate reloadText={'&#x21bb;'} />
                        </div>
                    </div>
                    <div className="capcha-box">
                        <span>Enter Capcha</span>
                        <div className="capcha-enter-box">
                            <input maxLength={6} value={captchaText} onChange={(e) => setCaptchaText(e.target.value)} type="text" />
                        </div>{invalidCap && captchaText.length === 0 && <div className="login-area-login-err" style={{ bottom: "-12px" }} >Invalid Capcha</div>}
                    </div>

                </div> */}


                <div className="capcha-section-new">
                    <div className="capcha-box">
                        <div className="capcha-new">
                            <span>Capcha Code </span>
                            <img src={refersh} alt='refresh-captcha' onClick={generateRandomCode} />
                        </div>
                        <canvas className="canvas-new" ref={canvasRef} />
                    </div>
                    <div className="capcha-box">
                        <span>Enter Capcha</span>
                        <div className="capcha-enter-box">
                            <input maxLength={6} value={captchaText}
                                onChange={(e) => setInputValue(e.target.value)}
                                type="text" />
                        </div>
                        {match && <div className="login-area-login-err" style={{ bottom: "-18px" }} >Invalid Capcha</div>}
                    </div>

                </div>

                <div disabled={!(data.otp1 && data.otp2 && data.otp3 && data.otp4 && captchaText.length === 6)} className={(data.otp1 && data.otp2 && data.otp3 && data.otp4 && captchaText.length === 6 && !firstSubmit) ? "popUp-btn active-btn" : "popUp-btn "}>
                    <button id="last" disabled={resp} type="submit">
                        {!resp ?
                            "Submit" :
                            <div className="continue-lottie">
                                <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                            </div>}
                    </button>
                </div>
            </form>
        </>
    )
}

export default LoginOTPScreen;