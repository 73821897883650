import React, { useState, useEffect } from "react";
import "../Form.css";
import EmailVerificationImg from "../../../../Icons/EmailVerification.svg";
import email from "../../../../Icons/email.svg";
import { useForm } from "react-hook-form";
import {
  prodbase_url,
  base_url,
  client_id,
  client_key,
  source,
  verification_source,
  verified_by,
  verifier_name,
  react_base_url,
} from "../../../Secret_Credentials/Secret_Credential";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentStep,
  updateStepBar,
  updateFormState,
  GenerateToken,
} from "../../../../Redux/action";
import Lottie from "react-lottie-player";
import ctaAnimation from "../../../assests/lootie-files/continue.json";
import {
  form_journey_action,
  updateCredentialsState,
  change_personal_loan_page,
  updateLoginDetails,
} from "../../../../Redux/action";
import { useNavigate } from "react-router-dom";
import CKYCWaiting from "../../../LoginPopUp/CKYCWaiting";
import { encryptAGRID, gettingerror, handleKeyPress } from "../../../EverCalledFunctions/EverCalledFunctions";
import { getToken } from "../../../EverCalledFunctions/EverCalledApis";
import Base64 from "base-64";
import { AxiosThirty } from "../../../AxiosConfiguration/AxiosConfig";
import NewErrorCase from "./NewErrorCase";
import { FetchCustomerPayload } from "../../../EverCalledFunctions/EverCalledPayload";
import { apiInstance } from "../../../Config/APIConfig";

const EmailVerification = () => {
  const [resp, setResp] = useState(false);
  const [state, setState] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [optionsSelect, setOptionSelect] = useState({
    edit: false,
    send: false
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    },
  });
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [mail, setMail] = useState("");
  const [timer, setTimer] = useState(30);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [hide, setHide] = useState(true);
  const [rocket, setRocket] = useState(false);
  const navigate = useNavigate();
  const {
    step_bar,
    mw_application_id,
    credentialState,
    loginDetails,
    formStates,
    personal_loan,
    token
  } = useSelector((state) => state);

  const data = watch();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, [])

  const hitFetchCustomer = async () => {
    let qryStr = encryptAGRID(mw_application_id);
    let decFetch = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 23), {
      token
    })
    // .then((res) => {
      if (decFetch.status_code === "200" && decFetch.status === "1") {
        dispatch(updateCredentialsState({ ...credentialState, cust_id: decFetch.data.customer_id }));
        if (decFetch.data.thank_you_flag == 1) {
          gettingerror("get thank you flag", mw_application_id)
          if (decFetch.data.thank_you_url !== "") {
            window.location.href = decFetch.data.thank_you_url;
            return
          }
          else {
            let qry = Base64.encode(`${mw_application_id}`);
            qry = Base64.encode(qry);
            window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18`;
            return
          }

        }
        if (decFetch.data.redirection_url && (decFetch.data.redirection_url !== window.location.href)) {
          window.location.href = decFetch.data.redirection_url
        }
        if (decFetch.data.email_id) {
          setMail(decFetch.data.email_id)
        }
        else {
          setOptionSelect({ ...optionsSelect, edit: true })
        }
      } else if (decFetch.status_code === "429" && decFetch.status === "0") {
        dispatch(updateFormState({ ...formStates, error: true }))
      } else {
        dispatch(updateFormState({ ...formStates, error: true }))
      }
    // }).catch((err) => {
    //   setRocket(true);
    // })
  }

  useEffect(() => {
    if (token !== "") {
      hitFetchCustomer();

    }
  }, [token]);



  const Verify = async () => {
    setResp(true);
    const otp = data.otp1 + data.otp2 + data.otp3 + data.otp4;
    let decEmailVerify = await apiInstance
      .post(
        `email-otp-verify`,
        {
          "customer_id": +credentialState.cust_id,
          "level_id": 1,
          "lead_id": +mw_application_id,
          "verified_by": verified_by,
          "email_type": 1,
          "verifier_name": verifier_name,
          "verification_source": verification_source,
          "otp": +otp,
          "source": "MWide",
        },
        {
          token:""
        }
      )
      // .then((res) => {
        if (decEmailVerify.status_code === "200" && decEmailVerify.status === '1') {
          gettingerror('email verified web', mw_application_id)
          for (var key in step_bar) {
            if (step_bar[key] === false) {
              dispatch(updateStepBar((step_bar[key] = true)));
              break;
            }
          }
          dispatch(
            updateFormState({
              ...formStates,
              StartJourney: true,
              showThank: true,
            })
          );
        } else if (decEmailVerify.status_code === "429" && decEmailVerify.status === "0") {
          setResp(false);
          setFirstSubmit(true);
        } else {
          setResp(false);
          dispatch(updateFormState({ ...formStates, error: true }))
        }
      // }).catch((err) => {
      //   setRocket(true);
      // });
  };

  useEffect(() => {
    if (data.otp4) {
      document.getElementById("forth-tag").blur();
    }
    if (data.otp1 && data.otp2 && data.otp3 && data.otp4) {
      Verify();
    }
  }, [data.otp1, data.otp2, data.otp3, data.otp4]);

  const onSubmit = () => {

  };

  const handleChange = (nextLocation, prevLocation, event, value) => {
    setValue(value, event.target.value);
    if (event.target.value.length > 0) {
      document.getElementById(nextLocation).focus();
    }
  };

  const handleKeyDown = (event, prevLocation) => {
    const key = event.keyCode || event.charCode;
    if (key == 8 || key == 46) {
      if (event.target.value.length === 0) {
        document.getElementById(prevLocation).focus();
      }
    }
  };

  // const handleKeyPress = (event) => {
  //   if (!/[0-9]/.test(event.key)) {
  //     event.preventDefault();
  //   }
  // };

  const handleClick = (e) => {
    if (firstSubmit) {
      setResp(false);
      reset()
      setFirstSubmit(false);
      document.getElementById("first-tag").focus();
    }
  };

  const sendOTP = async () => {
    setHide(false);
    const resendotp = {
      "customer_id": credentialState.cust_id,
      "lead_id": mw_application_id,
      "level_id": 1,
      "source": "MWide",
      "email_type": 1,
      "verified_by": verified_by,
      "verifier_name": verifier_name,
      "verification_source": verification_source,
    };
    let emailOtpSend = await apiInstance
      .post(`email-otp-send`, resendotp, {
        token:""
      })
      // .then((response) => {
        if (emailOtpSend.status_code === "200" && emailOtpSend.status === "1") {
          gettingerror('email otp send web', mw_application_id)
          setShow(true)
        } else {
          dispatch(updateFormState({ ...formStates, error: true }))
        }
      // }).catch((err) => {
      //   setRocket(true);
      // });
    reset();
    setFirstSubmit(false);
    var tag = document.getElementById("first-tag");
    if (tag) {
      tag.focus();
    }
  };


  const updateEmail = async () => {

    let emailUpdate = await apiInstance.post(`email-update`, {
      "customer_id": credentialState.cust_id,
      "lead_id": mw_application_id,
      "level_id": 1,
      "email": mail,
      "source": "MWide",
      "email_type": 1,
      "verified_by": verified_by,
      "verifier_name": verifier_name,
      "verification_source": verification_source,
    }, {
      token
    })
    // .then((res) => {
      if (emailUpdate.status_code === "200" && emailUpdate.status === "1") {
        setShow(true)
        sendOTP();
        gettingerror('update email web', mw_application_id)
      } else {
        dispatch(updateFormState({ ...formStates, error: true }))
      }
    // }).catch((err) => {
    //   setRocket(true);
    // });
  }
  const handleAPICALL = () => {
    if (optionsSelect.edit) {
      updateEmail();
    } else {
      sendOTP();
    }
  }

  useEffect(() => {
    timer > 0 &&
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
  }, [timer]);



  const handleEdit = () => {
    if (!show) {
      setOptionSelect({ ...optionsSelect, edit: true, send: true })
      setMail("")
      document.getElementById('email-box').focus();
    }
  }

  const getOTP = () => {
    handleAPICALL()
  }

  const handleEmailEditPress = (e) => {
    setMail(e.target.value)
  }

  useEffect(() => {
    if (mail) {
      var regex = /^([a-zA-Z0-9_.+-]{3,64})+\@(([a-zA-Z0-9-]{2,255})+\.)+([a-zA-Z0-9]{2,8})+$/
      if (regex.test(mail)) {
        setCanSend(true)
      } else {
        setCanSend(false)
      }
    }
  }, [mail])

  const handleError = (e) => {
    window.location.reload();
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      {state ? <CKYCWaiting heading={"We are Redirecting"} /> : <div>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          <div className="email-verification">
            <div className="email-verification-img">
              <img src={EmailVerificationImg} alt="" />
            </div>
            <div className="email-verification-content">
              <span>Verify your Email</span>

              <p>To proceed, enter OTP sent to <br />
                {!show && <span className={"edit-email" + (optionsSelect.edit || show ? " email-disable" : "")} disabled={optionsSelect.edit} onClick={() => handleEdit()}> (change email)</span>}
              </p>
            </div>

            {!show && <div className="email-input-box">
              <div className="input-tag remove-bottom add-border">
                <img src={email} alt="" />
                <input type="text" value={mail} id="email-box" onChange={(e) => handleEmailEditPress(e)} readOnly={!optionsSelect.edit || show} />

              </div>

            </div>}


            {!show && <button disabled={(!canSend && optionsSelect.edit) || !mail} className={"footer-btn Bmargin Tmargin" + ((optionsSelect.edit && canSend && mail) ? " footer-btn-activate" : !optionsSelect.edit ? " footer-btn-activate" : "")} onClick={() => getOTP()}>
              {optionsSelect.edit ? "Update Email" : "Get OTP"}
            </button>}

            {show && (
              <>
                <div className="enter-otp ">
                  <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                    <input maxLength={1}
                      disabled={resp}
                      id="first-tag"
                      type="tel"
                      {...register("otp1", { required: true })}
                      onKeyPress={(event) => handleKeyPress(event)}
                      onChange={(event) =>
                        handleChange("second-tag", "first-tag", event, "otp1")
                      }
                      onKeyDown={(event) => handleKeyDown(event, "first-tag")}
                      onClick={(e) => handleClick(e)}
                      autoFocus
                    />
                  </div>
                  <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                    <input maxLength={1}
                      disabled={resp}
                      id="second-tag"
                      type="tel"
                      {...register("otp2", { required: true })}
                      onKeyPress={(event) => handleKeyPress(event)}
                      onChange={(event) =>
                        handleChange("third-tag", "first-tag", event, "otp2")
                      }
                      onKeyDown={(event) => handleKeyDown(event, "first-tag")}
                      onClick={(e) => handleClick(e)}
                    />
                  </div>
                  <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                    <input maxLength={1}
                      disabled={resp}
                      id="third-tag"
                      type="tel"
                      {...register("otp3", { required: true })}
                      onKeyPress={(event) => handleKeyPress(event)}
                      onChange={(event) =>
                        handleChange("forth-tag", "second-tag", event, "otp3")
                      }
                      onKeyDown={(event) => handleKeyDown(event, "second-tag")}
                      onClick={(e) => handleClick(e)}
                    />
                  </div>
                  <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                    <input maxLength={1}
                      disabled={resp}
                      id="forth-tag"
                      type="tel"
                      {...register("otp4", { required: true })}
                      onKeyPress={(event) => handleKeyPress(event)}
                      onChange={(event) =>
                        handleChange("forth-tag", "third-tag", event, "otp4")
                      }
                      onKeyDown={(event) => handleKeyDown(event, "third-tag")}
                      onClick={(e) => handleClick(e)}
                    />
                  </div>
                </div>

                {firstSubmit ? <div className="otp-timer-slot err">
                  <span >Invalid OTP </span>
                </div> : " "}

                <div>
                  <button
                    id="last"
                    className={
                      "footer-btn Bmargin " +
                      (data.otp1 && data.otp2 && data.otp3 && data.otp4 && !firstSubmit
                        ? " footer-btn-activate"
                        : "")
                    }
                  >
                    {!resp ? (
                      "Verify"
                    ) : (
                      <div className="continue-lottie">
                        <Lottie
                          loop
                          animationData={ctaAnimation}
                          play
                          className="ctn-loader"
                        />
                      </div>
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </form>
      </div>}
    </>

  );
};

export default EmailVerification;
