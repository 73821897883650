import React from 'react'
// import Lottie from "react-lottie-player";
// import lottieJson from "../../Components/assests/lootie-files/successful.json";
import pendingIcon from '../../Icons/pendingIcon.svg'
import closeOption from '../../Icons/closeOption.svg'
import './LoanDetails.css'


const ForCloseProceedPopUp = ({ userName, baseData, loanName }) => {
    return (
        <div className="blur-background">
            <div className="pop-content">
                <div className="main-popUP-form manage-myaccount-popup">
                <div className="myc-back-btn foreclosure-back">
                    <a href="/myaccount/profile"><img src={closeOption} alt="" /></a>
                </div>
                    {/* <Lottie
                        loop
                        animationData={lottieJson}
                        play 
                    /> */}
                    <img src={pendingIcon} alt="NA" />
                    <div className='proceed-popup-content'>
                        <span>
                            Dear {userName}
                        </span>
                       {loanName == "loan" ? <p>
                            Your Personal Loan <b>{baseData.Id}</b> Foreclosure request is in process. The transaction will reflect in your loan account once the payment is successfully received
                        </p>:
                        <p>
                            Your HealthCare Facility <b>{baseData.Id}</b> Foreclosure request is in process. The transaction will reflect in your account once the payment is successfully received
                        </p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForCloseProceedPopUp