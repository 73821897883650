import React from "react";
import Lottie from "react-lottie-player";
import wrong from "../assests/lootie-files/ErrorWrongOtp.json"
const WrongOtp = () => {
  return (
    <>
      <div style={{ padding: "20px 0" }}>
      <div className="lotie-div">
                <Lottie loop animationData={wrong} play className="sry-popup-lottie" />
            </div>
        <div className="limit-over err-text">
          You have exhausted daily OTP limit. <br />
          Please try after 24 hours.
        </div>
      </div>
    </>
  );
};

export default WrongOtp;
