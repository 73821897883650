import React from 'react';
import './CustomerReview.css';
import queryString from "query-string";
import Base64 from "base-64";
import NotFound from '../NotFound/NotFound';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import LeftSide from '../../Icons/review-feedback-img.svg'
import NomineSorry from '../Nominee/NomineSorry';
import NomineeThanku from '../Nominee/NomineeThank';
import CustomerRevForm from './CustomerRevForm';
import NomineeSorryExist from '../Nominee/NomineeSorryExist';

const CustomerReviewForm = () => {
    const [status, setStatus] = React.useState()
    const [loading, setLoading] = React.useState(true)
    const [redirect, setRedirect] = React.useState(false)
    const [leadId, setLeadId] = React.useState("")
    const updateStatus = (data) => {
        setStatus(data)
    }

    React.useEffect(() => {
        setLoading(true)
        const qry = queryString.parse(window.location.search);
        try {
            let decodeData = Base64.decode(qry.fid);
            decodeData = Base64.decode(decodeData)
            // console.log(decodeData);
            if (!decodeData) {
                setRedirect(true)
            } else {
                setRedirect(false)
                setLeadId(decodeData)
                setStatus(1)
            }
        } catch {
            setRedirect(true)
        }
        setLoading(false)
    }, [])


  return (
    <>
    {redirect && <NotFound />}
            {loading && <CKYCWaiting />}
            {!redirect && <div className={"journey-part-mobile nominee"}>
                <div className="journey-content">
                    <div className="left-side">
                        <h2 className="journey-heading">Customer Review</h2>
                        <p className="journey-con">We would love to hear your feedback. This will help us to do better.</p>
                        <div><img src={LeftSide} alt="" /></div>
                    </div>
                    <div className="right-side">
                        <div className="form-box">
                            <div className="form-body">
                                {status !== 1 && status !== 2 && status !== 4 && <NomineSorry />}
                                {status === 1 && <CustomerRevForm updateStatus={updateStatus} leadId={leadId}/>}
                                {status === 2 && <NomineeThanku msg="Thanks for sharing your valuable feedback."/>}
                                {status === 4 && <NomineeSorryExist msg="Thankyou for your valuable reviews.We have already received your details."/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
    </>
  )
}

export default CustomerReviewForm