import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./UrbanMoneyOfferOld.css";
import Lottie from "react-lottie-player";
import ctaAnimation from "../assests/lootie-files/continue.json";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import FormHead from "../Journey Form/Form/FormHead";
import { offerSubmissionApi } from "../EverCalledFunctions/EverCalledApis";
import { RelocateUTMCheck, encryptLeadIdStepId, encryptOnlyLeadId } from "../EverCalledFunctions/EverCalledFunctions";
import { react_base_url } from "../Secret_Credentials/Secret_Credential";

function UrbanMoneyOfferOld() {
    const [resp, setResp] = useState(false);
    const [checkBox, setCheckBox] = useState(true);
    const [addClassName, setAddClassName] = useState(false);
    const [progName, setProgName] = useState("");
    const [radioData, setRadioData] = useState("");
    const {
        pre_approved_offer,
        PopUpState,
        preApprovedOfferDetails,
        choosen_amount,
        mw_application_id,
        credentialState,
        formStates,
        resumeJourney,
        decisionDemog,
        step_bar,
        token,
        Product_Id,
        partnerStates
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [tenureData, setTenureData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [amount, setAmount] = useState("");
    const [rocket, setRocket] = useState(false);
    const [state, setState] = useState(true);
    const [appRoi, setAppRoi] = useState("");

    useEffect(() => {
        if ((amount == "") && (formData.length != 0)) {
            setAmount(formData?.max_loan_amt?.toLocaleString("en-IN"))
            let prog = `prog_${formData.program_id}`;
            setProgName(prog);
            setState(false);
        }
    }, [formData])

    useEffect(() => {
        if(partnerStates.PreApp) {
            // console.log(partnerStates.PreApp);
            setFormData(partnerStates.PreApp);
        }
    }, [partnerStates])

    const EmiCalculation = () => {
        let emiArray = [];
        for (let i = 0; i < formData[progName].length; i++) {
            let obj = formData[progName][i];
            let monthly_roi = formData[progName][i].applicable_roi / (12 * 100);
            let value = +(amount.replaceAll(",", ""));
            let emi = (value * monthly_roi * Math.pow(1 + monthly_roi, formData[progName][i].tenure)) / (Math.pow(1 + monthly_roi, formData[progName][i].tenure) - 1);
            obj["emi"] = Math.round(emi).toLocaleString("en-IN");
            emiArray.push(obj);
        }
        setTenureData(emiArray);
    }

    const NewEmiCalculation = () => {
        let emiArray = [];
        for (let i = 0; i < formData[progName].length; i++) {
            let obj = formData[progName][i];
            let value = +(amount.replaceAll(",", ""));
            let monthly_roi = "";
            if (obj.amuntbasedtenroi.length > 0) {
                for (let j = 0; j < obj.amuntbasedtenroi.length; j++) {
                    if (((value >= +obj.amuntbasedtenroi[j].min_loan_amount) && (value <= +obj.amuntbasedtenroi[j].max_loan_amount))) {
                        monthly_roi = +obj.amuntbasedtenroi[j].approi / (12 * 100);
                    }
                    if (((value >= +obj.amuntbasedtenroi[j].min_loan_amount) && (value <= +obj.amuntbasedtenroi[j].max_loan_amount)) && (radioData.tenure == obj.tenure)) {
                        monthly_roi = +obj.amuntbasedtenroi[j].approi / (12 * 100);
                        setAppRoi((+obj.amuntbasedtenroi[j].approi) / 12);
                    }
                }
            }
            else {
                monthly_roi = +obj.applicable_roi / (12 * 100);
            }
            let emi = (value * monthly_roi * Math.pow(1 + monthly_roi, formData[progName][i].tenure)) / (Math.pow(1 + monthly_roi, formData[progName][i].tenure) - 1);
            obj["emi"] = Math.round(emi).toLocaleString("en-IN");
            obj["applicable_roi"] = monthly_roi * 100 * 12;
            emiArray.push(obj);

        }
        setTenureData(emiArray);
    }

    useEffect(() => {
        if (amount && progName) {
            // if(decisionDemog.offer_journey_change_flag == 1) {
            //     NewEmiCalculation()
            // }
            // else {
            //     EmiCalculation();
            // }
            NewEmiCalculation()
        }
    }, [amount, progName])

    const handleErrorCase = () => {
        setRocket(false);
    }

    const uncheckRadio = () => {
        setRadioData("");
        const radio_btns = document.querySelectorAll(".radio-btn");
        for (let i = 0; i < radio_btns.length; i++) {
            if (radio_btns[i].checked) {
                radio_btns[i].checked = false;
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "input-box") {
            if (!value) {
                setAmount("")
            }
            else {
                uncheckRadio();
                const shown_inpt = (+(value.replaceAll(",", ""))).toLocaleString("en-IN")
                setAmount(shown_inpt)
            }
        }
        else if (name === "input-range") {
            setAmount((+value).toLocaleString("en-IN"))
        }
    }

    const handleBlur = (e) => {
        const { value } = e.target;
        if (((+(value.replaceAll(",", ""))) < formData.min_loan_amt) || ((+(value.replaceAll(",", ""))) > formData.max_loan_amt)) {
            setAmount(formData.max_loan_amt.toLocaleString("en-IN"))
        }
        else {
            let exact_value = +(value.replaceAll(",", ""));
            exact_value = Math.floor(exact_value / 1000);
            exact_value = exact_value * 1000;
            setAmount(exact_value.toLocaleString("en-IN"));
        }
    }

    const handleRadioClick = (e, radioValue) => {
        setAppRoi((+radioValue.applicable_roi) / 12);
        setRadioData(radioValue);
    }


    const handleRadioActivity = (item) => {
        const amt = +(amount.replaceAll(",", ""))
        if ((amt >= +(item.min_loan_amt)) && (+(amt) <= +(item.max_loan_amt))) {
            return false;
        }
        else {
            if(radioData && radioData.tenure == item.tenure) {
                uncheckRadio()
            }
            return true
        }
    }

    const handleClick = async() => {
        setResp(true);
        const payLoad = {
            lead_id: mw_application_id,
            customer_id: credentialState.cust_id,
            selected_loan_amount: +(amount.replaceAll(",", "")),
            selected_tenure: radioData.tenure,
            emi_calculated_applicable_roi: +(radioData.emi.replaceAll(",", "")),
            applicable_roi:(appRoi * 12).toString(),
            // applicable_roi: decisionDemog.offer_journey_change_flag == 1 ? (appRoi * 12).toString() : radioData.applicable_roi.toString(),
            selected_program: formData.program_id
        };

        let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-submission-3O" : "offer-submission";

        const ApiData = await offerSubmissionApi(payLoad, token,url);
        if ((ApiData.status == "1") && (ApiData.status_code == "200")) {
            if (ApiData?.data?.thankyou_flag == 1) {
                let qry = encryptOnlyLeadId(mw_application_id);
                window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=16`
            }
            else if (ApiData?.data?.banking_req == 1) {
                let qryStr = encryptOnlyLeadId(mw_application_id);
                // RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
                window.location.href = `${react_base_url}analyzer/?fid=${qryStr}`
            }
            else {
                let qry = encryptLeadIdStepId(mw_application_id, 4);
                RelocateUTMCheck(`${react_base_url}personal-loan/?fid=${qry}`);
            }
        }
        else{
            setRocket(true);
        }
    }

    return (
        <>
            {rocket && <NewErrorCase handleClick={(e) => handleErrorCase(e)} />}
            {state ? (
                <CKYCWaiting heading={"We are Generating an Offer For You."} />
            ) : (
                <div>
                    <FormHead
                        formName="Loan Details"
                        formSubHead={"Choose your loan amount & loan tenure"}
                        progressBar={true}
                    />
                    <div className="pa-offer-main">
                        <div className="pa-offer-head">
                            <div className="pa-offer-head-first">
                                <p>Approved Loan Amount</p>
                                <div className="pa-offer-head-first-input-div">
                                    <span>{amount ? "₹" : ""}</span>
                                    <input type="text" name="input-box" value={amount} onChange={(e) => handleChange(e)} onBlur={(e) => handleBlur(e)} />
                                </div>
                            </div>
                            <div className="pa-offer-head-second">
                                <input type="range" className="urban-range-slider" name="input-range" min={formData.min_loan_amt} max={formData.max_loan_amt} value={amount
                                    ? amount.replaceAll(",", "") : formData.max_loan_amt} step={1000} onChange={(e) => handleChange(e)} />
                                <div className="pa-offer-head-input-minmax">
                                    <p>₹ {formData.min_loan_amt}</p>
                                    <p>₹ {formData.max_loan_amt}</p>
                                </div>
                            </div>
                        </div>
                        <div className="pa-offer-body">
                            <div className={"pa-offer-body-first" + (radioData ? "" : " margin-percentage")}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Tenure <br /><span>(in months)</span></th>
                                            <th>ROI <br /><span>(per month)</span></th>
                                            <th>EMI <br /><span>(in ₹)</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tenureData.map((item, idx) => (
                                                <tr key={idx}>
                                                    <td>
                                                        <input type="radio" disabled={handleRadioActivity(item)} className="radio-btn" name="radio-btn" onClick={(e) => handleRadioClick(e, item)} />
                                                    </td>
                                                    <td>{item.tenure}</td>
                                                    <td>{((+item.applicable_roi) !== 0.00) ? (+(item.applicable_roi) / 12).toFixed(2) : "--"}</td>
                                                    <td>{(+(amount.replaceAll(",", "")) >= +(item.min_loan_amt)) && (+(amount.replaceAll(",", "")) <= +(item.max_loan_amt)) ? item.emi : "--"}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {radioData ? <div className="pa-offer-body-second">
                                <p>Monthly EMI ₹ {radioData.emi}</p>
                                <p>Interest Rate {appRoi ? appRoi.toFixed(2) : ((+radioData.applicable_roi) / 12).toFixed(2)}%(P.M.)</p>
                            </div> : <div></div>}
                            <div className={"pa-offer-body-third"}>
                                <div>
                                    <input
                                        disabled={resp}
                                        type="checkbox"
                                        checked={(checkBox)}
                                        className="pointer-cur checkbox-field"
                                        onChange={() => setCheckBox(!checkBox)}
                                    />
                                </div>
                                <div>
                                    <span className="pointer-cur" onClick={() => setCheckBox(!checkBox)}>
                                        I authorise MoneyWide, its lending partners to access my
                                        credit report, download C-KYC records, share these with
                                        loan application
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="pa-offer-footer">
                            <button
                                onClick={() => handleClick()}
                                disabled={!radioData || !checkBox}
                                className={
                                    "footer-btn Tmargin Bmargin" + ((radioData && checkBox) ? " footer-btn-activate" : "")
                                }
                            >
                                {!resp ? (
                                    "Continue"
                                ) : (
                                    <div className="continue-lottie">
                                        <Lottie
                                            loop
                                            animationData={ctaAnimation}
                                            play
                                            className="ctn-loader"
                                        />
                                    </div>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default UrbanMoneyOfferOld;