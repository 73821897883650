import React, { useState } from "react";
import "./finaloffer.css";
import FormHead from "../Journey Form/Form/FormHead";
import Lottie from "react-lottie-player";
import colored from "../../Icons/Frame.svg";
import uncolored from "../../Icons/Frame-1.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateFormState } from "../../Redux/action";
import { useEffect } from "react";
import ctaAnimation from "../assests/lootie-files/continue.json";

const KFS = () => {
  const [APIData, setAPIData] = useState({});
  const [resp, setResp] = useState(false);
  const [openedOption, setOpenedOption] = useState({
    keyFact: true,
    repayment: false,
  });
  const [scroller, updateScroller] = useState(0);
  const dispatch = useDispatch();
  const { formStates, KFSData } =
    useSelector((state) => state);

  useEffect(() => {
    if (Object.keys(KFSData).length) {
      setAPIData(KFSData);
    }
  }, []);

  const openKeyFact = () => {
    setOpenedOption({ keyFact: !openedOption.keyFact, repayment: false });
  };

  const openRePayment = () => {
    setOpenedOption({ keyFact: false, repayment: !openedOption.repayment });
    updateScroller(0);
  };

  const onSubmit = () => {
    setResp(true);
    
    dispatch(updateFormState({ ...formStates, KFS: false, LoanAgreement: true }))

    sessionStorage.clear();
  };

  const moveLeft = (e) => {
    e.preventDefault();
    const scroll = document.getElementById("scroll");
    scroll.scrollLeft -= 185;
    updateScroller(scroll.scrollLeft);
  };

  const moveRight = (e) => {
    e.preventDefault();
    const scroll = document.getElementById("scroll");
    scroll.scrollLeft += 185;
    updateScroller(scroll.scrollLeft);
  };

  const PrevButtonClicked = () => {
    dispatch(updateFormState({ ...formStates, Terms: true, KFS: false }));
  };
  return (
    <div>
      <FormHead
        formName="Key Fact Statement"
        formSubHead={"Read the key fact statement carefully"}
        backBtn={true}
        progressBar={true}
        handleBack={PrevButtonClicked}
      />
      <div className="offer-main">
        <div className="hidden-contents">
          <div
            className="option-boxing"
            style={{ borderRadius: "5px 5px 0 0" }}
            onClick={() => openKeyFact()}
          >
            <span className="option-head">Key Facts Statement</span>
            <span>
              <i
                className={"arrow " + (openedOption.keyFact ? "up" : "down")}
                style={{ borderColor: "#fff" }}
              ></i>
            </span>
          </div>

          {openedOption.keyFact && (
            <>
              <div className="kfs-subhead">
                <div className="subHead-content">
                  <span>Date : </span>
                  <span>{APIData.Date ? APIData.Date.slice(0, 10) : ""}</span>
                </div>
                <div className="subHead-content">
                  <span>Name of the Regulated entity : </span>
                  <span>{APIData.name_of_the_regulated_entity}</span>
                </div>
                <div className="subHead-content">
                  <span>Applicant Name : </span>
                  <span>{APIData.applicant_name}</span>
                </div>
              </div>
              {APIData.parameter && (
                <div className="key-statements">
                  {APIData.parameter.map((row, ind) => {
                    return (
                      <div key={ind}>
                        {row.heading && (
                          <h3 className="nested-heading">{row.heading}</h3>
                        )}
                        {row.sub_array.map((content, index) => {
                          return (
                            <div className="statements" key={index}>
                              <span className="statement-content">
                                {content.sno}{" "}
                              </span>
                              <span className="statement-content" style={content["bold_flag"] == 1 ? {fontWeight:"bold"} : {}}>
                                {content.name} -{" "}
                              </span>
                              <span className="statement-head">
                                {content.value.replaceAll("<br/>", "\r")}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  {APIData.notes && (
                    <div className="key-statements">
                      {APIData.notes.map((row, ind) => {
                        return (
                          <div className="statements" key={ind}>
                            <span className="statement-content">
                              {row.sno} . {row.note}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          <div
            className="option-boxing"
            style={{ borderRadius: "0 0 5px 5px", marginTop: "1px" }}
            onClick={() => openRePayment()}
          >
            <span className="option-head">Repayment Schedule</span>
            <span>
              <i
                className={"arrow " + (openedOption.repayment ? "up" : "down")}
                style={{ borderColor: "#fff" }}
              ></i>
            </span>
          </div>
          {openedOption.repayment && (
            <div className="repayment-section">
              <div className="btns-colm">
                <div className="left-move">
                  {scroller === 0 ? (
                    <button onClick={(e) => moveLeft(e)}>
                      <img src={uncolored} alt="" /> Previous
                    </button>
                  ) : (
                    <button onClick={(e) => moveLeft(e)} className="colored">
                      <img src={colored} style={{ rotate: "180deg" }} alt="" />{" "}
                      Previous
                    </button>
                  )}
                </div>
                <div className="right-move">
                  {scroller >=
                  [APIData.repayment_sechdule.length * 100 - 300] ? (
                    <button onClick={(e) => moveRight(e)}>
                      Next{" "}
                      <img
                        src={uncolored}
                        style={{ rotate: "180deg" }}
                        alt=""
                      />
                    </button>
                  ) : (
                    <button onClick={(e) => moveRight(e)} className="colored">
                      Next <img src={colored} alt="" />
                    </button>
                  )}
                </div>
              </div>
              <div className="repayment-table" id="scroll">
                <div className="fixed-col">
                  <div className="row-heading">
                    {APIData.labels.installment_no}
                  </div>
                  <div className="row-heading">
                    {APIData.labels.outstanding_principal}
                  </div>
                  <div className="row-heading">{APIData.labels.principal}</div>
                  <div className="row-heading">{APIData.labels.interest}</div>
                  <div className="row-heading">
                    {APIData.labels.installment}
                  </div>
                </div>
                {APIData.repayment_sechdule.map((row, index) => {
                  return (
                    <div className="data-part" key={index}>
                      <div className="row-content">
                        {row?.sno?.toLocaleString("en-IN")}
                      </div>
                      <div className="row-content">
                        {row?.outstanding_principal?.toLocaleString("en-IN")}
                      </div>
                      <div className="row-content">
                        {row?.principle?.toLocaleString("en-IN")}
                      </div>
                      <div className="row-content">
                        {row?.interest?.toLocaleString("en-IN")}
                      </div>
                      <div className="row-content">
                        {row?.installment?.toLocaleString("en-IN")}
                      </div>
                    </div>
                  );
                })}
              </div>
              <p className="instruction-mitc">
                {APIData.repayment_schedule_note}
              </p>
            </div>
          )}
        </div>
      </div>
      <button
        type="submit"
        className={"footer-btn footer-btn-activate"}
        onClick={() => onSubmit()}
      >
        {!resp ? (
          "Continue"
        ) : (
          <div className="continue-lottie">
            <Lottie
              loop
              animationData={ctaAnimation}
              play
              className="ctn-loader"
            />
          </div>
        )}
      </button>
    </div>
  );
};

export default KFS;
