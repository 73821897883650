import React, { useState,useEffect } from "react";
import StepsliderContentLeft from "./StepsliderContentLeft";
import StepsliderContentRight from "./StepsliderContentRight";
import "./slider.css";
import Step1   from "../assests/Step1.webp";
import Step2   from "../assests/Step2.webp";
import Step3 from "../assests/Step3.webp";
import Step4 from "../assests/Step4.webp";
import Step5 from "../assests/Step5.webp";
function StepSlider () {
    const [step,updateStep] = useState([true,false,false,false,false]);
    const [page,updatePage] = useState(0);
    const [visible,setVisibile] = useState(true)

    React.useEffect(() => {
        let cur_url = window.location.href;
        if (((cur_url.includes("utm_medium") || (cur_url.includes("utm_source"))))) {
        setVisibile(false);
        }
      }, [])
    useEffect(()=>{
        var newStep = Array(5).fill(false);
        newStep[page]=true;
        updateStep(newStep)
    },[page])

    const changeStep=(index)=>{
        updatePage(index);
    }
    const nextPage=()=>{
        if(page<4){
            updatePage(page+1)
        }
    }
    const previousPage=()=>{
        if(page>0){
            updatePage(page-1);
        }
    }

    return (
        <section className="howtoapply stepslider">
             <div> <h1>Easy Steps to Get a <b>Personal Loan</b></h1></div>
			<div className="container">
				<div className="row">
					<div className="col-md-6">
						<div id="stepsbox">
							<StepsliderContentLeft 
                                className={`step ${step[0] ? "active-step" : ""}`}
                                onClick={()=>changeStep(0)}
                                id="s1"
                                stepnumber="Step 1"
                                steppera={visible?"Apply online or ":"Apply online "}
                                steplink={visible}
                            />
                            <StepsliderContentLeft 
                                className={`step ${step[1] ? "active-step" : ""}`}
                                onClick={()=>changeStep(1)}
                                id="s2"
                                stepnumber="Step 2"
                                steppera="Fill the application form online"
                            />
                            <StepsliderContentLeft 
                                className={`step ${step[2] ? "active-step" : ""}`}
                                onClick={()=>changeStep(2)}
                                id="s3"
                                stepnumber="Step 3"
                                steppera="Get Instant Approval"
                            />
                            <StepsliderContentLeft 
                                className={`step ${step[3] ? "active-step" : ""}`}
                                onClick={()=>changeStep(3)}
                                id="s4"
                                stepnumber="Step 4"
                                steppera="Complete e-KYC and verification"
                            />
                            <StepsliderContentLeft 
                                className={`step ${step[4] ? "active-step" : ""}`}
                                onClick={()=>changeStep(4)}
                                id="s5"
                                stepnumber="Step 5"
                                steppera="Get funds in your account"
                            />
						</div>
					</div>

					<div className="col-md-6 content-right">
						<div className="slidestepbox">
							<div id="step-slider">
                                {step[0] && 
                                    <StepsliderContentRight 
                                        id="sr1"
                                        // sheading="Apply Online for Personal Loan"
                                        // spera="Visit MoneyWide website or Download App to apply for personal loan"
                                        simg={Step1}
                                    />
                                }
                                {step[1] && 
                                    <StepsliderContentRight 
                                        id="sr2"
                                        // sheading="Fill the Application Form"
                                        // spera="Submit details like name, age, employment for AIP approval"
                                        simg={Step2}
                                    />
                                }
                                {step[2] && 
                                    <StepsliderContentRight 
                                        id="sr3"
                                        // sheading="Get instant approval"
                                        // spera="Get instant in-principle loan approval based on the information shared by you"
                                        simg={Step3}
                                    />
                                }
                                {step[3] && 
                                    <StepsliderContentRight 
                                        id="sr4"
                                        // sheading="Complete online KYC and verifications"
                                        // spera="After loan approval, complete video KYC and mandate verification"
                                        simg={Step4}
                                    />
                                }
                                {step[4] && 
                                    <StepsliderContentRight 
                                        id="sr5"
                                        // sheading="Fund Transfer to your account"
                                        // spera="After the process is complete, funds will be transferred to your account"
                                        simg={Step5}
                                    />
                                }
							</div>
							{/* <div id="step-previous" onClick={()=>previousPage()}className={(step[0])?"disable":""}>prev</div>
							<div id="step-next" onClick={()=>nextPage()}className={(step[4])?"disable":""}>next</div> */}
						</div>
					</div>
				</div>
			</div>
		</section>
    );
}
export default StepSlider;