import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import './PersonalLoanRepaymentOption.css';
import '../PersonalLoan/PersonalLoan.css'
// import paytm from './images/paytm.webp';
// import gpay from './images/gpay.webp';
// import phonepay from './images/phonepay.webp';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import MobileFooter from '../Footer/MobileFooter';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';

const PersonalLoanRepaymentOption = () => {
    const {PLLoader,formStates} = useSelector(state=>state)
    var loan_type_id_page = 56;
    const [data, setData] = React.useState({ heading: "", data: [],sub_bread: " Personal Loan Repayment Options" })
    const dispatch = useDispatch()
    const { mwpages } = useSelector(state => state);
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY <= 200 || window.scrollY >= 1708) {
            sety(false);
        }
        else {
            sety(true);
        }
    });
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])
    return (
        <>
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi/>
            <div className='journey-stop'>
                <NewLoginForm pageType={loan_type_id_page} mapData={data} />
                <div style={{ marginTop: "100px" }}>
                    {y && <RightSidePopUp />}
                    <section>
                        <div className="ploan-content1 mob-margin-top web-margin-top">
                            <div className="ploan-container">
                                <div className="">
                                    <h1 className='heading-h2'>How to Pay MoneyWide <b>Personal Loan EMI?</b></h1>
                                    <span className="heading-p">MoneyWide <Link className='send-lnk' to="/personal-loan"><b>Personal Loan</b></Link> is a one-stop solution to fulfill your financial needs digitally with a quick loan process and minimum documentation. In addition, it also offers flexible repayment tenures ranging from 3 months to 9 months. You can easily manage and repay Personal Loans EMI online using Personal Loan EMI Calculator. The process is completely online with a 100% paperless process.<br /><br /></span>
                                </div>
                            </div>
                        </div>
                        <div className="repay-width">
                            <b>The different modes of online payments that you can choose to repay your Personal Loan EMI are:</b>
                            <br />
                            <ol className='ol-list repay-list'>
                                <li>UPI</li>
                                <li>Bank Account</li>
                                <li>NACH</li>
                            </ol>
                        </div>
                    </section>
                    <section className="ploan-content1">
                        <div className="ploan-container">
                            <div className="eligib-top">
                                <h2 className="heading-h2"><b>Payment</b> Options</h2>
                                <span className="heading-p">UPI or Unified Payment Interface is an instant real-time payment mode to transfer money. You may pay your EMI or outstanding dues of your MoneyWide loan by logging in to the online loan. To login now, <a style={{ color: "#1592e6", fontWeight: "600", textDecoration: "none" }} href='https://login.moneywide.com/'>Click Here</a></span>
                                {/* <div className="payment-by">
                            <div className="row-imp im-row">
                                <img src={gpay} alt="Not Found" />
                                <img src={phonepay} alt="Not Found" />
                                <img src={paytm} alt="Not Found" />
                            </div>
                        </div> */}
                            </div>
                        </div>
                    </section>
                    <section className="ploan-content1">
                        <div className="ploan-container">
                            <div className="eligib-top">
                                <h3 className="heading-h2"><b>NACH</b></h3>
                                <span className="heading-p">NACH or National Automated Clearing House is an automated mode of payment that enables debit of funds from one bank account and credit to another bank account without manual intervention. You can make payment through this mode if you have availed of the service by filling in the NACH mandate form at the time of the loan application process.</span>
                            </div>
                        </div>
                    </section>
                    <section className="ploan-content1">
                        <div className="ploan-container">
                            <div className="eligib-top">
                                <h3 className="heading-h2"><b>Prepayment/Foreclosure</b>-login</h3>
                                <span className="heading-p">In addition to the above-mentioned methods, if you want to opt for part payment OR want to close your account early, you can make a payment for the same by logging into your account through the web or MoneyWide App.</span>
                            </div>
                        </div>
                    </section>
                    <section className="">
                        <div className="ploan-content1">
                            <div className="ploan-container">
                                <div className="eligib-top">
                                    <h3 className="heading-h2">Things to Know About MoneyWide <b>Personal Loan Repayment</b></h3>
                                </div>
                            </div>
                        </div>
                        <div className="repay-width">
                            <span className="heading-p"><b>Here are some things that you must know about Personal Loan repayment:</b>
                                <ul className='ul-list'>
                                    <li>After making the payment, please send a mail to care@moneywide.com with the following details from your email Id registered with us:
                                        <ol className='ol-list'>
                                            <li>Name of Customer</li>
                                            <li>Registered Mobile number</li>
                                            <li>Amount paid</li>
                                            <li>Mode of Payment- Gpay / Phonepe / Paytm / Bank account.</li>
                                            <li>In the case of a bank account, mention the bank account name.</li></ol></li>
                                    <li>We will reflect credits in the account as soon as possible but at times it may take upto 3 working days.</li>
                                    <li>The benefit of credit is given from the actual date of payment.</li></ul>
                            </span>
                        </div>
                    </section>
                </div>
            </div>
            <div className='journey-start' style={{ display: "none" }}>
                {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                {(!formStates.IsIframe) && <MobileFooter />}
            </div>
        </>
    )
}

export default PersonalLoanRepaymentOption;