export const PRINCIPAL_AMOUNT = "PRINCIPAL_AMOUNT";
export const LOAN_TENURE = "LOAN_TENURE";
export const RATE_OF_INTEREST = "RATE_OF_INTEREST";
export const EMI = "EMI";
export const SET_HEADER_NAVIGATION = "SET_HEADER_NAVIGATION";
export const MENU_BAR = "MENU_BAR";
export const APPLYFORJOB = "APPLYFORJOB";
export const SET_MONTHLY_INCOME = "SET_MONTHLY_INCOME";
export const SET_MONTHLY_EXPENSE = "SET_MONTHLY_EXPENSE";
export const SET_CAL_GEN = "SET_CAL_GEN";
export const SHOWFORM = "SHOWFORM";
export const UPDATE_STEP_BAR = "UPDATE_STEP_BAR";
export const PERSONAL_LOAN_EVENT = "PERSONAL_LOAN_EVENT";
export const FORM_JOURNEY_ACTION = "FORM_JOURNEY_ACTION";
export const LOGIN_DETAILS = "LOGIN_DETAILS";
export const SORRY_POPUP = "SORRY_POPUP";
export const SET_PREAPPROVED_OFFER = "SET_PREAPPROVED_OFFER";
export const UPDATE_POP_UP = "UPDATE_POP_UP";
export const CKYC_UPDATE = "CKYC_UPDATE";
export const FORM_STATES = "FORM_STATES";
export const CREDENTIALS_STATE = "CREDENTIALS_STATE";
export const SELECTED_SERVICE_REQUEST_QUERY = "SELECTED_SERVICE_REQUEST_QUERY";
export const UPDATE_MASTER_STATE = "UPDATE_MASTER_STATE";
export const CHECK_MODIFY = "CHECK_MODIFY";
export const OFFICE_EMAIL = "OFFICE_EMAIL";
export const SET_PREAPPROVED_OFFER_DETAILS = "SET_PREAPPROVED_OFFER_DETAILS";
export const SET_MW_APPLICATION_ID = "SET_MW_APPLICATION_ID";
export const SET_CHOOSEN_AMOUNT = "SET_CHOOSEN_AMOUNT";
export const SET_RESIDENTIAL_ADDRESS = "SET_RESIDENTIAL_ADDRESS";
export const SERVICE_SHOW="SERVICE_SHOW";
export const SERVICE_LOGIN="SERVICE_LOGIN";
export const SERVICE_CREDENTIAL="SERVICE_CREDENTIAL";
export const RESUME_JOURNEY = "RESUME_JOURNEY";
export const KFS_DATA = "KFS_DATA";
export const APP_STATUS = "APP_STATUS";
export const PL_LOADER = "PL_LOADER";
export const SUGGESTION_DROPDOWN = "SUGGESTION_DROPDOWN";
export const DECISION_DEMOG = "DECISION_DEMOG";
export const GENERATE_TOKEN = "GENERATE_TOKEN";
export const TEMPLET_ID = "TEMPLET_ID";
export const BLOG_DATA = "BLOG_DATA";
export const OFFICE_PENNY_DROP = "OFFICE_PENNY_DROP";
export const PRODUCT_ID = "PRODUCT_ID";
export const PART_PAYMENT_TENURE = "PART_PAYMENT_TENURE";
export const Part_PAYMENT_CHECKBOX = "Part_PAYMENT_CHECKBOX";
export const EMI_PAID = "EMI_PAID";
export const PART_PAYMENT_AMT = "PART_PAYMENT_AMT";
export const ORIGINAL_OUT_TENURE_LT = "ORIGINAL_OUT_TENURE_LT";
export const MW_PAGES = "MW_PAGES";
export const PARTNER_STATES = "PARTNER_STATES";
export const PARTNER_DATA = "PARTNER_DATA";
export const MAKE_PAYMENT = "MAKE_PAYMENT";
export const MYACCHEADERDATA = "MYACCHEADERDATA";
export const SUGGESTION_DROPDOWN2 = "SUGGESTION_DROPDOWN2";
export const SUGGESTION_DROPDOWN3 = "SUGGESTION_DROPDOWN3";
export const PARTNER_DOC_POPUP = "PARTNER_DOC_POPUP"