import React, { useState, useEffect } from "react";
import './form.css';
import { useDispatch, useSelector } from 'react-redux';
import { applyForJob } from "../../Redux/action";
import man from '../../Icons/man.svg';
import mail from '../../Icons/email.svg';
import phone from '../../Icons/phone.svg';
import upload_btn from '../../Icons/upload_btn.svg';
import remove_btn from '../../Icons/remove_btn.svg';
import { useForm } from "react-hook-form";
import Lottie from 'react-lottie-player';
import ctaAnimation from '../assests/lootie-files/continue.json';
import NomineeThank from '../Nominee/NomineeThank'
import NomineSorry from '../Nominee/NomineSorry'
import { BlueArr, LinkedIn } from "../../Icons/CodeIcons/codeicons";
import { prodbase_url, source, verification_source, verified_by, verifier_name } from "../Secret_Credentials/Secret_Credential";
import axios from "axios";
import { Link } from "react-router-dom";

function Form({ data, setOpenForm, }) {
    const [formStatus, setFormStatus] = useState(0)
    const [shownData, setShownData] = useState({
        profile: "",
        location: "",
        function: "",
        jobDesc: "",
        about: "",
        candidateProfile: ""
    })

    const checkParameter = (parameter, Values) => {
        if (!Values || !Values.length) {
            return -1;
        }
        for (var i = 0; i < Values.length; i++) {
            if (Values[i].hasOwnProperty(parameter)) {
                return i;
            }
        }
        return -1;
    }

    const ManageAllData = () => {
        var newVal = {
            profile: "",
            location: data.data.location,
            function: "",
            jobDesc: "",
            about: "",
            candidateProfile: ""
        }
        var profile = checkParameter("profile_name", data.data.profile_data)
        if (profile !== -1) {
            newVal.profile = data.data.profile_data[profile].profile_name
            newVal.function = data.data.profile_data[profile].department_name
        }
        var desc = checkParameter("Job_Description", data.data.profile_data)
        if (desc !== -1) {
            newVal.jobDesc = data.data.profile_data[desc].Job_Description
            stringToLi(data.data.profile_data[desc].Job_Description, "job-desc")
        } else {
            var removeIt = document.getElementById('job-desc')
            if (removeIt) {
                removeIt.style.display = "none"
            }
        }
        var candidateProfile = checkParameter("Desired_Candidate_Profile", data.data.profile_data)
        if (candidateProfile !== -1) {
            newVal.candidateProfile = data.data.profile_data[candidateProfile].Desired_Candidate_Profile
            stringToLi(data.data.profile_data[candidateProfile].Desired_Candidate_Profile, "candidate-profile")
        } else {
            var removeIt = document.getElementById('candidate-profile')
            if (removeIt) {
                removeIt.style.display = "none"
            }
        }
        var about = checkParameter("About_the_company", data.data.profile_data)
        if (about !== -1) {
            newVal.about = data.data.profile_data[about].About_the_company
            stringToLi(data.data.profile_data[about].About_the_company, "about-company")
        } else {
            var removeIt = document.getElementById('about-company')
            if (removeIt) {
                removeIt.style.display = "none"
            }
        }
        setShownData(newVal)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        ManageAllData()
    }, [])

    function stringToLi(val, appendId) {
        var ul = document.createElement('ul')
        var li = makeUL(val)
        var field = document.getElementById(appendId)
        ul.innerHTML = li.join("")
        field.append(ul)
    }


    function makeUL(options) {
        var m = [];
        for (var i = 0; i < options.length; i += 1) {
            m[i] = `<li>${options[i]}</li>`;
        }
        return m;
    }

    const ScrollToForm = () => {
        var formFocus = document.getElementById('career-form');
        if (formFocus) {
            formFocus.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "nearest"
                })
        }
    }

    const handleBackToAll = () => {
        setOpenForm(false)
        window.scrollTo(0, 0)
    }
    return (
        <div className="career-section" id="job-des-open">
            <div className="career-banner">
                <div className="partner-boxing car-box">
                    <div className="career-top-content carrer-form-head">
                        <div className="left-top">
                            <Link to='/'>Home</Link>  &gt;{" "}
                            <span>Career</span>
                        </div>
                        <h3 className="carrer-form-career-heading">{shownData.profile ? shownData.profile : ""}</h3>
                        <p>{shownData.function ? shownData.function : ""}</p>
                        <p>Location : {shownData.location ? shownData.location : ""}</p>
                    </div>
                </div>
            </div>

            <div className="job-desc">
                <div className="partner-boxing">
                    <div className="manage-Width">
                        <div className="back-to-all" >
                            <div className="backBTN" onClick={() => handleBackToAll()}>
                                <div className="LRotate">
                                    <BlueArr w={28} h={28} />
                                </div>
                                All Positions
                            </div>
                            <div className="applyBTN" onClick={() => ScrollToForm()}>
                                Apply Now
                            </div>
                        </div>
                        <div className="jd jd-top">
                            <span className="jd-heading" id="job-desc">Job Description</span>
                        </div>
                        <div className="jd">
                            <span className="jd-heading" id="candidate-profile">Desired Candidate Profile</span>
                        </div>
                        <div className="jd" >
                            <span className="jd-heading" id="about-company">About the company</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="joining-form" id="career-form">
                <div className="partner-boxing">
                    <div className="manage-Width">
                        <div className="joining-box">
                            <div className="work-together">
                                <span>Let's Work Together</span>
                                <p>Towards Widening Money Horizons</p>
                            </div>
                            <div className="joining-form-box" >
                                {formStatus === 0 ? <EmptyForm formId={data.id} setFormStatus={setFormStatus} />
                                    : formStatus === 1 ?
                                        <NomineeThank msg={"Details submitted successfully."} />
                                        :
                                        <NomineSorry msg={"There is some technical error. Please try again later."} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Form;

const EmptyForm = ({ setFormStatus, formId }) => {
    const [formSubmit, setFormSubmit] = useState(false)
    const [resp, setResp] = useState(false)
    const { register, handleSubmit, setValue, formState: { errors }, watch, setError, clearErrors } = useForm({
        defaultValues: {
            name: "",
            email: "",
            phone: "",
            resume: "",
            url: ""
        }, mode: "all"
    })
    const { token } = useSelector(state => state)

    const data = watch()

    useEffect(() => {
        if (data.name && data.email && data.phone && data.resume && data.url) {
            if (Object.keys(errors).length === 0) {
                setFormSubmit(true)
            } else {
                setFormSubmit(false)
            }
        } else {
            setFormSubmit(false)
        }
    }, [data])
    const onSubmit = (data) => {
        if (formSubmit) {
            setResp(true)
            const payLoad = new FormData()
            payLoad.append('source', source)
            payLoad.append('verification_source', verification_source)
            payLoad.append('verifier_name', verifier_name)
            payLoad.append('verified_by', verified_by)
            payLoad.append('name', data.name)
            payLoad.append('cust_email', data.email)
            payLoad.append('mobile', data.phone)
            payLoad.append('linkedIn_profile_link', data.url)
            payLoad.append('position_id', formId)
            payLoad.append('resumedoc[]', data.resume[0])

            axios.post(`https://api.mlc-finvest.com/career-form-submit`, payLoad, {
                headers: {
                    token
                }
            }).then((res) => {
                if (res.data.status === "1" && res.data.status_code === "200") {
                    setFormStatus(1)
                } else {
                    setFormStatus(2)
                }
            })
        }
    }

    const handleUpKeyNumber = (value, field) => {
        var regexp = /[^0-9]/g;
        if (value.length !== 0 && value.match(regexp)) {
            setValue(field, value.replace(regexp, ""))
        }
    }

    const handleAlphaOnly = (value, field) => {
        var regexp = /[^a-zA-Z ]/g;
        if (value.match(regexp)) {
            setValue(field, value.replace(regexp, ''))
        } else {
            setValue(field, value.trimLeft());
        }
    }

    const Blured = (field, value) => {
        var newVal = value.trimRight();
        setValue(field, newVal);
        if (newVal.length < 3) {
            setError(field)
        } else {
            clearErrors([field])
        }
    }

    const NumberOnly = (value) => {
        var lastseven = value.substr(value.length - 7);
        var num_charstring_number = value.substr(4, 4);
        var last_digit = value.slice(0, 2);
        var cr_str = Array(6).join(last_digit);
        var result = false;
        if (/^[6789]\d{9}$/i.test(value)) {
            result = true;
        }
        if (value === '9876543210') {
            result = false;
        }
        if (/^(\d)\1+$/.test(lastseven)) {
            result = false;
        }
        if (value === cr_str) {
            result = false;
        }
        if (num_charstring_number == 'XXXX') {
            result = true;
        }
        if (/(\d)\1{6,}/.test(value)) {
            result = false;
        }
        return result;

    }

    function isValidHttpUrl(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(str)

    }

    const NeedToRemove = () => {
        if (!resp) {
            setValue('resume', "")
        }
    }


    return <div className="career-form-content" >
        <span>Fill Joining Form</span>
        <div className="career-form">
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="career-form-row">
                    <div className={"career-input" + (data.name && errors.name ? " career-border-err" : (data.name
                        ? " career-border" : ""))}>
                        <img src={man} alt="na" />
                        <input readOnly={resp} type="text" {...register('name', { required: true, minLength: 3 })} placeholder="Full Name"
                            onKeyUp={() => handleAlphaOnly(data.name, "name")} onBlur={() => Blured("name", data.name)} />

                        {data.name && errors.name ? <p className="career-err">Enter at least 3 characters</p> : <></>}
                    </div>

                    <div className={"career-input" + (data.email && errors.email ? " career-border-err" : (data.email
                        ? " career-border" : ""))}>
                        <img src={mail} alt="na" />
                        <input readOnly={resp} type="text" {...register('email', {
                            required: true, pattern: {
                                value: /^([a-zA-Z0-9_.+-]{3,64})+\@(([a-zA-Z0-9-]{2,255})+\.)+([a-zA-Z0-9]{2,8})+$/
                            }
                        })} placeholder="Email ID" />

                        {data.email && errors.email ? <p className="career-err">Invalid Email Address</p> : <></>}
                    </div>
                </div>
                <div className="career-form-row">
                    <div className={"career-input" + (data.phone && errors.phone ? " career-border-err" : (data.phone
                        ? " career-border" : ""))}>
                        <img src={phone} alt="na" />
                        <input readOnly={resp} type="tel" maxLength={10} {...register('phone', {
                            required: true,
                            minLength: 10,
                            validate: NumberOnly
                        })} onKeyUp={() => handleUpKeyNumber(data.phone, "phone")} placeholder="Contact Number" />

                        {data.phone && errors.phone ? <p className="career-err">Invalid phone number</p> : <></>}
                    </div>
                    <div className={"career-input" + (data.url && errors.url ? " career-border-err" : (data.url
                        ? " career-border" : ""))}>
                        <LinkedIn h={28} w={28} />
                        <input readOnly={resp} type="url" {...register('url', { required: true, validate: isValidHttpUrl })} placeholder="LinkedIn Profile" />

                        {data.url && errors.url ? <p className="career-err">Invalid Url</p> : <></>}
                    </div>
                </div>
                <div className="career-form-row">

                    <div className='proof-border remove-bottom' >
                        {data.resume && data.resume[0] ?
                            <div className='proof-row' id="proof-row-id">
                                {<span className='proof-row-p'>{data.resume[0].name}</span>}
                                <img className='specific-width-height pointer-cur' src={remove_btn} alt="na" onClick={() => NeedToRemove()} />
                            </div>
                            :
                            <div className='proof-row' >
                                <label htmlFor="upload-the-photo">
                                    <p className='proof-row-p'>Upload Resume</p>
                                    <a><img className='specific-width-height' src={upload_btn} alt="NA" /></a></label>
                                <input readOnly={resp} type="file" {...register('resume', { required: true })} name="resume" id="upload-the-photo" accept='.pdf,.PDF,.DOCX,.docx,.doc,.DOC' />
                            </div>}
                    </div>
                    {/* <div className="career-input">
                        <BuildingIcon h={28} w={28} />
                        <input type="text" placeholder="Work Experience" />
                    </div> */}
                </div>
                <div className="manage-btn">
                    <button disabled={resp} type="submit" className={"footer-btn carrer-btn" + (formSubmit ? " footer-btn-activate" : "")}>
                        {resp ? <div className="continue-lottie">
                            <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                        </div> : "Submit"
                        }
                    </button>
                </div>
            </form>
        </div>
    </div>
}