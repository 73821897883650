import React from 'react'
import one from '../../Icons/onlystar.svg';

const ReviewImg = ({star}) => {
  return (
    <>
     {[...Array(star)].map((star,index) => {        
     return (         
      <img src={one} key={index} alt="starimage" />
      );
    })}
    </>
  )
}

export default ReviewImg