import React, { useState } from "react";
import FormHead from "../Journey Form/Form/FormHead";
import "./ComplainServiceRequest.css";
import { useSelector, useDispatch } from "react-redux";
import { GenerateToken, updateFormState, updateSelectedServiceRequestQuery, updateserviceshow } from "../../Redux/action";
import axios from "axios";
import { prodbase_url, client_id, client_key, source,verification_source,verified_by,verifier_name } from '../Secret_Credentials/Secret_Credential'
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";

// const pay = {
//     "client_id": client_id,
//     "client_key": client_key,
//     "source": source
//   }
//   axios.post(`${prodbase_url}token`, pay)
//     .then((res) => {
//       const token = res.data.token;
//       const datapin = {
//         "source": source,
//         "pincode": data.pincode
//       }
//       axios.post(`${prodbase_url}get-pincode-city`, datapin, {
//         headers: {
//           token
//         }
//       }).then((res) => {
//         console.log(res);
//         setCityFromPin(res.data.data.city)
//       })
//         .catch((err) => {
//           console.log(err);
//         })
//     })
function ComplainServiceRequest() {
    const dispatch = useDispatch();
    const { selectedServiceQuery, serviceCred,setService,token,formStates } = useSelector(state => state)
    const [data, setData] = useState([])
    const [state,setState] = useState(true);
    const [rocket, setRocket] = useState(false);

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
      }
    
      React.useEffect(() => {
        if(token == "") {
          myToken();
        }
        window.scrollTo(0,0);
        // knowdevice();
        // getData()
      }, [])

    React.useEffect(() => {
        if(token !== ""){
            // const pay = {
            //     "client_id": client_id,
            //     "client_key": client_key,
            //     "source": source
            // }
            // axios.post(`${prodbase_url}token`, pay)
            //     .then((res) => {
            //         const token = res.data.token;
                    const payload = {
                        "source": source,
                        cust_id: serviceCred.customer_id,
                        // cust_id: 5,
                        verified_by,
                        verifier_name,
                        verification_source
                    }
                    axios.post(`${prodbase_url}cms-products`, payload, {
                        headers: {
                            token
                        }
                    }).then((res) => {
                        // console.log(res);
                        if((res.data.status_code === "200") && (res.data.status === "1")){
                            setData(res.data.data.data)
                            dispatch(updateserviceshow({...setService,name:res.data.data.customer_name}));
                            setState(false);
                        }
                        else{
                            // dispatch(updateFormState({ ...formStates, error: true }));
                            setRocket(true);
                            setState(false);
                        }
                        
                    })
                // })
        }
    }, [token])

    const OpenThisOne = (item,index,e) => {

        dispatch(updateSelectedServiceRequestQuery({ ...selectedServiceQuery, show: true, value: data[index],queryId:data[index].query_id}))
        axios.post(`${prodbase_url}cms-ticket-status`,{
            source,
            "query_id":data[index].query_id,
            verified_by,
            verifier_name,
            verification_source
            },{
                headers:{
                    token
                }
            }).then((res)=>{
                if((res.data.status == 1) && (res.data.status_code == 200)){
                    if(res.data.data.message !== ""){
                        dispatch(updateserviceshow({ ...setService, complain: false, thanks: true,thankMessage:res.data.data.message}))
                    }
                    else{
                        dispatch(updateserviceshow({...setService,complain:false,selected:true}))
                    }
                }
                else if((res.data.status == 0) && (res.data.status_code == 429)){
                    dispatch(updateserviceshow({...setService,complain:false,selected:true}))
                }
            })
        // console.log(item);
        // console.log("working");
        e.stopPropagation();
    }

    const handleError = (e) => {
        window.location.reload();
    }
    return (
        <>
        {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
       {state ? <CKYCWaiting heading={"We are Fetching Your Details."}/> : <div>
            <FormHead formName={"Service Request"} formSubHead={"How may we help you"} progressBar={true} />
            <div className="complain-service-request-main">
                <div className="complain-service-request-product">
                    <p>Select your loan product</p>
                </div>
                {data && data.map((item, index) => <div key={index} className="complain-service-request-all-products" onClick={(e) => OpenThisOne(item,index,e)}>
                    <div className="complain-service-request-all-products-type">
                        <p>{item.prod_name}</p>
                        <input style={{ width: "20px", height: "20px" }} type="radio" name="radio" checked={false} readOnly />
                    </div>
                    <hr />
                    <div className="complain-service-request-all-products-date-amount">
                        <div className="complain-service-request-all-products-date">
                            <p>Application Date:</p>
                            <p>{item.applicationdate?item.applicationdate.slice(5,16):""}</p>
                        </div>
                        <div className="complain-service-request-all-products-amount">
                            <p>Loan Amount:</p>
                            <p>{item.loan_amount}</p>
                        </div>
                    </div>
                    <div className="complain-service-request-all-products-status">
                        <p>Status</p>
                        <p>{item.status}</p>
                    </div>
                </div>)}
            </div>
            <button className="footer-btn" style={{ margin: "50px 0 40px 0" }}>Submit</button>
        </div>}
        </>
    )
}
export default ComplainServiceRequest;