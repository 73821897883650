import React, { useEffect, useState } from 'react';
import './LoginPopUp.css';
import { useForm } from 'react-hook-form';
import phone from '../../Icons/phone.svg';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../assests/lootie-files/continue.json';
import { getToken, SendOTP, WhatsAppSubscription } from '../EverCalledFunctions/EverCalledApis';
import { useDispatch, useSelector } from 'react-redux';
import { GenerateToken } from '../../Redux/action';
import { gettingerror } from '../EverCalledFunctions/EverCalledFunctions';
import { getLoginData } from '../MyAccount/MyAccountAPIs';
import whatsapp from "../../Icons/whatsapp.svg";

const Mobile = ({ setCurrentPage, state, setState, setUserNo, setOTPId, setLoader, setWhatsappToggel, whatsappToggel }) => {
  const [isChecked, setIsChecked] = useState(true)
  const [resp, setResp] = useState(false)
  const [show, setShow] = useState(false)
  const { token } = useSelector(state => state)
  const [checkWhatsapp, setcheckWhatsapp] = useState(true)
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    defaultValues: {
      mobile: "",
      checked: "",
    },
    mode: "all"
  });

  const data = watch();


  const NumberOnly = (value) => {
    var lastseven = value.substr(value.length - 7);
    var num_charstring_number = value.substr(4, 4);
    var last_digit = value.slice(0, 2);
    var cr_str = Array(6).join(last_digit);
    var result = false;
    if (/^[6789]\d{9}$/i.test(value)) {
      result = true;
    }
    if (value === '9876543210') {
      result = false;
    }
    if (/^(\d)\1+$/.test(lastseven)) {
      result = false;
    }
    if (value === cr_str) {
      result = false;
    }
    if (num_charstring_number == 'XXXX') {
      result = true;
    }
    if (/(\d)\1{6,}/.test(value)) {
      result = false;
    }
    return result;

  }

  const myToken = async () => {
    const T = await getToken();
    dispatch(GenerateToken(T))
  }

  useEffect(() => {
    if (token === "") {
      myToken()
    }
  }, [])

  useEffect(() => {
    if (token != "" && window.location.href.includes('utm_source=')) {
      let data = getLoginData()
      setState(state => ({
        ...state, 
        loader: true 
      }));
      if (data.userNo && data.userNo != "" && data.userNo != null && data.userNo != undefined) {
        data.mobile = data.userNo
        onSubmit(data)
      }
    }
  }, [token])


  // const checkSubscription = async (number) => {
  //   const check = NumberOnly(number);
  //   if (check && !window.location.href.includes('medium=')) {
  //     const APIDATA = await WhatsAppSubscription(number, token)
  //     if (APIDATA.status_code === "200" && APIDATA.status === "1") {
  //       if (APIDATA.data.is_whatsapp_subscribed === 0) {
  //         setShow(true);
  //       } else {
  //         setShow(false)
  //       }
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (!errors.mobile && data.mobile.length == 10) {
  //     checkSubscription(data.mobile)
  //     setcheckWhatsapp(false)
  //   } else {
  //     setShow(false)

  //   }
  // }, [data])

  const onSubmit = async (data) => {
    if (isChecked) {
      setResp(true)
      const payLoad = {
        "mobile": data.mobile,
        "api_mode": "LIVE",
        "login_from": 3
      }
      const APIResponse = await SendOTP(payLoad, token);
      if (APIResponse.status_code === "200" && APIResponse.status === "1") {
        // setUserNo(data.mobile)
        setState(state => ({
          ...state, 
          userNo: data.mobile,
          loader: false,
          otpId: APIResponse.data.otp_id 
        }));
        setCurrentPage(2)
        // setOTPId(APIResponse.data.otp_id)
        // setLoader(false)
        gettingerror('myaccount otp send web', data.mobile)
      }
    }
  }


  const handleUpKey = (value, field) => {
    var regexp = /[^0-9]/g;
    if (value && value.match(regexp)) {
      setValue(field, value.replace(regexp, ""))
    }
  }

  return (
    <>
      <div className="popup-form-head">
        <span>Login to your Account</span>
      </div>
      <div className="popup-form">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} style={{ marginTop: "40px" }}>
          <div className={(errors.mobile && data.mobile) ? "popUp-input-tag popUp-red-error-border" : (data.mobile ? "popUp-input-tag popUp-add-border" : "popUp-input-tag")}>
            <img src={phone} alt="" />
            <input type="tel" maxLength={10} autoFocus placeholder="Enter Mobile Number" {...register("mobile", {
              required: true,
              minLength: 10,
              maxLength: 10,
              validate: NumberOnly
            })}
              onKeyUp={() => handleUpKey(data.mobile, "mobile")}
            />
            {errors.mobile && data.mobile && <div className="login-area-login-err">Enter a valid number</div>}
          </div>
          {/* {show ? (<div className="get-login-tag get-login-tag-myacc">
            <div className="left-tag-whatsaap myacc">
              <img src={whatsapp} />
              <span>Get update on WhatsApp</span>
            </div>
            <label className="switch switch-login">
              <input
                type="checkbox"
                onChange={() => setWhatsappToggel(!whatsappToggel)}
                checked={whatsappToggel}
              />
              <span className="slider round"></span>
            </label>
          </div>) : (
            ""
          )} */}
          <div className="popUp-checkbox">
            <label className="main">I authorize Moneywide and it's partners to connect me via call/SMS/WhatsApp/Email . This overrides my number being in the NDNC registry. <Link className="popUp-lnk" target='_blank' to='/terms-and-conditions'>T&C Apply</Link>
              <input type="checkbox" {...register('checked')} onClick={() => setIsChecked(!isChecked)} checked={isChecked} />
              <span className="geekmark"></span>
            </label>
          </div>
          <div className={(!errors.mobile && data.mobile.length === 10 && data.checked) ? "popUp-btn active-btn" : "popUp-btn "} >
            <button disabled={resp} type="submit">
              {!resp ?
                "Send OTP" :
                <div className="continue-lottie">
                  <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                </div>}
            </button>
          </div>
        </form>
      </div></>
  )
}

export default Mobile