import React from 'react'
import { useState } from 'react'
import { react_base_url } from '../Secret_Credentials/Secret_Credential'
import Base64 from "base-64";
import { useEffect } from 'react';

const MakePaymentOption = ({ baseData, setCurrentForm }) => {
    const [select, setSelect] = useState(baseData?.data?.overdue_flag == 1 ? 3 : (baseData?.data?.partpayment == 1 ? 2 : 1))
    const [count, setCount] = useState(0)



    useEffect(() => {
        if (baseData?.data?.overdue_flag == 1) {
            setCount(count + 1)
        }
        if (baseData?.data?.partpayment == 1) {
            setCount(count + 1)
        }
        if (baseData?.data?.foreclosure_flag == 1) {
            setCount(count + 1)
        }
        if (baseData?.data?.overdue_flag == 0 && baseData?.data?.partpayment == 0 && baseData?.data?.foreclosure_flag == 0) {
            setCurrentForm(6)
        }
    }, [])
    const MoveAhead = () => {
        if (select == 3) {
            if (baseData?.data?.overdue_link != "") {
                window.open(baseData.data.overdue_link, '_blank');
                setCurrentForm(3)
            } else {
                setCurrentForm(4)
            }
        } else {
            let qry = Base64.encode(select)
            qry = Base64.encode(qry)
            window.location.href = `${react_base_url}login/?utm_source=${qry}`
        }
    }
    return (
        <div className='main-options'>
            <div className="make-payment-option">

                <div className="make-box" >
                    {/* {<input type="radio" checked={ind == open} />} */}
                    <span>Loan Account</span>
                    <p>{baseData.Id}</p>
                </div>
                {<React.Fragment>
                    <div className="option-box" style={count == 2 ? { justifyContent: "space-evenly" } : (count == 1 ? { justifyContent: "center" } : { justifyContent: "space-between" })}>
                        {baseData?.data?.overdue_flag == 1 && <div className={'option red-option' + (select == 3 ? " option-red-selected" : "")} onClick={() => setSelect(3)}>Overdue</div>}
                        {baseData?.data?.partpayment == 1 && <div className={'option' + (select == 2 ? " selected-option" : "")} onClick={() => setSelect(2)}>Part Payment</div>}
                        {baseData?.data?.foreclosure_flag == 1 && <div className={'option' + (select == 1 ? " selected-option" : "")} onClick={() => setSelect(1)}>Foreclose</div>}
                    </div>
                    {select == 3 && <div className='over-amt'>Total Overdue:&nbsp;<span> ₹ {baseData?.data?.overdue_amount
                    }</span></div>}

                    {select != 0 && <div className="make-payment-btn">
                        <button onClick={() => MoveAhead()}>{select == 3 ? "Pay Now" : "Continue"}</button>
                    </div>}
                </React.Fragment>}
            </div>
        </div>
    )
}

export default MakePaymentOption;