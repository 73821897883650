import React, { useState, useEffect } from "react";
import "../../Loader/Loader.css";
import { useForm } from "react-hook-form";
import "./esign.css";
import { useSelector, useDispatch } from "react-redux";
import Base64 from 'base-64';
import {
  form_journey_action,
  updateCredentialsState,
  updateFormState,
  change_personal_loan_page,
  updateLoginDetails,
  GenerateToken
} from "../../../Redux/action";
import {
  GetPersonalDetails,
  LoanAgreementFetch,
  SendOTP,
  VerifyOTP,
  getToken
} from "../../EverCalledFunctions/EverCalledApis";
import axios from "axios";
import {
  react_base_url,
  verification_source,
  verified_by,
  verifier_name,
  source,
  client_id,
  client_key,
  prodbase_url,
} from "../../Secret_Credentials/Secret_Credential";
import ctaAnimation from "../../assests/lootie-files/continue.json";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import CKYCWaiting from "../../LoginPopUp/CKYCWaiting";
import NewErrorCase from "../../Journey Form/Form/All_Forms/NewErrorCase";
import { Check_utm_source, encryptOnlyLeadId, gettingerror, handleKeyPress, RelocateUTMCheck } from "../../EverCalledFunctions/EverCalledFunctions";
import { AxiosThirty } from "../../AxiosConfiguration/AxiosConfig";
import { FetchCustomerPayload } from "../../EverCalledFunctions/EverCalledPayload";
import queryString from "query-string";
import { apiInstance } from "../../Config/APIConfig";

const Esign = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    setFocus
  } = useForm({
    defaultValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    }, mode: "all"
  });

  const { formStates, loginDetails, credentialState, personal_loan, mw_application_id, token } =
    useSelector((state) => state);

  const data = watch();
  const [timer, setTimer] = useState(30);
  const [id, setId] = useState("");
  const [device_id, setDevice_id] = useState();
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [Phone, setPhone] = useState();
  const [resp, setResp] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ip, setIP] = useState(null);
  const [show, setShow] = useState(true);
  const [loader, setLoader] = useState(true);
  const [newErr, setNewErr] = useState(false)
  const [rocket, setRocket] = useState(false);
  const [flag, setFlag] = useState();

  const SendCustomerId = async () => {
    let decFetchCustomer = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 14), {
      token
    })
      // .then((res) => {
        if (decFetchCustomer.status === "1" && decFetchCustomer.status_code === "200") {
          let customerID = "0";
          if (decFetchCustomer.data.customer_id) {
            customerID = decFetchCustomer.data.customer_id;
            dispatch(updateCredentialsState({ ...credentialState, cust_id: decFetchCustomer.data.customer_id }))
          }
          if (decFetchCustomer.data.thank_you_flag == 1) {
            gettingerror("get thank you flag",mw_application_id)
            if (decFetchCustomer.data.thank_you_url !== "") {
              window.location.href = decFetchCustomer.data.thank_you_url;
              return;
            }
            else {
              let qry = Base64.encode(`${mw_application_id}`);
              qry = Base64.encode(qry);
              RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=16`)
              return;
            }
          }
          if (decFetchCustomer.data.redirection_url !== "") {
            window.location.href = decFetchCustomer.data.redirection_url;
            return;
          }
          if ((decFetchCustomer.data.custPhoneNo) && (decFetchCustomer.data.custPhoneNo !== "") && (sessionStorage.getItem("otp_count") === "0")) {
            sessionStorage.setItem("otp_count", "1");
            setPhone(decFetchCustomer.data.custPhoneNo);
            sessionStorage.setItem("phone", decFetchCustomer.data.custPhoneNo)
            GetOTP(decFetchCustomer.data.custPhoneNo, customerID)
          } else {
            let qry = Base64.encode(`${mw_application_id}`);
            qry = Base64.encode(qry);
            RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=10`)
          }
        } else {
          let qry = Base64.encode(`${mw_application_id}`);
          qry = Base64.encode(qry);
          RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=10`)
        }
      // }).catch((err) => {
      //   setRocket(true);
      // })

  };


  function knowdevice() {
    if (window.innerWidth > 1024) {
      setDevice_id("1");
      return "1"
    } else if (window.innerWidth > 480 && window.innerWidth < 1024) {
      setDevice_id("3");
      return "3"
    } else {
      setDevice_id("2");
      return "2"
    }
  }

  function getDevice() {
    if (window.innerWidth > 1024) {
      return "1"
    } else if (window.innerWidth > 480 && window.innerWidth < 1024) {
      return "3"
    } else {
      return "2"
    }
  }

  const getData = async () => {
    const res = await axios.get('https://api.ipify.org?format=json')
    setIP(res.data.ip);
  }

  const getOS = () => {

    let os = "Unknown OS";

    if (navigator.userAgent.indexOf("Win") != -1)
      os = "Windows";
    else if (navigator.userAgent.indexOf("Mac") != -1)
      os = "Macintosh";
    else if (navigator.userAgent.indexOf("Linux") != -1)
      os = "Linux";
    else if (navigator.userAgent.indexOf("Android") != -1)
      os = "Android";
    else if (navigator.userAgent.indexOf("like Mac") != -1)
      os = "iOS";
    else os = "No Os Detected";

    return os;

  }

  const getBrowser = () => {

    let userAgent = navigator.userAgent;
    let browser;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browser = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browser = "Firefox";
    } else if (userAgent.match(/safari/i)) {
      browser = "Safari";
    } else if (userAgent.match(/opr\//i)) {
      browser = "Opera";
    } else if (userAgent.match(/edg/i)) {
      browser = "Edge";
    } else {
      browser = "No browser detection";
    }

    return browser

  }

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

  React.useEffect(() => {
    if (token === "") {
      myToken();
    }
    let stamp = queryString.parse(window.location.search);
    if (stamp && stamp.stamp_flag) {
      let fl = stamp.stamp_flag
      try {
        fl = Base64.decode(fl)
        fl = Base64.decode(fl)
        setFlag(fl)
      } catch {
        setFlag(0)
      }
    }
    else {
      setFlag(0)
    }
    knowdevice();
    getData();
    sessionStorage.setItem("otp_count", "0");
  }, [])


  useEffect(() => {
    if (token !== "" && ip) {
      if (sessionStorage.getItem("otp_count") === "0") {
        SendCustomerId();
      }


    }
  }, [token, ip])

  const GetOTP = async (phone, custId) => {
    let decOtp = await apiInstance.post(`generate-otp`, {
      "mobile": phone.toString(),
      "api_mode": "LIVE",
      "login_from": 4,
      "lead_id": mw_application_id,
      "device_type": getDevice(),
      "customer_id": custId,
      "browser": getBrowser(),
      "OS": getOS(),
      "IP": ip,
      "verification_source": verification_source,
      "verified_by": verified_by,
      "verifier_name": verifier_name,
      "source": source
    }, {
      token:""
    })
    // .then((res) => {
      if (decOtp.status_code === "200" && decOtp.status === "1") {
        gettingerror('esign otp send web',mw_application_id)
        setLoader(false)
        if (decOtp?.data?.otp_id !== "") {
          setId(decOtp.data.otp_id)
          sessionStorage.setItem('otp-id', decOtp.data.otp_id)
        } else {
          setResp(false)
          setId("LIMIT")
        }
      } else if (decOtp.status_code === "429" && decOtp.status === "0") {
        setResp(false)
        setId("LIMIT")
      } else {
        setResp(false)
      }
    // }).catch((err) => {
    //   setRocket(true);
    // })

  };



  const Verify = async () => {
    setResp(true);
    const otp = data.otp1 + data.otp2 + data.otp3 + data.otp4;
    var otpId = id ? id : sessionStorage.getItem('otp-id')
    let decVerifyOtp = await apiInstance.post(`verify-otp`, {
      "otpId": otpId,
      "otp": otp,
      "mobile": sessionStorage.getItem("phone") ? sessionStorage.getItem("phone") : "",
      "login_from": 4,
      "deviceId": "",
      "browser": getBrowser(),
      "OS": getOS(),
      "IP": ip,
      "device_type": device_id,
      "customer_id": credentialState.cust_id,
      "lead_id": mw_application_id,
      "verification_source": verification_source,
      "verified_by": verified_by,
      "verifier_name": verifier_name,
      "source": source,
      "financial_protection": flag
    }, {
      "token": "",
      "log": 1
    })
    // .then((res) => {
      if (decVerifyOtp.status === "1" && decVerifyOtp.status_code === "200") {
        gettingerror('esign verified web',mw_application_id)
        if (decVerifyOtp.data.redirect_url && decVerifyOtp.data.redirect_url !== "") {
          window.location.href = decVerifyOtp.data.redirect_url;
          return;
        } else {
          const qry = encryptOnlyLeadId(mw_application_id);
          RelocateUTMCheck(`${react_base_url}emandate-setup/?fid=${qry}`)
        }
      } else if (decVerifyOtp.status === "0" && decVerifyOtp.status_code === "429") {
        setFirstSubmit(true);
        setResp(false)
      } else {
        setNewErr(true)
      }
    // }).catch((err) => {
    //   setRocket(true);
    // })
  };

  useEffect(() => {
    if (data.otp1) {
      setFocus('otp2')
    }
    if (data.otp1 && data.otp2) {
      setFocus('otp3')
    }
    if (data.otp1 && data.otp2 && data.otp3) {
      setFocus('otp4')
    }
    if (data.otp1 && data.otp2 && data.otp3 && data.otp4) {
      Verify();
    }
  }, [data.otp1, data.otp2, data.otp3, data.otp4]);

  const onSubmit = async () => {
    Verify()
  };

  const handleChange = (nextLocation, prevLocation, event, value) => {
    setValue(value, event.target.value);
  }

  const handleKeyDown = (event, prevLocation) => {
    const key = event.keyCode || event.charCode;
    if (key == 8 || key == 46) {
      if (event.target.value.length === 0) {
        document.getElementById(prevLocation).focus();
      }
    }
  }

  // const handleKeyPress = (event) => {
  //   if (!/[0-9]/.test(event.key)) {
  //     event.preventDefault();
  //   }
  // }



  const handleClick = (e) => {
    if (firstSubmit) {
      reset()
      setFirstSubmit(false)
      document.getElementById("first-input").focus()
    }
  }
  useEffect(() => {
    timer > 0 &&
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
  }, [timer]);

  const handleotp = async () => {
    sessionStorage.setItem("otp_count", "0");
    setNewErr(false)
    GetOTP(sessionStorage.getItem("phone"), credentialState.cust_id)
    setShow(false)
  };


  const RetryCase = () => {
    sessionStorage.clear()
    setNewErr(false)
    setResp(false)
    SendCustomerId()
  }

  const handleError = (e) => {
    window.location.reload();
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      {newErr && <NewErrorCase handleClick={RetryCase} />}
      {loader ? <CKYCWaiting heading={"We are fetching your details to initiate eSigning of Agreement"} /> :
        <div className="loaderbackground">
          <div className="loadertransparent">
            <form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              autoCorrect="false"
            >
              <div className="esign-cont">
                <p className="esign-p1">Digitally eSign your agreement</p>
                <p className="esign-span">
                  Please enter the OTP as sent on
                  <span style={{ color: "#4D3ECC" }}> +91-{sessionStorage.getItem("phone") ? sessionStorage.getItem("phone") : ""}</span>
                  {/* <span style={{ color: "#4D3ECC" }}> +91-{Phone}</span> */}
                </p>
                <div className="enter-otp ">
                  <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                    <input maxLength={1} readOnly={resp || id === "LIMIT"} id="first-input" type="tel" {...register('otp1', { required: true })} onKeyPress={(event) => handleKeyPress(event)} onChange={(event) => handleChange("second-input", "first-input", event, "otp1")} onKeyDown={(event) => handleKeyDown(event, "first-input")} onClick={(e) => handleClick(e)} autoFocus />
                  </div>
                  <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                    <input maxLength={1} readOnly={resp || id === "LIMIT"} id="second-input" type="tel" {...register('otp2', { required: true })} onKeyPress={(event) => handleKeyPress(event)} onChange={(event) => handleChange("third-input", "first-input", event, "otp2")} onKeyDown={(event) => handleKeyDown(event, "first-input")} onClick={(e) => handleClick(e)} />
                  </div>
                  <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                    <input maxLength={1} readOnly={resp || id === "LIMIT"} id="third-input" type="tel" {...register('otp3', { required: true })} onKeyPress={(event) => handleKeyPress(event)} onChange={(event) => handleChange("forth-input", "second-input", event, "otp3")} onKeyDown={(event) => handleKeyDown(event, "second-input")} onClick={(e) => handleClick(e)} />
                  </div>
                  <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                    <input maxLength={1} readOnly={resp || id === "LIMIT"} id="forth-input" type="tel" {...register('otp4', { required: true })} onKeyPress={(event) => handleKeyPress(event)} onChange={(event) => handleChange("forth-input", "third-input", event, "otp4")} onKeyDown={(event) => handleKeyDown(event, "third-input")} onClick={(e) => handleClick(e)} />
                  </div>
                </div>
                {id !== "LIMIT" ? <>{firstSubmit ? <div className="otp-timer-slot err">
                  <span >Invalid OTP </span>
                </div> : show && <div className="otp-timer-slot">
                  {timer !== 0 && <span>RESEND OTP : </span>}
                  {timer !== 0 ? <span className="resend-option" >{timer} Seconds</span>
                    : <span className="resend-option pointer-cur" onClick={handleotp}>RESEND OTP</span>}
                </div>}</>
                  : <div className="limit-over err-text">You have exhausted daily OTP limit. <br />
                    Please try after 24 hours.</div>}
                <div>
                  <button
                    id="last"
                    className={
                      " footer-btn Tmargin margin-b2" +
                      (data.otp1 &&
                        data.otp2 &&
                        data.otp3 &&
                        data.otp4 &&
                        !firstSubmit
                        ? " footer-btn-activate"
                        : "")
                    }
                  >
                    {!resp ? (
                      "Verify and Sign"
                    ) : (
                      <div className="continue-lottie">
                        <Lottie
                          loop
                          animationData={ctaAnimation}
                          play
                          className="ctn-loader"
                        />
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>}
    </>
  );
};

export default Esign;
