import React from 'react'
import { useRef } from 'react'
import useScrollSnap from "react-use-scroll-snap";

const AboutFullPageSlider = ({ givenData }) => {

    const content = ([...givenData])
    window.addEventListener("scroll", function () {
        let screenSize = window.screen.availHeight;
        let calcReduce20, calcReduce30, calcReduce40, calcReduce50, calcReduce60, calcReduce70, calcReduce80;

        if (screenSize < 600) {
            calcReduce20 = (screenSize * 20) / 100
            calcReduce30 = (screenSize * 30) / 100
            calcReduce40 = 0
            calcReduce50 = (screenSize * 50) / 100
            calcReduce60 = (screenSize * 60) / 100
            calcReduce70 = (screenSize * 70) / 100
            calcReduce80 = (screenSize * 80) / 100
        }
        else if (screenSize > 1000) {
            calcReduce20 = (screenSize * 20) / 100
            calcReduce30 = (screenSize * 30) / 100
            calcReduce40 = (screenSize * 40) / 100
            calcReduce50 = (screenSize * 50) / 100
            calcReduce60 = (screenSize * 60) / 100
            calcReduce70 = (screenSize * 70) / 100
            calcReduce80 = (screenSize * 80) / 100
        }
        else {
            calcReduce20 = (screenSize * 20) / 100
            calcReduce30 = (screenSize * 30) / 100
            calcReduce40 = (screenSize * 40) / 100
            calcReduce50 = (screenSize * 50) / 100
            calcReduce60 = (screenSize * 60) / 100
            calcReduce70 = (screenSize * 70) / 100
            calcReduce80 = (screenSize * 80) / 100
        }

        if (screenSize > 1000) {
            if ((window.scrollY > screenSize - 700) && (window.scrollY < ((2 * screenSize) - calcReduce20))) {
                let step1 = document.getElementById("step1")
                if (step1) {
                    step1.style.display = "block"
                    step1.style.visibility = "visible"
                    step1.style.animation = "moveFromTop 0.5s 1 0s";

                    let step2 = document.getElementById("step2")
                    step2.style.display = "none"
                    step2.style.visibility = "hidden"
                }

            }
            else if ((window.scrollY > ((2 * screenSize) - calcReduce20)) && (window.scrollY < ((3 * screenSize) - calcReduce40))) {
                let step1 = document.getElementById("step1")
                if (step1) {
                    step1.style.display = "none"
                    step1.style.visibility = "hidden"


                    let step2 = document.getElementById("step2")
                    step2.style.display = "block"
                    step2.style.visibility = "visible"
                    step2.style.animation = "moveFromTop 0.5s 1 0s";

                    let step3 = document.getElementById("step3")
                    step3.style.display = "none"
                    step3.style.visibility = "hidden"
                }

            }
            else if ((window.scrollY > ((3 * screenSize) - calcReduce40)) && (window.scrollY < ((4 * screenSize) - calcReduce60))) {
                let step2 = document.getElementById("step2")
                if (step2) {
                    step2.style.display = "none"
                    step2.style.visibility = "hidden"

                    let step3 = document.getElementById("step3")
                    step3.style.display = "block"
                    step3.style.visibility = "visible"
                    step3.style.animation = "moveFromTop 0.5s 1 0s";

                    let step4 = document.getElementById("step4")
                    step4.style.display = "none"
                    step4.style.visibility = "hidden"
                }
            }
            else if ((window.scrollY > ((4 * screenSize) - calcReduce60)) && (window.scrollY < ((5 * screenSize) - calcReduce80))) {
                let step3 = document.getElementById("step3")
                if (step3) {
                    step3.style.display = "none"
                    step3.style.visibility = "hidden"

                    let step4 = document.getElementById("step4")
                    step4.style.display = "block"
                    step4.style.visibility = "visible"
                    step4.style.animation = "moveFromTop 0.5s 1 0s";
                }
            }
        }
        else {
            if ((window.scrollY > screenSize - 200) && (window.scrollY < ((2 * screenSize) - calcReduce20))) {
                let step1 = document.getElementById("step1")
                if (step1) {
                    step1.style.display = "block"
                    step1.style.visibility = "visible"
                    step1.style.animation = "moveFromTop 0.5s 1 0s";

                    let step2 = document.getElementById("step2")
                    step2.style.display = "none"
                    step2.style.visibility = "hidden"
                }

            }
            else if ((window.scrollY > ((2 * screenSize) - calcReduce20)) && (window.scrollY < ((3 * screenSize) - calcReduce40))) {
                let step1 = document.getElementById("step1")
                if (step1) {
                    step1.style.display = "none"
                    step1.style.visibility = "hidden"


                    let step2 = document.getElementById("step2")
                    step2.style.display = "block"
                    step2.style.visibility = "visible"
                    step2.style.animation = "moveFromTop 0.5s 1 0s";

                    let step3 = document.getElementById("step3")
                    step3.style.display = "none"
                    step3.style.visibility = "hidden"
                }

            }
            else if ((window.scrollY > ((3 * screenSize) - calcReduce40)) && (window.scrollY < ((4 * screenSize) - calcReduce60))) {
                let step2 = document.getElementById("step2")
                if (step2) {
                    step2.style.display = "none"
                    step2.style.visibility = "hidden"

                    let step3 = document.getElementById("step3")
                    step3.style.display = "block"
                    step3.style.visibility = "visible"
                    step3.style.animation = "moveFromTop 0.5s 1 0s";

                    let step4 = document.getElementById("step4")
                    step4.style.display = "none"
                    step4.style.visibility = "hidden"
                }
            }
            else if ((window.scrollY > ((4 * screenSize) - calcReduce60)) && (window.scrollY < ((5 * screenSize) - calcReduce80))) {
                let step3 = document.getElementById("step3")
                if (step3) {
                    step3.style.display = "none"
                    step3.style.visibility = "hidden"

                    let step4 = document.getElementById("step4")
                    step4.style.display = "block"
                    step4.style.visibility = "visible"
                    step4.style.animation = "moveFromTop 0.5s 1 0s";
                }
            }
        }
    })

    const scrollRef = useRef(null);
    useScrollSnap({ ref: scrollRef, duration: 200, delay: 20 });
    return (
        <>
            <div className="fullslider-bg">
                <div className="fullpageslide-main">
                    <div className='left-fullpage-cont' ref={scrollRef} >
                        {content.map((row, ind) => {
                            return <div key={ind} className="Fullpage-App-header" >
                                {row.mainheading1 && <h2 className='about-heading'>{row.mainheading1} <span>{row.mainheading2}</span></h2>}
                                <div className="fullstep-heading">
                                    <span>{row.heading}</span>
                                    <p>{row.context}</p>
                                </div>
                                {row.list && <ul className='slider-list'>
                                    {row.list.map((item, idx) => {
                                        return <li key={idx} className="full-slider-list">{item}</li>
                                    })}
                                </ul>}
                                {row.url && <a className='slider-link' href={row.url}>Apply Now</a>}
                            </div>
                        })}
                    </div>
                    <div className='full-page-sticky'>
                        <div className="fullpage-contnt">
                            <div className="fullpageApp" >
                                {content.map((row, ind) => {
                                    return <header key={ind} className='fullsteps-main' id={`step${ind + 1}`} >
                                        <div className="fullpage-slides">
                                            <div className="full-right-img">
                                                <img src={row.img} alt='steps' />
                                            </div>
                                        </div>
                                    </header>
                                })}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AboutFullPageSlider;