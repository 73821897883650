import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateMonthOnly = ({ startDate, setStartDate,read }) => {
    //   const renderMonthContent = (month, shortMonth, longMonth) => {
    //     const tooltipText = `Tooltip for month: ${longMonth}`;
    //     return <span title={tooltipText}>{shortMonth}</span>;
    //   };
// const [startDate,setStartDate] = useState('');
const today = new Date();

    return (
        <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            placeholderText={"Click Here"}
            maxDate={today} 
            readOnly={read}
            onKeyDown={(e) => {
                e.preventDefault();
            }}
        />
    );
};

export default DateMonthOnly;
