import React from 'react';
import Slider from "react-slick";

const ConventionalVsMoneywide = ({ givenData }) => {

    const leftSliderData = [...givenData, ...givenData]

    var sliderblog = {
        infinite: true,
        autoplay: true,
        cssEase: 'ease',
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipe: false,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        responsive: []
    };
    return (
        <div className='conventional-vs-moneywide'>
            <div className="conventional-slider">
                <div className="left-slider">
                    <Slider {...sliderblog} >
                        {leftSliderData.map((row, ind) => {
                            return <div key={ind} className='conv-slider'>
                                <div className="left-slide">{row.left}</div>
                                <div className='just-img'>
                                    <div className="circle-back">
                                        <img className='slider-img' src={row.img} alt="na" />
                                        <span>{row.imgtext}</span>
                                    </div>
                                </div>
                                <div className="right-slide">{row.right}</div>
                            </div>
                        })}
                    </Slider>
                </div>

                

            </div>
        </div>
    )
}

export default ConventionalVsMoneywide;