import React from 'react';
import './mainloader.css';
import '../Loader/Loader.css'
import Lottie from 'react-lottie-player';
import lottieJson from "../assests/lootie-files/AA Loaders.json";


function MainLoader({ text }) {
    return (
        <div className='loaderbackground'>
            <div className='loadertransparent'>
                <div className='cont'>
                    <div>
                        <Lottie
                            loop
                            animationData={lottieJson}
                            play
                            className="main-loader-lottie"
                        />
                    </div>
                </div>
                <div className='loader-text-main'>
                    <p >{text}</p>
                </div>
            </div>
        </div>

    )
}

export default MainLoader