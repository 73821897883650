import React, { useEffect, useState } from 'react';
import './NewHeader.css';
import logo from '../../Icons/new_moneywide_logo.svg';
import downArr from '../../Icons/DownArr.svg';
import nav_icon from '../../Icons/nav_icon.svg';
import { BagIcon, BlogIcon, BuildingIcon, CalculatorIcon, FAQIcon, Infographics, PlayStoreIcon } from '../../Icons/CodeIcons/codeicons';
import cut from '../../Icons/cut.svg';
import { react_base_url } from '../Secret_Credentials/Secret_Credential';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppStatus, updateMakePayment, updateserviceshow } from '../../Redux/action';
import queryString from "query-string";
import { useSearchParams } from 'react-router-dom';
import { prodbase_url, verified_by, verifier_name, verification_source, source } from '../Secret_Credentials/Secret_Credential';
import { AAAPIInstance, apiInstance } from '../Config/APIConfig';

const NewHeader = () => {
    const [isLogin, setIsLogin] = React.useState(false)
    const [blogUrl, setBlogUrl] = useState(false)
    const [toggle, setToggle] = React.useState(false)
    const [mainMenu, setMainMenu] = React.useState(false)
    const [visible, setVisibile] = React.useState(false)
    const [sizeUp, setSizeUp] = React.useState(false)
    const [currentPage, setCurrentPage] = React.useState("")
    const [pageStatus, setPageStatus] = React.useState("")
    const [userName, setUserName] = React.useState("")
    const [removeLink, setRemoveLink] = React.useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [redirect, setRedirect] = useState(false);
    const { token,setService } = useSelector(state => state)
    const [count, setCount] = useState(0)
    const [err, setErr] = useState(false)
    const [headerData, setHeaderData] = useState([])
    const [openSubMenu, setOpenSubMenu] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [submenuId, setSubmenuId] = useState("");
    const s = searchParams.get("s");
    const dispatch = useDispatch();
    const { makePayment, myaccheaderdata } = useSelector(state => state)
    const [openSection, setOpenSection] = React.useState({
        loans: true,
        plLoans: false,
        resources: false,
        company: false,
        calc: false
    })
    const existed = sessionStorage.getItem('existed')
    const appURl = `https://play.google.com/store/apps/details?id=com.app.mlcfinotech`
    const handleName = () => {
        var na = sessionStorage.getItem("userName");
        if (na !== undefined && na !== null && na !== "") {
            setUserName(na)
        }
    }
    const currentPageURL = window.location.pathname;

    window.addEventListener("scroll", (event) => {
        if (window.scrollY >= 20) {
            setSizeUp(true);
        }
        else {
            setSizeUp(false);
        }
    });


    React.useEffect(() => {
        var loginedOrNot = sessionStorage.getItem("isLogin");
        // console.log(loginedOrNot)
        setTimeout(() => {
            handleName()
        }, [1000])
        if (loginedOrNot == 1) {
            setIsLogin(true)
        } else {
            setIsLogin(false)
        }
        if (s) {
            setRedirect(true);
        }
    }, [])

    React.useEffect(() => {
        let cur_url = window.location.href;
        if ((cur_url.includes("utm_medium") || (cur_url.includes("utm_source"))) && (!cur_url.includes("blog"))) {
            setVisibile(true);
            setRemoveLink(true);
            setRedirect(true)
        }
        if (window.location.href.includes('blog')) {
            setBlogUrl(true)
        }
        if (window.location.href === `${react_base_url}`) {
            setIsLogin(false)
            setCurrentPage(1)
        }
        if (cur_url.includes(`${react_base_url}personal-loan`)) {
            setCurrentPage(2)
        }
        if (cur_url.includes(`${react_base_url}service-request`)) {
            setCurrentPage(3)
        }
        if (cur_url.includes(`${react_base_url}contact-us`)) {
            setCurrentPage(4)
        }
        if (cur_url.includes(`login`)) {
            setVisibile(true)
        }
        if (cur_url.includes("app")) {
            let qry = queryString.parse(window.location.search);
            qry = qry.app;
            if (qry === "MQ==") {
                dispatch(updateAppStatus(true));
            }
        }
        if (cur_url.includes(`myaccount`)) {
            if(cur_url.includes(`cust_id`)){
                setVisibile(true)
            }else{
                setVisibile(false)
            }
        }
        if (cur_url.includes(`profile`)) {
            setPageStatus(1)
        } else if (cur_url.includes(`myloan`)) {
            setPageStatus(2)
        } else if (cur_url.includes(`track-application`)) {
            setPageStatus(3)
        } else if (cur_url.includes(`setting`)) {
            setPageStatus(4)
        } else {
            setPageStatus(0)
        }

    }, [])

    React.useEffect(() => {
        if (removeLink) {
            document.querySelector(".logo-header").removeAttribute("href");

        }
    }, [removeLink])

    const HandleHeaderApi = async () => {
        // setCount(1)
        let obj = {
            url: "api/fetch-webheader-detail",
            data: {
                verified_by: verified_by,
            verifier_name: verifier_name,
            verification_source: verification_source,
            source: source,
            page_url:window.location.href
            },
            token: token
        }
        let dec = await AAAPIInstance(obj)
        if (dec.status === "1" && dec.status_code === "200") {
            // console.log(dec)
            // setCount(1)
            // setErr(false)
            setHeaderData(dec.data.fetch_header_data)
            if(dec.data['chatbot_flag'] == 1){
                dispatch(updateserviceshow({ ...setService, showchatbot:true }));
            }
           
        }
    }
    useEffect(() => {
        HandleHeaderApi()
    }, [])


    const handleMobileClick = (field) => {
        if (field === "loans") {
            if (openSection.loans) {
                setOpenSection({ ...openSection, loans: false, plLoans: false })
            } else {
                setOpenSection({ ...openSection, loans: true, resources: false, company: false, calc: false })
            }
        } else if (field === "plLoans") {
            setOpenSection({ ...openSection, plLoans: !openSection.plLoans })
        } else if (field === "resources") {
            if (openSection.resources) {
                setOpenSection({ ...openSection, resources: false, company: false, calc: false })
            } else {
                setOpenSection({ ...openSection, resources: true, loans: false, plLoans: false })
            }
        } else if (field === "company") {
            setOpenSection({ ...openSection, company: !openSection.company, calc: false })
        } else if (field === "calc") {
            setOpenSection({ ...openSection, calc: !openSection.calc, company: false })
        }
    }

    const handleLogout = () => {
        sessionStorage.clear();
        // if(!removeLink) {
        //     window.location.href = `${react_base_url + window.location.search}`;
        // }
    }

    const getApp = () => {
        let a = document.createElement('a')
        a.href = appURl
        a.target = "_blank"
        a.click();
    }

    // const openMakePayment = (e, id) => {
    //     e.stopPropagation();
    
    //     let subele = document.getElementsByClassName("submenu-second-level");
    //     for (let i = 0; i < subele.length; i++) {
    //         subele[i].style.display = "none";
    //     }
    
    //     let ele = document.getElementsByClassName("submenu");
    //     for (let i = 0; i < ele.length; i++) {
    //         if (i != id) {
    //             ele[i].style.display = "none";
    //         }
    //     }
    
    //     // Toggle the clicked element
    //     if (id == "26") {
    //         dispatch(updateMakePayment({ ...makePayment, show: true }));
    //     } else if (ele[id]) {
    //         ele[id].style.display = ele[id].style.display === "block" ? "none" : "block";
    //     }
    // }
    
    // const openSecondMenu = (e, id) => {
    //     e.stopPropagation();
    
    //     let subele = document.getElementsByClassName("submenu-second-level");
    
    //     for (let i = 0; i < subele.length; i++) {
    //         subele[i].style.display = "none";
    //     }
    
    //     // Toggle the clicked element
    //     if (id == "13") {
    //         // For company
    //         if (subele[4]) {
    //             subele[4].style.display = subele[4].style.display === "block" ? "none" : "block";
    //         }
    //     } else if (id == "16") {
    //         // For Calculator
    //         if (subele[5]) {
    //             subele[5].style.display = subele[5].style.display === "block" ? "none" : "block";
    //         }
    //     } else if (id == "5") {
    //         // Personal loan second menu
    //         if (subele[3]) {
    //             subele[3].style.display = subele[3].style.display === "block" ? "none" : "block";
    //         }
    //     }
    // }
    // const toggleElement = (element) => {
    //     const style = window.getComputedStyle(element);
    //     const display = style.getPropertyValue('display');
        
    //     if (display === 'block') {
    //         element.style.display = 'none';
    //     } else {
    //         element.style.display = 'block';
    //     }
    // }
    
    // const openMakePayment = (e, id) => {
    //     e.stopPropagation();
    
    //     const ele = document.getElementsByClassName("submenu");
    
    //     for (let i = 0; i < ele.length; i++) {
    //         if (i != id) {
    //             ele[i].style.display = 'none';
    //         }
    //     }
    
    //     if (id == "26") {
    //         dispatch(updateMakePayment({ ...makePayment, show: true }));
    //     } else {
    //         toggleElement(ele[id]);
    //     }
    // }
    
    // const openSecondMenu = (e, id) => {
    //     e.stopPropagation();
    
    //     const subele = document.getElementsByClassName("submenu-second-level");
    
    //     if (id == "13") {
    //         toggleElement(subele[4]);
    //     } else if (id == "16") {
    //         toggleElement(subele[5]);
    //     } else if (id == "5") {
    //         toggleElement(subele[3]);
    //     }
    // }
    const openMakePayment = (e,id) => {
        e.stopPropagation()
        if(id == "26"){
            dispatch(updateMakePayment({ ...makePayment, show: true }))
        }
        
        if(id == "3"){
            let ele = document.getElementsByClassName("submenu")
            if(ele[3]){
                if(ele[3].style.display=="block"){
                    ele[3].style.display="none"
                }
                else{
                    ele[3].style.display="block"
                    
                }
                // console.log(ele[3].style.display)
            }
        }
        
    }
    const openSecondMenu = (e,id)=>{
        e.stopPropagation()
        if(id=="13"){
            let subele = document.getElementsByClassName("submenu-second-level")
            if(subele[4]){
            if(subele[4].style.display=="block"){
                subele[4].style.display="none" 
            }
            else{
                subele[4].style.display="block"
            }
           
            }
        }
        if(id=="16"){
            let subele2 = document.getElementsByClassName("submenu-second-level")
           if(subele2[5]){
            if(subele2[5].style.display=="block"){
                subele2[5].style.display="none"
            }
            else{
                subele2[5].style.display="block"
            }
           }
        }
        if(id=="5"){
            let ele1 = document.getElementsByClassName("submenu-second-level")
            if(ele1[3]){
                if(ele1[3].style.display=="block"){
                    ele1[3].style.display="none"
                }
                else{
                    ele1[3].style.display="block"
                }
            }
        }
        
    }
    // Function to toggle the display of elements with a given class
    
    const MobileMainMenu = () => {
        return <>
            {!isLogin ? <ul>
                {renderMenu(headerData)}
            </ul> : <div className='mobile-login-menu'><ul><li style={{ padding: "5px 10px" }} onClick={() => MenuClick()}>
                {isMenuOpen ? <div className="main-backbtn">
                    <img src={downArr} className="rotateLeft" alt="NA" />
                    Back To Profile
                </div> : <span className='pl-span'>
                    Main Menu <img src={downArr} className="rotateRight" alt="NA" />
                </span>}
                {isMenuOpen && <ul>
                    {renderMenu(headerData)}
                </ul>}

            </li>{!isMenuOpen && myaccheaderdata.data.map((el, index) => (<li key={index} onClick={() => setToggle(false)} className={currentPageURL === el.link ? "act-my-header" : ""} ><a href={el.link} target={el.target === 1 ? "_blank" : ""} >{el.header_name}</a></li>))}</ul></div>}
            <div className="header-bottom-content">
                <span>Need assistance?</span>
                <p>Our support team is available from <b>Monday to Friday- 10 AM to 6:30 PM & Saturday - 10 AM to 5:00 PM</b> to assist you with the best offers, or help resolve any queries. You can also submit your query online here.
                    <a href={redirect ? `/service-request/${window.location.search}` : `/service-request`}> Submit query</a>
                </p>
            </div>
        </>
    }

    const handleBlurClick = (event) => {
        event.stopPropagation()
        setToggle(false)
    }

    const MenuClick = () => {
        setMainMenu(!mainMenu)
        setIsMenuOpen(!isMenuOpen)
        // {renderMenu(headerData)}
        // console.log(headerData)
    }
    const SubMenu = ({ subMenu, depth }) => {
        if (!subMenu || subMenu.length === 0) {
            return null;
        }

        const submenuClass = depth === 2 ? "submenu-second-level" : "submenu onemenu";

        return (
            <ul className={submenuClass}>
                {subMenu.map((item) => (
                    <li key={item.id} onClick={(e) => openSecondMenu(e, item.id)}>
                        <p>{item.logo && <img src={item.logo} alt="Logo" />}
                            <a href={item.link && (redirect ? react_base_url + item.link + `${window.location.search}`: react_base_url + item.link)} >{item.header}</a>
                            {item.arrow_img && <img src={item.arrow_img} alt="Arrow" />}
                        </p>
                        <SubMenu subMenu={item.sub_menu} depth={depth + 1} />
                    </li>
                ))}
            </ul>
        );
    };

    const renderMenu = () => {
        return (
            <ul className="menu">
                {headerData.map((item) => (

                    isLogin && item.id == "26" ? null : <li
                        key={item.id}
                        className={`menu-item-${item.id} ${item.sub_menu.length > 0 ? "header-dropdown" : ""
                            } ${item.id === 29 || item.id === 30 ? `special-menu-item-${item.id}` : ((item.link != null && window.location.href == (redirect ? `${react_base_url}${item.link}${window.location.search}` : `${react_base_url}${item.link}`) ? "active-header-menu" : ''))}`}
                        onClick={(e) => openMakePayment(e, item.id)}
                        id={`menu-${item.id}`}
                    >
                        {/* {!isLogin ? (item.id === "26")  : <span>hello</span>} */}
                        {
                            <a href={item.link != null ? item.id === 30 ? item.link : (redirect ? react_base_url + item.link + `${window.location.search}` : react_base_url + item.link) : null} target={item.target}>
                            {item.logo && <img src={item.logo} alt="Logo" />}
                            {item.id === 29 && isLogin ? "Logout" : item.header}
                            {item.arrow_img && <img src={item.arrow_img} alt="Arrow" />}
                          </a>
                        }
                        <SubMenu subMenu={item.sub_menu} depth={1} />
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <section >
            <div className={"desktop-header-section" + (!blogUrl && sizeUp ? " shadow-give" : "")}>
                <div className="header-content">
                    <div className="header-left-content">
                        <a href={redirect ? `/${window.location.search}` : `/`} className="logo-header" onClick={() => handleLogout()}><img className="header-logo" src={logo} alt="logo" /></a>
                    </div>
                    {!visible && <div className='header-right-content'>
                        {renderMenu(headerData)}
                    </div>}
                </div>
            </div>


            {/* ---------------------------------Mobile Regular Header-------------------------------- */}
            {!(visible) && <div className={"mobile-header-section" + (!blogUrl && sizeUp ? " shadow-give" : "")}>
                <div className="header-content">
                    <div className="header-left-content">
                        {toggle ?
                            <img src={cut} className='nav-icon' onClick={() => setToggle(!toggle)} alt="NA" /> :
                            <img className='nav-icon' onClick={() => setToggle(!toggle)} src={nav_icon} alt="NA" />
                        }
                        <HLogo handleLogout={handleLogout} />
                    </div>
                    {!isLogin ? <div className="header-right-content">
                        <ul>
                            <li>
                                <a href="/login" className='header-login-btn'>Login</a>
                            </li>
                        </ul>
                    </div> : <div className="header-right-content">
                        <ul>
                            <li>
                                <a href="/login" className='header-login-btn'>Logout</a>
                            </li>
                        </ul>
                    </div>}

                </div>
                {toggle && <div className="header-blur-background" onClick={(e) => handleBlurClick(e)}>
                    <div className="open-mobile-header " onClick={(e) => e.stopPropagation()}>
                        <div className="mobile-header-content">
                            <MobileMainMenu />
                        </div>
                    </div>
                </div>}
            </div>}

            {/* --------------------My Account Mobile Header-------------- */}

        </section>
    )
}

export default NewHeader;

const HLogo = ({ handleLogout }) => {
    return <a onClick={() => handleLogout()} style={{ display: "flex" }}><img className='header-logo' src={logo} alt="logo" /></a>
}