import React, { useState } from 'react';
import './MyAccount.css';
import NoLoan from '../../Icons/NoLoan.svg';
import logo from '../../Icons/moneywide-logo.svg';
// import transaction from '../../Icons/transaction.svg';
// import accountstatement from '../../Icons/accountstatement.svg';
// import moneyloan from '../../Icons/moneyloan.svg';
// import searchpay from '../../Icons/searchpay.svg';
// import redmoney from '../../Icons/redmoney.svg';
import { useEffect } from 'react';
import { HeaderApi, MYLoanAPI, SaveLoginData, getLoginData } from './MyAccountAPIs';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../EverCalledFunctions/EverCalledApis';
import { GenerateToken } from '../../Redux/action';
import { useNavigate } from 'react-router-dom';
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';
import { gettingerror } from '../EverCalledFunctions/EverCalledFunctions';
import { react_base_url } from '../Secret_Credentials/Secret_Credential';

const MyLoan = ({ setUserName }) => {
    const [loader, setLoader] = useState(true)
    const [LoanData, setLoanData] = useState({})
    const [noData, setNodata] = useState(false)
    const [APIErr, setAPIErr] = useState(false)
    const [openSection, setOpenSection] = useState(0)
    const [headerdata, Setheaderdata] = useState([])
        const { token } = useSelector(state => state)
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const openCloseMYLoan = (ind) => {
        if (openSection === ind) {
            setOpenSection("")
        } else {
            setOpenSection(ind)
        }
    }

    const getLoanAPIData = async (num) => {
        const APILoanResponse = await MYLoanAPI(num, token);
        if (APILoanResponse.status_code === "200" && APILoanResponse.status === "1") {
            if (APILoanResponse.data.loanAccounts[0] && APILoanResponse.data.loanAccounts[0].customer_name) {
                let preData = getLoginData("NO");
                preData = { ...preData, userName: APILoanResponse.data.loanAccounts[0].customer_name }
                SaveLoginData(preData)
                setUserName(APILoanResponse.data.loanAccounts[0].customer_name)
            }
            var filteredData = []
                        if (APILoanResponse.data.loanAccounts) {
                                    filteredData = APILoanResponse.data.loanAccounts.filter((row) => {
                        return checkConditions(row);
                    })
                }
            if (filteredData.length === 0) {
                setNodata(true)
            } else {
                setLoanData(filteredData)
            }
            setLoader(false)
        }
        else if (APILoanResponse.status_code === "429" && APILoanResponse.status === "0") {
            gettingerror('error in fetch loan api web', num)
            setLoader(false)
            setNodata(true)
            setLoanData([])
        } else {
            setAPIErr(true)
            setLoader(false)
        }
    }

    const myloansdata = async (data) => {
        const APIResponse = await HeaderApi(1, token)
        if (APIResponse.status == "1" && APIResponse.status_code == "200") {
            Setheaderdata(APIResponse.data)
            getLoanAPIData(data)
        } else if (APIResponse.status_code == "429" && APIResponse.status == "0") {
            window.location.href = `${react_base_url}login`
            sessionStorage.clear()

        } else {
            setAPIErr(true)
            setLoader(false)
        }
    }

    useEffect(() => {
        // if (token !== "") {
        let payLoad = getLoginData();
        if (!payLoad && !payLoad.userNo) {
            navigate('/login')
        } else if (payLoad.userNo != undefined || payLoad.userNo !== "") {
            myloansdata(payLoad.userNo)
        }
        // }
    }, [])


    const myToken = async () => {
        const T = await getToken();
        dispatch(GenerateToken(T))
    }

    useEffect(() => {
        if (token === "") {
            myToken()
        }
    }, [])


    const SaveSessionData = (data) => {
        let payLoad = getLoginData("NO")
        payLoad = { ...payLoad, mw_app_id: data.mw_application_id, lead_source_flag: data.lead_source_flag == 1 ? 1 : 0 }
        SaveLoginData(payLoad)
    }

    const checkConditions = (row) => {
        if ((row.status.rejected || row.status.rejected === 1)
            && (
                (!row.status.active && row.status.active === 0 || row.status.active === "")
                || (!row.status.closed && row.status.closed === 0 || row.status.closed === "")
                || (!row.status.pendingApproval && row.status.pendingApproval === 0 || row.status.pendingApproval === "")
            )
        ) {
            return false;
        }
        if (row.mw_application_id === 0) {
            return false;
        }
        return true;
    }
    const handleBack = () => {
        setAPIErr(false)
        setLoader(true)
        if (window && window.sessionStorage) {
            let payLoad = getLoginData();
            if (!payLoad && !payLoad.userNo) {
                navigate('/login')
            } else if (payLoad.userNo != undefined || payLoad.userNo !== "") {
                getLoanAPIData(payLoad.userNo)
            }
        } else {
            navigate('/login')
        }
    }
    return (
        <>
            {APIErr && <NewErrorCase handleClick={handleBack} />}
            {loader && <CKYCWaiting />}
            <h3 className='myAccountHeading'>My Loan/ HealthCare Credit Facility</h3>
            {noData && !APIErr && !loader && <div className="myLoanBox">
                <div>
                    <img src={NoLoan} alt="" />
                </div>
                <p>You don’t have any active loan/Healthcare credit yet</p>
            </div>}
            {!loader && !APIErr && !noData && LoanData.length !== 0 && <div className='looping-class'>
                {LoanData.map((row, index) => {
                    return <div key={index} className='mainContent-box myloancontent-box'>
                        <div className="border-box myloan-borderbox">
                            <div className="track-row myloan-track">
                                <div className="content-area">
                                    <img src={logo} alt="" />
                                    <div className="contentData contentData-mob">{row.programName ? row.programName : "-"}</div>
                                </div>
                                <div className="content-area myc-mobile">
                                    {row.status.active ?
                                        <div className="contentData green-btn">Active</div> :
                                        row.status.closed ?
                                            <div className="contentData grey-btn">Closed</div> :
                                            <div className="contentData yellow-btn">In Process</div>}
                                </div>
                                <div className="content-area content-area-mob">
                                    <div className="contentHead">Program Name</div>
                                    <div className="contentData">{row.programName ? row.programName : "-"}</div>
                                </div>
                                <div className="content-area">
                                    <div className="contentHead">{((row.lead_source_flag == 1) ? "" : "Loan ")+"Account Number"}</div>
                                    <div className="contentData">{row.accountNo ? "************" + row.accountNo.slice(row.accountNo.length - 4) : "-"}</div>
                                </div>
                                <div className="content-area">
                                    <div className="contentHead">Outstanding Amount</div>
                                    <div className="contentData">Rs. {row.loanBalance ? row.loanBalance : '-'}</div>
                                </div>
                                <div className="content-area myc-desktop">
                                    <div className="contentHead">Status</div>
                                    {row.status.active ?
                                        <div className="contentData green-btn">Active</div> :
                                        row.status.closed ?
                                            <div className="contentData grey-btn">Closed</div> :
                                            <div className="contentData yellow-btn">In Process</div>}
                                </div>
                            </div>
                            {(row?.fundingPartnerId && [32].includes(row?.fundingPartnerId)) ? 
                            <div className="show-more-main hdb-case">
                                    <h3>Dear Valued Customer,</h3>
                                    <p>Kindly contact <a href="https://www.hdbfs.com/customer-services/customer-support" target="_blank">HDB Customer Care</a> for your upcoming payments. You can directly contact with them.</p>
                            </div>:
                            <div className="show-more-main">
                                <div className={"show-more-btn" + (openSection === index ? " activateBox" : "")} onClick={() => openCloseMYLoan(index)}>
                                    <span>{"" + (openSection === index ? "Show Less" : "Show More")}</span>
                                    <div style={{ display: "flex", alignItems: "center", gap: "40px" }}><i className={"arrow " + (openSection === index ? "up" : "down")} style={!openSection === index ? { marginTop: "-6px" } : {}} ></i></div></div>
                                {openSection === index &&
                                    <div className='myloanshowmore'>
                                        {headerdata.map((el, index) => {
                                            return (el.header_id != 13 ? <a key={index} onClick={() => SaveSessionData(row)} className='myloanboxes' href={el.link} >
                                                <img src={`https://blog.moneywide.com/assets/images/blog/${el.image}`} alt={el.header_name} />
                                                <span>{el.header_name}</span>
                                            </a> :
                                                row.checkOverdue !== 0 && <a key={index} onClick={() => SaveSessionData(row)} className='myloanboxes myloanredbox' href={el.link} >
                                                    <img src={`https://blog.moneywide.com/assets/images/blog/${el.image}`} alt={el.header_name} />
                                                    <span>{el.header_name}</span>
                                                </a>)
                                        })}
                                    </div>}

                            </div>}
                        </div>
                    </div>
                })}
            </div>}
            

        </>
    )
}

export default MyLoan;
