import React, { useState, useEffect } from 'react'
import './LoanDetails.css'
import alert from "../../Icons/alert.svg"
import Lottie from 'react-lottie-player';
import ctaAnimation from '../assests/lootie-files/continue.json';
import { getOverdueLink } from './MyAccountAPIs';
import { useDispatch, useSelector } from 'react-redux'
import { GenerateToken } from '../../Redux/action'
import { getToken } from '../EverCalledFunctions/EverCalledApis'

const OverDueAlert = ({ data, name, mw_app_id, setCurrentPage, baseData }) => {
    const [resp, setResp] = useState(false)
    const dispatch = useDispatch()
    const { token } = useSelector(state => state)




    const myToken = async () => {
        const T = await getToken();
        dispatch(GenerateToken(T))
    }

    useEffect(() => {
        if (token === "") {
            myToken()
        }
    }, [])
    const CallNextAPI = async () => {
        setResp(true)
        const APIResponse = await getOverdueLink(mw_app_id, data.amount, token)
        if (APIResponse.status_code === "200" && APIResponse.status === "1") {
            if (APIResponse.data.changes.paymentLink !== "") {
                var link = document.createElement('a');
                link.href = APIResponse.data.changes.paymentLink;
                document.body.appendChild(link);
                link.target = "_blank";
                link.style.zIndex = "-1";
                link.click();
                document.body.removeChild(link);
                setCurrentPage(2)
            } else {
                setCurrentPage(3)
            }
        } else if (APIResponse.status_code === "429" && APIResponse.status === "0") {
            setCurrentPage(3)
        }
    }

    const PartialPaymentFxn = () => {
        var win = window.open(baseData.partial_link, '_blank');
        win.focus();
        setCurrentPage(2)
    }


    return (
        <div className="overdue-payment-box">
            <div className="od-main">
                <div className="od-head">
                    <span>Overdue Payment</span>
                </div>
                <div className='od-alert'>
                    <img src={alert} alt="alert" />
                </div>
                <div className="od-name">
                    <span>Dear {name}</span>
                </div>
                <div className="od-money">
                    <span>₹ {data?.amount?.toLocaleString('en-IN')}</span>
                </div>
                <div className='od-content'>
                    <p>is overdue on your <b>{baseData.program} {baseData.Id}</b>. Please pay the overdue amount now as it might affect your credit score in the future.</p>
                </div>
                {baseData.partial_payment_link != "" && baseData.partial_flag == 1 && <div className={"account-state-button od-button partial"}>
                    <button type="submit" disabled={resp} onClick={() => PartialPaymentFxn()} className={"footer-btn od-tn-footer activate"}>Pay Partial Amount</button>
                </div>}

                <div className={'account-state-button od-button' + (baseData.partial_payment_link != "" && baseData.partial_flag == 1 ? " partial1" : "")}>
                    <button type="submit" disabled={resp} onClick={() => CallNextAPI()} className={"footer-btn od-tn-footer activate"}>
                        {!resp ?
                            (baseData.partial_payment_link != "" && baseData.partial_flag == 1 ? "Pay Full Amount" : "Pay Now") :
                            <div className="continue-lottie">
                                <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                            </div>}
                    </button>
                </div>
            </div>

        </div>
    )
}

export default OverDueAlert