import React, { useEffect } from "react";
import "./EmbeddedOfferings.css";
import RightSidePopUp from "../Right Side PopUp/RightSidePopUp";
import banner from "../../Icons/banner.svg"
import { Link } from "react-router-dom";
import greenticknew from '../../Icons/greenticknew.svg';
import healthtech from "../../Icons/healthtech.png"
import edtech from "../../Icons/edtech.png";
import insurance from "../../Icons/insurance.png";
import travel from "../../Icons/travel.png";
import call from "../../Icons/call.png";
import address from "../../Icons/address.png";
import chooseus from "../../Icons/choose-us.svg"
import EmbeddedSlider from "./EmbeddedSlider";
import EmbeddedMobileSlider from "./EmbeddedSliderMobile";

const EmbeddedOfferings = () => {
  const [y, sety] = React.useState(true);
  window.addEventListener("scroll", (event) => {
    if (window.scrollY >= 3800) {
      sety(false);
    } else {
      sety(true);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ScrollToForm = () => {
    var formFocus = document.getElementById('contact-div');
    if (formFocus) {
        formFocus.scrollIntoView(
            {
                behavior: "smooth",
                block: "nearest"
            })
    }
}


  return (
    <>
      {y && <RightSidePopUp />}
      <div className="main-start-div" style={{ backgroundColor: "#f9fafe", marginTop: "0px" }}>
        <div className="contact-bann">
        <div className="left-top embed-top-link">
              <Link to={`/${window.location.search}`}>Home</Link>  &gt;{" "}
              <span>Embedded Offerings</span>
            </div>
          <div className="contact-homebanner embedhome-banner">
            <div className="contact-left-content">
              <h1><span style={{color:"#4D3ECC"}}>Empowering</span> Businesses with Embedded Finance</h1>
              <hr />
              <div className="contact-home-words">
                <p>Unlock Instant Credit Solutions Anywhere</p>
              </div>
               <div className="contact-service-embedded" onClick={() => ScrollToForm()}>
              <a>Contact Us</a> 
                            </div>
            </div>
            <div className="contact-right-content">
              <img
                src={banner}
                alt=""
              />
            </div>
          </div>
          <div className='journey-stop embedded'>
            <div className='manage-top-margin web-margin-top'>
              <div className="main-div-embedded">
                <div className="heading-embedded">MoneyWide is a 100% Reliable Partner for all Merchants </div>
                <p className="heading-p">MoneyWide is a part of the PB Fintech Group that runs India’s leading marketplaces, Policybazaar and Paisabazaar.We offer fully curated and customizable end-to-end ready to integrate API stack for all partners to embed our financing solutions in their user flow. These solutions are highly differentiated, flexible and convenient for the digital merchants in strict compliance with RBI’s Digital Lending Guidelines. What sets us apart is our commitment to providing paperless, instant credit solutions that seamlessly integrate with minimal tech development, ensuring real-time disbursement for the user needs. </p>
              </div>
            </div>
            <div className="eligibility-criteria-box-embedded">
              <div className="why-choose-us-left">
                <div className="choose-heading"> <h1>Why Choose MoneyWide?</h1></div>
                <div className="choose-points">
                  <div className="mlc-pl-list-cont-embedded">
                    <img src={greenticknew} alt="tick" />
                    <span>Instant approval over API </span>
                  </div>
                  <div className="mlc-pl-list-cont-embedded">
                    <img src={greenticknew} alt="tick" />
                    <span> Real time disbursement</span>
                  </div>
                  <div className="mlc-pl-list-cont-embedded">
                    <img src={greenticknew} alt="tick" />
                    <span>100% digital process</span>
                  </div>
                  <div className="mlc-pl-list-cont-embedded">
                    <img src={greenticknew} alt="tick" />
                    <span>High approval ratios</span>
                  </div>
                  <div className="mlc-pl-list-cont-embedded">
                    <img src={greenticknew} alt="tick" />
                    <span>Available in 7500 pincodes PAN India</span>
                  </div>
                  <div className="mlc-pl-list-cont-embedded">
                    <img src={greenticknew} alt="tick" />
                    <span>Fully compliant with RBI’s digital guidelines </span>
                  </div>
                </div>
              </div>
              <div className="why-choose-us-right">
                <img src={chooseus} alt="chooseus-banner" />
              </div>
            </div>

            <div className="harnessed-embedded">
              <div className="harnessed-heading"><h1>A Comprehensive Solution for Every Credit Need</h1></div>
              <p>MoneyWide has harnessed the potential of embedded finance and channeled it into several industries:</p>
              <div className="harnessed-main">
                <div className="left-harnessed">
                  <img src={healthtech} alt="healthtech-banner" />
                </div>
                <div className="right-harnessed">
                  <div className="right-harnessed-heading">Health Tech</div>
                  <p> MoneyWide's integration with healthtech platforms allows users to effortlessly manage medical bills, insurance claims, and payment plans, all from within the same application or platform. One can choose from a variety of flexible payment options tailored to their healthcare needs, ensuring that medical expenses are never a burden.</p></div>
              </div>
            </div >
            {/*  */}
            <div className="harnessed-main-second">
              <div className="right-harnessed">
                <div className="right-harnessed-heading">EdTech</div>
                <p>Say goodbye to hefty tuition bills! MoneyWide offers flexible financing options for educational expenses, making it easier for students to pursue their dreams. MoneyWide's integrated platform helps students discover and manage scholarship and grant opportunities, reducing the financial burden of education. It offers low-interest loans ensuring that finances are never a barrier to education.</p></div>
              <div className="left-harnessed">
                <img src={edtech} alt="edtech-banner" />
              </div>
            </div>
            {/*  */}
            <div className="harnessed-main" style={{ marginTop: "40px" }}>
              <div className="left-harnessed">
                <img src={insurance} alt="insurance-banner" />
              </div>
              <div className="right-harnessed">
                <div className="right-harnessed-heading">Insurance</div>
                <p>MoneyWide collaborates with insurers to offer personalized insurance plans that meet your specific needs and budget. Filing insurance claims has never been this hassle-free. MoneyWide's integration streamlines the claims process, ensuring you get the coverage you deserve promptly. Keep track of all your insurance policies in one place, making it easier to stay protected and make informed decisions.</p></div>
            </div>
            {/*  */}
            <div className="harnessed-main-second" style={{ marginTop: "40px" }}>
              <div className="right-harnessed">
                <div className="right-harnessed-heading">Travel</div>
                <p>MoneyWide's embedded finance solutions also extend to travel, providing services such as travel insurance. These solutions facilitate the spread of holiday costs, allowing travelers to manage their finances more effectively when planning vacations. They can access flexible repayment options for a seamless travel experience. </p></div>
              <div className="left-harnessed">
                <img src={travel} alt="travel-banner" />
              </div>
            </div>
          </div>
          <EmbeddedSlider/>
          <EmbeddedMobileSlider/>        
          <div className="contact-heading">Let’s connect</div>
          <div className="contact-div" id="contact-div">
            <div className="contact-div-call">
              <div className="contact-person">
                <div className="contact-person-left"><img src={call} alt="call-banner" /></div>
                <div className="contact-person-right"><span>Avdhesh</span>
                  <p>+91 9328199236</p></div>
              </div>
              <div className="contact-person">
                <div className="contact-person-left"><img src={call} alt="call-banner" /></div>
                <div className="contact-person-right"><span>Abhavya</span>
                  <p>+91 9910990659</p></div>
              </div>
              {/* <div className="contact-person">
                <div className="contact-person-left"><img src={call} alt="call-banner" /></div>
                <div className="contact-person-right"><span>Vijay</span>
                  <p>+91 9873397329</p></div>
              </div> */}
              <div className="contact-person">
                <div className="contact-person-left"><img src={call} alt="call-banner" /></div>
                <div className="contact-person-right"><span>Ishita</span>
                  <p>+91 9319008720</p></div>
              </div>
              <div className="contact-person">
                <div className="contact-person-left"><img src={address} alt="address-banner" /></div>
                <div className="contact-person-right"><span>Address</span>
                  <p>Plot No. 131, Sector 44, Gurugram, Haryana 122003</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmbeddedOfferings;