import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import axios from "axios";
import {
 prodbase_url,
  client_id,
  client_key,
  source,
  verification_source,
  verified_by,
  verifier_name
} from "../Secret_Credentials/Secret_Credential";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken } from "../../Redux/action";
import { decryptWithHybrid, encryptWithHybrid } from '../../Keys';

const PartnerSlider = () => {
  let dispatch = useDispatch();
  const {token} = useSelector(state=>state)
  const [show,setShow] = useState(false);
  const [data, setData] = React.useState([])

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }


  useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, [])

  const encryptedPayload = async () => {
    let payLoad = {
      source: source,
      verifier_name: verifier_name,
      verified_by: verified_by,
      verification_source: verification_source
  }
  let payload = await encryptWithHybrid(JSON.stringify(payLoad));
    axios.post(`${prodbase_url}web/our-partner`,
  payload,{
    headers:{
      token
    }
  }).then((res)=>{
    let dec_data = decryptWithHybrid(res.data.encryptedMessage, res.data.encryptedAesKey, res.data.iv);
    if(dec_data.status == '1' && dec_data.status_code == '200'){
      setData(dec_data.data.lendingPartner)
      setShow(true)
    }
  })
  }

  useEffect(()=>{
    if(token !== ""){
      encryptedPayload();
    }
  },[token])

  var sliderblog = {
    infinite: false,
    autoplay: true,
    cssEase: 'ease-in-out',
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
    ]
  };

  return (
    <>
    { show ? <div style={{margin:"80px 0"}}>
      <p className='partner-heading'>Our Lending Partners</p>
      <div className='partner-blog'>
        <Slider {...sliderblog}>
          {data.map((el, index) => (
            <div key={index} className="nesting">
              <div className="card">
                <img className="blogimg" src={el.logo} alt="Image Not Found" />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div> : ""}
    </>
    
  )
}

export default PartnerSlider