import React from 'react';
import '../../../OfferSection/congratulationpop.css'
import Money from '../../../../Icons/Money.svg';
import rupee from '../../../../Icons/rupee.svg';
import greentick from '../../../../Icons/greentick.svg';
import '../../../Loader/Loader.css';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPreApprovedOfferDetails, setCurrentStep, setPreapprovedOfferPopup, setSorryPopup, updateCkycDetails, updateCredentialsState, updateFormState, updateLoginDetails, updateStepBar, updatePopUp, updateMwApplicationId, updateChoosenAmount, updateResumeJourney, GenerateToken } from '../../../../Redux/action';
import { base_url, client_id, client_key, source, prodbase_url, verification_source, verified_by, verifier_name, react_base_url } from '../../../Secret_Credentials/Secret_Credential';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FormHead from '../FormHead';
import queryString from "query-string";
import Base64 from "base-64";
import { Check_utm_source, decryptQueryIdOfferId, encryptLeadIdStepId, encryptQueryIdStepId } from '../../../EverCalledFunctions/EverCalledFunctions';
import { FinalOfferSubmissionApi, GetPersonalDetails, getToken } from '../../../EverCalledFunctions/EverCalledApis';
import CKYCWaiting from '../../../LoginPopUp/CKYCWaiting';
import { AxiosThirty } from '../../../AxiosConfiguration/AxiosConfig';
import NewErrorCase from './NewErrorCase';

const FinalOfferScreen = () => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  const { step_bar, loginDetails, ckycDetails, formStates, credentialState, PopUpState, mw_application_id, preApprovedOfferDetails, choosen_amount, resumeJourney, token } = useSelector(state => state)
  const dispatch = useDispatch();
  const [qryStr, setQryStr] = React.useState("");
  const [rocket, setRocket] = React.useState(false);

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

  useEffect(() => {
    dispatch(updateStepBar({ ...step_bar, profile: true, offer: true }))
    dispatch(updateFormState({ ...formStates, KycVerification: false, ResidentialAddress: false, MailingAddress: false, CurrentAddress: false }));
    if (token == "") {
      myToken();
    }
  }, [])

  useEffect(() => {
    if ((token !== "") && (mw_application_id !== "") && (credentialState.cust_id !== "")) {
      // const payload = {
      //   "source": source,
      //   "lead_id": mw_application_id,
      //   "product_id": "56",
      //   "customer_id": credentialState.cust_id,
      //   "offer_type": 2,
      //   "level_id": 1,
      //   "verified_by": verified_by,
      //   "verifier_name": verifier_name,
      //   "verification_source": verification_source,
      //   "program_id": "1",
      //   "stage_name": "Final Offer"
      // }
      // axios.post(`${prodbase_url}final-offer`, payload, {
      //   headers: {
      //     token
      //   }
      // }).then((res) => {
      //   setLoading(false)
      //   if (res.data.status === "1" && res.data.status_code === "200") {
      //     setData(res.data.data.offer.eligibility)
      //   } else {
      //     let decodeData = Base64.encode(`${mw_application_id}`)
      //     decodeData = Base64(decodeData)
      //     window.location.href = `${react_base_url}thank-you/?fid=${decodeData}&__temp_id=7`;
      //   }
      // })

      AxiosThirty.post(`${prodbase_url}final-offer-fetch`, {
        source,
        "lead_id": mw_application_id,
        "product_id": "56",
        "customer_id": credentialState.cust_id,
        "level_id": 1,
        verified_by,
        verifier_name,
        verification_source,
        "program_id": "1"
      }, {
        headers: {
          token
        }
      }).then((res) => {
        setLoading(false)
        if (res.data.status === "1" && res.data.status_code === "200") {
          setData(res.data.data.offer.eligibility)
        } else {
          // let decodeData = Base64.encode(`${mw_application_id}`)
          // decodeData = Base64.encode(decodeData)
          // window.location.href = `${react_base_url}thank-you/?fid=${decodeData}&__temp_id=7`;
          let checkCondition = Check_utm_source()
                    if(checkCondition && checkCondition.length !=0 ){
                      let qry = Base64.encode(`${mw_application_id}`);
                      qry = Base64.encode(qry);
                      window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=7${checkCondition}`;
                      // window.location.href = `${react_base_url}analyzer/?fid=${qryStr}${checkCondition}`;                
                      }
                      else{
                        let qry = Base64.encode(`${mw_application_id}`);
                        qry = Base64.encode(qry);
                        window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=7`;
                      }
        }
      }).catch((err) => {
        setRocket(true);
      })
    }
  }, [token, credentialState.cust_id, mw_application_id])

  useEffect(() => {
    console.log(data);
  }, [data])





  const handleClick = async () => {

    const payLoad = {
      lead_id: mw_application_id,
      customer_id: credentialState.cust_id,
      selected_loan_amount: data.loan_amt,
      selected_tenure: data.tenure,
      emi_calculated_applicable_roi: data.emi,
      applicable_roi: data.applicable_roi,
      "final_id": data.program_id
    };
    const FinalOfferSubmission = await FinalOfferSubmissionApi(payLoad, token);
    if (FinalOfferSubmission) {
      dispatch(updateFormState({ ...formStates, StartJourney: true, FinalOffer: false }));
      const qry = encryptLeadIdStepId(mw_application_id, 4);
      let checkCondition = Check_utm_source()
      if(checkCondition && checkCondition.length !=0 ){
                      // let qry = Base64.encode(`${mw_application_id}`);
                      // qry = Base64.encode(qry);
                      // window.location.href = `${react_base_url}thank-you/?fid=${decodeData}&__temp_id=7${checkCondition}`;
                      navigate(`/personal-loan/?fid=${qry}${checkCondition}`)
                      // window.location.href = `${react_base_url}analyzer/?fid=${qryStr}${checkCondition}`;                
             }
              else{
                    navigate(`/personal-loan/?fid=${qry}`)
                        // let qry = Base64.encode(`${mw_application_id}`);
                        // qry = Base64.encode(qry);
                        // window.location.href = `${react_base_url}thank-you/?fid=${decodeData}&__temp_id=7`;
                      }
      // navigate(`/personal-loan/?fid=${qry}`);
    }

  }

  const handleError = (e) => {
    window.location.reload();
  }


  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)}/>}
      {loading && <CKYCWaiting heading={"We are generating an offer for you."} />}
      {!loading && <div>
        <FormHead formName={"Final Offer"} formSubHead={"Accept the offer to further process your application"} />
        <div className="congratspopup-main inform-box">
          <h2>Congratulations!</h2>
          <img className='moneyearth' src={Money} alt="Money" />
          <div className='amount-box'>
            <p>You are eligible for a loan amount of </p>
            {/* <span><strong>₹</strong>1000000</span> */}
            {/* <span><strong>₹</strong>{(choosen_amount.amount.toLocaleString('en-IN'))}</span> */}
            <span><strong>₹ </strong>{(data && data.loan_amt) ? data?.loan_amt?.toLocaleString("en-IN"):""}</span>

          </div>
          {<div className='tenour-case'>
            <div className="tenour-Box">
              <div className="tenour-head">Tenure</div>
              <div className="tenour-content">{data.tenure} Months</div>
              {/* <div className="tenour-content">12 Month</div> */}
            </div>
            <div className="tenour-Box">
              <div className="tenour-head">ROI (P.M.)</div>
              {/* <div className="tenour-content">12 Month</div> */}
              <div className="tenour-content">{(data && data.applicable_roi) ?(((data.applicable_roi)/12).toFixed(2)):""} %</div>
            </div>
            <div className="tenour-Box">
              <div className="tenour-head">EMI</div>
              <div className="tenour-content">
                <img className='rupee-symbol' src={rupee} />
                {/* 12 Month */}
                {data?.emi?.toLocaleString("en-IN")}
              </div>
            </div>
          </div>}
          {/* <div className='require-doc-box'>
          <div className='cont-box'>
            <p>{preApprovedOfferDetails.final_data[0].program_features[0].feature}</p>
          </div>
          <div className='cont-box'>
            <p>{preApprovedOfferDetails.final_data[0].program_features[1].feature}</p>
          </div>
          <div className='cont-box'>
            <p>{preApprovedOfferDetails.final_data[0].program_features[2].feature}</p>
          </div>
        </div> */}
          {/* <div className='congrats-offer-button' > */}
          <button className='footer-btn  footer-btn-activate Bmargin' onClick={() => handleClick()}>Continue</button>
          {/* </div> */}
          {/* <div className='congrats-looking-offer'>
                            <span>Looking for Higher Amount ?</span>
                        </div> */}
        </div>
      </div>}
    </>
  )
}

export default FinalOfferScreen;