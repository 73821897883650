import React from 'react'
import { useEffect } from 'react'
import DropDownIcon from '../../Icons/DropDownIcon.svg'
import calender from '../../Icons/calender.svg'
import strategy from '../../Icons/strategy.svg'
import netmoney from '../../Icons/netmoney.svg'
import './LoanDetails.css'
import { useForm } from 'react-hook-form'
import { fetchPartPaymentAPI, formatDate, getLoginData, PartPayLinkAPI } from './MyAccountAPIs'
import { useState } from 'react'
import DateCalender from '../DateCalender/DateCalender'
import { useSelector, useDispatch } from 'react-redux'
import { getToken } from '../EverCalledFunctions/EverCalledApis'
import { GenerateToken, updateSuggestion_DropDown } from '../../Redux/action'
import { useNavigate } from 'react-router-dom'
import Lottie from 'react-lottie-player'
import ctaAnimation from '../assests/lootie-files/continue.json';

const PartPaymentForm = ({ preData, values, setPartPaymentFound }) => {
  const [mw, setMW] = React.useState("")
  const [startDate, setStartDate] = useState(new Date())
  const [resp, setResp] = useState(false)
  const [buttonDisabled, setbuttonDisabled] = useState(false)
  const [displayReason, setDisplayReason] = useState("")
  const [timeLimit, setTimeLimit] = React.useState({
    maxTime: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1))),
    minTime: new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`)
  })
  const { token, suggestionDropDown } = useSelector(state => state)
  const [err, setErr] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [selected, setSelected] = useState(-1)
  const [noDataFound, setNoDataFound] = useState(false)
  const [APIDATA, setAPIDATA] = useState({
    show: false,
    data: []
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { register, handleSubmit, reset, formState: { errors }, watch, setValue, clearErrors, setError } = useForm({
    defaultValues: {
      reason: "",
      date: "",
    }
  })

  const result = watch();




  const PartPayAPI = async () => {
    const Partpaymw = {
      "mw_application_id": mw,
      amount: result.amount,
      strategy: result.reason,
      date: startDate
    }
    const APIResponse = await PartPayLinkAPI(Partpaymw, token);
    if (APIResponse.status_code === "200" && APIResponse.status === "1") {
      if (APIResponse.data.changes.paymentLink && APIResponse.data.changes.paymentLink !== "") {
        var link = document.createElement('a');
        link.href = APIResponse.data.changes.paymentLink;
        document.body.appendChild(link);
        link.target = "_blank";
        link.style.zIndex = "-1";
        link.click();
        document.body.removeChild(link);
        setResp(false)
        setPartPaymentFound(2)
      } else {
        setResp(false)
        setPartPaymentFound(4)
      }
    } else if (APIResponse.status_code === "429" && APIResponse.status === "0") {
      setPartPaymentFound(4)
      setResp(false)
    }else{
      setPartPaymentFound(4)
      setResp(false)
    }
  }
  const handleArrows = (e) => {
    if (APIDATA.show) {
      var key = e.keyCode;
      if (key != 40 && key != 38 && key != 13) {
        return;
      }
      if (key == 13) {
        e.stopPropagation()
        if (selected > -1 && selected < APIDATA.data.length) {
          SetThisPin(APIDATA.data[selected])
          setSelected(-1)
        } else {
          setSelected(-1)
        }
      }
      else if (key == 38) {
        if (selected > 0) {
          setSelected(selected - 1)
        }
      }
      else if (key == 40) {
        if (selected < APIDATA.data.length - 1) {
          setSelected(selected + 1)
        }
      }
    }
  }

  const onSubmit = async () => {
    if (startDate && result.reason && result.amount && result.amount >= values.min && result.amount <= values.max) {
      setResp(true)
      PartPayAPI()
    }
  }
  const DropAPI = async (mw) => {
    const APIResponse = await fetchPartPaymentAPI(mw, token);
    if (APIResponse.status_code === "200" && APIResponse.status === "1") {
      if (APIResponse.data.length == 1) {
        setbuttonDisabled(true)
        SetThisPin(APIResponse.data[0])
      } else {
        setAPIDATA({ ...APIDATA, data: APIResponse.data })
      }

    }
  }


  useEffect(() => {
    if (token !== "") {
      let payLoad = getLoginData()
      if (!payLoad && !payLoad.mw_app_id) {
        navigate('/login')
      }
      else if ((payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "")) {
        setMW(payLoad.mw_app_id)
        DropAPI(payLoad.mw_app_id)
      }
    }
  }, [token])

  const myToken = async () => {
    const T = await getToken();
    dispatch(GenerateToken(T))
  }

  useEffect(() => {
    if (token !== "") {
      // myToken()
    }
  }, [])


  const OpenDropDown = () => {
    if (!buttonDisabled) {
      setAPIDATA({ ...APIDATA, show: true })
      dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: true }))
    }
  }

  const SetThisPin = (value) => {
    setDisplayReason(value.statusName)
    setValue('reason', value.statusId)
    setAPIDATA({ ...APIDATA, show: false })
  }

  const handleUpKeyNumber = (value, field, len) => {
    var regexp = /[^0-9]/g;
    if (value && value.match(regexp)) {
      setValue(field, value.replace(regexp, ""))
    }
  }


  useEffect(() => {
    if (result.amount) {
      if (!(result.amount >= values.min && result.amount <= values.max)) {
        setErr(true)
      } else {
        setErr(false)
      }
    } else {
      setErr(false)
    }
  }, [result.amount])
  return (
    <>
      <div className="loan-details-heading">Provide a few details for part payment</div>
      <div className='as-period'>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="as-period-firstbox">
            <div className='pp-boxes-main'>
              <div className='pp-first-box'>


                <div className={"date-box forebox add-border"}>
                  <img src={calender} alt={"NA"} />
                  <DateCalender startDate={startDate} setStartDate={setStartDate} minYear={new Date().getFullYear()} timeLimit={timeLimit} placeHolder={"Select Part Payment Date"} read={true}/>
                  <img className="small-the-icon" src={DropDownIcon} alt="NA" />
                </div>


                <div className={'date-box forebox add-border remove-bottom'}>
                  <img src={netmoney} alt={"NA"} />
                  <input type="tel" {...register('amount', { required: true })}
                    onKeyUp={() => handleUpKeyNumber(result.amount, "amount", 9)}
                    maxLength={9} placeholder='Part Payment Amount' />

                  {err && result.amount && <div className='foreclosue-err'>Enter values between {values.min} and {values.max}</div>}
                </div>


                <div className={'date-box forebox add-border'}>
                  <img src={strategy} alt={"NA"} />
                  <input type="text" disabled={buttonDisabled} readOnly value={displayReason} onClick={() => OpenDropDown()} onKeyDown={(e) => handleArrows(e)} placeholder='Strategy' />
                  {!buttonDisabled && <img className="small-the-icon pointer-cur" src={DropDownIcon} onClick={() => OpenDropDown()} alt="NA" />}
                  {APIDATA.show && suggestionDropDown.show && APIDATA.data.length!==0 && <div className='dropDownBox'>
                    {APIDATA.data.map((row, ind) => {
                      return <div className={'dropDownValue' + (selected === ind ? " select" : "")} key={ind} onClick={() => SetThisPin(row)}>{row.statusName}</div>
                    })}
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div className='account-state-button'>
            <button type="submit" disabled={resp} className={"footer-btn " + (startDate && result.amount && result.reason && result.amount >= values.min && result.amount <= values.max ? "activate" : "")}>
              {!resp ?
                "Proceed to Pay Now" :
                <div className="continue-lottie">
                  <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                </div>}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default PartPaymentForm