// import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
// import { GenerateToken } from "../../Redux/action";
// import { getToken } from "../EverCalledFunctions/EverCalledApis";
import {
  source,
  verification_source,
  verified_by,
  verifier_name,
} from "../Secret_Credentials/Secret_Credential";
import "./Review.css";
import star from "../../Icons/rating5.svg";
import Slider from "react-slick";
// import one from "../../Icons/onlystar.svg";
import ReviewImg from "./ReviewImg";
// import { decryptWithHybrid, encryptWithHybrid } from "../../Keys";
import { apiInstance } from "../Config/APIConfig";

const ReviewSlider = () => {
  const { token } = useSelector((state) => state);
  const [data, setData] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [rating,setRating] = React.useState(4.5);
  // const [no, setNo] = React.useState();
  var sliderblog = {
    infinite: true,
    autoplay: true,
    cssEase: "ease-in-out",
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          speed: 500,
          infinite:true
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          infinite:true
        },
      },
    ],
  };

  const ReviewData = async () => {
    let payLoad = {
      source: source,
      verifier_name: verifier_name,
      verified_by: verified_by,
      verification_source: verification_source,
    }
    // let payload = await encryptWithHybrid(JSON.stringify(payLoad));
    let dec = await apiInstance
      .post(
        `customer-reviews`,
        payLoad,
        {
          token,
        }
      )
      // .then((res) => {
        // let dec_data = decryptWithHybrid(res.data.encryptedMessage, res.data.encryptedAesKey, res.data.iv);
        if (dec.status === "1" && dec.status_code === "200") {
          setData(dec.data.data);
          setRating(dec.data.overall_rating);
          setShow(true);
        }
      // });
  };

  React.useEffect(() => {
    setTimeout(() => {
        ReviewData();
    }, 200);
  }, []);
  return (
    <>
      <div className="review-slider-main">
        <div className="review-first">
          <h2 className="review-h2">Rated as {rating}/5</h2>
          <img src={star} alt=""/>
          {/* <p>5000 Reviews</p> */}
        </div>
        {show ? (
          <div className="blog-card" id="review-card">
            <div className="review-card">
              <Slider {...sliderblog}>
                {data?.map((el, index) => (
                  <div key={index} className="nesting">
                    <div className="blogcard" style={{ padding: "20px" }}>
                      <div className="review-f-n">
                        <div className="review-f-n-1">
                          <span>{el.cust_name.charAt(0)}</span>
                        </div>
                        <div className="review-name">
                        <span>{el.cust_name}</span>
                        <p>{el.cust_city}</p>
                      </div>
                      </div>
                      <div className="review-f-n-2">
                          <div id="review-cust-star">
                            {/* show star img */}
                            <ReviewImg star={el.cust_rating} />
                          </div>
                          <div className="review-date">
                            <p>{el.review_date}</p>
                          </div>
                        </div>
                      
                      <div className="review-feedback">
                        <p>{el.cust_review}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              {/* <button className="blogviewall">View all</button> */}
            </div>
          </div>

        ) : (
          ""
        )}
       {show && <div className="review-button-div">
        <a href={`/customer-reviews/${window.location.search}`} className="review-buuton-all">Read More</a>
        </div>}
      </div>
    </>
  );
};

export default ReviewSlider;
