import React, { useState } from 'react';
import './../Form.css';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import { GenerateToken,  updateFormState,  updateStepBar, updateSuggestion_DropDown } from '../../../../Redux/action';
import pin from './../../../../Icons/pin.svg';
import building from './../../../../Icons/building.svg';
import FormHead from '../FormHead';
import { useEffect } from 'react';
// import { source, verification_source, verified_by, verifier_name } from '../../../Secret_Credentials/Secret_Credential';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../../../assests/lootie-files/continue.json';
import { Employer, getCityName, getPinOption, getToken } from '../../../EverCalledFunctions/EverCalledApis';
import { gettingerror } from '../../../EverCalledFunctions/EverCalledFunctions';
import { apiInstance } from '../../../Config/APIConfig';

const OfficeAddress = () => {
  const [resp, setResp] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [selected, setSelected] = React.useState(-1);
  const dispatch = useDispatch();
  const { step_bar, credentialState, mw_application_id, formStates, suggestionDropDown, token, office_action_penny } = useSelector(state => state)
  const [pincodeData, setPincodeData] = useState({
    show: false,
    data: []
  });
  const [cityFromPin, setCityFromPin] = useState({
    city: "",
    city_id: ""
  })
  const { setError, register, handleSubmit, reset, formState: { errors }, watch, setValue, clearErrors } = useForm({
    defaultValues: {
      office_address1: "",
      office_address2: "",
      office_address3: "",
      office_pincode: ""
    },
    mode: "all"
  })
  const data = watch();




  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  React.useEffect(() => {
    dispatch(updateStepBar({ ...step_bar, profile: true, offer: true, verification: true }))
    window.sessionStorage.clear();
    if (token == "") {
      myToken();
    }
  }, [])

  const bankPenny = async () => {
    let decGetSelfie = await apiInstance
        .post(
          `get-selfie-details`,
          {
            lead_id: mw_application_id,
            lenderID: office_action_penny.data.lenderId
          },
          {
            token
          }
        )
        // .then((res) => {

        // });
  }

  React.useEffect(() => {
    if ((token !== "") && (office_action_penny?.data)) {
      bankPenny()
    }
  }, [token])

  const getCityNameHere = async (pin) => {

    const APIDATA = await getCityName(pin, token);
    if (APIDATA.city && APIDATA.cityId) {
      setCityFromPin({ ...cityFromPin, city: APIDATA.city, city_id: APIDATA.cityId })
    }


  }

  const GetPinData = async (pin) => {

    const APIDATA = await getPinOption(pin, token);
    if (APIDATA.length !== 0) {
      await setPincodeData({ ...pincodeData, show: true, data: APIDATA })
      dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: true }))
    } else {
      setPincodeData({ ...pincodeData, show: false, data: [] })
    }
  }

  useEffect(() => {
    if (data.office_pincode) {
      if (data.office_pincode.length < 3) {
        setPincodeData({ ...pincodeData, show: false, data: [] })
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
      }
      else if (((data.office_pincode >= 110) && (data.office_pincode.length >= 3 && data.office_pincode.length < 6))) {
        GetPinData(data.office_pincode)
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
      }
      else if ((data.office_pincode <= 110000)) {
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
      }
      else if (data.office_pincode.length === 6) {
        getCityNameHere(data.office_pincode)
        setPincodeData({ ...pincodeData, show: false, data: [] })
      }
      else {
        setPincodeData({ ...pincodeData, show: false, data: [] })
      }
    } else {
      setPincodeData({ ...pincodeData, show: false, data: [] })
      setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
    }

  }, [data.office_pincode])


  {/* -------------------------------Submit Function Function------------------------------------------------- */ }
  const onSubmit = async (result) => {
    setResp(true)
    console.log(result);
    result = {
      ...result,
      mw_application_id,
      "customer_id": credentialState.cust_id
    }

    const APIDATA = await Employer(result, "")
    if (APIDATA.status_code = "200" && APIDATA.status === "1") {
      gettingerror('submit office address web',mw_application_id)
      dispatch(updateFormState({ ...formStates, OfficeAddress: false, AdditionalDetails: true }));
      window.sessionStorage.clear();
    } else {
      dispatch(updateFormState({ ...formStates, error: true }))
      setResp(false)
    }
  }

  const handleArrows = (e) => {
    if (pincodeData.show) {
      var key = e.keyCode;
      if (key != 40 && key != 38 && key != 13) {
        return;
      }
      if (key == 13) {
        e.stopPropagation()
        if (selected > -1 && selected < pincodeData.data.length) {
          setThisPin(pincodeData.data[selected])
          setSelected(-1)
        } else {
          setSelected(-1)
        }
      }
      else if (key == 38) {
        if (selected > 0) {
          setSelected(selected - 1)
        }
      }
      else if (key == 40) {
        if (selected < pincodeData.data.length - 1) {
          setSelected(selected + 1)
        }
      }
    }
  }
  const handleUpKeyNumber = (value, field, len) => {
    var regexp = /[^0-9]/g;
    if (value && typeof (value) === 'string' && value.match(regexp)) {
      setValue(field, value.replace(regexp, ""))
    }
  }

  const SpaceManage = (field, value) => {
    setValue(field, value.replace(/^\s+/, ''));
  }

  const setThisPin = (pin) => {
    setValue('office_pincode', pin);
    setPincodeData({ ...pincodeData, show: false })
    clearErrors(['office_pincode'])
    getCityNameHere(pin)
  }


  const Blured = (field, value) => {
    // var newVal = value.trimEnd();
    var newVal = value.replace(/\s+$/, '');
    setValue(field, newVal);
    if (newVal.length < 3) {
      setError(field)
    } else {
      clearErrors([field])
    }
  }

  useEffect(() => {
    if ((Object.keys(errors).length === 0 && data.office_address1 && data.office_address2 && data.office_address3 && data.office_pincode)) {
      setSubmit(true)
    } else {
      setSubmit(false)
    }
  }, [data])

  return (
    <>
      <FormHead formName="Office Address" formSubHead={"Provide office address to complete your KYC"} />
      <div className="tag-for-input">
        {/* -------------------------Current Address Field--------------------------------------- */}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} >




          {/* ------------------------------House No Field----------------------------------------- */}
          <div className={errors.office_address1 ? "input-tag error-border remove-bottom" : data.office_address1 ? "input-tag add-border remove-bottom" : "input-tag remove-bottom"}>
            <img className="tag-icon" src={building} alt=''/>
            <input disabled={resp} className="input-tag-box" type="text" placeholder='Office / Building / Block Number.' {...register('office_address1', {
              required: true, minLength: 3, pattern: {
                value: '([A-Za-z]+ ?)*'
              }
            })} onBlur={() => Blured('office_address1', data.office_address1)}
              autoComplete='off' onKeyUp={() => SpaceManage("office_address1", data.office_address1)} />
            {/* {errors.office_address1 && <img src={ThisIsAnError} alt="" />} */}
          </div>
          {errors.office_address1 && <div className='err-text'>Enter at least 3 Characters</div>}




          {/* -----------------------office_address2 Field----------------------------------------- */}
          <div className={errors.office_address2 ? "input-tag error-border remove-bottom" : data.office_address2 ? "input-tag add-border remove-bottom" : "input-tag remove-bottom"}>
            <img className="tag-icon" src={pin} alt=''/>
            <input disabled={resp} className="input-tag-box" type="text" placeholder='Area/Sector' {...register('office_address2', { required: true, minLength: 3 })} autoComplete='off' onKeyUp={() => SpaceManage("office_address2", data.office_address2)} onBlur={() => Blured('office_address2', data.office_address2)} />
            {/* {errors.office_address2 && <img src={ThisIsAnError} alt="" />} */}
          </div>
          {errors.office_address2 && <div className='err-text'>Enter at least 3 Characters</div>}





          {/* -----------------------office_address3 Field----------------------------------------- */}
          <div className={errors.office_address3 ? "input-tag error-border remove-bottom" : data.office_address3 ? "input-tag add-border remove-bottom" : "input-tag remove-bottom"}>
            <img className="tag-icon" src={pin} alt=''/>
            <input disabled={resp} className="input-tag-box" type="text" placeholder='Landmark' {...register('office_address3', { required: true, minLength: 3 })} autoComplete='off' onKeyUp={() => SpaceManage("office_address3", data.office_address3)} onBlur={() => Blured('office_address3', data.office_address3)} />
            {/* {errors.office_address3 && <img src={ThisIsAnError} alt="" />} */}
          </div>
          {errors.office_address3 && <div className='err-text'>Enter at least 3 Characters</div>}





          {/* ------------Pincode Field----------------------------------------- */}
          <div className={errors.office_pincode ? "input-tag error-border remove-bottom" : data.office_pincode ? "input-tag add-border remove-bottom" : "input-tag remove-bottom"} style={{ marginBottom: "0px" }}>
            <img className="tag-icon" src={pin} alt=''/>
            <input disabled={resp} className="input-tag-box" type="tel" placeholder='Pincode' minLength={6} maxLength={6}
              {...register('office_pincode', { required: true, minLength: 6, maxLength: 6 })}
              onKeyDown={(e) => handleArrows(e)}
              onKeyUp={() => handleUpKeyNumber(data.office_pincode, "office_pincode", 6)} autoComplete='off' />
            {/* {errors.office_pincode && <img src={ThisIsAnError} alt="" />} */}
            {suggestionDropDown.show && pincodeData.show && pincodeData && pincodeData.data && pincodeData.data.length && <div className="dropDownBox">
              {pincodeData.data.map((row, ind) => {
                return <div className={'dropDownValue' + (selected === ind ? " select" : "")} key={ind} onClick={() => setThisPin(row)}>{row}</div>
              })}
            </div>}
          </div>
          {data.office_pincode && <div className={"api-text " + (errors.office_pincode ? " err-text" : "")}>{cityFromPin.city}</div>}
          {errors.office_pincode && !data.office_pincode && <div className='err-text'>Enter valid Pincode</div>}




          {/* -----------------Submit button----------------------------------------- */}
          <button disabled={resp || !submit} type='submit' className={'footer-btn Tmargin Bmargin' + (submit ? " footer-btn-activate" : "")}  >
            {!resp ?
              "Continue" :
              <div className="continue-lottie">
                <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
              </div>}
          </button>
        </form>
      </div>
    </>
  )
}


export default OfficeAddress
