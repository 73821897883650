import React, { useState, useEffect } from 'react'
import calender from '../../Icons/calender.svg'
// import faq from '../../Icons/faq.svg'
import DropDownIcon from '../../Icons/DropDownIcon.svg'
import './LoanDetails.css'
import DateCalender from '../DateCalender/DateCalender';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchForeclosureLoanAPI, formatDate, getForeclosureMasterData, getLoginData } from './MyAccountAPIs'
import { GenerateToken, updateSuggestion_DropDown } from '../../Redux/action'
import { getToken } from '../EverCalledFunctions/EverCalledApis'
// import CKYCWaiting from '../LoginPopUp/CKYCWaiting'
import { useForm } from 'react-hook-form';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../assests/lootie-files/continue.json';

function ForeClosureLoanBox({ setPageStatus,setSuccessPageData }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token,suggestionDropDown } = useSelector(state => state)
  const [displayReason, setDisplayReason] = useState("")
  const [mw, setMW] = React.useState("")
  const [selected, setSelected] = useState(-1)
  const [startDate, setStartDate] = useState(new Date())
  const [resp, setResp] = useState(false)
  const [timeLimit, setTimeLimit] = React.useState({
    maxTime: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1))),
    minTime: new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`)
  })
  const [APIDATA, setAPIDATA] = useState({
    show: false,
    data: []
  })
  const { register, handleSubmit, reset, formState: { errors }, watch, setValue, clearErrors } = useForm({
    defaultValues: {
      reason: "",
      date: ""
    }
  })



  useEffect(() => {
    if (token !== "") {
      setTimeout(() => {
        getPreData();
      }, 0)

      let payLoad = getLoginData()
      if (!payLoad && !payLoad.mw_app_id) {
        navigate('/login')
      }
      else if (payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") {
        setMW(payLoad.mw_app_id)
      }
    }
  }, [token])



  const myToken = async () => {
    const T = await getToken();
    dispatch(GenerateToken(T))
  }

  useEffect(() => {
    if (token === "") {
      myToken()
    }
  }, [])

  const handleArrows = (e) => {
    if (APIDATA.show) {
      var key = e.keyCode;
      if (key != 40 && key != 38 && key != 13) {
        return;
      }
      if (key == 13) {
        e.stopPropagation()
        if (selected > -1 && selected < APIDATA.data.length) {
          setThisPin(APIDATA.data[selected])
          setSelected(-1)
        } else {
          setSelected(-1)
        }
      }
      else if (key == 38) {
        if (selected > 0) {
          setSelected(selected - 1)
        }
      }
      else if (key == 40) {
        if (selected < APIDATA.data.length - 1) {
          setSelected(selected + 1)
        }
      }
    }
  }

  const onSubmit = async (formData) => {
    if (startDate && displayReason) {
      setResp(true)
      const payLoad = {
        "mw_application_id": mw,
        "selected_reason_id": formData.reason,
        "foreclosure_date": formatDate(startDate),
      }
      const APIResponse = await fetchForeclosureLoanAPI(payLoad, token)
      if (APIResponse.status_code === "200" && APIResponse.status === "1") {
        setPageStatus(2)
        setSuccessPageData(APIResponse.data)
      } else if (APIResponse.status_code === "429" && APIResponse.status === "0") {
        setPageStatus(3)
      }else{            
        setPageStatus(3)
    }
    }
  }


  const getPreData = async () => {
    if (APIDATA.data.length === 0) {
      const APIResponse = await getForeclosureMasterData("foreclosure_reason", token)
      if (APIResponse.status_code === "200" && APIResponse.status === "1") {
        setAPIDATA({ ...APIDATA, data: APIResponse.data })
      }
    }
  }


  const OpenDropDown = () => {
    setAPIDATA({ ...APIDATA, show: true })
    dispatch(updateSuggestion_DropDown({...suggestionDropDown,show:true}))
  }

  const setThisPin = (value) => {
    setDisplayReason(value.value)
    setValue('reason', value.id)
    setAPIDATA({ ...APIDATA, show: false })
  }

  return (
    <>    
      <div className="loan-details-heading">Provide a few details for your loan foreclosure</div>
      <div className='as-period'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="as-period-firstbox">
            <div className='as-period-content'>
              <div className={"date-box forebox add-border"}>
                <img src={calender} alt={"NA"} />
                <DateCalender startDate={startDate} setStartDate={setStartDate} minYear={new Date().getFullYear()} timeLimit={timeLimit} placeHolder={"Select Foreclosure Date"} read={true}/>
                <img className="small-the-icon" src={DropDownIcon} alt="NA" />
              </div>
              <div className={'date-box forebox add-border'}>
                <img src={calender} alt={"NA"} />
                <input type="text" readOnly value={displayReason} onClick={() => OpenDropDown()} placeholder='Select Foreclosure Reason' onKeyDown={(e) => handleArrows(e)} />
                <img className="small-the-icon pointer-cur" src={DropDownIcon} onClick={() => OpenDropDown()} alt="NA" />
                {APIDATA.show && suggestionDropDown.show && APIDATA.data.length && <div className="dropDownBox">
                  {APIDATA.data.map((row, ind) => {
                    return <div className={'dropDownValue' + (selected === ind ? " select" : "")} key={ind} onClick={() => setThisPin(row)}>{row.value}</div>
                  })}
                </div>}
              </div>
            </div>
          </div>
          <div className='account-state-button'>
            <button type="submit" disabled={resp} className={"footer-btn " + (startDate && displayReason ? " activate" : "")}>
              {!resp ?
                "Continue" :
                <div className="continue-lottie">
                  <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                </div>}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ForeClosureLoanBox