import React from "react";
import "./HomePage.css";
import { Link } from "react-router-dom";
import newbanner from "../../Icons/newbanner.webp";
import Lottie from "react-lottie-player";
import budget from "../assests/lootie-files/budget.json"
import dl_logo from "../../Icons/dl-logo.svg";
import cl_logo from "../../Icons/cl-logo.svg";
import sl_logo from "../../Icons/sl-logo.svg";
import pl_logo from "../../Icons/pl-logo.svg";
import Slider from "react-slick";
import { useEffect } from "react";
import { prodbase_url } from "../Secret_Credentials/Secret_Credential";
import mwmobilebanner from "../../Icons/mw-mobile-banner.webp"
import TextSlider from "./TextSlider/TextSlider";


const First = () => {

  function ImgLoad() {
    setTimeout(() => {
      var element = document.getElementById("b-1");
      element.style.visibility = "visible";
      element.style.animation = "zoom .6s 1 0s";
    }, 0);
    setTimeout(() => {
      var element = document.getElementById("b-4");
      element.style.visibility = "visible";
      element.style.animation = "zoom .6s 1 0s";
    }, 500);
    setTimeout(() => {
      var element = document.getElementById("b-3");
      element.style.visibility = "visible";
      element.style.animation = "zoom .6s 1 0s";
    }, 1000);
    setTimeout(() => {
      var element = document.getElementById("b-2");
      element.style.visibility = "visible";
      element.style.animation = "zoom .6s 1 0s";
    }, 1500);
  };

  React.useEffect(() => {
    // console.log("entry")
    ImgLoad();
  }, [])

  var sliderblog = {
    infinite: false,
    autoplay: false,
    cssEase: 'ease-in-out',
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
          speed: 500
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        }
      }
    ]
  };
  return (
    <>
      <div className="bann">
        <Slider {...sliderblog}>
          <div className="budget-slide">
            <div className="budgetslide-left">
              <p>Instant</p>
              <span> Personal Loan</span>
              <p>in 1 Minute</p>
              <div className="btn" style={{ textAlign: "center" }}>
                <a href={`/personal-loan/${window.location.search}`}>Apply Now</a>
              </div>

            </div>
            <div className="budgetslide-right">
              <div>
                <img src={mwmobilebanner} alt="mw-mobile-banner" />
              </div>
            </div>
          </div>

          <div className="homebanner">
            <div className="left-content">
              <h1>
                Widening Horizons for
                <br />
                <span>Instant Money Disbursal</span>
              </h1>
              <div className="offering-loan">
                <span>Offering Loans beyond Top Locations</span>
              </div>
              <button className="btn">
                <Link to={"/personal-loan"}>Apply Now</Link>
              </button>
            </div>
            <div className="right-content">
              {/* <img className="right-img" src={banner} alt="image not fount" /> */}
              <img src={newbanner} alt="newbanner" loading="lazy" />
              <a href="new-credit-personal-loan-mw-first/" id="b-1" className="bubble-1 bb-1">
                <span className="cl-logo">
                  <img src={dl_logo} alt="dl_logo" loading="lazy" />
                </span>
                <div className="cl-content">
                  <span className="cont1">New to Credit</span>
                  <span className="cont2">Loan</span>
                </div>
              </a>
              <a href="/instant-personal-loan-without-documents/" id="b-4" className="bubble-4 bb-4">
                <span className="pl-logo">
                  <img src={cl_logo} alt="cl_logo" loading="lazy" />
                </span>
                <div className="pl-content">
                  <span className="cont1">PL without</span>
                  <span className="cont2">Documents</span>
                </div>
              </a>
              <a href="https://www.moneywide.com/blog/salary-advance-loans" id="b-3" className="bubble-3 bb-3">
                <span className="sl-logo">
                  <img src={sl_logo} alt="sl_logo" loading="lazy" />
                </span>
                <div className="sl-content">
                  <span className="cont1">Loan Against</span>
                  <span className="cont2">Salary</span>
                </div>
              </a>
              <a href="/personal-loan" id="b-2" className="bubble-2 bb-2">
                <span className="dl-logo">
                  <img src={pl_logo} alt="pl_logo" loading="lazy" />
                </span>
                <div className="dl-content">
                  <span className="cont1">Instant</span>
                  <span className="cont2">Personal Loan</span>
                </div>
              </a>
            </div>
          </div>
        </Slider>
      </div>
      <TextSlider/>
      

    </>
  );
};
export default First;
