import React from "react";
import "./moneywidefaq.css";
import comment from "./FaqComment.svg";
import loan from "./FaqLoan.svg";
import rupee from "./FaqRupees.svg";
import Slider from "react-slick";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken } from "../../Redux/action";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { prodbase_url, source, verification_source, verified_by, verifier_name } from "../Secret_Credentials/Secret_Credential";
// import FAQSection from "../FAQSection/FAQSection";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import { Link } from "react-router-dom";
import NewFaq from "./NewFaq";
import { apiInstance } from "../Config/APIConfig";

const MainFaq = () => {
  const [content, setContent] = React.useState([]);
  const { token } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState([
    {
      img: comment,
      para: "About MoneyWide",
    },
    {
      img: loan,
      para: "Loan Application",
    },
    {
      img: rupee,
      para: "Rates and Charges",
    },
    {
      img: rupee,
      para: "Loan Repayment",
    },
  ]);

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (token == "") {
      myToken();
    }
    setTimeout(() => {
      setShow(true);
    }, 2000);
  }, []);

  const FaqApiData = async () => {
    let dec = await apiInstance
      .post(
        `faq`,
        {
          source: source,
          verifier_name: verifier_name,
          verified_by: verified_by,
          verification_source: verification_source,
        },
        {
          token,
        }
      )
      // .then((res) => {
        if (dec.status === "1" && dec.status_code === "200") {
          setContent(dec.data.faq);
        }
      // });
  }

  React.useEffect(() => {
    if (token !== "") {
      FaqApiData();
    }
  }, [token]);

  var sliderblog = {
    infinite: false,
    autoplay: true,
    cssEase: "ease-in-out",
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  // const callFunction = (index) => {
  //   if (index === 0) {
  //     return "About MoneyWide";
  //   } else if (index === 1) {
  //     return "Loan Application";
  //   } else if (index === 2) {
  //     return "Rates and Charges";
  //   } else if (index === 3) {
  //     return "Loan Repayment";
  //   } else if (index === 4) {
  //     return "Didn’t find your query";
  //   }
  // };

  const handleclick = (index) => {
    // console.log(index);
    if (index == 0) {
      window.scrollTo({ top: 300, left: 300, behavior: 'smooth' });
    }
    else if (index == 1) {
      window.scrollTo({ top: 850, left: 2930, behavior: 'smooth' });
    }
    else if (index == 2) {
      window.scrollTo({ top: 3000, left: 3300, behavior: 'smooth' })
    }
    else if (index == 3) {
      window.scrollTo({ top: 3400, left: 3430, behavior: 'smooth' })

    }
  }

  return (
    <>
      {show ? <div>
        <div className="main-faq-bg">
          <div className="main-faq-cont">
            <div className="left-top">
              <Link to={`/${window.location.search}`}>Home</Link>  &gt;{" "}
              <span>FAQs</span>
            </div>
            <div className="main-faq-greet">
              <p>Hello,</p>
              <p style={{ color: "#4D3ECC", marginLeft: "1%" }}>how can we help?</p>
            </div>
            <p className="catergory-content">
              choose a category to quickly find the help you need
            </p>
            <div>
              <Slider {...sliderblog}>
                {data.map((el, index) => (
                  <div key={index} className="nesting">
                    <div onClick={() => handleclick(index)} className="faq-slide-card">
                      <img
                        className="faq-slide-img"
                        src={el.img}
                        alt="Image Not Found"
                      />
                      <p>{el.para}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <div>
          {content.map((el, index) => (
            <div className="faq-container-main" key={index}>
              <p>{el.heading}</p>
              <hr />
              {/* <FAQSection requiredSection={callFunction(index)} /> */}
              <NewFaq faqquestion={el} />
            </div>
          ))}
        </div>
      </div> : <CKYCWaiting heading={"We are Fetching FAQS"} />}
    </>
  );
};

export default MainFaq;
