import React from "react";
import contact from "../../Icons/contact.svg"
import DropDownIcon from "../../Icons/DropDownIcon.svg"
import { updatePartnerDocPopUp } from "../../Redux/action";
import { useDispatch, useSelector } from "react-redux";

export default function PartnerUploadDoc({ value, text, setDocuments }) {


    const { partnerdocupdate } = useSelector(state => state)
    const dispatch = useDispatch();
    
    let values = 0
    partnerdocupdate?.data?.length > 0 && partnerdocupdate.data.map((row)=>{
        if(row.checkbox){
            values++
        }
    })

    const OpenDocPopUp = (e) => {
        dispatch(updatePartnerDocPopUp({
            ...partnerdocupdate, status: true, val: value
        }))
        setDocuments({})
    }

    return (
        <>
            {/* <div className="upload-main">

                <div className="upload-left-input">
                <input type="file" placeholder="Upload File" />
                <a className="upload-file-partner"><p>Upload File</p>
                <img src={upload_btn} alt="" /></a>
            </div>
            </div> */}
            <div className="upload-right-input" onClick={(e) => OpenDocPopUp(e)}>
                <img src={contact} alt="" />
                <input type="text" placeholder={"Select Documents"} value={values > 0 ? (values + " document selected") : ""} readOnly/>
                <img src={DropDownIcon} alt=""/>
            </div>
        </>
    )
}