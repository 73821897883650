import React, { useState, useEffect } from 'react'
import "./partnerwithus.css"
import phone from "../../Icons/phone.svg"
import preaproveoffer from "../assests/partners/pre-aprove-offer.svg"
import { useForm } from "react-hook-form";
import Lottie from 'react-lottie-player';
import ctaAnimation from '../assests/lootie-files/continue.json';
import { SendOTP, getToken } from '../EverCalledFunctions/EverCalledApis';
import { useDispatch, useSelector } from 'react-redux';
import { GenerateToken,  updateLoginDetails, updatePartnerState, updateservicecredential } from '../../Redux/action';
// import { client_id, client_key, prodbase_url, source, verification_source, verified_by, verifier_name } from '../Secret_Credentials/Secret_Credential';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
// import queryString from "query-string";
// import Base64 from 'base-64';
// import axios from 'axios';
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';
import { useSearchParams } from 'react-router-dom';

function PartnerLogin() {
  const { partnerStates, token, loginDetails, serviceCred, partnerData } = useSelector(state => state)
  const [resp, setRes] = useState(false)
  const [checked, setChecked] = useState(true);
  const [device_id, setDevice_id] = useState("");
  const [loader, setLoader] = useState(true);
  const [rocket, setRocket] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  let dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue
  } = useForm({
    defaultValues: {
      mobile: "",
    }, mode: "onBlur"
  });
  const data = watch();

  function knowdevice() {
    if (window.innerWidth > 1024) {
      setDevice_id("1");
    }
    else if (window.innerWidth > 480 && window.innerWidth < 1024) {
      setDevice_id("3")
    }
    else {
      setDevice_id("2");
    }
  }

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

  useEffect(() => {
    if (token == "") {
      myToken();
      knowdevice();
    }

  }, [])

  useEffect(() => {
    if (partnerData.dashData) {
      setValue('mobile', partnerData.dashData.customer_phone.toString())
      setLoader(false);
    }
  }, [partnerData])



  const NumberOnly = (value) => {
    var lastseven = value.substr(value.length - 7);
    var num_charstring_number = value.substr(4, 4);
    var last_digit = value.slice(0, 2);
    var cr_str = Array(6).join(last_digit);
    var result = false;
    if (/^[6789]\d{9}$/i.test(value)) {
      result = true;
    }
    if (value === '9876543210') {
      result = false;
    }
    if (/^(\d)\1+$/.test(lastseven)) {
      result = false;
    }
    if (value === cr_str) {
      result = false;
    }
    if (num_charstring_number == 'XXXX') {
      result = true;
    }
    if (/(\d)\1{6,}/.test(value)) {
      result = false;
    }
    return result;

  }

  const handleUpKey = (value, field) => {
    var regexp = /[^0-9]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""))
    }
  }

  const onSubmit = async (data) => {
    setRes(true)
    //console.log(data);
    dispatch(updateLoginDetails({ ...loginDetails, mobile: data.mobile }));

    delete data.whatsapp
    data = {
      ...data,
      login_type: "OTP",
      source_from: 1,
      api_mode: userId ? "TEST" : "LIVE",
      login_from: 8,
      // IP: ip,
      device_type: +(device_id),
      customer_id: 0,
      lead_id: 0
    };
    // const payload = {
    //   client_id: client_id,
    //   client_key: client_key,
    //   source: source,
    // };

    const APIDATA = await SendOTP(data, token);
    if (APIDATA.status_code === "200" && APIDATA.status === "1") {
      // console.log(APIDATA);
      if (APIDATA.data.otp_id == "") {
        dispatch(updatePartnerState({ ...partnerStates, PartnerLogin: false, PartnerWrong: true }))
      }
      else {
        dispatch(updateservicecredential({ ...serviceCred, Otp_id: APIDATA.data.otp_id }))
        dispatch(updatePartnerState({ ...partnerStates, PartnerLogin: false, PartnerOtp: true }))
      }
    } else {

    }
  }

  useEffect(() => {
    if (data.mobile) {
      if (6 <= data.mobile.toString().charAt(0) && data.mobile.toString().charAt(0) <= 9) {
        clearErrors('mobile')
      }
      else {
        setError('mobile', { type: 'custom' });
      }
    }
  }, [data.mobile])

  const handleCheckBox = () => {
    if (!resp) {
      setChecked(!checked)
    }
  }

  const handleError = (e) => {
    setRocket(false);
    onSubmit(data);
    // gettingerror('api error',mw_application_id)
  }

  return (
    <>
      {loader && <CKYCWaiting />}
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      <div className="">
        <div className="partners-right-form-sub">
          <div className="partners-right-form-heading">
            <p>Hey, <span>{partnerData.dashData.customer_name} !</span></p>
          </div>
          <div className="partner-input-number">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
              <div
                className={"login-form-border" + ((errors.mobile && data.mobile) ? " red-error-border" : "")}>
                <img src={phone} alt=''/>
                <input minLength={10} maxLength={10}
                  type="tel"
                  autoComplete="off"
                  id="login-input"
                  placeholder="Mobile Number"
                  {...register("mobile", {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                    validate: NumberOnly
                  })}
                  onKeyUp={() => handleUpKey(data.mobile, "mobile")}
                  autoFocus
                  disabled={resp}
                />
                {errors.mobile && data.mobile && <div className="number-login-err">Enter a valid number</div>}
              </div>
              <p className="login-form-content-under-whatsapp" onClick={() => handleCheckBox()}>
                <div className="login-whatsapp-check">
                  <div><input type="checkbox" checked={checked} readOnly /></div>
                  <div className="login-auth-text">
                    I authorize MoneyWide to verify my PAN, mobile no., contact via call/SMS/Email &amp; access my credit report. I agree to the terms of the <a href="/disclaimer" className="TandC-link" target={"_blank"}>
                      User Consent Form
                    </a>{", "}
                    <a href="/policies" className="TandC-link" target={"_blank"}>
                      Privacy Policy
                    </a>{" & "}
                    <a href="/terms-and-conditions" className="TandC-link" target={"_blank"}>
                      Terms and Conditions
                    </a>
                    .
                  </div>
                </div>
              </p>
              <button id="last" disabled={resp || !checked} type="submit" className={"login-button" + (!errors.mobile && data.mobile.length === 10 && checked ? " login-btn-active" : " ")}>
                {!resp ? "Get OTP" : <div className='continue-lottie'>
                  <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                </div>}
              </button>
            </form>
          </div>
          {partnerData.dashData.loan_amount && <div className="pre-aprove-partner-offer">
            <div className="pre-aprove-space">
              <div className="pre-apprv-head">
                <p>Your pre-approved offer of upto</p>
              </div>
              <div className="pre-apprve-sub">
                <div className="pre-aprv-left">
                  <div className="pre-apprv-inner-head">
                    <p><b>₹ {partnerData?.dashData?.loan_amount?.toLocaleString("en-IN")}</b> <span>is ready</span></p>
                  </div>
                  <div className="pre-aprv-cont-main">
                    <div className="pre-aprv-cont">
                      <span>Tenure</span>
                      <p>{partnerData.dashData.tenure} Months</p>
                    </div>
                    <div className="pre-aprv-cont">
                      <span>ROI(PM)</span>
                      <p>{(partnerData.dashData.roi / 12).toFixed(2)} %</p>
                    </div>
                  </div>
                </div>
                <div className="pre-apprve-right">
                  <img src={preaproveoffer} alt='preaproveoffer' />
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </>
  )
}

export default PartnerLogin