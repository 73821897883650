import React from 'react';
import './LoginPopUp.css';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { updateMasterState } from '../../Redux/action';
import MainLoader from '../MainLoader/MainLoader';

const PopUp = () => {
    const dispatch = useDispatch();
    const { master_state } = useSelector(state => state);

    useEffect(() => {
        // console.log(master_state.values);
    }, [])

    const ReturnThisOne = (row, event) => {
        if (master_state.pol === null) {
            dispatch(updateMasterState({ ...master_state, pol: row, status: false, values: [] }));
        }
        if (master_state.ms === null) {
            dispatch(updateMasterState({ ...master_state, ms: row, status: false, values: [] }));
        }
        if (master_state.hq === null) {
            dispatch(updateMasterState({ ...master_state, hq: row, status: false, values: [] }));
        }
        if (master_state.et === null) {
            dispatch(updateMasterState({ ...master_state, et: row, status: false, values: [] }));
        }
        if (master_state.ovd === null) {
            dispatch(updateMasterState({ ...master_state, ovd: row, status: false, values: [] }));
        }
        if (master_state.extra === null) {
            dispatch(updateMasterState({ ...master_state, extra: row, status: false, values: [] }));
        }
        if (master_state.pt === null) {
            dispatch(updateMasterState({ ...master_state, pt: row, status: false, values: [] }));
        }
        if (master_state.oc === null) {
            dispatch(updateMasterState({ ...master_state, oc: row, status: false, values: [] }));
        }
        if (master_state.pf === null) {
            dispatch(updateMasterState({ ...master_state, pf: row, status: false, values: [] }));
        }
        event.stopPropagation();
    }

    const BackToMain = () => {
        if (master_state.pol === null) {
            dispatch(updateMasterState({ ...master_state, pol: false, status: false, values: [] }));
        }
        if (master_state.ms === null) {
            dispatch(updateMasterState({ ...master_state, ms: false, status: false, values: [] }));
        }
        if (master_state.hq === null) {
            dispatch(updateMasterState({ ...master_state, hq: false, status: false, values: [] }));
        }
        if (master_state.et === null) {
            dispatch(updateMasterState({ ...master_state, et: false, status: false, values: [] }));
        }
        if (master_state.ovd === null) {
            dispatch(updateMasterState({ ...master_state, ovd: false, status: false, values: [] }));
        }
        if (master_state.extra === null) {
            dispatch(updateMasterState({ ...master_state, extra: false, status: false, values: [] }));
        }
        if (master_state.pt === null) {
            dispatch(updateMasterState({ ...master_state, pt: false, status: false, values: [] }));
        }
        if (master_state.oc === null) {
            dispatch(updateMasterState({ ...master_state, oc: false, status: false, values: [] }));
        }
        if (master_state.pf === null) {
            dispatch(updateMasterState({ ...master_state, pf: false, status: false, values: [] }));
        }
        // if (master_state.lc === null) {
        //     dispatch(updateMasterState({ ...master_state, lc: false, status: false, values: [] }));
        // }
        // if (master_state.In === null) {
        //     dispatch(updateMasterState({ ...master_state, In: false, status: false, values: [] }));
        // }
        
    }

    return (
        <>
            {
                typeof (master_state.values) === "object" ?
                    <div className="blur-background" onClick={() => BackToMain()}>
                        <div className="master-popUp-content" >
                            <div className="master-popUp-heading">{master_state.heading}</div>
                            <div className="master-popUp-options">
                                {master_state.values.length ? master_state.values.map((row, ind) => {
                                    return <div key={ind} onClick={(event) => ReturnThisOne(row, event)} className="master-content">{row.value}</div>
                                }): ""}
                            </div>
                        </div>
                    </div> :
                    <MainLoader />
            }
        </>
    )
}

export default PopUp;