import React, { useEffect } from 'react'
import FAQSection from '../FAQSection/FAQSection';
import EligibilityCriteria from '../PersonalLoan/images/eligibility-criteria.webp';
import greenticknew from '../../Icons/greenticknew.svg';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import MobileFooter from '../Footer/MobileFooter';
import FeaturesBenefits from '../PersonalLoan/FeaturesBenefits';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import StepSlider from '../HowToApplyPLSLIDER/StepSlider';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';
import EligibilityCriteriaSection from '../PersonalLoan/EligibilityCriteria';
const HomeRenovation = () => {
    var loan_type_id_page = 56;
    const [data, setData] = React.useState({ sub_bread: " Personal Loan For Home Renovation" });
    const dispatch = useDispatch()
    const { formStates, PLLoader, mwpages } = useSelector(state => state)
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY <= 200 || window.scrollY >= 3137) {
            sety(false);
        }
        else {
            sety(true);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])

    const dataa = {
        heading: [
            {
                left: "Eligibility Criteria for ",
                right: "House Renovation Loan"
            }
        ],
        subhead: [
            {
                content: "All salaried professionals working at a private sector company or public undertaking."
            },
            {
                content: "The age of the borrower at the time of filing for a loan must be between 21 to 45 years."
            },
            {
                content: "A customer with a minimum credit score of 650 can apply for a personal loan for home renovation at MoneyWide."
            }
        ]
    }
    const datafandb= {
        givendata:[
        {
        span:"100% digital process ",
        para:"to renovate your house",
    },
    {
        span:"Quick approval and",
        para:"disbursement process",
    },
    {
        span:"Same day",
        para:"loan disbursement",
    },
    {
        span:"No Hidden Charges",
        para:"on home renovation loan",
    },
    {
        span:"Flexible repayment tenure",
        para:"upto 36 months",
    },
    {
        span:" No collateral required",
        para:" to get a renovation loan",
    }
    ]
    }

    return (
        <>
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi />
            <div className='journey-stop'>
                <NewLoginForm pageType={loan_type_id_page} mapData={data} />
                {y && <RightSidePopUp />}
                <div className='ploan-content1 mob-margin-top web-margin-top marr what-is-extra'>
                    <h2 className='heading-h2' style={{ marginBottom: "18px" }}><b>Home Renovation</b> Loan</h2>
                    <p className='heading-p' >Your home is a representation of amenities and comfort that you have built for your family for many years. The home is not just a place to live, it is an accumulation of memories, joy, and familiarity. It should be your utmost priority to maintain your home structure for better living. For that purpose, you need a MoneyWide personal loan for home renovation to transform your existing home into a better place.</p>
                    <p className='heading-p'>A personal loan for home renovation helps you get funds for home repairs and renovations of an existing house. The loan helps by providing funds for any expense, like painting, flooring, waterproofing, sanitary work, etc. You can avail a completely digital home renovation loan of upto ₹ 3,00,000 at affordable interest rates. The process requires minimal documentation and have flexible loan terms.</p>
                </div>

                <EligibilityCriteriaSection elgdata={dataa} />
                <FeaturesBenefits features={datafandb}/>
                <StepSlider />
                {/* <section className='FAQs'>
                    <FAQSection />
                </section> */}
                {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                    <FAQSectionChk data={mwpages.faqdata} />
                </section> : ""}
            </div>
            <div className='journey-start' style={{ display: "none" }}>
                {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                {(!formStates.IsIframe) && <MobileFooter />}
            </div>
        </>
    )
}

export default HomeRenovation