import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { set } from "react-hook-form";
import "../Journey Form/Form/otp.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  prodbase_url,
  base_url,
  client_id,
  client_key,
  source,
  verification_source,
  verified_by,
  verifier_name
} from "../Secret_Credentials/Secret_Credential";
import { GenerateToken, updateFormState, updateMwApplicationId, updatePartnerData, updatePartnerState, updateservicecredential, updateserviceshow } from "../../Redux/action";
import { form_journey_action, updateCredentialsState, change_personal_loan_page, updateLoginDetails } from "../../Redux/action";
import { getToken, SendOTP } from "../EverCalledFunctions/EverCalledApis";
import queryString from "query-string";
import Base64 from 'base-64';
import { handleKeyPress } from "../EverCalledFunctions/EverCalledFunctions";

const PartnerOTP = () => {
  const [timer, setTimer] = useState(30);
  const [show, setShow] = useState(true);
  const { partnerStates, loginDetails, serviceCred, personal_loan, token, credentialState, partnerData } = useSelector(state => state);
  const dispatch = useDispatch();
  const [firstSubmit, setFirstSubmit] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    },
  });
  const data = watch();
  const [ip, setIP] = useState('');
  const [device_id, setDevice_id] = useState();
  const [count, setCount] = useState(1);
  const [flag, setFlag] = useState('');

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }


  useEffect(() => {
    if (token == "") {
      myToken();
    }
    if (serviceCred.count === 5) {
      setShow(false);
    }
    let stamp = queryString.parse(window.location.search);
    if (stamp && stamp.reference_id) {
      try {
        let fl = stamp.reference_id
        fl = Base64.decode(fl)
        fl = Base64.decode(fl)
        setFlag(fl)
      }
      catch {

      }

    }
    // console.log(serviceCred.Otp_id)
    // knowdevice();
    // getData()
  }, [])

  useEffect(() => {
    if (token !== "") {
      if (data.otp4) {
        document.getElementById("forth-tag").blur();
      }
      if ((data.otp1 && data.otp2 && data.otp3 && data.otp4) && (firstSubmit == false) && (count == 1)) {
        setCount(2)
        const otp = data.otp1 + data.otp2 + data.otp3 + data.otp4;
        const result = {
          source: source,
          otpId: serviceCred.Otp_id,
          otp,
          mobile: loginDetails.mobile,
          whatsapp_consent: loginDetails.whatsaap ? "1" : "0",
          source_from: 1,
          "device_type": +(""),
          verification_source,
          verified_by,
          verifier_name,
          // device_type: +(device_id),
          // IP: ip,
          // lead_id: 0,
          // customer_id: 0,
          login_from: 8,
          "utm_source": partnerStates.utm ? partnerStates.utm : "",
          "reference_id": flag ? flag : ""
        };
        const payLoad = {
          client_id: client_id,
          client_key: client_key,
          source: source,
        };
        axios
          .post(`${prodbase_url}verify-otp`, result, {
            headers: {
              token,
            },
          })
          .then((response) => {
            if ((response.data.status_code === "200") && (response.data.status === "1")) {
              dispatch(updatePartnerData({ ...partnerData, dashData: response.data.data }))
              const cust_id = response.data.data.cust_id;
              dispatch(updateCredentialsState({ ...credentialState, cust_id }));
              if (response.data.data.loan_amount == "") {
                dispatch(updatePartnerState({ ...partnerStates, Sorry: true }))
              }
              else {
                axios.post(`${prodbase_url}web/get-dashboard-lpdata`, {
                  "customer_id": cust_id,
                  "mobile": loginDetails.mobile,
                  "lead_id": 0,
                  source,
                  "level_id": 1,
                  verified_by,
                  verifier_name,
                  verification_source,
                  "page_url": window.location.href
                }, {
                  headers: {
                    token
                  }
                }).then((res) => {
                  if ((res.data.status == 1) && (res.data.status_code == 200)) {
                    // console.log(res.data);
                    if (res.data.data.mw_application_id) {
                      dispatch(updateMwApplicationId(res.data.data.mw_application_id));
                    }
                    if (res.data.data.step == 1) {
                      dispatch(updatePartnerState({ ...partnerStates, PartnerOtp: false, PartnerProfile: true, Show: true }))
                    }
                    else if (res.data.data.redirectUrl !== "") {
                      window.location.href = res.data.data.redirectUrl;
                    }
                    else {
                      dispatch(updatePartnerState({ ...partnerStates, PartnerOtp: false, PartnerProfile: true, Show: true }))
                    }
                  }
                  else {
                    dispatch(updatePartnerState({ ...partnerStates, PartnerOtp: false, PartnerProfile: true, Show: true }))
                  }
                })
              }
            } else {
              setFirstSubmit(true);
            }
          });
        // });
      }
    }
  }, [data, token, count]);

  const onSubmit = (data) => {
    // console.log(data);
  };

  const handleChange = (nextLocation, prevLocation, event, value) => {
    setValue(value, event.target.value);
    if (event.target.value.length > 0) {
      document.getElementById(nextLocation).focus();
    }
  };

  const handleKeyDown = (event, prevLocation) => {
    const key = event.keyCode || event.charCode;
    if (key == 8 || key == 46) {
      if (event.target.value.length === 0) {
        document.getElementById(prevLocation).focus();
      }
    }
  };

  const handleClick = (e) => {
    if (firstSubmit) {
      setValue("otp1", "");
      setValue("otp2", "");
      setValue("otp3", "");
      setValue("otp4", "");
      setFirstSubmit(false);
      document.getElementById("first-tag").focus();
    }
  };

  const handleotp = async () => {
    // setTimer(30)
    setShow(false);
    const resendotp = {
      mobile: loginDetails.mobile,
      login_type: "OTP",
      // source_from: 1,
      api_mode: "LIVE",
      // verified_by,
      // verification_source,
      // verifier_name,
      login_from: 8,
      "utm_source": partnerStates.utm ? partnerStates.utm : ""
      // source: source,
      // device_type: +(device_id),
      // IP: ip,
      // lead_id: 0,
      // customer_id: 0,
      // login_from: 1
    };

    const APIDATA = await SendOTP(resendotp, token);
    dispatch(updateservicecredential({ ...serviceCred, Otp_id: APIDATA.data.otp_id }))
    setValue("otp1", "");
    setValue("otp2", "");
    setValue("otp3", "");
    setValue("otp4", "");
    setFirstSubmit(false);
    document.getElementById("first-tag").focus();
  };

  // if (res.data.data.otp_id) {
  //   dispatch(
  //     updateserviceshow({
  //       ...setService,
  //       serviceLogin: false,
  //       serviceOtp: true,
  //       count: res.data.data.count 
  //     })
  //   );
  //   dispatch(
  //     updateservicecredential({
  //       ...serviceCred,
  //       Otp_id: res.data.data.otp_id,
  //     })
  //   );
  // }

  // const handleKeyPress = (event) => {
  //   if (!/[0-9]/.test(event.key)) {
  //     event.preventDefault();
  //   }
  // };

  const handleNavigation = (e) => {
    dispatch(updatePartnerState({ ...partnerStates, PartnerOtp: false, PartnerLogin: true }));
    e.stopPropagation();
  };

  useEffect(() => {
    timer > 0 &&
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
  }, [timer]);

  return (
    <>
      <div style={{ padding: "30px 0" }}>
        {/* write code here */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete={"off"}
          autoCorrect="false"
        >
          <div className="otp-form-body">
            <div className="otp-form-top">
              <p className="otp-head">Enter OTP</p>
              <p className="otp-head-p">
                Please type the verification code sent on <br />
                +91-{loginDetails.mobile}
                {/* +91-9548913691  */}
                {personal_loan.servicemobile && <span className="sp-otp-content" onClick={(e) => handleNavigation(e)} >(change)</span>}
              </p>
            </div>
            <div className="enter-otp ">
              <div
                className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
              >
                <input maxLength={1}
                  id="first-tag"
                  type="tel"
                  {...register("otp1", { required: true })}
                  onKeyPress={(event) => handleKeyPress(event)}
                  onChange={(event) =>
                    handleChange("second-tag", "first-tag", event, "otp1")
                  }
                  onKeyDown={(event) => handleKeyDown(event, "first-tag")}
                  onClick={(e) => handleClick(e)}
                  autoFocus
                />
              </div>
              <div
                className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
              >
                <input maxLength={1}
                  id="second-tag"
                  type="tel"
                  {...register("otp2", { required: true })}
                  onKeyPress={(event) => handleKeyPress(event)}
                  onChange={(event) =>
                    handleChange("third-tag", "first-tag", event, "otp2")
                  }
                  onKeyDown={(event) => handleKeyDown(event, "first-tag")}
                  onClick={(e) => handleClick(e)}
                />
              </div>
              <div
                className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
              >
                <input maxLength={1}
                  id="third-tag"
                  type="tel"
                  {...register("otp3", { required: true })}
                  onKeyPress={(event) => handleKeyPress(event)}
                  onChange={(event) =>
                    handleChange("forth-tag", "second-tag", event, "otp3")
                  }
                  onKeyDown={(event) => handleKeyDown(event, "second-tag")}
                  onClick={(e) => handleClick(e)}
                />
              </div>
              <div
                className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
              >
                <input maxLength={1}
                  id="forth-tag"
                  type="tel"
                  {...register("otp4", { required: true })}
                  onKeyPress={(event) => handleKeyPress(event)}
                  onChange={(event) =>
                    handleChange("forth-tag", "third-tag", event, "otp4")
                  }
                  onKeyDown={(event) => handleKeyDown(event, "third-tag")}
                  onClick={(e) => handleClick(e)}
                />
              </div>
            </div>
            {serviceCred.Otp_id ? <>{firstSubmit ? <div className="otp-timer-slot err">
              <span >Invalid OTP </span>
            </div> : show && <div className="otp-timer-slot">
              {timer !== 0 && <span>RESEND OTP : </span>}
              {timer !== 0 ? <span className="resend-option" >{timer} Seconds</span>
                : <span className="resend-option" id="pointer-cur" onClick={handleotp}>RESEND OTP</span>}
            </div>}</>
              : <div className="limit-over err-text">You have exhausted daily OTP limit. <br />
                Please try after 24 hours.</div>}
            <div>
              {" "}
              <button
                id="last"
                className={
                  "otp-btn " +
                  (data.otp1 &&
                    data.otp2 &&
                    data.otp3 &&
                    data.otp4 &&
                    !firstSubmit
                    ? "activate-btn"
                    : "")
                }
                onClick={() => setFirstSubmit(true)}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PartnerOTP;
