import React from "react";
import { useForm } from "react-hook-form";
import email from "./../../../../Icons/email.svg";
import EmailVerificationImg from "../../../../Icons/EmailVerification.svg";
import axios from "axios";
import Lottie from 'react-lottie-player';
import {
  prodbase_url,
  client_id,
  client_key,
  source,
  verification_source,
  verified_by,
  verifier_name
} from "../../../Secret_Credentials/Secret_Credential";
import { useSelector, useDispatch } from "react-redux";
import { GenerateToken, updateFormState } from "../../../../Redux/action";
import ctaAnimation from "../../../assests/lootie-files/continue.json";
import { getToken } from "../../../EverCalledFunctions/EverCalledApis";
import { AxiosThirty } from "../../../AxiosConfiguration/AxiosConfig";
import NewErrorCase from "./NewErrorCase";
import { apiInstance } from "../../../Config/APIConfig";
const EmailChange = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "all",
  });
  const dispatch = useDispatch();
  const [resp, setResp] = React.useState(false);
  const [rocket, setRocket] = React.useState(false);

  const { mw_application_id, credentialState, loginDetails, formStates, personal_loan, token } =
    useSelector((state) => state);

  const data = watch();

  const SpaceManage = (field, value) => {
    if (value) {
      setValue(field, value.replace(/^\s+/, ''));
    }
  };

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, [])

  const onSubmit = async () => {
    setResp(true);
    let decEmail = await apiInstance.post(`email/update`, {
      "customer_id": credentialState.cust_id,
      "lead_id": mw_application_id,
      "level_id": 1,
      "email": data.email,
      "source": "MWide",
      "email_type": 1,
      "verified_by": verified_by,
      "verifier_name": verifier_name,
      "verification_source": verification_source,
    }, {
      token
    })
    // .then((res) => {
      if (decEmail.message === "Success") {
        dispatch(updateFormState({ ...formStates, ChangeEmail: false, Email: true }))
      }
    // }).catch((err) => {
    //   setRocket(true);
    // });
  };

  const handleError = (e) => {
    window.location.reload();
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
        <div className="email-verification">
          <div className="email-verification-img">
            <img src={EmailVerificationImg} alt="" />
          </div>
          <div className="email-verification-content">
            <span>Verify your Email</span>

            <p>
              To move ahead with your loan journey verify your email by entering
              the OTP as sent on
              <br />
            </p>
          </div>
          <div
            className={
              "input-tag remove-bottom" +
              (errors.email && data.email
                ? " error-border"
                : data.email
                  ? " add-border"
                  : "")
            }
          >
            <img className="tag-icon" src={email} />
            <input disabled={resp}
              className="input-tag-box"
              type="text"
              placeholder="Email Address"
              {...register("email", {
                required: true,
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                },
              })}
              autoComplete="off"
              onKeyUp={() => SpaceManage("email", data.email)}
            />
          </div>
          {errors.email && data.email && (
            <div className="err-text remove-bottom">Invalid Email Address</div>
          )}
          <button
            id="last"
            className={
              "footer-btn Tmargin Bmargin" +
              (data.email && !errors.email ? " footer-btn-activate" : "")
            }
          >
            {!resp ? "Update Email" :
              <div className='continue-lottie'>
                <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
              </div>
            }
          </button>
        </div>
      </form>
    </>
  );
};

export default EmailChange;
