 import React, { useEffect, useRef, useState } from 'react';


 export const panValidation = (val) => {
    const pan = val.trim();
    const pattern = /([a-zA-Z]){3}([pP]){1}([A-Za-z]){1}([0-9]){4}([a-zA-Z]){1}$/;
    if (!pattern.test(pan)) {
        return false;
    }
    else{
        return true;
    }
    
  };

  export const DateValidator = (val)=>{
    const data = val.trim();
    const pattern = /^\d{4}-\d{2}-\d{2}$/;
    if(!pattern.test(data)){
      return false;
    }
    else{
      return true
    }
  }

export const phnNumberValidation = (val) => {
    const number = val.trim();
    const pattern = /^[6-9]\d{9}$/;
    //return pattern.test(number);
    if (!pattern.test(number)) {
        return false;
    }
    else{
        return true;
    }
  };

  export const  NumberOnly = (value) => {
    const val = value.trim();
    var lastseven = val.substr(val.length - 7);
    var num_charstring_number = val.substr(4, 4);
    var last_digit = val.slice(0, 2);
    var cr_str = Array(6).join(last_digit);
    var result = false;
    if (/^[6789]\d{9}$/i.test(val)) {
      result = true;
    }
    // if (val === '9876543210') {
    //   result = false;
    // }
    if (/^(\d)\1+$/.test(lastseven)) {
      result = false;
    }
    if (val === cr_str) {
      result = false;
    }
    if (num_charstring_number == 'XXXX') {
      result = true;
    }
    if (/(\d)\1{6,}/.test(val)) {
      result = false;
    }
    return result;

  }

  export const DesiredLoanAmt = (val) => {
    const number = val.trim();
    if(number >= 10000){
    return true;
    }
    else{
        return false;
    }
  };

  export const Salary = (val) => {
    const number = val.trim();
    if(number >= 10000){
    return true;
    }
    else{
        return false;
    }
  };

  export const OtpValidation = (val) => {
    const number = val.trim();
    if(number.length ==4){
    return true;
    }
    else{
        return false;
    }
  };


  export const EmailValidation = (val) => {
    const email = val.trim();
    const pattern = /^([a-zA-Z0-9_.+-]{3,64})+\@(([a-zA-Z0-9-]{2,255})+\.)+([a-zA-Z0-9]{2,8})+$/;
    if (!pattern.test(email)) {
        return false;
    }
    else{
        return true;
    }
    
  };

  export const PanRunTimeValidation = (val) => {
    const len = val.length;
    // /([a-zA-Z]){3}([pP]){1}([A-Za-z]){1}([0-9]){4}([a-zA-Z]){1}$/;
    const pat_1 = /([a-zA-Z]){1}$/
    const pat_2 = /([a-zA-Z]){2}$/
    const pat_3 = /([a-zA-Z]){3}$/
    const pat_4 = /([a-zA-Z]){3}([pP]){1}$/
    const pat_5 = /([a-zA-Z]){3}([pP]){1}([A-Za-z]){1}$/
    const pat_6 = /([a-zA-Z]){3}([pP]){1}([A-Za-z]){1}([0-9]){1}$/
    const pat_7 = /([a-zA-Z]){3}([pP]){1}([A-Za-z]){1}([0-9]){2}$/
    const pat_8 = /([a-zA-Z]){3}([pP]){1}([A-Za-z]){1}([0-9]){3}$/
    const pat_9 = /([a-zA-Z]){3}([pP]){1}([A-Za-z]){1}([0-9]){4}$/
    const pat_10 = /([a-zA-Z]){3}([pP]){1}([A-Za-z]){1}([0-9]){4}([a-zA-Z]){1}$/
    // console.log(pat_1);
    if(((len === 1) && pat_1.test(val)) || ((len === 2) && pat_2.test(val)) || ((len === 3) && pat_3.test(val)) || ((len === 4) && pat_4.test(val)) || ((len === 5) && pat_5.test(val)) || ((len === 6) && pat_6.test(val)) || ((len === 7) && pat_7.test(val)) || ((len === 8) && pat_8.test(val)) || ((len === 9) && pat_9.test(val)) || ((len === 10) && pat_10.test(val))) {
      return true;
    }
    else {
      return false;
    }
  }

  export const PincodeValidation = (val) => {
    let validationRegex = /^[1-9][0-9]{2}(\s|s)?[0-9]{3}$/;
    if(validationRegex.test(val)) {
      return true;
    }
    else {
      // console.log("False");
      return false;
    }
  }




  

