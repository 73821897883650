import React from 'react';
import './QualificationPopUp.css';
import '../../Loader/Loader.css';
import {useDispatch,useSelector} from 'react-redux';
import { updatePopUp } from '../../../Redux/action';

function QualificationPopUp() {
    const dispatch = useDispatch();
    const {PopUpState} = useSelector(state=>state)

    
    const returnThisValue=(value)=>{
        const updatedHigherQulificationPopUp={
            show:false,
            value:value
        }
        dispatch(updatePopUp({...PopUpState,HigherQulificationPopUp:updatedHigherQulificationPopUp}))
    }
    return (
        <div className='loaderbackground'>
            <div className='loadertransparent'>
                <div className='QualificationPopUp'>
                    <h2>Highest Qualification</h2>
                    <div className='QualificationPopUp-content'>
                        <ul>
                            <li onClick={()=>returnThisValue("Post Graduate and Above")}>Post Graduate and Above</li>
                            <li onClick={()=>returnThisValue("Graduate")}>Graduate</li>
                            <li onClick={()=>returnThisValue("Under Graduate/ Diploma")}>Under Graduate/ Diploma</li>
                            <li onClick={()=>returnThisValue("Matriculates or less")}>Matriculates or less</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    
    )
}
export default QualificationPopUp;