import React, { useEffect, useState } from 'react'
import './LoanDetails.css'
import greenticknew from '../../Icons/greenticknew.svg';
import ForCloseProceedPopUp from './ForCloseProceedPopUp';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../assests/lootie-files/continue.json';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getForeclosureLink, getLoginData } from './MyAccountAPIs'
import { GenerateToken } from '../../Redux/action'
import { getToken } from '../EverCalledFunctions/EverCalledApis'
import yellownotify from '../../Icons/yellownotify.svg'


const ForeClosureGreen = ({ data, mw, userName, baseData,loanName }) => {
  const [mw_app_id, setMW_app_id] = useState(mw)
  const [resp, setResp] = useState(false)
  const [formStatus, setFormStatus] = useState(1)


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useSelector(state => state)
  useEffect(() => {
    if (token !== "" && !mw) {
      let payLoad = getLoginData()
      if (!payLoad && !payLoad.mw_app_id) {
        navigate('/login')
      }
      else if (payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") {
        setMW_app_id(payLoad.mw_app_id)
      }
    }
  }, [token])


  const myToken = async () => {
    const T = await getToken();
    dispatch(GenerateToken(T))
  }

  useEffect(() => {
    if (token === "") {
      myToken()
    }
  }, [])

  const handlePay = async () => {
    setResp(true)
    const APIResponse = await getForeclosureLink(mw_app_id, data.amount, token)
    if (APIResponse.status_code === "200" && APIResponse.status === "1") {
      if (APIResponse.data.changes.paymentLink && APIResponse.data.changes.paymentLink !== "") {
        var link = document.createElement('a');
        link.href = APIResponse.data.changes.paymentLink;
        document.body.appendChild(link);
        link.target = "_blank";
        link.style.zIndex = "-1";
        link.click();
        document.body.removeChild(link);
        setFormStatus(2)
        setResp(false)
      } else {
        setFormStatus(3)
      }
    } else if (APIResponse.data.status_code === "429" && APIResponse.data.status === "0") {
      setFormStatus(3)
    }else{
      setFormStatus(3)
    }
  }

  return (
    <>
      {formStatus === 2 && <ForCloseProceedPopUp userName={userName} baseData={baseData} loanName= {loanName} />}
      {formStatus === 3 && <div className="foreclose-yellow-box">
        <img src={yellownotify} alt="yellownotify" />
        <span>Dear {userName},<br />
          Sorry for the inconvenience. We are facing some technical error. Please try again after some time.<br />
          Regards<br />
          Team Moneywide</span>
      </div>}
      {formStatus!==3 && <>
        <div className="forclosegreenbox">
          <img src={greenticknew} alt="greentick" />
          <span>Dear <b>{userName}</b>,<br />
            Based on the details  shared by you, you have  to pay following amount to make Forclosure on your active {baseData.programName} {baseData.Id}.</span>
        </div>
        <div className='forclose-proceedbox'>
          <div className="forclose-all-amnts">
            <div className="forclose-amnts">
              <span>Total Amount</span>
              <p>Rs. {data?.amount?.toLocaleString('en-IN')}</p>
            </div>
            <div className="forclose-amnts">
              <span>Principal Amount</span>
              <p>Rs. {data?.principalPortion?.toLocaleString('en-IN')}</p>
            </div>
            <div className="forclose-amnts">
              <span>Fees and Charges</span>
              <p>Rs. {data?.feeChargesPortion?.toLocaleString('en-IN')}</p>
            </div>
            <div className="forclose-amnts">
              <span>Interest Amount</span>
              <p>Rs. {data?.interestPortion?.toLocaleString('en-IN')}</p>
            </div>
            <div className="forclose-amnts">
              <span>Penalty Charges</span>
              <p>Rs. {data?.penaltyChargesPortion?.toLocaleString('en-IN')}</p>
            </div>
          </div>
          <div className='forclose-proceed-btn'>
            <button disabled={resp} onClick={() => handlePay()} className='fc-proceed-green'>
              {!resp ?
                "Proceed to Pay Now" :
                <div className="continue-lottie">
                  <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                </div>}
            </button>
          </div>
        </div>
      </>}

    </>
  )
}

export default ForeClosureGreen