import React from 'react';
 
function StepsliderContentRight (props) {
    return (
        <div className="sp" id={props.id}>
            <h3 style={{color:"#2917a4"}}>{props.sheading}</h3>
            <p>{props.spera}</p>
            <img src={props.simg} alt="Step" />
        </div>
    );
}
export default StepsliderContentRight;