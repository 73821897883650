import React, { useState, useEffect } from 'react'
import LoanDetails from './LoanDetails'
import "./LoanDetails.css"
import back from '../../Icons/back.svg'
import ForeClosureLoanBox from './ForeClosureLoanBox'
import yellownotify from '../../Icons/yellownotify.svg'
import ForeClosureGreen from './ForeClosureGreen'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GetRepaymentDetailsAPI, getLoginData } from './MyAccountAPIs'
import { GenerateToken } from '../../Redux/action'
import { getToken } from '../EverCalledFunctions/EverCalledApis'
import CKYCWaiting from '../LoginPopUp/CKYCWaiting'
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase'

const ForeClosure = () => {
  const [mw, setMW] = useState("")
  const [userName, setUserName] = useState("")
  const [loader, setLoader] = useState(true)
  const [APIErr, setAPIErr] = useState(false)
  const [loanData, setLoanData] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useSelector(state => state)
  const [pageStatus, setPageStatus] = useState("")
  const [successPageData, setSuccessPageData] = useState({})
  const [baseData, setBaseData] = useState({
    programName: "",
    Id: ""
  })
  const [loanName, setLoanName] = useState("loan")

  const getUserData = async (mw_app_id) => {
    const APIResponse = await GetRepaymentDetailsAPI(mw_app_id, token);
    if (APIResponse.status_code === "200" && APIResponse.status === "1") {
      setLoanData(APIResponse.data)
      if (APIResponse.data.checkForeclosure == 1) {
        setPageStatus(1)
      } else {
        setPageStatus(3)
      }

      setLoader(false)
      setBaseData({ ...baseData, programName: APIResponse.data.programName, Id: APIResponse.data.accountNo })
    } else {
      setLoader(false)
      setAPIErr(true)
    }
  }

  useEffect(() => {
    if (token !== "") {
      let payLoad = getLoginData();
      if(payLoad.lead_source_flag == 1){
        setLoanName("healthcare credit")
      }
      
      if (!payLoad && !payLoad.mw_app_id) {
        navigate('/login')
      }
      else if ((payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") || (payLoad.userName != undefined && payLoad.userName !== "")) {
        if (payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") {
          getUserData(payLoad.mw_app_id);
          setMW(payLoad.mw_app_id)
        }
        if (payLoad.userName != undefined && payLoad.userName !== "") {
          setUserName(payLoad.userName)
        }
      }
    }
  }, [token])



  const myToken = async () => {
    const T = await getToken();
    dispatch(GenerateToken(T))
  }

  useEffect(() => {
    if (token === "") {
      myToken()
    }
  }, [])


  const manageRefresh = () => {
    setLoader(true)
    setAPIErr(false)
    let payLoad = getLoginData()
    if (!payLoad && !payLoad.mw_app_id) {
      navigate('/login')
    }
    else if (payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") {
      getUserData(payLoad.mw_app_id);
      setMW(payLoad.mw_app_id)
    }
  }

  return (
    <>
      {APIErr && !loader && <NewErrorCase handleClick={manageRefresh} />}
      {loader && <CKYCWaiting />}
      {!loader && !APIErr && <div className='transaction-main'>
        <div className='trasaction-head'>
          <a href="/myaccount/myloan"><img className="" src={back} alt="back-img" /></a>
          <span>Foreclosure Request</span>
        </div>


        <LoanDetails data={loanData} />
        {pageStatus === 1 && <>
          <ForeClosureLoanBox setPageStatus={setPageStatus} setSuccessPageData={setSuccessPageData} /></>
        }
        {pageStatus === 2 && <ForeClosureGreen data={successPageData} mw={mw} userName={userName} baseData={baseData} loanName={loanName}/>}

        {pageStatus === 3 && <div className="foreclose-yellow-box">
          <img src={yellownotify} alt="yellownotify" />
          <span>Dear {userName} , You are not eligible to foreclose your {loanName} today. Please try again after some time.</span>
        </div>}
      </div>}
    </>
  )
}

export default ForeClosure
