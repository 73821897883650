import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { storeEmi } from "../../Redux/action";

function Calculation() {
    const dispatch = useDispatch();
    const {pa, lt, roi} = useSelector((state) => state);
    const [emi, setEmi] = React.useState(0);
    const [tpa, setTpa] = React.useState(0);
    const [ti, setTi] = React.useState(0);
    const [intPer, setIntPer] = React.useState(0);
    const [remPer, setRemPer] = React.useState(0);

    // Total Payable Amount Calculation
    // roi = rate of interest
    // pa = principal amount
    // lt = loan tenure
    const TotalPayableAmount = () => {
        let r = roi / (12 * 100);
        let Emi = (pa * r * Math.pow((1 + r), lt)) / (Math.pow((1 + r), lt) - 1);
        let t_p_a = Emi * lt;
        let t_i = t_p_a - pa;
        let int_per = Math.round((t_i * 100) / t_p_a);
        let rem_per = 100 - int_per;
        setIntPer(int_per);
        setRemPer(rem_per);
        dispatch(storeEmi(Emi));
        setEmi(Math.round(Emi).toLocaleString('hi-IN'));
        setTpa(Math.round(t_p_a).toLocaleString('hi-IN'));
        setTi(Math.round(t_i).toLocaleString('hi-IN'));
    }

    React.useEffect(() => {
        TotalPayableAmount();

    }, [pa, lt, roi])

    React.useEffect(() => {
        document.querySelector(".percentage-box").style.background = `linear-gradient(to right, #F9466D, #F9466D ${intPer}%, #6D57DF ${intPer}%, #6D57DF 100%`;
    },[intPer])

    return (
        <div>
            <div className="calculation-main">
                <div className="calculation-first-half">
                    <div className="calculation-first">
                        <p>Your EMI is</p>
                        <h1>₹ {emi}</h1>
                    </div>
                    <div className="calculation-third">
                        <p>Total Interest<span style={{ color: "#F9466D", padding: "7px 2px 5px 3px", borderRadius: "5px", marginRight: "5px" }} > ({intPer}%) </span></p>
                        <h1>₹ {ti}</h1>
                    </div>  
                </div>
                <div className="calculation-second-half">
                    <div className="calculation-second">
                        <p>Total Amount Payable</p>
                        <h1>₹ {tpa}</h1>
                    </div>
                    <div className="calculation-fourth">
                        <p>Principal Amount<span style={{ color: "#475ef5", padding: "7px 2px 5px 3px", borderRadius: "5px", marginRight: "5px" }} > ({remPer}%)</span></p>
                        <h1>₹ {pa.toLocaleString('hi-IN')}</h1>
                    </div>
                </div>
            </div>
            <div className="percentage-box"></div>
        </div>
    )
}

export default Calculation;