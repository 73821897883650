import React, { useState } from 'react';
import RangeSlider from './RangeSlider';
import { useSelector } from 'react-redux';
import circle from '../PersonalLoan/images/circle.webp'
import pipes from '../PersonalLoan/images/pipes.webp'
import Calculation from './Calculation';
import { useLocation } from 'react-router-dom';
import { react_base_url } from '../Secret_Credentials/Secret_Credential';

function Calculator() {
    const tabletoshow = 10;
    const { pa, lt, roi, emi } = useSelector((state) => state);
    const [state, setState] = React.useState([])
    const [more, setMore] = useState(tabletoshow)
    const [show, setShow] = React.useState(false);
    const location = useLocation();
    const [emiscroll, setEmiScroll] = React.useState(false);

    React.useEffect(() => {
        let prin_cf = pa;
        let arr = [];
        for (let i = 0; i < lt; i++) {
            let int_payable = (roi * prin_cf / 1200);
            let principal_repaid = (emi - int_payable);
            prin_cf = (prin_cf - principal_repaid);
            let payload = {
                key: i + 1,
                interest: Math.round(int_payable),
                principal: Math.round(principal_repaid),
                balance: Math.abs(Math.round(prin_cf))
            }
            arr = [...arr, payload];
        }
        setState(arr)
    }, [pa, lt, roi, emi])

    const handleViewMore = () => {
        if(more < state.length) {
            setMore(more + state.length)
        }
        else {
            let my_ele = document.getElementById("mytbl");
            my_ele.scrollIntoView({behavior: "smooth"});
            setMore(tabletoshow);
        }
        
    };

    React.useEffect(() => {
        const { pathname } = location;
        if (pathname === "/personal-loan-emi-calculator") {
            setEmiScroll(true);
        }
    }, [location])

    const handleScroll = () => {
        if (emiscroll) {
            window.location.href = `${react_base_url}personal-loan`
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }

    const handleChange = (e) => {

    }
    const handleClick = () => {
        setShow(!show);
        if (emiscroll) {
            if (!show) {
                // window.scrollTo({ top: 700, behavior: "smooth" });
                let my_ele = document.getElementById("mytbl");
                my_ele.scrollIntoView({ behavior: "smooth" });
            }
            // if (window.screen.width < 768 || window.innerWidth < 768) {
            //     window.scrollTo({ top: 1000, behavior: "smooth" })
            // }
        }
        else {
            if (!show) {
                // window.scrollTo({ top: 3000, behavior: "smooth" });
                let my_ele = document.getElementById("mytbl");
                my_ele.scrollIntoView({ behavior: "smooth" });
            }
            // if (window.screen.width < 768 || window.innerWidth < 768) {
            //     window.scrollTo({ top: 4000, behavior: "smooth" })
            // }
        }
    }

    return (
        <div className="calculator-main">
            <div className="calculator-head">
                <h3>EMI <b>Calculator</b></h3>
                <p>Skip the manual calculations and use the MoneyWide EMI Calculator to accurately determine your repayment amounts, interest charges, and more.</p>
            </div>
            <div className="calculator-body">
                <div className="calc-bg-first">
                    <img src={circle} alt="circle" />
                </div>
                <div className="calculator-body-left ">
                    <span>MoneyWide Personal Loan</span>
                    <h2>EMI Calculator</h2>
                    <div className="eligib-slide-div">
                        <RangeSlider heading="Loan Amount" symbol={"(₹)"} text1="₹ " text2="" text3={"10000"} text4={"25 L"} id="id1" uid="uid1" min="10000" max="2500000" step="10000" id_in_div="uid-1" value={pa} handleChange={(e) => handleChange(e)} />
                        <RangeSlider heading="Loan Tenure" symbol={"(Months)"} text1="" text2=" Months" text3={"3 Months"} text4={"84 Months"} id="id2" uid="uid2" min="3" max="84" step="1" id_in_div="uid-2" value={lt} handleChange={(e) => handleChange(e)} />
                        <RangeSlider heading="Rate of Interest" symbol={"(%)"} text1="" text2=" %" text3={"5%"} text4={"32%"} id="id3" uid="uid3" min="5" max="32" step="1" id_in_div="uid-3" value={roi} handleChange={(e) => handleChange(e)} />
                    </div>

                </div>
                <div className="calculator-body-right">
                    <h3>Your Result</h3>
                    <Calculation />
                    <div className="calculator-btns">
                        <button className="repayment-schedule-btn" onClick={handleClick} >Repayment Schedule</button>
                        <button className="apply-now-btn" onClick={handleScroll}>Apply Now</button>
                    </div>
                </div>
                <div className="calc-bg-second">
                    <img src={pipes} alt="pipes" />
                </div>
            </div>


            <div className="" id='mytbl'>
                {show && <div className="repay-table">
                    <div className="repay-heading">
                        <span>Repayment <b>Schedule</b></span>
                    </div>
                    <div className="princip-monthly">
                        <div className="princip-cont">
                            <span>Principal Amount: ₹ {pa?.toLocaleString('hi-IN')}</span>
                        </div>
                        <div className="princip-cont">
                            <span>Monthly EMI: ₹ {Math?.round(emi)?.toLocaleString('hi-IN')}</span>
                        </div>
                    </div>
                    <table className='repaytab-cont'>
                        <thead>
                            <tr>
                                <td>Months</td>
                                <td>Balance</td>
                                <td>Interest</td>
                                <td>Principal</td>
                            </tr>
                        </thead>
                        <tbody>
                            {state.slice(0, more)?.map((item) => {
                                return (
                                    <tr key={item.key} >
                                        <td className='calc-td' >{item.key}</td>
                                        <td className='calc-td' >{item?.balance?.toLocaleString('hi-IN')}</td>
                                        <td className='calc-td' >{item?.interest?.toLocaleString('hi-IN')}</td>
                                        <td className='calc-td' >{item?.principal?.toLocaleString('hi-IN')}</td>
                                    </tr>)

                            })}

                        </tbody>
                    </table>
                    {( state.length > 10 &&
                        <button className={more < state?.length ? 'repaybtn' : "repaybtn1"} onClick={handleViewMore}>
                            {more < state?.length ? "View All" : "View Less"}
                        </button>
                    )}
                </div>
                }

            </div>
        </div>
    )
}

export default Calculator;