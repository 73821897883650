import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import { set } from "react-hook-form";
import "../Form.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { GenerateToken, updateCkycDetails, updateCredentialsState, updateDecisionDemog, updateFormState, updateLoginDetails, updateMwApplicationId, updatePartnerState, updatePrystinState, updateservicecredential, updateserviceshow } from "../../../../Redux/action";
import { change_personal_loan_page } from "../../../../Redux/action";
import { getToken, SendOTP, VerifyOTP } from "../../../EverCalledFunctions/EverCalledApis";
import { gettingerror, handleKeyPress } from "../../../EverCalledFunctions/EverCalledFunctions";
import { apiInstance } from "../../../Config/APIConfig";
import { react_base_url, source, verification_source, verified_by, verifier_name } from "../../../Secret_Credentials/Secret_Credential";
import { DecisionDemogOffer } from "../../../EverCalledFunctions/EverCalledPayload";
import Base64 from 'base-64'

const UpiJourneyOTP = () => {
    const [timer, setTimer] = useState(30);
    const [show, setShow] = useState(true);
    const {
        serviceCred,
        serviceNumber,
        setService,
        personal_loan,
        token,
        ckycDetails,
        formStates,
        credentialState,
        mw_application_id,
        loginDetails,
        decisionDemog,
        prystinStates,
        partnerStates
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [firstSubmit, setFirstSubmit] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm({
        defaultValues: {
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
        },
    });
    const data = watch();
    const [ip, setIP] = useState('');
    const [device_id, setDevice_id] = useState();
    const [count, setCount] = useState(1);
    const [upi, setUpi] = useState(true);

    function knowdevice() {
        if (window.innerWidth > 1024) {
            setDevice_id("1");
        }
        else if (window.innerWidth > 480 && window.innerWidth < 1024) {
            setDevice_id("3")
        }
        else {
            setDevice_id("2");
        }
    }

    // const getData = async () => {
    //     const res = await axios.get('https://geolocation-db.com/json/')
    //     setIP(res.data.IPv4)
    // }

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }


    useEffect(() => {
        if (token == "") {
            myToken();
        }
        // if (setService.count === 5) {
        //     setShow(false);
        // }
        knowdevice();
        // getData()
    }, [])

    useEffect(() => {
        if (token !== "") {
            if (data.otp4) {
                document.getElementById("forth-tag").blur();
            }
            if ((data.otp1 && data.otp2 && data.otp3 && data.otp4) && (firstSubmit == false) && (count == 1)) {
                setCount(2);
                SubmitOTP();
            }
        }
    }, [data, token, count]);

    const condition = ((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1));

    let endpoint = "";
    let checkType = "";

    const EligibleBSA = async () => {
        let decisionDec = await apiInstance.post(`decision-epfo-api`, {
            "customer_id": credentialState.cust_id,
            "product_id": 56,
            "username": loginDetails.name ? loginDetails.name : "",
            "mw_application_id": mw_application_id,
            "mobile_no": loginDetails.mobile ? loginDetails.mobile : "",
            "company_name": loginDetails.company_name ? loginDetails.company_name : "",
            "pancard": loginDetails.pan ? loginDetails.pan : "",
            "source": source,
            "verification_source": verification_source,
            "verified_by": verified_by,
            "verifier_name": verifier_name
        }, {
            "token": token
        })
        // .then((res) => {
        gettingerror('epfo hit web', mw_application_id)
        if ((decisionDec.status == 1) && (decisionDec.status_code == 200)) {
            let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test"
            let offerDec = await apiInstance.post(
                `${url}`,
                {
                    "source": source,
                    "mw_application_id": mw_application_id,
                    "product_id": 56,
                    "customer_id": credentialState.cust_id,
                    "offer_type": 1,
                    "level_id": 1,
                    "verified_by": verified_by,
                    "verifier_name": verifier_name,
                    "verification_source": verification_source,
                    "stage_name": "Employment Check"
                },
                {
                    "token": token,
                    "log": 1
                }
            )
            // .then((res) => {
            // if ((res.data.status == 1) && (res.data.status_code == 200)) {
            if (offerDec.status_code === "200") {

            }
            // }


            // });
        }
        // else {
        //   setState(false);
        //   setRocket(true);
        // }
        // })
    }


    const Upimove = async () => {
        let payload = {
            "customer_id": credentialState.cust_id,
            "product_id": 56,
            "gender":
                loginDetails.gender === "Male"
                    ? 1
                    : loginDetails.gender === "Female"
                        ? 2
                        : 3,
            "username": loginDetails.name,
            "dob": loginDetails.dob,
            "mw_application_id": mw_application_id,
            "mobile_no": loginDetails.mobile,
            "pincode": loginDetails.pin.toString(),
            "city_id": decisionDemog.city_id ? decisionDemog.city_id : "",
            "pancard": loginDetails.pan,
            "email": loginDetails.email,
            "source": source,
            "verification_source": verification_source,
            "verified_by": verified_by,
            "verifier_name": verifier_name
        }
        const decDecisionDemog = await apiInstance.post('decision-demog-api', payload, { token })
        if ((decDecisionDemog?.status == 1) && (decDecisionDemog?.status_code == 200)) {
            if (decDecisionDemog?.data.Response.BUREAU.is_phone_update_required == 1) {
                let url = window.location.pathname;
                if (decDecisionDemog?.data.Response.BUREAU.hint.includes("|")) {
                    let pipe = decDecisionDemog.data.Response.BUREAU.hint.split("|");
                    dispatch(updateLoginDetails({ ...loginDetails, equifax_phone_no: pipe, isPan: false, journeyAPI: 1 }));
                    dispatch(updateFormState({ ...formStates, YourProfile: false, EmployeeDetails: false, numberVerification: true, UpiJourneyOTP: false }));

                }
                else {
                         let pipe = [];
                        pipe.push(decDecisionDemog.data.Response.BUREAU.hint)
                        dispatch(updateLoginDetails({ ...loginDetails, equifax_phone_no: pipe, isPan: false, journeyAPI: 1 }));
                        dispatch(updateFormState({ ...formStates, YourProfile: false, EmployeeDetails: false, numberVerification: true, UpiJourneyOTP: false }));
                }
            }
            else if (decDecisionDemog?.data && decDecisionDemog?.data.Response && decDecisionDemog?.data.Response.BUREAU && (decDecisionDemog?.data.Response.BUREAU.is_phone_update_required != 2)) {
                gettingerror('hit demog web', mw_application_id)
                //if (condition) {
                dispatch(updateDecisionDemog({ ...decisionDemog, is_ntc: decDecisionDemog.data.Response.BUREAU.is_ntc_revised, empverification: decDecisionDemog.data.Response.empverification, pan_status: decDecisionDemog.data.Response.pan_status }));
                //}
                // else {
                //   dispatch(updateDecisionDemog({ ...decisionDemog, is_ntc: decDecisionDemog.data.Response.BUREAU.is_ntc_revised, empverification: decDecisionDemog.data.Response.empverification, pan_status: decDecisionDemog.data.Response.pan_status }));
                // }
                if (((decDecisionDemog?.data.Response.BUREAU.is_ntc_revised == 1) && (decDecisionDemog?.data.Response.BUREAU.is_ntc == 1)) || ((decDecisionDemog?.data.Response.BUREAU.is_ntc_revised == 0) && (decDecisionDemog?.data.Response.BUREAU.is_ntc == 1))) {
                    //setTimeout( async () => {
                    if (condition) {
                        // let decOfferEligibilityTest = await apiInstance.post('offer-eligibility-test', DecisionDemogOffer(mw_application_id, "CIBIL Check", credentialState.cust_id), {
                        //   token
                        // })
                        //   // .then((res) => {
                        //     if ((decOfferEligibilityTest?.status == 1) && (decOfferEligibilityTest?.status_code == 200)) {
                        //       dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOfferEligibilityTest?.data.cust_eligible }));
                        //       gettingerror('cibil check web', mw_application_id)
                        //     }
                        // })
                        endpoint =  decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
                        checkType = "CIBIL Check";
                    }
                    else {
                        endpoint = "offer-eligibility";
                        checkType = "CIBIL Check";
                        // let decEligibilityOffer = await apiInstance.post('offer-eligibility', DecisionDemogOffer(mw_application_id, "CIBIL Check", credentialState.cust_id), {
                        //   token
                        // })
                        //   // .then((res) => {
                        //     if ((decEligibilityOffer?.status == 1) && (decEligibilityOffer?.status_code == 200)) {
                        //       dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decEligibilityOffer.data.cust_eligible }));
                        //     }
                        // })
                    }
                    //}, 10000)
                }
                else if ((decDecisionDemog?.data.Response.BUREAU.is_ntc_revised == 1) && (decDecisionDemog?.data.Response.BUREAU.is_ntc == 0)) {
                    //setTimeout( async () => {
                    if (condition) {
                        endpoint =  decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
                        checkType = "CIBIL Check NTC";
                        // let decOfferEligibilityTest = await apiInstance.post('offer-eligibility-test', DecisionDemogOffer(mw_application_id, "CIBIL Check NTC", credentialState.cust_id), {
                        //   token
                        // })
                        //   // .then((res) => {
                        //     if ((decOfferEligibilityTest?.status == 1) && (decOfferEligibilityTest?.status_code == 200)) {
                        //       dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOfferEligibilityTest?.data.cust_eligible }));
                        //       gettingerror('cibil check NTC web', mw_application_id)
                        //     }
                        // })
                    }
                    else {
                        endpoint = "offer-eligibility";
                        checkType = "CIBIL Check NTC";
                        // let decOfferEligibility = await apiInstance.post('offer-eligibility', DecisionDemogOffer(mw_application_id, "CIBIL Check NTC", credentialState.cust_id), {
                        //   token
                        // })
                        //   // .then((res) => {
                        //     if ((decOfferEligibility?.status == 1) && (decOfferEligibility?.status_code == 200)) {
                        //       dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOfferEligibility.data.cust_eligible }));
                        //     }
                        //   // })
                    }
                    // }, 10000)
                }
                else if ((decDecisionDemog?.data.Response.BUREAU.is_ntc_revised == 0) && (decDecisionDemog?.data.Response.BUREAU.is_ntc == 0)) {
                    //setTimeout( async () => {
                    if (condition) {
                        endpoint =  decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
                        checkType = "Soft Bureau Check";
                        // let decOfferEligibilityTest = await apiInstance.post('offer-eligibility-test', DecisionDemogOffer(mw_application_id, "Soft Bureau Check", credentialState.cust_id), {
                        //   token
                        // })
                        //   // .then((res) => {
                        //     if ((decOfferEligibilityTest?.status == 1) && (decOfferEligibilityTest?.status_code == 200)) {
                        //       dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOfferEligibilityTest.data.cust_eligible }));
                        //       gettingerror('soft bureau web', mw_application_id)
                        //     }
                        //   // })
                    }
                    else {
                        endpoint = "offer-eligibility";
                        checkType = "Soft Bureau Check";
                        // let decOfferEligibility = await apiInstance.post('offer-eligibility', DecisionDemogOffer(mw_application_id, "Soft Bureau Check", credentialState.cust_id), {
                        //   token
                        // })
                        //   // .then((res) => {
                        //     if ((decOfferEligibility?.status == 1) && (decOfferEligibility?.status_code == 200)) {
                        //       dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOfferEligibility.data.cust_eligible }));
                        //     }
                        // })
                    }
                    // }, 10000)
                }

                let pathname = window.location.pathname;
                if (pathname.includes("/lp")) {
                    dispatch(updatePartnerState({ ...partnerStates, numberverficationotp: false, PartnerEmployer: true }))
                }
                else {
                    dispatch(updateFormState({ ...formStates, numberVerificationotp: false, EmployeeDetails: true, UpiJourneyOTP: false }))
                }

                //// Api Hit////
                setTimeout(async () => {
                    let decOfferEligibility = await apiInstance.post(endpoint, DecisionDemogOffer(mw_application_id, checkType, credentialState.cust_id), {
                        "token": token,
                        "log": 1
                    })
                    if ((decOfferEligibility?.status == 1) && (decOfferEligibility?.status_code == 200)) {
                        if (decOfferEligibility.data.cust_eligible == "1") {
                            console.log("function call successfully")
                            EligibleBSA();

                        }
                        dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOfferEligibility.data.cust_eligible }));
                        gettingerror(checkType, mw_application_id)
                    }
                }, 10000)

            }
            else{
                let pathname = window.location.pathname;
                if (pathname.includes("/lp")) {
                    dispatch(updatePartnerState({ ...partnerStates, numberverficationotp: false, PartnerEmployer: true }))
                }
                else {
                    dispatch(updateFormState({ ...formStates, numberVerificationotp: false, EmployeeDetails: true, UpiJourneyOTP: false }))
                }
            }

        }
    }

    const SubmitOTP = async () => {
        const otp = data.otp1 + data.otp2 + data.otp3 + data.otp4;
        const result = {
            otpId: serviceCred.Otp_id,
            otp,
            mobile: serviceNumber.mobile,
            source_from: 1,
            whatsapp_consent: 1,
            device_type: +device_id,
            // IP: ip,
            lead_id: 0,
            customer_id: 0,
            login_from: loginDetails?.journeyAPI == 2 ? 14 : 11,
            lead_id:mw_application_id,
            customer_id:credentialState.cust_id
        };
        const APIDATA = await VerifyOTP(result, token);
        // let APIDATA = await apiInstance.post('verify-otp',result,{token});
        if (APIDATA.status_code === "200" && APIDATA.status === "1") {
            let dec = await apiInstance.post(`mobile-lookup`, {
                "source": source,
                "mw_application_id": mw_application_id,
                "customer_id": credentialState.cust_id,
                "mobile_no": serviceNumber.mobile,
                "verified_by": verified_by,
                "verifier_name": verifier_name,
                "verification_source": verification_source,
                "product_id": 0,
                "partner_id": 349,
                "level_id": 1,
                "auth_pass": 1
            }, { token });
            // console.log(dec);
            if (dec.journeyUpiOk != 2) {
                if (dec.journeyUpiOk == 3) {
                    let qry = Base64.encode(mw_application_id)
                    qry = Base64.encode(qry)
                    window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=16`;
                }
                else {
                    Upimove();
                }
            }
            else {
                let pathname = window.location.pathname;
                if (pathname.includes("/lp")) {
                    dispatch(updatePartnerState({ ...partnerStates, numberverficationotp: false, PartnerEmployer: true }))
                }
                else {
                    dispatch(updateFormState({ ...formStates, numberVerificationotp: false, EmployeeDetails: true, UpiJourneyOTP: false }))
                }
            }
        }
        else {
            setFirstSubmit(true);
            setCount(1);
        }
    }

    const onSubmit = (data) => {
        // console.log(data);
    };

    const handleChange = (nextLocation, prevLocation, event, value) => {
        setValue(value, event.target.value);
        if (event.target.value.length > 0) {
            document.getElementById(nextLocation).focus();
        }
    };

    const handleKeyDown = (event, prevLocation) => {
        const key = event.keyCode || event.charCode;
        if (key == 8 || key == 46) {
            // console.log("Backspace Working");
            if (event.target.value.length === 0) {
                document.getElementById(prevLocation).focus();
            }
        }
    };

    const handleClick = (e) => {
        if (firstSubmit) {
            setValue("otp1", "");
            setValue("otp2", "");
            setValue("otp3", "");
            setValue("otp4", "");
            setFirstSubmit(false);
            document.getElementById("first-tag").focus();
        }
    };

    const handleotp = async () => {
        setUpi(false);
        const pay = {
            "mobile": serviceNumber.mobile,
            "api_mode": "LIVE",
            "login_from": loginDetails?.journeyAPI == 2 ? 14 : 11,
            "verification_source": verification_source,
            "verified_by": verified_by,
            "verifier_name": verifier_name,
            "source": source
        }
        let res = await SendOTP(pay, token);
        dispatch(updateservicecredential({ ...serviceCred, Otp_id: res.data.otp_id }))
    };

    // if (res.data.data.otp_id) {
    //   dispatch(
    //     updateserviceshow({
    //       ...setService,
    //       serviceLogin: false,
    //       serviceOtp: true,
    //       count: res.data.data.count 
    //     })
    //   );
    //   dispatch(
    //     updateservicecredential({
    //       ...serviceCred,
    //       Otp_id: res.data.data.otp_id,
    //     })
    //   );
    // }

    // const handleKeyPress = (event) => {
    //   if (!/[0-9]/.test(event.key)) {
    //     event.preventDefault();
    //   }
    // };

    const handleNavigation = (e) => {
        dispatch(updateserviceshow({ ...setService, serviceOtp: false, serviceLogin: true }));
        dispatch(
            change_personal_loan_page({ ...personal_loan, otp: false, servicemobile: false })
        )
        e.stopPropagation();
    };

    useEffect(() => {
        timer > 0 &&
            setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
    }, [timer]);
    return (
        <>
            <div style={{ padding: "30px 0" }}>
                {/* write code here */}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete={"off"}
                    autoCorrect="false"
                >
                    <div className="otp-form-body">
                        <div className="otp-form-top">
                            <p className="otp-head">Enter OTP</p>
                            <p className="otp-head-p">
                                Please type the verification code sent on <br />
                                +91-{serviceNumber.mobile}
                                {/* {personal_loan.servicemobile && <span className="sp-otp-content" onClick={(e) => handleNavigation(e)} >(change)</span>} */}
                            </p>
                        </div>
                        <div className="enter-otp ">
                            <div
                                className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
                            >
                                <input maxLength={1}
                                    id="first-tag"
                                    type="tel"
                                    {...register("otp1", { required: true })}
                                    onKeyPress={(event) => handleKeyPress(event)}
                                    onChange={(event) =>
                                        handleChange("second-tag", "first-tag", event, "otp1")
                                    }
                                    onKeyDown={(event) => handleKeyDown(event, "first-tag")}
                                    onClick={(e) => handleClick(e)}
                                    autoFocus
                                />
                            </div>
                            <div
                                className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
                            >
                                <input maxLength={1}
                                    id="second-tag"
                                    type="tel"
                                    {...register("otp2", { required: true })}
                                    onKeyPress={(event) => handleKeyPress(event)}
                                    onChange={(event) =>
                                        handleChange("third-tag", "first-tag", event, "otp2")
                                    }
                                    onKeyDown={(event) => handleKeyDown(event, "first-tag")}
                                    onClick={(e) => handleClick(e)}
                                />
                            </div>
                            <div
                                className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
                            >
                                <input maxLength={1}
                                    id="third-tag"
                                    type="tel"
                                    {...register("otp3", { required: true })}
                                    onKeyPress={(event) => handleKeyPress(event)}
                                    onChange={(event) =>
                                        handleChange("forth-tag", "second-tag", event, "otp3")
                                    }
                                    onKeyDown={(event) => handleKeyDown(event, "second-tag")}
                                    onClick={(e) => handleClick(e)}
                                />
                            </div>
                            <div
                                className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
                            >
                                <input maxLength={1}
                                    id="forth-tag"
                                    type="tel"
                                    {...register("otp4", { required: true })}
                                    onKeyPress={(event) => handleKeyPress(event)}
                                    onChange={(event) =>
                                        handleChange("forth-tag", "third-tag", event, "otp4")
                                    }
                                    onKeyDown={(event) => handleKeyDown(event, "third-tag")}
                                    onClick={(e) => handleClick(e)}
                                />
                            </div>
                        </div>
                        <div className="otp-timer-slot" style={{ marginTop: "-30px", textAlign: "right", marginRight: "34px", fontSize: "12px" }}>
                            {/* {timer !== 0 && <span>Didn't Recieve OTP : </span>} */}
                            {timer !== 0 ? <span className="resend-option" >00 : {timer}</span>
                                : ""}
                        </div>
                        {timer == 0 && upi && <p className="resend-option" style={{ cursor: "pointer", textDecoration: "underline", textAlign: "center", paddingTop: "12px" }} id="pointer-cur" onClick={handleotp}>Resend OTP</p>}
                        {serviceCred.Otp_id ? <>{firstSubmit ? <div className="otp-timer-slot err">
                            <span >Invalid OTP </span>
                        </div> : show && ""}</>
                            : <div className="limit-over err-text">You have exhausted daily OTP limit. <br />
                                Please try after 24 hours.</div>}
                        <div>
                            {" "}
                            <button
                                id="last"
                                className={
                                    "otp-btn " +
                                    (data.otp1 &&
                                        data.otp2 &&
                                        data.otp3 &&
                                        data.otp4 &&
                                        !firstSubmit
                                        ? "activate-btn"
                                        : "")
                                }
                                onClick={() => setFirstSubmit(true)}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default UpiJourneyOTP;
