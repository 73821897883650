import React, { useEffect } from 'react';
import './ploan&EMICalc.css';
import EligibilityCriteria from '../PersonalLoan/images/eligibility-criteria.webp';
import FAQSection from '../FAQSection/FAQSection';
import Calculator from '../PersonalLoan/Calculator';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import greenticknew from '../../Icons/greenticknew.svg';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import MobileFooter from '../Footer/MobileFooter';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';

const PersonalLoan_Ans_EMI = () => {
    var loan_type_id_page = 56;
    const dispatch = useDispatch()
    const { PLLoader, formStates, mwpages } = useSelector(state => state)
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY <= 200 || window.scrollY >= 2237) {
            sety(false);
        }
        else {
            sety(true);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])
    return (
        <>
            {/* <NewLoginForm pageType={loan_type_id_page} mapData={data} /> */}
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi />
            <div style={{ marginTop: "100px" }}>
                <Calculator />
                {y && <RightSidePopUp />}
                <section className="emi-calc-margin">
                    <div className="ploan-container">
                        <div className="ploan-content1">
                            <h3 className="heing add-btom-line cetnred">MoneyWide Personal Loan <b>EMI Calculator</b></h3>
                            <p className="heading-p" style={{ marginTop: "0" }}>MoneyWide’s Personal Loan EMI Calculator is an online tool that helps you calculate the Equated Monthly Installment outgo. To know about the monthly EMI on your loan, all you need to do is enter the loan amount, tenure, and interest rate.</p>
                        </div>
                    </div>
                </section>
                <section className="eligibility-criteria" style={{ marginTop: "0" }}>
                    <div className="ploan-container">
                        <div className="box-sh">
                            <div className="eligibility-criteria-box" style={{ paddingBottom: "20px", paddingTop: "24px" }}>
                                <h2 className="heing lefting add-btom-line bot-6" style={{ marginBottom: "20px", marginTop: "0" }}>How to Use MoneyWide Personal Loan <b>EMI Calculator?</b></h2>
                                <div className="ploan-row-criteria elgib-row-criteria">
                                    <div className="colm-2">
                                        <div className="steps-to-loan">
                                            <p>To calculate the Personal Loan EMI, enter the following details:</p>
                                        </div>
                                        <div className="mlc-pl-list mlc-pl-mrgin-top">
                                            <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span>Loan amount</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span>Loan Tenure</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span>Rate of Interest</span>
                                            </div>
                                        </div>
                                        <div className="steps-to-loan">
                                            <p>The calculator will determine the total amount payable, EMI, interest, and principal amount based on your entered input values.</p>
                                        </div>
                                    </div>
                                    <div className="colm-1">
                                        <img src={EligibilityCriteria} alt="Not Found" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="FAQs">
                    <FAQSection />
                </section> */}
                {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                    <FAQSectionChk data={mwpages.faqdata} />
                </section> : ""}
            </div>
        </>

    )
}

export default PersonalLoan_Ans_EMI