import axios from 'axios'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GenerateToken, updateFormState, updateserviceshow } from '../../Redux/action'
import { getToken } from '../EverCalledFunctions/EverCalledApis'
import CKYCWaiting from '../LoginPopUp/CKYCWaiting'
import { prodbase_url, source, verification_source, verified_by, verifier_name } from '../Secret_Credentials/Secret_Credential'
import queryString from "query-string";
import { apiInstance } from '../Config/APIConfig'

const LinkEmailMain = () => {
  const { credentialState, formStates, mw_application_id, token, setService } = useSelector(
    (state) => state
  );

  const dispatch = useDispatch();
  const [emailvertkn, setEmailvertkn] = React.useState('');
  const [show, setShow] = React.useState(true);

  const DecryptToken = () => {
    const qry = queryString.parse(window.location.search);
    let vertkn = qry.token;
    setEmailvertkn(vertkn);
  }

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
    DecryptToken();
  }, []);

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  const emailLinkVerify = async () => {
    let dec = await apiInstance.post(`email-link-verify`, {
      source,
      "token": emailvertkn,
      verified_by,
      verifier_name,
      verification_source
    }, {
      token
    })
    // .then((res) => {
      if ((dec.status === "1") && (dec.status_code === "200")) {
        setShow(false);
        dispatch(updateFormState({ ...formStates, StartJourney: true, EmailVerifyLink: true }))
        dispatch(updateserviceshow({ ...setService, thankMessageEmail: dec.msg_desc }))
      }
      else {
        setShow(false);
        dispatch(updateFormState({ ...formStates, StartJourney: true, EmailVerifyLinkSorry: true }))
        dispatch(updateserviceshow({ ...setService, thankMessageEmail: dec?.msg_desc }))
      }
    // })
    //   .catch((err) => {
    //     setShow(false);
    //     dispatch(updateFormState({ ...formStates, StartJourney: true, EmailVerifyLinkSorry: true }))
    //     dispatch(updateserviceshow({ ...setService, thankMessageEmail: err.response?.data?.msg_desc }))
    //   })
  }

  React.useEffect(() => {
    if ((token !== "") && (emailvertkn !== "")) {
      emailLinkVerify();
    }
  }, [token, emailvertkn])

  return (
    <>
      {show && <CKYCWaiting heading={"While we are verifying your email"} />}
    </>
  )
}

export default LinkEmailMain