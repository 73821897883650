import React from 'react';
import './Form.css';
import YourProfile from './All_Forms/YourProfile';
import CurrentAddress from './All_Forms/CurrentAddress';
import EmployerDetailsThreeTag from './All_Forms/EmployerDetailsThreeTag';
import OfficeAddress from './All_Forms/OfficeAddress';
import MailingAddress from './All_Forms/MailingAddress';
import ReferenceDetails from './All_Forms/ReferenceDetails';
import BankDetails from './All_Forms/BankDetails';
import KFS from '../../OfferSection/KFS';
import KYCVerification from './Verification/KYCVerification.jsx/KYCVerification';
import AdditionalDetails from './All_Forms/AdditionalDetails';
import EMandate from '../../EMANDATE & ENACH/EMandate';
import {  useSelector } from 'react-redux';
import ResidentialAddress from './All_Forms/ResidentialAddress';
import DesiredLoanAmount from '../AipOffer/DesiredLoanAmount';
import EmailVerification from './All_Forms/EmailVerification';
import Sorry from './All_Forms/Sorry';
import HoldCase from './All_Forms/HoldCase';
import TandC from './TandC';
import FinbitScreen from '../AipOffer/FinbitScreen';
import FinalOfferScreen from './All_Forms/FinalOfferScreen';
import EmailChange from './All_Forms/EmailChange';
import LoanAgreementBack from './All_Forms/LoanAgreementBack';
import PreApprovedOffer1 from '../AipOffer/PreApprovedOffer1';
import CongraulationEsign from "./CongraulationEsign";
import Esign from '../../Popups/esign/Esign';
import NewAnalyzer from '../../EMANDATE & ENACH/NewAnalyzer';
import ApplicationStatus from '../../Dashboard/ApplicationStatus';
import NumberVerification from './All_Forms/NumberVerification';
import PartnerOfferSelection from '../../PartnerWithUs/PartnerOfferSelection';
import UrbanMoneyOfferOld from '../../PartnerWithUs/UrbanMoneyOfferOld';
import PartneRNotRoi from '../../PartnerWithUs/PartneRNotRoi';
import NumberverificationOtp from './All_Forms/NumberverificationOtp';
import UpiJourneyOTP from './All_Forms/UpiJourneyOTP';

const Form = () => {

  const { formStates, sorry_popup, templet_id } = useSelector(state => state)
  return (
    <div>
      <div className="form-box">
        <div className="form-body">

          {formStates.numberVerification && <NumberVerification/>}
          {formStates.numberVerificationotp && <NumberverificationOtp/>}
          {formStates.dashBoard && <ApplicationStatus />}
          {formStates.YourProfile && <YourProfile />}
          {formStates.EmployeeDetails && <EmployerDetailsThreeTag step_enable={false}/>}
          {formStates.LoanDetails && <DesiredLoanAmount />}
          {formStates.AipOffer && <PreApprovedOffer1 />}
          {formStates.KycVerification && <KYCVerification />}
          {formStates.ResidentialAddress && <ResidentialAddress />}
          {formStates.MailingAddress && <MailingAddress />}
          {sorry_popup && <Sorry temp_id={templet_id}/>}{/*  16 */}
          {formStates.OfficeAddress && <OfficeAddress />}
          {formStates.AdditionalDetails && <AdditionalDetails />}
          {formStates.ReferenceDetails && <ReferenceDetails />}
          {formStates.HunterHold && <HoldCase/>}
          {formStates.FinbitScreen && <FinbitScreen />}
          {formStates.FinalOffer && <FinalOfferScreen/>}
          {formStates.CurrentAddress && <CurrentAddress/>}
          {formStates.Emandate && <EMandate/>}
          {formStates.Email && <EmailVerification/>}
          {formStates.Terms && <TandC/>}
          {formStates.LoanAgreement && <LoanAgreementBack/>}
          {formStates.Esign && <Esign/>}
          {formStates.KFS && <KFS/>}
         {formStates.ChangeEmail && <EmailChange/>}
         {formStates.BankDetails && <BankDetails/>}
         {formStates.congo && <CongraulationEsign number={templet_id}/>}{/*  12 */}
         {formStates.BSASuccessful && <CongraulationEsign number={templet_id}/>}{/*  7 */}
         {formStates.enashSorry && <Sorry temp_id={templet_id}/>}{/*  11 */}
         {formStates.BsaNewScreen && <NewAnalyzer/>}
         {formStates.OfferNew && <PartnerOfferSelection/>}
        {formStates.Offerselection && <UrbanMoneyOfferOld/>}
        {formStates.offernot && <PartneRNotRoi/>}
        {formStates.UpiJourneyOTP && <UpiJourneyOTP/>}
        </div>
      </div>
    </div>
  )
}

export default Form; 