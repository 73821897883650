import React, { useState, useEffect } from 'react';
import './ServiceReq.css';
import FormHead from '../Journey Form/Form/FormHead';
import { set, useForm } from 'react-hook-form';
import man from '../../Icons/man.svg';
import email from '../../Icons/email.svg';
import pin from '../../Icons/pin.svg';
import chat from '../../Icons/chat.svg';
import DropDownIcon from '../../Icons/DropDownIcon.svg';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../assests/lootie-files/continue.json';
import ThisIsAnError from '../../Icons/error.svg';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { prodbase_url, client_id, client_key, source, verification_source, verified_by, verifier_name } from '../Secret_Credentials/Secret_Credential';
import { GenerateToken, updateFormState, updateSelectedServiceRequestQuery, updateserviceshow } from '../../Redux/action';
import { getCityName,getPinOption, getToken } from '../EverCalledFunctions/EverCalledApis';



const NewServiceRequestForm = () => {
  const [resp,setResp] = useState(false)
  const [submit,setSubmit] = useState(false)
  // const [open, setOpen] = useState([false, false, false])
  const { selectedServiceQuery, setService, serviceCred, serviceNumber,token,formStates } = useSelector(state => state)
  const dispatch = useDispatch();
  // const [first,setFirst]=useState([]);
  const [showone, setShowone] = useState(false);
  // const [second,setSecond]=useState([]);
  const [showtwo, setShowtwo] = useState(false);
  const [firstid, setFirstId] = useState("");
  // const [thirdid,setThirdId]=useState("");
  // const [three,setThree]=useState([]);
  const [showthree, setShowthree] = useState(false);
  const [secondid, setSecondId] = useState("");
  const [showfour,setShowfour] = useState(false);
  const [dropDown, setDropDown] = useState({
    first: [],
    second: [],
    third: [],
    fourth:[]
  })
  const [pincodeData, setPincodeData] = useState({
    show: false,
    data: []
  });
  const [cityFromPin, setCityFromPin] = useState({
    city: "",
    city_id: ""
  })

  const { register, handleSubmit, formState: { errors }, watch, setValue, reset, clearErrors, setError } = useForm({
    defaultValues: {
      name: "",
      email: "",
      pincode: "",
      relatedTo: "",
      complaintType: "",
      subComplaint: "",
      subsubComplaint:"",
      comment: ""
    },
    mode: "all"
  })

  const data = watch();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

  React.useEffect(() => {
    if(token == "") {
      myToken();
    }
    // knowdevice();
    // getData()
  }, [])


  const getCityNameHere = async(pin) => {
    const APIDATA = await getCityName(pin,token);
    setCityFromPin({ ...cityFromPin, city: APIDATA.city, city_id: APIDATA.cityId })
  }

  const GetPinData =async (pin) => {
    const APIDATA = await getPinOption(pin,token);
    if (APIDATA.length !== 0) {
      await setPincodeData({ ...pincodeData, show: true, data: APIDATA })
    } else {
      setPincodeData({ ...pincodeData, show: false, data: [] })
    }
  }

  useEffect(() => {
    if (data.pincode) {
      if (data.pincode.length < 3) {
        setPincodeData({ ...pincodeData, show: false, data: [] })
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
      }
      else if (data.pincode.length >= 3 && data.pincode.length < 6) {
        GetPinData(data.pincode)
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
      } else if (data.pincode.length === 6) {
        getCityNameHere(data.pincode)
        setPincodeData({ ...pincodeData, show: false, data: [] })
      }
      else {
        setPincodeData({ ...pincodeData, show: false, data: [] })
      }
    } else {
      setPincodeData({ ...pincodeData, show: false, data: [] })
      setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
    }

  }, [data.pincode])



  const onSubmit = (result) => {
    // console.log(result);
    setResp(true)
    const pay = {
      "client_id": client_id,
      "client_key": client_key,
      "source": source
    }
    // axios.post(`${prodbase_url}token`, pay).then((res) => {
    //   let token = res.data.token;
      let pin = result.pincode;
      axios.post(`${prodbase_url}complaint-submission`, {
        "source": "Mwide",
        "cust_name": result.name,
        "phone_no": serviceNumber.mobile,
        "email": result.email,
        "pincode": pin.toString(),
        "product_id": "56",
        "relationship_id": serviceCred.customer_type,
        "consent": 1,
        "category": firstid,
        "subcategory": secondid,
        "subsubcategory": showfour ? data.subsubComplaint : "",
        "subsubcategory_reason": "xyz",
        "complaintDetails": data.comment,
        verified_by,
        verifier_name,
        verification_source
      }, {
        headers: {
          token
        }
      }).then((res) => {
        if((res.data.status_code === "200") && (res.data.status === "1")) {
          dispatch(updateserviceshow({ ...setService, newRequest: false, thanks: true,thankMessage:res.data.data.message,name:data.name }))
        }
        else{
          dispatch(updateFormState({ ...formStates, error: true }));
        }
      })
    // })
  }

  useEffect(() => {
    // console.log(selectedServiceQuery.queryId);
    // console.log(serviceCred.customer_type);
    // console.log(serviceNumber.mobile);
    if(token !== ""){
      const payload = {
        "source": "MWide",
        "type": "Query_regarding",
        "parent_id": 0,
        "customer_type": 2,
        verified_by,
        verifier_name,
        verification_source
      }
      const pay = {
        "client_id": client_id,
        "client_key": client_key,
        "source": source
      }
      // axios.post(`${prodbase_url}token`, pay).then((res) => {
      //   const token = res.data.token;
        axios.post(`${prodbase_url}cms-dropdown`, payload, {
          headers: {
            token
          }
        }).then((res) => {
          if((res.data.status_code === "200") && (res.data.status === "1")){
            setDropDown({ ...dropDown, first: res.data.data })
          }
          else{
            dispatch(updateFormState({ ...formStates, error: true }));
          }
          // console.log(res);
          // setFirst(res.data.data); 
          
        })
      // })
    }
  }, [token])

  useEffect(() => {
    if (showone) {
      const payload = {
        "source": "MWide",
        "type": "Product_complaint",
        "parent_id": 0,
        "customer_type": 2,
        verified_by,
        verifier_name,
        verification_source
      }
      // const pay = {
      //   "client_id": client_id,
      //   "client_key": client_key,
      //   "source": source
      // }
      // axios.post(`${prodbase_url}token`, pay).then((res) => {
      //   const token = res.data.token;
        axios.post(`${prodbase_url}cms-dropdown`, payload, {
          headers: {
            token
          }
        }).then((res) => {
          if((res.data.status_code === "200") && (res.data.status === "1")){
            setDropDown({ ...dropDown, second: res.data.data })
          }
          else{
            dispatch(updateFormState({ ...formStates, error: true }));
          }
          // console.log(res);
          // setSecond(res.data.data); 
          
        })
      // })
    }
  }, [showone])

  useEffect(() => {
    if (showtwo) {
      const payload = {
        "source": "MWide",
        "type": "Product_complaint",
        "parent_id": firstid,
        "customer_type": 2,
        verified_by,
        verifier_name,
        verification_source
      }
      // const pay = {
      //   "client_id": client_id,
      //   "client_key": client_key,
      //   "source": source
      // }
      // axios.post(`${prodbase_url}token`, pay).then((res) => {
      //   const token = res.data.token;
        axios.post(`${prodbase_url}cms-dropdown`, payload, {
          headers: {
            token
          }
        }).then((res) => {
          if((res.data.status_code === "200") && (res.data.status === "1")){
            setShowthree(true);
            setDropDown({ ...dropDown, third: res.data.data })
          }
          else{
            dispatch(updateFormState({ ...formStates, error: true }));
          }
        })
      // })
    }
  }, [showtwo, firstid])

  const RemoveDropdown = () =>{
    dispatch(updateserviceshow({...setService,open:[false,false,false,false]}));
  }

  const LockThisValue = (num, data,e) => {
    // console.log("Enter");
    if (num === 0) {
      setValue('relatedTo', data.value);
      setShowone(true);
      setValue('subComplaint', "")
      setValue('complaintType', "")
      setValue('subsubComplaint',"")
      setShowfour(false);
    }
    else if (num === 1) {
      setValue('complaintType', data.value);
      setFirstId(data.id);
      setShowtwo(true);
      // console.log(firstid);
      setValue('subComplaint', "");
      setValue('subsubComplaint',"");
      setShowfour(false);
    }
    else if (num === 2) {
      setValue('subComplaint', data.value);
      setSecondId(data.id);
      setValue('subsubComplaint',"");
      // console.log(secondid);
      // RemoveDropdown()
    }
    else if (num === 3){
      console.log(data);
      setValue('subsubComplaint',data.value);
      //  setThirdId(data.id);
  }
    // setOpen([false, false, false]);
    dispatch(updateserviceshow({...setService,open:[false,false,false,false]}));
    e.stopPropagation();
  }

  useEffect(()=>{
    // console.log(thirdid);
    if((firstid === 146)){ 
       const payload={
           "source": "MWide",
           "type": "Product_complaint",
           "parent_id":secondid,
           "customer_type":2,
           verified_by,
           verifier_name,
           verification_source
       }
      //  const pay = {
      //      "client_id": client_id,
      //      "client_key": client_key,
      //      "source": source
      //  }
      //  axios.post(`${prodbase_url}token`,pay).then((res)=>{
      //      const token=res.data.token;
     axios.post(`${prodbase_url}cms-dropdown`,payload,{
       headers:{
           token
       }
     }).then((res)=>{ 
      //  console.log(res);
       if((res.data.status_code === "200") && (res.data.status === "1")){
         if(res.data.data.length !== 0){
          setShowfour(true);
          // setThree(res.data.data); 
          setDropDown({...dropDown,fourth:res.data.data})
          
          }
          else if(res.data.data.length == 0){
           // console.log("entering")
           setShowfour(false);
          }
       }
       else{
        dispatch(updateFormState({ ...formStates, error: true }));
       }
       
     }) 
      //  })
    }
    else{
      setShowfour(false);
    }
  },[secondid])

 

  

  const OpenThis = (id,e) => {
    // console.log("open this")
    var newA = [false, false, false,false]
    newA[id] = true;
    // setOpen(newA)
    dispatch(updateserviceshow({...setService,open:newA}))
    e.stopPropagation();
  }

  const Blured = (field, value) => {
    // var newVal = value.trimEnd();
    var newVal = value.replace(/\s+$/, '');
    setValue(field, newVal);
    if (newVal.length < 3) {
      setError(field)
    } else {
      clearErrors([field])
    }
  }

  const SpaceManage = (field, value) => {
    setValue(field, value.replace(/^\s+/, ''));
  }


  const handleUpKeyNumber = (value, field) => {
    var regexp = /[^0-9]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""))
    }
  }

  const setThisPin = (pin) => {
    setValue('pincode', pin);
    setPincodeData({ ...pincodeData, show: false })
    clearErrors(['pincode'])
    getCityNameHere(pin)
  }

  const handleAlphaOnly = (value, field) => {
    var regexp = /[^a-zA-Z ]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ''))
    } else {
      setValue(field, value.replace(/^\s+/, ''));
    }
  }

  useEffect(()=>{
    if(data.complaintType && errors.complaintType){
      clearErrors(['complaintType'])
    }
    if(data.subComplaint && errors.subComplaint){
      clearErrors(['subComplaint'])
    }
    if(data.relatedTo && errors.relatedTo){
      clearErrors(['relatedTo'])
    }
    if(data.subsubComplaint && errors.subsubComplaint){
      clearErrors(['subsubComplaint'])
    }
      if(data.name && data.email && data.pincode && data.relatedTo && data.complaintType && data.subComplaint && data.comment &&  Object.keys(errors).length===0){
        if(showfour && data.subsubComplaint ){
          setSubmit(true)
        }else if(showfour && !data.subsubComplaint){
          setSubmit(false)
        }else{
          setSubmit(true)
        }
      }else{
        setSubmit(false)
      }
    console.log(data);
  },[data])

  useEffect(()=>{
    // console.log(errors);
    // console.log(data);
  },[data])

  return (
    <div>
      <FormHead formName={"Service Request"} formSubHead={"How may we help you"} progressBar={true} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} >

        <div className={"input-tag remove-bottom" + (errors.name ? " error-border" : data.name ? " add-border" : "")}>
          <img src={man} alt="" />
          <input type="text" placeholder='Name' autoComplete='off'
            {...register('name', { required: true, minLength: 3 })}
            onBlur={() => Blured("name", data.name)}
            // onKeyUp={() => SpaceManage("name", data.name)}
            onKeyUp={() => handleAlphaOnly(data.name, "name")}
          />
        </div>
        {errors.name && <div className='err-text'>Enter At least 3 Characters</div>}


        <div className={"input-tag remove-bottom" + (errors.email ? " error-border" : data.email ? " add-border" : "")}>
          <img src={email} alt="" />
          <input type="email" placeholder='Email ID' autoComplete='off'
            {...register('email', {
              required: true, pattern: {
                value:/^([a-zA-Z0-9_.+-]{3,64})+\@(([a-zA-Z0-9-]{2,255})+\.)+([a-zA-Z0-9]{2,8})+$/
              }
            })} />
        </div>
        {errors.email && <div className='err-text'>Invalid Email</div>}

        <div className={"input-tag remove-bottom" + (errors.pincode ? " error-border" : data.pincode ? " add-border" : "")}>
          <img src={pin} alt="" />
          <input type="tel" placeholder='Residence Pincode' autoComplete='off' minLength={6} maxLength={6}
            {...register('pincode', { required: true, minLength: 6, maxLength: 6 })}
            onKeyUp={() => handleUpKeyNumber(data.pincode, "pincode")}
          />
          {pincodeData.show && pincodeData.data.length && <div className="dropDownBox">
            {pincodeData.data.map((row, ind) => {
              return <div className='dropDownValue' key={ind} onClick={() => setThisPin(row)}>{row}</div>
            })}
          </div>}
        </div>
        {(data.pincode) && <div className={(errors.pincode) ? 'api-text err-text ' : "api-text "} >{cityFromPin.city}</div>}


        <div className={"input-tag" + (errors.relatedTo && !data.relatedTo ? " error-border" : data.relatedTo ? " add-border" : "")} >
          <img className="tag-icon" src={chat} />
          <input className="input-tag-box" type="text" placeholder='Select Query Regarding' id="pointer-cur"
            {...register('relatedTo', { required: true })} autoComplete="off" readOnly onClick={(e) => OpenThis(0,e)} onFocus={(e)=>OpenThis(0,e)}   />
          <img src={DropDownIcon} className="small-the-icon" alt="" />
          {setService.open[0] &&
            <div className='input-dropdown'>
              {dropDown.first && dropDown.first.map((row) => {
                return <div onClick={(e) => LockThisValue(0, row,e)} key={row.id} className="dropdown-content">{row.value}</div>
              })}
            </div>
          }

        </div>


        {showone && <div className={"input-tag" + (errors.complaintType && !data.complaintType ? " error-border " : data.complaintType ? " add-border" : "")} >
          <img className="tag-icon" src={chat} />
          <input className="input-tag-box" type="text" placeholder='Product Related To'
            {...register('complaintType', { required: true })} autoComplete="off" readOnly id="pointer-cur" onClick={(e) => OpenThis(1,e)} onFocus={(e)=>OpenThis(1,e)}   />
          <img src={DropDownIcon} className="small-the-icon" alt="" />
          {setService.open[1] && <div className='input-dropdown'>
            {dropDown.second && dropDown.second.map((row) => {
              return <div onClick={(e) => LockThisValue(1, row,e)} key={row.id} className="dropdown-content">{row.value}</div>
            })}
          </div>}
        </div>}




        {showtwo &&
          <div className={"input-tag" + (errors.subComplaint && !data.subComplaint ? " error-border" : data.subComplaint ? " add-border" : "")} >
            <img className="tag-icon" src={chat} />
            <input className="input-tag-box" type="text" placeholder='Complaint Type'
              {...register('subComplaint', { required: true })} autoComplete="off" readOnly style={{ cursor: "pointer" }} onClick={(e) => OpenThis(2,e)} onFocus={(e)=>OpenThis(2,e)}  />
            <img src={DropDownIcon} className="small-the-icon" alt="" />
            {setService.open[2] && <div className='input-dropdown'>
              {dropDown.third && dropDown.third.map((row) => {
                return <div onClick={(e) => LockThisValue(2, row,e)} key={row.id} className="dropdown-content">{row.value}</div>
              })}
            </div>}
          </div>
        }

            {showfour &&
                    <div className={"input-tag "+(errors.subsubComplaint && !data.subsubComplaint ? " error-border" : data.subsubComplaint ? " add-border" : "")}>
                        <img className="tag-icon" src={chat} />
                        <input className="input-tag-box" type="text" placeholder='Sub-Complaint Type'
                            {...register('subsubComplaint', { required: true })} autoComplete="off" readOnly style={{ cursor: "pointer" }} onClick={(e) => OpenThis(3,e)} onFocus={(e)=>OpenThis(3,e)} />
                        <img src={DropDownIcon} className="small-the-icon" alt="" />
                        {setService.open[3] && <div className='input-dropdown'>
                            {dropDown.fourth && dropDown.fourth.map((row) => {
                                return <div onClick={(e) => LockThisValue(3, row,e)} key={row.id} className="dropdown-content">{row.value}</div>
                            })}
                        </div>}
                   </div>
              }


        {/* <div className="input-tag">
          <img src={chat} alt="" />
          <input type="text" readOnly placeholder='Select Query Regarding' />
          <img src={DropDownIcon} alt="" className=''/>
        </div> */}

        <div className={"input-tag" + (errors.comment ? " error-border" : data.comment ? " add-border" : "")}>


          {/* <input className="input-tag-box" type="text-area" style={{ marginBottom: "2px" }} placeholder='Write your comment'
                        {...register('comment', { required: true })} autoComplete="off" /> */}
          <textarea className="input-tag-box" type="text" onFocus={RemoveDropdown} style={{ marginBottom: "2px" }} onBlur={() => Blured("comment", data.comment)} onKeyUp={() => SpaceManage("comment", data.comment)} placeholder='Write your comment' {...register('comment',{ required: true, minLength: 3 })} autoComplete="off"></textarea>
        </div>

        <button disabled={resp || !submit} className={'footer-btn' + ((submit) ? ' footer-btn-activate' : "")}>
        {!resp ?
              "Submit" :
              <div className="continue-lottie">
                <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
              </div>}
        </button>
    </form>
    </div >
  )
}

export default NewServiceRequestForm;