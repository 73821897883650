import React, { useState } from "react";
import "./eMandate.css";
import FormHead from "../Journey Form/Form/FormHead";
import bank from "../../Icons/bank.svg";
import npci from "../../Icons/NPCI.webp";
import rupee from "../../Icons/rupee.svg";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  prodbase_url,
  client_id,
  client_key,
  source,
  react_base_url,
  verification_source,
  verified_by,
  verifier_name,
} from "../Secret_Credentials/Secret_Credential";
import axios from "axios";
import MainLoader from "../MainLoader/MainLoader";
import {
  Check_utm_source,
  RelocateUTMCheck,
  decryptOnlyLeadId,
  encryptMandateId,
  encryptOnlyLeadId,
  gettingerror,
} from "../EverCalledFunctions/EverCalledFunctions";
import {
  GenerateToken,
  setSorryPopup,
  updateAppStatus,
  updateCredentialsState,
  updateFormState,
  updateMwApplicationId,
  updateStepBar,
} from "../../Redux/action";
import ctaAnimation from "../assests/lootie-files/continue.json";
import Lottie from "react-lottie-player";
import Base64 from "base-64";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import NewLeftPart from "../Journey Form/FormLeftPart/NewLeftPart";
import FinbitScreen from "../Journey Form/AipOffer/FinbitScreen";
import Analyzer from "../Analyzer/Analyzer";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
import { FetchCustomerPayload } from "../EverCalledFunctions/EverCalledPayload";
import { apiInstance } from "../Config/APIConfig";

const BsaAnalyzer = () => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState();
  const [via, setVia] = useState("");
  const [state, setState] = useState(true);
  const { credentialState, mw_application_id, step_bar, formStates, token } =
    useSelector((state) => state);
  const [resp, setResp] = useState(false);
  const [loanAmt, setLoanAmt] = useState("");
  const [accountType, setAccountType] = useState("");
  const [ifsc, setIfsc] = useState(true);
  const [leadSource, setLeadSource] = useState("");
  const [app, setApp] = useState(false);
  const [rocket, setRocket] = useState(false);
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState(true);


  React.useEffect(() => {

    if (token == "") {
      myToken();
    }
  }, []);

  const getStepId = async () => {
    const qry = queryString.parse(window.location.search);
    if (qry.app) {
      dispatch(updateAppStatus(true));
      window.sessionStorage.setItem("count", 1);
    }
    const mw_app_id = await decryptOnlyLeadId();
    window.sessionStorage.setItem("mw_app_id", mw_app_id);
    dispatch(updateMwApplicationId(mw_app_id));
  };

  React.useEffect(() => {
    if (mw_application_id !== "") {
      setTimeout(async () => {
        let decFetch = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 5), {
          "token": token
        })
          // .then((res) => {
            if ((decFetch.status == 1) && (decFetch.status_code == 200)) {
              if (decFetch.data.thank_you_flag == 1) {
                gettingerror("get thank you flag",mw_application_id)
                if (decFetch.data.thank_you_url !== "") {
                  window.location.href = decFetch.data.thank_you_url;
                  return;
                } else {
                  let qry = Base64.encode(`${mw_application_id}`);
                  qry = Base64.encode(qry);
                  RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=18`);
                  return;
                }
              }
              else {
                window.sessionStorage.setItem("cust_id", decFetch.data.customer_id);
                dispatch(updateCredentialsState({ ...credentialState, lead_id: decFetch.data.lead_id, cust_id: decFetch.data.customer_id, step_5: decFetch.data }));
              }
            }
            else {
              window.location.href = `${react_base_url}`;
            }


          // }).catch((err) => {
          //   setRocket(true);
          // })
      }, 100)
    }
  }, [mw_application_id])

  const OfferEligibilityAtEmployement = async () => {

    let dec = await apiInstance.post(
      `offer-eligibility-test`,
      {
        "source": source,
        "mw_application_id": mw_application_id,
        "product_id": 56,
        "customer_id": credentialState.cust_id,
        "offer_type": 1,
        "level_id": 1,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "verification_source": verification_source,
        "stage_name": "Employment Check"
      },
      {
        "token": token,
      }
    )
      // .then((res) => {
        if(dec.status_code) {
        }
      // });
  }

  const EmployementCheck = async () => {
    if (credentialState.step_5.is_customer_eligible === 1) {
      if (credentialState.step_5.is_customer_eligible === 1 &&
        credentialState.step_5.uan_no.length !== 0 && ((credentialState.step_5.new_journey_lead_source_flag == 1) || (credentialState.step_5.new_journey_customer_id_flag == 1))) {
        setLoader(false);
        dispatch(updateFormState({ ...formStates, BsaNewScreen: true }))
      }
      else if (
        credentialState.step_5.is_customer_eligible === 1 &&
        credentialState.step_5.uan_no.length !== 0 && credentialState.step_5.new_journey_lead_source_flag != 1 && credentialState.step_5.new_journey_customer_id_flag != 1
      ) {
        let id = ""
        id = credentialState.step_5.selected_program_id;
        window.sessionStorage.setItem("selected_program_id", id);
        let decOffer = await apiInstance
          .post(
            `offer-eligibility`,
            {
              "customer_id": credentialState.cust_id,
              "mw_application_id": mw_application_id,
              "offer_type": 1,
              "verifier_name": verifier_name,
              "verification_source": verification_source,
              "verified_by": verified_by,
              "product_id": 0,
              "stage_name": "Employment Check",
              "program_id": id,
              "level_id": 1,
              "source": source,
            },
            {
              "token": token,
            }
          )
          .then((res) => {
            console.log(res);
          });
      }
      setLoader(false);
      dispatch(updateFormState({ ...formStates, BsaNewScreen: true }))
    }
    else {
      let checkCondition = Check_utm_source()
      if (checkCondition && checkCondition.length != 0) {
        setLoader(false);
        let qry = Base64.encode(`${mw_application_id}`);
        qry = Base64.encode(qry);
        window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18${checkCondition}`;
      }
      else {
        setLoader(false);
        let qry = Base64.encode(`${mw_application_id}`);
        qry = Base64.encode(qry);
        RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=18`);
      }
    }
  }

  React.useEffect(() => {
    if ((credentialState.cust_id !== "") && (mw_application_id !== "") && (credentialState.step_5)) {
      sessionStorage.setItem("new_journey_lead_source_flag", credentialState.step_5.new_journey_lead_source_flag);
      // dispatch(updateCredentialsState({ ...credentialState, newState: false }))
      // dispatch(updateFormState({ ...formStates, BsaNewScreen: true }))
      // setLoader(false)
      if (credentialState.step_5.bsa_partner_id === 3) {
        setLoader(false);
        dispatch(updateCredentialsState({ ...credentialState, newState: true }));
      }
      else {
        EmployementCheck();
      }

    }

  }, [mw_application_id, credentialState.step_5, credentialState.cust_id])

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }

  }, []);

  React.useEffect(() => {
    if (token !== "") {
      getStepId();
    }
  }, [token])

  const handleError = (e) => {
    window.location.reload();
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      {loader && <CKYCWaiting
        heading={
          "We are initiating bank account check to validate your salary credits."
        }
      />}
      {!credentialState.newState ? <NewLeftPart title="Get Instant Loan Using MoneyWide"
        content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." /> : <div className={app ? "sdk-mobile" : "sdk"}>
        <Analyzer />
      </div>}
    </>
  );
};

export default BsaAnalyzer;