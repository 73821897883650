import React from "react";
import queryString from "query-string";
import Base64 from "base-64";
import { useSelector, useDispatch } from "react-redux";
import { updateMwApplicationId, updateCredentialsState, updateFormState,  GenerateToken } from "../../Redux/action";
// import axios from "axios";
import {  source, verification_source, verified_by, verifier_name, react_base_url } from "../Secret_Credentials/Secret_Credential";
import { Helmet } from "react-helmet";
import "./Analyzer.css";
import { useNavigate } from "react-router-dom";
import { Check_utm_source ,encryptLeadIdStepId, encryptQueryIdCustomerIdStepId,gettingerror } from "../EverCalledFunctions/EverCalledFunctions";
import {  getToken} from "../EverCalledFunctions/EverCalledApis";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import NotFound from "../NotFound/NotFound";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
// import { AxiosThirty } from "../AxiosConfiguration/AxiosConfig";
import { FetchCustomerPayload } from "../EverCalledFunctions/EverCalledPayload";
import { apiInstance } from "../Config/APIConfig";

function NewVkyc() {
    const { mw_application_id, credentialState, formStates,  token } = useSelector(state => state);
    const dispatch = useDispatch();
    const [vkyc, setVkyc] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const [iframeUrl, setIframeUrl] = React.useState("");
    const [stepId, setStepId] = React.useState("");
    // const [updatedId, setUpdatedId] = React.useState("");
    const [res, setRes] = React.useState("");
    const [rocket, setRocket] = React.useState(false);
    const navigate = useNavigate();

    const getCustomerId = async (mw_app_id) => {
        let decFetch = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_app_id, 4), {
            token
        })
        // .then((response) => {
        if ((decFetch.status == "1") && (decFetch.status_code == "200")) {
            if (decFetch.data.thank_you_flag == 1) {
                gettingerror("get thank you flag", mw_app_id)
                if (decFetch.data.thank_you_url !== "") {
                    window.location.href = decFetch.data.thank_you_url;
                    return
                }
                else {
                    let qry = Base64.encode(`${mw_app_id}`);
                    qry = Base64.encode(qry);
                    let checkCondition = Check_utm_source();
                    if (checkCondition && checkCondition.length != 0) {
                        window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18${checkCondition}`;
                        return
                    }
                    else {
                        window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18`;
                        return
                    }
                }

            }
            else if (decFetch.data.redirection_url !== "" && !decFetch.data.redirection_url.includes('vkyc')) {
                window.location.href = decFetch.data.redirection_url;
                return;
            }
            else {
                setRes(decFetch);
                dispatch(updateCredentialsState({ ...credentialState, cust_id: decFetch.data.customer_id }))
            }
        }
        // }).catch((err) => {
        //     setRocket(true);
        // })
    }

    const GetQueryFid = async () => {
        let query = queryString.parse(window.location.search);

        if (query.fid) {
            try {
                let fid = Base64.decode(query.fid);
                fid = Base64.decode(fid)
                if (fid.includes("&")) {
                    fid = fid.split("&");
                    let mw_app_id = fid[0];
                    let step_id_kyc = "";
                    if (fid[1] !== undefined) {
                        step_id_kyc = fid[1];
                    }

                    if (step_id_kyc.includes("step_id")) {
                        step_id_kyc = step_id_kyc.split("step_id=");
                        step_id_kyc = Base64.decode(step_id_kyc[1]);
                        step_id_kyc = Base64.decode(step_id_kyc)
                        setStepId(step_id_kyc);
                    }
                    if (mw_app_id.includes("query_id")) {
                        mw_app_id = mw_app_id.split("query_id=");
                        mw_app_id = Base64.decode(mw_app_id[1]);
                        mw_app_id = Base64.decode(mw_app_id)
                        dispatch(updateMwApplicationId(mw_app_id));
                    }

                }
                else if (fid.includes("query_id")) {
                    let mw_app_id = fid.split("query_id=");
                    mw_app_id = Base64.decode(mw_app_id[1]);
                    mw_app_id = Base64.decode(mw_app_id)
                    dispatch(updateMwApplicationId(mw_app_id));
                    setStepId(100);
                }
                else {
                    let mw_app_id = fid;
                    dispatch(updateMwApplicationId(mw_app_id));
                    setStepId(100);
                }
            } catch (error) {
                console.log(error);
            }

        }

    }

    const onLoad = () => {
        const iframe = document.getElementById("vkyc-iframe");
        if (iframeUrl && vkyc) {
            const iframeHeight = window.innerHeight || document.documentElement.clientHeight;
            iframe.style.height = `${iframeHeight - 75}px`;
        }
    };

    const redirectnUrl = () => {
        let qry = encryptQueryIdCustomerIdStepId(mw_application_id, credentialState.cust_id, stepId);
        let red_url = "";
        let checkUtmCondition = Check_utm_source();
        if (checkUtmCondition && checkUtmCondition.length != 0) {
            red_url = `${react_base_url}vkyc/redirect/?fid=${qry}${checkUtmCondition}`;
        }
        else {
            red_url = `${react_base_url}vkyc/redirect/?fid=${qry}`;
        }
        return red_url;
    }

    React.useEffect(() => {
        if (iframeUrl && vkyc) {
            onLoad();
        }
    }, [iframeUrl, vkyc]);

    React.useEffect(() => {
        if (mw_application_id !== "") {
            getCustomerId(mw_application_id);
        }
    }, [mw_application_id])

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }

    React.useEffect(() => {
        if (token == "") {
            myToken();
        }
        let checkCondition = Check_utm_source();
        if (checkCondition && checkCondition.length !== 0) {
            sessionStorage?.setItem("utm", checkCondition);
        }
    }, [])


    React.useEffect(() => {
        setTimeout(() => {
            const script = document.createElement('script');
        // script.src = 'https://sdksb.digitap.work/ekyc/scripts/ekyc.js';
        script.src = "https://sdk.digitap.ai/ekyc/scripts/ekyc.js";
        script.async = true;
        document.body.appendChild(script);
        }, 5000);
    }, []);

    React.useEffect(() => {
        setTimeout(() => {
            const script = document.createElement('script');
        script.src = 'https://app.digio.in/sdk/v11/digio.js';
        script.async = true;
        document.body.appendChild(script);
        }, 5000);
    }, []);


    React.useEffect(() => {
        if (token !== "") {
            GetQueryFid();
        }
    }, [token])

    React.useEffect(() => {
        if (iframeUrl !== "") {
            setVkyc(true);
        }
    }, [iframeUrl])

    const vkycActivation = async (updated_Vkyc) => {
        let decVkyc = await apiInstance.post(`web/vkyc-user-activate`, {
            source,
            "customer_id": credentialState.cust_id,
            "lead_id": mw_application_id,
            "verification_source": verification_source,
            "verified_by": verified_by,
            "verifier_name": verifier_name,
            "updatedVkyc": updated_Vkyc
        }, {
            "token": token,
            "log": 1
          })
        // .then((res) => {
        if ((decVkyc?.status == "1") && (decVkyc?.status_code == "200")) {

            document.cookie = `cust_id=${credentialState.cust_id}; path=/vkyc/redirect`;
            document.cookie = `mw_app_id=${mw_application_id}; path=/vkyc/redirect`;

            let checkCondition = Check_utm_source();
            if (checkCondition && checkCondition.length !== 0) {
                document.cookie = `utm=${checkCondition}; path=/vkyc/redirect`;
            }
            if (decVkyc?.data?.model?.url) {
                setIframeUrl(decVkyc.data.model.url);
                gettingerror('vkyc initiate web', mw_application_id)
            }
            else {
                setRocket(true);
            }
        }
        else {
            setRocket(true);
        }


        // }).catch((err) => {
        //     setRocket(true);
        // })
    }

    const digilockerActivation = async () => {
        const firstName = res.data.first_name;
        const lastName = res.data.last_name;
        const email = res.data.email_id;
        const mobile = res.data.phone_no;
        const mw_lead_id = res.data.lead_id;

        sessionStorage?.setItem("cur_step", 1);
        sessionStorage?.setItem("next_step", 2);
        sessionStorage?.setItem("digilocker", "true")
        sessionStorage?.setItem("okyc", "false")

        let decDigilocker = await apiInstance.post(`digilocker/fetch-details`, {
            "partner_id": 5,
            "level_id": 1,
            "lead_id": mw_application_id,
            "verified_by": verified_by,
            "verifier_name": verifier_name,
            "verification_source": verification_source,
            "customer_id": credentialState.cust_id,
            "product_id": 0,
            "source": source,
            "uniq_id": mw_lead_id,
        }, {
            "token": token,
            "log": 1
          })
            // .then((res) => {

                if ((decDigilocker.status == 1) && (decDigilocker.status_code == 200)) {
                    gettingerror('hit digilocker fetch details & initiate web', mw_application_id)
                    if (decDigilocker.data && decDigilocker.data.code.toString() === "401") {

                        const qry = encryptLeadIdStepId(mw_application_id, 4);
                        let checkCondition = Check_utm_source();
                        if (checkCondition && checkCondition.length !== 0) {
                            window.location.href = `${react_base_url}personal-loan/?fid=${qry}${checkCondition}`;
                            return;
                        }
                        else {
                            window.location.href = `${react_base_url}personal-loan/?fid=${qry}`;
                            return;
                        }

                    }

                    var options = {
                        "environment": "PRODUCTION",
                        // "environment": "UAT",
                        "logo": "https://www.moneywide.com/assets/images/logo.webp",
                        "theme": {
                            primaryColor: '',
                            secondaryColor: 'F9FAFE'
                        },
                        "redirect_url": `${react_base_url}vkyc/redirect/`,
                        "error_url": `${react_base_url}vkyc/redirect/`,
                        "directInitiate": "digilocker",
                        "firstName": firstName,
                        "lastName": lastName,
                        "mobile": mobile,
                        "email": email,
                        "applicationNo": `${mw_lead_id}_1`,
                        "sendOtp": "false",
                        "transactionId": `${decDigilocker.data.model.transactionId}`,
                        "clientId": "29022165",
                        "clientSecret": { "digilocker": "mPFFdeXhhk0BsAUQuBG7CV4DKaarV8as" },
                    };
                    setTimeout(() => {
                        var digitap_ekyc = window.ekyc(options);
                    }, 10000);
                }
                else {
                    const qry = encryptLeadIdStepId(mw_application_id, 4);
                    let checkCondition = Check_utm_source();
                    if (checkCondition && checkCondition.length !== 0) {
                        navigate(`/personal-loan/?fid=${qry}${checkCondition}`);
                    }
                    else {
                        navigate(`/personal-loan/?fid=${qry}`);
                    }
                }

            // }).catch((err) => {
            //     setRocket(true);
            // })
    }

    const selfieActivation = async (updated_selfie_vkyc) => {
        let red_url = redirectnUrl();

                        const payload = {
                            "source": source,
                            "customer_id": credentialState.cust_id,
                            "lead_id": mw_application_id,
                            "lenderId": res.data.lenderId,
                            "level_id": 1,
                            "product_id": 0,
                            "partner_id": 42,
                            "updated_selfie_vkyc": ((updated_selfie_vkyc == "") ? 0 : updated_selfie_vkyc),
                            "verified_by": verified_by,
                            "verifier_name": verifier_name,
                            "verification_source": verification_source
                        }
                        let decSelfie = await apiInstance.post(`create-selfie`, payload, {
                            "token": token,
                            "log": 1
                          })
                            // .then((res) => {
                                if ((decSelfie.status == 1) && (decSelfie.status_code == 200)) {
                                    gettingerror('selfie initiate web', mw_application_id)
                                    const entity_id = decSelfie.data.access_token.entity_id;
                                    const id = decSelfie.data.access_token.id;
                                    const customer_identifier = decSelfie.data.customer_identifier;

                                    var options = {
                                        "callback": function (t) {
                                            if (t.hasOwnProperty('error_code')) {
                                                document.getElementById("loading").style.display = 'none';
                                                document.getElementById("result").innerHTML = "failed to sign with error : " + t.message;
                                            }
                                            else {
                                                // sessionStorage.clear();
                                                document.getElementById("loading").style.display = 'none';
                                                document.getElementById("result").innerHTML = "Sign Successful";
                                            }
                                        },
                                        "is_redirection_approach": true,
                                        "logo": "https://www.moneywide.com/assets/images/logo.webp",
                                        "is_iframe": false,
                                        "redirect_url": red_url,
                                        "environment": "production"
                                        // "environment": "sandbox"
                                    };
                                    setTimeout(() => {
                                        var digio = new window.Digio(options);
                                    digio.init();
                                    digio.submit(entity_id, customer_identifier, id);
                                    }, 10000);
                                }
                                else {
                                    dispatch(updateFormState({ ...formStates, StartJourney: true, ResidentialAddress: true, error: true }))
                                    let checkCondition = Check_utm_source();
                                    if (checkCondition && checkCondition.length !== 0) {
                                        navigate(`/personal-loan/?${checkCondition.slice(1)}`);
                                    }
                                    else {
                                        navigate("/personal-loan");
                                    }

                                }

                            // }).catch((err) => {
                            //     setRocket(true);
                            // })
    }

    React.useEffect(() => {
        if ((credentialState.cust_id !== "") && (mw_application_id !== "") && (stepId !== "") && (res !== "")) {
            sessionStorage?.setItem("mw_app_id", mw_application_id);
            sessionStorage?.setItem("cust_id", credentialState.cust_id);

            if ((stepId.toString() === "1")) {
                if ((res.status == 1) && (res.status_code == 200)) {

                    let qry = queryString.parse(window.location.search);
                    let updated_id = "";
                    if (qry.updated_id) {
                        updated_id = Base64.decode(qry.updated_id);
                        updated_id = Base64.decode(updated_id);
                    }

                    if ((res.data.Okyc_get_transactionId !== "") && (res.data.okyc_status === "s") && (updated_id == "")) {

                        const qry = encryptLeadIdStepId(mw_application_id, 4);
                        let checkCondition = Check_utm_source();
                        if (checkCondition && checkCondition.length !== 0) {
                            navigate(`/personal-loan/?fid=${qry}${checkCondition}`);
                        }
                        else {
                            navigate(`/personal-loan/?fid=${qry}`);
                        }
                    }
                    else {
                        gettingerror('okyc initiate web', mw_application_id)
                        const firstName = res.data.first_name;
                        const lastName = res.data.last_name;
                        const email = res.data.email_id;
                        const mw_lead_id = res.data.lead_id;

                        let red_url = redirectnUrl()

                        var options = {
                            // "environment": "UAT",
                            "environment": "PRODUCTION",
                            // "clientId": "55449062",
                            "clientId": "29022165",
                            // "clientSecret": { "adhaar_xml": "uH46V0lIH4g7R0XG8BdtSJEEB7JR3ueA" },
                            "clientSecret": { "adhaar_xml": "mPFFdeXhhk0BsAUQuBG7CV4DKaarV8as" },
                            "logo": "https://www.moneywide.com/assets/images/logo.webp",
                            // "customTheme": <customTheme>,
                            "theme": {
                                primaryColor: '',
                                secondaryColor: 'F9FAFE'
                            },
                            "redirect_url": red_url,
                            "error_url": red_url,
                            "sendOtp": false,
                            "directInitiate": "okyc",
                            "firstName": firstName,
                            "lastName": lastName,
                            "email": email,
                            // "mobile": mobile,
                            "applicationNo": ((updated_id == "") ? `${mw_lead_id}` : `${mw_lead_id}_2`),
                            "hideShareCode": true,
                        };
                        setTimeout(() => {
                            var digitap_ekyc = window.ekyc(options);
                        }, 10000);
                    }
                }
                else {
                    const qry = encryptLeadIdStepId(mw_application_id, 4);
                    navigate(`/personal-loan/?fid=${qry}`);
                }
            }
            else if (stepId.toString() === "2") {

                if ((res.status == 1) && (res.status_code == 200)) {
                    let qry = queryString.parse(window.location.search);
                    let updated_Vkyc = "";
                    if (qry.updatedVkyc) {
                        updated_Vkyc = Base64.decode(qry.updatedVkyc);
                        updated_Vkyc = Base64.decode(updated_Vkyc);
                    }

                    if ((res.data.vkyc_transaction_id != "") && (res.data.vkyc_transaction_id != 0) && (res.data.vkyv_agentvkycStatus == "APPROVED") && (updated_Vkyc == "")) {

                        if (sessionStorage?.getItem("reference") == "true") {
                            sessionStorage?.clear();
                            const qry = encryptLeadIdStepId(mw_application_id, 21);
                            let checkCondition = Check_utm_source();
                            if (checkCondition && checkCondition.length !== 0) {
                                window.location.href = `${react_base_url}personal-loan/?fid=${qry}${checkCondition}`
                            }
                            else {
                                window.location.href = `${react_base_url}personal-loan/?fid=${qry}`
                            }
                        }
                        else {
                            sessionStorage?.clear();
                            const qry = encryptLeadIdStepId(mw_application_id, 20);
                            let checkCondition = Check_utm_source();
                            if (checkCondition && checkCondition.length !== 0) {
                                window.location.href = `${react_base_url}personal-loan/?fid=${qry}${checkCondition}`
                            }
                            else {
                                window.location.href = `${react_base_url}personal-loan/?fid=${qry}`
                            }
                        }

                    }
                    else {
                        vkycActivation(updated_Vkyc);
                    }
                }
                else {
                    if (sessionStorage?.getItem("reference") == "true") {
                        dispatch(updateFormState({ ...formStates, StartJourney: true, ReferenceDetails: true, error: true }))
                        let checkCondition = Check_utm_source();
                        if (checkCondition && checkCondition.length !== 0) {
                            navigate(`/personal-loan/?${checkCondition.slice(1)}`);
                        }
                        else {
                            navigate("/personal-loan");
                        }
                    }
                    else {
                        dispatch(updateFormState({ ...formStates, StartJourney: true, ResidentialAddress: true, error: true }))
                        let checkCondition = Check_utm_source();
                        if (checkCondition && checkCondition.length !== 0) {
                            navigate(`/personal-loan/?${checkCondition.slice(1)}`)
                        }
                        else {
                            navigate("/personal-loan");
                        }
                    }
                }
            }
            else if (stepId.toString() === "3") {

                if ((res.status == 1) && (res.status_code == 200)) {

                    if (res.data.digi_masked_adhar !== "") {
                        const qry = encryptLeadIdStepId(mw_application_id, 4);
                        let checkCondition = Check_utm_source();
                        if (checkCondition && checkCondition.length !== 0) {
                            navigate(`/personal-loan/?fid=${qry}${checkCondition}`);
                        }
                        else {
                            navigate(`/personal-loan/?fid=${qry}`);
                        }
                    }
                    else if ((res.data.digi_transaction_id != "") && (res.data.digi_transaction_id != 0)) {
                        gettingerror('digi initiate web', mw_application_id)
                        const txnid = res.data.digi_transaction_id;
                        const firstName = res.data.first_name;
                        const lastName = res.data.last_name;
                        const email = res.data.email_id;
                        const mobile = res.data.phone_no;
                        const mw_lead_id = res.data.lead_id;

                        sessionStorage?.setItem("cur_step", 1);
                        sessionStorage?.setItem("next_step", 2);
                        sessionStorage?.setItem("digilocker", "true")
                        sessionStorage?.setItem("okyc", "false")

                        var options = {
                            // "environment": "UAT",
                            "environment": "PRODUCTION",
                            "logo": "https://www.moneywide.com/assets/images/logo.webp",
                            "theme": {
                                primaryColor: '',
                                secondaryColor: 'F9FAFE'
                            },
                            "redirect_url": `${react_base_url}vkyc/redirect/`,
                            "error_url": `${react_base_url}vkyc/redirect/`,
                            "directInitiate": "digilocker",
                            "firstName": firstName,
                            "lastName": lastName,
                            "mobile": mobile,
                            "email": email,
                            "applicationNo": `${mw_lead_id}_1`,
                            "sendOtp": "false",
                            "transactionId": txnid,
                            "clientId": "29022165",
                            "clientSecret": { "digilocker": "mPFFdeXhhk0BsAUQuBG7CV4DKaarV8as" },
                        };
                        setTimeout(() => {
                            var digitap_ekyc = window.ekyc(options);
                        }, 10000);
                    }
                    else {
                        // gettingerror('digi initiate web',mw_application_id)
                        digilockerActivation();
                    }
                }
                else {
                    const qry = encryptLeadIdStepId(mw_application_id, 4);
                    let checkCondition = Check_utm_source();
                    if (checkCondition && checkCondition.length !== 0) {
                        navigate(`/personal-loan/?fid=${qry}${checkCondition}`);
                    }
                    else {
                        navigate(`/personal-loan/?fid=${qry}`);
                    }
                }
            }

            else if (stepId.toString() === "100") {

                if ((res.status == 1) && (res.status_code == 200)) {

                    let qry = queryString.parse(window.location.search);
                    let updated_selfie_vkyc = "";
                    if (qry.updated_selfie_vkyc) {
                        updated_selfie_vkyc = Base64.decode(qry.updated_selfie_vkyc);
                        updated_selfie_vkyc = Base64.decode(updated_selfie_vkyc);
                        // setUpdatedId(updated_selfie_vkyc);
                    }

                    if ((res.data.selfiestatus != "") && ((res.data.selfieoverall_status == "approval_pending") || (res.data.selfieoverall_status == "approved")) && updated_selfie_vkyc == "") {

                        const qry = encryptLeadIdStepId(mw_application_id, 20);
                        let checkCondition = Check_utm_source();
                        if (checkCondition && checkCondition.length !== 0) {
                            navigate(`/personal-loan/?fid=${qry}${checkCondition}`);
                        }
                        else {
                            navigate(`/personal-loan/?fid=${qry}`);
                        }

                    }
                    else {
                        selfieActivation(updated_selfie_vkyc);
                        
                    }
                }
                else {
                    dispatch(updateFormState({ ...formStates, StartJourney: true, ResidentialAddress: true }))
                    let checkCondition = Check_utm_source();
                    if (checkCondition && checkCondition.length !== 0) {
                        navigate(`/personal-loan/?${checkCondition.slice(1)}`);
                    }
                    else {
                        navigate("/personal-loan");
                    }
                }
            }

        }
    }, [credentialState, mw_application_id, stepId, res])

    const handleError = (e) => {
        window.location.reload();
    }

    return (
        <>
            <Helmet>

                {/* <script src="https://app.digio.in/sdk/v10/digio.js"></script> */}
            </Helmet>
            {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
            {redirect ? <NotFound /> :
                <>{!vkyc && <CKYCWaiting heading={"We are proceeding with KYC verification"} time={30}/>}

                    {vkyc && <div style={{ padding: "0% 10%", marginTop: "2%" }}>
                        <iframe allow="camera; microphone; geolocation" id="vkyc-iframe" src={`${iframeUrl}`} className="vkyc-class" ></iframe>
                    </div>}</>}


        </>
    )
}

export default NewVkyc;