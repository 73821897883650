import React, { useState } from "react";
import "./eMandate.css";
import FormHead from "../Journey Form/Form/FormHead";
import bank from "../../Icons/bank.svg";
import npci from "../../Icons/NPCI.webp";
import rupee from "../../Icons/rupee.svg";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  prodbase_url,
  client_id,
  client_key,
  source,
  react_base_url,
  verification_source,
  verified_by,
  verifier_name
} from "../Secret_Credentials/Secret_Credential";
import axios from "axios";
import MainLoader from "../MainLoader/MainLoader";
import { Check_utm_source, encryptMandateId, encryptOnlyLeadId, gettingerror, RelocateUTMCheck } from "../EverCalledFunctions/EverCalledFunctions";
import { GenerateToken, updateFormState, updateStepBar } from "../../Redux/action";
import ctaAnimation from '../assests/lootie-files/continue.json';
import Lottie from 'react-lottie-player';
import Base64 from "base-64";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
import { AxiosThirty } from "../AxiosConfiguration/AxiosConfig";
import { FetchCustomerPayload } from "../EverCalledFunctions/EverCalledPayload";
import { apiInstance } from "../Config/APIConfig";

const EMandate = () => {
  const dispatch = useDispatch();
  const [accountHolderName, setAccountHolderName] = useState("");
  const [bankname, setBankName] = useState("");
  const [account, setAccount] = useState("");
  const [loan, setLoan] = useState("");
  const [emi, setEmi] = useState("");
  const [via, setVia] = useState("");
  const [state, setState] = useState(true);
  const { credentialState, mw_application_id, step_bar, formStates, token } = useSelector(state => state);
  const [resp, setResp] = useState(false);
  const [loanAmt, setLoanAmt] = useState("");
  const [accountType, setAccountType] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [rocket, setRocket] = useState(false);
  const navigate = useNavigate();
  const [prystin,setPrystin] = useState(false);

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }



  React.useEffect(() => {
    dispatch(updateStepBar({ ...step_bar, profile: true, offer: true, verification: true, disbursal: true }))

    if (token == "") {
      myToken();
    }

  }, [])

  const hitFetchCustomer = async () => {
    let decFetch = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 22), {
      "token": "",
      "log": 1
    })
    // .then((res) => {
      if (decFetch.status_code == "200" && decFetch.status == "1") {
        if (decFetch.data.thank_you_flag == 1) {
          gettingerror("get thank you flag", mw_application_id)
          if (decFetch.data.thank_you_url !== "") {
            window.location.href = decFetch.data.thank_you_url;
            return
          }
          else {
            let qry = Base64.encode(`${mw_application_id}`);
            qry = Base64.encode(qry);
            RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=18`)
            return
          }

        }
        if(decFetch?.data['skip_screen_flag'] == 1){
          setPrystin(true);
        }
        if (decFetch.data.is_account_ok.toString() === "0") {
          setState(false)
          dispatch(updateFormState({ ...formStates, StartJourney: true, BankDetails: true, Emandate: false }));
          return;
        }
        if (decFetch.data.lead_source == 49) {
          setLeadSource(decFetch.data.lead_source);
        }
        setAccountHolderName(decFetch.data.accountHolderName);
        setBankName(decFetch.data.bank_name);
        setAccount(decFetch.data.account_no);
        setLoan(decFetch?.data?.sanction_loan_amount?.toLocaleString("en-IN"));
        setLoanAmt(decFetch.data.sanction_loan_amount);
        setEmi(decFetch?.data?.final_emi?.toLocaleString("en-IN"));
        setAccountType(decFetch.data.accountType);
        setIfsc(decFetch.data.ifsc_code);
        setState(false);
      }
      else if (decFetch.status_code == "429" && decFetch.status == "0") {
        setState(false)
        dispatch(updateFormState({ ...formStates, StartJourney: true, BankDetails: true, Emandate: false }));
        let checkCondition = Check_utm_source()
        if (checkCondition && checkCondition.length != 0) {
          navigate(`/persona-loan/?${checkCondition.slice(1)}`)
        } else {
          navigate("/personal-loan");
        }
      }
      else {
        setState(false)
        dispatch(updateFormState({ ...formStates, StartJourney: true, enashSorry: true, Emandate: false }));
        let checkCondition = Check_utm_source()
        if (checkCondition && checkCondition.length != 0) {
          navigate(`/persona-loan/?${checkCondition.slice(1)}`)
        } else {
          navigate("/personal-loan");
        }
      }
    // }).catch((err) => {
    //   setRocket(true);
    // })
  }

  React.useEffect(() => {
    if (token !== "") {
      hitFetchCustomer();
    }
  }, [token])




  const handleClick = () => {
    setResp(true);
    if (leadSource == 49) {
      gettingerror('initiate enach web', mw_application_id)
      let qry = queryString.parse(window.location.search);
      if (qry.redirect_url) {
        const qry_str = encryptMandateId(mw_application_id, via);
        window.location.href = `${react_base_url}partnermandate/?fid=${qry_str}&redirect_url=${qry.redirect_url}`;
      }
      else {
        const qryStr = encryptMandateId(mw_application_id, via);
        RelocateUTMCheck(`${react_base_url}emandate/?fid=${qryStr}`)
      }
    }
    else {
      gettingerror('initiate enach web', mw_application_id)
      const qryStr = encryptMandateId(mw_application_id, via);
      RelocateUTMCheck(`${react_base_url}emandate/?fid=${qryStr}`)
    }


  }

  const handleError = (e) => {
    window.location.reload();
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      {
        state ? <CKYCWaiting heading={"We are initiating the process of E-Mandate."} /> :
          <div>
            <Helmet>
              <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
              <script src="https://app.digio.in/sdk/v10/digio.js"></script>
            </Helmet>
            <FormHead formName="Setup EMI Auto-Debit" formSubHead={"Your EMIs will be deducted from this account"} />
            <div className="emandate-cont">
              <div className="emandate-cont-info">
                <div className="emandate-cont-info-1">
                  <img src={bank} alt="notfound" />
                </div>
                <div className="emandate-cont-info-2">
                  <p className="emandate-p">{bankname}</p>
                  <p className="emandate-p-1">A/C: {account}</p>
                </div>
              </div>
              <hr className="emandate-line" />
              <div className="emandate-loan-info">
                <div className="emandate-amount">
                  <p className="emandate-loan-p">Sanctioned Amount</p>
                  <p className="emandate-loan-p-1"><img className="rupee-symbol" src={rupee} />{loan}</p>
                </div>
                {prystin ? "": <div className="emandate-emi">
                  <p className="emandate-loan-p">EMI</p>
                  <p className="emandate-loan-p-2"><img className="rupee-symbol" src={rupee} />{emi}</p>
                </div> }
              </div>
              <p className="emandate-setup">Setup eMandate for Repayment using :</p>
              <div className="emandate-option">
                <div className="emandate-option-1" onClick={() => setVia(1)}>
                  <div className="emandate-radio">
                    <input disabled={resp} name="debitcard" onClick={() => setVia(1)} checked={via.toString() === '1'} value="netbanking" style={{ height: "20px", width: "20px", accentColor: "#0FA82E" }} type="radio" />
                  </div>
                  <div className="emandate-option-1-info">
                    <p className="emandate-option-p-1">Net Banking</p>
                    <p className="emandate-option-p-2">
                      Active net banking and credentials will be <br />needed
                    </p>
                  </div>
                </div>
                <hr className="emandate-line" />
                <div className="emandate-option-1" onClick={() => setVia(2)}>
                  <div className="emandate-radio">
                    <input disabled={resp} name="debitcard" onClick={() => setVia(2)} checked={via.toString() === '2'} value="debitcard" style={{ height: "20px", width: "20px", accentColor: "#0FA82E" }} type="radio" />
                  </div>
                  <div className="emandate-option-1-info">
                    <p className="emandate-option-p-1">ATM / Debit Card</p>
                    <p className="emandate-option-p-2">
                      Debit Card number and PIN will be needed
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={{ fontSize: "14px", color: "#1C1939" }}>Secured Verified by</p>
                <img className="npci-logo" src={npci} alt='notfound' />
              </div>
              <div style={{ marginTop: "-5px" }}>
                <p className="emandate-other">Note:- Ensure that the current balance in your account is more than minimum balance specified by your bank.</p>
                <p className="emandate-other">Your monthly due amount will be debited as per your EMI schedule. No EMI be deducted at this stage.</p>
              </div>
              <button disabled={resp} onClick={() => handleClick()} className={"footer-btn Bmargin" + (via ? " footer-btn-activate" : "")}>{!resp ?
                "Authorize" :
                <div className="continue-lottie">
                  <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                </div>}</button>
            </div>
          </div>
      }
    </>
  );
};

export default EMandate;
