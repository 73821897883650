import React from 'react';
import './mobilefooter.css'
import IndianFlag from "../Footer/images/indianflag.webp";
const MobileFooter = () => {
  return (
     <>
<div className='mobile-footer-cont'>
    <div>
          <p className='mobile-footer-p'>Copyright © 2022. All Rights Reserved. MoneyWide is a registered trademark, CIN- U67100HR2022PTC107092</p>
    </div>
    <div className='mobile-indian-flag'>
       <img src={IndianFlag} alt="Image not Found" />
        <span style={{marginLeft:"10px"}}>Built with Love <br />Made in India</span>
    </div>
</div>
     </>
  )
}

export default MobileFooter