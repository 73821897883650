import React, { useState } from 'react';
import RangeSlider from './RangeSlider';
import { useDispatch, useSelector } from 'react-redux';
import circle from '../PersonalLoan/images/circle.webp'
import pipes from '../PersonalLoan/images/pipes.webp'
import EmiIcon from "../../Icons/emi.svg";

import ForeclosureCalculation from './ForeclosureCalculation';
import { EmiPaid } from '../../Redux/action';
import { react_base_url } from '../Secret_Credentials/Secret_Credential';

function PrePaymentCalculator() {
    const { pa, lt, roi, emi, emiPaid, PartPayAmt } = useSelector((state) => state);
    const [state, setState] = React.useState([])
    const dispatch = useDispatch()

    const handleInputChange = (event) => {
        if (event.target.value <= lt) {
            dispatch(EmiPaid(event.target.value))
        }
    };

    React.useEffect(()=>{
        if(lt<emiPaid){
            dispatch(EmiPaid(lt))
        }

    },[lt])

    React.useEffect(() => {
        let prin_cf = pa;
        let arr = [];
        for (let i = 0; i < lt; i++) {
            let int_payable = (roi * prin_cf / 1200);
            let principal_repaid = (emi - int_payable);
            prin_cf = (prin_cf - principal_repaid);
            let payload = {
                key: i + 1,
                interest: Math.round(int_payable),
                principal: Math.round(principal_repaid),
                balance: Math.abs(Math.round(prin_cf))
            }
            arr = [...arr, payload];
        }
        setState(arr)
    }, [pa, lt, roi, emi])
    

    const handleScroll = () => {
        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        window.location.href = `${react_base_url}personal-loan`
    }

    const handleChange = (e) => {

    }

    return (
        <div className="calculator-main">
            <div className="calculator-head">
                <h3>MoneyWide Personal Loan <b>Foreclosure Calculator</b></h3>
                <p>Use the MoneyWide Foreclosure Calculator to calculate the interest saved in foreclosing the loan before the scheduled EMI period.</p>
            </div>
            <div className="calculator-body">
                <div className="calc-bg-first">
                    <img src={circle} alt="circle" />
                </div>
                <div className="calculator-body-left">
                    <h2>Foreclosure Calculator</h2>
                    <RangeSlider heading="Loan Amount" symbol={"(₹)"} text1="₹ " text2="" text3={"10000"} text4={"25L"} id="id1" uid="uid1" min="10000" max="2500000" step="10000" id_in_div="uid-1" value={pa} handleChange={(e) => handleChange(e)} />

                    <RangeSlider heading="Loan Tenure" symbol={"(Months)"} text1="" text2=" Months" text3={"3 Months"} text4={"84 Months"} id="id2" uid="uid2" min="3" max="84" step="1" id_in_div="uid-2" value={lt} handleChange={(e) => handleChange(e)} />

                    <RangeSlider heading="Rate of Interest" symbol={"(%)"} text1="" text2=" %" text3={"5%"} text4={"32%"} id="id3" uid="uid3" min="5" max="32" step="1" id_in_div="uid-3" value={roi} handleChange={(e) => handleChange(e)} />

                    <div className="foreclose-emi-paid">
                        <img className="tag-icon" src={EmiIcon} style={{ width: "28px" }} />
                        <input className="input-tag-box" id="input-tag-box" type="text" placeholder='Number of EMIs Paid' value={emiPaid} onChange={handleInputChange} autocomplete="off"/>
                    </div>

                </div>
                <div className="calculator-body-right">
                    <h3>Your Result</h3>
                    <ForeclosureCalculation />
                    <div className="calculator-btns">
                        <button className="apply-now-btn" onClick={handleScroll}>Apply Now</button>
                    </div>
                </div>
                <div className="calc-bg-second">
                    <img src={pipes} alt="pipes" />
                </div>
            </div>

            <div className="">
                {/* {show && <div className="repay-table">
                    <div className="repay-heading">
                        <span>Repayment <b>Schedule</b></span>
                    </div>
                    <div className="princip-monthly">
                        <div className="princip-cont">
                            <span>Principal Amount: ₹ {pa.toLocaleString('hi-IN')}</span>
                        </div>
                        <div className="princip-cont">
                            <span>Monthly EMI: ₹ {Math.round(emi).toLocaleString('hi-IN')}</span>
                        </div>
                    </div>
                    
                   
                </div>
                } */}

            </div>

        </div>
    )
}

export default PrePaymentCalculator;