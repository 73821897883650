import axios from "axios";

export const AxiosThirty = axios.create({
    timeout: 60000
});

export const AxiosSixty = axios.create({
    timeout: 60000
});

export const AxiosHundredTwenty = axios.create({
    timeout: 120000
})
