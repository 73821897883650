import React from "react";
import "./Infographpage.css";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken } from "../../Redux/action";
import { useParams } from "react-router-dom";
import { prodbase_url, react_base_url } from "../Secret_Credentials/Secret_Credential";
import InfographCard from "./InfographCard";
import axios from "axios";
import Helmet from "react-helmet";

function Infographpage() {
    const { token } = useSelector(state => state);
    const dispatch = useDispatch();
    const params = useParams();
    const [InfoData, setInfoData] = React.useState([]);
    const [mainData, setMAinData] = React.useState([]);
    const [spanState, setSpanState] = React.useState(false);

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }

    const handleView = (e, idx) => {
        window.location.href = `${react_base_url}infographics/${InfoData[idx].url}`
    }

    React.useEffect(() => {
        if (token == "") {
            myToken()
        }
    }, [])

    // const handleShare = ()=>{
    //         if (navigator.share) {
    //         navigator.share({
    //         title: 'Moneywide',
    //         url: window.location.href,
    //         }).then(() => {
    //         console.log('Thanks for sharing!');
    //         })
    //         .catch(console.error);
    //         } else {
    //         // fallback
    //         }
    // }

    const handleSpan = (e, idx) => {
        if (spanState) {
            document.getElementById(`${idx}-info-cont-p`).innerHTML = `${InfoData[idx].sub_heading.substr(0, 74)}...`;
            document.getElementById(`${idx}-info-cont-span`).innerText = "Read More";
        }
        else {
            document.getElementById(`${idx}-info-cont-p`).innerHTML = `${InfoData[idx].sub_heading}`;
            document.getElementById(`${idx}-info-cont-span`).innerText = "Read Less";
        }
        setSpanState(!spanState);

    }



    React.useEffect(() => {
        if (token !== "") {
            if (params) {
                axios.post(`${prodbase_url}api/fetch-infographic`, {
                    "source": "MWide",
                    "url": params.url
                }, {
                    headers: {
                        token
                    }
                }).then((res) => {
                    if ((res?.data?.status == "1") && (res?.data?.status_code == "200") && (res?.data?.data?.length !== 0)) {
                        //console.log(params.url);
                        setMAinData(res.data.data);
                    }
                    else {
                        window.location.href = `${react_base_url}infographics`
                    }
                }).then(() => {
                    axios.post(`${prodbase_url}api/fetch-infographic`, {
                        "source": "MWide",
                    }, {
                        headers: {
                            token
                        }
                    }).then((res) => {
                        if ((res?.data?.status == "1") && (res?.data?.status_code == "200") && (res?.data?.data?.length !== 0)) {
                            console.log(params.url)
                            for (let i = 0; i < res.data.data.length; i++) {
                                if (params.url != res.data.data[i].url) {
                                    setInfoData([...InfoData, res.data.data[i]]);
                                }
                            }
                            // setInfoData(res.data.data);
                        }
                        else {
                            window.location.href = `${react_base_url}`
                        }
                    })
                })
            }
        }
    }, [token])
    return (
        <>
            {mainData.length ? <Helmet>
                <title>{mainData[0].title_page}</title>
                <meta name="description" content={mainData[0].page_desc} />
                <meta name="keywords" content={mainData[0].page_keywords} />
            </Helmet> : ""}
            <div className="infobg-main-div">
                <section className="infobg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="infopage-heading">
                                    <p>{mainData.length ? mainData[0].main_heading : ""}</p>
                                    {/* <p>Pellentesque sed tellus nunc.</p> */}
                                </div>
                                <div className="infopage-media-date">
                                    <div className="infographdate">
                                        <span>Posted on</span>
                                        <p>{mainData.length ? mainData[0].date : ""}</p>
                                    </div>
                                    {/* <div className="infographmedia">
                                    <span>Share Now</span>
                                    <div className="social-icon">
                                        <a href="https://www.facebook.com/MoneyWide1/">
                                            <img src="<?php echo $head_url; ?>assets/images/moneywide/infographics/facebook.svg" alt="fb" />
                                            <img src="<?php echo $head_url; ?>assets/images/moneywide/infographics/facebook-mob.svg" alt="fb" />
                                        </a>
                                        <a href="https://twitter.com/MoneyWide_MW">
                                            <img src="<?php echo $head_url; ?>assets/images/moneywide/infographics/twitter.svg" alt="fb" />
                                            <img src="<?php echo $head_url; ?>assets/images/moneywide/infographics/twitter-mob.svg" alt="fb" />
                                        </a>
                                        <a href="https://www.linkedin.com/company/moneywide1/">
                                            <img src="<?php echo $head_url; ?>assets/images/moneywide/infographics/linkedin.svg" alt="fb" />
                                            <img src="<?php echo $head_url; ?>assets/images/moneywide/infographics/linkedin-mob.svg" alt="fb" />
                                        </a>
                                        <a href="https://www.instagram.com/moneywide_official/">
                                            <img src="<?php echo $head_url; ?>assets/images/moneywide/infographics/instagram.svg" alt="fb" />
                                            <img src="<?php echo $head_url; ?>assets/images/moneywide/infographics/instagram-mob.svg" alt="fb" />
                                        </a>
                                    </div>
                                </div> */}
                                    <div className="social-media">
                                        <p>Share Now</p>
                                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" className="facebook"></a>
                                        <a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target="_blank" className="twitter"></a>
                                        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`} target="_blank" className="linkedin"></a>
                                        <a href={`https://www.instagram.com/?url=${window.location.href}`} target="_blank" className="instagram"></a>
                                        {/* <a href="" target="_blank" className="youtube"></a> */}
                                    </div>
                                </div>
                                <div className="infographic-all-main">
                                    <img src={mainData.length ? "https://www.moneywide.com/" + mainData[0].image : ""} alt="icon" />
                                    {/* <p>{mainData.length ? mainData[0].sub_heading : ""}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="infosection">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 infopagespace">
                                <div className="otherinfograph">
                                    <span>Other Infographics</span>
                                </div>
                                <div className="infograph-cards-main">
                                    {InfoData.length ? InfoData.map((row, idx) => <InfographCard key={`${idx}-infodata`} id={`${idx}-infodata`} img={"https://www.moneywide.com/" + row.image} subhead={row.main_heading} description={row.sub_heading} viewdate={row.date} handleView={(e) => handleView(e, idx)} handleSpan={(e) => handleSpan(e, idx)} info_cont_p={`${idx}-info-cont-p`} info_cont_span={`${idx}-info-cont-span`} />) : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default Infographpage;