import React, { useState } from 'react';
import './../Form.css';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import { GenerateToken, setCurrentStep, updateFormState, updateStepBar, updateSuggestion_DropDown } from '../../../../Redux/action';
import pin from './../../../../Icons/pin.svg';
import ThisIsAnError from './../../../../Icons/error.svg';
import building from './../../../../Icons/building.svg';
import FormHead from '../FormHead';
import { useEffect } from 'react';
import axios from 'axios';
import { base_url, client_id, client_key, source, prodbase_url, verification_source, verified_by, verifier_name, react_base_url } from '../../../Secret_Credentials/Secret_Credential';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../../../assests/lootie-files/continue.json';
import Helmet from 'react-helmet';
import Base64 from "base-64";
import { useNavigate } from 'react-router-dom';
import { Check_utm_source, encryptQueryIdStepId, gettingerror } from '../../../EverCalledFunctions/EverCalledFunctions';
import { getAddressData, getCityName, getPinOption, getToken, WebAddressSubmit } from '../../../EverCalledFunctions/EverCalledApis';
import CKYCWaiting from '../../../LoginPopUp/CKYCWaiting';



const MailingAddress = () => {
  const [resp, setResp] = useState(false)
  const [load, setLoad] = useState(true);
  const [selected, setSelected] = useState(-1)
  const [cityFromPin, setCityFromPin] = useState({
    city: "",
    city_id: ""
  })
  const [pincodeData, setPincodeData] = useState({
    show: false,
    data: []
  })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formState } = useSelector(state => state)
  const { step_bar, mw_application_id, credentialState, loginDetails, resumeJourney, suggestionDropDown, token, residentialAddress } = useSelector(state => state)
  const { register, handleSubmit, reset, setError, setValue, formState: { errors }, watch, clearErrors } = useForm({
    defaultValues: {
      houseNo: "",
      area: "",
      landmark: "",
      pin: ""
    }, mode: "all"
  })
  const data = watch();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }

  }, [])

  React.useEffect(() => {
    if (residentialAddress.address_line1 !== "") {
      setValue('houseNo', residentialAddress.address_line1 + " " + residentialAddress.address_line2)
      setValue('area', residentialAddress.landmark + " " + residentialAddress.cityname)
      setValue('pin', residentialAddress.pincode)
      setLoad(false);
    }
  }, [residentialAddress])

  const getCityNameHere = async (pin) => {
    const APIDATA = await getCityName(pin, token);
    setCityFromPin({ ...cityFromPin, city: APIDATA.city, city_id: APIDATA.cityId })
  }

  const GetPinData = async (pin) => {
    const APIDATA = await getPinOption(pin, token);
    if (APIDATA.length !== 0) {
      await setPincodeData({ ...pincodeData, show: true, data: APIDATA })
      dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: true }))
    } else {
      setPincodeData({ ...pincodeData, show: false, data: [] })
    }

  }

  useEffect(() => {
    if (data.pin) {
      if (data.pin.toString()?.length < 3) {
        setPincodeData({ ...pincodeData, show: false, data: [] })
        setCityFromPin({ ...cityFromPin, city: "" })
      }
      else if (((data.pin >= 110) && (data.pin.length >= 3 && data.pin.length < 6))) {
        GetPinData(data.pin)
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
      }
      else if((data.pin <= 110000)){
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
      }
      else if (data.pin.toString().length === 6) {
        getCityNameHere(data.pin)
        setPincodeData({ ...pincodeData, show: false, data: [] })
      }
      else {
        setPincodeData({ ...pincodeData, show: false, data: [] })
        setCityFromPin({ ...cityFromPin, city: "" })
      }
    } else {
      setPincodeData({ ...pincodeData, show: false, data: [] })
      setCityFromPin({ ...cityFromPin, city: "" })
    }

  }, [data.pin])

  React.useEffect(() => {
    if (token !== "") {
    }
  }, [token])

  useEffect(() => {
    dispatch(updateStepBar({ ...step_bar, profile: true, offer: true, verification: true }))

  }, [])

  const submitAddress = async () => {
    const payLoad = {
      "curr_address_house_no": data.houseNo,
      "curr_address_area_sector": data.area,
      "curr_address_landmark": data.landmark,
      "curr_address_ovd_proof": 7,
      "current_pincode": data.pin,
      mw_application_id,
      "current_address_same_as": 1,
    }

    const APIDATA = await WebAddressSubmit(payLoad, "")
    if ((APIDATA.status == 1) && (APIDATA.status_code == 200)) {
      gettingerror('submit mailing address web',mw_application_id)
      if (APIDATA.data.next_step.toLowerCase() === "vkyc") {
        gettingerror('get vkyc at mailing web',mw_application_id)
        window.sessionStorage.setItem("cust_id", credentialState.cust_id);
        const qry = encryptQueryIdStepId(mw_application_id, 2);
        let checkCondition = Check_utm_source();
        if(checkCondition && checkCondition.length !== 0){
        window.location.href = `${react_base_url}vkyc/?fid=${qry}${checkCondition}`;
        }
        else{
          window.location.href = `${react_base_url}vkyc/?fid=${qry}`;
        }
      }
      else if (APIDATA.data.next_step.toLowerCase() === "selfie") {
        gettingerror('get selfie at mailing web',mw_application_id)
        window.sessionStorage.setItem("cust_id", credentialState.cust_id);
        let checkCondition = Check_utm_source();
        if(checkCondition && checkCondition.length !== 0){
        let qry = Base64.encode(`${mw_application_id}`);
        qry = Base64.encode(`${qry}`);
        window.location.href = `${react_base_url}vkyc/?fid=${qry}${checkCondition}`;
        }
        else{
          window.sessionStorage.setItem("cust_id", credentialState.cust_id);
        let qry = Base64.encode(`${mw_application_id}`);
        qry = Base64.encode(`${qry}`);
        window.location.href = `${react_base_url}vkyc/?fid=${qry}`;
        }
      }
      else if (APIDATA.data.next_step === "") {
        let qry = Base64.encode(mw_application_id)
        qry = Base64.encode(qry)
        let checkCondition = Check_utm_source();
        if(checkCondition && checkCondition.length !== 0){
          window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=16${checkCondition}`;
        }
        else{
          window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=16`;
        }
      }
      else {
        dispatch(updateFormState({ ...formState, error: true }))
        setResp(false)
      }
    }
    else {
      dispatch(updateFormState({ ...formState, error: true }))
      setResp(false);
    }

  }



  {/* -------------------------------Submit Function Function------------------------------------------------- */ }
  const onSubmit = (result) => {
    // console.log(result);
    setResp(true);

    submitAddress();

  }


  {/* -------------------------------Pincode Validation Function------------------------------------------------- */ }
  const handleNumbersOnly = (event) => {
    {
      if (data.pin.length === 6) {
        event.preventDefault()
      }
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }
  }




  const setThisPin = (data) => {
    setValue('pin', data);
    setPincodeData({ ...pincodeData, show: false, data: [] })
    getCityNameHere(data)
    clearErrors(['pin'])
  }

  const handleArrows = (e) => {
    if (pincodeData.show) {
      var key = e.keyCode;
      if (key != 40 && key != 38 && key != 13) {
        return;
      }
      if (key == 13) {
        e.stopPropagation()
        if (selected > -1 && selected < pincodeData.data.length) {
          setThisPin(pincodeData.data[selected])
          setSelected(-1)
        } else {
          setSelected(-1)
        }
      }
      else if (key == 38) {
        if (selected > 0) {
          setSelected(selected - 1)
        }
      }
      else if (key == 40) {
        if (selected < pincodeData.data.length - 1) {
          setSelected(selected + 1)
        }
      }
    }
  }
  const handleUpKeyNumber = (value, field, len) => {
    var regexp = /[^0-9]/g;
    if (value && value.toString().match(regexp)) {
      setValue(field, value.replace(regexp, ""))
    }
  }

  const SpaceManage = (field, value) => {
    setValue(field, value.replace(/^\s+/, ''));
  }

  const Blured = (field, value) => {
    // var newVal = value.trimEnd();
    var newVal = value.replace(/\s+$/, '');
    setValue(field, newVal);
    if (newVal.length < 3) {
      setError(field)
    } else {
      clearErrors([field])
    }
  }
  return (

    <>
      {load ? <CKYCWaiting heading={"We are fetching your details"} /> : <div>
        <FormHead formName="Mailing Address" formSubHead="Provide residential address details for KYC" />
        <div className="tag-for-input">
          {/* --------------------------------Current Address Field--------------------------------------- */}
          <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>

            {/* -----------------------------------House No Field----------------------------------------- */}
            <div className={errors.houseNo ? "input-tag error-border remove-bottom" : data.houseNo ? "input-tag add-border remove-bottom" : "input-tag remove-bottom"}>
              <img className="tag-icon" src={building} />
              <input disabled={resp} className="input-tag-box" type="text" placeholder='House No./Flat No./Building No.'  {...register('houseNo', { required: true, minLength: 3 })} onKeyUp={() => SpaceManage("houseNo", data.houseNo)} onBlur={() => Blured("houseNo", data.houseNo)} />
              {/* {errors.houseNo && <img src={ThisIsAnError} alt="" />} */}
            </div>
            {errors.houseNo && <div className='err-text'>Please Enter at least 3 Characters.</div>}


            {/* ---------------------------------------Area Field----------------------------------------- */}
            <div className={errors.area ? "input-tag error-border remove-bottom" : data.area ? "input-tag add-border remove-bottom" : "input-tag remove-bottom"}>
              <img className="tag-icon" src={pin} />
              <input disabled={resp} className="input-tag-box" type="text" placeholder='Area/Sector/Society' {...register('area', { required: true, minLength: 3 })} onKeyUp={() => SpaceManage("area", data.area)} onBlur={() => Blured("area", data.area)} />
              {/* {errors.area && <img src={ThisIsAnError} alt="" />} */}
            </div>
            {errors.area && <div className='err-text'>Please Enter at least 3 Characters.</div>}



            {/* ----------------------------------------Landmark Field----------------------------------------- */}
            <div className={errors.landmark ? "input-tag error-border remove-bottom" : data.landmark ? "input-tag add-border remove-bottom" : "input-tag remove-bottom"}>
              <img className="tag-icon" src={pin} />
              <input disabled={resp} className="input-tag-box" type="text" placeholder='Landmark'  {...register('landmark', { required: true, minLength: 3 })} autoComplete='off' onKeyUp={() => SpaceManage("landmark", data.landmark)} onBlur={() => Blured("landmark", data.landmark)} />
              {/* {errors.landmark && <img src={ThisIsAnError} alt="" />} */}
            </div>
            {errors.landmark && <div className='err-text'>Please Enter at least 3 Characters.</div>}


            {/* -------------------------------------Pincode Field----------------------------------------- */}
            <div className={"input-tag remove-bottom" + (errors.pin ? " error-border" : data.pin ? " add-border" : "")} >
              <img className="tag-icon" src={pin} />
              <input disabled={resp} className="input-tag-box" type="tel" placeholder='Pincode' minLength={6} maxLength={6}
                {...register('pin', { required: true, minLength: 6, maxLength: 6 })} onKeyDown={(e) => handleArrows(e)}
                onKeyUp={() => handleUpKeyNumber(data.pin, "pin", 6)} autoComplete="off"
              />
              {/* {errors.pin && <img src={ThisIsAnError} alt="" />} */}
              {suggestionDropDown.show && pincodeData.show && pincodeData.data.length && <div className="dropDownBox">
                {pincodeData.data.map((row, ind) => {
                  return <div className={'dropDownValue' + (selected === ind ? " select" : "")} key={ind} onClick={() => setThisPin(row)}>{row}</div>
                })}
              </div>}

            </div>
            {errors.pin && <div className="err-text">{cityFromPin.city}</div>}
            {!errors.pin && data.pin && <div style={cityFromPin.city == "Invalid Pincode" ? {color:"#D0342C"}:{}}
            className='api-text'>{cityFromPin.city}</div>}


            {/* ---------------------------------------Submit button----------------------------------------- */}
            <button type='submit' disabled={resp || Object.keys(errors).length !== 0 || !data.landmark || cityFromPin.city == "Invalid Pincode"} className={'footer-btn Bmargin Tmargin' + ((data.landmark && data.area && data.houseNo && data.pin && Object.keys(errors).length === 0 ) && cityFromPin.city != "Invalid Pincode" ? " footer-btn-activate" : "")}>
              {!resp ?
                "Continue" :
                <div className="continue-lottie">
                  <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                </div>}
            </button>
          </form>
        </div>
      </div>}
    </>
  )
}


export default MailingAddress;