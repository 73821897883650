import React, { useState } from 'react';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import queryString from "query-string";
import Base64 from "base-64";
import { useEffect } from 'react';
import { decryptAGRID } from '../EverCalledFunctions/EverCalledFunctions'
import MainLoader from '../MainLoader/MainLoader';
import { useDispatch, useSelector } from 'react-redux'
import { updateFormState, updateMwApplicationId } from '../../Redux/action';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import { react_base_url } from '../Secret_Credentials/Secret_Credential';

const Agreement = () => {
    const [loadingTime, setLoadingTime] = useState(true)
    const { formStates } = useSelector(state => state)
    const dispatch = useDispatch();

    const decryptAgrid = async () => {
        const mw_app_id = await decryptAGRID();
        dispatch(updateFormState({ ...formStates, StartJourney: true, Terms: true }))

        if (mw_app_id) {
            setLoadingTime(false)
            dispatch(updateMwApplicationId(mw_app_id))
        } else {
            setLoadingTime(true)
        }
    }


    useEffect(() => {
        const qry = queryString.parse(window.location.search);
        if (qry.agrid) {
            decryptAgrid();
        }
        else {
            window.location.href = `${react_base_url}personal-loan`;
        }

    }, [])
    return (
        <>
            {loadingTime && <CKYCWaiting heading={"We are redirecting you to the next page."} />}
            <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." /></>
    )
}

export default Agreement;