import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomDropdown from "./CustomDropdown";

const PolicyMenuAll = () => {
  const [diff,setDiff] = React.useState('Cancellation Policy');
  const [searchParams, setSearchParams] = useSearchParams();
  const app = searchParams.get("app");
  const navigate = useNavigate();
  const location = useLocation();
  const data = [
    {
      name: "Cancellation Policy",
      url: "cancellation-and-refund-policy",
    },
    {
      name: "Privacy Policy",
      url: "privacy-policy",
    },
    {
      name: "Terms and Conditions",
      url: "terms-and-conditions",
    },
  ];

  React.useEffect(() => {
    // const qry = window.location.href;
    const { pathname } = location;
    if (pathname === "/cancellation-and-refund-policy") {
      setDiff('Cancellation Policy');
    } else if (pathname === "/privacy-policy") {
      setDiff('Privacy Policy');
    } else if (pathname === "/terms-and-conditions") {
      setDiff('Terms and Conditions');
    }
    if(app){
      if (pathname === "/cancellation-and-refund-policy/") {
        setDiff('Cancellation Policy');
      } else if (pathname === "/privacy-policy/") {
        setDiff('Privacy Policy');
      } else if (pathname === "/terms-and-conditions/") {
        setDiff('Terms and Conditions');
      }
    }
  }, [location]);

  const handleClick = (url) => {
    if(app) {
      // window.location.href = `${react_base_url}${url}/?app=MQ==`
      navigate(`/${url}/?app=MQ==`)
    }
    else {
      // window.location.href = `${react_base_url}${url}`;
      navigate(`/${url}`)
    }
  }
  
  return (
    <>
      <div className="policyholder">
        {data.map((el, index) => {
          return (
            <a onClick={() => handleClick(el.url)} className={diff === el.name ? "policyname":"policyname-unselected"} key={index}>
              {el.name}
            </a>
          );
        })}
      </div>
      <div className="policyholder-mob-select">
          <CustomDropdown/>
      </div>
    </>
  );
};

export default PolicyMenuAll;
