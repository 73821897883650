import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

const Base64ToPdf = ({ base64pdf }) => {
    const [pages, setPages] = useState([])

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const onDocumentLoadSuccess = ({ numPages }) => {
        let list = []
        for (let i = 1; i <= numPages; i++) {
            list.push(i)
        }
        setPages(list)
    };




    return (
        <div>
            <Document file={`data:application/pdf;base64,${base64pdf}`} onLoadSuccess={onDocumentLoadSuccess}>
                {pages.length > 0 && pages.map((item) => {
                    return <Page pageNumber={item} key={item} pageIndex={item} />
                })}
            </Document>
        </div>
    );
};


export default Base64ToPdf