import React from 'react';
import './Calculator.css';
import { useDispatch, useSelector } from 'react-redux';
import { loanTenure, principalAmount, rateOfInterest, setMonthlyExpense, setMonthlyIncome } from '../../Redux/action';

function RangeSlider({ heading, text1, text2, id, uid, min, max, step, id_in_div, value, handleChange, symbol }) {

    const dispatch = useDispatch();
    const { pa, lt, roi, monthly_income, monthly_expense } = useSelector((state) => state);

    const [val, setVal] = React.useState();
    const [percentage, setPercentage] = React.useState("");

    // const d = document.getElementById(id);

    const setData = (payload) => {
        if (uid === "uid1") {
            dispatch(principalAmount(payload));
        }
        else if (uid === "uid2") {
            dispatch(loanTenure(payload))
        }
        else if (uid === "uid3") {
            dispatch(rateOfInterest(payload))
        }
        else if (uid === "uid4") {
            dispatch(setMonthlyIncome(payload))
        }
        else if (uid === "uid5") {
            dispatch(setMonthlyExpense(payload))
        }
    }

    React.useEffect(() => {
        var slider = document.getElementById(uid);
        var output = document.getElementById(id);
        output.innerHTML = slider.value; // Display the default slider value
        // console.log(d.value);



        // Update the current slider value (each time you drag the slider handle)
        slider.oninput = function () {
            output.innerHTML = this.value;
            const val = this.value;
            setData(val)
        }
    }, [setData, id, uid, pa, lt, roi, monthly_income, monthly_expense])

    React.useEffect(() => {
        // let percentage = (((+pa) - (+min)) / ((+max) - (+min))) * 100;
        // if (percentage <= 100) {

        //     console.log(pa, +min, +max);
        //     console.log(percentage);
        //     document.getElementById(
        //         "uid-1"
        //     ).style.background = `linear-gradient(to right, #50299c, #7a00ff ${percentage}%, #d3edff ${percentage}%, #dee1e2 100%`;
        // }

    }, [pa])

    React.useEffect(() => {

    }, [lt])

    React.useEffect(() => {

    }, [roi])

    return (
        <div className="range-main">
            <div className="rangeslider-main">
                <div className="rangesslide-new">
                    <h3>{heading} {symbol}</h3>
                    <p>{text1}<span id={id}></span>{text2}</p>
                </div>
                <div className='rangeslider-input-div' id={id_in_div}>
                    <input type="range" className="newrange" min={min} max={max} value={value} step={step}  id={uid} onChange={(e) => handleChange(e)} />
                </div>
            </div>
            <div className='range'>
                <span>{text1 + min + text2}</span>
                <span>{text1 + max + text2}</span>
            </div>
        </div>
    )
}

export default RangeSlider;