import React from 'react'
import phone from '../../Icons/phone.svg';
import calender from '../../Icons/calender.svg';
import { useForm } from 'react-hook-form';
import DateCalender from '../DateCalender/DateCalender';
import { useState } from 'react';
import { useEffect } from 'react';
import { SaveLoginData, formatDate, makePaymentAPI } from './MyAccountAPIs';
import ctaAnimation from '../assests/lootie-files/continue.json'
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { GenerateToken } from '../../Redux/action';
import { getToken } from '../EverCalledFunctions/EverCalledApis'

const MakePaymentForm = ({ setCurrentForm, setBaseData, baseData }) => {
    const [startDate, setStartDate] = useState(null)
    const [resp, setResp] = useState(false)
    const [err, setErr] = useState(false)
    const { register, handleSubmit, reset, formState: { errors }, watch, setValue, clearErrors } = useForm({
        defaultValues: {
            mobile: "",
            dob: ""
        }, mode: "all"
    })
    const { token } = useSelector(state => state)
    const dispatch = useDispatch()
    const data = watch()
    const timeLimit = {
        minTime: new Date(`${new Date().getFullYear() - 56}-1-1`),
        maxTime: new Date(`${new Date().getFullYear() - 21}-1-1`)
    }

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }

    useEffect(() => {
        if (token == "") {
            myToken()
        }
    }, [])

    const onSubmit = async (data) => {
        if (data.mobile && startDate) {
            setResp(true)
            data.dob = formatDate(startDate)

            const Response = await makePaymentAPI(data, token)
            if (Response.status == "1" && Response.status_code == "200") {
                let payLoad = {
                    userNo: data.mobile,
                    userName: Response.data.customer_name,
                    mw_app_id: Response.data.mw_application_id,
                    cust_id: Response.data.cust_id
                }
                setBaseData({
                    ...baseData,
                    name: Response.data.customer_name,
                    Id: Response.data.loan_account_no,
                    program: Response.data.program_name,
                    data: Response.data
                })
                SaveLoginData(payLoad)
                setCurrentForm(2)

            } else if (Response.status == "0" && Response.status_code == "429") {
                setCurrentForm(5)
            } else if (Response.status == "0" && Response.status_code == "426") {
                setErr(true)
                setResp(false)
            } else {
                setCurrentForm(5)
            }
        } else {
            setErr(true)
        }
    }


    const NumberOnly = (value) => {
        var lastseven = value.substr(value.length - 7);
        var num_charstring_number = value.substr(4, 4);
        var last_digit = value.slice(0, 2);
        var cr_str = Array(6).join(last_digit);
        var result = false;
        if (/^[6789]\d{9}$/i.test(value)) {
            result = true;
        }
        if (value === '9876543210') {
            result = false;
        }
        if (/^(\d)\1+$/.test(lastseven)) {
            result = false;
        }
        if (value === cr_str) {
            result = false;
        }
        if (num_charstring_number == 'XXXX') {
            result = true;
        }
        if (/(\d)\1{6,}/.test(value)) {
            result = false;
        }
        return result;

    }


    const handleUpKey = (value, field) => {
        var regexp = /[^0-9]/g;
        if (value && value.match(regexp)) {
            setValue(field, value.replace(regexp, ""))
        }
    }
    return (
        <div className="make-payment-form">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                <div className={(errors.mobile && data.mobile) ? "popUp-input-tag popUp-red-error-border" : (data.mobile ? " popUp-input-tag popUp-add-border" : " popUp-input-tag")}>
                    <img src={phone} alt="" />
                    <input type="tel" maxLength={10} placeholder="Enter Mobile Number" {...register("mobile", {
                        minLength: 10,
                        maxLength: 10,
                        validate: NumberOnly,
                        required: data.email ? true : false
                    })}
                        onKeyUp={() => handleUpKey(data.mobile, "mobile")}
                        readOnly={data.email ? true : false}
                    />
                    {errors.mobile && data.mobile && <div className="login-area-login-err">Enter a valid number</div>}
                </div>
                <div className={"popUp-input-tag " + (startDate ? " popUp-add-border" : "")}>
                    <img src={calender} alt={"NA"} />
                    <DateCalender startDate={startDate} setStartDate={setStartDate} minYear={new Date().getFullYear() - 56} timeLimit={timeLimit} placeHolder={"Date of Birth"} maxYear={new Date().getFullYear() - 21} />
                </div>

                {err && <p style={{ textAlign: "center", color: "#D0342C", fontSize: "14px", lineHeight: "140%" }}>Incorrect Mobile Number or DOB</p>}

                <button type='submit' disabled={resp || !data.mobile || !startDate} className={'footer-btn' + (!errors.mobile && data.mobile && startDate ? " footer-btn-activate" : "")}>{!resp ?
                    "Get Details" :
                    <div className="continue-lottie">
                        <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                    </div>}</button>
            </form>
        </div>
    )
}

export default MakePaymentForm
