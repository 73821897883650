import React from "react";
import queryString from "query-string";
import Base64 from "base-64";
import { useSelector, useDispatch } from "react-redux";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import {
    updateCredentialsState,
    updateMwApplicationId,
    updateResidentialAddress,
    updateFormState,
    setPreApprovedOfferDetails,
    GenerateToken,
} from "../../Redux/action";
import {
    prodbase_url,
    client_id,
    client_key,
    source,
    verification_source,
    verified_by,
    verifier_name,
    react_base_url,
    doc_upload_url,
    base_url,
} from "../Secret_Credentials/Secret_Credential";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
    Check_utm_source,
    decryptQueryIdCustomerIdStepId,
    encryptAGRID,
    encryptLeadIdStepId,
    encryptOnlyLeadId,
    encryptQueryIdOfferId,
    encryptQueryIdStepId,
    gettingerror,
} from "../EverCalledFunctions/EverCalledFunctions";
import {
    getCustId,
    getToken,
    ResumeJourneyApi,
    VkycKrown,
} from "../EverCalledFunctions/EverCalledApis";
import { AxiosThirty } from "../AxiosConfiguration/AxiosConfig";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
import { apiInstance } from "../Config/APIConfig";

function NewVkycRedirect() {
    const {
        mw_application_id,
        credentialState,
        formStates,
        preApprovedOfferDetails,
        token
    } = useSelector((state) => state);
    const [rocket, setRocket] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [txnId, setTxnId] = React.useState("");

    const getCustIdMwAppIdFromCookie = () => {
        let cookieString = document.cookie;
        let obj = {};
        if ((cookieString.includes("cust_id")) && (cookieString.includes("mw_app_id"))) {
            cookieString = cookieString.split(";");
            for (let i = 0; i < cookieString.length; i++) {
                const cookie = cookieString[i].split("=");
                let cookieName = cookie[0];
                let cookieValue = cookie[1];
                if (cookieName.includes(" ")) {
                    cookieName = cookieName.replace(/^\s+/, '');
                }
                if (cookieValue.includes(" ")) {
                    cookieValue = cookieValue.replace(/^\s+/, '');
                }
                if (cookieName === "cust_id") {
                    obj["cust_id"] = cookieValue;
                }
                else if (cookieName === "mw_app_id") {
                    obj["mw_app_id"] = cookieValue;
                }
            }
        }
        return obj;
    }

    const getUtmFromCookie = () => {
        let cookieString = document.cookie;
        let utm = "";
        if ((cookieString.includes("utm"))) {
            cookieString = cookieString.split(";");
            for (let i = 0; i < cookieString.length; i++) {
                const cookie = cookieString[i].split("=");
                let cookieName = cookie[0];
                let cookieValue = cookie[1];
                if (cookieName.includes(" ")) {
                    cookieName = cookieName.replace(/^\s+/, '');
                }
                if (cookieValue.includes(" ")) {
                    cookieValue = cookieValue.replace(/^\s+/, '');
                }
                if (cookieName === "utm") {
                    utm = cookieValue;
                }
            }
        }
        return utm;

    }

    const VkycRedirection = async () => {
        const query = queryString.parse(window.location.search);
        let obj = getCustIdMwAppIdFromCookie();
        let app_id = "";
        let cust_id = "";
        const checkCondition = getUtmFromCookie();
        if ((obj?.cust_id) && (obj?.mw_app_id)) {
            app_id = obj["mw_app_id"];
            cust_id = obj["cust_id"];
        }
        else {
            setRocket(true);
        }


        if (query.vkycstatus == "true" && query.event == "DIGIVKYC_COMPLETED") {
            setTimeout(async () => {
                let decVkycKrown = await apiInstance
                    .post(
                        `vkyc/get-transactionid-v2`,
                        {
                            "lead_id": app_id,
                        },
                        {
                            "token": token,
                            "log": 1
                          }
                    )
                // .then((res) => {
                if (decVkycKrown.status_code) {
                    gettingerror('vkyc cron web', app_id)
                    localStorage?.clear();
                    if (sessionStorage.getItem("reference") == "true") {
                        if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                            const qry = encryptLeadIdStepId(app_id, 21);
                            window.location.href = `${react_base_url}personal-loan/?fid=${qry}${checkCondition}`
                        }
                        else {
                            const qry = encryptLeadIdStepId(app_id, 21);
                            window.location.href = `${react_base_url}personal-loan/?fid=${qry}`
                        }
                    }
                    else {
                        if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                            const qry = encryptLeadIdStepId(app_id, 20);
                            window.location.href = `${react_base_url}personal-loan/?fid=${qry}${checkCondition}`;
                        }
                        else {
                            const qry = encryptLeadIdStepId(app_id, 20);
                            window.location.href = `${react_base_url}personal-loan/?fid=${qry}`;
                        }
                    }
                }

                // }).catch((err) => {
                //     setRocket(true);
                // });
            }, 10000);
        } else if (
            query.vkycstatus == "false" &&
            query.event == "DIGIVKYC_INCOMPLETED"
        ) {
            localStorage?.clear();
            if (sessionStorage.getItem("reference") == "true") {
                if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                    const qry = encryptLeadIdStepId(app_id, 21);
                    window.location.href = `${react_base_url}personal-loan/?fid=${qry}${checkCondition}`
                }
                else {
                    const qry = encryptLeadIdStepId(app_id, 21);
                    window.location.href = `${react_base_url}personal-loan/?fid=${qry}`
                }
            }
            else {
                if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                    const qry = encryptLeadIdStepId(app_id, 4);
                    window.location.href = `${react_base_url}personal-loan/?fid=${qry}${checkCondition}`;
                }
                else {
                    const qry = encryptLeadIdStepId(app_id, 4);
                    window.location.href = `${react_base_url}personal-loan/?fid=${qry}`;
                }
            }
        } else if (query.msg == "Video verification completed") {
            localStorage?.clear();
            if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                const qry = encryptLeadIdStepId(app_id, 20);
                window.location.href = `${react_base_url}personal-loan/?fid=${qry}${checkCondition}`;
            }
            else {
                const qry = encryptLeadIdStepId(app_id, 20);
                window.location.href = `${react_base_url}personal-loan/?fid=${qry}`;
            }
        } else if (
            query.vkycstatus == "false" &&
            query.event == "DIGIVKYC_SCHEDULED"
        ) {
            let payLoad = {
                "customer_id": cust_id,
                "is_scheduled": 1,
                "scheduled_date_time": query.slotTime,
                "mw_application_id": app_id,
                source,
                verification_source,
                verified_by,
                verifier_name
            }
            let decVkycShedule = await apiInstance.post(`vkyc/submit-schedule-data`, payLoad, {
                "token": token,
                "log": 1
              })
            // .then((res) => {
            if (decVkycShedule.status_code) {
                sessionStorage?.clear();
                localStorage?.clear();
                dispatch(
                    updateFormState({ ...formStates, StartJourney: true, HunterHold: true })
                );
                if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                    navigate(`/personal-loan/?${checkCondition.slice(1)}`);
                }
                else {
                    navigate("/personal-loan");
                }
            }
            // }).catch((err) => {
            //     setRocket(true);
            // })

        } else if (
            query.vkycstatus == "false" &&
            query.event == "DIGIVKYC_SLOTDELETED"
        ) {
            localStorage?.clear();
            if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                const qry = encryptLeadIdStepId(app_id, 4);
                window.location.href = `${react_base_url}personal-loan/?fid=${qry}${checkCondition}`;
            }
            else {
                const qry = encryptLeadIdStepId(app_id, 4);
                window.location.href = `${react_base_url}personal-loan/?fid=${qry}`;
            }

        } else if (
            query.vkycstatus == "false" &&
            query.event == "DIGIVKYC_PERMISSIONS_DENIED"
        ) {
            localStorage?.clear();
            if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                const qry = encryptLeadIdStepId(app_id, 4);
                window.location.href = `${react_base_url}personal-loan/?fid=${qry}${checkCondition}`;
            }
            else {
                const qry = encryptLeadIdStepId(app_id, 4);
                window.location.href = `${react_base_url}personal-loan/?fid=${qry}`;
            }
        } else if (query.vkycstatus == "false" && query.event == "DIGIVKYC_REJECTED") {
            localStorage?.clear();
            if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                const qry = encryptOnlyLeadId(app_id)
                window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=16${checkCondition}`
            }
            else {
                const qry = encryptOnlyLeadId(app_id)
                window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=16`
            }
        }
    };

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }

    React.useEffect(() => {
        if (token == "") {
            myToken();
        }
    }, [])

    const digilockerGetCustomer = async (app_id, cust_id, checkCondition) => {
        let decDigi = await apiInstance
            .post(
                `digilocker/get-customer-details`,
                {
                    "partner_id": 5,
                    "level_id": 1,
                    "lead_id": app_id,
                    "verified_by": verified_by,
                    "verifier_name": verifier_name,
                    "verification_source": verification_source,
                    "customer_id": cust_id,
                    "product_id": 0,
                    "source": source,
                },
                {
                    "token": "",
                    "log": 1
                  }
            )
        // .then((res) => {
        if (decDigi.status == 1 && decDigi.data.status_code == 200) {
            gettingerror('submit digi data web', app_id)
            if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                const qry = encryptLeadIdStepId(app_id, 4);
                navigate(`/personal-loan/?fid=${qry}${checkCondition}`);
            }
            else {
                const qry = encryptLeadIdStepId(app_id, 4);
                navigate(`/personal-loan/?fid=${qry}`);
            }
        } else {
            if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                const qry = encryptLeadIdStepId(app_id, 4);
                navigate(`/personal-loan/?fid=${qry}${checkCondition}`);
            }
            else {
                const qry = encryptLeadIdStepId(app_id, 4);
                navigate(`/personal-loan/?fid=${qry}`);
            }
        }
        // }).catch((err) => {
        //     setRocket(true);
        // });
    }

    const submitOkyc = async (okycStatusCode, cust_id, app_id, txnId, checkCondition) => {
        let decSubmitOkyc = await apiInstance
            .post(
                `web/submit-okyc-data`,
                {
                    "source": source,
                    "status_code": okycStatusCode,
                    "customer_id": cust_id,
                    "partner_id": 5,
                    "level_id": 1,
                    "lead_id": app_id,
                    "verified_by": verified_by,
                    "verifier_name": verifier_name,
                    "verification_source": verification_source,
                    "transactionId": txnId,
                },
                {
                    "token": "",
                    "log": 1
                  }
            )
            // .then((res) => {
                if (decSubmitOkyc.status == 1 && decSubmitOkyc.status_code == 200) {
                    gettingerror('submit okyc data web', app_id)
                    if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                        const qry = encryptLeadIdStepId(app_id, 4);
                        navigate(`/personal-loan/?fid=${qry}${checkCondition}`);
                    }
                    else {
                        const qry = encryptLeadIdStepId(app_id, 4);
                        navigate(`/personal-loan/?fid=${qry}`);
                    }
                } else {
                    gettingerror('error in submit okyc data web', app_id)
                    if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                        const qry = encryptLeadIdStepId(app_id, 4);
                        navigate(`/personal-loan/?fid=${qry}${checkCondition}`);
                    }
                    else {
                        const qry = encryptLeadIdStepId(app_id, 4);
                        navigate(`/personal-loan/?fid=${qry}`);
                    }
                }
            // }).catch((err) => {
            //     setRocket(true);
            // });
    }

    React.useEffect(() => {
        if (token !== "") {

            const query = queryString.parse(window.location.search);
            const checkCondition = sessionStorage.getItem("utm");
            if (
                query.vkycstatus == "true" ||
                query.vkycstatus == "false" ||
                query.msg == "Video verification completed"
            ) {
                VkycRedirection();
            }

            let app_id = "";
            let cust_id = "";
            let cur_step = "";
            let txnId = "";
            if (query.fid) {
                cur_step = decryptQueryIdCustomerIdStepId();
                app_id = cur_step[0];
                cust_id = cur_step[1]
                txnId = cur_step[3];
                cur_step = cur_step[2];
            }
            else {
                app_id = sessionStorage.getItem("mw_app_id");
                cust_id = sessionStorage.getItem("cust_id");
                cur_step = sessionStorage.getItem("cur_step");
                // next_step = window.sessionStorage.getItem("next_step");
            }


            dispatch(updateCredentialsState({ ...credentialState, cust_id }));
            dispatch(updateMwApplicationId(app_id));

            if (!query.vkycstatus) {
                if (cur_step == "1") {
                    if (query.success) {
                        if (sessionStorage.getItem("digilocker") == "true") {
                            digilockerGetCustomer(app_id, cust_id, checkCondition);
                        }
                        else {
                            submitOkyc(200, cust_id, app_id, txnId, checkCondition)
                        }
                    } else {
                        if (sessionStorage.getItem("digilocker") == "true") {
                            if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                                const qry = encryptLeadIdStepId(app_id, 4);
                                navigate(`/personal-loan/?fid=${qry}${checkCondition}`);
                            }
                            else {
                                const qry = encryptLeadIdStepId(app_id, 4);
                                navigate(`/personal-loan/?fid=${qry}`);
                            }
                        }
                        else {
                            submitOkyc(400, cust_id, app_id, txnId, checkCondition);
                        }
                    }
                } else if (cur_step == "100") {
                    if (query.status == "success") {
                        gettingerror('selfie redirection web', app_id)
                        if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                            const qry = encryptLeadIdStepId(app_id, 20);
                            navigate(`/personal-loan/?fid=${qry}${checkCondition}`);
                        }
                        else {
                            const qry = encryptLeadIdStepId(app_id, 20);
                            navigate(`/personal-loan/?fid=${qry}`);
                        }

                    } else {
                        if (checkCondition !== null && checkCondition !== undefined && checkCondition !== "") {
                            const qry = encryptLeadIdStepId(app_id, 4);
                            navigate(`/personal-loan/?fid=${qry}${checkCondition}`);
                        }
                        else {
                            const qry = encryptLeadIdStepId(app_id, 4);
                            navigate(`/personal-loan/?fid=${qry}`);
                        }
                    }
                }
            }

        }
    }, [token]);

    const handleError = (e) => {
        window.location.reload();
    }

    return (
        <>
            {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
            <div className="load-loading">
                <CKYCWaiting heading={"We are redirecting you to the next page."} />
            </div>
        </>
    );
}

export default NewVkycRedirect;
