import React, { useEffect } from 'react'
import FAQSection from '../FAQSection/FAQSection';
import "./NewCreditPL.css";
import '../PersonalLoan/PersonalLoan.css';
import EligibilityCriteria from '../PersonalLoan/images/eligibility-criteria.webp';
import greenticknew from '../../Icons/greenticknew.svg';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import MobileFooter from '../Footer/MobileFooter';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import FeaturesBenefits from '../PersonalLoan/FeaturesBenefits';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import StepSlider from '../HowToApplyPLSLIDER/StepSlider';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';
import EligibilityCriteriaSection from '../PersonalLoan/EligibilityCriteria';
const NewCreditPL = () => {
    var loan_type_id_page = 56;
    // var data = {
    //     heading: "Instant Personal Loan without CIBIL Score",
    //     data: [
    //         "Zero Credit score or history",
    //         "No documents upload",
    //         "Paperless approval",
    //         "Loan upto Rs. 300000",
    //         "No documents upload",
    //         "100% Digital Process"
    //     ],
    //     sub_bread:"New Credit Personal Loan"
    // }
    const dispatch = useDispatch()
    const [data, setData] = React.useState({ sub_bread: "New Credit Personal Loan" })
    const { PLLoader, formStates, mwpages } = useSelector(state => state)
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY <= 200 || window.scrollY >= 3400) {
            sety(false);
        }
        else {
            sety(true);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])

    const dataa = {
        heading: [
            {
                left: "Eligibility Criteria for ",
                right: "First Time Borrowers"
            }
        ],
        subhead: [
            {
                head: "Age",
                content: "The age of the borrower must be between 21-35 years."
            },
            {
                head: "Employer",
                content: "All salaried professionals working at a private sector company or public undertaking."
            },
            {
                head: "Minimum Income",
                content: "Your minimum monthly income should be atleast ₹ 20,000."
            }
        ]
    }
    const datafandb= {
        givendata:[
        {
        span:"Get a Loan from ",
        para:"Rs. 20,000 to 1 lakh.",
    },
    {  
        span:"No credit score ",
        para:"or history required",
    },
    {  
        span:"Quick approval",
        para:"and disbursement",
    },
    {  
        span:"Repayment tenure",
        para:"from 3 to 12 months",
    },
    {  
        span:"100% paperless",
        para:"process on approval",
    },
    {  
        span:"Part-payment",
        para:" and foreclosure facility",
    }
    ]
    }

    return (
        <>
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi />
            <div className='journey-stop'>
                <NewLoginForm pageType={loan_type_id_page} mapData={data} />
                <div className='ploan-content1  manage-top-margin mob-margin-top web-margin-top what-is-extra'>
                    {y && <RightSidePopUp />}
                    <h2 className="heing bot-6 add-btom-line cetnred" style={{ marginBottom: "18px" }}><b>New Credit Personal</b> Loan MW First</h2>
                    <p className="heading-p">The term New Credit Personal Loan refers to the unsecured loan  to those borrowers who do not have a credit history i.e have not availed of any loans in the past (NH) and their credit score is -1. The loan is also available for the borrowers who have a zero credit score means their credit account does not have enough information, usually available for less than six months(NA). MoneyWide offers New Credit Personal Loan under the program known as MF First.</p>
                </div>
                {/* <div className='ploan-content1 manage-top-margin'>
                    <h2 className="heading-h2">Can You Get a Personal loan with a <b>0 or -1 CIBIL Score?</b></h2>
                    <p className="heading-p">A good credit score is the first thing that helps you to avail of any loans without rejection at lower rates. It is also one of the primary things that lenders check as you apply for a  <a href="https://www.moneywide.com/personal-loan/">personal loan</a>. It helps the lenders to determine your creditworthiness and ensure lenders that you can repay your dues on time. So does that mean you cannot avail a personal loan without CIBIL score? No, with MoneyWide, you can avail an instant personal loan without credit score.</p>
                </div> */}
                 <EligibilityCriteriaSection elgdata={dataa} />
                <FeaturesBenefits features={datafandb}/>
                <section className="pl-charges newcredtopmargin">
                    <div className="ploan-contanier ourproducts-main">
                        <div className="box-sh">
                            <div className="new-cred-main">
                                <div className="product-heading nwprod-heading">
                                    <p>New Credit</p>
                                    <span>Personal Loan</span>
                                    <p>Interest Rates and Other Charges</p>
                                </div>
                                <div className="instant-box nw-cred-box">
                                    <div className="instant-head-cont">
                                        <div className="instant-head">
                                            <span>Instant Personal Loan</span>
                                        </div>
                                        <div className="instant-contnt">
                                            <div className="instant-inner nwinstant">
                                                <span>Interest Rates:</span>
                                                <p>30% to 35% P.A</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Processing Fees:</span>
                                                <p>4% of Loan Amount</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Partpayment Charges:</span>
                                                <p>3% of part principal payment after 1st EMI</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Foreclosure Charges:</span>
                                                <p>4% of outstanding amount after 1st EMI</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="instant-bottom">
                                        <a href='#'>Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <StepSlider />
                {/* <section className='FAQs'>
                    <FAQSection />
                </section> */}
                {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                    <FAQSectionChk data={mwpages.faqdata} />
                </section> : ""}
            </div>
            <div className='journey-start' style={{ display: "none" }}>
                {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                {(!formStates.IsIframe) && <MobileFooter />}
            </div>
        </>
    )
}

export default NewCreditPL;