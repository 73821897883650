import axios from "axios";
import React from "react";
import Helmet from "react-helmet";
import {
  prodbase_url,
  client_id,
  client_key,
  source,
  react_base_url,
  verification_source,
  verified_by,
  verifier_name,
} from "../Secret_Credentials/Secret_Credential";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { decryptOnlyLeadId } from "../EverCalledFunctions/EverCalledFunctions";
import NewLeftPart from "../Journey Form/FormLeftPart/NewLeftPart";
import NotFound from "../NotFound/NotFound";
import {
  GenerateToken,
  updateAppStatus,
  updateCredentialsState,
  updateFormState,
  updateMwApplicationId,
} from "../../Redux/action";
import { getCustId, getToken } from "../EverCalledFunctions/EverCalledApis";
import MainLoader from "../MainLoader/MainLoader";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";

const Emandate_Setup = () => {
  const {
    step_bar,
    mw_application_id,
    credentialState,
    loginDetails,
    formStates,
    token
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [state, setState] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }


  React.useEffect(() => {
    if (token == "") {
      myToken();
    }

  }, []);

  
  const getCustomerId = async (mw_app_id) => {
    const cust_id = await getCustId(mw_app_id, token,15);
    window.sessionStorage.setItem("cust_id", cust_id);
    dispatch(updateCredentialsState({ ...credentialState, cust_id }));
  };

  const decryptFid = async () => {
    const mw_app_id = await decryptOnlyLeadId();
    dispatch(updateMwApplicationId(mw_app_id));
    if(window.sessionStorage.getItem("cust_id") && (window.sessionStorage.getItem("cust_id") !== undefined)) {
      dispatch(updateCredentialsState({...credentialState, cust_id: window.sessionStorage.getItem("cust_id")}))
    }
    else {
      getCustomerId(mw_app_id);
    }
    
  }

  React.useEffect(() => {
    if(token !== ""){
      let qry = queryString.parse(window.location.search);
      if (qry.app) {
        dispatch(updateAppStatus(true));
    }
      if (qry.fid) {
        decryptFid();
      }
      else {
          setRedirect(true)
          setState(false)
      }
    }
  }, [token]);

  React.useEffect(() => {
    if (credentialState.cust_id !== "") {
      setState(false);
      dispatch(
        updateFormState({ ...formStates, StartJourney: true, Emandate: true })
      );
    }
  }, [credentialState]);

  return (
    <>
      {state &&
        <CKYCWaiting heading={"We are initiating the process of E-Mandate."} />}

      {redirect ? <NotFound/> : <NewLeftPart
        title="Get Instant Loan Using MoneyWide"
        content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process."
      />}
    </>
  );
};

export default Emandate_Setup;
