import { useSelector } from "react-redux"
import { source, client_id, client_key, verification_source, verified_by, verifier_name } from "../Secret_Credentials/Secret_Credential"

export const FetchCustomerPayload = (mw_application_id, step,ip) => {
    return {
        "mw_application_id": +mw_application_id,
        "step": +step,
        "verifier_name": verifier_name,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "source": source,
        "page_url":window.location.href,
        "ip_address":ip ? ip : ""
    }
} 

export const DecisionDemogOffer = (mw_application_id, stage,cust_id) => {
    return {
            "source": "MWide",
             "mw_application_id": mw_application_id,
             "product_id": 56,
             "customer_id": cust_id,
             "offer_type": 1,
             "level_id": 1,
             "verified_by": verified_by,
             "verifier_name": verifier_name,
             "verification_source": verification_source,
             "stage_name": stage
    }
}

