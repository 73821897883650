import React from 'react';
import Lottie from "react-lottie-player";
import lottieJson from "../assests/lootie-files/lf20_dxvedfri (1).json";

const NomineSorry = () => {
  return (
    <div className='manage-sry-screen'>
            <div className="lotie-div">
                <Lottie loop animationData={lottieJson} play className="sry-popup-lottie" />
            </div>
            <div className="sry-heading">Sorry! </div>
            <div className="sry-text" style={{paddingBottom:"50px"}}>Please check required params and try again!</div>
            {/* <div className="sry-timer">You may try again after {store} days</div> */}
        </div>
  )
}

export default NomineSorry
