import React, { useEffect } from 'react'
//import "./NewCreditPL.css";
import "../NewCreditPersonalLoan/NewCreditPL.css"
import '../PersonalLoan/PersonalLoan.css';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import MobileFooter from '../Footer/MobileFooter';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import FeaturesBenefits from '../PersonalLoan/FeaturesBenefits';
import Helmet from 'react-helmet';
import FAQSectionChk from "../FAQ/FAQ";
import StepSlider from '../HowToApplyPLSLIDER/StepSlider';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
// import greenticknew from '../../Icons/greenticknew.svg';
// import EligibilityCriteria from '../PersonalLoan/images/eligibility-criteria.webp';
import EligibilityCriteriaSection from '../PersonalLoan/EligibilityCriteria';


const ShortTermPl = () => {
    const { mwpages } = useSelector((state) => state);
    const [data, setData] = React.useState({sub_bread: "Short Term Personal Loan" })

    var loan_type_id_page = 56;
    const dispatch = useDispatch()
    const { PLLoader, formStates } = useSelector(state => state)
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY >= 2400) {
            sety(false);
        }
        else {
            sety(true);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])

    const datafandb= {
        givendata:[
        {
        span:"Borrow loan from",
        para:"Rs. 10000 to upto Rs. 50,000",
    },
    {
        span:"Flexible repayment tenure",
        para:"for 3, 6, and 9 months",
    },
    {
        span:"Instant disbursal",
        para:" within 24 hours",
    },
    {
        span:"100% digital loans with ",
        para:"online approval and disbursement",
    },
    {
        span:"Flexible repayment tenure",
        para:" upto 36 months",
    },
    {
        span:"Zero hidden charges",
        para:"on Short Term Personal Loan",
    }
    ]
    }
    const dataa = {
        heading: [
            {
                left: "Eligibility Criteria for ",
                right: "Short Term Loan"
            }
        ],
        subhead: [
            {
                head: "Age",
                content: "You must be between 22 - 40  years of age."
            },
            {
                head: "Employer",
                content: "All salaried employees working at a private sector company or public undertaking."
            },
            {
                head: "Minimum Income",
                content: "The minimum income for a short term personal loan is ₹18000."
            },
            {
                head:"Credit Score  ",
                content:"At Moneywide, you can get a Short Term Personal Loan with a minimum credit score of 600 or above."
            }
        ]
    }

    return (
        <>
         {/* {loader ?<CKYCWaiting/>:""} */}
        {/* {rocket ? <NewErrorCase handleClick={()=>meta()}/>:""} */}
        <div>
                <HittingMetaTagApi />
            </div>

            <Helmet>
                <title>{mwpages.metatagsdata?.page_title}</title>
                <meta name="description" content={mwpages.metatagsdata?.page_description} />
                <meta name="keywords" content={mwpages.metatagsdata?.page_keywords} />
            </Helmet>

            <div className='journey-stop'>
                <NewLoginForm pageType={loan_type_id_page} mapData={data} />
                <div className='what-is ploan-content1 manage-top-margin mob-margin-top web-margin-top'>
                    {y && <RightSidePopUp />}
                    <h2 className="heading-h2">Short Term Personal Loans</h2>
                    <p className="heading-p">The term ‘short term’ refers to the limited period of repayment tenure. MoneyWide offers short-term personal loans ranging from 3 months to 9 months to fulfill various personal or business needs. You do not require security or collateral to avail of such loans.</p>
                </div>

              
                <EligibilityCriteriaSection elgdata={dataa} />
                <FeaturesBenefits features={datafandb}/>
                <section className="pl-charges newcredtopmargin">
                    <div className="ploan-contanier ourproducts-main">
                        <div className="box-sh">
                            <div className="eligibility-criteria-box new-cred-main">
                                <div className="product-heading nwprod-heading">
                                    <p>Short Term</p>
                                    <span>Personal Loan</span>
                                    <p>Interest Rates and Other Charges</p>
                                </div>
                                <div className="instant-box nw-cred-box">
                                    <div className="instant-head-cont">
                                        <div className="instant-head">
                                            <span>Short Term Personal Loan</span>
                                        </div>
                                        <div className="instant-contnt">
                                            <div className="instant-inner nwinstant">
                                                <span>Interest Rates:</span>
                                                <p>36%</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Processing Fees:</span>
                                                <p>5% of loan amount</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Partpayment Charges:</span>
                                                <p>Not Allowed</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Foreclosure Charges:</span>
                                                <p>Allowed after 1 EMI at 4%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="instant-bottom">
                                        <a href='#'>Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <StepSlider/>
                {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                        <FAQSectionChk data={mwpages.faqdata} />
                    </section> : ""}
            </div>
            <div className='journey-start' style={{ display: "none" }}>
                {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                {(!formStates.IsIframe) && <MobileFooter />}
            </div>
        </>
    )
}

export default ShortTermPl;