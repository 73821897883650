import React, { useEffect } from 'react';
import "../LoginPopUp/LoginPopUp.css"

const UnsubscribePopup = ({ subscribeClick }) => {


    return (
        <>
            <div className="blur-background">
                <div className="pop-content login-area-popup" onClick={(e) => { e.stopPropagation() }}>
                    <div className="popup-form-head">
                        <span>Unsubscribe from updates</span>
                    </div>
                    <div className="popup-form unsubscribeform">
                        <form autoComplete={"off"} style={{ marginTop: "30px" }}>
                            <div className="unsubscribe-content"><p>You are about to unsubscribe for all communication with MoneyWide. You might miss important updates and offers.</p>
                            </div>

                            <div className="main-button-unsubscribe">
                                <div className="popUp-btn active-btn">
                                    <button type="button" onClick={() => subscribeClick(0)}>
                                        Cancel
                                    </button>
                                </div>
                                <div className="popUp-btn active-btn" style={{ marginTop: "20px" }}>
                                    <button type='button' onClick={() => subscribeClick(1)} className="unsubbtn" style={{ backgroundColor: "#ffffff", borderRadius: "10px", color: "#525660", border: "2px solid #525660", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        Unsubscribe
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default UnsubscribePopup