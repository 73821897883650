import axios from "axios";
import React from "react";
import Helmet from "react-helmet";
import {
  prodbase_url,
  client_id,
  client_key,
  source,
  react_base_url,
  verification_source,
  verified_by,
  verifier_name,
} from "../Secret_Credentials/Secret_Credential";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { decryptOnlyLeadId } from "../EverCalledFunctions/EverCalledFunctions";
import NewLeftPart from "../Journey Form/FormLeftPart/NewLeftPart";
import {
  GenerateToken,
  updateAppStatus,
  updateCredentialsState,
  updateFormState,
  updateMwApplicationId,
} from "../../Redux/action";
import { getCustId, getToken } from "../EverCalledFunctions/EverCalledApis";
import MainLoader from "../MainLoader/MainLoader";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import Base64 from "base-64";

const Sign = () => {
  const {
    step_bar,
    mw_application_id,
    credentialState,
    loginDetails,
    formStates,
    token
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [state, setState] = React.useState(true);


  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

  React.useEffect(()=>{
    if (token == "") {
      myToken();
    }
  },[])

  const getCustomerId = async (mw_app_id) => {
    const cust_id = await getCustId(mw_app_id,token,14);
    window.sessionStorage.setItem("cust_id", cust_id);
    dispatch(updateCredentialsState({ ...credentialState, cust_id }));
  };

  const decryptFid = async () => {
    try{
    let qry = queryString.parse(window.location.search);
    let mw_app_id = Base64.decode(qry.fid)
    mw_app_id = Base64.decode(mw_app_id);
    mw_app_id = mw_app_id.split("lead_id=");
    mw_app_id = Base64.decode(mw_app_id[1]);
    mw_app_id = Base64.decode(mw_app_id);
    dispatch(updateMwApplicationId(mw_app_id));
    if(window.sessionStorage.getItem("cust_id")) {
        dispatch(updateCredentialsState({...credentialState, cust_id: window.sessionStorage.getItem("cust_id")}));
    }
    else {
        getCustomerId(mw_app_id);
    }
    
    }
    catch{
      window.location.href = `${react_base_url}personal-loan`;
    }
  }

  React.useEffect(() => {
    if(token !== ""){
      let qry = queryString.parse(window.location.search);
      if (qry.app) {
        dispatch(updateAppStatus(true));
    }
      if (qry.fid) {
        decryptFid();
      }
      else {
        window.location.href = `${react_base_url}personal-loan`;
      }
    }
  }, [token]);

  React.useEffect(() => {
    if (credentialState.cust_id !== "") {
      setState(false);
      dispatch(updateFormState({...formStates,Esign:true}));
    }
  }, [credentialState]);

  return (
    <>
      {state ? (
        <CKYCWaiting heading={"We are fetching your details to initiate eSigning of Agreement"} />
      ) : (
        <NewLeftPart
          title="Get Instant Loan Using MoneyWide"
          content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process."
        />
      )}
    </>
  );
};

export default Sign;
