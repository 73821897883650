import React, { useState } from "react";
import "./CustomerReviewMain.css";
import custreviewfeed from "../../Icons/customerreviewfeed.svg";
import share_icon from "../../Icons/share_icon.svg";
import onlystar from "../../Icons/onlystar.svg";
import axios from "axios";
import {
  prodbase_url,
  source,
  verification_source,
  verified_by,
  verifier_name,
} from "../Secret_Credentials/Secret_Credential";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken } from "../../Redux/action";
import ReviewImg from "../ReviewSlider/ReviewImg";
import facebook from "../../Icons/facebook.svg";
import twitter from "../../Icons/twitter.svg";
import linkedin from "../../Icons/linkdin.svg";
import { decryptWithHybrid, encryptWithHybrid } from "../../Keys";
import { apiInstance } from "../Config/APIConfig";

function CustomerReviewMain() {
  const imagePerRow = 9;
  const { token } = useSelector((state) => state);
  const [data, setData] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [hoverIdx, setHoverIdx] = useState(-1)
  const [isread, setIsread] = useState(false);
  const [hovered, setHovered] = useState(false)
  const [next, setNext] = useState(imagePerRow);
  const ReviewData = async () => {
    let payLoad = {
      source: source,
      verifier_name: verifier_name,
      verified_by: verified_by,
      verification_source: verification_source,
    }
    // let payload = await encryptWithHybrid(JSON.stringify(payLoad));
    let dec = await apiInstance
      .post(
        `customer-reviews`,
        payLoad,
        {
          token,
        }
      )
      // .then((res) => {
        // let dec_data = decryptWithHybrid(res.data.encryptedMessage, res.data.encryptedAesKey, res.data.iv);
        if (dec.status === "1" && dec.status_code === "200") {
          setData(dec.data.data);
          setShow(true);
        }
      // });
  };

  React.useEffect(() => {
      ReviewData();
    window.scrollTo(0, 0);
  }, []);


  const closeshare = () => {
    for (let i = 0; i < data.length; i++) {
      let ele = document.getElementById(`${i}-share`)
      if (ele) {
        ele.style.display = "none";
      }
    }
  }

  const mouseOver = (idx) => {
    setHoverIdx(idx)
    setHovered(true)
  }

  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };

  const mouseleavebox = (idx) => {
    setHoverIdx(-1)
    setHovered(false)
  }


  // let a = document.getElementById(`${idx}-open`)

  return (
    <>
      <div className="share-review-div-main" onClick={closeshare}>
        <div className="review-breadcrum">
          <div className="bred-cont">
            <a href={`/${window.location.search}`}>Home</a>&gt; <span> Reviews</span>
          </div>
          <div className="customer-review">
            <div className="revie-left">
              <h2>Customer Review</h2>
              <p>
                We think our job is to take responsibility for the complete user
                experience. And if it's not up to par, it's our fault, plain and
                simple.
              </p>
            </div>
            <div className="revie-right">
              <img src={custreviewfeed} alt="cust-review-feed" />
            </div>
          </div>
        </div>
        <div className="all-review-bg">
          <div className="all-reviews">
            <h2>Our Happy Customers</h2>
            <div className="re-views-cards">
              {data ? data?.slice(0, next)?.map((el, idx) => {
                return (
                  <div key={idx} onMouseLeave={() => {

                    mouseleavebox(idx)
                  }} onMouseOver={() => { mouseOver(idx) }} className={!hovered ? "single-review-card" : hoverIdx !== idx && hovered ? "opacityChanger" : "single-review-card"}>
                    <div className="head-main-review">
                      <div className="head-part-review">
                        <span>{el.cust_name.charAt(0)}</span>
                      </div>
                      <div className="head-cont-area">
                        <span>{el.cust_name}</span>
                        <p>{el.cust_city}</p>
                      </div>
                    </div>
                    <div className="review-date-area">
                      <div className="review-right-date">
                        <ReviewImg star={el.cust_rating} />
                      </div>
                      <div className="review-left-date">
                        <span>{el.review_date}</span>
                      </div>
                    </div>
                    <div className="feeback-review">
                      <p>{el.cust_review}</p>
                    </div>
                    {/* <div onClick={(e)=>{openmedia(e,idx)}} className="review-right-date">
                      <img src={share_icon} alt="share_icon" />
                      <span>Share</span>
                      <div id={`${idx}-share`} className="share-review-div">
                        <a href="https://www.facebook.com/MoneyWide1/" target="_blank"> <img src={facebook}/></a>
                        <a href="https://twitter.com/MoneyWide_MW" target="_blank"> <img src={twitter}/></a>
                        <a href="https://www.linkedin.com/company/moneywidemw/" target="_blank"> <img src={linkedin}/></a>
                        </div>
                    </div> */}
                  </div>
                );
              }) : ""}
            </div>
            {next < data?.length && (
              <div className="more-open-btn">
                <button onClick={handleMoreImage}>Load More</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerReviewMain;
