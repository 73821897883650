import React, { useState } from "react";
import FormHead from "../FormHead";
import "./resisential.css";
import { useDispatch, useSelector } from "react-redux";
import { GenerateToken, updateCredentialsState, updateFormState, updateMwApplicationId, updateResidentialAddress, updateStepBar } from "../../../../Redux/action";
import Data from "./Data";
import Lottie from 'react-lottie-player';
import ctaAnimation from '../../../assests/lootie-files/continue.json';
import axios from "axios";
import { prodbase_url, client_id, client_key, source, verification_source, verified_by, verifier_name, react_base_url } from "../../../Secret_Credentials/Secret_Credential";
import { useNavigate } from "react-router-dom";
import { getAddressData, getToken } from "../../../EverCalledFunctions/EverCalledApis";
import CKYCWaiting from "../../../LoginPopUp/CKYCWaiting";
import { gettingerror } from "../../../EverCalledFunctions/EverCalledFunctions";
import queryString from 'query-string';


const ResidentialAddress = () => {
  const [resp, setResp] = useState(false);
  const { formStates, residentialAddress, mw_application_id, credentialState, step_bar,token } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("")
  const navigate = useNavigate();
  const [load,setLoad] = useState(true);
  const [Address, setAddress] = useState({});

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  React.useEffect(() => {
    dispatch(updateStepBar({ ...step_bar, profile: true, offer: true, verification: true }));
    if(token == "") {
      myToken();
    }
  },[])

  React.useEffect(() => {
    if(residentialAddress.address !== "") {
      setLoad(false);
    }
  },[residentialAddress])

  const handleClick = () => {

    setResp(true);
    if (selected===1) {
      gettingerror('yes address web',mw_application_id)
      dispatch(updateFormState({ ...formStates, ResidentialAddress: false, MailingAddress: true }));
    }else{
      gettingerror('no address web',mw_application_id)
      let qry = queryString.parse(window.location.search);
      if( credentialState.lead_source != 455 ){
        dispatch(updateFormState({ ...formStates, ResidentialAddress: false, CurrentAddress: true }));
      }else if(qry['pretry']){
        dispatch(updateFormState({ ...formStates, ResidentialAddress: false, CurrentAddress: true }));
      }else{
        dispatch(updateCredentialsState({...credentialState, pretry: 1}))
        dispatch(updateFormState({ ...formStates, ResidentialAddress: false, KycVerification: true }));
      }
    }

  }

  const EnterNewOne = () => {

    dispatch(updateFormState({ ...formStates, ResidentialAddress: false, CurrentAddress: true }));


  }


  return (
    <>
    {load ? <CKYCWaiting heading={"We are Fetching your Details"}/> : <div>
        <FormHead
          formName="Residential Address"
          formSubHead={"Provide your residentiaal address details for KYC"}
        />
        <div className="res-question">
          <span>Are you currently staying at the below mentioned address?</span>
        </div>
        <div className="address-box">


          {Object.keys(residentialAddress).length !== 0 &&
            <div className={"choose-residential-address"} >
              <div className="residential-cont-info">
                {/* <div className="residential-radio">
                  <input disabled={resp} type="radio" name="address" readOnly checked={selected ? true : false} />
                </div> */}

                <div className="residential-option-1-info">
                  <p className="residential-option-p-2">{residentialAddress.address_line1}</p>
                  <p className="residential-option-p-2">{residentialAddress.address_line2}</p>
                  <p className="residential-option-p-2">{residentialAddress.landmark}</p>
                  {/* <p className="residential-option-p-2">{setResidentialAddress.state}</p> */}
                  <p className="residential-option-p-2">{residentialAddress.cityname}</p>
                  <p className="residential-option-p-2">{residentialAddress.pincode}</p>
                </div>
              </div>
            </div>
          }


          {/* <p onClick={() => EnterNewOne()} className={"add-resident-address" + (Object.keys(Address).length === 0 ? " marginT60" : "")}>+ Add New Address</p> */}


            <div className="options-confirm">
              <button className={"yes-option-confirm"+(selected===1 ?" activated-option":"")}  onClick={()=>setSelected(1)}><input readOnly checked={selected===1} type="radio"/>Yes</button>
              <button className={"no-option-confirm "+(selected===2?" activated-option":"")}  onClick={()=>setSelected(2)}><input readOnly checked={selected===2} type="radio"  />No</button>
            </div>




          <button disabled={resp}
            className={"footer-btn Tmargin Bmargin"+(selected ? " footer-btn-activate " : "")} onClick={() => handleClick()}
          >{!resp ?
            "Continue" :
            <div className="continue-lottie">
              <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
            </div>}
          </button>
        </div>
      </div>}
    </>
  )
}

export default ResidentialAddress;
