import React, { useEffect } from 'react';
import FormHead from '../Journey Form/Form/FormHead';
import { useForm } from 'react-hook-form';
import phone from '../../Icons/phone.svg';
import { useState } from 'react';
import './ServiceReq.css';
import {
  prodbase_url,
  base_url,
  client_id,
  client_key,
  source,
  verification_source,
  verified_by,
  verifier_name
} from "../Secret_Credentials/Secret_Credential";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { GenerateToken, updateFormState, updateservicecredential, updateservicelogin, updateserviceshow } from '../../Redux/action';
import { getToken } from '../EverCalledFunctions/EverCalledApis';

const ServiceLogin = () => {
  let dispatch = useDispatch();
  const { setService, serviceNumber, serviceCred,token,formStates } = useSelector(state => state)
  const [check, updateCheck] = useState(true)
  const { register, handleSubmit, reset, formState: { errors }, watch, setError, setValue, clearErrors } = useForm({
    defaultValues: {
      mobile: ""
    }, mode: "onBlur"
  })

  const data = watch();

  
  // function knowdevice() {
  //   if (window.innerWidth > 1024) {
  //     setDevice_id("1");
  //   }
  //   else if (window.innerWidth > 480 && window.innerWidth < 1024) {
  //     setDevice_id("3")
  //   }
  //   else {
  //     setDevice_id("2");
  //   }
  // }

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }


  useEffect(() => {
    if(token == "") {
      myToken();
    }
    // knowdevice();
    // getData()
  }, [])

  

  // const getData = async () => {
  //   const res = await axios.get('https://geolocation-db.com/json/')
  //   setIP(res.data.IPv4)
  // }

  const onSubmit = data => {
    dispatch(updateservicelogin({ ...serviceNumber, mobile: data.mobile }));
    data = {
      ...data,
      // login_type: "OTP",
      // source_from: 1,
      api_mode: "LIVE",
      verified_by,
      verification_source,
      verifier_name,
      login_from: 2,
      source: source,
      // IP: ip,
      // device_type: +(device_id),
      // customer_id: 0,
      // lead_id: 0
    };
    const payload = {
      client_id: client_id,
      client_key: client_key,
      source: source,
    };
    // axios.post(`${prodbase_url}token`, payload).then((res) => {
    //   const token = res.data.token;
      axios.post(`${prodbase_url}generate-otp`, data, {
        headers: {
          token
        }
      }).then((res) => {
        if((res.data.status_code === "200") && (res.data.status === "1")){
          if (res.data.data.otp_id) {
            dispatch(updateserviceshow({ ...setService, serviceLogin: false, serviceOtp: true, count: res.data.data.count }));
            dispatch(updateservicecredential({ ...serviceCred, Otp_id: res.data.data.otp_id }))
          }
          if(res.data.data.otp_id === ""){
            dispatch(updateserviceshow({ ...setService, serviceLogin: false, serviceWrong: true}));
          }
        }
        else{
          dispatch(updateFormState({ ...formStates, error: true }));
        }
       
      })
    // })
  }
  // const handleNumbersOnly = (event) => {
  //   {
  //     if (!/[0-9]/.test(event.key)) {
  //       event.preventDefault();
  //     }
  //   }
  // } 

  
  useEffect(() => {
    if (data.mobile) {
      if (6 <= data.mobile.charAt(0) && data.mobile.charAt(0) <= 9) {
        clearErrors('mobile')
      }
      else {
        setError('mobile', { type: 'custom' });
      }
    }
  }, [data.mobile]) 


  useEffect(() => {
    if (data.mobile) {
      if (6 <= data.mobile.charAt(0) && data.mobile.charAt(0) <= 9) {
        clearErrors('mobile')
      }
      else {
        setError('mobile', { type: 'custom' });
      }
    }
  }, [data.mobile])

  const NumberOnly = (value) => {
    var lastseven = value.substr(value.length - 7);
    var num_charstring_number = value.substr(4, 4);
    var last_digit = value.slice(0, 2);
    var cr_str = Array(6).join(last_digit);
    var result = false;
    if (/^[6789]\d{9}$/i.test(value)) {
      result = true;
    }
    if (value === '9876543210') {
      result = false;
    }
    if (/^(\d)\1+$/.test(lastseven)) {
      result = false;
    }
    if (value === cr_str) {
      result = false;
    }
    if (num_charstring_number == 'XXXX') {
      result = true;
    }
    if (/(\d)\1{6,}/.test(value)) {
      result = false;
    }
    return result;

  }

  const handleUpKey = (value, field) => {
    var regexp = /[^0-9]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""))
    }
  }
  return (
    <>
      <FormHead formName={"Service Request"} formSubHead={"How may we help you ?"} progressBar={true} />
      <div className="tag-for-input">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          <div style={{ position: "relative" }} className={(errors.mobile && data.mobile) ? "input-tag error-border" : data.mobile ? "input-tag add-border" : "input-tag "}>
            <img className="tag-icon" src={phone} />
            <input className="input-tag-box service-class" id="login-input" type="tel" placeholder='Enter Mobile Number' minLength={10} maxLength={10}
              {...register('mobile', { required: true, minLength: 10, maxLength: 10, validate: NumberOnly })}  onKeyUp={() => handleUpKey(data.mobile, "mobile")} autoComplete="off" autoFocus />
            {/* {errors.mobile && <abbr title="Enter valid Mobile Number"><div><img className="error-img-show" src={ThisIsAnError} alt="" /></div></abbr>} */}

            {data.mobile && errors.mobile && <div className="number-login-err">Enter a valid number</div>}
          </div>

          <div className="service-checkbox">
            <input type="checkbox" readOnly onClick={() => updateCheck(!check)} checked={check} />
            <label >I authorize MoneyWide to contact me via call, SMS and Email. I accept MoneyWide’s <a style={{ fontWeight: "600" }} className='send-lnk' href={'/terms-and-conditions'}>Terms and Conditions.</a></label>
          </div>

          {/* ----------------------------Submit button----------------------------------------- */}
          <button type='submit' id="service-submit"  disabled={!check || data.mobile.length!==10 || errors.mobile} className={(!errors.mobile && data.mobile.length === 10 && check) ? 'footer-btn footer-btn-activate Tmargin' : "footer-btn Tmargin"} >Send OTP</button>
        </form>
      </div>
    </>
  )
}

export default ServiceLogin
