import React from 'react'
import Slider from 'react-slick';
import { SlickPrev } from '../MoneyWideAnnounceMent/SlickPrev';
import { SlickNext } from '../MoneyWideAnnounceMent/SlickNext';

const MobileFullPageSlider = ({ givenData }) => {
    var sliderblog = {
        infinite: false,
        autoplay: true,
        cssEase: 'ease-in-out',
        speed: 1000,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SlickNext />,
        prevArrow: <SlickPrev />,

        responsive: [
            {
                breakpoint: 750,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
        ]
    };
    return (
        <div>
            <div className="box-sh" style={{marginTop:"50px"}}>
                <div className="eligibility-criteria-box">
                    <div className="about-heading" style={{textAlign:"left"}}>Our <span>Tenets</span></div>
                    <div className="mobile-about-slider">
                        <Slider {...sliderblog} aria-live="polite">
                            {givenData.map((row, ind) => {
                                return <div key={ind} className='about-slide'>
                                    <span className="main-cont">{row.heading}</span>
                                    <p>{row.context}</p>
                                    <ul className='slider-list'>
                                        {row.list.map((item, index) => {
                                            return <li key={index} className="full-slider-list">{item}</li>
                                        })}
                                    </ul>
                                    {row.url && <a className='slider-link' href={row.url}>Apply Now</a>}
                                    <img className='about-slider-img' src={row.img} alt="na" />
                                </div>
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileFullPageSlider;