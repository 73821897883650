import React from "react";
import ImageSlider from "./ImageSlider";
import './Slider1.css';
import Images from "./Images";
import mob from "../assests/Mobile.webp";
function Slider() {
    return(
        <div className="slider-main" >
            <div className="slider-left">
                <div className="slider-frame">
                    <img src={mob} alt="img" />
                </div>
                <div className="slider-img">
                    <ImageSlider images={Images}/>
                </div>
            </div>
            <div className="slider-right">
                <div className="slide-right-first">
                    <h2 style={{color:"white"}}>How does MoneyWide work?</h2>
                    <p>MoneyWide offers instant loans in 4 easy steps:</p>
                </div>
                <div className="slide-right-second">
                    <ul>
                        <li><span style={{display:"flow-root"}}>Submit loan application with necessary documents.</span></li>
                        <li><span style={{display:"flow-root"}}>Get instant in-principle approval based on the details.</span></li>
                        <li><span style={{display:"flow-root"}}>Complete video KYC and eSign process.</span></li>
                        <li><span style={{display:"flow-root"}}>Successful loan disbursement in your bank account.</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Slider;