import React, { useEffect } from 'react'
import "./Eligibility.css";
import EligibilityCriteria from '../PersonalLoan/images/eligibility-criteria.webp';
import StepSlider_1 from './StepSlider_1';
import FAQSection from '../FAQSection/FAQSection';
import greenticknew from '../../Icons/greenticknew.svg';
import EligibilityCalculator from '../Eligibility_Calculator/EligibilityCalculator';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import MobileFooter from '../Footer/MobileFooter';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';
const PLEligibilityCalculator = () => {
    var loan_type_id_page = 56;
    const { formStates, PLLoader, mwpages } = useSelector(state => state);
    const dispatch = useDispatch()
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY <= 200 || window.scrollY >= 2558) {
            sety(false);
        }
        else {
            sety(true);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])
    return (
        <>
            {/* <NewLoginForm pageType={loan_type_id_page} mapData={data} /> */}
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi />
            <EligibilityCalculator />
            {y && <RightSidePopUp />}
            <div className="eligib-criteria">
                <div className="eligib-head-main" style={{ marginTop: "0px" }}>
                    <div className="elgib-heading">
                        <h3>MoneyWide Personal Loan <b>Eligibility Calculator</b></h3>
                        <p>MoneyWide Personal Loan Eligibility Calculator is an online tool that helps you evaluate the credit limit, interest rate and repayment tenure against a loan. The eligibility for a loan depends on various factors like income, employment type, age, work experience etc. Before applying for a loan, it is important to know if your profile matches the requirements of a personal loan
                        </p>
                        <p><b>The general requirements for a MoneyWide personal loan are stated below:</b></p>
                    </div>
                    <div className="eligib-age-factor">
                        <div className="age-fact-card">
                            <span>Age</span>
                            <p>21 years to 55 years</p>
                        </div>
                        <div className="age-fact-card">
                            <span>Work experience</span>
                            <p>Minimum 2 years</p>
                        </div>
                        <div className="age-fact-card">
                            <span>Minimum net monthly income</span>
                            <p>₹ 25,000</p>
                        </div>
                        <div className="age-fact-card">
                            <span>Credit score</span>
                            <p>650 or above</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="eligibility-criteria">
                <div className="ploan-container">
                    <div className="box-sh">
                        <div className="eligibility-criteria-box" style={{ paddingBottom: "20px", paddingTop: "24px" }}>
                            <h2 className="heing lefting bot-6 add-btom-line" style={{ marginBottom: "20px" }}>How to Use MoneyWide Personal Loan <b>Eligibility Calculator?</b></h2>
                            <div className="ploan-row-criteria elgib-row-criteria">
                                <div className="colm-2">
                                    <div className="steps-to-loan">
                                        <p>To calculate the eligibility for a Personal Loan, you need to enter the following details:</p>
                                    </div>
                                    <div className="mlc-pl-list mlc-pl-mrgin-top">
                                        <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                            <img src={greenticknew} alt="tick" />
                                            <span>Date of Birth</span>
                                        </div>
                                        <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                            <img src={greenticknew} alt="tick" />
                                            <span>Net Monthly Income</span>
                                        </div>
                                        <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                            <img src={greenticknew} alt="tick" />
                                            <span>Current EMI's</span>
                                        </div>
                                    </div>
                                    <div className="steps-to-loan">
                                        <p>The calculator will automatically calculate the maximum amount you are eligible for a Personal Loan.</p>
                                    </div>
                                </div>
                                <div className="colm-1">
                                    <img src={EligibilityCriteria} alt="Not Found" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>

            </section>
            {/* <section className='FAQs'>
                <FAQSection />
            </section> */}
            {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                <FAQSectionChk data={mwpages.faqdata} />
            </section> : ""}
        </>
    )
}

export default PLEligibilityCalculator;