import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import './LoanDetails.css'
import { getLoginData } from './MyAccountAPIs';

const LoanDetails = ({ data }) => {
  const [userName, setUserName] = useState("")
  const [leadSource, setLeadsource] = useState(false)

  useEffect(() => {
    let payLoad = getLoginData("NO")
    if (payLoad && payLoad?.userName && (payLoad?.userName !== undefined || payLoad?.userName !== "")) {
      setUserName(payLoad.userName)
      if (payLoad?.lead_source_flag == 1){
        setLeadsource(true)
      }
    }
  }, [])
  return (
    <div>
      <div className="loan-details-heading">{(leadSource) ? " Account Details" : "Loan Account Details"}</div>
      <div className="loan-details-box myAccountBoxContent">
        <div className="userData">
          <div className="contentHead">{(leadSource) ? "Credit Facility Type" : "Loan Type"}</div>
          <div className="contentData">{data.programName ? data.programName : "-"}</div>
        </div>
        <div className="userData">
          <div className="contentHead">Name</div>
          <div className="contentData">{userName ? userName : "-"}</div>
        </div>
        <div className="userData">
          <div className="contentHead">Outstanding Amount</div>
          <div className="contentData">{data.summary.totalOutstanding ? "Rs. " + data.summary.totalOutstanding.toLocaleString("en-IN") : "-"}</div>
        </div>
        <div className="userData">
          <div className="contentHead">Tenure</div>
          <div className="contentData">{data.numberOfRepayments ? data.numberOfRepayments + " Months" : "-"}</div>
        </div>
        <div className="userData">
          <div className="contentHead">Lender Name</div>
          <div className="contentData">{data.lenderName ? data.lenderName : "-"}</div>
        </div>
        <div className="userData">
          <div className="contentHead">{(leadSource) ? "Acc. Num." : "Loan Acc. Num."}</div>
          <div className="contentData">{data.accountNo ? data.accountNo : "-"}</div>
        </div>
        <div className="userData">
          <div className="contentHead">{(leadSource) ? "Healthcare Credit Amount." : "Loan Amount"}</div>
          <div className="contentData">{data.approvedPrincipal ? "Rs. " + data.approvedPrincipal.toLocaleString("en-IN") : "-"}</div>
        </div>
        <div className="userData">
          <div className="contentHead">Interest Rate</div>
          <div className="contentData">{data.annualInterestRate ? data.annualInterestRate + "%" : "-"}</div>
        </div>
      </div>
    </div>
  )
}

export default LoanDetails;