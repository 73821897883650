import React, { useEffect, useState } from 'react'
import "./TermsAndCondition.css";
import "../PrivacyPolicy/Privacy.css";
import translatelang from "../../Icons/translatelang.svg"
import "../footermorelinkspages.css";
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../EverCalledFunctions/EverCalledApis';
import { GenerateToken } from '../../Redux/action';
import { prodbase_url, source, verification_source, verified_by, verifier_name } from '../Secret_Credentials/Secret_Credential';
import axios from 'axios';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import { apiInstance } from '../Config/APIConfig';

const TermsAndCondition = () => {
  const [y, sety] = React.useState(true);
  const dispatch = useDispatch()
  const { App_status, token } = useSelector(state => state);
  const [functionValues, setFunctionValues] = useState([])
  const [value, setValue] = useState([]);
  const [getid, setGetid] = useState(1);
  const [loader, setLoader] = useState(true);

  window.addEventListener("scroll", (event) => {
    if (window.scrollY >= 2775) {
      sety(false);
    }
    else {
      sety(true);
    }
  });

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, [])

  const masterApiLang = async () => {
    let decMaster = await apiInstance
      .post(
        `master`,
        {
          source,
          api_type: "language",
          verification_source: verification_source,
          verified_by: verified_by,
          verifier_name: "system"
        },
        {
          token
        }
      )
    // .then((res) => {
    if ((decMaster.status == 1) && (decMaster.status_code == 200)) {
      setFunctionValues(decMaster.data);
    }
    // })
  }


  useEffect(() => {
    if (token !== "") {
      masterApiLang();
    }
  }, [token])

  const webTermsPrivacy = async () => {
    let decWebTerms = await apiInstance.post(`web/terms-privacy`, {
      source,
      "type_terms_privacy": "2",
      "language": getid.toString(),
      verification_source,
      verified_by,
      verifier_name
    }, {
      token
    })
    // .then((res) => {
      setLoader(false)
      if ((decWebTerms.status == 1) && (decWebTerms.status_code == 200)) {
        setValue(decWebTerms.data)
      }
    // })
  }

  useEffect(() => {
    if (token !== "") {
      webTermsPrivacy();
    }
  }, [token, getid])

  useEffect(() => {
    if (value.length !== 0) {
      const container = document.getElementById('content-container');
      container.innerHTML = value[0].content;
    }
  }, [value])

  const handleSelect = (event) => {
    // console.log(event.id);
    const selectedRow = JSON.parse(event.target.value);
    setGetid(selectedRow.id)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      {y && !App_status && <RightSidePopUp />}
      {/* <div className={App_status ? 'policymain-container-head-app policymain-container-head' : 'policymain-container-head'}><h2 className='sachhead'>Terms and Conditions</h2></div> */}
      {/* <div className='sachmain'>
     <p>Thank you for visiting <a href={ !App_status && "/"} className={ !App_status && "gtm_interlinking bold"}>moneywide.com</a> and/or its associated mobile apps (the  “Website”),  owned and managed by MLC Finotech Private Limited (hereinafter referred to as “Company" or “MoneyWide”).</p>
     <p>This document (referred to as “Agreement”) is published in terms of Rule 3 of the Information Technology (Intermediaries guidelines) 2011, that provides for the due diligence  to  be exercised  for the  access or usage of this Website.</p>
     <p>By continuing to use this website, you: </p>
     <ul className='sachterm_1'>
        <li className='sachterm_2'>agree to be bound by the Terms and Conditions of Use described below</li>
        <li className='sachterm_1 sachterm_2'>confirm that you are an Indian resident national holding valid Indian citizenship and that you are neither a foreign national nor a Non-Resident Indian.</li>
        <li className='sachterm_1 sachterm_2'>that you have read the Terms and Conditions of Use contained in this document carefully and have accepted the same willfully and without any threat, pressure or duress.</li>
        <li className=' sachterm_1 sachterm_2'>represent and warrant that you are legally allowed to enter into these Terms and Conditions of Use and to use the Website in accordance with these Terms and Conditions of Use. </li>
        <li className=' sachterm_1 sachterm_2'>agree and acknowledge that MoneyWide may change the Terms and Conditions of Use from time to time without notice to you. </li>
        <li className=' sachterm_1 sachterm_2'>agree and acknowledge that your continued access or use of the Website after any modifications have become effective shall be deemed your irrevocable acceptance of the Terms and Conditions of Use, as modified.</li>
     </ul>
     <p>IN CASE YOU DO NOT WISH TO BE BOUND BY THESE TERMS AND CONDITIONS OF USE, YOU MAY CHOOSE NOT TO ACCESS OR USE THE WEBSITE. </p>
     <b className='sachinb'>Privacy Policy</b>
     <p>We respect your privacy and it is important to us. Our privacy policy is available as <a href={ !App_status && "/privacy-policy"} className={ !App_status && "gtm_interlinking bold"}>Privacy Policy</a>  page to this Terms and Conditions and explains how we deal with and treat your information including personal information. We will not share or sell any of your information with any third party, except as specifically described in our privacy policy.</p>
     <p>Any personal information, documents and credit data and/or technical and/or business and/ or commercial and/ or financial data provided by the user can be used by the Company and its agents without any obligation to the user and be shared with any regulatory or statutory body on their request. The Company is not liable to delete any such information from its servers or records.</p>
     <b className='sachinb'>Information Sharing and Social Features</b>
     <p>You may have the opportunity to share content or information, including, but not limited to, your name, email address, or other information that you provide via the Website, with other users of the Website. By sharing information or content on the Website, you expressly agree to all of the following:</p>
     <ul className='sachterm_1'>
     <li className='sachterm_2'>You agree that you shall be solely responsible for all content you post on the Website and the Company shall not be responsible for any content you make available on or through the Website. At Company`s sole discretion, such content may be included in the Service and ancillary services (in whole or in part or in a modified form).</li>
     <li className='sachterm_1 sachterm_2'>You agree that you are fully responsible for the content you submit. You are prohibited from posting or transmitting to or from this Website: (i) any unlawful, threatening, libelous, defamatory, obscene, pornographic, or other material or content that would violate rights of publicity and/or privacy or that would violate any law; (ii) any commercial material or content (including, but not limited to, solicitation of funds, advertising, or marketing of any good or services); and (iii) any material or content that infringes, misappropriates or violates any copyright, trademark, patent right or other proprietary right of any third party.</li>
     <li className='sachterm_1 sachterm_2'>You agree that you shall be solely liable for any damages resulting from any violation of the foregoing restrictions, or any other harm resulting from your posting of content to this Website.</li>
     <li className='sachterm_1 sachterm_2'>With respect to the content you submit or make available on the Website, you grant MoneyWide a perpetual, irrevocable, non-terminable, worldwide, royalty-free and non-exclusive license to use, copy, distribute, publicly display, modify, create derivative works, and sublicense such materials or any part of such content.</li>
     <li className='sachterm_1 sachterm_2'>You understand and acknowledge that deleted content or information may continue to exist on servers or in backup copies that we maintain.</li>
     <li className='sachterm_1 sachterm_2'>You shall not post content or take any action on the Website that may constitute libel or slander, infringes or violates someone else’s rights, that is protected by any copyright or trademark, or otherwise violates the law.</li>
     <li className='sachterm_1 sachterm_2'>Content and information that you share will be available to other users of our Website. You understand that such third parties may access, use, store and transfer such content and information and to associate it with you.</li>
     <li className='sachterm_1 sachterm_2'>You agree that you will not use the Website for commercial purposes.</li>
     <li className='sachterm_1 sachterm_2'>We may remove any content or information that you share on the Website for any reason.</li>
     </ul>
     <p>You agree that you will not use any device, software or routine to interfere or attempt to interfere with the proper working of the Website or any transaction being conducted on our Website.</p>
     <b className='sachinb'>Registrations and Loan Applications</b>
     <p>All loans are granted at sole discretion of MoneyWide and/or its lending partners and all or any offers  may  be  withdrawn or modified  at any time with or without notice. You may register and/or apply for a loan for yourself through the Website. We reserve the right to decline your application for a loan. When registering or applying for a loan, you agree to provide current, complete, and accurate information about yourself. If any information you provide is untrue, inaccurate, not current, or incomplete, MoneyWide shall have the right to, inter  alia,  terminate  your  loan and/or loan application and to prohibit future use of the Website.</p>
     <p>While applying for a loan or service or facility, MoneyWide  may  seek  consent  to  access  to  certain functionalities on your device/ phone such as but not limited to access to camera, microphone, location, other device features during the underwriting, onboarding and KYC process specifying the purpose for which the consent is being sought. Such consent(s) when sought and granted by you, will be used specifically for  the purpose and the duration for which it/ they may have been sought. You have the option to give or deny consent and in cases where such consent is required by us to process your application, we may not be able to process your application in case you choose to deny the consent.</p>
     <b className='sachinb'>Changes and Modifications</b>
     <p>MoneyWide reserves the exclusive right to modify or discontinue, temporarily or permanently, the use of the Website, or any section thereof, at our sole discretion, to one or more or all users including or excluding you at any time and for any period. </p>
     <b className='sachinb'>Links</b>
     <p>This Website may contain links to websites maintained by affiliated or non-affiliated third parties. By accessing such links through the Website, you agree to abide by and accept the terms and conditions of use, policies including privacy policies, restrictions, security practices and risks of those links which may be different from those of this Website. MoneyWide hereby disclaims any liability for or any claims in respect of, any information or transaction or products and services offered by such websites and availed by you.</p>
     <b className='sachinb'>No Warranty</b>
     <p>Information carried at website is not and should not be construed as an offer or solicitation or invitation to borrow or lend. By submitting your query, you authorize us to share your information with credit bureau and / or lender(s) and consent to pull your credit information report and call/ message you regarding your query. </p>
     <p>The Company is, by no means, assuring as to the correctness of information, FAQ’s, graphics, images, text, and/ or various tools and calculators, including their result, displayed at this website or sent to the user by email or otherwise (together called “Information”). These are purely for indicative purposes and cannot be relied upon, either individually or together with any other information, by the user in any manner whatsoever. </p>
     <p>The Company is an independent service provider and is not related to the government or any regulator or any credit information bureau in any way.  The Company is not guaranteeing or assuring a particular or favorable outcome. The Company is not providing any legal or accounting or tax advice. The Company shall have no liability whatsoever to the user for any direct, indirect or consequential losses, damages, costs, charges, expenses or otherwise, which the user or any third party may incur arising out of the use of the Information.</p>
     <p>By accessing the Information, you expressly and irrevocably agree that your use of the Website, together with its ancillary services and all information, products, other services and content (including that of third parties) included in or accessible from the Website is at your sole risk. All services are provided on an “as is” and “as available” basis.</p>
     <p>The Company expressly disclaim all warranties of any kind as to the services, ancillary services and all Information and content included in or accessible from the services, whether express or implied, including but not limited to the implied warranties of accuracy, reliability or completeness, usability, fitness for a particular purpose and non-infringement.</p>
     <p>Company and its service providers, affiliates, business partners make no warranty that (i) the services will meet your requirements, (ii) the services will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the services will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by you through the services will meet your expectations, and (v) any errors in the technology will be corrected. MoneyWide shall not be responsible and shall bear no liability in case of failure or delay of delivering the products or services including any damage or loss caused to you due to such delay. No deliveries of the products/services shall be made outside the territorial boundaries of India.</p>
     <p>Prices for products available on our Website are incorporated into this Agreement by reference. All prices are in Indian rupees only and these may change from time to time in accordance with the guidelines or other terms and conditions applicable to them and/ or in sole discretion of their respective merchants or the Company (where the Company itself is the merchant), as the case may be. By initiating a transaction, you are entering into a legally binding and enforceable contract with the merchant to purchase the products and/ or avail services using as may be permitted by applicable laws.</p>
     <p>The Company does not charge any registration fee or membership fee or usage fee or processing fee on its own account, except when it is itself the merchant. However, the Company reserves the absolute right to do so in future in its sole discretion, provided that such change shall be implemented prospectively and will be intimated to the users on the Website and such charges shall be payable in Indian Rupees only. </p>
     <b className='sachinb'>Limitation of Liability</b>
     <p>You agree that all access and use of the Website and its contents is at your own risk. In no event shall we be held liable for any loss, expenses or damages, including but not limited to direct or indirect, incidental, or consequential ones arising from the use of or in connection with or due to inability to use the Website or failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure.</p>
     <p>You expressly understand and agree that the Company (including its subsidiaries, affiliates, directors, officers, employees, representatives and providers) shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to damages for loss of profits, opportunity, goodwill, use, data or other intangible losses, even if Company has been advised of the possibility of such damages, resulting from (i) any failure or delay (including without limitation the use of or inability to use any component of the Website), or (ii) any use of the Website or content, or (iii) the performance or non-performance by us or any provider, even if we have been advised of the possibility of damages to such parties or any other party, or (b) any damages to or viruses that may infect your computer equipment or other property as the result of your access to the Website or your downloading of any content from the Website.</p>
     <p>The Company has no control over the third party websites which would be provided to you through its Website. You acknowledge and agree that under no circumstance shall the Company be liable for your using the services offered or provided by any third party service provider.</p>
     <p>Notwithstanding the above, if the Company is found liable for any proven and actual loss or damage which arises out of or in any way connected with any of the occurrences described above, then you agree that the liability of Company shall be restricted to, in the aggregate, any fees paid by you to the Company in connection with such transaction(s)/ services on this Website, if applicable.</p>
     <b className='sachinb'>Applicable Law</b>
     <p>These Terms and Conditions of Use shall be governed in all respects by the laws of India, without regard to its provisions relating to conflict of laws. Courts at Gurugram, Haryana, shall have exclusive jurisdiction over any and all matters relating to the provision of our products and services. The failure of MoneyWide to exercise or enforce any right or provision of the Terms and Conditions of Use shall not constitute a waiver of such right or provision. If any provision of the Terms and Conditions of Use is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that they shall endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of the Terms and Conditions of Use remain in full force and effect. </p>
     <b className='sachinb'>Notice</b>
     <p>The effective date of this Policy, as stated below, indicates the last time this Policy was revised or materially changed.</p>
     <b className='sachinb'>Effective Date</b>
     <p style={{fontFamily:"poppinssemibold"}}><strong>29th October 2022</strong></p>
     </div> */}
      <div>
        {
          loader ? <CKYCWaiting /> : <div className="privacy-lang">
            <div className="translate-select translate-area">
              <img src={translatelang} alt='lang' />
              <select onChange={handleSelect}>
                {functionValues && functionValues.length !== 0 && functionValues.map((row, ind) => {
                  return <option key={row.id} value={JSON.stringify(row)}>
                    {row.value}
                  </option>
                })}
              </select>
            </div>
            <div className='sachmain trans-main-lang' id='content-container'></div>
          </div>
        }
      </div>
    </>
  )
}

export default TermsAndCondition;