import React from 'react'
// import Lottie from "react-lottie-player";
// import lottieJson from "../../Components/assests/lootie-files/successful.json";
import pendingIcon from '../../Icons/pendingIcon.svg'
import closeOption from '../../Icons/closeOption.svg'
import './LoanDetails.css'
import { useDispatch, useSelector } from 'react-redux';
import { updateMakePayment } from '../../Redux/action';

const OverDueSuccess = ({ name, baseData, isMakePayment }) => {
    const dispatch = useDispatch()
    const { makePayment } = useSelector(state => state)
    return (
        <div className={"overdue-payment-box" + (isMakePayment ? " make-pay-overdue" : "")}>
            {<div className="myc-back-btn" style={isMakePayment? {}:{right:"20px"}}>
                {isMakePayment ?
                    <img width={"130%"} src={closeOption} onClick={() => dispatch(updateMakePayment({ ...makePayment, show: false }))} alt="close" /> :
                    <a href="/myaccount/profile">
                        <img src={closeOption} alt="close" />
                    </a>}
            </div>}
            <div className="thank-popup-lottie manage-myaccount-popup">
                <img src={pendingIcon} alt="NA" />
            </div>
            <div className="od-main">
                <div className="od-head-blue">
                    <span>Dear {name}</span>
                </div>
                <div className='od-content'>
                    <p>Your overdue payment for <b>{baseData.program}</b> (<b>{baseData.Id}</b>) has been processed. Please wait for the updated payment status to reflect in  your account. In case the payment is unsuccessful, please try again after some time.</p>
                </div>
            </div>
        </div>
    )
}

export default OverDueSuccess