import React, { useEffect, useState } from 'react';
import '../Form.css';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep, updateStepBar, updatePopUp, updateFormState, updateMasterState, updateSuggestion_DropDown, GenerateToken } from '../../../../Redux/action';
import pin from '../../../../Icons/pin.svg'
import ThisIsAnError from '../../../../Icons/error.svg'
import home from '../../../../Icons/home.svg'
import contact from '../../../../Icons/contact.svg'
import DropDownIcon from '../../../../Icons/DropDownIcon.svg'
import remove_btn from '../../../../Icons/remove_btn.svg';
import upload_btn from '../../../../Icons/upload_btn.svg';
import OVDPopUp from '../../../Popups/OVDPopUp/OVDPopUp';
import FormHead from '../FormHead';
import { source, client_id, client_key, base_url, prodbase_url, verification_source, verified_by, verifier_name, react_base_url, doc_upload_url } from '../../../Secret_Credentials/Secret_Credential';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Base64 from "base-64";
import Lottie from 'react-lottie-player';
import ctaAnimation from '../../../assests/lootie-files/continue.json';
import { encryptQueryIdStepId, gettingerror, RelocateUTMCheck } from '../../../EverCalledFunctions/EverCalledFunctions';
import { getCityName, getMasterData, GetPersonalDetails, getPinOption, getToken, OVDOCRAPI, WebAddressSubmit } from '../../../EverCalledFunctions/EverCalledApis';
import NewErrorCase from './NewErrorCase';
import { apiInstance } from '../../../Config/APIConfig';

const CurrentAddress = () => {
  const [OvdData, setOvdData] = useState([])
  const [selected, setSelected] = useState(-1)
  const [pincodeData, setPincodeData] = useState({
    show: false,
    data: []
  });
  const [cityFromPin, setCityFromPin] = useState({
    city: "",
    city_id: ""
  })
  const [firstSubmit, setFirstSubmit] = useState(false)
  const [addressProof, setAddressProof] = useState("")
  const [resp, setResp] = useState(false)
  const [Submit, setSubmit] = useState(false)
  const [extraAddress, setExtraAddress] = useState("")
  const [extraDocs, setExtraDocs] = useState({
    show: false,
    data: []
  })
  const [rocket, setRocket] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Arr = [
    "*Should be in validity period",
    "*Latest 3 Months Statement",
    "*Not more than 2 months old"
  ]
  const { step_bar, PopUpState, formStates, master_state, credentialState, mw_application_id, resumeJourney, suggestionDropDown, token } = useSelector(state => state)
  const { register, handleSubmit, reset, setValue, formState: { errors }, watch, clearErrors, setError } = useForm({
    defaultValues: {
      houseNo: "",
      area: "",
      landmark: "",
      pin: "",
      addressProof: "",
      frontphoto: "",
      backphoto: "",
      proofOfAddress: ""
    },
    mode: "all"
  })
  const data = watch();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, [])

  const getCityNameHere = async (pin) => {
    const APIDATA = await getCityName(pin, token);
    setCityFromPin({ ...cityFromPin, city: APIDATA.city, city_id: APIDATA.cityId })
  }

  const GetPreData = async () => {
    const APIDATA = await GetPersonalDetails(credentialState.cust_id, token)
    if (APIDATA.status_code === "200" && APIDATA.status === "1") {
      setValue('pin', APIDATA.data.pincode)
      getCityNameHere(APIDATA.data.pincode)
    }
  }

  const getPopUpData = async () => {
    const APIDATA = await getMasterData("ovd_proof", token)
    setOvdData(APIDATA)
  }

  useEffect(() => {
    dispatch(updateStepBar({ ...step_bar, profile: true, offer: true, verification: true }))
  }, [])

  useEffect(() => {
    if (token !== "") {
      GetPreData();
      setTimeout(() => {
        getPopUpData();
      }, 2000);
    }
  }, [token])

  const submitAddress = async (item) => {
    const payLoad = {
      "curr_address_house_no": data.houseNo,
      "curr_address_area_sector": data.area,
      "curr_address_landmark": data.landmark,
      "curr_address_ovd_proof": item,
      "mw_application_id": mw_application_id,
      "current_pincode": data.pin,
      "current_address_same_as": 0,
    }

    const APIDATA = await WebAddressSubmit(payLoad, "")

    if ((APIDATA.status == 1) && (APIDATA.status_code == 200)) {
      gettingerror('submit current address web',mw_application_id)
      if (data.proofOfAddress === "") {
        let decOffer = await apiInstance.post(`offer-eligibility`, {
          "customer_id": credentialState.cust_id,
          "lead_id": mw_application_id,
          "offer_type": 1,
          "verifier_name": verifier_name,
          "verification_source": verification_source,
          "verified_by": verified_by,
          "product_id": 56,
          "stage_name": "Further Details",
          "level_id": 1,
          "source": source,
        }, {
          token
        })
        // .then((res) => {

        // })
      }
      if (APIDATA.data.next_step.toLowerCase() === "vkyc") {
        gettingerror('get vkyc at current web',mw_application_id)
        window.sessionStorage.setItem("cust_id", credentialState.cust_id);
        const qry = encryptQueryIdStepId(mw_application_id, 2);
        RelocateUTMCheck(`${react_base_url}vkyc/?fid=${qry}`)
      }
      else if (APIDATA.data.next_step.toLowerCase() === "selfie") {
        gettingerror('get selfie at current web',mw_application_id)
        window.sessionStorage.setItem("cust_id", credentialState.cust_id);
        let qry = Base64.encode(`${mw_application_id}`);
        qry = Base64.encode(qry);
        RelocateUTMCheck(`${react_base_url}vkyc/?fid=${qry}`);
      }
      else {

        let qry = Base64.encode(mw_application_id)
        qry = Base64.encode(qry)
        RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=16`);
      }
    }
    else {
      dispatch(updateFormState({ ...formStates, error: true }))
      setResp(false)
    }



  }

  const CallExtraAPI = async () => {
    if (extraDocs.data.length === 0) {
      const APIResponse = await getMasterData("other_address_proof", token)
      setExtraDocs({ ...extraAddress, data: APIResponse })
    }
  }


  useEffect(() => {
    if (master_state.ovd) {
      setValue('addressProof', master_state.ovd.id)
      setAddressProof(master_state.ovd.value)
      setValue('frontphoto', "")
      setValue('backphoto', "")
    }
    if (master_state.extra) {
      setValue('proofOfAddress', master_state.extra.id)
      setExtraAddress(master_state.extra.value)
      setValue('backphoto', "")
    }


  }, [master_state])

  useEffect(() => {

    if (data.addressProof === 10004) {
      CallExtraAPI()
    }

  }, [data.addressProof])

  const GetPinData = async (pin) => {
    const APIDATA = await getPinOption(pin, token);
    if (APIDATA.length !== 0) {
      await setPincodeData({ ...pincodeData, show: true, data: APIDATA })
      dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: true }))
    } else {
      setPincodeData({ ...pincodeData, show: false, data: [] })
    }
  }

  useEffect(() => {
    if (data.pin) {
      if (data.pin.length === 6) {
        getCityNameHere(data.pin)
        setPincodeData({ ...pincodeData, show: false, data: [] })
      }
      else if (data.pin.length < 3) {
        setPincodeData({ ...pincodeData, show: false, data: [] })
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
      }
      else if (data.pin.length >= 3 && data.pin.length < 6) {
        GetPinData(data.pin)
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
      }
      else {
        setPincodeData({ ...pincodeData, show: false, data: [] })
      }
    } else {
      setPincodeData({ ...pincodeData, show: false, data: [] })
      setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
    }

  }, [data.pin])


  {/* -------------------------------Submit Function Function------------------------------------------------- */ }
  const onSubmit = async (result) => {
    setFirstSubmit(true);
    setResp(true)

    if (data.addressProof && data.addressProof !== 10004) {
      const docId = {
        item1: result.addressProof === 10 ? 10 : result.addressProof === 12 ? 12 : 13,
        item2: result.addressProof === 10 ? 11 : result.addressProof === 12 ? 21 : 14
      }

      let fileName1 = result.frontphoto[0].name;
      fileName1 = fileName1.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
      let fileName2 = result.backphoto[0].name;
      fileName2 = fileName2.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");

      Object.defineProperty(result.frontphoto[0], 'name', {
        writable: true,
        value: fileName1
      });

      Object.defineProperty(result.backphoto[0], 'name', {
        writable: true,
        value: fileName2
      });


      const formData = new FormData();
      formData.append('consent', "Y");
      formData.append('customer_id', credentialState.cust_id);
      formData.append('product_id', 56);
      formData.append('partner_id', 1);
      formData.append('lead_id', mw_application_id);
      formData.append('level_id', 1);
      formData.append('kyc_validation_type_id', result.addressProof);
      formData.append('document_id1', docId.item1);
      formData.append('file_name1[]', result.frontphoto[0]);
      formData.append('document_id2', docId.item2);
      formData.append('file_name2[]', result.backphoto[0]);
      formData.append('source', source);
      formData.append('verified_by', verified_by);
      formData.append('verifier_name', verifier_name);
      formData.append('verification_source', verification_source);


      const APIDATA = await OVDOCRAPI(formData, token);

      if (APIDATA.data.is_doc_valid === "OK") {
        submitAddress(result.addressProof);
        setResp(false)
      } else {
        const updateVal = { show: true }
        dispatch(updatePopUp({ ...PopUpState, InvalidDocument: updateVal }))
        setValue('backphoto', "")
        setValue('frontphoto', "")
        setResp(false)
      }
    } else {


      const formData = new FormData();
      formData.append('consent', "Y");
      formData.append('customer_id', credentialState.cust_id);
      formData.append('product_id', 56);
      formData.append('partner_id', 1);
      formData.append('lead_id', mw_application_id);
      formData.append('level_id', 1);
      formData.append('kyc_validation_type_id', result.proofOfAddress);
      formData.append('document_id1', result.proofOfAddress);
      formData.append('file_name1[]', result.backphoto[0]);
      formData.append('source', source);
      formData.append('verified_by', verified_by);
      formData.append('verifier_name', verifier_name);
      formData.append('verification_source', verification_source);
      const APIDATA = await OVDOCRAPI(formData, token);

      if (APIDATA.status === "1") {
        submitAddress(result.proofOfAddress);
        setResp(false)
      } else {
        const updateVal = { show: true }
        dispatch(updatePopUp({ ...PopUpState, InvalidDocument: updateVal }))
        setValue('backphoto', "")
        setValue('frontphoto', "")
        setResp(false)
      }

    }

  }





  const handleOVDPOP = () => {

    dispatch(updateMasterState({ ...master_state, values: OvdData, status: true, heading: "Select any one of the below residence document proof", ovd: null }))
  }

  const handleExtra = () => {
    console.log(extraDocs.data);
    dispatch(updateMasterState({ ...master_state, values: extraDocs.data, status: true, heading: "Select any one of the below residence document proof", extra: null }))
  }


  const CloseOVDPopUp = () => {
    dispatch(updateMasterState({ ...master_state, status: false }))
  }
  const handleArrows = (e) => {
    if (pincodeData.show) {
      setSelected(0)
      var key = e.keyCode;
      if (key != 40 && key != 38 && key != 13) {
        return;
      }
      if (key == 13) {
        e.stopPropagation()
        if (selected > -1 && selected < pincodeData.data.length) {
          setThisPin(pincodeData.data[selected])
          setSelected(-1)
        } else {
          setSelected(-1)
        }
      }
      else if (key == 38) {
        if (selected > 0) {
          setSelected(selected - 1)
        }
      }
      else if (key == 40) {
        if (selected < pincodeData.data.length - 1) {
          setSelected(selected + 1)
        }
      }
    }



  }
  const handleUpKeyNumber = (value, field, len) => {
    var regexp = /[^0-9]/g;
    if (value && value.match(regexp)) {
      setValue(field, value.replace(regexp, ""))
    }
  }

  const SpaceManage = (value, field) => {
    setValue(field, value.replace(/^\s+/, ''));
  }

  const setThisPin = (pin) => {
    setValue('pin', pin);
    setPincodeData({ ...pincodeData, show: false })
    clearErrors(['pin'])
    getCityNameHere(pin)
  }

  const Blured = (field, value) => {
    // var newVal = value.trimEnd();
    var newVal = value.replace(/\s+$/, '');
    setValue(field, newVal);
    if (newVal.length < 3) {
      setError(field)
    } else {
      clearErrors([field])
    }
  }

  useEffect(() => {
    if (data.houseNo && data.area && data.landmark && data.pin) {
      if (data.addressProof && data.addressProof !== 10004 && data.backphoto && data.frontphoto) {
        if (Object.keys(errors).length === 0) {
          setSubmit(true)
        } else {
          setSubmit(false)
        }
      } else if (data.addressProof && data.addressProof === 10004 && data.backphoto) {
        if (Object.keys(errors).length === 0) {
          setSubmit(true)
        } else {
          setSubmit(false)
        }
      } else {
        setSubmit(false)
      }
    } else {
      setSubmit(false)
    }
  }, [data])


  useEffect(() => {
    if (data.backphoto[0] && data.frontphoto[0]) {
      if (data.backphoto[0].name === data.frontphoto[0].name) {

      }
    }
  }, [data.backphoto], [data.frontphoto])

  const handleError = (e) => {
    window.location.reload();
  }


  return (<>
    {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}

    {PopUpState.OVDPopUp.show && <OVDPopUp />}
    <FormHead formName="Current Address" formSubHead={"Provide residential address details for KYC"} />
    <div className="tag-for-input">
      {/*------------------------------------------Current Address Field--------------------------------------- */}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>


        {/* -------------------------------------------House No Field----------------------------------------- */}
        <div className={"input-tag remove-bottom" + (errors.houseNo ? " error-border" : data.houseNo ? " add-border" : "")}>
          <img className="tag-icon" src={home} />
          <input disabled={resp} className="input-tag-box" type="text" placeholder='House No./ Flat No./ Building No.' {...register('houseNo', { required: true, minLength: 3 })} autoComplete='off' onKeyUp={() => SpaceManage(data.houseNo, "houseNo")} onBlur={() => Blured("houseNo", data.houseNo)} />
        </div>
        {errors.houseNo && <div className='err-text'>Please Enter at least 3 Characters.</div>}


        {/* -------------------------------------------Area Field----------------------------------------- */}
        <div className={"input-tag remove-bottom" + (errors.area ? " error-border" : data.area ? " add-border" : " ")}>
          <img className="tag-icon" src={pin} />
          <input disabled={resp} className="input-tag-box" type="text" placeholder='Area/Sector/Society' {...register('area', { required: true, minLength: 3 })} autoComplete='off' onKeyUp={() => SpaceManage(data.area, "area")} onBlur={() => Blured("area", data.area)} />
        </div>
        {errors.area && <div className='err-text'>Please Enter at least 3 Characters.</div>}


        {/* -------------------------------------------Landmark Field----------------------------------------- */}
        <div className={"input-tag remove-bottom" + (errors.landmark ? " error-border" : data.landmark ? " add-border" : " ")}>
          <img className="tag-icon" src={pin} />
          <input disabled={resp} className="input-tag-box" type="text" placeholder='Landmark' {...register('landmark', { required: true, minLength: 3 })} autoComplete='off' onKeyUp={() => SpaceManage(data.landmark, "landmark")} onBlur={() => Blured("landmark", data.landmark)} />
        </div>
        {errors.landmark && <div className='err-text'>Please Enter at least 3 Characters.</div>}


        {/* -------------------------------------------Pincode Field----------------------------------------- */}
        <div className={"input-tag remove-bottom" + (errors.pin ? " error-border" : data.pin ? " add-border" : "")} >
          <img className="tag-icon" src={pin} />
          <input disabled={resp} readOnly className="input-tag-box" type="tel" placeholder='Pincode' minLength={6} maxLength={6}
            {...register('pin', { required: true, minLength: 6, maxLength: 6 })}
            onKeyDown={(e) => handleArrows(e)}
            onKeyUp={() => handleUpKeyNumber(data.pin, "pin", 6)}
          />
          {suggestionDropDown.show && pincodeData.show && pincodeData.data.length !== 0 &&
            <div className="dropDownBox">
              {pincodeData.data.map((row, ind) => {
                return <div className={'dropDownValue' + (selected === ind ? " select" : "")} key={ind} onClick={() => setThisPin(row)}>{row}</div>
              })}
            </div>}
        </div>
        {errors.pin && <div className="err-text">{cityFromPin.city}</div>}
        {!errors.pin && data.pin && <div className='api-text'>{cityFromPin.city}</div>}


        {/* -------------------------------------------Address Proof----------------------------------------- */}
        <div className={"input-tag" + ((errors.addressProof) ? " error-border" : data.addressProof ? " add-border" : "")}>
          <img className="tag-icon" src={contact} />
          <input disabled={resp} className="input-tag-box pointer-cur" type="text" placeholder='Proof of Address'
            value={addressProof} onClick={() => handleOVDPOP()} readOnly />
          <img className="small-the-icon pointer-cur" src={DropDownIcon} onFocus={() => handleOVDPOP()} onClick={() => handleOVDPOP()} onBlur={() => CloseOVDPopUp()} alt="" />

        </div>



        {/* ----------------------Extra Input Field-------------------- */}


        {data.addressProof && data.addressProof === 10004 && <>
          <p style={{ marginBottom: "-30px", marginTop: "-15px", fontSize: "12px", lineHeight: "140%", fontWeight: "600" }}>*Expect few hours delay in processing with below options.</p>
          <div className={"input-tag" + ((errors.proofOfAddress) ? " error-border" : data.proofOfAddress ? " add-border" : "")} >
            <img className="tag-icon" src={contact} />
            <input disabled={resp} value={extraAddress} className="input-tag-box pointer-cur" type="text" placeholder='Proof of Address' readOnly onClick={() => handleExtra()}
            />
            <img className="small-the-icon pointer-cur" src={DropDownIcon} onClick={() => handleExtra()} alt="" />

          </div>
        </>}

        {/* ----------------------New One Document Upload-------------------- */}
        {data.proofOfAddress && data.addressProof === 10004 &&
          <>
            <div className='proof-border remove-bottom' style={{ marginTop: "-2px" }}>
              {data.backphoto && data.backphoto[0] ?
                <div className='proof-row' id="proof-row-id">
                  {<span className='proof-row-p'>{data.backphoto[0].name}</span>}
                  <img className='specific-width-height pointer-cur' src={remove_btn} alt="" onClick={() => setValue('backphoto', "")} />
                </div>
                :
                <div className='proof-row' >
                  <label htmlFor="upload-the-photo">
                    <p className='proof-row-p'>Upload File</p>
                    <a><img className='specific-width-height' src={upload_btn} alt="" /></a></label>
                  <input disabled={resp} type="file" {...register('backphoto', { required: true })} name="backphoto" id="upload-the-photo" accept={data.proofOfAddress.toString() === "27" || data.proofOfAddress.toString() === "30" ? '.pdf' : '.docs, .jpg, .jpeg, .gif, .pdf, .png'} />
                </div>}
            </div>
            <p style={{ fontSize: "12px", color: "#df6500", marginTop: "6px", marginBottom: "10px" }}>{data.proofOfAddress === 27 ? Arr[0] : data.proofOfAddress === 30 ? Arr[1] : Arr[2]}</p>
          </>}



        {/* ----------------------PLD TWO Document Upload-------------------- */}

        {(data.addressProof && data.addressProof !== 10004) && <div className="proof-div">
          <div className='proof-border' style={{ borderBottom: "0px" }}>
            {data.frontphoto && data.frontphoto[0] ?
              <div className="proof-row" id="proof-row-id">
                {<span className='proof-row-p'>{data.frontphoto[0].name}</span>}
                <img className='specific-width-height pointer-cur' src={remove_btn} alt="" onClick={() => setValue('frontphoto', "")} />
              </div>
              :
              <div className="proof-row" >

                <label htmlFor="upload-photo">
                  <p className='proof-row-p'>Upload Front Image</p>

                  <a><img className='specific-width-height' src={upload_btn} alt="" /></a>
                </label>
                <input disabled={resp} type="file" {...register('frontphoto', { required: true })} name="frontphoto" id="upload-photo" accept='.docs, .jpg, .jpeg, .gif, .pdf, .png' />
              </div>}

          </div>
          <div className='proof-border' style={{ marginTop: "-2px" }}>
            {data.backphoto && data.backphoto[0] ?
              <div className='proof-row' id="proof-row-id">
                {<span className='proof-row-p'>{data.backphoto[0].name}</span>}
                <img className='specific-width-height pointer-cur' src={remove_btn} alt="" onClick={() => setValue('backphoto', "")} />
              </div>
              :
              <div className='proof-row' >
                <label htmlFor="upload-the-photo">
                  <p className='proof-row-p'>Upload Back Image</p>
                  <a><img className='specific-width-height' src={upload_btn} alt="" /></a></label>
                <input disabled={resp} type="file" {...register('backphoto', { required: true })} name="backphoto" id="upload-the-photo" accept='.docs, .jpg, .jpeg, .gif, .pdf, .png' />
              </div>}
          </div>
        </div>}


        {/* -------------------------------------------Submit button----------------------------------------- */}
        <button type='submit' disabled={!Submit || resp} className={'footer-btn Bmargin' + ((Submit) ? " footer-btn-activate " : "")} >
          {!resp ?
            "Continue" :
            <div className="continue-lottie">
              <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
            </div>}
        </button>
      </form>
    </div></>
  )
}

export default CurrentAddress