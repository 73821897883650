import React, { useEffect } from 'react';
import './PersonalLoan.css';
import EligibilityCriteria from './images/eligibility-criteria.webp';
import FAQSection from '../FAQSection/FAQSection';
import StepSlider from "../HowToApplyPLSLIDER/StepSlider";
import Calculator from './Calculator';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import { useSelector, useDispatch } from 'react-redux';
import MobileFooter from '../Footer/MobileFooter';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import greenticknew from '../../Icons/greenticknew.svg';
import fandb from '../../Icons/fandb.svg'
import axios from 'axios';
import { source, prodbase_url, react_base_url, verification_source, verified_by, verifier_name } from '../Secret_Credentials/Secret_Credential';
import { useState } from 'react';
import Footer from '../Footer/Footer';
import queryString from "query-string";
import Base64 from "base-64";
import EligibilityCriteriaSection from './EligibilityCriteria';
import { setPreApprovedOfferDetails, updateCredentialsState, updateFormState, updateMwApplicationId, updatePLLoader, GenerateToken, updateLoginDetails, drop, updateProductId, updateDecisionDemog, updatePartnerData } from '../../Redux/action';
import { decryptLeadIdStepId, decryptPhoneAndCustomer, encryptAGRID, encryptOnlyLeadId, encryptQueryIdOfferId, gettingerror } from '../EverCalledFunctions/EverCalledFunctions';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getToken, getAprData } from '../EverCalledFunctions/EverCalledApis';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import FeaturesBenefits from './FeaturesBenefits';
import OurProducts from './OurProducts';
import { FetchCustomerPayload } from '../EverCalledFunctions/EverCalledPayload';
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';
import LoansSlider from './LoansSlider';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';
import FaqRupees from '../MoneyWideFaq/FaqRupees.svg';
import elctric from '../../Icons/elctric.svg'
import calender from '../../Icons/calender.svg'
import lapy from '../../Icons/lapy.svg'
import oalpha from '../../Icons/oalpha.svg'
import netmoney from '../../Icons/netmoney.svg'
import { apiInstance } from '../Config/APIConfig';
import NewFooter from '../NewFooter/NewFooter';
const dataa = {
    heading: [
        {
            left: "Personal Loan",
            right: "Eligibility Criteria"
        }
    ],
    subhead: [
        {
            head: "Age",
            content: "You must be between 21 to 55 years of age."
        },
        {
            head: "Net Monthly Income",
            content: " Rs. 25,000 and above."
        },
        {
            head: "Current Work Experience",
            content: "2 months and above."
        },
        {
            head: "Total Work Experience",
            content: " Minimum 2 years."
        },
        {
            head: "Credit Score",
            content: "650 or above."
        }
    ]
}

const datafandb= {
    givendata:[
    {
    img:elctric,
    span:"Instant in-principle",
    para:"approval in 30 seconds",
},
{
    img:FaqRupees,
    span:"Get a loan from",
    para:"Rs. 20,000 to 3 Lakhs.",
},
{
    img:calender,
    span:"Short term personal loans",
    para:"for less than 1 year.",
},
{
    img:netmoney,
    span:"Part payment and",
    para:"foreclosure facility",
},
{
    img:lapy,
    span:"100% digital process for",
    para:"loan approval",
},
{
    img:oalpha,
    span:"Repay through app",
    para:" or web login",
}
]
}

const PersonalLoan = () => {
    var loan_type_id_page = 56;
    const [showDashBoard, setShowDashBoard] = useState(false)
    const { formStates, mw_application_id, credentialState, preApprovedOfferDetails, PLLoader, token, loginDetails, office_action_penny, decisionDemog, mwpages,partnerData } = useSelector(state => state);
    const dispatch = useDispatch();
    const [data, setData] = React.useState({ sub_bread: "" })
    const [y, sety] = React.useState(false);
    const [v, setV] = React.useState("");
    const [state, setState] = React.useState(false);
    const [step, setStep] = React.useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const fid = searchParams.get("fid");
    const dashboard = searchParams.get("pad");
    const utm_source = searchParams.get("utm_source");
    const userId = searchParams.get("eligibility");
    const [loading, setLoading] = React.useState(true);
    const [rocket, setRocket] = React.useState(false);
    const [aprdata, setAprData] = React.useState('');
    const location = useLocation();
    const [mart, setMart] = React.useState(false);


    window.addEventListener("scroll", (event) => {
        if (window.scrollY > 5100) {
            sety(false);
        }
        else if (window.scrollY >= 536) {
            sety(true);
        }
        else {
            sety(false);
        }
    });

    useEffect(() => {
        if (dashboard != null && dashboard != "" && dashboard == "TVE9PQ==") {
            setShowDashBoard(true)
        }
    }, [dashboard])


    const getFidFromQuery = async () => {
        dispatch(updatePLLoader({ ...PLLoader, show: true }))
        setState(true);
        const details = await decryptLeadIdStepId();
        if (details) {
            dispatch(updateMwApplicationId(details[0]));
            setStep(details[1]);
        }
    }

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }

    const decJid = async () => {
        let dec_jid = await decryptPhoneAndCustomer();
        dispatch(updateLoginDetails({
            ...loginDetails, mobile: dec_jid[0]
        }))
        dispatch(updateCredentialsState({
            ...credentialState, cust_id: dec_jid[1]
        }))
        dispatch(updateFormState({
            ...formStates, StartJourney: true, YourProfile: true
        }))
    } 


    useEffect(() => {
        let qry = queryString.parse(window.location.search);
        if (qry.fid) {
            dispatch(updatePLLoader({ ...PLLoader, show: true }))
        }
        else if (qry.jid) {
            decJid();
        }
        else {
            setLoading(false);
        }
        window.scrollTo(0, 0);
        if (token == "") {
            myToken();
        }
        dispatch(updateProductId(loan_type_id_page))
    }, [])

    React.useEffect(() => {
        const { pathname } = location;
        if (pathname === "/personal-loan") {
            setMart(true);
        }
    }, [location])

    useEffect(() => {
        if ((token !== "") && (fid !== null)) {
            getFidFromQuery();
        }
    }, [token])

    const UtmSorce = async()=>{
        let decCheckUtm = await apiInstance.post(`check-utm-source`, {
            source,
            utmsource: utm_source,
            verification_source,
            verified_by,
            verifier_name
        }, {
            "token": token,
            "log": 1
        })
            // .then((res) => {
                if ((decCheckUtm.status == 1) && (decCheckUtm.status_code == 200)) {
                    if (decCheckUtm.data.utm_source_flag == 0) {
                        window.location.href = `${react_base_url}`;
                    }
                }
    }

    useEffect(() => {
        if (token !== "") {
            if (utm_source !== null) {
                UtmSorce();
            }
        }
    }, [token])

    const fetchCustomerDetails = async (mw_app_id, step) => {
        let decFetch = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_app_id, step), {
            "token": token,
            "log": 1
        })
            // .then((res) => {
                if ((decFetch.status == "1") && (decFetch.status_code == "200")) {
                    dispatch(updateCredentialsState({ ...credentialState, cust_id: decFetch.data.customer_id, lead_source: decFetch.data.lead_source }));
                    dispatch(updatePLLoader({ ...PLLoader, show: false }))
                    dispatch(updateDecisionDemog({ ...decisionDemog, new_journey_lead_source_flag: decFetch.data.new_journey_lead_source_flag, lead_source: decFetch.data.lead_source, new_journey_customer_id_flag: decFetch.data.new_journey_customer_id_flag,offer_journey_change_flag: decFetch.data.offer_journey_change_flag }));
                    if (showDashBoard) {
                        dispatch(updateFormState({ ...formStates, StartJourney: true, dashBoard: true }));
                        dispatch(updateLoginDetails({ ...loginDetails, dashBoardData: decFetch.data }))
                        return;
                    } else if (decFetch.data.thank_you_flag == 1) {
                        gettingerror("get thank you flag",mw_app_id)
                        if (decFetch.data.thank_you_url !== "") {
                            window.location.href = decFetch.data.thank_you_url;
                            return
                        }
                        else {
                            let qry = Base64.encode(`${mw_application_id}`);
                            qry = Base64.encode(qry);
                            window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18`;
                            return
                        }
                    } else if (decFetch.data.redirection_url && (decFetch.data.redirection_url !== "") && step != decFetch.data.currentstep) {
                        window.location.href = decFetch.data.redirection_url;
                        return;
                    } else {
                        switch (step.toString()) {
                            case '1': {
                                dispatch(updateFormState({ ...formStates, StartJourney: true, YourProfile: true }));
                                dispatch(updateLoginDetails({ ...loginDetails, preVal: decFetch.data, empData: decFetch.data, preData: decFetch, mobile: decFetch.data.phone_no, equifax_no: decFetch.data.equifax_no }));
                                break;
                            }
                            case '2': {
                                dispatch(updateFormState({ ...formStates, StartJourney: true, EmployeeDetails: true }));
                                dispatch(updateLoginDetails({ ...loginDetails, empData: decFetch.data }))
                                break;
                            }
                            case '19': {
                                dispatch(updateFormState({ ...formStates, StartJourney: true, LoanDetails: true }));
                                dispatch(updateLoginDetails({ ...loginDetails, preData: decFetch }))
                                break;
                            }
                            case '3': {
                                // if(decFetch.data.customer_id == 5){
                                //     dispatch(updateFormState({...formStates,StartJourney:true,OfferNew:true}))
                                // }
                                // else if (decFetch.data.aip_generate_type == 2) {
                                //     dispatch(updateFormState({ ...formStates, StartJourney: true }));
                                //     dispatch(setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: 2 }))
                                // }
                                // else if (decFetch.data.final_offer_generate_type == 2) {
                                //     dispatch(updateFormState({ ...formStates, StartJourney: true }));
                                //     dispatch(setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: 2 }))
                                // }
                                // else {
                                //     dispatch(updateFormState({ ...formStates, StartJourney: true }));
                                //     dispatch(setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: 1 }))
                                // }
                                // break;
                                if((decFetch?.data?.lead_source_flag) && (decFetch?.data?.lead_source_flag == 1)){
                                    if(decFetch?.data["final_offer_generate_type"] == 2){
                                        dispatch(updatePartnerData({ ...partnerData, finaloffer: decFetch.data.aipManual_offer }))
                                    }
                                    dispatch(updateFormState({...formStates,StartJourney:true,OfferNew:true}))
                                    break;
                                }
                                else{
                                    if(decFetch.data.dual_offer_journey_flag == 1 && (userId == null || userId != 2)){
                                        dispatch(updateFormState({...formStates,StartJourney:true,OfferNew:true}));
                                        break;
                                    }
                                    else if(decFetch.data.aip_generate_type == 2){
                                        dispatch(updateFormState({ ...formStates, StartJourney: true }));
                                        dispatch(setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: 2 }))
                                        break;
                                    }
                                    else if(decFetch.data.final_offer_generate_type == 2){
                                        dispatch(updateFormState({ ...formStates, StartJourney: true }));
                                        dispatch(setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: 2 }))
                                        break;
                                    }
                                    else{
                                        dispatch(updateFormState({ ...formStates, StartJourney: true }));
                                        dispatch(setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: 1 }))
                                        break;
                                    }
                                }
                               
                            }
                            case '4': {
                                dispatch(updateFormState({ ...formStates, StartJourney: true, KycVerification: true }));
                                break;
                            }
                            case '5': {
                                const qry = encryptOnlyLeadId(mw_application_id);
                                window.location.href = `${react_base_url}analyzer/?fid=${qry}`;
                                break;
                            }
                            case '7': {
                                const qry = encryptQueryIdOfferId(mw_application_id, 1);
                                window.location.href = `${react_base_url}final-offer/?fid=${qry}`;
                                break;
                            }
                            case '8': {
                                let qry = encryptAGRID(mw_application_id);
                                window.location.href = `${react_base_url}loan-agreement/?agrid=${qry}`;
                                break;
                            }
                            case '15': {
                                const qry = encryptOnlyLeadId(mw_application_id);
                                window.location.href = `${react_base_url}emandate-setup/?fid=${qry}`;
                                break;
                            }
                            case '16': {
                                dispatch(updateFormState({ ...formStates, StartJourney: true, congo: true }));
                                break;
                            }
                            case '20': {
                                // dispatch(drop({ ...office_action_penny, data: decFetch.data }))
                                // dispatch(updateFormState({ ...formStates, StartJourney: true, OfficeAddress: true }));
                                // break;
                                if (decFetch?.data['skip_screen_flag'] == 1) {
                                    dispatch(updateFormState({ ...formStates, StartJourney: true, AdditionalDetails: true }));
                                    break;
                                }
                                else {
                                    dispatch(drop({ ...office_action_penny, data: decFetch.data }))
                                    dispatch(updateFormState({ ...formStates, StartJourney: true, OfficeAddress: true }));
                                    break;
                                }
                            }
                            case '21': {
                                dispatch(updateFormState({ ...formStates, StartJourney: true, ReferenceDetails: true }));
                                break;
                            }
                            case '22': {
                                dispatch(updateFormState({ ...formStates, StartJourney: true, BankDetails: true }));
                                break;
                            }
                            case '23': {
                                dispatch(updateFormState({ ...formStates, StartJourney: true, Email: true }));
                                break;
                            }
                            case '24': {
                                dispatch(updateFormState({ ...formStates, StartJourney: true, AdditionalDetails: true }));
                                break;
                            }
                            default: {
                                dispatch(updateFormState({ ...formStates, StartJourney: true, YourProfile: true }));
                            }
                        }
                    }
                }
                else {
                    gettingerror('fetch customer error web',mw_app_id)
                    setRocket(true);
                }
            // }).catch((err) => {
            //     setRocket(true)
            // })
    }

    React.useEffect(() => {
        if ((mw_application_id !== "") && state) {
            if (fid !== null) {
                fetchCustomerDetails(mw_application_id, step);
            }
        }
    }, [mw_application_id, state, showDashBoard])

    const apr = async () => {
        const AprData = await getAprData(token);
        if ((AprData.status === "1") && (AprData.status_code === "200")) {
            if ((AprData.data.Values) && (AprData.data.Values.length !== 0) && (AprData.data.labels) && (AprData.data.labels.length !== 0)) {
                setAprData(AprData.data);
            }
        }
    }

    React.useEffect(() => {
        if ((token !== "") && (!fid)) {
            setTimeout(() => {
                apr();
            }, 500);
        }
    }, [token, fid])

    const handleError = (e) => {
        window.location.reload();
    }

    return (
        (!formStates.StartJourney ?
            <div>
                { mwpages?.metatagsdata ? <Helmet>
                    <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                    <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                    <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
                </Helmet> : "" }
                { !fid ? <HittingMetaTagApi /> : ""}
                {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
                {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                {!loading &&
                    <div>
                        <section className="add">
                            {<NewLoginForm pageType={loan_type_id_page} mapData={data} />}
                            {y && <RightSidePopUp />}
                        </section>
                        <section className='about-content' id='about-pl-after-form'>
                            <div className='ploan-conatiner'>
                                <div className="ploan-content1">
                                    <h2 className='heing add-btom-line cetnred bot-6'>About MoneyWide <b>Personal Loan</b></h2>
                                    <p className='heading-p'>Get a personal loan of upto Rs. 3 Lakhs without any branch visits at affordable-interest rates and zero hidden charges from digital lending platform
                                        Company in India. The entire process takes less than 5 minutes for approval and instant disbursal. You can also apply using the MoneyWide app for a quick loan
                                        process with minimum documentation, Part-payment and foreclosure.</p>
                                </div>
                            </div>
                        </section>
                        <OurProducts />
                        <FeaturesBenefits  features={datafandb}/>
                        <EligibilityCriteriaSection elgdata={dataa} />
                        <Calculator />
                        <StepSlider />
                        {aprdata && <section className="ploan-APR">
                            <div className="ploan-container pl-cont-new">
                                <div className="box-sh">
                                    <div className="eligibility-criteria-box">
                                        <h2 className="heing bot-6 lefting add-btom-line"><b>Illustration</b> of the total cost of a <b>personal loan</b></h2>
                                        <div className="plpage mlc-pl-cost-main " >
                                            <div className="mlc-pl-left">
                                                <div className="mlc-pl-list">
                                                    <div className="mlc-pl-list-cont">
                                                        <img src={greenticknew} alt="tick" />
                                                        <span>{aprdata.labels.label1} {aprdata.Values.value1}</span>
                                                    </div>
                                                    <div className="mlc-pl-list-cont">
                                                        <img src={greenticknew} alt="tick" />
                                                        <span>{aprdata.labels.label2} {aprdata.Values.value2}</span>
                                                    </div>
                                                    <div className="mlc-pl-list-cont">
                                                        <img src={greenticknew} alt="tick" />
                                                        <span>{aprdata.labels.label3} {aprdata.Values.value3}</span>
                                                    </div>
                                                    <div className="mlc-pl-list-cont">
                                                        <img src={greenticknew} alt="tick" />
                                                        <span>{aprdata.labels.label4} {aprdata.Values.value4}</span>
                                                    </div>
                                                    <div className="mlc-pl-list-cont">
                                                        <img src={greenticknew} alt="tick" />
                                                        <span>{aprdata.labels.label5} {aprdata.Values.value5}</span>
                                                    </div>
                                                    <div className="mlc-pl-list-cont">
                                                        <img src={greenticknew} alt="tick" />
                                                        <span>{aprdata.labels.label6} {aprdata.Values.value6}</span>
                                                    </div>
                                                    <div className="mlc-pl-list-cont">
                                                        <img src={greenticknew} alt="tick" />
                                                        <span>{aprdata.labels.label7} {aprdata.Values.value7}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mlc-pl-right">
                                                <img src={fandb} alt="illust-banner" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>}
                        <LoansSlider />
                        {/* <section className={mart ? "martop-faq" : 'FAQs'}>
                            <FAQSection />
                        </section> */}
                        {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                            <FAQSectionChk data={mwpages.faqdata} />
                        </section> : ""}
                        <NewFooter />
                    </div>
                }
            </div>
            : (
                // ------------------------Current rendered page
                <>
                    {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                    <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                    {(!formStates.IsIframe) && <MobileFooter />}
                </>
            )
        )
    )

}

export default PersonalLoan;
