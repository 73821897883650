import React, { useEffect } from 'react'
import back from "../../Icons/back.svg"
import LoanDetails from './LoanDetails'
import calender from '../../Icons/calender.svg'
import DropDownIcon from '../../Icons/DropDownIcon.svg'
import './LoanDetails.css'
import DateCalender from '../DateCalender/DateCalender'
import { useForm } from 'react-hook-form';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../assests/lootie-files/continue.json';
import { DownloadPDFFunc, GetRepaymentDetailsAPI, GetStatementAPI, formatDate, getLoginData } from './MyAccountAPIs'
import { useState } from 'react'
import CKYCWaiting from '../LoginPopUp/CKYCWaiting'
import RecentTransaction from './RecentTransaction'
import yellownotify from '../../Icons/yellownotify.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../EverCalledFunctions/EverCalledApis';
import { GenerateToken } from '../../Redux/action';
import { useNavigate } from 'react-router-dom';
import share from '../../Icons/Share.svg'
import successTick from '../../Icons/successTick.svg'
import downloadIcon from '../../Icons/download.svg'
import { verified_by, verifier_name, verification_source, source } from '../Secret_Credentials/Secret_Credential'
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase'

const AccountStatement = () => {
  const [loandata, setLoanData] = useState({})
  const [appstatement, setAppStatement] = useState({})
  const [loader, setLoader] = useState(true)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [noDataFound, setNoDataFound] = useState(false)
  const [startingDate, setStartingDate] = React.useState(null)
  const [endingDate, setEndingDate] = React.useState(null)
  const [err, setErr] = React.useState(false)
  const [resp, setResp] = React.useState(false)
  const [APIErr, setAPIErr] = React.useState(false)
  const [send, setSend] = useState(1)
  const [download, setDownload] = useState(1)
  const [mw, setMW] = React.useState("")
  const [timeLimit, setTimeLimit] = React.useState({
    minTime: new Date(),
    maxTime: new Date()
  })
  const { token } = useSelector(state => state)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { register, handleSubmit, reset, formState: { errors }, watch, setValue, clearErrors } = useForm({
    defaultValues: {
      start: "",
      end: ""
    }
  })


  const data = watch();

  const payLoad = {
    "mw_application_id": mw,
    "level_id": 1,
    "product_id": 56,
    "date_from": formatDate(startingDate),
    "date_to": formatDate(endingDate),
    verified_by,
    verifier_name,
    verification_source,
    source
  }


  const getStatementData = async () => {
    if (startingDate && endingDate && startingDate <= endingDate) {
      setNoDataFound(false)
      setResp(true)
      var pay = { ...payLoad }
      const APIResponse = await GetStatementAPI(pay, token)
      if ((APIResponse.status_code === "200") && (APIResponse.status === "1")) {
        setAppStatement(APIResponse.data)
        setFormSubmitted(true)
      } else if ((APIResponse.status_code === "429") && (APIResponse.status === "0")) {
        setNoDataFound(true)
        setResp(false)
      } else {
        setAPIErr(true)
      }
    }

  }

  const onSubmit = (e) => {
    e.preventDefault();
    getStatementData()
  }


  React.useEffect(() => {
    if (endingDate && startingDate) {
      if (new Date(endingDate) < new Date(startingDate)) {
        setEndingDate("")
        setErr(true)
      } else {
        setErr(false)
      }
    }
  }, [endingDate, startingDate])

  const getUserData = async (mw_app_id) => {
    const APIResponse = await GetRepaymentDetailsAPI(mw_app_id, token);
    if (APIResponse.status_code === "200" && APIResponse.status === "1") {
      setLoanData(APIResponse.data)
      setLoader(false)
      if (APIResponse.data.transactions[0].date && formatDate(APIResponse.data.transactions[0].date) != "0000-00-00" && formatDate(APIResponse.data.transactions[0].date) != "1970-01-01") {
        setTimeLimit({ ...timeLimit, minTime: new Date(formatDate(APIResponse.data.transactions[0].date)) })
      } else if (APIResponse.data.transactions[0].transactionDate && formatDate(APIResponse.data.transactions[0].transactionDate) != "0000-00-00" && formatDate(APIResponse.data.transactions[0].transactionDate) != "1970-01-01") {
        setTimeLimit({ ...timeLimit, minTime: new Date(formatDate(APIResponse.data.transactions[0].transactionDate)) })
      }
    } else {
      setLoader(false)
      setAPIErr(true)
    }
  }


  useEffect(() => {
    if (token !== "") {
      let payLoad = getLoginData()
      if (!payLoad && !payLoad.mw_app_id) {
        navigate('/login')
      }
      else if (payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") {
        getUserData(payLoad.mw_app_id);
        setMW(payLoad.mw_app_id)
      }
    }
  }, [token])


  const myToken = async () => {
    const T = await getToken();
    dispatch(GenerateToken(T))
  }

  useEffect(() => {
    if (token === "") {
      myToken()
    }
  }, [])

  const RemoveSessionData = () => {

  }

  const handleEmailSend = async () => {
    setSend(2)
    var pay = {
      ...payLoad,
      "send_email": 1
    }
    const APIResponse = await GetStatementAPI(pay, token)
    if (APIResponse.status_code === "200" && APIResponse.status === "1") {
      setSend(3)
    } else if (APIResponse.status_code === "429" && APIResponse.status === "0") {
      setSend(1)
    } else {
      setSend(1)
    }
  }


  const handleDownload = async () => {
    setDownload(2)
    var pay = {
      ...payLoad,
      "pdf_download": 1
    }
    const APIResponse = await GetStatementAPI(pay, token)
    if (APIResponse.status_code === "200" && APIResponse.status === "1") {
      setDownload(3)
      DownloadPDFFunc(APIResponse.data.Pdf_data, "account_statement")
    } else if (APIResponse.status_code === "429" && APIResponse.status === "0") {
      setDownload(1)
    } else {
      setDownload(1)
    }

  }

  const HandleButton = () => {
    if (send === 1) {
      return <button onClick={() => handleEmailSend()}>
        Send Over Mail <img src={share} alt='NA' /></button>
    } else if (send === 2) {
      return <button disabled >
        <div className="continue-lottie">
          <Lottie loop animationData={ctaAnimation} play className="download-ctn-loader" />
        </div>
      </button>
    } else {
      return <button className='send-success'>Email Sent Successfully <img src={successTick} alt="NA" /></button>
    }
  }



  const manageRefresh = () => {
    setLoader(true)
    setAPIErr(false)
    let payLoad = getLoginData();
    if (!payLoad && !payLoad.mw_app_id) {
      navigate('/login')
    }
    else if (payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") {
      getUserData(payLoad.mw_app_id);
      setMW(payLoad.mw_app_id)
    }
  }

  return (
    <>
      {APIErr && !loader && <NewErrorCase handleClick={manageRefresh} />}
      {loader && <CKYCWaiting />}
      {!loader && !APIErr &&
        <div className='transaction-main'>
          <div className='trasaction-head'>
            <a href="/myaccount/myloan" onClick={() => RemoveSessionData()}>
              <img className="" src={back} alt="back-img" /></a>
            <span>Account Statement</span>
          </div>
          <LoanDetails data={loandata} />
          {!formSubmitted && <div className='account-statement-main'>
            <div className='as-head'>
              <span>Select date for the statement period</span>
            </div>
            <div className='as-period'>
              <form>
                <div className="as-period-firstbox">
                  <div className='as-period-content'>
                    <div className={'date-box' + (startingDate ? " add-border" : "")}>
                      <img src={calender} alt={"NA"} />
                      <DateCalender startDate={startingDate} setStartDate={setStartingDate} minYear={timeLimit.minTime.getFullYear()} timeLimit={timeLimit} setTimeLimit={setTimeLimit} placeHolder={"Select Start Date"} />
                      <img className="small-the-icon" src={DropDownIcon} alt="NA" />
                    </div>
                    <div className={'date-box' + (endingDate ? " add-border" : "")}>
                      <img src={calender} alt={"NA"} />
                      <DateCalender startDate={endingDate} setStartDate={setEndingDate} minYear={timeLimit.minTime.getFullYear()} timeLimit={timeLimit} placeHolder={"Select End Date"} />
                      <img className="small-the-icon" src={DropDownIcon} alt="NA" />
                      {err && <div className="myc-err-text">The start date cannot be greater than the end date.</div>}
                    </div>
                  </div>
                </div>
                <div className='account-state-button od-button'>
                  <button type="submit" onClick={(e) => onSubmit(e)} disabled={(!startingDate && !endingDate) || resp} className={"footer-btn od-tn-footer" + (startingDate && endingDate ? " activate" : "")}>{!resp ?
                    "Continue" :
                    <div className="continue-lottie">
                      <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                    </div>}</button>
                </div>
              </form>
            </div>
          </div>}

          {noDataFound && <div className="foreclose-yellow-box yellow-box-spacing">
            <img src={yellownotify} alt="yellownotify" />
            <span>There are no transactions made in the selected tenure. Please choose another date.</span>
          </div>}
          {formSubmitted &&
            <div className='account-statement-main'>
              <div className='as-head'>
                <span>Account Statement from {formatDate(startingDate)} to {formatDate(endingDate)}.</span>
                <div className='has-download-btn'>
                  <HandleDownloadButton download={download} handleDownload={handleDownload} text={"Download"} />
                  <HandleButton />
                </div>
              </div>
              {appstatement && appstatement.map((row, index) => {
                return <RecentTransaction key={index} rowData={row} text={row?.repaymentAtDisbursement ? "Processing fees and other charges" : (row?.disbursement ? "Disbursement" : "Repayment")}/>
              })}
            </div>
          }
        </div>
      }

    </>
  )
}

export default AccountStatement;

export const HandleDownloadButton = ({ download, handleDownload, text }) => {
  if (download === 1) {
    return <button onClick={() => handleDownload()}>
      {text} <img src={downloadIcon} alt='NA' /></button>
  } else if (download === 2) {
    return <button disabled >
      <div className="continue-lottie">
        <Lottie loop animationData={ctaAnimation} play className="download-ctn-loader" />
      </div>
    </button>
  } else {
    return <button className='send-success'>Download Successfully <img src={successTick} alt="NA" /></button>
  }
}