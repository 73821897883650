import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GenerateToken, setPreApprovedOfferDetails, updateCredentialsState, updateFormState, updateMwApplicationId } from "../../Redux/action";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import queryString from "query-string";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import NewLeftPart from "../Journey Form/FormLeftPart/NewLeftPart";
import axios from "axios";
import { prodbase_url, react_base_url, source, verification_source, verified_by, verifier_name } from "../Secret_Credentials/Secret_Credential";
import Base64 from "base-64";
import { FetchCustomerPayload } from "../EverCalledFunctions/EverCalledPayload";
import { Check_utm_source, RelocateUTMCheck, encryptLeadIdStepId, encryptOnlyLeadId, gettingerror } from "../EverCalledFunctions/EverCalledFunctions";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
import { useNavigate } from "react-router";
import { AxiosThirty } from "../AxiosConfiguration/AxiosConfig";
import { AAAPIInstance, apiInstance } from "../Config/APIConfig";

function BsaRedirect() {
    const [state, setState] = useState(true);
    const { token, credentialState, mw_application_id, preApprovedOfferDetails, formStates } = useSelector(state => state);
    const [finProData, setFinProData] = React.useState({
        con_handle: "",
        ecres: "",
        resdate: "",
        fi: "",
        plmo_handle: ""
    })
    const [rocket, setRocket] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }

    const decodingFid = async () => {
        try {
            let qry = queryString.parse(window.location.search);
            let decodeData = Base64.decode(qry.fid);
            decodeData = Base64.decode(decodeData);
            console.log(decodeData);
            dispatch(updateMwApplicationId(decodeData));
        }
        catch {
            window.location.href = `${react_base_url}personal-loan`;
        }
    }

    const decodingQueryAndCust = () => {
        try {
            let qry = queryString.parse(window.location.search);
            let decodeData = Base64.decode(qry.fid);
            decodeData = Base64.decode(decodeData);
            if (decodeData.includes("&")) {
                let query_id = decodeData.split("&");
                query_id = query_id[0].split("query_id=");
                query_id = query_id[1];
                query_id = Base64.decode(query_id);
                query_id = Base64.decode(query_id);
                dispatch(updateMwApplicationId(query_id));

                let cust_id = decodeData.split("&");
                cust_id = cust_id[1].split("cust_id=");
                cust_id = cust_id[1];
                cust_id = Base64.decode(cust_id);
                cust_id = Base64.decode(cust_id);
                dispatch(updateCredentialsState({ ...credentialState, cust_id: cust_id }))
            }
        }
        catch {
            window.location.href = `${react_base_url}personal-loan`;
        }
    }

    useEffect(() => {
        if (token === "") {
            myToken()
        }
    }, [])

    useEffect(() => {
        if (token !== "") {
            let qry = queryString.parse(window.location.search);
            if ((qry.fid)) {
                decodingQueryAndCust();
            }
        }
    }, [token])

    const getfidata = async (id, data) => {
        let obj = {
            url: "finprogetfilist",
            data: {
                "customer_id": credentialState.cust_id,
                "product_id": 0,
                "partner_id": id,
                "level_id": 1,
                "lead_id": mw_application_id,
                "verified_by": verified_by,
                "verifier_name": verifier_name,
                "verification_source": verification_source,
                "source": source
            },
            token: token
        }
        let dec = await AAAPIInstance(obj)
        // .then((res) => {
        if (dec.status == '1' && dec.status_code == '200') {
            if (dec.data.fi_data_present == '0') {
                gettingerror('bsadigi error api web', mw_application_id)
                if (window.sessionStorage.getItem("count") == 1) {
                    setTimeout(() => {
                        window.sessionStorage.setItem("count", 2);
                        getfidata(id, data);
                    }, 30000);
                }
                else if (window.sessionStorage.getItem("count") == 2) {
                    setTimeout(() => {
                        window.sessionStorage.setItem("count", 3);
                        getfidata(id, data);
                    }, 10000);
                }
                else if (window.sessionStorage.getItem("count") == 3) {
                    setTimeout(() => {
                        window.sessionStorage.setItem("count", 4);
                        getfidata(id, data);
                    }, 10000);
                }
                else {
                    if (window.location.search.includes("app=MQ==")) {
                        window.location.href = `${react_base_url}analyzer/sorry`;
                    }
                    else {
                        window.sessionStorage.clear();
                        let qry = Base64.encode(`${mw_application_id}`);
                        qry = Base64.encode(qry);
                        // RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qry}&aa_eligibility=2`);
                        window.location.href =`${react_base_url}analyzer/?fid=${qry}&aa_eligibility=2`
                        // window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18`;
                    }

                }
            }
            else {
                finbitRedirectNew(data);
            }
        }
        else if ((dec.status == '0') && (dec.status_code == '429')) {
            gettingerror('error in filist web', mw_application_id)
            window.sessionStorage.clear();
            let qry = Base64.encode(`${mw_application_id}`);
            qry = Base64.encode(qry);
            // RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qry}&aa_eligibility=2`);
            window.location.href =`${react_base_url}analyzer/?fid=${qry}&aa_eligibility=2`
        }
        else {
            setRocket(true);
        }
        // })
    }

    const FinproDecryptAPi = async () => {
        let dec = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 5), {
            token
        })
        // .then((res) => {
        if ((dec.status == "1") && (dec.status_code == "200")) {
            let partner_id = dec?.data?.finpro_partner_id ? dec?.data?.finpro_partner_id : 425;
            // const payLoad = {
            //     "product_id": 0,
            //     "partner_id": partner_id,
            //     "customer_id": parseInt(credentialState.cust_id),
            //     "level_id": 1,
            //     "lead_id": mw_application_id,
            //     "verified_by": verified_by,
            //     "verifier_name": verifier_name,
            //     "verification_source": verification_source,
            //     "source": source,
            //     "ecres": finProData.ecres,
            //     "resdate": finProData.resdate,
            //     "fi": finProData.fi,
            //     "consent_handle": finProData.con_handle
            // }
            let obj = {
                url: "finprodecrypturl",
                data: {
                    "product_id": 0,
                    "partner_id": partner_id,
                    "customer_id": parseInt(credentialState.cust_id),
                    "level_id": 1,
                    "lead_id": mw_application_id,
                    "verified_by": verified_by,
                    "verifier_name": verifier_name,
                    "verification_source": verification_source,
                    "source": source,
                    "ecres": finProData.ecres,
                    "resdate": finProData.resdate,
                    "fi": finProData.fi,
                    "consent_handle": finProData.con_handle,
                    'plmo_handle': finProData.plmo_handle
                },
                token: token
            }
            let finprodec = await AAAPIInstance(obj)
            // .then((response) => {
            if ((finprodec.status == "1") && (finprodec.status_code == "200")) {
                // finbitRedirectNew(res.data.data);
                gettingerror('hit decrypturl web', mw_application_id)
                setTimeout(() => {
                    window.sessionStorage.setItem("count", 1);
                    getfidata(partner_id, dec.data);
                }, 20000);
            }
            else if ((finprodec.status == '0') && (finprodec.status_code == '429')) {
                gettingerror('error in decrypturl web', mw_application_id)
                let qry = Base64.encode(`${mw_application_id}`);
                qry = Base64.encode(qry);
                // RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qry}&aa_eligibility=2`);
                window.location.href =`${react_base_url}analyzer/?fid=${qry}&aa_eligibility=2`
            }
            else {
                setRocket(true);
            }
            // }).catch((err) => {
            //     setRocket(true);
            // })
        }
        // })

    }

    const finbitRedirectNew = async (eligibilityData) => {
        let url = eligibilityData.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
        let dec = await apiInstance
            .post(
                `${url}`,
                {
                    "customer_id": credentialState.cust_id,
                    "mw_application_id": mw_application_id,
                    "offer_type": 1,
                    "verifier_name": verifier_name,
                    "verification_source": verification_source,
                    "verified_by": verified_by,
                    "product_id": 56,
                    "stage_name": "Bank Statement Analyser",
                    "level_id": 1,
                    "source": source,
                },
                {
                    "token": '6bf4b0fe-d412-4b42-8d2e-625c2ec20bc5'
                }
            )
        // .then((res) => {
        if ((dec.status == "1") && (dec.status_code == "200")) {
            const eligibleBankRes = dec.data;
            gettingerror('hit bank statement web', mw_application_id)
            if ((dec.data.cust_eligible == 1) || (dec.data.template_Id == 7)) {
                if (eligibilityData.fetch_epfo_flag == 1) {
                    let decisionDec = await apiInstance.post(`decision-epfo-api`, {
                        "customer_id": credentialState.cust_id,
                        "product_id": 56,
                        "username": eligibilityData.customer_full_name ? eligibilityData.customer_full_name : "",
                        "mw_application_id": mw_application_id,
                        "mobile_no": eligibilityData.custPhoneNo ? eligibilityData.custPhoneNo : "",
                        "company_name": eligibilityData.company_name ? eligibilityData.company_name : "",
                        "pancard": eligibilityData.pancard ? eligibilityData.pancard : "",
                        "source": source,
                        "verification_source": verification_source,
                        "verified_by": verified_by,
                        "verifier_name": verifier_name
                    }, {
                        "token": token
                    })
                    // .then((res) => {
                    gettingerror('epfo hit web', mw_application_id)
                    if ((decisionDec.status == 1) && (decisionDec.status_code == 200)) {
                        // let url = eligibilityData.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test"
                        let offerDec = await apiInstance.post(
                            `${url}`,
                            {
                                "source": source,
                                "mw_application_id": mw_application_id,
                                "product_id": 56,
                                "customer_id": credentialState.cust_id,
                                "offer_type": 1,
                                "level_id": 1,
                                "verified_by": verified_by,
                                "verifier_name": verifier_name,
                                "verification_source": verification_source,
                                "stage_name": "Employment Check"
                            },
                            {
                                "token": token,
                                "log": 1
                            }
                        )
                        // .then((res) => {
                        // if ((res.data.status == 1) && (res.data.status_code == 200)) {
                        if (offerDec.status_code === "200") {
                            let cur_url = window.location.href
                            if (cur_url.includes("app=MQ==")) {
                                window.location.href = `${react_base_url}analyzer/thank-you`;
                            }
                            else if (eligibleBankRes.cust_eligible == 1) {
                                let qry = encryptLeadIdStepId(mw_application_id, 3);
                                let cur_url = window.location.href;
                                if (cur_url.includes("con_handle") || (cur_url.includes("ecres") || (cur_url.includes("fi")) || (cur_url.includes("resdate")))) {
                                    window.location.href = `${react_base_url}personal-loan/?fid=${qry}&eligibility=2`;
                                }
                                else {
                                    RelocateUTMCheck(`${react_base_url}personal-loan/?fid=${qry}&eligibility=2`);
                                }
                            }
                            else if (eligibleBankRes.template_Id == 7) {
                                let qry = Base64.encode(`${mw_application_id}`);
                                qry = Base64.encode(qry);
                                RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=7`);
                            }
                        }
                        // }


                        // });
                    }
                    else {
                        setState(false);
                        setRocket(true);
                    }
                }
                else {
                    let cur_url = window.location.href
                    if (cur_url.includes("app=MQ==")) {
                        window.location.href = `${react_base_url}analyzer/thank-you`;
                    }
                    else if (eligibleBankRes.cust_eligible == 1) {
                        let qry = encryptLeadIdStepId(mw_application_id, 3);
                        let cur_url = window.location.href;
                        if (cur_url.includes("con_handle") || (cur_url.includes("ecres") || (cur_url.includes("fi")) || (cur_url.includes("resdate")))) {
                            window.location.href = `${react_base_url}personal-loan/?fid=${qry}&eligibility=2`;
                        }
                        else {
                            RelocateUTMCheck(`${react_base_url}personal-loan/?fid=${qry}&eligibility=2`);
                        }
                    }
                    else if (eligibleBankRes.template_Id == 7) {
                        let qry = Base64.encode(`${mw_application_id}`);
                        qry = Base64.encode(qry);
                        RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=7`);
                    }
                }



            }
            else {
                let qry = Base64.encode(`${mw_application_id}`);
                qry = Base64.encode(qry);
                RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=7`);
            }
        }
        else {
            setRocket(true);
        }

        // }).catch((err) => {
        //     setRocket(true);
        // })
    }

    const finbitRedirect = async () => {
        if ((window.sessionStorage.getItem("selected_program_id")) && (window.sessionStorage.getItem("selected_program_id") !== undefined) && (window.sessionStorage.getItem("selected_program_id") !== null)) {
            const id = window.sessionStorage.getItem("selected_program_id");
            let offerDec = await apiInstance
                .post(
                    `offer-eligibility`,
                    {
                        "customer_id": credentialState.cust_id,
                        "mw_application_id": mw_application_id,
                        "offer_type": 1,
                        "verifier_name": verifier_name,
                        "verification_source": verification_source,
                        "verified_by": verified_by,
                        "product_id": 56,
                        "stage_name": "Bank Statement Analyser",
                        "program_id": id,
                        "level_id": 1,
                        "source": source,
                    },
                    {
                        "token": token,
                        "log": 1
                    }
                )
            // .then((res) => {
            if ((offerDec.status == "1") && (offerDec.status_code == "200")) {
                let checkCondition = Check_utm_source()
                if (checkCondition && checkCondition.length != 0 && !window.location.search.includes("app=MQ==")) {
                    let qry = Base64.encode(`${mw_application_id}`);
                    qry = Base64.encode(qry);
                    window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=7${checkCondition}`;
                }
                else if (window.location.search.includes("app=MQ==")) {
                    window.location.href = `${react_base_url}analyzer/thank-you`;
                }
                else {
                    let qry = Base64.encode(`${mw_application_id}`);
                    qry = Base64.encode(qry);
                    RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=7`);
                }
            }

            // }).catch((err) => {
            //     setRocket(true);
            // });
        }
        else {
            let fetchDec = await apiInstance
                .post(
                    `fetch-customer-details`,
                    FetchCustomerPayload(mw_application_id, 5),
                    {
                        "token": token
                    }
                )
            // .then((res) => {
            if ((fetchDec.status == 1) && (fetchDec.status_code == 200)) {

                const id = fetchDec.data.selected_program_id;
                window.sessionStorage.setItem("selected_program_id", id);
                let newOfferDec = await apiInstance
                    .post(
                        `offer-eligibility`,
                        {
                            "customer_id": credentialState.cust_id,
                            "mw_application_id": mw_application_id,
                            "offer_type": 1,
                            "verifier_name": verifier_name,
                            "verification_source": verification_source,
                            "verified_by": verified_by,
                            "product_id": 56,
                            "stage_name": "Bank Statement Analyser",
                            "program_id": id,
                            "level_id": 1,
                            "source": source,
                        },
                        {
                            "token": token,
                            "log": 1
                        }
                    )
                // .then((res) => {
                if ((newOfferDec.status == "1") && (newOfferDec.status_code == "200")) {
                    let checkCondition = Check_utm_source();
                    if (checkCondition && checkCondition.length != 0 && !window.location.search.includes("app=MQ==")) {
                        let qry = Base64.encode(`${mw_application_id}`);
                        qry = Base64.encode(qry);
                        window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=7${checkCondition}`;
                    }
                    else if (window.location.search.includes("app=MQ==")) {
                        window.location.href = `${react_base_url}analyzer/thank-you`;
                    }
                    else {
                        let qry = Base64.encode(`${mw_application_id}`);
                        qry = Base64.encode(qry);
                        RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=7`);
                    }
                }

                // }).catch((err) => {
                //     setRocket(true);
                // })
            }
            else {
                setRocket(true);
            }
            // }).catch((err) => {
            //     setRocket(true);
            // });
        }
    }

    const leadSourceTesting = async () => {
        let cur_url = window.location.href;
        let fetchDec = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 5), {
            "token": token
        })
        // .then((res) => {
        if ((fetchDec.status == "1") && (fetchDec.status_code == "200")) {
            sessionStorage.setItem("new_journey_lead_source_flag", fetchDec.data.new_journey_lead_source_flag);
            if ((!window.sessionStorage.getItem("selected_program_id")) || (window.sessionStorage.getItem("selected_program_id") == "undefined") || (window.sessionStorage.getItem("selected_program_id") == "null")) {
                sessionStorage.setItem("selected_program_id", fetchDec.data.selected_program_id)
            }
            if ((fetchDec.data.new_journey_lead_source_flag == 1) || (fetchDec.data.new_journey_customer_id_flag == 1)) {
                if (cur_url.includes("app=MQ==")) {
                    window.location.href = `${react_base_url}analyzer/thank-you`;
                }
                else {
                    finbitRedirectNew(fetchDec.data);
                }

            }
            else {
                finbitRedirect();
            }
        }
        // })
    }

    const digitapGetStatus = async () => {

        let obj = {
            url: "bsadigi-getstatus",
            data: {
                "customer_id": credentialState.cust_id,
                "mw_application_id": mw_application_id,
                "level_id": 1,
                "partner_id": 5,
                "product_id": 0,
                "source": source,
                "verification_source": verification_source,
                "verified_by": verified_by,
                "verifier_name": verifier_name
            },
            token: token
        }

        // const payLoad = {
        //     "customer_id": credentialState.cust_id,
        //     "mw_application_id": mw_application_id,
        //     "level_id": 1,
        //     "partner_id": 5,
        //     "product_id": 0,
        //     "source": source,
        //     "verification_source": verification_source,
        //     "verified_by": verified_by,
        //     "verifier_name": verifier_name
        // }
        let digiDec = await AAAPIInstance(obj)
        // let digiDec = await apiInstance
        // .post(`bsadigi-getstatus`, payLoad, {
        //     "token": token
        // })
        // .then((res) => {
        // console.log(res.data);
        if ((digiDec.status == '1') && (digiDec.status_code == '200')) {
            gettingerror('hit bsadigi web', mw_application_id)
            leadSourceTesting()

        }
        else {
            gettingerror('bsadigi error api web', mw_application_id)
            if (window.sessionStorage.getItem("count") == 1) {
                setTimeout(() => {
                    window.sessionStorage.setItem("count", 2);
                    digitapGetStatus();
                }, 10000);
            }
            else {
                if (window.location.search.includes("app=MQ==")) {
                    window.location.href = `${react_base_url}analyzer/sorry`;
                }
                else {
                    window.sessionStorage.clear();
                    let qry = Base64.encode(`${mw_application_id}`);
                    qry = Base64.encode(qry);
                    window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18`;
                }

            }
        }

        // }).catch((err) => {
        //     setRocket(true);
        // })
    }

    React.useEffect(() => {
        if ((token !== "") && (mw_application_id !== "") && (credentialState.cust_id !== "")) {
            let qry = queryString.parse(window.location.search);
            if (qry.transID) {
                setTimeout(() => {
                    window.sessionStorage.setItem("count", 1);
                    digitapGetStatus();
                }, 10000);
            }
            else if ((qry.con_handle) || (qry.ecres) || (qry.resdate) || (qry.fi) || (qry.plmo_handle)) {
                let con_handle = qry.con_handle
                con_handle = con_handle.replace("b'", "");
                con_handle = con_handle.replace("'", "");
                let plmo_handle = "";
                if (qry.plmo_handle) {
                    plmo_handle = qry.plmo_handle;
                    plmo_handle = plmo_handle.replace("b'", "");
                    plmo_handle = plmo_handle.replace("'", "")
                }
                setFinProData({ ...finProData, con_handle: con_handle, ecres: qry.ecres, resdate: qry.resdate, fi: qry.fi, plmo_handle: plmo_handle })
            }
            else {
                leadSourceTesting();

            }
        }

    }, [token, mw_application_id, credentialState.cust_id])

    React.useEffect(() => {
        if (mw_application_id && (mw_application_id !== "") && credentialState.cust_id && (credentialState.cust_id !== "") && ((finProData.con_handle) || (finProData.ecres) || (finProData.resdate) || (finProData.fi) || (finProData.plmo_handle))) {
            if ((finProData.con_handle) && (finProData.ecres) && (finProData.resdate) && (finProData.fi)) {
                FinproDecryptAPi();
            }
        }
    }, [mw_application_id, credentialState, finProData])

    const handleError = (e) => {
        window.location.reload();
    }

    return (
        <div>
            {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
            {state ?
                <CKYCWaiting heading={"We are redirecting you to the next page."} />
                :
                <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
            }
        </div>
    )
}

export default BsaRedirect;