import React, { useEffect } from "react";
import Lottie from "react-lottie-player";
import lottieJson from "../../../assests/lootie-files/successful.json";
import { useSelector } from "react-redux";

const Thankyou = ({ content, heading }) => {
  const { setService } = useSelector((state) => state);
  const str = setService.name;
  const arr = str?str.split(" "):[];
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
}
const str2 = arr.join(" ");
 
  return (
    <>
      <div className="thank-cont">
        <div className="thank-popup-lottie">
          <Lottie
            loop
            animationData={lottieJson}
            play 
          />
        </div>
        {setService.name !== "" ? (
          <p className="thank-p">Hi {str2}</p>
        ) : (
          <p className="thank-p">Hi</p>
        )}
        {setService.thankMessage !== "" ? (
          <p className="thank-p1">{setService.thankMessage}</p>
        ) : (
          <p className="thank-p1">your request has been submitted</p>
        )}
        <p className="thank-regards">Regards<br/>Team MoneyWide</p>
        {/* <p className="thank-regards"></p> */}
      </div>
    </>
  );
};

export default Thankyou;
