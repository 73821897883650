import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GenerateToken, updatemwpages } from "../../Redux/action";
import { getToken, getMetatags } from "../EverCalledFunctions/EverCalledApis";

const HittingMetaTagApi = () => {
  const { token, mwpages } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const myToken = async () => {
      const tkn = await getToken();
      dispatch(GenerateToken(tkn));
    };

    if (token === "") {
      myToken();
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const meta = async () => {
      let url = window.location.pathname;
      if (url.includes("/")) {
        // url = url.replaceAll("/", "");
        url = url.replace(/\//g, "");
      }
      const MetaData = await getMetatags(token, url);

      if (MetaData.status === "1" && MetaData.status_code === "200") {
        dispatch(
          updatemwpages({
            ...mwpages,
            bannerdata: MetaData.data.bannerData,
            faqdata: MetaData.data.faqData,
            metatagsdata: MetaData.data.metatagsData[0],
          })
        );
      }
    };

    if (token !== "") {
      meta();
    }
  }, [token]);

  return <div></div>;
};

export default HittingMetaTagApi;
