import React from "react";
import "./announce.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken } from "../../Redux/action";
import {
  prodbase_url,
  source,
  verification_source,
  verified_by,
  verifier_name,
} from "../Secret_Credentials/Secret_Credential";
import { SlickNext } from "./SlickNext";
import { SlickPrev } from "./SlickPrev";
// import { decryptWithHybrid, encryptWithHybrid } from "../../Keys";
import { apiInstance } from "../Config/APIConfig";

const MwAnnouncement = () => {
  const { token } = useSelector((state) => state);
  const dispatch = useDispatch();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, []);

  const encryptedPayload = async () => {
    let payLoad = {
      source: source,
      verification_source: verification_source,
      verified_by: verified_by,
      verifier_name: verifier_name,
    }
    // let payload = await encryptWithHybrid(JSON.stringify(payLoad));
    let dec = await apiInstance
      .post(
        `announcement`,
        payLoad,
        {
          token,
        }
      )
      // .then((res) => {
        // let dec_data = decryptWithHybrid(res.data.encryptedMessage, res.data.encryptedAesKey, res.data.iv)
        if (dec.status === "1" && dec.status_code === "200") {
          SetData(dec.data.result);
        }
      // });
  }

  React.useEffect(() => {
    if (token !== "") {
      encryptedPayload();
    }
  }, [token]);

  const [data, SetData] = React.useState([]);

  var sliderblog = {
    infinite: true,
    autoplay: true,
    cssEase: "ease-in-out",
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SlickNext />,
    prevArrow: <SlickPrev />,
    mobileFirst:true,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          dots: false
        }
      },
    ],
  };

  return (
    <>
      <div className="mw-annouce">
        {data ? (
          <div className="mw-announce-cont">
            <div className="annoucement-heading ">
              <h2>
                MoneyWide Announcements
              </h2>
            </div>
            <Slider {...sliderblog}>
              {data.map((el, idx) => {
                return (
                  <div key={idx} className="mw-announce-cont-1">

                    <div className="mw-announce-card announce-bg">
                      <img className="announce-bg-top" src={el.image1} alt="not found" />
                      <div className="contentMain">
                        <div className="mw-announce-card-img">
                          <img src={el.icon} alt="not found" />
                        </div>
                        <div className="mw-announce-card-desc">
                          <p>{el.description}</p>
                          <p className="date">{el.date}</p>
                        </div>
                      </div>
                      <img className="announce-bg-bottom" src={el.image2} alt="not found" />
                    </div>

                  </div>
                );
              })}
            </Slider>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default MwAnnouncement;
