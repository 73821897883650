import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./career.css";
import { BlueArr } from "../../Icons/CodeIcons/codeicons";
import { react_base_url } from "../Secret_Credentials/Secret_Credential";


const CareerSlider = ({ updateSelectedData, data }) => {

    const NextArrow = ({ onClick }) => {
        return <button type="button" onClick={onClick} className="slick-next rotate-Left">
            <div>
                <BlueArr w={28} h={28} />
            </div>
        </button>
    }
    const PreviousArrow = ({ onClick }) => {
        return <button type="button" onClick={onClick} className="slick-prev rotate-Right">
            <div>
                <BlueArr w={28} h={28} />
            </div>
        </button>
    }
    var sliderblog = {
        infinite: false,
        autoplay: true,
        cssEase: 'ease-in-out',
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: < PreviousArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false,
                    speed: 500
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                }
            }
        ]
    };

    const returnTwoHunderWords = (arrayOf) => {
        var str = "";
        if(!arrayOf || !arrayOf.length){
            return str;
        }
        for (var i = 0; i < arrayOf.length; i++) {
            str+=arrayOf[i]
            if(str.length>200){
                return str.slice(0,140);
            }
        }
        return str;

    }

    return (
        <>
            <div className="career-slider-main">
                {<div className="career-slider-boxing">
                    <Slider {...sliderblog}>
                        {data.map((row, index) => (
                            <div key={index} className="career-slides">
                                <div className="career-slider-card" onClick={() => updateSelectedData(row)}>

                                    <p>
                                        <span>{row.profile}</span>
                                    </p>
                                    <p>
                                        <span>{row.function}</span>
                                    </p>
                                    <p>
                                        <span>{row.description[row.id]?returnTwoHunderWords(row.description[row.id])+"...":""}</span>
                                    </p>

                                    <p>Apply Now</p>
                                    <div className="top-left-des">
                                        <img src={`${react_base_url}assets/images/Careers/CareerSliderTopLeft.svg`} alt="" />
                                    </div>
                                    <div className="bottom-right-des">
                                        <img src={`${react_base_url}assets/images/Careers/CareerSliderBottomRight.svg`} alt="" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>}
            </div>
        </>
    );
};

export default CareerSlider;
