import React, { useEffect } from 'react';
import "./LoanDetails.css";
import LoanDetails from './LoanDetails';
import back from '../../Icons/back.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../EverCalledFunctions/EverCalledApis';
import { GenerateToken } from '../../Redux/action';
import { GetRepaymentDetailsAPI, formatDate, getLoginData } from './MyAccountAPIs';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';

const Repayment = () => {
    const [scroller, updateScroller] = React.useState(0)
    const [loader, setLoader] = React.useState(true)
    const [APIErr, setAPIErr] = React.useState(false)
    const [mw, setMW] = React.useState("")
    const [loanData, setLoanData] = useState({})
    const { token } = useSelector(state => state)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const moveLeft = (e) => {
        e.preventDefault()
        const scroll = document.getElementById('kfs-table')
        scroll.scrollLeft -= 185;
        updateScroller(scroll.scrollLeft)
    }

    const moveRight = (e) => {
        e.preventDefault()
        const scroll = document.getElementById('kfs-table')
        scroll.scrollLeft += 185;
        updateScroller(scroll.scrollLeft)
    }

    const getUserData = async (mw_app_id) => {
        const APIResponse = await GetRepaymentDetailsAPI(mw_app_id, token);
        if (APIResponse.status_code === "200" && APIResponse.status === "1") {
            setLoanData(APIResponse.data)
            setLoader(false)
        } else {
            setLoader(false)
            setAPIErr(true)
        }
    }

    useEffect(() => {
        if (token !== "") {
            let payLoad = getLoginData()
            if (!payLoad && !payLoad.mw_app_id) {
                navigate('/login')
            }
            else if (payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") {
                getUserData(payLoad.mw_app_id);
                setMW(payLoad.mw_app_id)
            }
        }
    }, [token])



    const myToken = async () => {
        const T = await getToken();
        dispatch(GenerateToken(T))
    }

    useEffect(() => {
        if (token === "") {
            myToken()
        }
    }, [])



    const manageRefresh = () => {
        setLoader(true)
        setAPIErr(false)
        let payLoad = getLoginData()
        if (!payLoad && !payLoad.mw_app_id) {
            navigate('/login')
        }
        else if (payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") {
            getUserData(payLoad.mw_app_id);
            setMW(payLoad.mw_app_id)
        }
    }
    return (
        <>
            {APIErr && !loader && <NewErrorCase handleClick={manageRefresh} />}
            {loader && <CKYCWaiting />}
            {!loader && !APIErr &&
                <div className='transaction-main'>
                    <div className='trasaction-head'>
                        <a href="/myaccount/myloan"><img className="" src={back} alt="back-img" /></a>
                        <span>Repayment Schedule</span>
                    </div>
                    <LoanDetails data={loanData} />
                    <div className="myacc-repayment-table">
                        <div className="repayment-table-heaing">
                            Repayment Schedule
                        </div>
                        <div className="myacc-repayment-btns">
                            <button className={"myacc-prev" + (scroller === 0 ? " my-acc-dis" : "")} onClick={(e) => moveLeft(e)}>
                                <i className={"arrow right"} style={{ width: "1px", height: "1px", marginLeft: "0", marginBottom: "-2px" }}></i>
                                Previous
                            </button>
                            <button className={"myacc-next" + (scroller >= (9 * (loanData.repaymentSchedule.periods.length) - 300) ? " my-acc-dis" : " ")} onClick={(e) => moveRight(e)}>
                                Next
                                <i className={"arrow left"} style={{ width: "1px", height: "1px", marginLeft: "0", marginBottom: "-2px" }}></i>
                            </button>
                        </div>
                        <table id='kfs-table'>
                            <thead>

                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Date</th>
                                    <th>Balance Principal</th>
                                    <th>Principal</th>
                                    <th>Interest</th>
                                    <th>EMI</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loanData.repaymentSchedule && loanData.repaymentSchedule.periods &&
                                    loanData.repaymentSchedule.periods.map((row, ind) => {
                                        return ind !== 0 && <tr key={ind}>
                                            <td>{ind}</td>
                                            <td>{formatDate(row.dueDate)}</td>
                                            <td>{row?.principalLoanBalanceOutstanding?.toLocaleString('en-IN')}</td>
                                            <td>{row?.principalOriginalDue?.toLocaleString('en-IN')}</td>
                                            <td>{row?.totalActualCostOfLoanForPeriod?.toLocaleString('en-IN')}</td>
                                            <td>{row?.totalDueForPeriod?.toLocaleString('en-IN')}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>}
        </>
    )
}

export default Repayment