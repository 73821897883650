import React, { useEffect } from 'react'
//import "./NewCreditPL.css";
import "../NewCreditPersonalLoan/NewCreditPL.css"
import '../PersonalLoan/PersonalLoan.css';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import MobileFooter from '../Footer/MobileFooter';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import FeaturesBenefits from '../PersonalLoan/FeaturesBenefits';
import Helmet from 'react-helmet';
import FAQSectionChk from "../FAQ/FAQ";
import StepSlider from '../HowToApplyPLSLIDER/StepSlider';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Link } from 'react-router-dom';
import greenticknew from '../../Icons/greenticknew.svg';
import EligibilityCriteria from '../PersonalLoan/images/eligibility-criteria.webp';
import EligibilityCriteriaSection from '../PersonalLoan/EligibilityCriteria';


const PreApprovedPl = () => {
    const { mwpages } = useSelector((state) => state);
    const [data, setData] = React.useState({ sub_bread: "Pre Approved Personal Loan" })

    var loan_type_id_page = 56;
    const dispatch = useDispatch()
    const { PLLoader, formStates } = useSelector(state => state)
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY >= 2400) {
            sety(false);
        }
        else {
            sety(true);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])


    const datafandb= {
        givendata:[
        {
        span:"No income  ",
        para:"documents required",
    },
    {
        span:"100% digital",
        para:"Instant Personal Loan",
    },
    {
        span:"Same day",
        para:"loan disbursement",
    },
    {
        span:"Loan amount ranging from",
        para:"Rs. 20,000 to Rs. 3 Lakhs",
    },
    {
        span:"Flexible repayment tenure",
        para:" upto 36 months",
    },
    {
        span:" No collateral required",
        para:"to apply for a loan",
    }
    ]
    }
    const dataa = {
        para:"To be eligible for a pre-approved Personal Loan, the borrower must meet specific criteria. These may include:",
        heading: [
            {
                left: "Eligibility Criteria for ",
                right: "Pre Approved Personal Loan"
            }
        ],
        subhead: [
            {
                head: "Age",
                content: "You must be between 23 - 55 years of age."
            },
            {
                head: "Employment stability",
                content: "The borrower must have a stable job and should have worked with the current employer for at least six months to a year. "
            },
            {
                head: "A good credit score",
                content: "Borrower should have a minimum of 675 or above to be eligible for a pre-approved personal loan."
            },
            {
                head:"Minimum Income ",
                content:"The minimum income for an instant Personal Loan is ₹ 20,000."
            }
        ]
    }

    return (
        <>
            {/* {loader ?<CKYCWaiting/>:""} */}
            {/* {rocket ? <NewErrorCase handleClick={()=>meta()}/>:""} */}
            <div>
                <HittingMetaTagApi />
            </div>

            <Helmet>
                <title>{mwpages.metatagsdata?.page_title}</title>
                <meta name="description" content={mwpages.metatagsdata?.page_description} />
                <meta name="keywords" content={mwpages.metatagsdata?.page_keywords} />
            </Helmet>

            <div className='journey-stop'>
                <NewLoginForm pageType={loan_type_id_page} mapData={data} />
                <div className='what-is ploan-content1  manage-top-margin mob-margin-top web-margin-top'>
                    {y && <RightSidePopUp />}
                    <h2 className="heading-h2">What is a Pre-Approved Personal Loan?</h2>
                    <p className="heading-p">A pre-approved <Link className='send-lnk' to="/personal-loan"><b>Personal Loan</b></Link> refers to a loan offer extended to an individual based on an initial evaluation of their creditworthiness and financial history. Being a pre-approved customer means you don't need to follow a lengthy application process. You can get a loan with minimal documentation.</p>

                    <p className="heading-p">If you're looking for a quick and hassle-free way to borrow money, MoneyWide gives you a pre-approved Personal loan ranging from Rs. 20,000 to 3 Lakhs at affordable-interest rates and zero hidden charges. The entire process takes less than 5 minutes for approval and instant disbursal. You can also apply using the <a href="https://play.google.com/store/apps/details?id=com.app.mlcfinotech" className='send-lnk' ><b>MoneyWide app</b></a> for a quick loan process with minimum documentation, part-payment, and foreclosure.</p>
                </div>

                <EligibilityCriteriaSection elgdata={dataa} />
                <FeaturesBenefits features={datafandb}/>
                <section className="pl-charges newcredtopmargin">
                    <div className="ploan-contanier ourproducts-main">
                        <div className="box-sh">
                            <div className="eligibility-criteria-box new-cred-main">
                                <div className="product-heading nwprod-heading">
                                    <p>Pre Approved</p>
                                    <span>Personal Loan</span>
                                    <p>Interest Rates and Other Charges</p>
                                </div>
                                <div className="instant-box nw-cred-box">
                                    <div className="instant-head-cont">
                                        <div className="instant-head">
                                            <span>Pre Approved Personal Loan</span>
                                        </div>
                                        <div className="instant-contnt">
                                            <div className="instant-inner nwinstant">
                                                <span>Interest Rates:</span>
                                                <p>18% to 30%</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Processing Fees:</span>
                                                <p>3.75% of loan amount</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Partpayment Charges:</span>
                                                <p>Allowed after 1 EMI at 4%</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Foreclosure Charges:</span>
                                                <p>Allowed after 1 EMI at 4%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="instant-bottom">
                                        <a href='#'>Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <StepSlider />
                {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                    <FAQSectionChk data={mwpages.faqdata} />
                </section> : ""}
            </div>
            <div className='journey-start' style={{ display: "none" }}>
                {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                {(!formStates.IsIframe) && <MobileFooter />}
            </div>
        </>
    )
}

export default PreApprovedPl;