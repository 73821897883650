import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from "../assests/lootie-files/successful.json";
import '../Popups/Thankyou/thank.css';

const ThankyouPopupPartner = ({ content }) => {
   const [continueNextSection, setcontinueNextSection] = useState(false);
   const [timer,setTimer] = useState(4);
   const [heading, setHeading] = useState("")

   useEffect(()=>{
      if(window.location.href.includes("Mk1nPT0")){
         setHeading("Your e-sign has already been done.")
      }
      else{
         setHeading("Your e-sign have been done successfully.")
      }
   },[])

   return (
      <>
         <div className={continueNextSection ? "loaderbackground remove-block" : "loaderbackground"}>
            <div className='loadertransparent'>
               <div className='thank-container'>
                  <div className='thank-lotie'>
                     <Lottie loop animationData={lottieJson} play />
                  </div>
                  <p className='thank-p'>{heading}</p>
                  {content && <p className='thank-p1'>{content}</p>}
               </div>
            </div>
         </div>
      </>
   )
}

export default ThankyouPopupPartner

