import React from 'react';
import './MyAccount.css';
import NoLoan from '../../Icons/NoLoan.svg';
import logo from '../../Icons/moneywide-logo.svg';
// import edit from '../../Icons/edit.svg';
import { useEffect } from 'react';
import { useState } from 'react';
import { TrackAPPAPI, getLoginData } from './MyAccountAPIs';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../EverCalledFunctions/EverCalledApis';
import { GenerateToken } from '../../Redux/action';
import { useNavigate } from 'react-router-dom';
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';

const TrackApplication = () => {

    const [trackappdata, setTrackAppData] = useState({})
    const [trackloader, setTrackLoader] = useState(true)
    const [noTrackData, setNoTrackData] = useState(false)
    const [APIErr, setAPIErr] = useState(false)
    const { token } = useSelector(state => state)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const trackAPIData = async (num) => {
        const APIResponse = await TrackAPPAPI(num,token)
        if (APIResponse.status_code === "200" && APIResponse.status === "1") {
            setTrackAppData(APIResponse.data)
            setTrackLoader(false)
        }
        else if (APIResponse.status_code === "429" && APIResponse.status === "0") {
            setTrackLoader(false)
            setNoTrackData(true)
        }else{  
            setAPIErr(true)
            setTrackLoader(false)
        }
    }

    useEffect(() => {
        if (token !== "") {
            var payLoad = getLoginData();
            if (!payLoad && payLoad.userNo) {
                navigate('/login')
            } else if (payLoad.userNo != undefined || payLoad.userNo !== "") {
                trackAPIData(payLoad.userNo)
            }
        }
    }, [token])


    const myToken = async () => {
        const T = await getToken();
        dispatch(GenerateToken(T))
    }

    useEffect(() => {
        if (token === "") {
            myToken()
        }
    }, [])

    const manageRefresh =()=>{
        setTrackLoader(true)
        setAPIErr(false)
        let payLoad = getLoginData()
        if (!payLoad && !payLoad.userNo) {
            navigate('/login')
        } else if (payLoad.userNo != undefined || payLoad.userNo !== "") {
            trackAPIData(payLoad.userNo)
        }
    }
    return (
        <>
        {APIErr && !trackloader && <NewErrorCase handleClick={manageRefresh}/>}
            {trackloader && <CKYCWaiting />}
            <h3 className="myAccountHeading">Track Application</h3>
            {noTrackData && <div className="myLoanBox">
                <div>
                    <img src={NoLoan} alt="" />
                </div>
                <p>You don’t have any active loan/Healthcare credit yet</p>
            </div>}
            {!APIErr && Object.keys(trackappdata).length!==0 && <div className='looping-class'>
                {trackappdata.map((row, index) => {
                    return <div key={index} className="mainContent-box">
                        <div className="border-box">
                            <div className="track-row">
                                <div className="content-area">
                                    <img src={logo} alt="" />
                                </div>
                                <div className="content-area myc-mobile">
                                    {row.status_display === "In Process" ?
                                    <div className='contentData yellow-btn'>{row.status_display}</div>:
                                    row.status_display === "Duplicate" ?
                                    <div className='contentData red-btn'>{row.status_display}</div>:
                                    <div className='contentData green-btn'>{row.status_display}</div>}
                                </div>
                                <div className="content-area">
                                    <div className="contentHead">Program Name</div>
                                    <div className="contentData">{row.product ? row.product : "-"}</div>
                                </div>
                                <div className="content-area">
                                    <div className="contentHead">Applied Date</div>
                                    <div className="contentData">{row.created_on ? row.created_on : "-"}</div>
                                </div>
                                <div className="content-area">
                                    <div className="contentHead">Application ID</div>
                                    <div className="contentData">{row.lan_no ? row.lan_no : "-"}</div>
                                </div>
                                <div className="content-area">
                                    <div className="contentHead">Applied Amount</div>
                                    <div className="contentData">{row.loan_amt ? row.loan_amt : "-"}</div>
                                </div>
                                <div className="content-area myc-desktop">
                                    <div className="contentHead">Status</div>
                                    {row.status_display === "In Process" ?
                                        <div className="contentData yellow-btn">{row.status_display}</div> :
                                        row.status_display === "Duplicate" ?
                                            <div className="contentData red-btn">{row.status_display}</div> :
                                            <div className="contentData green-btn">{row.status_display}</div>}
                                </div>
                            </div>
                            <div className="track-btn">
                                {row.url_link && <a href={row.url_link} target="_blank">Resume Application</a>}
                            </div>
                        </div>
                    </div>
                })}
            </div>}
            {/* <div className='looping-class'>
                <div className="mainContent-box">
                    <div className="border-box">
                        <div className="track-row">
                            <div className="content-area">
                                <img src={logo} alt="" />
                            </div>
                            <div className="content-area myc-mobile">
                                <div className="contentHead">Status</div>
                                <div className="contentData yellow-btn">In Process</div>
                            </div>

                            <div className="content-area">
                                <div className="contentHead">Program Name</div>
                                <div className="contentData">Personal Loan</div>
                            </div>
                            <div className="content-area">
                                <div className="contentHead">Applied Date</div>
                                <div className="contentData">24-08-2022</div>
                            </div>
                            <div className="content-area">
                                <div className="contentHead">Application ID</div>
                                <div className="contentData">L202208243910600</div>
                            </div>
                            <div className="content-area">
                                <div className="contentHead">Applied Amount</div>
                                <div className="contentData">Rs. 50,000</div>
                            </div>
                            <div className="content-area myc-desktop">
                                <div className="contentHead">Status</div>
                                <div className="contentData yellow-btn">In Process</div>
                            </div>
                        </div>
                        <div className="track-btn">
                            <button>Resume Application</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default TrackApplication;