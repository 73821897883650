import React from 'react'

const BlogCard = ({imagepath,heading,date,time,views,content,url}) => {
  return (
   <>
   <a style={{textDecoration:"none"}} href={url}>
   <div className='blog-card-main-mobile-vw'>
   <div className='blog-card-main'>
    <div className='blogimage'>
        <img  src={imagepath}/>
    </div>
    <div className='blogdescption'>
        <h2 style={{marginTop:"0px"}}>{heading}</h2>
        <p>{content}</p>
        <div className='read-btn'>Read More</div>
        <div className='blogtrack'>
            <p>{date}</p>
            <p>{time} MIN READ</p>
            <p>{views} VIEWS</p>
        </div>
    </div>
   </div>
   </div>
   </a>
   </>
  )
}

export default BlogCard