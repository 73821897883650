import React from "react";
import './EmbeddedSlider.css'
import offerhand from '../../Icons/offer-hand.svg'
import integration from '../../Icons/intergration.svg'
import disburse from '../../Icons/disbursment.svg'
import repayment from '../../Icons/repayment.svg'


const EmbeddedMobileSlider = () => {
    return (
        <>
            <div className="mobile-slider-main">
                <div className="simple-steps-heading"><h2>Get Started in 4 Simple Steps</h2></div>
                <div className="mobile-slider-sub">
                    <div className="offer-mobile-embed">
                        <div className="sub-left">
                            <img src={offerhand} alt="offerhand" />
                        </div>
                        <div className="sub-right">
                            <span>Offer Eligibility Check</span>
                            <p>Checking the eligibility of users based on data shared by a partner.</p>
                        </div>
                    </div>
                    <div className="offer-mobile-embed">
                        <div className="sub-left">
                            <img src={integration} alt="offerhand" />
                        </div>
                        <div className="sub-right">
                            <span>Onboarding & Integration</span>
                            <p>If eligible, complete the KYC verification and address verification.</p>
                        </div>
                    </div>
                    <div className="offer-mobile-embed">
                        <div className="sub-left">
                            <img src={disburse} alt="offerhand" />
                        </div>
                        <div className="sub-right">
                            <span>Disbursement</span>
                            <p>Credit is finally disbursed to the bank account without any hassle.</p>
                        </div>
                    </div>
                    <div className="offer-mobile-embed">
                        <div className="sub-left">
                            <img src={repayment} alt="offerhand" />
                        </div>
                        <div className="sub-right">
                            <span>Flexible Repayment</span>
                            <p>The amount is paid off by a smart repayment set-up digitally.</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )

}

export default EmbeddedMobileSlider;
