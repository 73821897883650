import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./login.css";
import phone from "../../../Icons/phone.svg";
// import whatsapp from "../../../Icons/whatsapp.svg";
import { useDispatch, useSelector } from "react-redux";
import { change_personal_loan_page, GenerateToken, updateCredentialsState, updateLoginDetails, } from "../../../Redux/action";
import Lottie from 'react-lottie-player';
import ctaAnimation from '../../assests/lootie-files/continue.json';
import { client_id, client_key, source } from "../../Secret_Credentials/Secret_Credential";
import { getToken, SendOTP } from "../../EverCalledFunctions/EverCalledApis";
import NewErrorCase from "./All_Forms/NewErrorCase";
import { gettingerror } from "../../EverCalledFunctions/EverCalledFunctions";

const NumberLogIn = ({ loan_type }) => {
  const [resp, setRes] = useState(false)
  const { personal_loan, loginDetails, credentialState, token } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [whatsappToggel, setWhatsappToggel] = useState(1);
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(true);
  const [device_id, setDevice_id] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("")
  const [rocket, setRocket] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue
  } = useForm({
    defaultValues: {
      mobile: "",
      whatsapp: "",
    }, mode: "onBlur"
  });
  const data = watch();

  function knowdevice() {
    if (window.innerWidth > 1024) {
      setDevice_id("1");
    }
    else if (window.innerWidth > 480 && window.innerWidth < 1024) {
      setDevice_id("3")
    }
    else {
      setDevice_id("2");
    }
  }

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }


  useEffect(() => {
    if (token == "") {
      myToken();
    }
    knowdevice();

  }, [])



  useEffect(() => {
    var regexp = /[^0-9]/g;
    if (data.mobile && data.mobile.length !== 10) {
      setWhatsappNumber("")
      setShow(false)
    }
    else if (data.mobile && data.mobile.length === 10 && !errors.mobile && !data.mobile.match(regexp) && whatsappNumber !== data.mobile) {
      document.getElementById("login-input").blur();
      if (window.screen.width > 750) {
        document.getElementById("last").focus();
      }
      setWhatsappNumber(data.mobile)
      // checkSubscription(data.mobile)
    }
    else if (whatsappNumber === data.mobile && !errors.mobile && !data.mobile.match(regexp)) {
      setShow(show)
    }
    else {
      setShow(false);
    }

  }, [data]);

  // const checkSubscription = async (number) => {
  //   const check = NumberOnly(number);
  //   if (check) {
  //     const APIDATA = await WhatsAppSubscription(number, token)
  //     if (APIDATA.status_code === "200" && APIDATA.status === "1") {
  //       if (APIDATA.data.is_whatsapp_subscribed === 0) {
  //         console.log(window.screen.availWidth)
  //         if (window.screen.width < 430) {
  //           let ele = document.getElementById('about-pl-after-form')
  //           if (ele) {
  //             ele.style.marginTop = "260px";
  //           }
  //         }
  //         setShow(true);
  //       } else {
  //         setShow(false)
  //         if (window.screen.width < 430) {
  //           let ele = document.getElementById('about-pl-after-form')
  //           if (ele) {
  //             ele.style.marginTop = "250px";
  //           }
  //         }
  //       }
  //     }
  //   }
  // }


  const onSubmit = async (data) => {
    setRes(true)
    dispatch(updateLoginDetails({ ...loginDetails, mobile: data.mobile, whatsapp: whatsappToggel }));
    delete data.whatsapp
    data = {
      ...data,
      login_type: "OTP",
      source_from: 1,
      api_mode: "LIVE",
      login_from: 1,
      device_type: +(device_id),
      customer_id: 0,
      lead_id: 0
    };
    const payload = {
      client_id: client_id,
      client_key: client_key,
      source: source,
    };

    const APIDATA = await SendOTP(data, "");
    if (APIDATA.status_code === "200" && APIDATA.status === "1") {
      gettingerror('send otp web',data.mobile)
      dispatch(change_personal_loan_page({ ...personal_loan, otp: true, count: APIDATA.data.count }));
      dispatch(updateCredentialsState({ ...credentialState, otpId: APIDATA.data.otp_id, count: APIDATA.data.count }));
    } else {
      setRocket(true);
    }
  };



  useEffect(() => {
    if (data.mobile) {
      if (6 <= data.mobile.charAt(0) && data.mobile.charAt(0) <= 9) {
        clearErrors('mobile')
      }
      else {
        setError('mobile', { type: 'custom' });
      }
    }
  }, [data.mobile])


  // const updateWhatsApp = () => {
  //   if (whatsappToggel) {
  //     setWhatsappToggel(0)
  //   } else {
  //     setWhatsappToggel(1)
  //   }
  // }

  useEffect(() => {
    if (whatsappToggel) {
      setValue('whatsapp', 1)
    } else {
      setValue('whatsapp', 0)
    }
  }, [whatsappToggel])


  const NumberOnly = (value) => {
    var lastseven = value.substr(value.length - 7);
    var num_charstring_number = value.substr(4, 4);
    var last_digit = value.slice(0, 2);
    var cr_str = Array(6).join(last_digit);
    var result = false;
    if (/^[6789]\d{9}$/i.test(value)) {
      result = true;
    }
    if (value === '9876543210') {
      result = false;
    }
    if (/^(\d)\1+$/.test(lastseven)) {
      result = false;
    }
    if (value === cr_str) {
      result = false;
    }
    if (num_charstring_number == 'XXXX') {
      result = true;
    }
    if (/(\d)\1{6,}/.test(value)) {
      result = false;
    }
    return result;

  }

  const handleUpKey = (value, field) => {
    var regexp = /[^0-9]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""))
    }
  }

  const handleCheckBox = () => {
    if (!resp) {
      setChecked(!checked)
    }
  }

  const handleError = (e) => {
    window.location.reload();
  }


  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      <div className="log-form">
        <div className="login-form-head">Apply for {loan_type} Online</div>
        {/* <div className="form-subHead logoni" >Let us help you find the most suitable offers</div> */}
        <div className="login-form-part">
          <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
            <div
              className={"login-form-border" + ((errors.mobile && data.mobile) ? " red-error-border" : "")}>
              <img src={phone} alt=""/>
              <input minLength={10} maxLength={10}
                type="tel"
                autoComplete="off"
                id="login-input"
                placeholder="Mobile Number"
                {...register("mobile", {
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                  validate: NumberOnly
                })}
                onKeyUp={() => handleUpKey(data.mobile, "mobile")}
                autoFocus
                disabled={resp}
              />
              {errors.mobile && data.mobile && <div className="number-login-err">Enter a valid number</div>}
            </div>

            {/* {show ? (
              <div className="get-login-tag">
                <div className="left-tag-whatsaap">
                  <img src={whatsapp} />
                  <span>Get update on WhatsApp</span>
                </div>
                <label className="switch">
                  <input type="checkbox" onChange={() => updateWhatsApp()} checked={whatsappToggel} />
                  <span className="slider round"></span>
                </label>
              </div>
            ) : (
              ""
            )} */}
            <p className="login-form-content-under-whatsapp" onClick={() => handleCheckBox()}>
              <div className="login-whatsapp-check">
                <div><input type="checkbox" checked={checked} readOnly /></div>
                <div className="login-auth-text">
                  I authorize MoneyWide to verify my PAN, mobile no., contact via call/SMS/WhatsApp/Email &amp; access my credit report. I agree to the terms of the {" "}
                  <a href="/disclaimer" className="TandC-link" target={"_blank"}>
                    User Consent Form
                  </a>{", "}
                  <a href="/policies" className="TandC-link" target={"_blank"}>
                    Privacy Policy
                  </a>{" & "}
                  <a href="/terms-and-conditions" className="TandC-link" target={"_blank"}>
                    Terms and Conditions
                  </a>
                  .
                </div>
              </div>
            </p>
            <button id="last" disabled={resp || !checked} type="submit" className={"login-button" + (!errors.mobile && data.mobile.length === 10 && checked ? " login-btn-active" : " ")}>
              {!resp ? "Get OTP" : <div className='continue-lottie'>
                <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
              </div>}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NumberLogIn;