import React from 'react'
import "./Right.css";
import first from "../assests/whatright_1.webp";
import second from "../assests/whatright_2.webp";
import third from "../assests/whatsrigth_3.webp";
import four from "../assests/whatsright_4.webp";
function Right() {
  return (
    <>
       <div className="sachcont" >
          <div className="sachfirst">
            <h2>Choose what’s right for you!</h2>
          </div>
          <div className="sachsecond">
              <div className="sachthird">
                <div className="sachpicker">
                    <img className="sachfix" height={70} width={70} src={first} alt=""/>
                </div>
                <p className="sachfixer">Completely Digital - No paperwork needed.</p>
              </div>
              <div className="sachthird">
              <div className="sachpicker">
              <img className="sachfix" src={second} alt=""/>
              </div>
              <p className="sachfixer">Minimum KYC & Income Documents Required</p>
              </div>
              <div className="sachthird">
              <div className="sachpicker">
              <img className="sachfix" src={third} alt=""/>
              </div>
              <p className="sachfixer"> Instant Money in your Bank Account</p>
              </div>
              <div className="sachthird">
              <div className="sachpicker">
              <img className="sachfix" src={four} alt=""/>
              </div>
              <p className="sachfixer">Flexibility to partpay or foreclose loan after 1st EMI</p>
              </div>
          </div>
       </div>
    </>
  );
}

export default Right;
