import React, { useEffect, useState } from 'react'
import "./LoanDetails.css"
import OverDueAlert from './OverDueAlert'
import OverDueSuccess from './OverDueSuccess'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getLoginData, getOverDueData, GetRepaymentDetailsAPI } from './MyAccountAPIs'
import { GenerateToken } from '../../Redux/action'
import { getToken } from '../EverCalledFunctions/EverCalledApis'
import CKYCWaiting from '../LoginPopUp/CKYCWaiting'
import yellownotify from '../../Icons/yellownotify.svg'
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase'

const OverDueStatement = () => {
    const [mw_app_id, setMW_app_id] = useState("")
    const [userName, setUserName] = useState("")
    const [loader, setLoader] = useState(true)
    const [APIErr, setAPIErr] = useState(false)
    const [OverdueData, setOverdueData] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [baseData, setBaseData] = useState({
        program: "",
        Id: "",
        partial_flag:"",
        partial_link:""
    })


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { token } = useSelector(state => state)



    const getUserData = async (mw) => {
        const APIResponse = await getOverDueData(mw, token)
        if (APIResponse.status_code === "200" && APIResponse.status === "1") {
            setLoader(false)
            setOverdueData(APIResponse.data)
            setBaseData({ ...baseData, program: APIResponse.data.programName, Id: APIResponse.data.account_no, partial_flag: APIResponse.data?.partial_payment_flag, partial_link: APIResponse.data?.partial_payment_link })
        } else {
            setLoader(false)
            setAPIErr(true)
        }
    }
    useEffect(() => {
        if (token !== "") {
            let payLoad = getLoginData()
            if (!payLoad && !payLoad.mw_app_id) {
                navigate('/login')
            }
            else if ((payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") || (payLoad.userName != undefined && payLoad.userName !== "")) {
                if (payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") {
                    getUserData(payLoad.mw_app_id);
                    setMW_app_id(payLoad.mw_app_id)
                }
                if (payLoad.userName != undefined && payLoad.userName !== "") {
                    setUserName(payLoad.userName)
                }
            }
        }
    }, [token])



    const myToken = async () => {
        const T = await getToken();
        dispatch(GenerateToken(T))
    }

    useEffect(() => {
        if (token === "") {
            myToken()
        }
    }, [])


    const manageRefresh = () => {
        setLoader(true)
        setAPIErr(false)
        let payLoad = getLoginData()
        if (!payLoad && !payLoad.mw_app_id) {
            navigate('/login')
        }
        else if (payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") {
            getUserData(payLoad.mw_app_id);
        }
    }
    return (
        <>
            {APIErr && !loader && <NewErrorCase handleClick={manageRefresh} />}
            {loader && <CKYCWaiting />}
            {!loader && !APIErr && <>
                <div className='transaction-main'>
                    <div className='trasaction-head'>
                        <span>Overdue Payment</span>
                    </div>
                </div>
                {currentPage === 1 && <OverDueAlert name={userName} data={OverdueData} mw_app_id={mw_app_id} setCurrentPage={setCurrentPage} baseData={baseData} />}
                {currentPage === 2 && <OverDueSuccess name={userName} baseData={baseData} />}
                {currentPage === 3 && <div className="foreclose-yellow-box">
                    <img src={yellownotify} alt="yellownotify" />
                    <span>Dear <b>{userName}</b>, <br /> Sorry for the inconvenience. We are facing some technical error. Please try again after some time.<br />Regards <br />Team MoneyWide</span>
                </div>}
            </>
            }
        </>
    )
}

export default OverDueStatement