import React from 'react';
import './NewLeftPart.css';
import LeftSide from '../../../Icons/LeftSide.svg';
import Form from '../Form/Form';
import { updatePopUp, updateSuggestion_DropDown } from '../../../Redux/action';
import { useSelector, useDispatch } from 'react-redux';
import FinbitScreen from '../AipOffer/FinbitScreen';


const NewLeftPart = ({ title, content }) => {


  const dispatch = useDispatch();
  const { PopUpState, formStates, suggestionDropDown,App_status } = useSelector(state => state);

  const handleClick = (e) => {
    if(suggestionDropDown.show){
      dispatch(updateSuggestion_DropDown({...suggestionDropDown,show:false}))
    }
    if (PopUpState.DOBPopUp.isBubbling) {
      const updatedDOBPopUp = { ...PopUpState.DOBPopUp, isBubbling: false }
      dispatch(updatePopUp({ ...PopUpState, DOBPopUp: updatedDOBPopUp }))
    }
    else if (PopUpState.WorkExperiencePopUp.isBubbling) {
      const updated = { ...PopUpState.WorkExperiencePopUp, isBubbling: false }
      dispatch(updatePopUp({ ...PopUpState, WorkExperiencePopUp: updated }))
    }
    else if (PopUpState.TotalWorkExperiencePopUp.isBubbling) {
      const updated = { ...PopUpState.TotalWorkExperiencePopUp, isBubbling: false }
      dispatch(updatePopUp({ ...PopUpState, TotalWorkExperiencePopUp: updated }))
    }
    else {
      const updatedDOBPopUp = { ...PopUpState.DOBPopUp, show: false }
      const updatedWorkExperiencePopUp = { ...PopUpState.WorkExperiencePopUp, show: false }
      const updatedTotalWorkExperiencePopUp = { ...PopUpState.WorkExperiencePopUp, show: false }
      dispatch(updatePopUp({ ...PopUpState, DOBPopUp: updatedDOBPopUp, WorkExperiencePopUp: updatedWorkExperiencePopUp, TotalWorkExperiencePopUp: updatedTotalWorkExperiencePopUp }))
    }
    e.stopPropagation()
  }


  return (
    <>
      {!formStates.IsIframe ? (<div className={App_status ? "journey-part-mobile":"journey-part"} onClick={(e) => handleClick(e)}>
        <div className="journey-content">
          <div className="left-side">
            <h2 className="journey-heading">{title}</h2>
            <p className="journey-con">{content}</p>
            <div className="left-img"><img src={LeftSide} alt="" /></div>
          </div>
          <div className="right-side">
            <Form />
          </div>
        </div>
      </div>) :
        (formStates.FinbitScreen ? <FinbitScreen/> : (formStates.Vcip && <div>
          <h1>Hello Vcip</h1>
        </div>))}
    </>
  )
}

export default NewLeftPart;
