import React from 'react';
import '../../../LoginPopUp/LoginPopUp.css';
import ErrorRocket from '../../../../Icons/ErrorRocket.svg';
import { useSelector,useDispatch } from 'react-redux';
import { updateFormState } from '../../../../Redux/action';

const ErrorCase = () => {
    const {formStates} = useSelector(state=>state)
    const dispatch = useDispatch();
    const handleClick=()=>{
        dispatch(updateFormState({...formStates,error:false}))
    }
    return (
        <div className="blur-background">
            <div className="pop-content">
                <div className="main-popUP-form">
                    <div className="logo-img">
                        <img src={ErrorRocket} alt="" />
                    </div>
                    <div className="err-head">
                        <div className="err-heading">Error!</div>
                        <div className="err-content">We are currently facing some technical Issues. Please wait or try again after sometime</div>
                    </div>
                    <button className="refresh" onClick={()=>handleClick()}>TRY AGAIN</button>
                </div>
            </div>
        </div>
    )
}

export default ErrorCase;