import axios from "axios";
import React, { useEffect, useState } from "react";
import "./newfooter.css";
import logo from "./images/footer-logo.webp";
import googleplaystore from "../../Icons/googleplaystore.svg"
import facebook from "../../Icons/facebook.svg";
import twitter from "../../Icons/twitter.svg";
import youtube from "../../Icons/youtube.svg";
import instagram from "../../Icons/instagram.svg";
import linkedin from "../../Icons/linkdin.svg";
import mail from "./images/mail.svg";
import phone from "./images/phone.svg";
import indianflag from "./images/indianflag.webp";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken, updateMakePayment } from "../../Redux/action";
import {
  prodbase_url,
  react_base_url,
  source,
  verification_source,
  verified_by,
  verifier_name,
} from "../Secret_Credentials/Secret_Credential";
import { AAAPIInstance } from "../Config/APIConfig";


function NewFooter() {
  const { token, makePayment } = useSelector((state) => state);
  const [data, setData] = React.useState([]);
  const [hide, setHide] = React.useState('');
  const[bottomData, setBottomData]= useState("");
  const dispatch = useDispatch();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  const AppendCont = (NonSubMenu, append) => { };

  function func_footer(data) {
    var mainfooter = document.getElementById("main_footer");
    if (mainfooter) {
      var subMenu = data.filter((row) => {
        return row.sub_menu.length;
      });
      var NonSubMenu = data.filter((row) => {
        return row.sub_menu.length === 0;
      });
      var div = document.createElement("div"); //create dynamic div
      div.className = "non_submenu";

      NonSubMenu.map(function (row, index) {
        var divOne = document.createElement("div");
        divOne.setAttribute("className", "sub_footer");
        div.append(divOne);
        if (row.id == 31) {
          var span = document.createElement("span");
          span.style.color = "#D5D7DD"
          span.style.fontSize = "16px"
          span.style.fontFamily = "poppinsmedium"
          span.style.cursor = "pointer"
          span.innerText = row.header
          span.addEventListener('click', openMakePayment)
          divOne.append(span)
        } else {
          var a = document.createElement("a");
          a.href = `${react_base_url}${row.link}${window.location.search}`;
          a.innerText = `${row.header}`;
          divOne.append(a);
        }
      });

      // for (var i = 0; i < NonSubMenu.length; i++) {
      //     var a = document.createElement('a')
      //     a.href = `${react_base_url}${NonSubMenu[i].link}`
      //     a.innerText = `${NonSubMenu[i].header}`
      //     div.append(a)
      // }
      mainfooter.append(div); // append div
      func_sub_footer(subMenu);
    }

  }
  const openMakePayment = () => {
    dispatch(updateMakePayment({ ...makePayment, show: true }))
  }
  function func_sub_footer(data) {
    var mainfooter = document.getElementById("sub_footer");
    data.map((row, index) => {
      var div = document.createElement("div");
      if (row.id == "31") {
        div.addEventListener('click', openMakePayment)
      }
      div.className = "subhead";
      div.id = row.header + index;
      var divspan = document.createElement("div");
      divspan.className = "submenufooter";
      var img = document.createElement("img");
      img.src = `${react_base_url}assets/images/headerlogo/footerdroparw.svg`;
      img.style.width = "24px";
      img.style.height = "24px";
      img.className = "foterdownArow";
      var span = document.createElement("span");
      span.innerText = row.header;
      var lis = linksAppend(row.sub_menu);
      divspan.append(img, span);
      // div.append(img);
      // div.append(span)
      div.append(divspan, lis);
      mainfooter.append(div);
      div.addEventListener("click", function () {
        handleMobileClick(row, index);
      });
    });
    for (var i = 0; i < data.length; i++) { }
  }

  function handleMobileClick(row, index) {
    if (window.screen.width < 768 || window.innerWidth < 768) {
      var div = document.getElementById(row.header + index);
      var ul = div.getElementsByTagName("ul")[0];
      if (ul.style.display !== "block") {
        let submenu = document.getElementsByClassName("subhead");
        for (let i = 0; i < submenu.length; i++) {
          let entity = submenu[i].getElementsByTagName("ul")[0];
          entity.style.display = "none";
          var img = submenu[i].getElementsByTagName("img")[0];
          img.style.transform = "rotate(0deg)";
        }
      }



      if (ul.style.display !== "block") {
        ul.style.display = "block";
        var img = div.getElementsByClassName("foterdownArow")[0];
        img.style.transform = "rotate(180deg)";
        // img.classList.add('imgrotateup')
      }
      else {
        ul.style.display = "none";
        var img = div.getElementsByClassName("foterdownArow")[0];
        img.style.transform = "rotate(0deg)";
        // img.classList.remove('imgrotateup')
      }
    }
  }

  function linksAppend(data) {
    var ul = document.createElement("ul");
    for (var i = 0; i < data.length; i++) {
      var li = document.createElement("li");
      var a = document.createElement("a");
      a.innerText = data[i].header;
      // a.href = data[i].link;
      a.href = `${react_base_url}${data[i].link}${window.location.search}`
      li.append(a);
      ul.append(li);
    }
    return ul;
  }

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
    window.scrollTo(0, 0);
  }, []);

  const ApiFetch = async() => {
    let obj = {
      url: "api/fetch-footer-detail",
      data: {
          "verified_by": verified_by,
          "verifier_name": verifier_name,
          "verification_source": verification_source,
          "source": source
      },
      token: token
  }
  let dec = await AAAPIInstance(obj)
  if(dec.status == "1" && dec.status_code == "200"){
         func_footer(dec.data.fetch_footer_data);
          setBottomData(dec.data.bottom_data)
  }
  }


  
  React.useEffect(() => {
    if (token !== "") {
      setTimeout(() => {
        ApiFetch();
      }, 1000);
    }
  }, [token]);

  React.useEffect(() => {
    if (data.length) {
      data.map(function (row, index) {
        // console.log(row.header);
      });
    }
  }, [data]);
  // React.useEffect(() => {
  //     if (window.innerWidth < 750) {
  //         document.querySelector(".foot-mid-inner").style.dispaly = "none";
  //     }
  // }, [])

  return (
    <>
      <div className="NewFooter-bg">
        <div className="foot-first">
          <div className="foot-head" id="main_footer"></div>
          {/* <div className="sub_footer" id="sub_footer">
                        
                    </div> */}
        </div>
      </div>
      <div className="foot-mid-bg">
        <div className="foot-mid">
          <div className="sub_footer" id="sub_footer"></div>
        </div>
      </div>
     { bottomData.length != 0 && <div className="foot-base-bg">
        <div className="foot-base">
          <div className="foot-mw-adres">
            {/* <img src={logo} alt="" />
            <p>MLC Finotech Private Limited<br />
              Plot No-131, Sector-44,
              <br />
              Gurugram - 122003</p> */}
              <img src={bottomData[0]?.image} alt=""/>
              <p>{bottomData[0]?.content[0]?.heading}</p>
              <p>{bottomData[0]?.content[1]?.heading}</p>
              <p>{bottomData[0]?.content[2]?.heading}</p>
          </div>
          <div className="foot-mw-cntct">
            <div className="foot-ctct-head">
              {/* <span>Contact Information</span> */}
              <span>{bottomData[1]?.heading}</span>
            </div>
            <div className="foot-cntct-area">
              <div className="cntct-area-main">
                {/* <img src={mail} alt="mail" /> */}
                <img src={bottomData[1]?.content[0]?.image}/>
                <span>{bottomData[1]?.content[0]?.heading}</span>
                {/* <span>corporate@moneywide.com</span> */}
              </div>
              <div className="cntct-area-main">
                <img src={bottomData[1]?.content[1]?.image}/>
                <span>{bottomData[1]?.content[1]?.heading}</span>
                {/* <img src={phone} alt="phone" />
                <span>+91-9911862226</span> */}
              </div>
              <div className="cntct-area-main cntct-timing">
                {/* <span>(Monday to Friday- 10 AM to 6:30 PM & Saturday-10 AM to 5:00 PM)</span> */}
                <span>{bottomData[1]?.content[2]?.heading}</span>
              </div>
            </div>
          </div>
         
        </div>
      </div>}
      
      <div className="foot-copyright-bg">
        <div className="foot-copyright">
          <div className="foot-copy-txt">
            <span>
              Copyright © 2022. All Rights Reserved. MoneyWide is a registered trademark
            </span>
          </div>
          <div className="foot-flag-copy">
            <img src={indianflag} />
            <div className="foot-india-love">
              <p>Built with Love</p>
              <p>Made in India</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewFooter;
