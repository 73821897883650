import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {updateFormState, updateStepBar} from '../../../Redux/action'
import './Form.css';
// import ProgressBar from './ProgressBar';
import back from '../../../Icons/back.svg';
import setting from '../../../Icons/setting.svg';
import faq from '../../../Icons/faq.svg';
import StepProgressBar from '../../StepProgressBar/StepProgressBar';

const FormHead = ({formName,formSubHead,progressBar,backBtn,handleBack}) => {
  const {step_bar,formStates} = useSelector(state=>state)
  const dispatch = useDispatch()

 

  return (
    <div >
      <div className="form-head">
        <div className="Left-head">
          {backBtn && <img src={back} id="back-arr" alt="not found" onClick={(e)=>handleBack(e)}/>}
          <span className='heading-2' >{formName}</span>
        </div>
        {/* <div className="Right-head">
          <div className="icons-img">
            <img src={setting} alt="" />
            <img src={faq} alt="" /></div>
        </div> */}
      </div>
      <div className={"form-sub-head"} >{formSubHead}</div>
      {/* <hr style={{ height: "48px", width: "99%",margin:"30px 0", backgroundColor: "blue" }} /> */}
      {!progressBar && <StepProgressBar/>}
    </div>
  )
}


export default FormHead;