import React, { useEffect, useState } from 'react';
import '../Journey Form/Form/Form.css';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateCredentialsState, updateFormState, updateLoginDetails, updatePopUp, updateMwApplicationId, updateResumeJourney, updateSuggestion_DropDown, GenerateToken, updateDecisionDemog, updateMasterState, updatePartnerState } from '../../Redux/action';
import email from '../../Icons/email.svg'
import calender from '../../Icons/calender.svg';
import pin from '../../Icons/pin.svg';
import contact from '../../Icons/contact.svg';
import netMoney from '../../Icons/netmoney.svg';
import building from '../../Icons/building.svg';
import DropDownIcon from '../../Icons/DropDownIcon.svg';
import axios from 'axios';
import { base_url, client_id, client_key, source, prodbase_url, verification_source, verified_by, verifier_name, react_base_url } from '../Secret_Credentials/Secret_Credential';
import { numberToWords } from 'amount-to-words';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../assests/lootie-files/continue.json';
import { DecisionPanApi, getCityName, getCompanyName, getMasterData, GetPersonalDetails, getPinOption, getToken, PushLosApi } from '../EverCalledFunctions/EverCalledApis';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../Journey Form/Form/All_Forms/DOBCalender.css'
import { Helmet } from 'react-helmet';
import { encryptLeadIdStepId, gettingerror } from '../EverCalledFunctions/EverCalledFunctions';
import { AxiosThirty } from '../AxiosConfiguration/AxiosConfig';
import { formatDate } from '../MyAccount/MyAccountAPIs';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import bag from '../../Icons/bag.svg';
import { useSearchParams } from 'react-router-dom';
import Base64 from 'base-64';
import FormHead from '../Journey Form/Form/FormHead';
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';
import preaproveoffer from "../assests/partners/pre-aprove-offer.svg";

const PartnerProfile = () => {
  const [resp, setResp] = useState(false);
  const [PANErr, setPANErr] = useState(false);
  const [firstClick, setFirstClick] = useState(false)
  const [salaryInText, setSalaryInText] = useState("")
  const [showsalary, setShowsalary] = React.useState('');
  const [netincomeErr, setNetIncomeErr] = useState(false)
  const [submitButton, setSubmitButtom] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [ip, setIP] = useState('');
  const [device_id, setDevice_id] = useState("");
  const [mobile, setMobile] = useState();
  const [selected, setSelected] = useState(-1);
  const [showsalaried, setShowsalaried] = useState(false);
  const [employeName, setEmployeName] = useState(false)
  const [occupationDeatails, setOccupationDeatails] = useState({});
  const [loadingTime, setLoaderTime] = useState(false)
  const [nameLength, setNameLength] = useState(0)
  const [paymentModeData, setPaymentModeData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [EmployePopUpData, setEmployePopUpData] = useState({});
  const s = searchParams.get("s");
  const [localStore, updateLocalStore] = useState({
    displayEmployeName: "",
  })
  const [pincodeData, setPincodeData] = useState({
    show: false,
    data: []
  });
  const [cityFromPin, setCityFromPin] = useState({
    city: "",
    city_id: ""
  })
  const [apiData, setAPIData] = useState({
    show: false,
    data: [],
    selected: false
  })
  const [rocket, setRocket] = useState(false);
  let dispatch = useDispatch();
  const { step_bar, loginDetails, ckycDetails, formStates, credentialState, master_state, PopUpState, mw_application_id, resumeJourney, modify, suggestionDropDown, token, decisionDemog, Product_Id,partnerStates,partnerData } = useSelector(state => state)
  const [myPan, setMyPan] = React.useState("")
  const { register, handleSubmit, reset, formState: { errors }, watch,setError, setValue, clearErrors } = useForm({
    defaultValues: {
      email: "",
      pancard: "",
      pincode: "",
      net_income_take_home: "",
      display_employer_type: "",
      employer_type: "",
      employer_name: "",
      mode_of_salary: "",
      display_mode_of_salary: ""
    },
    mode: "all"
  })


  const data = watch();



  const [Show, setShow] = useState({
    today: new Date(),
    max: new Date().getFullYear() - 21,
    min: new Date().getFullYear() - 60
  })
  const [startDate, setStartDate] = useState(null);
  const years = []
  for (var i = Show.max; i > Show.min; i--) {
    years.push(i);
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getMonth = (date) => {
    return date.getMonth();
  }

  const getYear = (date) => {
    return date.getFullYear();
  }

  const handleDateClick = (date) => {
    if (date && date.getFullYear() < Show.min) {
      setStartDate(new Date(`${date.getDate()} ${months[date.getMonth()]}, ${Show.min}`))
    } else if (date && date.getFullYear() > Show.max) {
      setStartDate(new Date(`${date.getDate()} ${months[date.getMonth()]}, ${Show.max}`))
    } else {
      setStartDate(date)
    }
  }

  const closeFormOptions = () => {
    dispatch(updateMasterState({ ...master_state, status: false }))
  }

  const setThisValue = (data) => {
    setValue('employer_name', data.company_name);
    setAPIData({ ...apiData, show: false, data: [] })
    setNameLength(data.company_name.length)
  }

  const RemoveThis = () => {
    // setAPIData({...apiData,show:false})
  }


  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
    dispatch(updateCredentialsState({ ...credentialState, ipaddress: res.data.IPv4 }));
  }


  function knowdevice() {
    if (window.innerWidth > 1024) {
      setDevice_id("1");
    }
    else if (window.innerWidth > 480 && window.innerWidth < 1024) {
      setDevice_id("3")
    }
    else {
      setDevice_id("2");
    }
  }

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }


  useEffect(() => {
    if (token == "") {
      myToken();
    }
    knowdevice();
    getData()
    if(partnerData.dashData.pancard !== ""){
      setValue('pancard',partnerData.dashData.pancard)
    }
    // console.log(partnerData.dashData);
  }, [])


  useEffect(() => {
    if (loginDetails?.preVal) {
      if (loginDetails?.preVal?.pancard) {
        if (myPan !== "") {
          setValue('pancard', myPan)
        }
        else {
          setValue('pancard', loginDetails.preVal.pancard)
        }

      }
      if (loginDetails?.preVal?.email_id) {
        setValue('email', loginDetails.preVal.email_id)
      }
      if (loginDetails?.preVal?.pincode) {
        setValue('pincode', loginDetails.preVal.pincode)
        getCityNameHere(loginDetails.preVal.pincode)
      }
      if (loginDetails?.preVal?.dob) {
        if (loginDetails?.preVal?.dob !== "0000-00-00") {
          setStartDate(new Date(loginDetails.preVal.dob))
        }
      }
      if (loginDetails?.preVal?.net_income) {
        setValue('net_income_take_home', loginDetails.preVal.net_income)
      }
      if (loginDetails?.preVal?.phone_no) {
        setMobile(loginDetails.preVal.phone_no)
      }
      // if (loginDetails?.preVal?.occupation_id) {
      //   setValue('occupation_type', loginDetails.preVal.occupation_id)
      // }
      // if (loginDetails?.preVal?.occupation_type) {
      //   setValue('display_occupation_type', loginDetails.preVal.occupation_type)
      // }
    }
    if (loginDetails?.empData) {
      if (loginDetails?.empData?.employer_type) {
        setValue('employer_type', loginDetails.empData.employer_type)


      }
      if (loginDetails?.empData?.employer_name) {
        setValue('display_employer_type', loginDetails.empData.employer_name)
        updateLocalStore({
          ...localStore,
          displayEmployeName: loginDetails.empData.employer_name,
        })
      }
      if (loginDetails?.empData?.mode_of_salary) {
        setValue("display_mode_of_salary", loginDetails.empData.mode_of_salary_name);
        setValue("mode_of_salary", loginDetails.empData.mode_of_salary);
      }
      if (loginDetails?.empData?.company_name) {
        setTimeout(() => {
          setValue('employer_name', loginDetails.empData.company_name)
        }, 100)
      }
    }

  }, [loginDetails])

  const openForm = async () => {
    if (EmployePopUpData?.length) {
      dispatch(updateMasterState({ ...master_state, values: EmployePopUpData, status: true, heading: "Select your employer type", et: null }))
    } else {
      setLoaderTime(true)
      const APIDATA = await getMasterData("employer_type", token)
      await dispatch(updateMasterState({ ...master_state, values: APIDATA, status: true, heading: "Select your employer type", et: null }))
      setEmployePopUpData(APIDATA)
      setLoaderTime(false)
    }

  }

  useEffect(() => {

    if (master_state?.et) {
      setValue('employer_type', master_state.et.id)
      setValue('display_employer_type', master_state.et.value)

    }
    if(master_state.pt) {
      setValue('mode_of_salary', master_state.pt.id)
      setValue('display_mode_of_salary', master_state.pt.value)
    }


  }, [PopUpState, master_state])

  const APIFetch = (field, value) => {

    if (value.length !== 0 && typeof (value) === 'string') {
      setValue(field, String(value).replace(/^\s/, ""));
      if (value.length > 2 && value.length < 50) {
        if (nameLength !== value.length) {
          getOptions(data.employer_name)
        } else {
          setAPIData({ ...apiData, show: false, data: [] })
        }
      } else {
        setAPIData({ ...apiData, show: false, data: [] })
      }
    } else {
      setAPIData({ ...apiData, show: false, data: [] })
    }
  }

  const getOptions = async (name) => {
    const APIDATA = await getCompanyName(name, token);
    if (APIDATA?.status_code === "200" && APIDATA?.status === '1') {
      if (APIDATA?.data.length === 1 && data?.employer_name === APIDATA?.data[0]?.company_name) {
        setAPIData({ ...apiData, show: false, data: [] })
      } else {
        setAPIData({ ...apiData, show: true, data: APIDATA.data })
        dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: true }))
      }
    } else {
      setAPIData({ ...apiData, show: false, data: [] })
    }
  }

  const Blured = (field, value) => {
    var newVal = "";
    if (value.length !== 0 && typeof (value) === 'string') {
      newVal = String(value).replace(/^\s+|\s+$/g, "")
    }
    setValue(field, newVal);
    if (newVal && newVal.length < 3) {
      setError(field)
    } else {
      clearErrors([field])
    }
    RemoveThis()
  }

  const openPaymentMode = async () => {
    if (paymentModeData.length) {
      dispatch(updateMasterState({...master_state, values: paymentModeData, status: true, heading: "Select your mode of payment", pt: null}))
    }
    else {
      setLoaderTime(true);
      const ApiData = await getMasterData("mode_of_salary", token)
      dispatch(updateMasterState({...master_state, values: ApiData, status: true, heading: "Select your mode of payment", pt: null}))
      setPaymentModeData(ApiData)
      setLoaderTime(false);
    }
  }

  useEffect(() => {
    if (!(data?.display_employer_type == "Defence/ Paramilitary" || data?.display_employer_type == "Police")) {
      setValue('employer_name', "")
      setEmployeName(true)
    } else {
      setEmployeName(false)
    }
  }, [data.employer_type])



  const getCityNameHere = async (pin) => {

    const APIDATA = await getCityName(pin, token);
    if (APIDATA.city && APIDATA.cityId) {
      setCityFromPin({ ...cityFromPin, city: APIDATA.city, city_id: APIDATA.cityId })
      dispatch(updateDecisionDemog({ ...decisionDemog, city_id: APIDATA.cityId }));
    }


  }

  const GetPinData = async (pin) => {

    const APIDATA = await getPinOption(pin, token);
    dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: true }))
    setPincodeData({ ...pincodeData, show: true, data: APIDATA })

  }


  useEffect(() => {
    if (data?.pincode) {
      if (data?.pincode?.length < 3) {
        setPincodeData({ ...pincodeData, show: false, data: [] })
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
      }
      else if (((data?.pincode >= 110) && (data?.pincode?.length >= 3 && data?.pincode?.length < 6))) {
        GetPinData(data.pincode)
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
      }
      else if ((data?.pincode <= 110000)) {
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
      }
      else if (data?.pincode?.length === 6) {
        getCityNameHere(data.pincode)
        setPincodeData({ ...pincodeData, show: false, data: [] })
      }
      else {
        setPincodeData({ ...pincodeData, show: false, data: [] })
      }
    } else {
      setPincodeData({ ...pincodeData, show: false, data: [] })
      setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" })
    }

  }, [data.pincode])

  useEffect(() => {
    if (data?.net_income_take_home < 10000) {
      setSalaryInText("")
    } else if (numberToWords(data.net_income_take_home) === "Overflow") {
      setSalaryInText("Invalid value")
    }
    else {
      setSalaryInText(numberToWords(data.net_income_take_home))
    }
  }, [data.net_income_take_home])

  React.useEffect(() => {
    var str = salaryInText;
    var arr = str.split(" ");
    if (arr[arr.length - 1] === 'And') {
      str = str.replace("And", "");
    }
    setShowsalary(str);
  }, [salaryInText])

  const SubmitFunction = (result) => {
    AxiosThirty.post(`${prodbase_url}web/pa-basic-details`, result, {
      headers: {
        "token": token
      }
    }).then((res) => {
      if (res.data.status_code === "200" && res.data.status === "1") {

        gettingerror("basic profile web",res.data.data.mw_application_id)
        const application_id = res.data.data.mw_application_id;
        dispatch(updateMwApplicationId(application_id));
        // dispatch(updateDecisionDemog({ ...decisionDemog, is_junk1: res.data.data.is_junk }));
        dispatch(updateDecisionDemog({ ...decisionDemog, is_junk1: res.data.data.is_junk, lead_source: res.data.data.lead_source, new_journey_lead_source_flag: res.data.data.new_journey_lead_source_flag, new_journey_customer_id_flag: res.data.data.new_journey_customer_id_flag,offer_journey_change_flag : res.data.data.offer_journey_change_flag}))
        if ((res.data?.data?.is_junk === 0) && (res.data?.data?.query_status !== 132)) {
          // const payLoad = {
          //   application_id,
          //   cust_id: +(credentialState.cust_id),
          //   mobile_no: +(loginDetails.mobile),
          //   pincode: (data.pincode).toString(),
          //   city_id: +(cityFromPin.city_id),
          //   pancard: result.pancard,
          //   email: data.email
          // }

          AxiosThirty.post(`${prodbase_url}decision-pan-api`, {
            "mw_application_id": application_id,
            "customer_id": +(credentialState.cust_id),
            "level_id": 1,
            "mobile_no": +(loginDetails.mobile),
            "pincode": (data.pincode).toString(),
            "city_id": +(cityFromPin.city_id),
            "pancard": result.pancard,
            "email": data.email,
            "product_id": 56,
            "source": source,
            "verification_source": verification_source,
            "verified_by": verified_by,
            "verifier_name": verifier_name
          }, {
            headers: {
              "token": token
            }
          }).then((res) => {
            if ((res.data?.data?.Response) && (res.data?.data?.Response?.pan_status_flag !== 2)) {
              const updatedDOB = res.data.data.Response.PAN.result.dob ? res.data.data.Response.PAN.result.dob : result.dob
              let updateGender = ""
              updateGender = (res.data.data.Response.PAN && res.data.data.Response.PAN.result && res.data.data.Response.PAN.result.gender === "others")
                ? "" : res.data.data.Response.PAN.result.gender
              if (updateGender) {
                updateGender = updateGender !== "" && updateGender !== null && updateGender !== undefined ?
                  updateGender.charAt(0).toUpperCase() + updateGender.slice(1) : "";
              }
              dispatch(updateLoginDetails({ ...loginDetails, firstName: res.data.data.Response.PAN.result.firstName, lastName: res.data.data.Response.PAN.result.lastName, name: res.data.data.Response.PAN.result.firstName + " " + res.data.data.Response.PAN.result.lastName, dob: updatedDOB, gender: updateGender, email: result.email, pan: result.pancard, pin: result.pincode }));
              dispatch(updateCredentialsState({ ...credentialState, message: true }));
              dispatch(updatePartnerState({...partnerStates,PartnerPan:true}))
              setResp(false)
            }

            else {
              dispatch(updateLoginDetails({ ...loginDetails, dob: result.dob, email: result.email }));
              dispatch(updatePartnerState({...partnerStates,PartnerProfile:false,PartnerEmployer:true}))
              dispatch(updateCredentialsState({ ...credentialState, message: false }));
            }
          }).catch((err) => {
            setRocket(true);
          })
        }
        else {
          gettingerror("not eligible web",res.data.data.mw_application_id)
          dispatch(updateLoginDetails({ ...loginDetails, dob: result.dob, email: result.email }));
          dispatch(updatePartnerState({...partnerStates,PartnerProfile:false,PartnerEmployer:true}))
          dispatch(updateCredentialsState({ ...credentialState, message: false }));
        }
      }
      else if ((res.data.status_code === "203") && (res.data.status === "0")) {
        const application_id = res.data.data.mw_application_id;
        dispatch(updateMwApplicationId(application_id));
        dispatch(updatePartnerState({...partnerStates,PartnerProfile:false,PartnerEmployer:true}))
        // dispatch(updateCredentialsState({ ...credentialState, message: false }));
        dispatch(updateLoginDetails({ ...loginDetails, email: result.email, dob: result.dob }))
      }
      else {
        dispatch(updateFormState({ ...formStates, error: true }));
        dispatch(updateCredentialsState({ ...credentialState, message: false }));
        setResp(false)
      }
    }).catch((err) => {
      setRocket(true);
    })
  }

  {/* -------------------------------Submit Function---------------------------------------------------------- */ }
  const onSubmit = async (result) => {
    setResp(true)


    delete result.display_employer_type;
    delete result.display_occupation_type;
    delete result.display_mode_of_salary;

    result = {
      ...result,
      "dob": formatDate(startDate),
      "net_income_take_home": parseInt(result.net_income_take_home),
      "source": source,
      "mobile_no": mobile ? mobile : loginDetails.mobile,
      "ip": ip ? ip : "",
      "device_id": device_id ? device_id : "1",
      "form_type": 1,
      "mw_application_id": (mw_application_id != "") ? mw_application_id : 0,
      "customer_id": credentialState.cust_id,
      "product_id": 56,
      "verification_source": verification_source,
      "verified_by": verified_by,
      "verifier_name": verifier_name,
      "page_url": window.location.href,
      "pancard": result.pancard.toUpperCase(),
      "occupation_type": 1,
      "loan_amount":partnerData.dashData.loan_amount ? partnerData.dashData.loan_amount : (loginDetails.preVal.loan_amount ? loginDetails.preVal.loan_amount : +(""))
    };

    if (modify < 1) {

      SubmitFunction(result);

    }
    else {

      SubmitFunction(result);

    }

  }




  const BlurThis = (event) => {
    if (event?.target?.value?.length === 0) {
      setValue('net_income_take_home', "")
    } else {
      setValue('net_income_take_home', +event.target.value)
    }
    if (data?.net_income_take_home?.length < 5) {
      setNetIncomeErr(true)
    } else {
      setNetIncomeErr(false)
    }
  }


  React.useEffect(() => {
    dispatch(updateResumeJourney({ ...resumeJourney, productId: 56 }));


  }, [])



  const hideDOBPopUP = (e) => {
    if (PopUpState?.DOBPopUp?.show) {
      const updatedDOBPopUp = { ...PopUpState.DOBPopUp, show: false }
      dispatch(updatePopUp({ ...PopUpState, DOBPopUp: updatedDOBPopUp }))
      setPincodeData({ ...pincodeData, show: false })
    }
    e.stopPropagation()
  }

  const handleOnFocusIncome = (e) => {
    setValue('net_income_take_home', data.net_income_take_home.toString());
    hideDOBPopUP(e)
  }

  const setThisPin = (pin) => {
    setValue('pincode', pin);
    setPincodeData({ ...pincodeData, show: false })
    clearErrors(['pincode'])
    getCityNameHere(pin)
  }

  const removeThis = () => {
    // setPincodeData({...pincodeData,show:false})
  }


  const handleUpKeyNumber = (value, field) => {
    var regexp = /[^0-9]/g;
    if (value.length !== 0 && typeof (value) === 'string' && value.match(regexp)) {
      setValue(field, value.replace(regexp, ""))
    }
  }

  useEffect(() => {
    setMyPan(data.pancard);
    if (data?.pancard && data?.pancard?.length === 10) {
      var validRegex = /([a-zA-Z]){3}([pP]){1}([A-Za-z]){1}([0-9]){4}([a-zA-Z]){1}$/;
      if ((data?.pancard).match(validRegex)) {
        setPANErr(false)
      } else {
        setPANErr(true)
      }
    } else if (!data?.pancard) {
      setPANErr(false)
    }
    else {
      setPANErr(false)
    }
  }, [data.pancard])

  const PANCheck = () => {
    if (data?.pancard && data?.pancard?.length !== 10) {
      setPANErr(true)
    }
  }



  const SpaceManage = (field, value) => {
    if (value.length !== 0 && typeof (value) === 'string') {
      setValue(field, String(value).replace(/^\s+|\s+$/g, ""));
    }
  }

  useEffect(() => {
    if (modify === 1) {
      document.getElementById('pan-field').focus();

    }
  }, [modify])


  const SpecialChar = (field, value) => {
    var newVal = "";
    if (value) {
      newVal = value.replace(/[^a-z0-9]/gi, '');
      setValue(field, newVal);
    }
    SpaceManage(field, newVal)


  }

  const handleArrows = (e) => {
    if (pincodeData?.show) {
      var key = e.keyCode;
      if (key != 40 && key != 38 && key != 13) {
        return;
      }
      if (key == 13) {
        e.stopPropagation()
        if (selected > -1 && selected < pincodeData.data.length) {
          setThisPin(pincodeData.data[selected])
          setSelected(-1)
        } else {
          setSelected(-1)
        }
      }
      else if (key == 38) {
        if (selected > 0) {
          setSelected(selected - 1)
        }
      }
      else if (key == 40) {
        if (selected < pincodeData?.data?.length - 1) {
          setSelected(selected + 1)
        }
      }
    }
  }

  const handleError = (e) => {
    setRocket(false);
    onSubmit(data);
    gettingerror('api error',mw_application_id)
  }


  useEffect(() => {
    if (master_state?.oc) {
      setValue('occupation_type', master_state.oc.id)
      setValue('display_occupation_type', master_state.oc.value)
    }
  }, [master_state])

  useEffect(() => {
    if (startDate) {
      var day = startDate.getDate();
      var month = (startDate.getMonth() + 1) < 10 ? "0" + (startDate.getMonth() + 1) : (startDate.getMonth() + 1);
      var year = startDate.getFullYear();
      setValue('dob', year + "-" + month + "-" + day)
    } else {
      setValue('dob', "")
    }
  }, [startDate])

  useEffect(() => {
    if(employeName){
      if(data.display_employer_type && data.display_mode_of_salary  && data.pancard && data.net_income_take_home && data.pincode && data.mode_of_salary && data.employer_type && data.employer_name && data.email){
        setSubmitButtom(true)
      }
      else{
        setSubmitButtom(false);
      }
    }
    else{
      if(data.display_employer_type && data.display_mode_of_salary  && data.pancard && data.net_income_take_home && data.pincode && data.mode_of_salary && data.employer_type && data.email){
        setSubmitButtom(true)
      }
    }
  }, [data])


  return (
    <>
      {loadingTime && <CKYCWaiting />}
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      <FormHead formName="Profile" formSubHead={"Share a few details about you"} progressBar={true} />

      <div className="tag-for-input" style={partnerStates.PartnerUtm ? {paddingBottom:"26px"}:{}}>
        {/* -------------------------------Form Fields---------------------------------------------- */}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} >

          {/* ------------------------------------------Pan input Field---------------------------- */}
          <div className={"input-tag remove-bottom " + (PANErr ? " error-border " + (!PANErr ? " pan-case" : "") : data.pancard ? " add-border " : "")} >
            <img className="tag-icon" src={contact} />
            <input disabled={resp} className={"input-tag-box " + (data.pancard ? " capLetter" : "")} id="pan-field" type="text" minLength={10} maxLength={10} placeholder='PAN Number'
              {...register('pancard', {
                required: true, pattern: {
                  value: /([a-zA-Z]){3}([pP]){1}([A-Za-z]){1}([0-9]){4}([a-zA-Z]){1}$/
                }
              })} autoComplete="off"
              onFocus={(e) => hideDOBPopUP(e)} onKeyUp={() => SpecialChar("pancard", data.pancard)}
              onBlur={() => PANCheck()}
              readOnly={partnerData.dashData.pancard !== '' ? true:(loginDetails?.preVal?.pancard ? true : false)}
            />
          </div>
          {errors.pancard && data.pancard && <div className={'err-text' + (!PANErr ? ' pan-text' : "")} >Enter a valid PAN format (Example-ABCDE1234F)</div>}

          <div className={"input-tag remove-bottom" + (firstClick && !data.dob ? " error-border" : data.dob ? " add-border" : "")}>
            <img className="tag-icon" src={calender} />
            <DatePicker
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth
              }) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0 15px",
                    gap: 10
                  }}
                >
                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>




                </div>
              )}
              selected={startDate}
              dateFormat="dd-MMM-yyyy"
              onChange={(date) => handleDateClick(date)}
              placeholderText="Date Of Birth"
              // customInput={ <ExampleCustomInput />}
              disabled={resp}
            />
          </div>
          {!data.dob && firstClick && <div className='err-text' >Select Date Of Birth</div>}


          {/* ---------------------------Net Monthly Salary Field-------------------------------------- */}
          <div className={"input-tag remove-bottom" + ((errors.net_income_take_home && data.net_income_take_home < 10000) ? " error-border" : data.net_income_take_home ? " add-border" : "")} >
            <img className="tag-icon" src={netMoney} />
            {/* {data.net_income_take_home && <img className='rupee-symbol' src={rupee} />} */}
            <input disabled={resp} className="input-tag-box" type="tel" placeholder='Net Take Home Salary'
              {...register('net_income_take_home', { required: true, min: 10000 })} value={data.net_income_take_home ? data.net_income_take_home.toLocaleString('en-IN') : ""} autoComplete="off" onKeyUp={() => handleUpKeyNumber(data.net_income_take_home, "net_income_take_home")} onFocus={(e) => handleOnFocusIncome(e)} onBlur={(e) => BlurThis(e)} minLength={5} maxLength={9} />
          </div>
          {(errors.net_income_take_home) && data.net_income_take_home < 10000 && <div className={"api-text err-text"} >Minimum salary is 10,000</div>}
          {data.net_income_take_home >= 10000 && <div className="api-text">{showsalary}</div>}


          {/* ---------------------------------PINCode input Field-------------------------------------- */}
          <div className={"input-tag remove-bottom" + (errors.pincode ? " error-border" : data.pincode ? " add-border" : "")} >
            <img className="tag-icon" src={pin} />
            <input disabled={resp} className="input-tag-box" type="tel" minLength={6} maxLength={6} placeholder='Current Residence Pincode'
              {...register('pincode', { required: true, minLength: 6, maxLength: 6 })}
              onKeyUp={() => handleUpKeyNumber(data.pincode, "pincode")} autoComplete="off" onFocus={(e) => hideDOBPopUP(e)} onBlur={() => removeThis()} onKeyDown={(e) => handleArrows(e)} />
            {suggestionDropDown.show && pincodeData.show && pincodeData && pincodeData.data.length && pincodeData.data.length < 6 && <div className="dropDownBox">
              {pincodeData.data.map((row, ind) => {
                return <div className={'dropDownValue' + (ind === selected ? " select" : "")} key={ind} onClick={() => setThisPin(row)}>{row}</div>
              })}
            </div>}
          </div>

          {(data.pincode) && <div className={(errors.pincode) ? 'api-text err-text ' : "api-text "} >{cityFromPin.city}</div>}

          {(!data.pincode && errors.pincode) && <div className={'api-text err-text '} >Invalid Pincode</div>}


          {/* -----------------------------------Email Input Field------------------------------- */}
          <div className={"input-tag remove-bottom" + (errors.email ? " error-border" : data.email ? " add-border" : "")}>
            <img className="tag-icon" src={email} />
            <input disabled={resp} className="input-tag-box" type="text" placeholder='Email Address' maxLength={50}
              {...register('email', {
                required: true, pattern: {
                  value: /^([a-zA-Z0-9_.+-]{3,64})+\@(([a-zA-Z0-9-]{2,255})+\.)+([a-zA-Z0-9]{2,8})+$/
                }
              })} autoComplete="off" onFocus={(e) => hideDOBPopUP(e)}
              onKeyUp={() => SpaceManage("email", data.email)}
            />
          </div>
          {data.email && !errors.email && <div className='api-text remove-bottom' >A verification link will be send to your Email ID</div>}
          {errors.email && data.email && <div className='err-text remove-bottom' >Invalid Email Address</div>}
          {errors.email && !data.email && <div className='err-text remove-bottom' >Enter Email Address</div>}


          {/* ------------------------------Payment Mode Field----------------------------------------- */}

          <div className={"input-tag remove-bottom" + (errors.display_mode_of_salary && !data.display_mode_of_salary ? " error-border" : data.display_mode_of_salary ? " add-border" : "")}>
            <img className="tag-icon" src={building} />
            <input disabled={resp} className='pointer-cur' type="text" placeholder="Salary Payment Mode" readOnly {...register('display_mode_of_salary')} onFocus={() => openPaymentMode()} />
            <img src={DropDownIcon} className={"small-the-icon pointer-cur" + (master_state.show ? " img-rotateing" : "")} onClick={() => openPaymentMode()} alt="" />
            {/* {errors.display_employer_type && <img src={ThisIsAnError} alt="" />} */}
          </div>
          {!data.display_mode_of_salary && errors.display_mode_of_salary && <div className='err-text'>Select payment mode</div>}

          <div className={"input-tag remove-bottom" + (errors.display_employer_type && !data.display_employer_type ? " error-border" : data.display_employer_type ? " add-border" : "")}>
            <img className="tag-icon" src={building} />
            <input disabled={resp} className='pointer-cur' type="text" placeholder="Employer Type" readOnly {...register('display_employer_type')} onFocus={() => openForm()} />
            <img src={DropDownIcon} className={"small-the-icon pointer-cur" + (master_state.show ? " img-rotateing" : "")} onClick={() => openForm()} alt="" />
            {/* {errors.display_employer_type && <img src={ThisIsAnError} alt="" />} */}
          </div>
          {!data.display_employer_type && errors.display_employer_type && <div className='err-text'>Select Employer Type</div>}


            {/* -----------------------------------Employe Name Field-------------------------------------- */}
            {employeName && <> <div className={"input-tag remove-bottom" + (errors.employer_name ? " error-border" : data.employer_name ? " add-border" : "")}>
            <img className="tag-icon" src={building} />
            <input disabled={resp} type="text" placeholder='Employer Name' {...register('employer_name', { required: true, minLength: 3 })} onKeyDown={(e) => handleArrows(e)}
              onKeyUp={() => APIFetch("employer_name", data.employer_name)} onBlur={() => Blured("employer_name", data.employer_name)}
              onFocus={() => closeFormOptions()} />
            {/* {errors.employer_name && <img src={ThisIsAnError} alt="" />} */}
            {suggestionDropDown.show && data.employer_name && apiData.show && apiData.data.length !== 0 && <div className="dropDownBox">
              {apiData.data.map((row, ind) => {
                return <div className={'dropDownValue' + (ind === selected ? " select" : "")} key={ind} onClick={() => setThisValue(row)}>{row.company_name}</div>
              })}
            </div>}
          </div>
            {errors.employer_name && <div className='err-text'>Enter At least 3 Characters</div>}</>}


          {/* ----------------------------Submit button----------------------------------------- */}
          <button type='submit' disabled={resp || !submitButton} className={(submitButton && Object.keys(errors).length === 0) ? 'footer-btn Tmargin footer-btn-activate Bmargin' : "footer-btn Tmargin Bmargin"} onClick={() => setFirstClick(true)}>
            {!resp ?
              "Continue" :
              <div className="continue-lottie">
                <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
              </div>}
          </button>
        </form>
        {partnerData.dashData.loan_amount && partnerStates.PartnerUtm && <div className="pre-aprove-partner-offer">
            <div className="pre-aprove-space">
              <div className="pre-apprv-head">
                <p>Your pre-approved offer of upto</p>
              </div>
              <div className="pre-apprve-sub">
                <div className="pre-aprv-left">
                  <div className="pre-apprv-inner-head">
                    <p><b>₹ {partnerData?.dashData.loan_amount?.toLocaleString("en-IN")}</b> <span>is ready</span></p>
                  </div>
                  <div className="pre-aprv-cont-main">
                    <div className="pre-aprv-cont">
                      <span>Tenure</span>
                      <p>{partnerData.dashData.tenure} Months</p>
                    </div>
                    <div className="pre-aprv-cont">
                      <span>ROI(PM)</span>
                      <p>{(partnerData.dashData.roi/12).toFixed(2)} %</p>
                    </div>
                  </div>
                </div>
                <div className="pre-apprve-right">
                  <img src={preaproveoffer} alt='preaproveoffer' />
                </div>
              </div>
            </div>
          </div>}
      </div>
    </>
  )
}

export default PartnerProfile
