import React from "react";

function InfographCard({img, subhead, description, viewdate, handleView, id, handleSpan, info_cont_p, info_cont_span}) {
    return (
        <div>
            <div onClick={(e) => handleView(e)}  className="infographcard" id={id}>
                <div className="info-img">
                    <img src={img} alt="icon" />
                </div>
                <div className="infograph-cont-main">
                    <div className="info-cont">
                        <span style={{"lineHeight": "20px", "display": "block", "marginBottom": "16px"}}>{subhead}</span>
                        <p id={info_cont_p} style={{"lineHeight": "20px", "display": "inline"}}>{description}</p>
                        {/* <span id={info_cont_span} className="read-more-class" onClick={(e) => handleSpan(e)} >Read More</span> */}
                    </div>
                    <div className="info-view-date">
                        <span>Read More</span>
                        <p>{viewdate}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfographCard;