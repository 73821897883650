import React, { useState, useEffect } from "react";
import "../../Loader/Loader.css";
import { useForm } from "react-hook-form";
import "./esign.css";
import { useSelector, useDispatch } from "react-redux";
import Base64 from 'base-64';
import axios from "axios";
import {
  react_base_url,
  verification_source,
  verified_by,
  verifier_name,
  source,
} from "../../Secret_Credentials/Secret_Credential";
import ctaAnimation from "../../assests/lootie-files/continue.json";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import CKYCWaiting from "../../LoginPopUp/CKYCWaiting";
import NewErrorCase from "../../Journey Form/Form/All_Forms/NewErrorCase";
import {  handleKeyPress } from "../../EverCalledFunctions/EverCalledFunctions";
import queryString from "query-string";
import { apiInstance } from "../../Config/APIConfig";

const EsignPartnerForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    setFocus
  } = useForm({
    defaultValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    }, mode: "all"
  });

  const { 
    //formStates, loginDetails, 
    credentialState, 
    formStates,
    //personal_loan,
     mw_application_id, token } =
    useSelector((state) => state);

  const data = watch();
  const [timer, setTimer] = useState(30);
  const [id, setId] = useState("");
  const [device_id, setDevice_id] = useState();
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [Phone, setPhone] = useState();
  const [resp, setResp] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ip, setIP] = useState('');
  const [show, setShow] = useState(true);
  const [loader, setLoader] = useState(false);
  const [newErr, setNewErr] = useState(false)
  const [rocket, setRocket] = useState(false);
  const [flag, setFlag] = useState();
  const [userId, setUserId]= useState("");
  const [partnerId, setPartnerId]= useState("")


  function knowdevice() {
    if (window.innerWidth > 1024) {
      setDevice_id("1");
      return "1"
    } else if (window.innerWidth > 480 && window.innerWidth < 1024) {
      setDevice_id("3");
      return "3"
    } else {
      setDevice_id("2");
      return "2"
    }
  }

  function getDevice() {
    if (window.innerWidth > 1024) {
      return "1"
    } else if (window.innerWidth > 480 && window.innerWidth < 1024) {
      return "3"
    } else {
      return "2"
    }
  }

  const getData = async () => {
    const res = await axios.get('https://api.ipify.org?format=json')
    setIP(res.data.ip);
  }


  React.useEffect(() => {
    let stamp = queryString.parse(window.location.search);
    if (stamp && stamp.stamp_flag) {
      let fl = stamp.stamp_flag
      try {
        fl = Base64.decode(fl)
        fl = Base64.decode(fl)
        setFlag(fl)
      } catch {
        setFlag(0)
      }
    }
    else {
      setFlag(0)
    }
    knowdevice();
    getData();
    sessionStorage.setItem("otp_count", "0");
  }, [])


  const GetOTP = async () => {
    const res = await apiInstance.post(`generate-otp`, {
      "mobile": userId.toString(),
      "api_mode": "LIVE",
      "login_from": 12,
      "partner_id": partnerId,
      "device_type": getDevice(),
      "device_id": ip,
      "verification_source": verification_source,
      "verified_by": verified_by,
      "verifier_name": verifier_name,
      "source": source
    }, {
      token
    })
    if (res.status_code === "200" && res.status === "1") {
      setLoader(false)
      if (res && res.data && res.data.otp_id !== "") {
        setId(res.data.otp_id)
        sessionStorage.setItem('otp-id', res.data.otp_id)
      } else {
        setResp(false)
        setId("LIMIT")
      }
    } else if (res.status_code === "429" && res.status === "0") {
      setResp(false)
      setId("LIMIT")
      setRocket(true);
    } else {
      setResp(false)
      setRocket(false);
    }

  };


  const Verify = async () => {
    setResp(true);
    const otp = data.otp1 + data.otp2 + data.otp3 + data.otp4;
    var otpId = id ? id : sessionStorage.getItem('otp-id')
    const res = await apiInstance.post(`verify-otp`, {
      "otpId": otpId,
      "otp": otp,
      "mobile": userId.toString(),
      "login_from": 12,
      "device_id": ip,
      "device_type": device_id,
      "verification_source": verification_source,
      "verified_by": verified_by,
      "verifier_name": verifier_name,
      "source": source,
      "partner_id": partnerId
    }, {
      token,
      "log": 1
    })
    if (res.status === "1" && res.status_code === "200") {
        let qry = Base64.encode(partnerId);
        qry = Base64.encode(qry);
        window.location.href = `${react_base_url}partner-thank-you/?fid=${qry}`

    } else if (res.status === "0" && res.status_code === "429") {
      setFirstSubmit(true);
      setResp(false)
    } else {
      setNewErr(true)
    }
  };

  useEffect(() => {
    if (data.otp1) {
      setFocus('otp2')
    }
    if (data.otp1 && data.otp2) {
      setFocus('otp3')
    }
    if (data.otp1 && data.otp2 && data.otp3) {
      setFocus('otp4')
    }
    if (data.otp1 && data.otp2 && data.otp3 && data.otp4) {
      Verify();
    }
  }, [data.otp1, data.otp2, data.otp3, data.otp4]);

  const onSubmit = async () => {
    Verify()
  };

  const handleChange = (nextLocation, prevLocation, event, value) => {
    setValue(value, event.target.value);
  }

  const handleKeyDown = (event, prevLocation) => {
    const key = event.keyCode || event.charCode;
    if (key == 8 || key == 46) {
      if (event.target.value.length === 0) {
        document.getElementById(prevLocation).focus();
      }
    }
  }

  const handleClick = (e) => {
    if (firstSubmit) {
      reset()
      setFirstSubmit(false)
      document.getElementById("first-input").focus()
    }
  }
  useEffect(() => {
    timer > 0 &&
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
  }, [timer]);

  const handleotp = async () => {
    // sessionStorage.setItem("otp_count", "0");
    // setNewErr(false)
    // GetOTP(sessionStorage.getItem("phone"), credentialState.cust_id)
    GetOTP();
    setShow(false)
  };


  const RetryCase = () => {
    sessionStorage.clear()
    setNewErr(false)
    setResp(false)
  }

  const handleError = (e) => {
    window.location.reload();
  }

  useEffect(()=>{
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const fidValue = url.searchParams.get("fid");
    const partner_id =  Base64.decode(fidValue);
    const final_partner_id = Base64.decode(partner_id);

    const useridValue =url.searchParams.get("user_id");
    const user_id = Base64.decode(useridValue);
    const final_user_id = Base64.decode(user_id);
    setUserId(final_user_id);
    setPartnerId(final_partner_id);

  },[])

  useEffect(()=>{
    if(userId !== "" && partnerId !== "" && ip!==""){
        setRocket(false);
        GetOTP();
    }
  },[userId,partnerId,ip])

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      {newErr && <NewErrorCase handleClick={RetryCase} />}
      {loader ? <CKYCWaiting heading={"We are fetching your details to initiate eSigning of Agreement"} /> :
        <div className="loaderbackground">
          <div className="loadertransparent">
            <form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              autoCorrect="false"
            >
              <div className="esign-cont">
                <p className="esign-p1">Digitally eSign your loan agreement</p>
                <p className="esign-span">
                  Please enter the OTP as sent on
                  <span style={{ color: "#4D3ECC" }}> +91-{userId}</span>
                </p>
                <div className="enter-otp ">
                  <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                    <input maxLength={1} readOnly={resp || id === "LIMIT"} id="first-input" type="tel" {...register('otp1', { required: true })} onKeyPress={(event) => handleKeyPress(event)} onChange={(event) => handleChange("second-input", "first-input", event, "otp1")} onKeyDown={(event) => handleKeyDown(event, "first-input")} onClick={(e) => handleClick(e)} autoFocus />
                  </div>
                  <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                    <input maxLength={1} readOnly={resp || id === "LIMIT"} id="second-input" type="tel" {...register('otp2', { required: true })} onKeyPress={(event) => handleKeyPress(event)} onChange={(event) => handleChange("third-input", "first-input", event, "otp2")} onKeyDown={(event) => handleKeyDown(event, "first-input")} onClick={(e) => handleClick(e)} />
                  </div>
                  <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                    <input maxLength={1} readOnly={resp || id === "LIMIT"} id="third-input" type="tel" {...register('otp3', { required: true })} onKeyPress={(event) => handleKeyPress(event)} onChange={(event) => handleChange("forth-input", "second-input", event, "otp3")} onKeyDown={(event) => handleKeyDown(event, "second-input")} onClick={(e) => handleClick(e)} />
                  </div>
                  <div className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}>
                    <input maxLength={1} readOnly={resp || id === "LIMIT"} id="forth-input" type="tel" {...register('otp4', { required: true })} onKeyPress={(event) => handleKeyPress(event)} onChange={(event) => handleChange("forth-input", "third-input", event, "otp4")} onKeyDown={(event) => handleKeyDown(event, "third-input")} onClick={(e) => handleClick(e)} />
                  </div>
                </div>
                {id !== "LIMIT" ? <>{firstSubmit ? <div className="otp-timer-slot err">
                  <span >Invalid OTP </span>
                </div> : show && <div className="otp-timer-slot">
                  {timer !== 0 && <span>RESEND OTP : </span>}
                  {timer !== 0 ? <span className="resend-option" >{timer} Seconds</span>
                    : <span className="resend-option pointer-cur" onClick={handleotp}>RESEND OTP</span>}
                </div>}</>
                  : <div className="limit-over err-text">You have exhausted daily OTP limit. <br />
                    Please try after 24 hours.</div>}
                <div>
                  <button
                    id="last"
                    className={
                      " footer-btn Tmargin margin-b2" +
                      (data.otp1 &&
                        data.otp2 &&
                        data.otp3 &&
                        data.otp4 &&
                        !firstSubmit
                        ? " footer-btn-activate"
                        : "")
                    }
                  >
                    {!resp ? (
                      "Verify and Sign"
                    ) : (
                      <div className="continue-lottie">
                        <Lottie
                          loop
                          animationData={ctaAnimation}
                          play
                          className="ctn-loader"
                        />
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>}
    </>
  );
};

export default EsignPartnerForm;
