import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./BlogSlider.css";
import axios from "axios";
import {
 prodbase_url,
  client_id,
  client_key,
  source,
  verification_source,
  verified_by,
  verifier_name
} from "../Secret_Credentials/Secret_Credential";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken } from "../../Redux/action";
import { decryptWithHybrid, encryptWithHybrid } from "../../Keys";


const BlogSlider = () => {
  const [blog, setBlog] = useState([]);
  const [show,setShow]=useState(false);
  const {token} = useSelector(state=>state)
  const dispatch = useDispatch();
  var sliderblog = {
    infinite: true,
    autoplay: true,
    cssEase: 'ease-in-out',
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          speed: 500
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }
    ]
  };

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }


  useEffect(() => {
    if (token == "") {
      myToken();
    }
    // window.sessionStorage.clear();
  }, [])

  const encryptedPayload = async () => {
    let payLoad1= {
      "source": source,
      verified_by: verified_by,
      verifier_name: verifier_name,
      verification_source: verification_source
   }
   let payLoad2 = await encryptWithHybrid(JSON.stringify(payLoad1));
   axios.post(`${prodbase_url}fetch-blog-articles`,payLoad2, {
    headers: {
      token
    },
  })
  .then((res) => {
    let dec_data = decryptWithHybrid(res.data.encryptedMessage, res.data.encryptedAesKey, res.data.iv);
    if(dec_data.status_code === "200" && dec_data.status === "1"){
      setShow(true);
      setBlog(dec_data.data);
    }
    else{
      setShow(false);
    }
  }).catch((err)=>{
    setShow(false);
  });
  }

  useEffect(() => {
    if(token !== ""){

      setTimeout(() => {
        encryptedPayload();
      }, 1000);
    }
    // }); 
  },[token])


  return (
    <>
      <div className="blog-cont">
        <div className="blog-header">
          <p>MoneyWide Blog</p>
        </div>
        {show ? <div className="blog-card">
          <Slider {...sliderblog}>
            {blog.map((el, index) => (
              <div key={index} className="nesting">
                <div className="blogcard">
                  <img className="blogimg" src={el.image_link} alt="Image Not Found"/>
                  <p className="blogp">{el.heading}</p>
                  <button><a href={`${el.url}${window.location.search}`} target="_blank">Read More</a></button>
                </div>
              </div>
            ))}
          </Slider>
          {/* <button className="blogviewall">View all</button> */}
        </div>:""}
      </div>
    </>
  );
};

export default BlogSlider;
