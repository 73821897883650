import React, { useState } from 'react';
import "../FAQSection/FAQSection.css";
import { useDispatch, useSelector } from 'react-redux';
import { prodbase_url, react_base_url } from '../Secret_Credentials/Secret_Credential'
import { getToken } from '../EverCalledFunctions/EverCalledApis';
import { GenerateToken } from '../../Redux/action';

const FAQSectionChk = ({ data }) => {
    React.useEffect(() => {
    }, []);
    
    const { token, formStates } = useSelector(state => state)
    const [faqquestions, setFAQquestions] = useState([]);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    };

    React.useEffect(() => {
        if (token == "") {
            myToken();
        }
    }, []);

    React.useEffect(() => {
        let sendingQuestion = data.map((row,index) => {
                return {...row, isActive: false }
            })
            setFAQquestions(sendingQuestion)

    }, []);
    
    const ChangeActive = (index) => {
        var val = [...faqquestions];
        val.forEach((item, ind) => {
            if (ind !== index) {
                item.isActive = false;
            } else {
                item.isActive = !faqquestions[index].isActive;
            }
        })

        setFAQquestions(val);
    }
    const AppendData=({data,index})=>{
        let ele = document.createElement('span')
        ele.innerHTML = data.desc
        ele.className = "heading-p"

        setTimeout(()=>{
            let d = document.getElementById(index+data.title.charAt(0)+data.desc.charAt(0))
            if(d){
                d.appendChild(ele)
            }
        },10)

    }

    return (
        <div>
            <div className="FAQ-container">
                <div className="box-shape">
                    <h2 className="heading-h2">Frequently asked questions <b>(FAQ’s)</b></h2>
                    <div className={( faqquestions.length > 5) ? ('manage-div-limit' + (open ? " open-div" : "")) : ""}>
                        {faqquestions.length!==0 && faqquestions.map((el, index) => {
                            return <div key={index}><div className={'question-box ' + (el.isActive ? "active" : "notactive")} >
                                <div className="question" onClick={() => ChangeActive(index)}>
                                    <div className="question-left">{el.title}</div>
                                    <div>{el.isActive ? <img className='faq-arr' src={`${react_base_url}assets/images/headerlogo/BlueArr.svg`} alt="na" /> : <img src={`${react_base_url}assets/images/headerlogo/downarrow.svg`} alt="" />}</div>
                                </div>
                                {el.isActive && <div className="FAQ-Question-Content" id={index+el.title.charAt(0)+el.desc.charAt(0)}>{  <AppendData data={el} index={index}/>}</div>}
                            </div>
                            </div>
                        })}
                        {(faqquestions.length > 5) && !open && <div className="more-open-btn">
                            <button onClick={() => setOpen(true)}>View All</button>
                        </div>}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default FAQSectionChk;
