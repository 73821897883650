import { PRINCIPAL_AMOUNT,ORIGINAL_OUT_TENURE_LT, LOAN_TENURE,PART_PAYMENT_TENURE, Part_PAYMENT_CHECKBOX, RATE_OF_INTEREST, EMI, SET_HEADER_NAVIGATION, MENU_BAR, APPLYFORJOB, SET_MONTHLY_INCOME, SET_MONTHLY_EXPENSE, SET_CAL_GEN, SHOWFORM, UPDATE_STEP_BAR, PERSONAL_LOAN_EVENT, FORM_JOURNEY_ACTION, LOGIN_DETAILS, SORRY_POPUP, UPDATE_POP_UP, SET_PREAPPROVED_OFFER, CKYC_UPDATE, FORM_STATES, CREDENTIALS_STATE, SELECTED_SERVICE_REQUEST_QUERY, UPDATE_MASTER_STATE, CHECK_MODIFY, OFFICE_EMAIL, SET_PREAPPROVED_OFFER_DETAILS, SET_MW_APPLICATION_ID, SET_CHOOSEN_AMOUNT, SET_RESIDENTIAL_ADDRESS, SERVICE_SHOW, SERVICE_LOGIN, SERVICE_CREDENTIAL, RESUME_JOURNEY, KFS_DATA, APP_STATUS,PL_LOADER,SUGGESTION_DROPDOWN, DECISION_DEMOG, GENERATE_TOKEN, TEMPLET_ID, BLOG_DATA,OFFICE_PENNY_DROP,PRODUCT_ID,EMI_PAID,PART_PAYMENT_AMT, MW_PAGES ,PARTNER_STATES ,PARTNER_DATA,MAKE_PAYMENT, MYACCHEADERDATA,SUGGESTION_DROPDOWN2,SUGGESTION_DROPDOWN3,PARTNER_DOC_POPUP } from "./actionType";

export const principalAmount = (payLoad) => {
    return {
        type: PRINCIPAL_AMOUNT,
        payLoad
    }
}

export const updatePartnerDocPopUp = (payLoad) =>{
    return{
        type: PARTNER_DOC_POPUP,
        payLoad
    }
}

export const updaterevisedoutstenurelt = (payLoad) => {
    return {
        type: ORIGINAL_OUT_TENURE_LT,
        payLoad
    }
}

export const loanTenure = (payLoad) => {
    return {
        type: LOAN_TENURE,
        payLoad
    }
}

export const EmiPaid = (payLoad) => {
    return {
        type: EMI_PAID,
        payLoad
    }
}

export const partPatmentAmt = (payLoad) => {
    return {
        type: PART_PAYMENT_AMT,
        payLoad
    }
}

export const partPaymentTenure = (payLoad) => {
    return {
        type: PART_PAYMENT_TENURE,
        payLoad
    }
}

export const updatepartpaymentcheckbox = (payLoad) => {
    return {
        type: Part_PAYMENT_CHECKBOX,
        payLoad
    }
}

export const rateOfInterest = (payLoad) => {
    return {
        type: RATE_OF_INTEREST,
        payLoad
    }
}

export const storeEmi = (payLoad) => {
    return {
        type: EMI,
        payLoad
    }
}

export const setHeaderNavigation = (payLoad) => {
    return {
        type: SET_HEADER_NAVIGATION,
        payLoad
    }
}

export const setMenuBar = (payLoad) => {
    return {
        type: MENU_BAR,
        payLoad
    }
}
export const applyForJob = (payLoad) => {
    return {
        type: APPLYFORJOB,
        payLoad
    }
}

export const setMonthlyIncome = (payLoad) => {
    return {
        type: SET_MONTHLY_INCOME,
        payLoad
    }
}

export const setMonthlyExpense = (payLoad) => {
    return {
        type: SET_MONTHLY_EXPENSE,
        payLoad
    }
}

export const setCalGen = (payLoad) => {
    return {
        type: SET_CAL_GEN,
        payLoad
    }
}
export const setFormSubmit = (payLoad) => {
    return {
        type: SHOWFORM,
        payLoad
    }
}

export const updateStepBar = (payLoad) => {
    return {
        type: UPDATE_STEP_BAR,
        payLoad
    }
}

export const change_personal_loan_page = (payLoad) => {
    return {
        type: PERSONAL_LOAN_EVENT,
        payLoad
    }
}

export const form_journey_action = (payLoad) => {
    return {
        type: FORM_JOURNEY_ACTION,
        payLoad
    }
}

export const updateLoginDetails = (payLoad) => {
    return {
        type: LOGIN_DETAILS,
        payLoad
    }
}

export const updateCkycDetails = (payLoad) => {
    return {
        type: CKYC_UPDATE,
        payLoad
    }
}


export const setSorryPopup = (payLoad) => {
    return {
        type: SORRY_POPUP,
        payLoad
    }
}

export const updatePopUp = (payLoad) => {
    return {
        type: UPDATE_POP_UP,
        payLoad
    }
}

export const setPreapprovedOfferPopup = (payLoad) => {
    return {
        type: SET_PREAPPROVED_OFFER,
        payLoad
    }
}

export const updateFormState = (payLoad) => {
    return {
        type: FORM_STATES,
        payLoad
    }
}

export const updatePartnerState = (payLoad) => {
    return {
        type: PARTNER_STATES,
        payLoad
    }
}

export const updatePartnerData = (payLoad) => {
    return {
        type: PARTNER_DATA,
        payLoad
    }
}

export const updateCredentialsState = (payLoad) => {
    return {
        type: CREDENTIALS_STATE,
        payLoad
    }
}

export const updateSelectedServiceRequestQuery = (payLoad) => {
    return {
        type: SELECTED_SERVICE_REQUEST_QUERY,
        payLoad
    }
}

export const updateMasterState = (payLoad) => {
    return {
        type: UPDATE_MASTER_STATE,
        payLoad
    }
}

export const checkModify = (payLoad) => {
    return {
        type: CHECK_MODIFY,
        payLoad
    }
}

export const updateOfficeEmail = (payLoad) => {
    return {
        type: OFFICE_EMAIL,
        payLoad
    }
}

export const setPreApprovedOfferDetails = (payLoad) => {
    return {
        type: SET_PREAPPROVED_OFFER_DETAILS,
        payLoad
    }
}

export const updateMwApplicationId = (payLoad) => {
    return {
        type: SET_MW_APPLICATION_ID,
        payLoad
    }
}

export const updateChoosenAmount = (payLoad) => {
    return {
        type: SET_CHOOSEN_AMOUNT,
        payLoad
    }
}

export const updateResidentialAddress = (payLoad) => {
    return {
        type: SET_RESIDENTIAL_ADDRESS,
        payLoad
    }
}

export const updateserviceshow = (payLoad) => {
    return {
        type: SERVICE_SHOW,
        payLoad
    }
}

export const updateservicelogin = (payLoad) => {
    return {
        type: SERVICE_LOGIN,
        payLoad
    }
}

export const updateservicecredential = (payLoad) => {
    return {
        type: SERVICE_CREDENTIAL,
        payLoad
    }
}

export const updateResumeJourney = (payLoad) => {
    return {
        type: RESUME_JOURNEY,
        payLoad
    }
}
export const updateKFSData = (payLoad) => {
    return {
        type: KFS_DATA,
        payLoad
    }
}

export const updateAppStatus = (payLoad) => {
    return {
        type: APP_STATUS,
        payLoad
    }
}

export const updatePLLoader=(payLoad)=>{
    return {
        type:PL_LOADER,
        payLoad
    }
}
export const updateSuggestion_DropDown =(payLoad)=>{
    return {
        type:SUGGESTION_DROPDOWN,
        payLoad
    }
}

export const updateDecisionDemog = (payLoad) => {
    return {
        type: DECISION_DEMOG,
        payLoad
    }
}

export const GenerateToken = (payLoad) => {
    return {
        type: GENERATE_TOKEN,
        payLoad
    }
}

export const SetTempId = (payLoad) => {
    return {
        type: TEMPLET_ID,
        payLoad
    }
}

export const updateBlogData = (payLoad) => {
    return {
        type: BLOG_DATA,
        payLoad
    }
}

export const drop = (payLoad) =>{
    return{
        type:OFFICE_PENNY_DROP,
        payLoad
    }
}

export const updateProductId=(payLoad)=>{
    return {
        type:PRODUCT_ID,
        payLoad
    }
}

export const updatemwpages = (payLoad) => {
    return {
        type: MW_PAGES,
        payLoad
    }
}

export const updateMakePayment = (payLoad) => {
    return {
        type: MAKE_PAYMENT,
        payLoad
    }
}

export const updateheaderdata = (payLoad) => {
    return {
        type: MYACCHEADERDATA,
        payLoad
    }
}

export const updateSuggestion_DropDown2 =(payLoad)=>{
    return {
        type:SUGGESTION_DROPDOWN2,
        payLoad
    }
}
export const updateSuggestion_DropDown3 =(payLoad)=>{
    return {
        type:SUGGESTION_DROPDOWN3,
        payLoad
    }
}