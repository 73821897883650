import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updatePartnerDocPopUp } from '../../Redux/action';
import { apiInstance } from '../Config/APIConfig';
import { useEffect } from 'react';
import MainLoader from '../MainLoader/MainLoader';
import { getMasterData } from '../EverCalledFunctions/EverCalledApis';

function PartneDocPopUp() {
    const dispatch = useDispatch();
    const { partnerdocupdate, token } = useSelector(state => state);

    const handleClick = (e, temp_id) => {
        e.stopPropagation();
        let cur_arr = [];
        for (let i = 0; i < partnerdocupdate.data.length; i++) {
            let cur_obj = partnerdocupdate.data[i];
            let { checkbox, id } = cur_obj;
            if (temp_id == id) {
                cur_obj = { ...cur_obj, checkbox: !checkbox }
                cur_arr.push(cur_obj);
            }
            else {
                cur_arr.push(cur_obj);
            }
        }
        dispatch(updatePartnerDocPopUp({
            ...partnerdocupdate, data: cur_arr
        }))
    }

    const handleButton = (e) => {
        e.stopPropagation();
        dispatch(updatePartnerDocPopUp({
            ...partnerdocupdate, status: false
        }))
    }

    const ClosePopup = (e) => {
        e.stopPropagation();
        if (partnerdocupdate.status) {
            dispatch(updatePartnerDocPopUp({
                ...partnerdocupdate, status: false
            }))
        }
    }

    

    return (
        <>
            <div className='loaderbackground' onClick={(e) => ClosePopup(e)}>
                <div className='loadertransparent'>
                    {partnerdocupdate.data.length > 0 ? <div className='ovdpopup doc-partner-pop' onClick={(e) => e.stopPropagation()}>
                        <h2>Select Documents</h2>
                        <div className='ovdpopup-content'>
                            {/* {docData.map((row,ind)=>(
                                return <div key={ind} className="">
                                    <input type='checkbox'/>
                                </div>
                            ))} */}
                            {partnerdocupdate.data && partnerdocupdate.data.map((row, ind) => {
                                return <div key={ind} className='partner-popup-check' onClick={(e) => handleClick(e, row.id)}>
                                    <input type='checkbox' checked={row.checkbox} />
                                    <span>{row.value}</span>
                                </div>
                            })}
                            <div className='partner-doc-btn'>
                                <button onClick={(e) => handleButton(e)}>Done</button>
                            </div>
                        </div>

                    </div> : <MainLoader />}
                </div>
            </div>
        </>
    )
}

export default PartneDocPopUp