import axios from 'axios';
import React from 'react';
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { GenerateToken } from '../../../../Redux/action';
import lottieJson from "../../../assests/lootie-files/lf20_dxvedfri (1).json";
import { getToken } from '../../../EverCalledFunctions/EverCalledApis';
import CKYCWaiting from '../../../LoginPopUp/CKYCWaiting';
import { prodbase_url,  source, verification_source, verified_by, verifier_name } from '../../../Secret_Credentials/Secret_Credential';
import { apiInstance } from '../../../Config/APIConfig';

const Sorry = ({ temp_id }) => {
    const { credentialState, token } = useSelector(state => state)
    const [store, setStore] = React.useState("");
    const [msg, setMsg] = React.useState("");
    const [state, setState] = React.useState(true);
    const dispatch = useDispatch();
    const [updatedMsg, setUpdatedMsg] = React.useState("");

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn))
    }

    React.useEffect(() => {
        if (token == "") {
            myToken();
        }
    }, [])

    const ThankyouApi = async () => {
        let decThankyou = await apiInstance.post(`thankyou-screen-message`, {
            source,
            "template_id": temp_id,
            "customer_id": credentialState.cust_id,
            verifier_name,
            verification_source,
            verified_by
        }, {
            token
        })
        // .then((res) => {
            if ((decThankyou.status == 1) && (decThankyou.status_code == 200)) {
                if (temp_id !== 16) {
                    setMsg(decThankyou.data.msg);
                    setState(false);
                }
                else {
                    if (decThankyou.data.days_count) {
                        setStore(decThankyou.data.days_count)
                    }
                    setMsg(decThankyou.data.msg);
                    setState(false);
                }
            }

        // })
    }

    React.useEffect(() => {
        if (token !== "") {
            ThankyouApi();
        }
        //    })
    }, [token])

    React.useEffect(() => {
        if(msg !== "") {
          let newMsg = msg;
          if(newMsg.includes("Sorry !!")) {
            newMsg = newMsg.split("Sorry !!");
            newMsg = newMsg[1];
            setUpdatedMsg(newMsg);
          }
          else if(newMsg.includes("Sorry!!")){
            newMsg = newMsg.split("Sorry!!");
            newMsg = newMsg[1];
            setUpdatedMsg(newMsg);
          }
        }
      },[msg])


    return (
        <>
            {
                state ?
                    <CKYCWaiting />
                    :
                    ( !state && ((temp_id == 11) || (temp_id == 5) || (temp_id == 8) || (temp_id == 9) || (temp_id == 10) || (temp_id == 17))) ?
                        <div className='manage-sry-screen'>
                            <div className="lotie-div">
                                <Lottie loop animationData={lottieJson} play className="sry-popup-lottie" />
                            </div>
                            <div className="sry-heading">Sorry</div>
                            <div className="sry-text PadBottom">{updatedMsg}</div>
                        </div> :
                        <div className='manage-sry-screen'>
                            <div className="lotie-div">
                                <Lottie loop animationData={lottieJson} play className="sry-popup-lottie" />
                            </div>
                            <div className="sry-heading">Sorry</div>
                            <div className="sry-text">We are not able to process your  loan application currently.</div>
                            <div className="sry-timer">You may try again after 30 days</div>
                        </div>
            }
        </>
    )
}

export default Sorry;