import React from "react";
import { useForm } from "react-hook-form";
import familyPlus from "../../Icons/man.svg";
import FormHead from "../Journey Form/Form/FormHead";
import pin from "../../Icons/pin.svg";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken } from "../../Redux/action";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { prodbase_url,verification_source,verified_by,verifier_name,source } from "../Secret_Credentials/Secret_Credential";

const CustomerRevForm = ({ updateStatus, leadId }) => {
    const [submit, setSubmit] = React.useState(false);
    const [count,setCount] = React.useState(0);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      Name: "",
      City: "",
      comment: "",
    },
    mode: "all",
  });

  const data = watch();

  const { token } = useSelector((state) => state);
  const dispatch = useDispatch();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, []);

  React.useEffect(()=>{
    if(token !== ""){
      axios.post(`${prodbase_url}fetch-customer-details`,{
        "mw_application_id": leadId,
        verifier_name,
        verification_source,
        verified_by,
        source
    },{
      headers:{
        token
      }
    }).then((res)=>{
      if((res.data.status == 1) && (res.data.status_code == 200)){
        if(res.data.data.review_flag === 2){
          updateStatus(0)
        }
        if(res.data.data.review_flag === 1){
          updateStatus(4)
        }
        setValue("Name",res.data.data.customer_full_name);
        setValue("City",res.data.data.city_name);
      }
    })
    }
  },[token]);

  const handleAlphaOnly = (value, field) => {
    var regexp = /[^a-zA-Z ]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""));
    } else {
      setValue(field, value.replace(/^\s+/, ''));
    }
  };

  const Blured = (field, value) => {
    // var newVal = value.trimEnd();
    var newVal = value.replace(/\s+$/, '');
    setValue(field, newVal);
    if (newVal.length < 3) {
      setError(field);
    } else {
      clearErrors([field]);
    }
  };

  const SpaceManage = (field, value) => {
    setValue(field, value.replace(/^\s+/, ''));
  };

  React.useEffect(() => {
    if (
      data.Name  &&
      data.City &&
      data.comment && 
      count
    ) {
      if (Object.keys(errors).length === 0) {
        setSubmit(true);
      } else {
        setSubmit(false);
      }
    } else {
      setSubmit(false);
    }
  }, [data]);

  const setthis = (pin)=>{
    setCount(pin);
  }

  const onSubmit = ()=>{
    axios.post(`${prodbase_url}insert-customer-reviews`,{
      "name": data.Name,
      "city": data.City,
      "overall_rating" : count,
      "comments" : data.comment,
      "mw_application_id":leadId,
      source,
      verification_source,
      verified_by,
      verifier_name
      },{
        headers:{
          token
        }
      }).then((res)=>{
        if((res.data.status === "1") && (res.data.status_code === "200")){
          updateStatus(2);
        }
        else{
          updateStatus(0);
        }
      })
  }

  return (
    <>
      <FormHead
        formName={"Submit Your Feedback to Help Us Improve"}
        progressBar={true}
      />
      <div className="tag-for-input">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          <div
            className={
              errors.Name
                ? "input-tag error-border remove-bottom"
                : data.Name
                ? "input-tag  add-border remove-bottom"
                : "input-tag  remove-bottom"
            }
          >
            <img className="tag-icon" src={familyPlus} />
            <input
              type="text"
              placeholder="Full Name"
              {...register("Name", { required: true, minLength: 3 })}
              autoComplete="off"
              onKeyUp={() => handleAlphaOnly(data.Name, "Name")}
              onBlur={() => Blured("Name", data.Name)}
            />
            {/* {errors.Name && <img src={ThisIsAnError} alt="" id="pointer-cur" />} */}
          </div>
          {errors.Name && (
            <div className="err-text">Enter At least 3 Characters</div>
          )}

          <div
            className={
              errors.Name
                ? "input-tag error-border remove-bottom"
                : data.Name
                ? "input-tag add-border remove-bottom"
                : "input-tag remove-bottom"
            }
          >
            <img className="tag-icon" src={pin} />
            <input
              type="text"
              placeholder="City"
              {...register("City", { required: true, minLength: 3 })}
              autoComplete="off"
              onKeyUp={() => handleAlphaOnly(data.City, "City")}
              onBlur={() => Blured("City", data.City)}
            />
            {/* {errors.City && <img src={ThisIsAnError} alt="" id="pointer-cur" />} */}
          </div>
          {errors.City && (
            <div className="err-text">Enter At least 3 Characters</div>
          )}

            <p style={{margin:"8% 0"}}><strong>Rate Us</strong></p>
           <div className="rating-star-1">
                    <div className="givestar-rating">
                        <div className="rate large-latestart-star">
                            <input type="radio" id="starfavorite star.svg" name="ratng_mlc" value="5" onClick={()=>setthis(5)} />
                            <label htmlFor="starfavorite star.svg" title="text"></label>
                            <input type="radio" id="star4" name="ratng_mlc" value="4" onClick={()=>setthis(4)}/>
                            <label htmlFor="star4" title="text"></label>
                            <input type="radio" id="star3" name="ratng_mlc" value="3" onClick={()=>setthis(3)}/>
                            <label htmlFor="star3" title="text"></label>
                            <input type="radio" id="star2" name="ratng_mlc" value="2" onClick={()=>setthis(2)}/>
                            <label htmlFor="star2" title="text"></label>
                            <input type="radio" id="star1" name="ratng_mlc" value="1" onClick={()=>setthis(1)}/>
                            <label htmlFor="star1" title="text"></label>
                        </div>
                        <span id="rtng_mlc_error" className="error"></span>
                    </div>
            </div>

          <div
            className={
              "input-tag" +
              (errors.comment
                ? " error-border"
                : data.comment
                ? " add-border"
                : "")
            }
          >
            {/* <input className="input-tag-box" type="text-area" style={{ marginBottom: "2px" }} placeholder='Write your comment'
                        {...register('comment', { required: true })} autoComplete="off" /> */}
            <textarea
              className="input-tag-box"
              type="text"
              style={{ marginBottom: "2px" }}
              onBlur={() => Blured("comment", data.comment)}
              onKeyUp={() => SpaceManage("comment", data.comment)}
              placeholder="Write your comment"
              {...register("comment", { required: true, minLength: 50 })}
              autoComplete="off"
            ></textarea>
          </div>
          {errors.comment && (
            <div className="err-text">Enter At least 50 Characters</div>
          )}

          <button
            className={
              "footer-btn Tmargin manage-nomine-bottom" +
              (submit ? " footer-btn-activate" : "")
            }
          >
            Continue
          </button>
        </form>
      </div>
    </>
  );
};

export default CustomerRevForm;
