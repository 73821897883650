import forge from 'node-forge';

export const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuFKZIcpeoK79UJVXOLTZ
PvP+j5vMKiKxtIGRFlmom7l8uJxna6FMEJuJ49K2zj2U+pCWH2OP6cLnLYt03vuB
2RT1IbS1CozrFhqoJcb35JRoXFecmXaDAkvvhlX6Xw4WZyRjQmtcLziGkBwgH3Gf
DZtJLTgY13FQHqDlPxGUNIJeZg3p5Uz8mPB7mgvXGcjD2d4pqiiyZU1a+8Y9Q3Sn
Gh1u6C8HqRjkjQj0XgkptU0hRvSlO+ivcwdvrs50kz43mJQIelOFfxL7+4Smy8Yz
BAcAQLG9+LScKeVw6QVkSsbNJ7GfpCL+bOhK3usw/pGfxLze6XXWAdHeUZ7lXO5F
vQIDAQAB
-----END PUBLIC KEY-----`;

export const privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIIEowIBAAKCAQEAuFKZIcpeoK79UJVXOLTZPvP+j5vMKiKxtIGRFlmom7l8uJxn
a6FMEJuJ49K2zj2U+pCWH2OP6cLnLYt03vuB2RT1IbS1CozrFhqoJcb35JRoXFec
mXaDAkvvhlX6Xw4WZyRjQmtcLziGkBwgH3GfDZtJLTgY13FQHqDlPxGUNIJeZg3p
5Uz8mPB7mgvXGcjD2d4pqiiyZU1a+8Y9Q3SnGh1u6C8HqRjkjQj0XgkptU0hRvSl
O+ivcwdvrs50kz43mJQIelOFfxL7+4Smy8YzBAcAQLG9+LScKeVw6QVkSsbNJ7Gf
pCL+bOhK3usw/pGfxLze6XXWAdHeUZ7lXO5FvQIDAQABAoIBAAkSt8r8BTBvrLR8
xXBbJp58sDkteB1S2+CAyD2LaEtSFBuaR1T6+Z2Pqnf2dV0tltnGqD0fT4Q4Pdz2
EmivhLLE31QrawX77c6GvWQuqSEtVP3GqkS7mrNQPZglP0/Sh7PIDMLGOiFw7N7H
I6WomCW5UR7SthZU05bOYLp8CQmUk9LDkHSOcMWbZmKDudVYXtB2nVgpx188ApwB
nPNQvuJ14V0p6oScYPwwIdsyigOdyaKRkkYT9lpovwgwvmOZIyUa6HVtG7mJBYEN
tDjDcglzOEtcpOYtSy4nNUAi3ASE9ZkiCMMZzdmJqs/yqvdSbK9UdYJ7MH1+sPHK
ZXwfUxkCgYEA6jB8SWKeeDj7tBJE1uR9sqz+xwEGCSo4+Su+A3amGdWxK1T0YSD1
ymJI+UfNQghN2g3YvMmhViHcbySoYvy/WWX6EL/RLy0hs4QZtcmqDn3mG7i0BOPO
vT+FpNnOH5uKyFoeuqXkuswTCn4ykRQ2c6FJwVaoI0RsdVKVZaayza8CgYEAyX0y
JS7PvapgtAQJ8vNA5Z9ZIa+2THalI3AnQdIRgwObu3o1wXiDJ3Qk6sr5TySFm0+1
eQ85gcI9xjf0WKm8tMFHJ8qSwHJCnntKJVh5j0PYsyBHWAtcKTxljtlngQs1gJcB
rshlO0TRu2iyrgqfatk9uOBbgh7AKFzjJf/PSlMCgYEAvpKUIVfKY0TjtwaC1Gh9
zW+vqJuxoLWmRUYOS107D5zwnrV0ZLHgsj/G6GxAoPKG/+HytLZTvvaxHhSznlHV
GNe+ZfDiNkTAvwfJsEAaTc6v+XEVtUD4lNJjW+IONXRqvF1x0it7dXLqL8jAN4U+
RlxC27079+8PX23Du0OrD2sCgYAzqz0+t8BiHNzrjNWYTbTorXJH5y1ScmJqsgn/
IQWImVZIP1ClqmfHqEsHQsGn/++bIlehEbxngA4jVJn+Wpifqai7lYEIhMR8q5UZ
+pRBnt5lIPPqqg8aK/jHJw0Z1GwF67NsxMrg5gb6jiCz7NlwpwDi9fXBam1QbHF7
fxwDLQKBgG6tUU9QmA8wpbXNHYKQM7a/s5d9+UY9ogDy4xAynj9E3duFeeSouuJm
NttQOoVR3bYVQOIaF2q0ix7SPqmYv2T5shDFSZj7ftJjW0Mey3zOb1SxAY+s4F46
/Zf6UcN6E4Zpv2/Xy+ORqA8xqQNyPLyq9gnfcfVl5wvaMMi31nHB
-----END RSA PRIVATE KEY-----`;

export const encryptWithHybrid = (message) => {
    // Generate a random AES encryption key
    const aesKey = forge.random.getBytesSync(32); // 32 bytes for AES-256
    const iv = forge.random.getBytesSync(16);
    // Convert the AES key to a Forge cipher key object
    const cipherKey = forge.util.createBuffer(aesKey);

    // Create a Forge cipher object for AES encryption
    const cipher = forge.cipher.createCipher('AES-CBC', cipherKey);

    // Encrypt the message using AES
    cipher.start({ iv: iv }); // Generate a random IV
    cipher.update(forge.util.createBuffer(message, 'utf8'));
    cipher.finish();
    const encryptedMessage = cipher.output.getBytes();

    // Encrypt the AES key with RSA public key
    const rsaPublicKey = forge.pki.publicKeyFromPem(publicKey);
    const encryptedAesKey = rsaPublicKey.encrypt(aesKey, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
    });

    return {
        encryptedMessage: forge.util.encode64(encryptedMessage),
        encryptedAesKey: forge.util.encode64(encryptedAesKey),
        iv: forge.util.encode64(iv)
    };
}

export const decryptWithHybrid = (encryptedMessage, encryptedAesKey, iv) => {
    // Decrypt the AES key with RSA private key
    const rsaPrivateKey = forge.pki.privateKeyFromPem(privateKey);
    const aesKey = rsaPrivateKey.decrypt(forge.util.decode64(encryptedAesKey), 'RSA-OAEP', {
      md: forge.md.sha256.create(),
    });

    // Convert the AES key to a Forge cipher key object
    const cipherKey = forge.util.createBuffer(aesKey);

    // Create a Forge cipher object for AES decryption
    const decipher = forge.cipher.createDecipher('AES-CBC', cipherKey);

    // Decrypt the encrypted message using AES
    decipher.start({ iv: forge.util.decode64(iv) }); // Use the same IV used during encryption
    decipher.update(forge.util.createBuffer(forge.util.decode64(encryptedMessage)));
    decipher.finish();

    let decryptedMessage = decipher.output.toString('utf8');
    if (decryptedMessage.includes("\u0000")) {
        decryptedMessage = decryptedMessage.trim().replace(/\u0000/g, "");
        return JSON.parse(decryptedMessage);
    }
    else {
        return JSON.parse(decryptedMessage);
    }
}