import React, { useEffect, useState } from "react";
import "../Journey Form/Form/Form.css";
import "./partner-form.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePopUp,
  updateSuggestion_DropDown,
  updateSuggestion_DropDown2,
  updateSuggestion_DropDown3,
  updateMasterState,
  updatePartnerDocPopUp,
} from "../../Redux/action";
import email from "../../Icons/email.svg";
import calender from "../../Icons/calender.svg";
import phone from "../../Icons/phone.svg";
import pin from "../../Icons/pin.svg";
import contact from "../../Icons/contact.svg";
import man from "../../Icons/man.svg";
import {
  source,
  verification_source,
  verified_by,
  verifier_name,
  react_base_url,
} from "../Secret_Credentials/Secret_Credential";
import Lottie from "react-lottie-player";
import ctaAnimation from "../assests/lootie-files/continue.json";
import name from "../../Icons/name-icon.svg";
import {
  getCityName,
  getMasterData,
  getPinOption,
} from "../EverCalledFunctions/EverCalledApis";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Journey Form/Form/All_Forms/DOBCalender.css";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
import {
  encryptOnlyLeadId,
} from "../EverCalledFunctions/EverCalledFunctions";
import { formatDate} from "../MyAccount/MyAccountAPIs";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import DropDownIcon from "../../Icons/DropDownIcon.svg";
import FormHead from "../Journey Form/Form/FormHead";
import { AAAPIInstance, DocAPIInstance, DocUpload, apiInstance } from "../Config/APIConfig";
import PartnerUploadDoc from "./PartnerUploadDoc";
import DocInput from "./DocInput";
import bank from '../../Icons/bank.svg';

const PartnerForm = () => {
  const [resp, setResp] = useState(false);
  const [submitButton, setSubmitButtom] = useState(false);
  const [PANErr, setPANErr] = useState(false);
  // const [GSTErr, setGSTErr] = useState(false);
  const [firstClick, setFirstClick] = useState(false);
  const [device_id, setDevice_id] = useState("");
  const [selected, setSelected] = useState(-1);
  const [selected2, setSelected2] = useState(-1);
  const [selected3, setSelected3] = useState(-1);
  const [loadingTime, setLoaderTime] = useState(false);
  const [hide, setHide] = useState(true);
  const [submitButtonNew, setSubmitButtonNew] = useState(false);
  const [gstinError, setGstinError] = useState(false);
  const [documents, setDocuments] = useState({});
  const [showupload,setShowupload] = useState('');

  const [occupationDeatails, setOccupationDeatails] = useState([
    {
      id: 1,
      value: "Individual",
    },
    {
      id: 2,
      value: "Corporate Body",
    },
    {
      id: 3,
      value: "Partnership Form",
    },
  ]);

  const [pincodeData, setPincodeData] = useState({
    show: false,
    data: [],
  });

  const [ConpincodeData, setConPincodeData] = useState({
    showc: false,
    datac: [],
  });

  const [CorporatepincodeData, setCorporatePincodeData] = useState({
    showp: false,
    datap: [],
  });

  const [cityFromPin, setCityFromPin] = useState({
    city: "",
    city_id: "",
  });

  const [cityFromPin2, setCityFromPin2] = useState({
    cityc: "",
    city_idc: "",
  });

  const [cityFromPin3, setCityFromPin3] = useState({
    cityp: "",
    city_idp: "",
  });

  const [rocket, setRocket] = useState(false);

  let dispatch = useDispatch();

  const [Radioselected, setRadioSelected] = useState("");
  const [Radiogst, setRadiogst] = useState("");
  const [RadioMsme, setMsme] = useState("");

  const {
    master_state,
    PopUpState,
    suggestionDropDown,
    suggestionDropDown2,
    suggestionDropDown3,
    token,
    partnerdocupdate
  } = useSelector((state) => state);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      email: "",
      pancard: "",
      pincode: "",
      contact_pincode: "",
      dob: "",
      referrer_name: "",
      contact_person_name: "",
      contact_number: "",
      type_of_referrer: "",
      display_type_of_referrer: "",
      reg_address: "",
      contact_add: "",
      corporate_address: "",
      gstin: "",
      corporate_pincode: "",
      udyam_regi_no: "",
      certificate_upload: "",
      account_holder_name: "",
      account_number: "",
      ifsc_code: "",
    },
    mode: "all",
  });

  const data = watch();

  useEffect(() => {
    if (Radioselected == 1) {
      setValue("corporate_address", "");
      setValue("corporate_pincode", "");
      clearErrors("corporate_address","")
      clearErrors("corporate_pincode","")
    }
  }, [Radioselected]);

  useEffect(() => {
    if (Radiogst == 1) {
      setValue("gstin", "");
      clearErrors("gstin");
    }
  }, [Radiogst]);

  useEffect(() => {
    if (RadioMsme == 2) {
      setValue("udyam_regi_no", "");
      setValue("certificate_upload", "");
      clearErrors("udyam_regi_no");
    }
  }, [RadioMsme]);

  useEffect(() => {
    if (Radioselected == 2 && RadioMsme == 2) {
      if (data.corporate_address && data.corporate_pincode && data.account_holder_name && data.account_number && data.ifsc_code) {
        setSubmitButtonNew(true);
      } else {
        setSubmitButtonNew(false);
      }
    } else if (Radioselected == 1 && RadioMsme == 2) {
      if (data.account_holder_name && data.account_number && data.ifsc_code) {
        setSubmitButtonNew(true);
      }
      else {
        setSubmitButtonNew(false);
      }
    } else if (Radioselected == 1 && RadioMsme == 1) {
      if (data.account_holder_name && data.account_number && data.ifsc_code) {
        setSubmitButtonNew(true);
      }
      else {
        setSubmitButtonNew(false);
      }
    } else if (Radioselected == 2 && RadioMsme == 1) {
      if (
        data.corporate_address && data.corporate_pincode && data.account_holder_name && data.account_number && data.ifsc_code
      ) {
        setSubmitButtonNew(true);
      } else {
        setSubmitButtonNew(false);
      }
    }
  }, [data, Radioselected, RadioMsme]);

  useEffect(() => {
    if (
      data.email &&
      data.pancard &&
      data.pincode &&
      data.contact_pincode &&
      data.dob &&
      data.referrer_name &&
      data.contact_person_name &&
      data.contact_number &&
      data.type_of_referrer &&
      data.reg_address &&
      data.contact_add
    ) {
      if (Radiogst == "") {
        setSubmitButtom(false);
      } else if (Radiogst == 2) {
        if (
          data.email &&
          data.pancard &&
          data.pincode &&
          data.contact_pincode &&
          data.dob &&
          data.referrer_name &&
          data.contact_person_name &&
          data.contact_number &&
          data.type_of_referrer &&
          data.reg_address &&
          data.contact_add &&
          data.gstin
          && !gstinError
        ) {
          setSubmitButtom(true);
        } else {
          setSubmitButtom(false);
        }
      } else if (Radiogst == 1) {
        if (
          data.email &&
          data.pancard &&
          data.pincode &&
          data.contact_pincode &&
          data.dob &&
          data.referrer_name &&
          data.contact_person_name &&
          data.contact_number &&
          data.type_of_referrer &&
          data.reg_address &&
          data.contact_add
        ) {
          setSubmitButtom(true);
        } else {
          setSubmitButtom(false);
        }
      }
    }
  }, [data, Radiogst]);

  const AlphaNum = (value, field) => {
    var newVal = "";
    if (value) {
      newVal = value.replace(/[^a-z0-9]/gi, "");
      setValue(field, newVal);
    }
    SpaceManage(field, newVal);
  }


  const [Show, setShow] = useState({
    today: new Date(),
    max: new Date().getFullYear(),
    min: new Date().getFullYear() - 77,
  });

  const [startDate, setStartDate] = useState(null);
  const years = [];
  for (var i = Show.max; i > Show.min; i--) {
    years.push(i);
  }

  const maxSelectableDate = new Date();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getMonth = (date) => {
    return date.getMonth();
  };

  const getYear = (date) => {
    return date.getFullYear();
  };

  const handleDateClick = (date) => {
    if (date && date.getFullYear() < Show.min) {
      setStartDate(
        new Date(`${date.getDate()} ${months[date.getMonth()]}, ${Show.min}`)
      );
    } else if (date && date.getFullYear() > Show.max) {
      setStartDate(
        new Date(`${date.getDate()} ${months[date.getMonth()]}, ${Show.max}`)
      );
    } else {
      setStartDate(date);
    }
  };

  // const getData = async () => {
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   setIP(res.data.IPv4);
  //   dispatch(
  //     updateCredentialsState({ ...credentialState, ipaddress: res.data.IPv4 })
  //   );
  // };

  function knowdevice() {
    if (window.innerWidth > 1024) {
      setDevice_id("1");
    } else if (window.innerWidth > 480 && window.innerWidth < 1024) {
      setDevice_id("3");
    } else {
      setDevice_id("2");
    }
  }

  useEffect(() => {
    knowdevice();
    // getData();
  }, []);

  const getCityNameHere = async (pin) => {
    const APIDATA = await getCityName(pin, token);
    if (APIDATA.city && APIDATA.cityId) {
      setCityFromPin({
        ...cityFromPin,
        city: APIDATA.city,
        city_id: APIDATA.cityId,
      });
    }
  };

  const getCityNameHere2 = async (pin) => {
    const APIDATA = await getCityName(pin, token);
    if (APIDATA.city && APIDATA.cityId) {
      setCityFromPin2({
        ...cityFromPin2,
        cityc: APIDATA.city,
        city_idc: APIDATA.cityId,
      });
    }
  };

  const getCityNameHere3 = async (pin) => {
    const APIDATA = await getCityName(pin, token);
    if (APIDATA.city && APIDATA.cityId) {
      setCityFromPin3({
        ...cityFromPin3,
        cityp: APIDATA.city,
        city_idp: APIDATA.cityId,
      });
    }
  };

  const GetPinData = async (pin) => {
    const APIDATA = await getPinOption(pin, token);
    dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: true }));
    setPincodeData({ ...pincodeData, show: true, data: APIDATA });
  };

  const GetPinData2 = async (pin) => {
    const APIDATA = await getPinOption(pin, token);
    dispatch(
      updateSuggestion_DropDown2({ ...suggestionDropDown2, showc: true })
    );
    setConPincodeData({ ...ConpincodeData, showc: true, datac: APIDATA });
  };

  const GetPinData3 = async (pin) => {
    const APIDATA = await getPinOption(pin, token);
    dispatch(
      updateSuggestion_DropDown3({ ...suggestionDropDown3, showp: true })
    );
    setCorporatePincodeData({
      ...CorporatepincodeData,
      showp: true,
      datap: APIDATA,
    });
  };

  const handleAlphaOnly = (value, field) => {
    var regexp = /[^a-zA-Z ]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""));
    } else {
      setValue(field, value.replace(/^\s+/, ""));
    }
  };

  const NumberOnly = (value) => {
    var lastseven = value.substr(value.length - 7);
    var num_charstring_number = value.substr(4, 4);
    var last_digit = value.slice(0, 2);
    var cr_str = Array(6).join(last_digit);
    var result = false;
    if (/^[6789]\d{9}$/i.test(value)) {
      result = true;
    }
    if (value === "9876543210") {
      result = false;
    }
    if (/^(\d)\1+$/.test(lastseven)) {
      result = false;
    }
    if (value === cr_str) {
      result = false;
    }
    if (num_charstring_number == "XXXX") {
      result = true;
    }
    if (/(\d)\1{6,}/.test(value)) {
      result = false;
    }
    return result;
  };

  useEffect(() => {
    if (data?.pincode) {
      if (data?.pincode?.length < 3) {
        setPincodeData({ ...pincodeData, show: false, data: [] });
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" });
      } else if (
        data?.pincode >= 110 &&
        data?.pincode?.length >= 3 &&
        data?.pincode?.length < 6
      ) {
        GetPinData(data.pincode);
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" });
      } else if (data?.pincode <= 110000) {
        setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" });
      } else if (data?.pincode?.length === 6) {
        getCityNameHere(data.pincode);
        setPincodeData({ ...pincodeData, show: false, data: [] });
      } else {
        setPincodeData({ ...pincodeData, show: false, data: [] });
      }
    } else {
      setPincodeData({ ...pincodeData, show: false, data: [] });
      setCityFromPin({ ...cityFromPin, city: "Invalid Pincode" });
    }
  }, [data.pincode]);

  useEffect(() => {
    if (data?.contact_pincode) {
      if (data?.contact_pincode?.length < 3) {
        setConPincodeData({ ...ConpincodeData, showc: false, datac: [] });
        setCityFromPin2({ ...cityFromPin2, cityc: "Invalid Pincode" });
      } else if (
        data?.contact_pincode >= 110 &&
        data?.contact_pincode?.length >= 3 &&
        data?.contact_pincode?.length < 6
      ) {
        GetPinData2(data.contact_pincode);
        setCityFromPin2({ ...cityFromPin2, cityc: "Invalid Pincode" });
      } else if (data?.contact_pincode <= 110000) {
        setCityFromPin2({ ...cityFromPin2, cityc: "Invalid Pincode" });
      } else if (data?.contact_pincode?.length === 6) {
        getCityNameHere2(data.contact_pincode);
        setConPincodeData({ ...ConpincodeData, showc: false, datac: [] });
      } else {
        setConPincodeData({ ...ConpincodeData, showc: false, datac: [] });
      }
    } else {
      setConPincodeData({ ...ConpincodeData, showc: false, datac: [] });
      setCityFromPin2({ ...cityFromPin2, cityc: "Invalid Pincode" });
    }
  }, [data.contact_pincode]);

  useEffect(() => {
    if (data?.corporate_pincode) {
      if (data?.corporate_pincode?.length < 3) {
        setCorporatePincodeData({
          ...CorporatepincodeData,
          showp: false,
          datap: [],
        });
        setCityFromPin3({ ...cityFromPin3, cityp: "Invalid Pincode" });
      } else if (
        data?.corporate_pincode >= 110 &&
        data?.corporate_pincode?.length >= 3 &&
        data?.corporate_pincode?.length < 6
      ) {
        GetPinData3(data.corporate_pincode);
        setCityFromPin3({ ...cityFromPin3, cityp: "Invalid Pincode" });
      } else if (data?.corporate_pincode <= 110000) {
        setCityFromPin3({ ...cityFromPin3, cityp: "Invalid Pincode" });
      } else if (data?.corporate_pincode?.length === 6) {
        getCityNameHere3(data.corporate_pincode);
        setCorporatePincodeData({ ...ConpincodeData, showp: false, datap: [] });
      } else {
        setCorporatePincodeData({
          ...CorporatepincodeData,
          showp: false,
          datap: [],
        });
      }
    } else {
      setCorporatePincodeData({
        ...CorporatepincodeData,
        showp: false,
        datap: [],
      });
      setCityFromPin3({ ...cityFromPin3, cityp: "Invalid Pincode" });
    }
  }, [data.corporate_pincode]);

  function ValidateFile(file) {
    var allowedExtensions = ["jpg", "jpeg", "pdf"];
    var fileExtension = file.name.split(".").pop().toLowerCase();
    if (allowedExtensions.indexOf(fileExtension) === -1) {
      return false;
    }
    return true;
  }

  const redirectFunc = (res) => {
    if (res.esign_done == 0) {
      let partner_id = res.partner_id;
      let encry_partner_id = encryptOnlyLeadId(partner_id);
      window.location.href = `${react_base_url}loan-agreement-partner-form/?agrid=${encry_partner_id}`;
    }
    else {
      let partner_id = res.partner_id;
      let encry_partner_id = encryptOnlyLeadId(partner_id);
      let newUrl = `${react_base_url}partner-thank-you/?fid=${encry_partner_id}`;
      newUrl += "&Mk1nPT0";
      window.location.href = newUrl;
    }
  }

  const ovdocrfxn = async (res) => {
    const formData = new FormData();

    formData.append("partner_id", res.partner_id);
    formData.append("verified_by", verified_by);
    formData.append("verifier_name", verifier_name);
    formData.append("verification_source", verification_source);
    formData.append("source", source);
    formData.append("type_of_referrer", data.type_of_referrer);
    partnerdocupdate.data.map((item) => {
      if (item?.sub_dict?.length > 0) {
        item.sub_dict.map((row) => {
          formData.append(`${row.keys}[]`, documents[row.id] ? documents[row.id].file : "null")
          formData.append(`${row.keys}_id`, documents[row.id] ? row.id : 0)
        })
      } else {
        formData.append(`${item.keys}[]`, documents[item.id] ? documents[item.id].file : "null")
        formData.append(`${item.keys}_id`, documents[item.id] ? item.id : 0)
      }
    })

    const ApiRes = await DocUpload({ token, data: formData, url: 'upload-certificate' })
    // const ApiRes = await AAAPIInstance({ token, data: formData, url: 'upload-certificate' })
    if (ApiRes.status == 1 && ApiRes.status_code == 200) {
      redirectFunc(res)
    } else {
      setRocket(true)
    }
  }

  {
    /* ------------------------------Submit Function---------------------------------------- */
  }
  const onSubmit = async (result) => {
    setResp(true);
    delete result.display_type_of_referrer;

    let payload = {
      source: source,
      referrer_name: data.referrer_name,
      registered_address: data.reg_address,
      registered_address_pincode: data.pincode,
      is_corporate_address_same_as_registered: Radioselected,
      corporate_address: data.corporate_address,
      corporate_address_pincode: data.corporate_pincode,
      type_of_referrer: data.type_of_referrer,
      pan: data.pancard.toUpperCase(),
      date_of_incorporation: formatDate(data.dob),
      gstin: data.gstin,
      contact_person_name: data.contact_person_name,
      contact_no: data.contact_number,
      contact_address: data.contact_add,
      contact_address_pincode: data.contact_pincode,
      contact_email: data.email,
      verification_source: verification_source,
      verified_by: verified_by,
      verifier_name: verifier_name,
      registered_under_MSME: RadioMsme,
      udyam_registration_number: data.udyam_regi_no,
      "page_url":window.location.href,
      beneficiary_name: data.account_holder_name,
      ifsc_code: data.ifsc_code,
      bank_account_number: data.account_number
    };
    // if ((RadioMsme == 1 && (data.certificate_upload && ValidateFile(data.certificate_upload[0]))) || RadioMsme == 2) {

      const res = await apiInstance.post(
        "partner-basic-details-submit",
        payload,
        { token }
      );
      if (res.status == 1 && res.status_code == "200") {
        if (Object.keys(documents).length > 0) {
          ovdocrfxn(res.data)
        } else {
          redirectFunc(res.data)
        }
      } else {
        setRocket(true);
      }
    // } else {
    //   setValue("certificate_upload", "")
    //   setResp(false)
    // }
  };

  const Blured = (field, value) => {
    var newVal = "";
    if (value.length !== 0 && typeof value === "string") {
      newVal = String(value).replace(/^\s+|\s+$/g, "");
    }
    setValue(field, newVal);
    if (newVal && newVal.length < 3) {
      setError(field);
    } else {
      clearErrors([field]);
    }
  };

  const hideDOBPopUP = (e) => {
    if (PopUpState?.DOBPopUp?.show) {
      const updatedDOBPopUp = { ...PopUpState.DOBPopUp, show: false };
      dispatch(updatePopUp({ ...PopUpState, DOBPopUp: updatedDOBPopUp }));
      setPincodeData({ ...pincodeData, show: false });
      setConPincodeData({ ...ConpincodeData, showc: false });
      setCorporatePincodeData({ ...CorporatepincodeData, showp: false });
    }
    e.stopPropagation();
  };

  const setThisPin = (pin) => {
    setValue("pincode", pin);
    setPincodeData({ ...pincodeData, show: false });
    clearErrors(["pincode"]);
    getCityNameHere(pin);
  };

  const setThisPin2 = (pin) => {
    setValue("contact_pincode", pin);
    setConPincodeData({ ...ConpincodeData, showc: false });
    clearErrors(["contact_pincode"]);
    getCityNameHere2(pin);
  };

  const setThisPin3 = (pin) => {
    setValue("corporate_pincode", pin);
    setCorporatePincodeData({ ...CorporatepincodeData, showp: false });
    clearErrors(["corporate_pincode"]);
    getCityNameHere3(pin);
  };

  const handleUpKeyNumber = (value, field) => {
    var regexp = /[^0-9]/g;
    if (
      value.length !== 0 &&
      typeof value === "string" &&
      value.match(regexp)
    ) {
      setValue(field, value.replace(regexp, ""));
    }
  };

  useEffect(() => {
    let vaidationRegex =
      data.type_of_referrer == 1
        ? /([a-zA-Z]){3}([pP]){1}([A-Za-z]){1}([0-9]){4}([a-zA-Z]){1}$/
        : (data.type_of_referrer == 2 ? /([a-zA-Z]){3}([cC]){1}([A-Za-z]){1}([0-9]){4}([a-zA-Z]){1}$/ : (data.type_of_referrer == 3 ? /([a-zA-Z]){3}([fF]){1}([A-Za-z]){1}([0-9]){4}([a-zA-Z]){1}$/ : ""))
    if (data.pancard.match(vaidationRegex)) {
      clearErrors("pancard");
    } else if (
      data.pancard.length == 10 &&
      !data.pancard.match(vaidationRegex)
    ) {
      setError("pancard");
    }
    if (data.type_of_referrer == 1) {
      setShow({ ...Show, max: new Date().getFullYear() - 21 })
      setStartDate(null)
    }
    else {
      setShow({ ...Show, max: new Date().getFullYear() })
      setStartDate(null)
    }
  }, [data.type_of_referrer]);

  useEffect(() => {
    if (data.gstin.length >= 12) {
      let subStr = data.gstin.substring(2, 12);
      if (subStr.toUpperCase() !== data.pancard.toUpperCase()) {
        setGstinError(true);
      } else {
        setGstinError(false);
      }
    }
  }, [data.gstin]);

  const PANCheck = () => {
    if (data?.pancard && data?.pancard?.length !== 10) {
      setPANErr(true);
    }
  };

  // const GSTCheck = () => {
  //   if (data?.gstin && data?.gstin?.length !== 15) {
  //     setGSTErr(true);
  //   }
  // };

  const SpaceManage = (field, value) => {
    if (value.length !== 0 && typeof value === "string") {
      setValue(field, String(value).replace(/^\s+|\s+$/g, ""));
    }
  };

  const SpecialChar = (field, value) => {
    var newVal = "";
    if (value) {
      newVal = value.replace(/[^a-z0-9]/gi, "");
      setValue(field, newVal);
    }
    SpaceManage(field, newVal);
  };

  const handleArrows = (e) => {
    if (pincodeData?.show) {
      var key = e.keyCode;
      if (key != 40 && key != 38 && key != 13) {
        return;
      }
      if (key == 13) {
        e.stopPropagation();
        if (selected > -1 && selected < pincodeData.data.length) {
          setThisPin(pincodeData.data[selected]);
          // window.scrollTo({ top: 1000, behavior: "smooth" });
          setSelected(-1);
        } else {
          setSelected(-1);
        }
      } else if (key == 38) {
        if (selected > 0) {
          setSelected(selected - 1);
        }
      } else if (key == 40) {
        if (selected < pincodeData?.data?.length - 1) {
          setSelected(selected + 1);
        }
      }
    }
  };

  // ..........Contact Pincode...........///
  const handleArrows2 = (e) => {
    if (ConpincodeData?.showc) {
      var key = e.keyCode;
      if (key != 40 && key != 38 && key != 13) {
        return;
      }
      if (key == 13) {
        e.stopPropagation();
        if (selected2 > -1 && selected2 < ConpincodeData.datac.length) {
          setThisPin2(ConpincodeData.datac[selected2]);
          // window.scrollTo({ top: 1000, behavior: "smooth" });
          setSelected2(-1);
        } else {
          setSelected2(-1);
        }
      } else if (key == 38) {
        if (selected2 > 0) {
          setSelected2(selected2 - 1);
        }
      } else if (key == 40) {
        if (selected2 < ConpincodeData?.datac?.length - 1) {
          setSelected2(selected2 + 1);
        }
      }
    }
  };

  // ...............Corporate Pincode...............///
  const handleArrows3 = (e) => {
    if (CorporatepincodeData?.showp) {
      var key = e.keyCode;
      if (key != 40 && key != 38 && key != 13) {
        return;
      }
      if (key == 13) {
        e.stopPropagation();
        if (selected3 > -1 && selected3 < CorporatepincodeData.datap.length) {
          setThisPin3(CorporatepincodeData.datap[selected3]);
          // window.scrollTo({ top: 1000, behavior: "smooth" });
          setSelected3(-1);
        } else {
          setSelected3(-1);
        }
      } else if (key == 38) {
        if (selected3 > 0) {
          setSelected2(selected3 - 1);
        }
      } else if (key == 40) {
        if (selected3 < CorporatepincodeData?.datap?.length - 1) {
          setSelected3(selected3 + 1);
        }
      }
    }
  };

  useEffect(() => {
    if (startDate) {
      var day = startDate.getDate();
      var month =
        startDate.getMonth() + 1 < 10
          ? "0" + (startDate.getMonth() + 1)
          : startDate.getMonth() + 1;
      var year = startDate.getFullYear();
      setValue("dob", year + "-" + month + "-" + day);
    } else {
      setValue("dob", "");
    }
  }, [startDate]);

  const handleError = (e) => {
    setRocket(false);
    onSubmit(data);
  };

  const openFormOptions = async () => {
    if (occupationDeatails?.length) {
      dispatch(
        updateMasterState({
          ...master_state,
          values: occupationDeatails,
          status: true,
          heading: "Select Type of Referrer",
          pf: null,
        })
      );
    } else {
      setLoaderTime(true);
      const APIDATA = await getMasterData("occupation", token);
      dispatch(
        updateMasterState({
          ...master_state,
          values: APIDATA,
          status: true,
          heading: "Select Occupation type",
          pf: null,
        })
      );
      setOccupationDeatails(APIDATA);
      setLoaderTime(false);
    }
  };

  useEffect(() => {
    if (master_state?.pf) {
      setValue("type_of_referrer", master_state.pf.id);
      setValue("display_type_of_referrer", master_state.pf.value);
    }
  }, [master_state]);

  const partnerMaster = async () => {
    let api_type = data.type_of_referrer == 1 ? "partner_agreement_document_indiviual" : "partner_agreement_document_others"
    let dec = await apiInstance.post(`master`, {
      "source": source,
      "api_type": api_type,
      "verification_source": verification_source,
      "verified_by": verified_by,
      "verifier_name": verifier_name
  }, {
      "token": token
  })
  // console.log(dec);
  // console.log(dec.status_code);
  // console.log(dec.data);
    // console.log(res.show_upload);
    setShowupload(dec.show_upload)
    dispatch(updatePartnerDocPopUp({ ...partnerdocupdate, data: dec.data }))
  }

  const onSubmitNew = () => {
    setHide(false);
    partnerMaster()
    window.scrollTo(0, 0);
  };

  const handleDocInputs = (item) => {
    if (item?.sub_dict?.length > 0) {
      return item?.sub_dict?.map((row) => {
        return <DocInput data={row} documents={documents} setDocuments={setDocuments} />
      })
    }
    return <DocInput data={item} documents={documents} setDocuments={setDocuments} />
  }

  return (
    <div className="form-box">
      <div className="form-body">
        {loadingTime && <CKYCWaiting />}
        {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
        <FormHead
          formName="Partner Form"
          progressBar={true}
        //   formSubHead={"Complete your profile to know loan eligibility"}
        />
        <div className="tag-for-input">
          {/* ------------------------------- Form Fields ------------------------------------ */}
          {hide ? (
            <form onSubmit={handleSubmit(onSubmitNew)} autoComplete={"off"}>
              {/* ------------------------------- Referrer Name field ------------------------------------ */}
              <div
                className={
                  "input-tag remove-bottom" +
                  (errors.referrer_name && data.referrer_name < 3
                    ? " error-border"
                    : data.referrer_name
                      ? " add-border"
                      : "")
                }
              >
                <img src={man} alt=""/>
                <input
                  disabled={resp}
                  className="pointer-cur"
                  type="text"
                  maxLength={60}
                  placeholder="Referrer Name"
                  {...register("referrer_name", {
                    required: true,
                    minLength: 3,
                  })}
                  // onKeyUp={() =>
                  //   handleAlphaOnly(data.referrer_name, "referrer_name")
                  // }
                  onBlur={() => Blured("referrer_name", data.referrer_name)}
                  value={
                    data.referrer_name
                      ? data.referrer_name.toLocaleString("en-IN")
                      : ""
                  }
                  autoComplete="off"
                />
              </div>
              {errors.referrer_name && (
                <div className="api-text err-text">
                  {data.referrer_name && data.referrer_name.length < 3
                    ? "Enter Atleast 3 Characters"
                    : "This field is required"}
                </div>
              )}

              {/* ------------------------------- Resistered Address field ------------------------------------ */}
              <div
                className={
                  "input-tag remove-bottom" +
                  (errors.reg_address && data.reg_address < 3
                    ? " error-border"
                    : data.reg_address
                      ? " add-border"
                      : "")
                }
              >
                <img src={man} alt=""/>
                <input
                  disabled={resp}
                  className="pointer-cur"
                  type="text"
                  placeholder="Registered/Office Address"
                  {...register("reg_address", {
                    required: true,
                    minLength: 3,
                  })}
                  //   onKeyUp={() =>
                  //     handleAlphaOnly(data.reg_address, "reg_address")
                  //   }
                  onBlur={() => Blured("reg_address", data.reg_address)}
                  value={
                    data.reg_address
                      ? data.reg_address.toLocaleString("en-IN")
                      : ""
                  }
                  autoComplete="off"
                />
              </div>
              {errors.reg_address && (
                <div className="api-text err-text">
                  {data.reg_address && data.reg_address.length < 3
                    ? "Enter Atleast 3 Characters"
                    : "This field is required"}
                </div>
              )}

              {/* ----------------------------- Resistered PINCode Field--------------------------------- */}
              <div
                className={
                  "input-tag remove-bottom" +
                  (errors.pincode
                    ? " error-border"
                    : data.pincode
                      ? " add-border"
                      : "")
                }
              >
                <img className="tag-icon" src={pin} alt=""/>
                <input
                  disabled={resp}
                  className="input-tag-box"
                  type="tel"
                  minLength={6}
                  maxLength={6}
                  placeholder="Pincode"
                  {...register("pincode", {
                    required: true,
                    minLength: 6,
                    maxLength: 6,
                  })}
                  onKeyUp={() => handleUpKeyNumber(data.pincode, "pincode")}
                  autoComplete="off"
                  onFocus={(e) => hideDOBPopUP(e)}
                  onKeyDown={(e) => handleArrows(e)}
                />
                {suggestionDropDown.show &&
                  pincodeData.show &&
                  pincodeData &&
                  pincodeData.data.length &&
                  pincodeData.data.length < 6 && (
                    <div className="dropDownBox">
                      {pincodeData.data.map((row, ind) => {
                        return (
                          <div
                            className={
                              "dropDownValue" +
                              (ind === selected ? " select" : "")
                            }
                            key={ind}
                            onClick={() => setThisPin(row)}
                          >
                            {row}
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
              {data.pincode && (
                <div
                  className={
                    errors.pincode ? "api-text err-text " : "api-text "
                  }
                >
                  {cityFromPin.city}
                </div>
              )}

              {!data.pincode && errors.pincode && (
                <div className={"api-text err-text "}>Invalid Pincode</div>
              )}

              {/* ---------------------------------- Referrer Type Field ------------------------------ */}
              <div
                className={
                  "input-tag remove-bottom" +
                  (errors.type_of_referrer && !data.type_of_referrer
                    ? " error-border"
                    : data.type_of_referrer
                      ? " add-border"
                      : "")
                }
              >
                <img className="tag-icon" src={name} alt=""/>
                <input
                  disabled={resp}
                  className="pointer-cur"
                  type="text"
                  placeholder="Select Type of Referrer"
                  readOnly
                  {...register("display_type_of_referrer")}
                  onFocus={() => openFormOptions()}
                />
                <img
                  src={DropDownIcon}
                  className={
                    "small-the-icon pointer-cur" +
                    (master_state.show ? " img-rotateing" : "")
                  }
                  onClick={() => openFormOptions()}
                  alt=""
                />
              </div>
              {!data.type_of_referrer && errors.type_of_referrer && (
                <div className="err-text">Select Type of Referrer</div>
              )}

              {/* ------------------------------------- Pan input Field ---------------------------- */}
              <div
                className={
                  "input-tag remove-bottom " +
                  (errors.pancard
                    ? " error-border " + (!errors.pancard ? " pan-case" : "")
                    : data.pancard
                      ? " add-border "
                      : "")
                }
              >
                <img className="tag-icon" src={contact} alt=""/>
                <input
                  disabled={resp}
                  className={
                    "input-tag-box " + (data.pancard ? " capLetter" : "")
                  }
                  id="pan-field"
                  type="text"
                  minLength={10}
                  maxLength={10}
                  placeholder="PAN Number"
                  {...register("pancard", {
                    required: true,
                    pattern: {
                      value:
                        data.type_of_referrer == 1
                          ? /([a-zA-Z]){3}([pP]){1}([A-Za-z]){1}([0-9]){4}([a-zA-Z]){1}$/
                          : (data.type_of_referrer == 2 ? /([a-zA-Z]){3}([cC]){1}([A-Za-z]){1}([0-9]){4}([a-zA-Z]){1}$/ : (data.type_of_referrer == 3 ? /([a-zA-Z]){3}([fF]){1}([A-Za-z]){1}([0-9]){4}([a-zA-Z]){1}$/ : "")),
                    },
                  })}
                  autoComplete="off"
                  onFocus={(e) => hideDOBPopUP(e)}
                  onKeyUp={() => SpecialChar("pancard", data.pancard)}
                  onBlur={() => PANCheck()}
                />
              </div>
              {errors.pancard && data.pancard && (
                <div className={"err-text" + (!PANErr ? " pan-text" : "")}>
                  Enter a valid PAN format (Example-ABCDE1234F)
                </div>
              )}

              {/* ------------------------------------ DOBinput Field------------------------------------- */}
              <div
                style={{ justifyContent: "flex-start" }}
                className={
                  "input-tag remove-bottom" +
                  (firstClick && !data.dob
                    ? " error-border"
                    : data.dob
                      ? " add-border"
                      : "")
                }
              >
                <img className="tag-icon" src={calender} alt=""/>
                <DatePicker
                  renderCustomHeader={({ date, changeYear, changeMonth }) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0 15px",
                        gap: 10,
                      }}
                    >
                      <select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  selected={startDate}
                  dateFormat="dd-MMM-yyyy"
                  maxDate={maxSelectableDate}
                  onChange={(date) => handleDateClick(date)}
                  placeholderText="DOB / Incorporation"
                  disabled={resp}
                />
              </div>
              {!data.dob && firstClick && (
                <div className="err-text">Select Date Of Birth</div>
              )}

              {/* -------------------------------------Contact Person Field---------------------------- */}
              <div
                className={
                  "input-tag remove-bottom" +
                  (errors.contact_person_name && data.contact_person_name < 3
                    ? " error-border"
                    : data.contact_person_name
                      ? " add-border"
                      : "")
                }
              >
                <img src={man} alt=""/>
                <input
                  disabled={resp}
                  className="pointer-cur"
                  type="text"
                  placeholder="Contact Person Name"
                  {...register("contact_person_name", {
                    required: true,
                    minLength: 3,
                  })}
                  onKeyUp={() =>
                    handleAlphaOnly(
                      data.contact_person_name,
                      "contact_person_name"
                    )
                  }
                  onBlur={() =>
                    Blured("contact_person_name", data.contact_person_name)
                  }
                  value={
                    data.contact_person_name
                      ? data.contact_person_name.toLocaleString("en-IN")
                      : ""
                  }
                  autoComplete="off"
                />
              </div>
              {errors.contact_person_name && (
                <div className="api-text err-text">
                  {data.contact_person_name &&
                    data.contact_person_name.length < 3
                    ? "Enter Atleast 3 Characters"
                    : "This field is required"}
                </div>
              )}
              {/* -------------------------------------Contact Person Mobile input Field---------------------------- */}
              <div
                className={
                  "input-tag remove-bottom" +
                  (errors.contact_number
                    ? " error-border"
                    : data.contact_number
                      ? "add-border"
                      : "")
                }
              >
                <img src={phone} alt=""/>
                <input
                  disabled={resp}
                  className="pointer-cur"
                  type="tel"
                  placeholder="Contact Number"
                  {...register("contact_number", {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                    validate: NumberOnly,
                  })}
                  value={
                    data.contact_number
                      ? data.contact_number.toLocaleString("en-IN")
                      : ""
                  }
                  autoComplete="off"
                  onKeyUp={() =>
                    handleUpKeyNumber(data.contact_number, "contact_number")
                  }
                  onBlur={() => Blured("contact_number", data.contact_number)}
                  minLength={10}
                  maxLength={10}
                />
              </div>
              {errors.contact_number && data.contact_number && (
                <div className="api-text err-text">Enter a valid number</div>
              )}

              {/* --------------------------------Email Input Field----------------------------- */}
              <div
                className={
                  "input-tag remove-bottom" +
                  (errors.email
                    ? " error-border"
                    : data.email
                      ? " add-border"
                      : "")
                }
              >
                <img className="tag-icon" src={email} alt=""/>
                <input
                  disabled={resp}
                  className="input-tag-box"
                  type="text"
                  placeholder="Email Address"
                  maxLength={50}
                  {...register("email", {
                    required: true,
                    pattern: {
                      value:
                        /^([a-zA-Z0-9_.+-]{3,64})+\@(([a-zA-Z0-9-]{2,255})+\.)+([a-zA-Z0-9]{2,8})+$/,
                    },
                  })}
                  autoComplete="off"
                  onFocus={(e) => hideDOBPopUP(e)}
                  onKeyUp={() => SpaceManage("email", data.email)}
                />
              </div>
              {data.email && !errors.email && (
                <div className="api-text remove-bottom"></div>
              )}
              {errors.email && data.email && (
                <div className="err-text remove-bottom">
                  Invalid Email Address
                </div>
              )}
              {errors.email && !data.email && (
                <div className="err-text remove-bottom">
                  Enter Email Address
                </div>
              )}

              {/* ----------------------------- Contact Address -------------------------------- */}
              <div
                className={
                  "input-tag remove-bottom" +
                  (errors.contact_add && data.contact_add < 3
                    ? " error-border"
                    : data.contact_add
                      ? " add-border"
                      : "")
                }
              >
                <img src={man} alt=""/>
                <input
                  disabled={resp}
                  className="pointer-cur"
                  type="text"
                  placeholder="Contact Address"
                  {...register("contact_add", {
                    required: true,
                    minLength: 3,
                  })}
                  //   onKeyUp={() =>
                  //     handleAlphaOnly(data.contact_add, "contact_add")
                  //   }
                  onBlur={() => Blured("contact_add", data.contact_add)}
                  value={
                    data.contact_add
                      ? data.contact_add.toLocaleString("en-IN")
                      : ""
                  }
                  autoComplete="off"
                />
              </div>
              {errors.contact_add && (
                <div className="api-text err-text">
                  {data.contact_add && data.contact_add.length < 3
                    ? "Enter Atleast 3 Characters"
                    : "This field is required"}
                </div>
              )}

              {/* -----------------------------Contact PINCode  Field--------------------------------- */}
              <div
                className={
                  "input-tag remove-bottom" +
                  (errors.contact_pincode
                    ? " error-border"
                    : data.contact_pincode
                      ? " add-border"
                      : "")
                }
              >
                <img className="tag-icon" src={pin} alt=""/>
                <input
                  disabled={resp}
                  className="input-tag-box"
                  type="tel"
                  minLength={6}
                  maxLength={6}
                  placeholder="Contact Pincode"
                  {...register("contact_pincode", {
                    required: true,
                    minLength: 6,
                    maxLength: 6,
                  })}
                  onKeyUp={() =>
                    handleUpKeyNumber(data.contact_pincode, "contact_pincode")
                  }
                  autoComplete="off"
                  onFocus={(e) => hideDOBPopUP(e)}
                  onKeyDown={(e) => handleArrows2(e)}
                />
                {suggestionDropDown2.showc &&
                  ConpincodeData.showc &&
                  ConpincodeData &&
                  ConpincodeData.datac.length &&
                  ConpincodeData.datac.length < 6 && (
                    <div className="dropDownBox">
                      {ConpincodeData.datac.map((row, ind) => {
                        return (
                          <div
                            className={
                              "dropDownValue" +
                              (ind === selected2 ? " select" : "")
                            }
                            key={ind}
                            onClick={() => setThisPin2(row)}
                          >
                            {row}
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
              {data.contact_pincode && (
                <div
                  className={
                    errors.contact_pincode ? "api-text err-text " : "api-text "
                  }
                >
                  {cityFromPin2.cityc}
                </div>
              )}

              {!data.contact_pincode && errors.contact_pincode && (
                <div className={"api-text err-text "}>Invalid Pincode</div>
              )}

              {/* -----------------------------  GSTIN Field  --------------------------------- */}
              <div className="partner-res-question">
                <span>GSTIN</span>
              </div>
              <div className="partner-options-confirm">
                <label>
                  <input
                    type="radio"
                    name="gstinRadio"
                    onClick={() => setRadiogst(1)}
                    value="option1"
                    checked={Radiogst === 1}
                  />
                  Not Applicable
                </label>
                <label>
                  <input
                    type="radio"
                    onClick={() => setRadiogst(2)}
                    name="gstinRadio"
                    value="option2"
                    checked={Radiogst === 2}
                  />
                  Applicable{" "}
                </label>
              </div>

              {/* --------------------------- GSTIN NUMBER ------------------------------------------ */}

              {Radiogst === 2 && (
                <div
                  className={
                    "input-tag remove-bottom" +
                    // (errors.gstin && data.gstin < 15
                    //   ? " error-border"
                    //   : data.gstin
                    //     ? " add-border"
                    //     : "")
                    (errors.gstin || gstinError
                      ? " error-border " +
                      (!errors.gstin || !gstinError ? " pan-case" : "")
                      : data.gstin
                        ? " add-border "
                        : "")
                  }
                >
                  <img src={man} alt=""/>
                  <input
                    disabled={resp}
                    //className="pointer-cur"
                    className={
                      "input-tag-box " + (data.gstin ? " capLetter" : "")
                    }
                    type="text"
                    placeholder="GSTIN"
                    minLength={15}
                    maxLength={15}
                    {...register("gstin", {
                      required: true,
                      pattern: {
                        value:
                          /^(0[2-9]|1[0-9]|2[0-7]|2[6-9]|3[0-8]|97)[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9]{1}[Zz]{1}[A-Za-z0-9]{1}$/,
                      },
                    })}
                    //   onKeyUp={() =>
                    //     handleAlphaOnly(data.gstin, "gstin")
                    //   }
                    //onBlur={() => Blured("gstin", data.gstin)}
                    onFocus={(e) => hideDOBPopUP(e)}
                    //  onKeyUp={() => SpaceManage("email", data.email)}
                    // onBlur={() => GSTCheck()}
                    value={data.gstin ? data.gstin.toLocaleString("en-IN") : ""}
                    autoComplete="off"
                  />
                </div>
              )}
              {(errors.gstin || gstinError) && (
                <div className="api-text err-text">
                  {data.gstin && data.gstin.length < 15
                    ? "Enter Valid GSTIN "
                    : "Use format 22AAAAA0000A1Z5"}
                </div>
              )}

              {/* ---------------------------------Submit button----------------------------------------- */}
              <button
                type="submit"
                disabled={resp || !submitButton}
                className={
                  submitButton && Object.keys(errors).length === 0
                    ? "footer-btn Tmargin footer-btn-activate Bmargin"
                    : "footer-btn Tmargin Bmargin"
                }
                onClick={() => setFirstClick(true)}
              >
                {!resp ? (
                  "Continue"
                ) : (
                  <div className="continue-lottie">
                    <Lottie
                      loop
                      animationData={ctaAnimation}
                      play
                      className="ctn-loader"
                    />
                  </div>
                )}
              </button>
            </form>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* ---------------------Is Corporate Address same or different from above----------------------- */}
              <div className="partner-res-question">
                <span>Is Corporate Address same or different from above?</span>
              </div>
              <div className="partner-options-confirm">
                <label>
                  <input
                    type="radio"
                    name="radioButton"
                    onClick={() => setRadioSelected(1)}
                    value="option1"
                    checked={Radioselected === 1}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    onClick={() => setRadioSelected(2)}
                    name="radioButton"
                    value="option2"
                    checked={Radioselected === 2}
                  />
                  No{" "}
                </label>
              </div>
              {/* ---------------------If Corporate Address different from above----------------------- */}

              {/* -------------------------Corporate Address field ------------------------------ */}
              {Radioselected === 2 && (
                <div
                  className={
                    "input-tag remove-bottom" +
                    (errors.corporate_address && data.corporate_address < 3
                      ? " error-border"
                      : data.corporate_address
                        ? " add-border"
                        : "")
                  }
                >
                  <img src={man} alt=""/>
                  <input
                    disabled={resp}
                    className="pointer-cur"
                    type="text"
                    placeholder="Corporate Address"
                    {...register("corporate_address", {
                      required: true,
                      minLength: 3,
                    })}
                    //   onKeyUp={() =>
                    //     handleAlphaOnly(data.corporate_address, "corporate_address")
                    //   }
                    onBlur={() =>
                      Blured("corporate_address", data.corporate_address)
                    }
                    value={
                      data.corporate_address
                        ? data.corporate_address.toLocaleString("en-IN")
                        : ""
                    }
                    autoComplete="off"
                  />
                </div>
              )}
              {errors.corporate_address && (
                <div className="api-text err-text">
                  {data.corporate_address && data.corporate_address.length < 3
                    ? "Enter Atleast 3 Characters"
                    : "This field is required"}
                </div>
              )}

              {/* ----------------------------- Corporate address PINCode  Field --------------------------------- */}
              {Radioselected === 2 && (
                <div
                  className={
                    "input-tag remove-bottom" +
                    (errors.corporate_pincode
                      ? " error-border"
                      : data.corporate_pincode
                        ? " add-border"
                        : "")
                  }
                >
                  <img className="tag-icon" src={pin} alt=""/>
                  <input
                    disabled={resp}
                    className="input-tag-box"
                    type="tel"
                    minLength={6}
                    maxLength={6}
                    placeholder="Pincode"
                    {...register("corporate_pincode", {
                      required: true,
                      minLength: 6,
                      maxLength: 6,
                    })}
                    onKeyUp={() =>
                      handleUpKeyNumber(
                        data.corporate_pincode,
                        "corporate_pincode"
                      )
                    }
                    autoComplete="off"
                    onFocus={(e) => hideDOBPopUP(e)}
                    onKeyDown={(e) => handleArrows3(e)}
                  />
                  {suggestionDropDown3.showp &&
                    CorporatepincodeData.showp &&
                    CorporatepincodeData &&
                    CorporatepincodeData.datap.length &&
                    CorporatepincodeData.datap.length < 6 && (
                      <div className="dropDownBox">
                        {CorporatepincodeData.datap.map((row, ind) => {
                          return (
                            <div
                              className={
                                "dropDownValue" +
                                (ind === selected3 ? " select" : "")
                              }
                              key={ind}
                              onClick={() => setThisPin3(row)}
                            >
                              {row}
                            </div>
                          );
                        })}
                      </div>
                    )}
                </div>
              )}
              {data.corporate_pincode && (
                <div
                  className={
                    errors.corporate_pincode
                      ? "api-text err-text "
                      : "api-text "
                  }
                >
                  {cityFromPin3.cityp}
                </div>
              )}

              {!data.corporate_pincode && errors.corporate_pincode && (
                <div className={"api-text err-text "}>Invalid Pincode</div>
              )}

              {/* -----------------------------  Are you registered under MSME Field  --------------------------------- */}
              <div className="partner-res-question">
                <span>Are you registered under MSME</span>
              </div>
              <div className="partner-options-confirm">
                <label>
                  <input
                    type="radio"
                    name="msmeRadio"
                    onClick={() => setMsme(1)}
                    value="option1"
                    checked={RadioMsme === 1}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    onClick={() => setMsme(2)}
                    name="msmeRadio"
                    value="option2"
                    checked={RadioMsme === 2}
                  />
                  No{" "}
                </label>
              </div>

             {/* --------------------Account Holder input Field----------------------------- */}
             <div className="partner-res-question">
                <span>Bank Details</span>
              </div>
              <div className={'input-tag remove-bottom' + (errors.account_holder_name ? " error-border" : data.account_holder_name ? " add-border" : "")}>
                <img className="tag-icon" src={bank} />
                <input disabled={resp} className="input-tag-box " minLength={3} type="text" placeholder={"Beneficiary Name "}
                  {...register('account_holder_name', { required: true, minLength: 3 })} autoComplete="off" onKeyUp={() => handleAlphaOnly(data.account_holder_name, "account_holder_name")} onBlur={() => Blured("account_holder_name", data.account_holder_name)} />
              </div>
              {errors.account_holder_name && <div className='err-text'> Enter At least 3 Characters </div>}

              {/* --------------------Account Number input Field----------------------------- */}
              <div className={'input-tag remove-bottom' + (errors.account_number ? " error-border" : data.account_number ? " add-border" : "")}>
                <img className="tag-icon" src={bank} />
                <input disabled={resp} className={"input-tag-box" + (data.account_number ? " capLetter" : "")} type="tel" placeholder={"Enter Account Number"} minLength={6} maxLength={20}
                  {...register('account_number', { required: true, minLength: 6, maxLength: 20 })} autoComplete="off"
                  onKeyUp={() => handleUpKeyNumber(data.account_number, "account_number")} />
              </div>
              {errors.account_number && <div className='err-text'> Invalid Account Number </div>}


              {/* --------------------IFSC Code input Field----------------------------- */}
              <div className={'input-tag remove-bottom' + (errors.ifsc_code ? " error-border" : data.ifsc_code ? " add-border" : "")}>
                <img className="tag-icon" src={bank} />
                <input disabled={resp} className={"input-tag-box" + (data.ifsc_code && " capLetter")} minLength={11} maxLength={11} type="text" placeholder={"IFSC Code"}
                  {...register('ifsc_code', {
                    required: true, minLength: 11, maxLength: 11, pattern: {
                      value: /([a-zA-Z]){4}([0-9]){1}([0-9a-zA-Z]){6}$/
                    }
                  })} autoComplete="off" onKeyUp={() => AlphaNum(data.ifsc_code, "ifsc_code")} />
              </div>
              {errors.ifsc_code && <div className='err-text'> Invalid IFSC Code </div>}

              {showupload == 1 ? <PartnerUploadDoc value={data.type_of_referrer} setDocuments={setDocuments} /> : ""}
              {
                showupload == 1 && partnerdocupdate.data && partnerdocupdate.data.map((item, idx) => {
                  return (
                    item.checkbox && handleDocInputs(item)
                  )
                })
              }
              {showupload == 0 && <div className="partner-form-document">
<p>
Please share the below mentioned documents on <a href="mailto:Partner.care@moneywide.com"
style={{ textDecoration: "none", color: "#015ef7" }} >Partner.care@moneywide.com</a>
</p>
<table border="1" style={{ borderCollapse: "collapse" }} className="partner-form-table">
<thead>
<tr>
<th>Individual (Sole Proprietorship)</th>
<th>Othe than Individual (Company/ LLP/ Partnership Firm/ Others)</th>
</tr>
</thead>
<tbody>
<tr>
<td>PAN</td>
<td>PAN</td>
</tr>
<tr>
<td>GST Certificate</td>
<td>GST Certificate</td>
</tr>
<tr>
<td>MSME</td>
<td>MSME Certificate (If Applicable)</td>
</tr>
<tr>
<td>Cancelled Chque/ Bank Statement</td>
<td>Cancelled Chque/ Bank Statement</td>
</tr>
<tr>
<td>Aadhaar</td>
<td>Certificate of Incorporation(Company/LLP)</td>
</tr>
<tr>
<td></td>
<td>MOA/AOA(Company) or LLP Agreement (LLP)</td>
</tr>
<tr>
<td></td>
<td>Partnership Deed(Partnership Firm)</td>
</tr>
<tr>
<td></td>
<td>Board Resolution/ Authority Letter for signing Authority</td>
</tr>
<tr>
<td></td>
<td>Director/Partner KYC- PAN & Aadhaar</td>
</tr>
</tbody>
</table>
</div>}
              <button
                type="submit"
                disabled={resp || !submitButtonNew || gstinError}
                className={
                  submitButtonNew &&
                    Object.keys(errors).length === 0 &&
                    !gstinError
                    ? "footer-btn Tmargin footer-btn-activate Bmargin"
                    : "footer-btn Tmargin Bmargin"
                }
                onClick={() => setFirstClick(true)}
              >
                {!resp ? (
                  "Continue"
                ) : (
                  <div className="continue-lottie">
                    <Lottie
                      loop
                      animationData={ctaAnimation}
                      play
                      className="ctn-loader"
                    />
                  </div>
                )}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerForm;
