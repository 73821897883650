import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './MyAccount.css';
import { useSelector } from 'react-redux';

const MyAccountMenuBox = ({ setParentId }) => {
    const { myaccheaderdata } = useSelector((state) => state);
    const currentPageURL = window.location.pathname;

    const existed = sessionStorage.getItem('existed')

    const Checkurl = (data) => {
        if (data.link == currentPageURL) {        
            setTimeout(() => {
            setParentId(data.header_id)             
            }, 1);
            return true
        }
        return false

    }
    return (
        <>
            <div className="MyAccountLeftSide">{myaccheaderdata.data.map((el, index) => (
                <a key={index} target={el.target === 1 ? "_blank" : ""} className={"myAccountSectionName" + (Checkurl(el) ? " myAccountActivateSection" : "")} href={el.link} >{el.header_name}</a>
            ))}</div>
        </>
    )
}

export default MyAccountMenuBox
