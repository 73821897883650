import React from 'react'
import './PurposeLoanPopUp.css'
import '../../Loader/Loader.css'
import { useDispatch, useSelector } from "react-redux";
import { updatePopUp } from '../../../Redux/action';

function PurposeLoanPopUp() {
    const { PopUpState } = useSelector(state=>state);
    const dispatch = useDispatch();
    const returnThisValue =(value)=>{
        const updatedLoanPurpose = {...PopUpState.LoanPurposePopUp,show:false,value:value}
        dispatch(updatePopUp({...PopUpState,LoanPurposePopUp:updatedLoanPurpose}))
    }
    return (
        <div className='loaderbackground'>
            <div className='loadertransparent'>
                <div className='purposeloanpopup'>
                    <h2>Purpose of Loan</h2>
                    <div className='purposeloanpopup-content'>
                        <ul>
                            <li onClick={()=>returnThisValue("Home Renovation")}>Home Renovation</li>
                            <li onClick={()=>returnThisValue("Deposit for Rental")}>Deposit for Rental</li>
                            <li onClick={()=>returnThisValue("Travel/Wedding")}>Travel/Wedding</li>
                            <li onClick={()=>returnThisValue("Medical Treatment")}>Medical Treatment</li>
                            <li onClick={()=>returnThisValue("Purchase of Durable,Vehicle")}>Purchase of Durable,Vehicle</li>
                            <li onClick={()=>returnThisValue("Education")}>Education</li>
                            <li onClick={()=>returnThisValue("Others")}>Others</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    
    )
}

export default PurposeLoanPopUp;