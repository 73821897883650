import axios from "axios"
import { client_id, client_key, source, prodbase_url, verification_source, verified_by, verifier_name,doc_upload_url } from "../Secret_Credentials/Secret_Credential";
import { apiInstance } from "../Config/APIConfig";

const getOS = () => {

    let os = "Unknown OS";

    if (navigator.userAgent.indexOf("Win") != -1)
        os = "Windows";
    else if (navigator.userAgent.indexOf("Mac") != -1)
        os = "Macintosh";
    else if (navigator.userAgent.indexOf("Linux") != -1)
        os = "Linux";
    else if (navigator.userAgent.indexOf("Android") != -1)
        os = "Android";
    else if (navigator.userAgent.indexOf("like Mac") != -1)
        os = "iOS";
    else os = "No Os Detected";

    return os;

}

const getBrowser = () => {

    let userAgent = navigator.userAgent;
    let browser;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browser = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browser = "Firefox";
    } else if (userAgent.match(/safari/i)) {
        browser = "Safari";
    } else if (userAgent.match(/opr\//i)) {
        browser = "Opera";
    } else if (userAgent.match(/edg/i)) {
        browser = "Edge";
    } else {
        browser = "No browser detection";
    }

    return browser

}

export const getToken = () => {
    const payLoad = {
        "source": source,
        "client_id": client_id,
        "client_key": client_key
    }
    // return axios.post("https://api.moneywide.com/token/generate", payLoad)
    return axios.post(`${prodbase_url}token`, payLoad)
        .then((res) => {
            return res.data.token;
        })
}


export const VerifyOTP = async (result, token) => {
    
    return await apiInstance.post(`verify-otp`, {
        "otpId": result.otpId,
        "otp": result.otp,
        "mobile": result.mobile,
        "whatsapp_consent": result.whatsapp_consent,
        "login_from": result.login_from,
        "deviceId": "",
        "device_type": result.device_type,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "source": source,
        "lead_id":result.lead_id ? result.lead_id  : "",
        "customer_id":result.customer_id ? result.customer_id :""
    }, {
        "token": token
    })
    // .then((res) => {
    //     return res.data
    // })
}

export const SendOTP = async (result, token) => {
    
    return await apiInstance.post(`generate-otp`, {
        "mobile": result.mobile,
        "api_mode": result.api_mode,
        "login_from": result.login_from,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "source": source
    }, {
        "token": token,
        "log": 1
    })
    // .then((res) => {
    //     return res.data
    // })
}

export const WhatsAppSubscription = async (mobileNumber, token) => {
    
    return await apiInstance.post(`check-whatsapp-subscription`, {
        "phone_no": mobileNumber,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "source": source
    }, {
        "token": token
    })
    // .then((res) => {
    //     return res.data;
    // })
}

export const ResumeJourneyApi = async (cust_id, token, mobile) => {
    
    return await apiInstance.post(`get-dashboard-data`, {
        "customer_id": cust_id,
        "mobile": mobile,
        "lead_id": 0,
        "source": source,
        "level_id": 1,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "verification_source": verification_source,
        "page_url": window.location.href
    }, {
        "token": token,
        "log": 1
    })
    // .then((res) => {
    //     return res.data;
    // }).catch((err) => {
    //     const handleError = (e) => {
    //         window.location.reload();
    //     }
    //     return(<div>
    //         <NewErrorCase handleClick={(e) => handleError(e)}/>
    //     </div>)
    // })

}




export const getCustId = async (mw_app_id, token, step) => {
    
    let dec = await apiInstance.post(`fetch-customer-details`, {
        "mw_application_id": mw_app_id,
        "verifier_name": verifier_name,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "source": source,
        "step": step,
        "page_url": window.location.href
    }, {
        "token": token
    })
        // .then((res) => {
            if (dec.data.customer_id) {
                return dec.data.customer_id;
            }else{
                return 0;
            }
        // })
}

export const getCityName = async (pin, token) => {
    
    let dec = await apiInstance.post(`get-pincode-city`, {
        "source": source,
        "pincode": pin,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }, {
        "token": token
    })
    // .then((res) => {
        return dec.data;
    // })
}

export const getPinOption = async (pin, token) => {
    
    let dec = await apiInstance.post(`get-pincode`, {
        "source": source,
        "pincode_text": pin,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }, {
        "token": token
    })
    // .then((res) => {
        return dec.data;
    // })
}


export const getCompanyName = async (name, token) => {
    
    return await apiInstance.post(`get-company`, {
        "source": source,
        "company_text": name,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }, {
        "token": token
    })
    // .then((res) => {
        // return dec;
    // })
}

export const getMasterData = async (type, token) => {
    
    let dec = await apiInstance.post(`master`, {
        "source": source,
        "api_type": type,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }, {
        "token": token
    })
    // .then((res) => {
        return dec.data;
    // })
}

export const SubmitEmploymentDetail = async (result, token) => {
    
    return await apiInstance.post(`web/employment-details`, {
        "customer_id": result.customer_id,
        "employer_type": result.employer_type,
        "employer_name": result.employer_name,
        "current_work_experience": result.current_work_experience,
        "total_work_experience": result.total_work_experience,
        "mw_application_id": result.mw_application_id,
        "do_you_have_office_email": result.do_you_have_office_email,
        "office_email_id": result.office_email_id,
        "mode_of_salary": result.mode_of_salary,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "verification_source": verification_source,
        "source": source
    }, {
        "token": token,
        "log": 1
      })
    // .then((res) => {
        // return res.data;
    // })
}

export const SubmitLoanDetail = async (result, token) => {
    
    return await apiInstance.post(`web/loan-details-submit`, {
        "customer_id": result.customer_id,
        "mw_application_id": result.mw_application_id,
        "loan_amount": result.loan_amount,
        "purpose_of_loan": result.purpose_of_loan,
        "purpose_name_other": result.purpose_name_other,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "verification_source": verification_source,
        "source": source
    }, {
        "token": token,
        "log": 1
      })
    // .then((res) => {
    //     return res.data;
    // })
}

export const WebOffer = async (result, token) => {
    
    result = { ...result, "verification_source": verification_source, "verified_by": verified_by, "verifier_name": verifier_name, "source": source };
    
    return await apiInstance.post(`offer-eligibility`, result, {
        "token": token,
        "log": 1
      })
    // .then((res) => {
    //     return res.data;
    // })
}

//// shailza //////
export const getMetatags = async ( token,url) => {
    let payLoad = {
        "verifier_name": verifier_name,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "source": source,
        "url":url
    }
    // let payload = await encryptWithHybrid(JSON.stringify(payLoad));
    return await apiInstance.post(`web/meta-tags`, 
    payLoad, {
        "token": token
    })
}

export const FinalOfferAPI = async (cust_id, mw_app_id, id, token) => {
    
    return await apiInstance.post(`web/final-offer`, {
        "customer_id": cust_id,
        "lead_id": mw_app_id,
        "offer_type": 1,
        "product_id": 56,
        "stage_name": "Final Offer",
        "program_id": id,
        "level_id": 1,
        "is_eligibility_check": 1,
        "verifier_name": verifier_name,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "source": source
    }, {
        "token": token
    })
    // .then((res) => {
    //     return res.data;
    // })
}
export const LoanAgreementFetch = async (result, token) => {
    
    return await apiInstance.post(`web/loan-agreement-t-c`, {
        "lead_id": result.mw_application_id,
        "customer_id": result.customer_id,
        "ip_address": result.ip,
        "partner_id": 1,
        "level_id": 1,
        "stampflag": result.stampflag,
        "verifier_name": verifier_name,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "source": source,
        "device_type": result.device_type,
        "browser": getBrowser(),
        "OS": getOS(),
        "financial_protection":result.financial_protection
    }, {
        "token": token,
        "log": 1
      })
    // .then((res) => {
    //     return res.data;
    // })
}

export const offerSubmissionApi = async (result, token, url) => {
    
    return await apiInstance.post(url, {
        "lead_id": result.lead_id,
        "customer_id": result.customer_id,
        "source": "MWide",
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "form_type": 1,
        "selected_program": result.selected_program,
        "selected_loan_amount": result.selected_loan_amount,
        "selected_tenure": result.selected_tenure,
        "emi_calculated_applicable_roi": result.emi_calculated_applicable_roi,
        "emi_calculated_max_rate": "",
        "emi_calculated_min_rate": "",
        "applicable_roi": result.applicable_roi

    }, {
        "token": token,
        "log": 1
      })
        // .then((res) => {
        //     return res;
            

        // })
}

export const offerSubmissionApi2 = async (result, token) => {
    
    return await apiInstance.post(`offer-submission`, {
        "lead_id": result.lead_id,
        "customer_id": result.customer_id,
        "final_offer_generate_type": result.final_offer_generate_type,
        "source": "MWide",
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "form_type": 1,
        "selected_program": result.selected_program,
        "selected_loan_amount": result.selected_loan_amount,
        "selected_tenure": result.selected_tenure,
        "emi_calculated_applicable_roi": result.emi_calculated_applicable_roi,
        "emi_calculated_max_rate": "",
        "emi_calculated_min_rate": "",
        "applicable_roi": result.applicable_roi

    }, {
        "token": token,
        "log": 1
      })
        // .then((res) => {
        //     return res;

        // })
}

export const FinalOfferSubmissionApi = async (result, token) => {
    
    return await apiInstance.post(`final-offer-submit`, {
        "lead_id": result.lead_id,
        "customer_id": result.customer_id,
        "source": "MWide",
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "final_selected_program_id": result.final_id,
        "sanction_loan_amount": result.selected_loan_amount,
        "sanction_tenure": result.selected_tenure,
        "sanction_emi": result.emi_calculated_applicable_roi,
        "sanction_roi": result.applicable_roi.toString(),
        "is_eligibility_check": 1,
        "is_ntc_check": 1

    }, {
        "token": token
    })
    // .then((res) => {
    //     return res.data;
    // })
}

export const GetPersonalDetails = async (customer_id, token) => {
    
    return await apiInstance.post(`get-profile-personal-details`, {
        "customer_id": customer_id,
        "source": source,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }, {
        "token": token
    })
    // .then((res) => {
    //     if (res.data) {
    //         return res.data;
    //     }
    // })
}

export const PushLosApi = async (result, token) => {
    
    return await apiInstance.post(`web/push-los`, result, {
        "token": token
    })
    // .then((res) => {
    //     if (res.data) {
    //         return res.data;
    //     }
    // })
}

export const getAddressData = async (result, token) => {
    
    return await apiInstance.post(`kyc/get-address-data`, {
        "mw_application_id": result.mw_application_id,
        "customer_id": result.customer_id,
        "level_id": result.level_id,
        "product_id": 56,
        "source": source,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }, {
        "token": token
    })
    // .then((res) => {
    //     return res.data;
    // })
}

export const DecisionPanApi = async (result, token) => {
    
    return await apiInstance.post(`decision-pan-api`, {
        "mw_application_id": result.application_id,
        "customer_id": result.cust_id,
        "level_id": 1,
        "mobile_no": result.mobile_no,
        "pincode": result.pincode,
        "city_id": result.city_id,
        "pancard": result.pancard,
        "email": result.email,
        "product_id": 56,
        "source": source,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }, {
        "token": token,
        "log": 1
    })
    // .then((res) => {
    //     return res.data;
    // })
}

export const WebAddressSubmit = async (result, token) => {
    
    return await apiInstance.post(`web/web-address-details`, {
        "curr_address_house_no": result.curr_address_house_no,
        "curr_address_area_sector": result.curr_address_area_sector,
        "curr_address_landmark": result.curr_address_landmark,
        "curr_address_ovd_proof": result.curr_address_ovd_proof,
        "mw_application_id": result.mw_application_id,
        "current_pincode": result.current_pincode,
        "current_address_same_as": result.current_address_same_as,
        "source": source,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }, {
        "token": token,
        "log": 1
      })
    // .then((res) => {
    //     return res.data;
    // })
}

export const Employer = async (result, token) => {
    
    return await apiInstance.post(`web/employer`, {
        "mw_application_id": result.mw_application_id,
        "customer_id": result.customer_id,
        "office_address1": result.office_address1,
        "office_address2": result.office_address2,
        "office_address3": result.office_address3,
        "office_pincode": result.office_pincode,
        "source": source,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }, {
        "token": token,
        "log": 1
      })
    // .then((res) => {
    //     return res.data;
    // })
}

export const AdditionalDetailsAPI = async (result, token) => {
    
    return await apiInstance.post(`web/additional-details`, {
        "mw_application_id": result.mw_application_id,
        "customer_id": result.customer_id,
        "marital_status": result.marital_status,
        "highest_qualification": result.highest_qualification,
        "mother_name": result.mother_name,
        "father_name": result.father_name,
        "source": source,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }, {
        "token": token,
        "log": 1
      })
    // .then((res) => {
    //     return res.data;
    // })
}

export const ReferenceDetailsAPI = async (result, token) => {
    
    return await apiInstance.post(`web/reference-details`, {
        "mw_application_id": result.mw_application_id,
        "customer_id": result.customer_id,
        "reference_name1": result.reference_name1,
        "reference_name2": result.reference_name2,
        "reference_mobile1": result.reference_mobile1,
        "reference_mobile2": result.reference_mobile2,
        "source": source,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }, {
        "token": token,
        "log": 1
      })
    // .then((res) => {
    //     return res.data;
    // })
}

export const GetFinbitToken = async (result, token) => {
    
    return await apiInstance.post(`finbit-token`, {
        "customer_id": result.customer_id,
        "partner_id": result.partner_id,
        "level_id": result.level_id,
        "lead_id": result.lead_id,
        "source": source,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "verification_source": verification_source
    }, {
        "token": token,
        "log": 1
      })
    // .then((res) => {
    //     return res.data;
    // })
}

export const OVDOCRAPI = async (result, token) => {
    
    return await axios.post(`${doc_upload_url}ovd-ocr-master`, result, {
        headers: {
            "token": token,
            "Content-Type": "multipart/form-data"
        }
    }).then((res) => {
        return res.data;
    })
}

export const EmailOtpSend = async (result, token) => {
    
    return await apiInstance.post(`email/otp-send`, {
        "customer_id": result.customer_id,
        "lead_id": result.lead_id,
        "level_id": result.level_id,
        "email_type": result.email_type,
        "source": source,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "verification_source": verification_source
    }, {
        "token": token
    })
    // .then((res) => {
    //     return res.data;
    // })
}

export const EmailOtpVerify = async (result, token) => {
    
    return await apiInstance.post(`email/otp-verify`, {
        "customer_id": result.customer_id,
        "level_id": result.level_id,
        "lead_id": result.lead_id,
        "email_type": result.email_type,
        "otp": result.otp,
        "source": source,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "verification_source": verification_source
    }, {
        "token": token
    })
    // .then((res) => {
    //     return res.data;
    // })
}

export const EligibilityUpdate = async (result, token) => {
    
    return await apiInstance.post(`get-customer-eligible`, {
        "mw_application_id": result.mw_app_id,
        "customer_id": result.custId,
        "verified_by": verified_by,
        "verification_source": verification_source,
        "verifier_name": verifier_name,
        "source": source,
    },
    {
        "token": token
    })
    // .then((res) => {
    //         return res.data;
    //     })
}

export const VkycKrown = async (mw_application_id, token) => {
    
    return await axios.post(`${prodbase_url}vkyc/get-transactionid-v2`, {
        "source": source,
        "lead_id": mw_application_id,
        "verification_source": verification_source,
        "verifier_name": verifier_name,
        "verified_by": verified_by,
    }, {
        headers: {
            "token": token
        }
    })
        .then((res) => {
            return res;
        })
}

export const FetchDetails = async (mw_app_id, custId, token) => {

    return await apiInstance.post(`web/fetch-bank-details`, {
        "mw_application_id": mw_app_id,
        "customer_id": custId,
        "verified_by": verified_by,
        "verification_source": verification_source,
        "verifier_name": verifier_name,
        "source": source
    }, {
        "token": token
    })
    // .then((res) => {
    //     return res.data;
    // })
}


export const getCareerFunctions = async (token) => {
    let payload = {
        "verified_by": verified_by,
        "verification_source": verification_source,
        "verifier_name": verifier_name,
        "source": source
    }
    // payload = await encryptWithHybrid(JSON.stringify(payload));
    return await apiInstance.post(`fetch-function`,
    payload, {
        "token": token
    })
}

export const getCareerSliderData = async (functionId, token) => {
    let payLoad = {
        "function_id": functionId,
        "verified_by": verified_by,
        "verification_source": verification_source,
        "verifier_name": verifier_name,
        "source": source
    }
    // let payload = await encryptWithHybrid(JSON.stringify(payLoad));
    return await apiInstance.post(`fetch-function-details`,
    payLoad, {
        "token": token
    })
}

export const getCareerIdData = async (id, token) => {
    let payLoad = {
        "position_id": id,
        "verified_by": verified_by,
        "verification_source": verification_source,
        "verifier_name": verifier_name,
        "source": source
    }
    // let payload = await encryptWithHybrid(JSON.stringify(payLoad)); 
    return await apiInstance.post(`fetch-position-data`,
    payLoad, {
        "token": token
    })
}

export const getAprData = async (token) => {
    let payLoad = {
        "verified_by": verified_by,
        "verification_source": verification_source,
        "verifier_name": verifier_name,
        "source": source
    }
    // let payload = await encryptWithHybrid(JSON.stringify(payLoad));
    return await apiInstance.post(`apr-details`,
    payLoad, {
        "token": token
    })
}

const getData = async () => {
    const res = await axios.get('https://api.ipify.org?format=json')
    return res.data.ip;
  }

  const fetchCurrentUrl = async () => {
    try {
      const response = await fetch(window.location.href);
      const status = response.status;
      return status;
    } catch (error) {
      console.error(error);
    }
  };

export const LogApi = async (payLoad, token) => {
    let web_status = await fetchCurrentUrl();
    let ip = await getData();
    let currentDate = new Date();
    currentDate = currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate() + " " + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()
    let payload = {
        "api_details": payLoad.api_details,
        "prev_url": document.referrer,
        "cur_url": window.location.href,
        "os": getOS(),
        "browser": getBrowser(),
        "web_status": web_status.toString(),
        "date_time": currentDate,
        "customer_ip": ip,
        "method": "POST",
        "source": source,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }
    return await apiInstance.post('web/access-log-submit', payload, { "token": token })
}
export const NoticeAPi = async (data, token) => {
    return await apiInstance.post('web/collection-legal-notice', {
        "collection_id": +(data.id),
        "document_id": +(data.doc_id),
        "source": source,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
    }, {token})
}

export const SubmitUrlApi = async (token) => {
    const ip = await getData();
    const payLoad = {
        "browser": getBrowser(),
        "ip": ip,
        "url": window.location.href,
        "source": source,
        "verification_source": verification_source,
        "verified_by": verified_by,
        "verifier_name": verifier_name
        }
        return await apiInstance.post("web/submit-url", payLoad, {token})
}