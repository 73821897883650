import React from 'react'
import './HomePage.css';
import paisabazaar from '../../Icons/paisabazaar.svg'
import pbfintech from '../../Icons/pb-fintech.webp'
import pbPartner from '../../Icons/pbPartner.svg'
import PolicyBazaar from '../../Icons/PolicyBazaar.svg'
import PolicyBazarAE from '../../Icons/PolicyBazarAE.svg'

const GroupBrands = () => {
  return (
    <>
    <div className="groupbrands-main">
        <div className="groupbrand-heading">
            <div>Group Brands</div>
        </div>
        <div className="groupbrand-logos">
        <a target="_blank" href="https://www.policybazaar.com/" rel="nofollow"><img src={PolicyBazaar} alt="policybazar" /></a>
        <a target="_blank" href="https://www.paisabazaar.com/" rel="nofollow"><img src={paisabazaar} alt="paisabazar" /></a>
        <a target="_blank" href="https://www.policybazaar.ae/" rel="nofollow"><img src={PolicyBazarAE} alt="policybazarae" /></a>
        <a target="_blank" href="https://www.pbpartners.com/" rel="nofollow"><img src={pbPartner} alt="pbpartner" /></a>
        <a target="_blank" href="https://www.pbfintech.in/" rel="nofollow" ><img style={{width: 70, height: 70 }} src={pbfintech} alt="pbfintech" /></a>   
        </div>
    </div>

    </>
  )
}

export default GroupBrands