import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import './StepProgressBar.css';
import tick from '../../Icons/tick.svg'

function StepProgressBar() {
    const step_bar = useSelector(state => state.step_bar);
    const dispatch = useDispatch();

    return(
        <div className="step-main">
            <div className="step-bar-main">
                <div className={(step_bar.profile && step_bar.offer) ? "steps-circle-green" : (step_bar.profile ? "steps-circle-orange" : "steps-circle-gray")} id="steps-circle1">
                    <span className="steps-circle-span" id="steps-circle-span-profile">{step_bar.profile ? <img src={tick} alt="" /> : ""}</span>
                </div>
                <div className={(step_bar.profile && step_bar.offer && step_bar.verification) ? "steps-line-green" : ((step_bar.profile && step_bar.offer) ? "steps-line-orange" : "steps-line-gray") }>
                    <span className="steps-line-span" id="steps-line-span-profile"></span>
                </div>
                <div className={(step_bar.profile && step_bar.offer && step_bar.verification) ? "steps-circle-green" : ((step_bar.profile && step_bar.offer) ? "steps-circle-orange" : "steps-circle-gray")}  id="steps-circle2">
                    <span className="steps-circle-span" id="steps-circle-span-offer">{step_bar.offer ? <img src={tick} alt="" /> : ""}</span>
                </div>
                <div className={(step_bar.profile && step_bar.offer && step_bar.verification && step_bar.disbursal) ? "steps-line-green" : ((step_bar.profile && step_bar.offer && step_bar.verification) ? "steps-line-orange" : "steps-line-gray") }>
                    <span className="steps-line-span" id="steps-line-span-profile"></span>
                </div>
                <div className={(step_bar.profile && step_bar.offer && step_bar.verification && step_bar.disbursal) ? "steps-circle-green" : ((step_bar.profile && step_bar.offer && step_bar.verification) ? "steps-circle-orange" : "steps-circle-gray")}  id="steps-circle3">
                    <span className="steps-circle-span" id="steps-circle-span-verification">{step_bar.verification ? <img src={tick} alt="" /> : ""}</span>
                </div>
                <div className={(step_bar.profile && step_bar.offer && step_bar.verification && step_bar.disbursal && step_bar.final) ? "steps-line-green" : ((step_bar.profile && step_bar.offer && step_bar.verification && step_bar.disbursal) ? "steps-line-orange" : "steps-line-gray") }>
                    <span className="steps-line-span" id="steps-line-span-profile"></span>
                </div>
                <div className={(step_bar.profile && step_bar.offer && step_bar.verification && step_bar.disbursal && step_bar.final) ? "steps-circle-green" : ((step_bar.profile && step_bar.offer && step_bar.verification && step_bar.disbursal) ? "steps-circle-orange" : "steps-circle-gray")}  id="steps-circle4">
                    <span className="steps-circle-span" id="steps-circle-span-disbursal">{step_bar.disbursal ? <img src={tick} alt="" /> : ""}</span>
                </div>
            </div>
        </div>
    )
}

export default StepProgressBar;