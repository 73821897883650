import React from 'react';
import { Link } from 'react-router-dom'
import './PLWithoutDocuments.css';
import '../PersonalLoan/PersonalLoan.css';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import EligibilityCriteria from '../PersonalLoan/images/eligibility-criteria.webp';
import FAQSection from '../FAQSection/FAQSection'
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import greenticknew from '../../Icons/greenticknew.svg';
import fandb from '../../Icons/fandb.svg'
import MobileFooter from '../Footer/MobileFooter';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';
import EligibilityCriteriaSection from '../PersonalLoan/EligibilityCriteria';

const PLWithoutDocuments = () => {
    var loan_type_id_page = 56;
    const [data, setData] = React.useState({ sub_bread: "Personal Loan Without Documents" })
    const dispatch = useDispatch()
    const { PLLoader, formStates, mwpages } = useSelector(state => state)
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY <= 200 || window.scrollY >= 2400) {
            sety(false);
        }
        else {
            sety(true);
        }
    });

    React.useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])
    const dataa = {
        heading: [
            {
                left: "Tips to Get",
                right: "Personal Loan Without Documents"
            }
        ],
        subhead: [
            {
                head: "Healthy credit score",
                content: "Ensuring timely repayment without defaulting on your loan EMIs helps in building a solid credit score."
            },
            {
                head: "Debt to income ratio",
                content: "An important indicator of a customer’s financial credibility. Ideally, a person should have a debt to income ratio of below 50%."
            },
            {
                head: "Relationship with the lender",
                content: "Acquiring the trust of your lender will help you in easily availing of financial services at any time."
            }
        ]
    }


    return (
        <>
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi />
            <div className='journey-stop'>
                {y && <RightSidePopUp />}
                <NewLoginForm pageType={loan_type_id_page} mapData={data} />
                <section className="newpl-main manage-top-margin-main mob-margin-top web-margin-top what-is-extra">
                    <div className="ploan-container ">
                        <div className="re-sh-box ploan-content1 ">
                            <h4 className="heading-h2" style={{ marginBottom: "16px" }}>What is Instant <b> Personal Loan Without Documents?</b></h4>
                            <p className="heading-p"><Link className="send-lnk b-it" style={{ fontWeight: "600" }} to='/instant-personal-loan'>Instant personal loan</Link> is an unsecured credit provided based on multiple factors like employment history, income, credit score etc of the customers. Such loans come with no end-usage restrictions, and you can thus utilize them to fulfill various personal or business needs. In addition, through advanced tech-based tools, MoneyWide tends to provide better services to their customers by offering an <b>Instant personal loan without documents</b> via KYC verification without relying on traditional methods of heavy documentation.</p>
                        </div>
                    </div>
                </section>
                <section className="salary-slip-KYC-documents">
                    <div className="">
                        <div className="re-sh-box eligibility-criteria-box">
                            <h4 className="heading-h2 add-btom-line bot-6" style={{ paddingBottom: "18px" }}> How to Get Instant Personal Loan <b>Without Salary Slip and KYC Documents?</b></h4>
                            <div className="heading-p ">MoneyWide digital lending platform is designed to reduce friction in the process of taking a personal loan. From approval to disbursement everything is done digitally which facilitates more convenience and safety of customers’ personal data.
                                <br /><br />
                                MoneyWide digital process of availing of an instant loan without pay slip and KYC documents is as follows:
                                <ol className="ol-list">
                                    <li><b>Identity proof:</b> Virtual verification of PAN and Aadhar card details.</li><br />
                                    <li><b>Income proof:</b> Online verification of a customer’s financial activities using bank statements or net banking credentials.</li><br />
                                    <li><b>Address proof:</b> OVD documents like Aadhar card, voter ID, passport, or driving incense are required for current address proof.</li><br />
                                </ol>
                                After the details are successfully verified, a customer is presented with a loan agreement that requires the electronic signature of a customer for the successful disbursal of the loan amount.</div>
                        </div>
                    </div>
                </section>
                <EligibilityCriteriaSection elgdata={dataa} />
                <section className="benefits-without-doc">
                    <div className="">
                        <div className="re-sh-box eligibility-criteria-box">
                            <h4 className="heading-h2 underline-heading"><b>Benefits </b>of Taking Personal Loan <b>Without Documents</b></h4>
                            <div className="heading-p top-five" >MoneyWide allows you to avail <Link className='send-lnk b-it' to="/">Instant Personal Loan without salary slip</Link> and other key KYC documents. Here are some key benefits of taking the loan.
                                <div style={{ gap: '24px' }} className="mlc-pl-cost-main">
                                    <div className="mlc-pl-left">
                                        <div className="mlc-pl-list mlc-pl-mrgin-top">
                                            <div className="mlc-pl-list-cont wd-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span><b>Easy verification process:</b> Since every formality pertaining to personal loan verification can be done online, customers tend to get hassle-free loans without a bank visit.</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span><b>No collateral:</b> Personal loans do not require customers to pledge any collateral against the loan amount. Such loans are provided based on the overall credit profile of the customers.</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span><b>No end-usage restriction:</b> Funds raised through personal loans can be used for multiple purposes like education, travel, <Link className="send-lnk b-it" to="/">medical emergencies</Link>, <Link className="send-lnk b-it" to="">weddings</Link>, festivals, etc.</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span><b>Minimum physical interaction:</b> Loan formalities are now done online. An eligible customer can get an instant personal loan from anywhere within a few clicks.</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span><b>More efficiency:</b> Features like instant approval, flexibility to manage funds online, etc have made availing of financial services extremely convenient for customers. </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mlc-pl-right">
                                        <img style={{ width: '400px', height: '400px' }} src={fandb} alt="illust-banner" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="FAQs">
                    <FAQSection />
                </section> */}
                {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                    <FAQSectionChk data={mwpages.faqdata} />
                </section> : ""}
            </div>
            <div className='journey-start' style={{ display: "none" }}>
                {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                {(!formStates.IsIframe) && <MobileFooter />}
            </div>
        </>
    )
}

export default PLWithoutDocuments;