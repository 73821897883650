import axios from "axios";
import { client_id, client_key, source, prodbase_url, verification_source, verified_by, verifier_name, react_base_url, doc_upload_url } from "../Secret_Credentials/Secret_Credential";
import { apiInstance } from "../Config/APIConfig";
import { decryptWithHybrid, encryptWithHybrid } from "../../Keys";

export const commonPayload = {
    verified_by,
    verifier_name,
    verification_source,
    source
}

export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const ProfileAPI = async (num, token) => {
    return await apiInstance.post(`myaccount/profile-details`, {
        "mobile": num,
        "level_id": 1,
        ...commonPayload
    }, {
        token
    })
}

export const MYLoanAPI = async (num, token) => {
    return await apiInstance.post(`myaccount/fetch-loan-details`, {
        "mobile": num,
        "mw_application_id": 0,
        "level_id": 1,
        "product_id": 0,
        ...commonPayload
    }, {
        token
    })
}

export const GetRepaymentDetailsAPI = async (mw, token) => {
    return await apiInstance.post(`myaccount/fetch-repayment-details`, {
        "mw_application_id": mw,
        "level_id": 1,
        "product_id": 0,
        ...commonPayload
    }, {
        token
    })
}

export const TrackAPPAPI = async (num, token) => {
    return await apiInstance.post(`myaccount/get-track-application`, {
        "mobile": num,
        "level_id": 1,
        ...commonPayload
    }, {
        token
    })
}

export const GetStatementAPI = async (result, token) => {
    return await apiInstance.post(`myaccount/fetch-account-statement`, result, {
        token
    })
}

export const getForeclosureMasterData = (reason, token) => {
    return axios.post(`${prodbase_url}master`, {
        "api_type": reason,
        ...commonPayload
    }, {
        headers: {
            token
        }
    })
        .then((response) => {
            return response.data
        })
}

export const fetchForeclosureLoanAPI = async (result, token) => {
    return await apiInstance.post(`myaccount/fetch-foreclosure-loan`, {
        "mw_application_id": result.mw_application_id,
        "selected_reason_id": result.selected_reason_id,
        "foreclosure_date": result.foreclosure_date,
        "level_id": 1,
        "product_id": 0,
        ...commonPayload
    }, {
        token
    })

}

export const getForeclosureLink = async (mw, amount, token) => {
    return await apiInstance.post(`myaccount/fetch-foreclosure-link`, {
        "mw_application_id": mw,
        "transaction_amount": amount,
        "level_id": 1,
        "product_id": 0,
        ...commonPayload
    }, {
        token
    })
}

export const getOverDueData = async (mw, token) => {
    return await apiInstance.post(`myaccount/get-missed-emi`, {
        "mw_application_id": mw,
        "level_id": 1,
        ...commonPayload
    }, {
        token
    })
}

export const getOverdueLink = async (mw_app_id, amount, token) => {
    return await apiInstance.post(`myaccount/get-overlink-due`, {
        "mw_application_id": mw_app_id,
        "level_id": 1,
        "product_id": 0,
        "transaction_amount": amount,
        ...commonPayload
    }, {
        token
    })
}

export const fetchPartPaymentAPI = async (mw, token) => {
    return await apiInstance.post(`myaccount/fetch-part-payment-simulation`, {
        "mw_application_id": mw,
        "level_id": 1,
        "strategy": 200,
        "product_id": 0,
        ...commonPayload
    }, {
        token
    })
}

export const PartPayLinkAPI = async (result, token) => {
    return await apiInstance.post(`myaccount/fetch-part-payment-link`, {
        "mw_application_id": result.mw_application_id,
        "level_id": 1,
        "transaction_amount": result.amount,
        "strategy": result.strategy,
        "product_id": 0,
        ...commonPayload
    }, {
        token
    })
}

export const SaveLoginData = (data) => {
    data = JSON.stringify(data)
    sessionStorage.setItem("user", JSON.stringify(encryptWithHybrid(data)))
}

export const getLoginData = (pageReload) => {

    let data = sessionStorage.getItem("user")
    try {
        data = JSON.parse(data)
        if (data?.encryptedMessage && data?.encryptedAesKey && data?.iv) {
            data = decryptWithHybrid(data.encryptedMessage, data.encryptedAesKey, data.iv)
        } else {
            data = {}
        }
    } catch (err) {
        console.log(err);
        data = {}
    }
    if (data && Object.keys(data).length == 0) {
        if (pageReload != "NO") {
            window.location.href = `${react_base_url}login`;
        }
    }
    return data;
}

export const makePaymentAPI = async (data, token) => {
    return await apiInstance.post(`myaccount/make-payment`, {
        ...data,
        "level_id": 1,
        ...commonPayload
    }, {
        token
    })
}

export const DownloadPDFFunc = (pdf, title) => {
    var link = document.createElement('a');
    link.innerHTML = 'Download PDF file';
    link.download = `${title}.pdf`;
    link.href = 'data:application/pdf;base64,' + pdf;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const getTransactionsDownload = async (mw, token) => {
    return await apiInstance.post(`myaccount/fetch-transactions`, {
        "mw_application_id": mw,
        "level_id": 1,
        "product_id": 0,
        ...commonPayload
    }, {
        token
    })
}

export const updateToogleButton = async (data, token) => {
    return await apiInstance.post(`web/comm-preferences-submit`, {
        ...data,
        ...commonPayload
    }, {
        token
    })
}

export const getLoginMaster = async (cust_id, token) => {
    return axios.post(`${prodbase_url}master`, {
        "customer_id": cust_id,
        "api_type": "communication_preference",
        ...commonPayload
    }, {
        headers: {
            token
        }
    }).then((res) => {
        return res.data
    })
}

export const getLabelData = async (id, cust_id, token) => {
    return await apiInstance.post(`web/comm-preferences-label`, {
        "comm_pref_send_to": id,
        "customer_id": cust_id,
        ...commonPayload
    }, {
        token
    })
}

export const HeaderApi = async (parentid, token) => {
    return await apiInstance.post(`myaccount/dynamic-header-updated`, {
        ...commonPayload,
        "level_id": 1,
        "parent_id": parentid
    }, {
        token
    })

}





export const ReasonDeleteAPI = async (data, token) => {
    return await apiInstance.post(`submit-deletion-reason`, data, {
        token
    })
}

export const confirmDeleteAPI = async (data, token) => {
    return await apiInstance.post(`submit-deletion-response`, data, {
        token
    })
}

export const formmonthonly = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    return `${year}-${month}`;
}