import React, { useEffect, useState } from "react";
import man from "../../Icons/man.svg";
import cal from "../../Icons/calender.svg";
import gen from "../../Icons/Maritial_Icon.svg";
import DropDownIcon from "../../Icons/DropDownIcon.svg";
import FormHead from "../Journey Form/Form/FormHead";
import { useForm } from "react-hook-form";
import {
  getCustId,
  getMasterData,
  getToken,
} from "../EverCalledFunctions/EverCalledApis";
import {
  client_id,
  client_key,
  source,
  prodbase_url,
  verification_source,
  verified_by,
  verifier_name,
  react_base_url,
  doc_upload_url,
} from "../Secret_Credentials/Secret_Credential";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { GenerateToken, updateSuggestion_DropDown } from "../../Redux/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import calender from './../../../../Icons/calender.svg';
// import './DOBCalender.css'
import calender from "../../Icons/calender.svg";
import DateCalender from "../DateCalender/DateCalender";

const NomineForm = ({ updateStatus, leadId }) => {
  const [submit, setSubmit] = useState(false);
  const [firstClick, setFirstClick] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [gi, setGi] = useState();
  const [ri, setRi] = useState();
  const [isread, setIsread] = useState(false);
  const [checkbox, setCheckBox] = useState(true);
  const [one, setOne] = useState(false);
  const [selected, setSelected] = React.useState(-1);
  const [timeLimit, setTimeLimit] = React.useState({
    minTime: new Date(`${new Date().getFullYear()-45}-1-1`),
    maxTime: new Date()
  })
  const [Show, setShow] = useState({
    today: new Date(),
    max: new Date().getFullYear() - 21,
    min: new Date().getFullYear() - 60,
  });
  const [pincodeData, setPincodeData] = useState({
    show: false,
    data: [],
  });
  const [pincode, setPincode] = useState({
    show: false,
    data: [],
  });
  const [custId, setCustId] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: "",
      dob: "",
      gender: "",
      relation: "",
      address: "",
    },
    mode: "all",
  });
  const data = watch();
  const dispatch = useDispatch();
  const { token, suggestionDropDown } = useSelector((state) => state);

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, []);

  const Para =
    "I do hereby declare that I have received a loan from M/S MyLoanCare Ventures Private Limited(Master Policyholder). In order to secure that said loan I have taken the above referenced policy from HDFC life insurance company(HDFC life). In consideration of receiving the said loan I hereby authorize HDFC life to make payment of outstanding loan balance amount to Master policy holder by deducting from the claim proceeds payable on happening of the contingent event covered by the group life insurance scheme/policy referenced above.";

  const getUserData = async () => {
    if (!custId) {
      const APIDATA = await getCustId(leadId, token);
      setCustId(APIDATA);
    }
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const onSubmit = async (data) => {
    const payload = {
      lead_id: leadId,
      customer_id: custId,
      nm_name: data.name,
      nm_dob: formatDate(startDate),
      nm_gender: gi,
      nm_relationship: ri,
      nm_address: data.address,
      verification_source,
      verified_by,
      verifier_name,
      source,
    };
    await axios
      .post(`${prodbase_url}nominee-update`, payload, {
        headers: {
          token,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status_code === "200" && res.data.status === "1") {
          updateStatus(2);
        } else {
          updateStatus(0);
        }
      });
  };

  const handleAlphaOnly = (value, field) => {
    var regexp = /[^a-zA-Z ]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""));
    } else {
      setValue(field, value.replace(/^\s+/, ''));
    }
  };

  const Blured = (field, value) => {
    // var newVal = value.trimEnd();
    var newVal = value.replace(/\s+$/, '');
    setValue(field, newVal);
    if (newVal.length < 3) {
      setError(field);
    } else {
      clearErrors([field]);
    }
  };

  useEffect(() => {
    if (
      data.name &&
      startDate &&
      data.gender &&
      data.relation &&
      data.address &&
      checkbox
    ) {
      if (Object.keys(errors).length === 0) {
        setSubmit(true);
      } else {
        setSubmit(false);
      }
    } else {
      setSubmit(false);
    }
  }, [data]);

  const handleArrows = (e) => {
    if (pincodeData.show) {
      var key = e.keyCode;
      if (key != 40 && key != 38 && key != 13) {
        return;
      }
      if (key == 13) {
        e.stopPropagation();
        if (selected > -1 && selected < pincodeData.data.length) {
          setThisPin(pincodeData.data[selected]);
          // setValue('pin', pincodeData.data[selected])
          // clearErrors('pin')
          setSelected(-1);
        } else {
          setSelected(-1);
        }
      } else if (key == 38) {
        if (selected > 0) {
          setSelected(selected - 1);
        }
      } else if (key == 40) {
        if (selected < pincodeData.data.length - 1) {
          setSelected(selected + 1);
        }
      }
    }
  };

  const handleArr = (e) => {
    if (pincode.show) {
      var key = e.keyCode;
      if (key != 40 && key != 38 && key != 13) {
        return;
      }
      if (key == 13) {
        e.stopPropagation();
        if (selected > -1 && selected < pincode.data.length) {
          setThis(pincode.data[selected]);
          // setValue('pin', pincodeData.data[selected])
          // clearErrors('pin')
          setSelected(-1);
        } else {
          setSelected(-1);
        }
      } else if (key == 38) {
        if (selected > 0) {
          setSelected(selected - 1);
        }
      } else if (key == 40) {
        if (selected < pincode.data.length - 1) {
          setSelected(selected + 1);
        }
      }
    }
  };

  const getPopUpData = async () => {
    const APIDATA = await getMasterData("gender", token);
    if (!one) {
      if (APIDATA.length !== 0) {
        await setPincodeData({ ...pincodeData, show: true, data: APIDATA });
        dispatch(
          updateSuggestion_DropDown({ ...suggestionDropDown, show: true })
        );
      } else {
        setPincodeData({ ...pincodeData, show: false, data: [] });
      }
    }
  };

  const getPopUpDataRelation = async () => {
    const APIDATA = await getMasterData("relation_type", token);
    if (APIDATA.length !== 0) {
      await setPincode({ ...pincode, show: true, data: APIDATA });
      dispatch(
        updateSuggestion_DropDown({ ...suggestionDropDown, show: true })
      );
      setOne(true);
    } else {
      setPincode({ ...pincodeData, show: false, data: [] });
    }
  };

  const setThisPin = (pin) => {
    setValue("gender", pin.value);
    setGi(pin.id);
    setPincodeData({ ...pincodeData, show: false });
    clearErrors(["gender"]);
    // getCityNameHere(pin)
  };

  const setThis = (pin) => {
    setValue("relation", pin.value);
    setRi(pin.id);
    setPincode({ ...pincode, show: false });
    clearErrors(["relation"]);
    // getCityNameHere(pin)
  };

  useEffect(() => {
    if (token !== "") {
      getUserData();
    }
  }, [token]);

  const togglebtn = () => {
    setIsread((prevSate) => !prevSate);
  };

  return (
    <>
      <FormHead
        formName={"Submit Nominee Details to Get Free Insurance Cover"}
        progressBar={true}
      />
      <div className="customer-box">
        <p>
          <strong>Dear Customer</strong>
          <br />
          As part of your active Personal Loan with MoneyWide, you are eligible
          for a Free Insurance Cover "HDFC Life - Credit Protect".
        </p>
      </div>
      <div className="tag-for-input">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          {/* <div className={"input-tag remove-bottom" + (errors.name ? " error-border" : data.name ? " add-border" : "")}>
                        <img className="tag-icon" src={man} />
                        <input className="input-tag-box" type="text" placeholder='Name of the Nominee'  {...register('name', { required: true, minLength: 3 })} />
                    </div> */}
          {
            <div
              className={
                errors.name
                  ? "input-tag error-border remove-bottom"
                  : data.name
                  ? "input-tag add-border remove-bottom"
                  : "input-tag remove-bottom"
              }
            >
              <img className="tag-icon" src={man} />
              <input
                className="input-tag-box"
                type="text"
                placeholder="Name of the Nominee"
                {...register("name", { required: true, minLength: 3 })}
                autoComplete="off"
                onKeyUp={() => handleAlphaOnly(data.name, "name")}
                onBlur={() => Blured("name", data.name)}
              />
              {/* {errors.fatherName && <img className="error-img-show" src={ThisIsAnError} alt="" />} */}
            </div>
          }
          {errors.name && (
            <div className="err-text">Please Enter at least 3 Characters.</div>
          )}

          {/* <div className={"input-tag remove-bottom" + (errors.dob ? " error-border" : data.dob ? " add-border" : "")}>
                        <img className="tag-icon" src={cal} />
                        <input className="input-tag-box" type="text" placeholder='Date of Birth'  {...register('dob', { required: true })} />
                        <img src={DropDownIcon} alt="icon" className='small-the-icon' />
                    </div> */}
          <div
            className={
              "input-tag remove-bottom" +
              (firstClick && !data.dob
                ? " error-border"
                : data.dob
                ? " add-border"
                : "")
            }
            style={{ justifyContent: "left" }}
          >
            <img className="tag-icon" src={calender} />

            <DateCalender
              setStartDate={setStartDate}
              startDate={startDate}
              placeHolder={"Nominee Dob"}
              minYear={new Date().getFullYear()-45}
              timeLimit={timeLimit}
            />
          </div>

          {/* <div className={"input-tag remove-bottom" + (errors.gender ? " error-border" : data.gender ? " add-border" : "")}>
                        <img className="tag-icon" src={gen} />
                        <input className="input-tag-box" type="text" placeholder='Gender'  {...register('gender', { required: true })} />
                        <img src={DropDownIcon} alt="icon" className='small-the-icon' />
                    </div> */}

          <div
            className={
              errors.gender
                ? "input-tag error-border remove-bottom"
                : data.gender
                ? "input-tag add-border remove-bottom"
                : "input-tag remove-bottom"
            }
            style={{ marginBottom: "0px" }}
          >
            <img className="tag-icon" src={gen} />
            <input
              className="input-tag-box"
              type="text"
              placeholder="Select Gender"
              minLength={4}
              maxLength={6}
              onFocus={() => getPopUpData()}
              {...register("gender", {
                required: true,
                minLength: 4,
                maxLength: 6,
              })}
              onKeyDown={(e) => handleArrows(e)}
            />
            <img src={DropDownIcon} alt="icon" className="small-the-icon" />
            {/* onKeyUp={() => handleUpKeyNumber(data.gender, "gender", 6)} autoComplete='off' */}
            {/* {errors.office_pincode && <img src={ThisIsAnError} alt="" />} */}
            {suggestionDropDown.show &&
              pincodeData.show &&
              pincodeData.data.length && (
                <div className="dropDownBox">
                  {pincodeData.data.map((row, ind) => {
                    return (
                      <div
                        className={
                          "dropDownValue" + (selected === ind ? " select" : "")
                        }
                        key={ind}
                        onClick={() => setThisPin(row)}
                      >
                        {row.value}
                      </div>
                    );
                  })}
                </div>
              )}
          </div>

          {/* <div className={"input-tag remove-bottom" + (errors.relation ? " error-border" : data.relation ? " add-border" : "")}>
                        <img className="tag-icon" src={man} />
                        <input className="input-tag-box" type="text" placeholder='Relationship with Nominee'  {...register('relation', { required: true })} />
                        <img src={DropDownIcon} alt="icon" className='small-the-icon' />
                    </div> */}
          <div
            className={
              errors.relation
                ? "input-tag error-border remove-bottom"
                : data.relation
                ? "input-tag add-border remove-bottom"
                : "input-tag remove-bottom"
            }
            style={{ marginBottom: "0px" }}
          >
            <img className="tag-icon" src={gen} />
            <input
              className="input-tag-box"
              type="text"
              placeholder="Relationship with Nominee"
              minLength={4}
              maxLength={6}
              onFocus={() => getPopUpDataRelation()}
              {...register("relation", { required: true })}
              onKeyDown={(e) => handleArr(e)}
            />
            <img src={DropDownIcon} alt="icon" className="small-the-icon" />
            {/* onKeyUp={() => handleUpKeyNumber(data.relation, "relation", 6)} autoComplete='off' */}
            {/* {errors.office_pincode && <img src={ThisIsAnError} alt="" />} */}
            {suggestionDropDown.show && pincode.show && pincode.data.length && (
              <div className="dropDownBox">
                {pincode.data.map((row, ind) => {
                  return (
                    <div
                      className={
                        "dropDownValue" + (selected === ind ? " select" : "")
                      }
                      key={ind}
                      onClick={() => setThis(row)}
                    >
                      {row.value}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div
            className={
              "input-tag remove-bottom" +
              (errors.address
                ? " error-border"
                : data.address
                ? " add-border"
                : "")
            }
          >
            <textarea
              className="input-tag-box"
              type="text"
              placeholder="Residence Address of Nominee"
              {...register("address", { required: true, minLength: 3 })}
              autoComplete="off"
            ></textarea>
          </div>
          {errors.address && (
            <div className="err-text">Please Enter at least 3 Characters.</div>
          )}

          <div className="nominee-rmrl">
            <input
              type="checkbox"
              checked={checkbox}
              onClick={() => setCheckBox(!checkbox)}
            />
            <p>{isread ? Para : Para.substr(0, 139)}</p>
            <p className="nominee-read-more" onClick={togglebtn}>
              {isread ? "Read Less" : "Read More..."}
            </p>
          </div>

          <button
            className={
              "footer-btn Tmargin manage-nomine-bottom" +
              (submit ? " footer-btn-activate" : "")
            }
          >
            Continue
          </button>
        </form>
      </div>
    </>
  );
};

export default NomineForm;
