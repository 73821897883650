import React, { useEffect } from 'react'
import FAQSection from '../FAQSection/FAQSection';
import Calculator from '../PersonalLoan/Calculator';
import "./fee.css";
import EligibilityCriteria from '../PersonalLoan/images/eligibility-criteria.webp';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import greenticknew from '../../Icons/greenticknew.svg';
import NewLoginForm from '../Journey Form/Form/NewLoginForm';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import NewLeftPart from '../Journey Form/FormLeftPart/NewLeftPart';
import MobileFooter from '../Footer/MobileFooter';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';
const PLIRFeeAndCharges = () => {
    var loan_type_id_page = 56;
    const [data, setData] = React.useState({ heading: "", data: [],sub_bread: "Personal Loan Interest Rate Fees And Charges " })
    const dispatch = useDispatch()
    const { PLLoader, formStates, mwpages } = useSelector(state => state)
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY <= 200 || window.scrollY >= 3616) {
            sety(false);
        }
        else {
            sety(true);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])
    return (
        <>
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi />
            <div className='journey-stop'>
                <NewLoginForm pageType={loan_type_id_page} mapData={data} />
                {y && <RightSidePopUp />}
                <div className='ploan-content1 mob-margin-top web-margin-top rateinterestp'>
                    <h2 className="heading-h2" >Personal Loan <b>Interest Rate</b></h2>
                    <p>Personal loan is the best financing option to handle any unforeseen event that requires immediate funding. It is an unsecured credit used to meet financial obligations like weddings, home renovation, debt consolidation, medical emergencies, educational expenses, etc.</p>
                    <p>Based on factors like credit score, employment type, income, loan size, loan repayment tenure and credit history, Moneywide offers personal loans best interest rates starting at 16.50% rate.</p>
                </div>
                <div className='ploan-content1 mob-margin-top web-margin-top rateinterestp'>
                    <h2 className="heading-h2" >What Is <b>Reducing Balance Formula</b>?</h2>
                    <p>There are primarily 2 methods to calculate the interest rates on personal loan. A fixed interest rate EMI is one where the personal loan interest rate does not change during the repayment tenure of the loan. On the other hand, the interest amount varies depending upon the outstanding principal amount and is calculated at the lending interest rate under the reducing balance formula.</p>
                </div>
                <div className='ploan-content1 mob-margin-top web-margin-top rateinterestp'>
                    <h2 className="heading-h2">How to Calculate Personal Loan Interest Using <b>Reducing Balance Formula</b></h2>
                    <p><b>The formula for calculating the reducing balance interest rate EMI is as given below:</b></p>
                    <p>Interest to be paid on each instalment = The remaining loan amount x the interest rate that is applicable for each instalment.</p>
                </div>
                <section className="pl-charges newcredtopmargin">
                    <div className="ploan-contanier ourproducts-main">
                        <div className="box-sh">
                            <div className="eligibility-criteria-box new-cred-main">
                                <div className="product-heading nwprod-heading">
                                    <p></p>
                                    <span>Personal Loan</span>
                                    <p>Interest Rates Fees and Other Charges</p>
                                </div>
                                <div className="instant-box nw-cred-box">
                                    <div className="instant-head-cont">
                                        <div className="instant-head">
                                            <span>Personal Loan for Travel </span>
                                        </div>
                                        <div className="instant-contnt">
                                            <div className="instant-inner nwinstant">
                                                <span>Fee Type</span>
                                                <p>Amount</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Interest rate</span>
                                                <p>16% to 32%</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Processing fee</span>
                                                <p>2.5% of the loan amount, ₹ 1,000</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Partpayment charges</span>
                                                <p>3% of the prepaid amount after 1st EMI</p>
                                            </div>
                                            <div className="instant-inner nwinstant">
                                                <span>Foreclosure charges</span>
                                                <p>4% of the outstanding amount after 1st EMI</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="instant-bottom">
                                        <a href='#'>Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='ploan-content1 mob-margin-top web-margin-top rateinterestp'>
                    <h2 className="heading-h2">What are the Types of <b>Personal Loan Interest Rates</b>?</h2>
                    <p><b>There are two types of personal loan interest rates:</b></p>
                    <p><b>Fixed interest rates:</b>Under this, the interest rate for a loan is fixed throughout the repayment tenure. Also, the EMI amount remains constant.</p>
                    <p><b>Floating interest rates:</b>Floating interest rates or variable interest rates are linked to the internal benchmark rate of the financial institution. If this benchmark rate is influenced by any reason, it will directly affect the interest rate of the loan. Therefore, floating rates are different throughout the loan tenor.</p>
                </div>
                <section className="eligibility-criteria">
                    <div className="ploan-container">
                        <div className="box-sh">
                            <div className="eligibility-criteria-box">
                                <h2 className="heading-h2 underline-heading"><b>Factors Affecting</b> Personal Loan Interest Rate</h2>
                                <div className="ploan-row-criteria">
                                    <div className="colm-1">
                                        <img src={EligibilityCriteria} alt="Not Found" />
                                    </div>
                                    <div className="colm-2">
                                        <div className="mlc-pl-list mlc-pl-mrgin-top">
                                            <div className="mlc-pl-list-cont wd-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span><b>Income of the borrower:</b>Borrowers with high annual income get loans at lower interest rates as they are more likely to repay the debt in the specified time. Contrarily, if you have lower annual income you would pose a higher risk of payment default and therefore would get personal loans at higher interest rates.</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span><b>Nature of the employment:</b>At Moneywide, a salaried borrower can get an instant personal loan upto INR ₹ 3,00,000 amount with the repayment tenure of 36 months.</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span><b>Age of the borrower:</b>Anyone who is nearing his retirement age is prone to be offered personal loans at higher interest rates.</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span><b>Terms with the lender:</b>Existing customers of Moneywide, are more likely to get loans at affordable interest rates. However, it is not mandatory and is based on the personal preference and past relationship with the organisation</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Calculator />
                <div className='ploan-content1 mob-margin-top web-margin-top'>
                    <h2 className="heading-h2">How to Get Personal Loan at <b>Lowest Interest Rates?</b></h2>
                </div>
                <div className="repay-width interest-fee-charge eligib-top">
                    <p>Here are some ways that can help you avail a personal loan with minimum interest rates.</p>
                    <p><b>Improve your credit score:</b>You can avail low rates on Personal Loan if you have a high credit score. Therefore, it is important to check your credit score before applying for a loan and look for ways to improve it to get the best deals.</p>
                    <p><b>Repay EMIs on time:</b> You should have a consistent debt repayment cycle if you wish to have a good credit history. A bad profile will hinder your chances of getting a personal loan at a lower interest rate.</p>
                    <p><b>Be aware of the offers:</b>You can grab the special schemes at different times of the year, especially during festivals. If you avail of a loan during that time, you may get a personal loan at lower interest rates.</p>
                    <p><b>Market research:</b>It is important to explore the market and understand the loan terms and conditions before applying for a personal loan. It gives you a clear sight to compare interest rates and helps you in availing yourself of a loan at the best terms.</p>
                </div>
                {/* <section className='FAQs' >
                    <FAQSection />
                </section> */}
                {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                    <FAQSectionChk data={mwpages.faqdata} />
                </section> : ""}
            </div>
            <div className='journey-start' style={{ display: "none" }}>
                {PLLoader.show && <CKYCWaiting heading={"We are redirecting."} />}
                <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
                {(!formStates.IsIframe) && <MobileFooter />}
            </div>
        </>
    )
}

export default PLIRFeeAndCharges;