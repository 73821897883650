import React, { useEffect, useState } from 'react';
import './experienceyearpopup.css';
import { useSelector, useDispatch } from 'react-redux';
import { updatePopUp } from '../../../Redux/action';
import LeftSlow from '../../../Icons/LeftS.svg'
import RightSlow from '../../../Icons/RightS.svg'
import LeftFast from '../../../Icons/LeftF.svg'
import RightFast from '../../../Icons/RightF.svg'





const ExperienceYearPopup = ({DOBYear}) => {
  const [firstClick, setFirstClick] = useState({
    current: false,
    total: false
  })
  const [local, setLocal] = useState({
    year: new Date().getFullYear(),
    month:new Date().getMonth()
  })
  const { PopUpState } = useSelector(state => state)
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();



  //----------------------------OnChange Year---------------------------------- */ }
  const updateYear = (val, event) => {
    event.preventDefault();
    if (((local.year + val) <= currentYear) && (local.year + val > +(DOBYear)+17)) {
      setLocal({ ...local, year: local.year + val })
    }
    event.stopPropagation();
  }

  {/* ------------------------------------------OnChange Month---------------------------------- */ }
  const selectThisMonth = (monthNumber, event) => {
    event.preventDefault()
    if (PopUpState.WorkExperiencePopUp.show) {
      if (local.year === currentYear && monthNumber <= currentMonth + 1) {
        const updatedWorkExperiencePopUp = { ...PopUpState.WorkExperiencePopUp, month: monthNumber - 1, year: local.year, show: false ,firstClick:true}
        dispatch(updatePopUp({ ...PopUpState, WorkExperiencePopUp: updatedWorkExperiencePopUp }))
      }
      else if (local.year < currentYear) {
        const updatedWorkExperiencePopUp = { ...PopUpState.WorkExperiencePopUp, year: local.year, month: monthNumber - 1, show: false ,firstClick:true}
        dispatch(updatePopUp({ ...PopUpState, WorkExperiencePopUp: updatedWorkExperiencePopUp }))
      }
    }


    else if(PopUpState.TotalWorkExperiencePopUp.show) {
      if (local.year === currentYear && monthNumber <= currentMonth + 1) {
        const updatedWorkExperiencePopUp = { ...PopUpState.TotalWorkExperiencePopUp, month: monthNumber - 1, year: local.year, show: false ,firstClick:true}
        dispatch(updatePopUp({ ...PopUpState, TotalWorkExperiencePopUp: updatedWorkExperiencePopUp }))
      }
      else if (local.year < currentYear) {
        const updatedWorkExperiencePopUp = { ...PopUpState.TotalWorkExperiencePopUp, year: local.year, month: monthNumber - 1, show: false ,firstClick:true}
        dispatch(updatePopUp({ ...PopUpState, TotalWorkExperiencePopUp: updatedWorkExperiencePopUp }))
      }
    }
    event.stopPropagation();

  }

  return (
    <div className="main-cal-of-exp">
      <div className="calendar-content">
        {/* -------------------------------------Left Side Buttons for Year Change----------------------------- */}
        <div className="calendar-head">
          <button className={"calendar-btn btn-fast " + (local.year - 5 <= +(DOBYear)+17 ? "calendar-btn-disable" : "")} onClick={(event) => updateYear(-5, event)}><img className="small-the-icon" src={LeftFast} /></button>

          <button className={"calendar-btn btn-slow " + (local.year - 1 <= +(DOBYear)+17 ? "calendar-btn-disable" : "")} onClick={(event) => updateYear(-1, event)}><img className="small-the-icon" src={LeftSlow} /></button>

          {/* ------------------------------------------Year Display---------------------------------- */}
          <div className="show-year">{local.year}</div>

          {/* --------------------------------Right Side Buttons for Year Change-------------------------------- */}

          <button className={"calendar-btn btn-slow " + (local.year + 1 > new Date().getFullYear() ? "calendar-btn-disable" : "")} onClick={(event) => updateYear(+1, event)}><img className="small-the-icon" src={RightSlow} /></button>
          <button className={"calendar-btn btn-fast " + (local.year + 5 > new Date().getFullYear() ? "calendar-btn-disable" : "")} onClick={(event) => updateYear(+5, event)}><img className="small-the-icon" src={RightFast} /></button>
        </div>

        {/* ------------------------------------------Months Name---------------------------------- */}
        <div className="calendar-body">
          <div className="cal-box"><button className={"box-content-cal "+(local.year===new Date().getFullYear() && (1>new Date().getMonth()+1)?" disable":"")} onClick={(event) => selectThisMonth(1, event)} >JAN</button></div>
          <div className="cal-box"><button className={"box-content-cal "+(local.year===new Date().getFullYear() && (2>new Date().getMonth()+1)?" disable":"")} onClick={(event) => selectThisMonth(2, event)} >FEB</button></div>
          <div className="cal-box"><button className={"box-content-cal "+(local.year===new Date().getFullYear() && (3>new Date().getMonth()+1)?" disable":"")} onClick={(event) => selectThisMonth(3, event)} >MAR</button></div>
          <div className="cal-box"><button className={"box-content-cal "+(local.year===new Date().getFullYear() && (4>new Date().getMonth()+1)?" disable":"")} onClick={(event) => selectThisMonth(4, event)} >APR</button></div>
          <div className="cal-box"><button className={"box-content-cal "+(local.year===new Date().getFullYear() && (5>new Date().getMonth()+1)?" disable":"")} onClick={(event) => selectThisMonth(5, event)} >MAY</button></div>
          <div className="cal-box"><button className={"box-content-cal "+(local.year===new Date().getFullYear() && (6>new Date().getMonth()+1)?" disable":"")} onClick={(event) => selectThisMonth(6, event)} >JUN</button></div>
          <div className="cal-box"><button className={"box-content-cal "+(local.year===new Date().getFullYear() && (7>new Date().getMonth()+1)?" disable":"")} onClick={(event) => selectThisMonth(7, event)} >JUL</button></div>
          <div className="cal-box"><button className={"box-content-cal "+(local.year===new Date().getFullYear() && (8>new Date().getMonth()+1)?" disable":"")} onClick={(event) => selectThisMonth(8, event)} >AUG</button></div>
          <div className="cal-box"><button className={"box-content-cal "+(local.year===new Date().getFullYear() && (9>new Date().getMonth()+1)?" disable":"")} onClick={(event) => selectThisMonth(9, event)} >SEP</button></div>
          <div className="cal-box"><button className={"box-content-cal "+(local.year===new Date().getFullYear() && (10>new Date().getMonth()+1)?" disable":"")} onClick={(event) => selectThisMonth(10, event)} >OCT</button></div>
          <div className="cal-box"><button className={"box-content-cal "+(local.year===new Date().getFullYear() && (11>new Date().getMonth()+1)?" disable":"")} onClick={(event) => selectThisMonth(11, event)} >NOV</button></div>
          <div className="cal-box"><button className={"box-content-cal "+(local.year===new Date().getFullYear() && (12>new Date().getMonth()+1)?" disable":"")} onClick={(event) => selectThisMonth(12, event)} >DEC</button></div>
        </div>
      </div>
    </div>
  )
}

export default ExperienceYearPopup;
