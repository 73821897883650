import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GenerateToken } from "../../Redux/action";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { prodbase_url } from "../Secret_Credentials/Secret_Credential";
import "./sitemap.css";
// import Banner from "./SiteMapBg.svg";

const SiteMap = () => {
  const { token } = useSelector((state) => state);
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (token !== "") {
      axios.post(`${prodbase_url}api/fetch-htmlsitemap-detail`, {
        source: "MWide",
      },{
        headers:{
          token
        }
      }).then((res)=>{
        if((res.data.status === "1") && (res.data.status_code === "200")){
          setData(res.data.data);
        }
      })
    }
  }, [token]);

  return (
    <>
      <div className="policy-img">
      <div className="left-top">
          <Link to={`/${window.location.search}`}>Home</Link>  &gt;{" "}
          <span>Sitemap</span>
        </div>
        {/* <img src={Banner} /> */}
        <h1 className="policy-head policy-head-site">HTML Sitemap</h1>
      </div>
      <div className="site-map-cont">
        {data ? <div>
          {data && data.map((el,idx)=>{
            return(
              <div key={idx}>
                <p className="site-map-cont-head">{el.header_name}</p>
                <hr/>
                <div className="company-links">
                {el.sub_menu.map((el)=>{
                  return(
                    <div className="company-links-main">
                    <a href={el.link}>{el.header_name}</a>
                    </div>
                  )
                })}
                    </div>
              </div>
            )
          })}
        </div> :""}
      </div>
    </>
  );
};

export default SiteMap;
