import React from 'react';
import MakePaymentForm from './MakePaymentForm';
import MakePaymentOption from './MakePaymentOption';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMakePayment } from '../../Redux/action';
import OverDueSuccess from './OverDueSuccess'
import yellownotify from '../../Icons/yellownotify.svg'
import NoLoan from '../../Icons/NoLoan.svg'
import './Settings.css'

const MakePayment = () => {
    const [currentForm, setCurrentForm] = useState(1);
    const [baseData, setBaseData] = useState({
        name: "",
        Id: "",
        program: ""
    });
    const { makePayment } = useSelector(state => state)
    const dispatch = useDispatch();

    const CloseMakePayment = () => {
        dispatch(updateMakePayment({ ...makePayment, show: false }))
        sessionStorage.clear()
    }

    return (

        <div className="blur-background" onClick={() => CloseMakePayment()}>
            <div className="pop-content login-area-popup make-pay" onClick={(e) => { e.stopPropagation() }}>
                <div className="main-popUP-form" >
                    <div className="popup-form-head">
                        {currentForm == 3 ? "" : <span>{(currentForm == 5 ? "Oops" : (currentForm == 6 ? "No Pending Dues!" : " Make Payment"))}</span>}
                    </div>
                    {
                        currentForm == 1 ? <MakePaymentForm setCurrentForm={setCurrentForm} setBaseData={setBaseData} baseData={baseData} /> :
                            (currentForm == 2 ? <MakePaymentOption baseData={baseData} setCurrentForm={setCurrentForm} /> :
                                (currentForm == 3 ? <OverDueSuccess name={baseData?.name} isMakePayment={true} baseData={baseData} /> :
                                    (currentForm == 4 ? <Sorry /> :
                                        (currentForm == 5 || currentForm == 6 ? <NoDues currentPage={currentForm} /> :
                                            ""))))
                    }

                </div>
            </div>
        </div>
    )
}

const NoDues = ({ currentPage }) => {
    return (<div className="myLoanBox" style={{ textAlign: "center", lineHeight: "140%",boxShadow:"none" }}>
        <div>
            <img src={NoLoan} alt="" />
        </div>
        {currentPage == 5 ? "You don't have any active loan linked to this mobile number." :
            currentPage == 6 ? "You do not have any pending payment on your account." : ""}
    </div>)
}

const Sorry = () => {
    return (<div className="foreclose-yellow-box">
        <img src={yellownotify} alt="yellownotify" />
        <span>Dear Customer ,Sorry for the inconvenience. We are facing some technical error. Please try again after some time.<br />Regards <br />Team MoneyWide.</span>
    </div>)
}

export default MakePayment;