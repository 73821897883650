import AWS, { SecretsManager } from 'aws-sdk'
import { AccessKeyId, SecretAcceessKey } from '../Secret_Credentials/Secret_Credential';

const secretsManager = new AWS.SecretsManager({
    region: 'ap-south-1',
    accessKeyId: AccessKeyId,
    secretAccessKey: SecretAcceessKey,
});


export const getSecret = async (name) => {
    try {
        const data = await secretsManager.getSecretValue({ SecretId: name }).promise();
        if(name = 'ApiEndPoints') {
            sessionStorage.setItem('allApiEndPoints', data.SecretString);
        }
        return data?.SecretString
    } catch (error) {
        console.error('Error Retrieving secret:', error);
    }
};

export const updateSecret = async (name, value) => {
    try {
        await secretsManager.updateSecret({
            SecretId: name,
            SecretString: value,
        }).promise();
        alert('Secret updated successfully!');
    } catch (error) {
        console.log('Error updating secret:', error);
        alert('Error updating secret. Please check the console for details.');
    }
};

export const handleCreateSecret = async (name, value) => {
    try {
        await secretsManager.createSecret({
            Name: name,
            SecretString: value,
        }).promise();
        alert('Secret created successfully!');
    } catch (error) {
        console.error('Error creating secret:', error);
        alert('Error creating secret. Please check the console for details.');
    }
}

export const DeleteSecret = async (name) => {
    try {
        await secretsManager.deleteSecret({
            SecretId: name,
        }).promise();
    } catch (error) {
        console.error('Error deleting secret:', error);
        alert('Error deleting secret. Please check the console for details.')
    }
};

export const ListSecret = async () => {
    try {
        let data = await secretsManager.listSecrets().promise();
        if (data.SecretList) {
            data.SecretList()
        }
    } catch (error) {
        console.error('Error listing secrets:', error);

    }
}

export default SecretsManager;