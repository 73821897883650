import React, { useState } from "react";
import { decryptQueryIdCustomerId } from "../EverCalledFunctions/EverCalledFunctions";
import { useDispatch, useSelector } from "react-redux";
import { updateCredentialsState, updateMwApplicationId } from "../../Redux/action";
import { prodbase_url, react_base_url, source, verification_source, verified_by, verifier_name } from "../Secret_Credentials/Secret_Credential";
import { AxiosThirty } from "../AxiosConfiguration/AxiosConfig";
import { useNavigate } from "react-router-dom";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";

function Selfie() {
    const { mw_application_id, credentialState, token } = useSelector(state => state);
    const dispatch = useDispatch();
    const [lenderId, setLenderId] = useState("");
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);

    const decryptingFid = async () => {
        let qryStr = await decryptQueryIdCustomerId();
        console.log(qryStr);
        dispatch(updateMwApplicationId(qryStr[0]));
        dispatch(updateCredentialsState({
            ...credentialState,
            cust_id: qryStr[1]
        }));
        setLenderId(qryStr[2]);
    }

    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://app.digio.in/sdk/v11/digio.js';
        script.async = true;
        document.body.appendChild(script);
        decryptingFid()
    }, []);

    React.useEffect(() => {
        if ((mw_application_id != "") && (credentialState.cust_id != "") && (lenderId != "")) {
            let updated_selfie_vkyc = "";
            console.log(credentialState.cust_id);
            const payload = {
                "source": source,
                "customer_id": credentialState.cust_id,
                "lead_id": mw_application_id,
                "lenderId": lenderId,
                "level_id": 1,
                "product_id": 0,
                "partner_id": 42,
                "updated_selfie_vkyc": ((updated_selfie_vkyc == "") ? 0 : updated_selfie_vkyc),
                "verified_by": verified_by,
                "verifier_name": verifier_name,
                "verification_source": verification_source
            }
            AxiosThirty.post(`${prodbase_url}create-selfie`, payload, {
                headers: {
                    token
                }
            })
                .then((res) => {
                    if ((res.data.status == 1) && (res.data.status_code == 200)) {
                        setLoader(false)
                        // gettingerror('selfie initiate web', mw_application_id)
                        const entity_id = res.data.data.access_token.entity_id;
                        const id = res.data.data.access_token.id;
                        const customer_identifier = res.data.data.customer_identifier;

                        window.sessionStorage.setItem("cur_step", 3);
                        window.sessionStorage.setItem("next_step", 4);
                        window.sessionStorage.setItem("mw_app_id", mw_application_id);
                        window.sessionStorage.setItem("cust_id", credentialState.cust_id);

                        var options = {
                            "callback": function (t) {
                                if (t.hasOwnProperty('error_code')) {
                                    document.getElementById("loading").style.display = 'none';
                                    document.getElementById("result").innerHTML = "failed to sign with error : " + t.message;
                                }
                                else {
                                    // sessionStorage.clear();
                                    document.getElementById("loading").style.display = 'none';
                                    document.getElementById("result").innerHTML = "Sign Successful";
                                }
                            },
                            "is_redirection_approach": true,
                            "logo": "https://www.moneywide.com/assets/images/logo.webp",
                            "is_iframe": false,
                            "redirect_url": `${react_base_url}vkyc/redirect/`,
                            "environment": "production"
                            // "environment": "sandbox"
                        };
                        var digio = new window.Digio(options);
                        digio.init();
                        digio.submit(entity_id, customer_identifier, id);
                    }

                }).catch((err) => {
                    // setRocket(true);
                })
        }
    }, [mw_application_id, credentialState.cust_id, lenderId])

    return (
        <div>
            {
                loader && <CKYCWaiting />
            }
        </div>
    )
}

export default Selfie;