import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../Journey Form/Form/All_Forms/DOBCalender.css'

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const DateCalender = ({ startDate, setStartDate, placeHolder, minYear, maxYear, timeLimit, read,format }) => {

    const Show = {
        today: new Date(),
        max: maxYear ? maxYear : new Date().getFullYear(),
        min: minYear
    }
    const MinMax = {
        minDate: timeLimit.minTime,
        maxDate: timeLimit.maxTime
    }
    const changeMonthDropDown = (year) => {
        if (year == MinMax.maxDate.getFullYear() || year == MinMax.minDate.getFullYear()) {
            if (year == MinMax.maxDate.getFullYear() && year == MinMax.minDate.getFullYear()) {
                return months.slice(MinMax.minDate.getMonth(), MinMax.maxDate.getMonth() + 1)
            } else if (year == MinMax.maxDate.getFullYear()) {
                return months.slice(0, MinMax.maxDate.getMonth() + 1)
            } else {
                return months.slice(MinMax.minDate.getMonth(), 13)
            }
        }
        return months.slice(0, 13)
    }
    const [allMonths, setAllMonths] = useState(changeMonthDropDown(new Date().getFullYear()))
    const years = []
    for (var i = Show.max; i >= Show.min; i--) {
        years.push(i);
    }

    const getMonth = (date) => {
        return date.getMonth();
    }

    const getYear = (date) => {
        return date.getFullYear();
    }

    const handleDateClick = (date) => {
        if (timeLimit) {
            if (date >= timeLimit.minTime && date <= timeLimit.maxTime) {
                setStartDate(date)
            } else if (date < timeLimit.minTime) {
                setStartDate(timeLimit.minTime)
            } else {
                setStartDate(timeLimit.maxTime)
            }
        } else {
            if (date.getFullYear() < Show.min) {
                setStartDate(new Date(`${date.getDate()} ${months[date.getMonth()]}, ${Show.min}`))
            } else if (date.getFullYear() > Show.max) {
                setStartDate(new Date(`${date.getDate()} ${months[date.getMonth()]}, ${Show.max}`))
            } else {
                setStartDate(date)
            }
        }

    }



    return (
        <div>
            <DatePicker
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth
                }) => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "0 15px",
                            gap: 10,
                            flexDirection: (format == 1 ? "row-reverse" : "")
                        }}
                    >
                        <select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                            }
                        >
                            {allMonths.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <select
                            value={getYear(date)}
                            onChange={({ target: { value } }) => {
                                changeYear(value)
                                setAllMonths(changeMonthDropDown(value))
                            }}
                        >
                            {years.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>




                    </div>
                )}
                selected={startDate}
                dateFormat="dd-MMM-yyyy"
                onChange={(date) => handleDateClick(date)}
                placeholderText={placeHolder}
                minDate={timeLimit.minTime}
                maxDate={timeLimit.maxTime}
                readOnly={read}
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
            />
        </div>
    )
}

export default DateCalender;