import React from 'react'
import upload_btn from "../../Icons/upload_btn.svg"
import remove_btn from "../../Icons/remove_btn.svg"
function DocInput({ data, documents, setDocuments }) {
  const handleFileChange = (e) => {
    let list = { ...documents }
    let values = {
      "file": e.target.files[0],
      "key": data.keys,
      "id": data.id
    }
    list[data.id] = values
    setDocuments(list)
  }
  const handleRemoveDocs = () => {
    let list = { ...documents }
    delete list[data.id]
    setDocuments(list)
  }
  return (
    <div className='partner-doc'>
      {/* <input type="text" placeholder='Upload Doc' /> */}
      <div className="proof-border" style={{ marginTop: "20px" }}>
        {documents[data.id] ? (
          <div className="proof-row doc-case" style={{ position: "relative" }} >
            {
              <span className="proof-row-p">
                {documents[data.id]?.file?.name}
              </span>
            }
            <img className="specific-width-height pointer-cur" src={remove_btn} alt="" onClick={() => handleRemoveDocs()} />
          </div>
        ) : (
          <div className="proof-row">
            <label htmlFor={"upload-the-photo" + data.id}>
              <p className="proof-row-p">Upload {data.value}</p>
              <a>
                <img className="specific-width-height" src={upload_btn} alt="" />
              </a>
            </label>
            <input type="file" name={data.id} className="upload-the-photo" id={"upload-the-photo" + data.id} accept=".jpg, .jpeg, .pdf," onChange={(e) => handleFileChange(e)} />
          </div>
        )}
        <span className="err-text" style={{ position: "absolute" }}>Only pdf,jpeg and jpg format are allowed</span>
      </div>
    </div>
  )
}

export default DocInput