import React from "react";
import { react_base_url } from "../Secret_Credentials/Secret_Credential";
function AboutMoney() {
  const [openSection, setOpenSection] = React.useState([
    {
      question: "About MoneyWide",
      content: "MoneyWide is a digital lending application that offers 100% digital, paperless and instant personal loans using cutting-edge proprietary technology. We are a part of the PB Fintech Group that runs India’s leading marketplaces, Policybazaar and Paisabazaar. All loans are offered with 100% transparency and strict adherence to RBI’s Digital Lending Guidelines. MoneyWide is a registered trademark.",
      isActive: true
    }, {
      question: "About Personal Loans",
      content: "MoneyWide personal loan is an unsecured instant loan that you can avail of to fulfil various personal or business-related financial requirements such as unplanned medical emergencies, planning a vacation, home renovation etc. The loan can be availed digitally with paperless approval through Video KYC. The entire process takes less than 30 seconds for in-principle approval and instant disbursal on the same day. You can also avail of Personal Loans if you are a first-time borrower with a 0 or -1 credit score.",
      isActive: false
    }
  ])

  const handleCl = ( ind) => {
    var data = [...openSection]
    if (data[ind].isActive) {
      data[ind].isActive = false
    } else {
      data.map((row, ind) => {
        row.isActive = false
      })
      data[ind].isActive = true
    }
    setOpenSection(data)
  }

  return (
    <section className="about-mw">
      <div className="homebanner">
        <div className="left-content">
          <div className="about-mwide">
            {typeof (openSection) === "object" && openSection.length !== 0 && openSection.map((row, ind) => {
              return <div key={ind} className={'question-box ' + (row.isActive ? "active" : "notactive")}
                onClick={() => handleCl( ind)}>
                <div className="question">
                  <div className="question-left">{row.question}</div>
                  <div>{row.isActive ? (<img className="faq-arr" src={`${react_base_url}assets/images/headerlogo/BlueArr.svg`} alt="na" />) : (<img src={`${react_base_url}assets/images/headerlogo/downarrow.svg`} alt="" /> )}</div>
                </div>
                {row.isActive && <div className="FAQ-Question-Content"><span className='heading-p'>{row.content}</span></div>}
              </div>
            })}
          </div>
        </div>
        <div className="right-content">
          <img src={`${react_base_url}assets/images/AboutMWHome.svg`} alt="na" />
        </div>
      </div>
    </section >
  );
}

export default AboutMoney;
