import React from 'react'

const Pagination = ({pg, handlePagination, id}) => {
  // const handlePagination = () => {
  //   console.log("Pagination");
  // }
  return (
    <>
    <div className="pagination" id={id} onClick={(e) => handlePagination(e)} >
        <a className="pagi-btn">{pg}</a>
    </div>
    </>
  )
}

export default Pagination