import React from 'react';
import Slider from 'react-slick';
import dashed from "../../Icons/dashed-border.svg"
import dashedopst from "../../Icons/dashed-opst.svg"

const MobileConventionalSlider = ({ givenData }) => {

    const sliderData = [...givenData, ...givenData]
    var sliderblog = {
        infinite: false,
        autoplay: true,
        cssEase: 'ease-in-out',
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        swipe: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        responsive: [
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
        ]
    };

    const LeftSlides = {
        ...sliderblog,
        vertical: true,
        rtl: true
    }
    const RightSlides = {
        ...sliderblog,
        vertical: true
    }
    const centerSlides = {
        ...sliderblog,
        responsive: [
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
        ]
    }
    return (
        <div>
            <div className="top-slider" >
                <Slider {...LeftSlides}>
                    {sliderData.map((row, ind) => {
                        return <div key={ind} className='about-conv-slides'>
                            <div>{row.left}</div>
                        </div>
                    })}
                </Slider>
                <div className="about-heading">
                    <img src={dashed} alt='na'/>
                    <span>Conventional NBFC</span>
                    <img style={{transform:"rotate(270deg)"}} src={dashed} alt='na'/>
                </div>
            </div>

            <div className="center-box">
                <Slider {...centerSlides}>
                    {sliderData.map((row, ind) => {
                        return <div key={ind} className='about-conv-slides'>
                            <div className="img-div">
                                <div className="img-b">
                                    <img src={row.img} alt="img" />
                                    <span>{row.imgtext}</span>
                                </div>
                            </div>
                        </div>
                    })}
                </Slider>
            </div>
            <div className="bottom-slider" >
                <div className="about-heading moneywide-dashed" style={{display:"flex", justifyContent:"space-around", alignItems:"end"}}>
                    <img src={dashedopst} alt='na'/>
                    <span style={{marginBottom:"12px"}}>Moneywide</span>
                    <img style={{transform:"rotate(90deg)"}} src={dashedopst} alt='na'/>
                </div>
                <Slider {...RightSlides}>
                    {sliderData.map((row, ind) => {
                        return <div key={ind} className='about-conv-slides'>
                            <div>{row.right}</div>
                        </div>
                    })}
                </Slider>
            </div>
        </div>
    )
}

export default MobileConventionalSlider;