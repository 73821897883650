import React from "react";
import "./innercard.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { prodbase_blog_url, source, verification_source, verified_by, verifier_name } from "../Secret_Credentials/Secret_Credential";
import { useSelector } from "react-redux";
import Helmet from "react-helmet";

const BlogInnerCard = ({
  heading,
  author,
  time,
  views,
  image,
  content,
  bio,
  id,
  blogid,
  title,
  desc,
  keyword
}) => {
  const { token } = useSelector((state) => state);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  const share_url=window.location.href;

  const facebook_url=`https://www.facebook.com/sharer/sharer.php?u=${share_url}`;
  const twitter_url=`https://twitter.com/intent/tweet?url=${share_url}`;
  const linkedin_url=`https://www.linkedin.com/shareArticle?mini=true&url=${share_url}`;

  return (
    <>
    <Helmet>
            <title>{title}</title>
            <meta name="description" content={desc} />
            <meta name="keywords" content={keyword} />
    </Helmet>
      <div className="blog-new-header">
        <div className="blog-inner-header">
          <p style={{lineHeight:"140%"}}>
            <a href={`/${window.location.search}`}>Home</a> <span>❯</span> <a href={`/blog${window.location.search}`}>Blog</a>{" "}
            <span>❯</span>
            {heading}
          </p>
        </div>
      </div>

      <div className="blog-inner">
        <h1>{heading}</h1>
        <div className="blog-inner-info">
          <p>
            Author - <strong>{author}</strong>
          </p>
          <p>{time} MIN READ</p>
          <p>
            VIEWS - <strong>{views}</strong>
          </p>
        </div>
        <div className="blog-inner-image-main">
          <img src={image} alt=""/>
          <div className="social-media">
            <a href={facebook_url} target="_blank" className="facebook"></a>
            <a href={twitter_url} target="_blank" className="twitter"></a>
            <a href={linkedin_url} target="_blank" className="linkedin"></a>
          </div>
        </div>
        <div className="blog-inner-content-main" id={id}>{content}</div>
        <div className="blog-inner-author">
          <h2>About Author</h2>
          <p><strong>{author}</strong></p>
          <p>{bio}</p>
        </div>
      </div>
    </>
  );
};

export default BlogInnerCard;
