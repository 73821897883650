import React, { useEffect, useState } from 'react';
import "../../Loader/Loader.css"
import Lottie from 'react-lottie-player';
import lottieJson from "../../assests/lootie-files/successful.json";
import './thank.css';
import { useDispatch,useSelector} from 'react-redux';
import { setCurrentStep, updateStepBar,updateFormState } from '../../../Redux/action';
import { react_base_url } from '../../Secret_Credentials/Secret_Credential';
import { encryptAGRID, RelocateUTMCheck } from '../../EverCalledFunctions/EverCalledFunctions';

const ThankyouPopup = ({ content, heading }) => {
    const { step_bar, mw_application_id, credentialState, loginDetails,formStates, personal_loan } =
    useSelector((state) => state);
   const [continueNextSection, setcontinueNextSection] = useState(false);
   const [timer,setTimer] = useState(4);
   const dispatch=useDispatch();
  useEffect(() => {
   timer > 0 && setTimeout(() => {
     setTimer(timer - 1)
   }, 1000)
 }, [timer])
   useEffect(()=>{
      let qryStr = encryptAGRID(mw_application_id);
      setTimeout(() => {
      //   dispatch(updateFormState({ ...formStates,Email: false,showThank:false,Terms:true}))\
      RelocateUTMCheck(`${react_base_url}loan-agreement/?agrid=${qryStr}`)
      }, 4000);
   },[])
   return (
      <>
         <div className={continueNextSection ? "loaderbackground remove-block" : "loaderbackground"}>
            <div className='loadertransparent'>
               <div className='thank-container'>
                  <div className='thank-lotie'>
                     <Lottie loop animationData={lottieJson} play />
                  </div>
                  <p className='thank-p'>{heading}</p>
                  {content && <p className='thank-p1'>{content}</p>}
                  <p style={{textAlign:"center"}}>Automatically Redirect after {timer} Seconds</p>
               </div>
            </div>
         </div>
      </>
   )
}

export default ThankyouPopup

