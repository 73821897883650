import React, { useEffect } from 'react';
import './LoginPopUp.css';
import Lottie from 'react-lottie-player';
import WaitingLottie from '../assests/lootie-files/logo-loader-3.json'

const CKYCWaiting = ({heading,time}) => {
    const [hide,setHide] = React.useState(true);
    const [show,setShow] = React.useState(heading);
    const [timer,setTimer] = React.useState(time);


useEffect(()=>{
   if(hide){
    setTimeout(() => {
        setShow("This may take sometime.")
        setHide(false);
    }, 10000)
   }else{
    return undefined
   }
//    console.log(hide)
},[hide])

useEffect(() => {
    timer > 0 && setTimeout(() => {
      setTimer(timer - 1)
    }, 1000)
  }, [timer])


setTimeout(() => {
    setShow("We are almost there. Thank you for your patience")
}, 20000);

    return (
        <div className='blur-background '>
            <div className="pop-content">
                <div className="main-popUP-form">
                    <div className="littie-time">
                        <Lottie loop animationData={WaitingLottie} play className="ckyc-loader" />
                    </div>
                    <div className="content">
                        <p><strong>Please Wait ...</strong><br/>{show}</p>
                    </div>
                   { time ? <p style={{textAlign:"center"}}>This will automatically redirect within {timer} seconds</p>:""}
                </div>
            </div>
        </div>
    )
}

export default CKYCWaiting
