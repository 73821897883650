import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import logo from "./images/footer-logo.webp";
import facebook from "../../Icons/facebook.svg";
import twitter from "../../Icons/twitter.svg";
import youtube from "../../Icons/youtube.svg";
import instagram from "../../Icons/instagram.svg";
import linkedin from "../../Icons/linkdin.svg";
import IndianFlag from "./images/indianflag.webp";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderNavigation, updateMakePayment } from "../../Redux/action";
import google from "../../Icons/money-wide-app-google-play-store.webp";
import { react_base_url } from "../Secret_Credentials/Secret_Credential";

function Footer() {
  const dispatch = useDispatch();
  const { makePayment } = useSelector(state => state)
  return (
    <>
      <div className="footer-main" >
        <div className="footer-container" >
          <div className="footer-row">
            <div className="footer-address">
              <img src={logo} alt="Image not Found" />
              <p className="footer-p">
                <strong>MLC Finotech Private Limited</strong>
                <br />
                Plot No-131, Sector-44,<br />
                Gurugram - 122003 <br />
              </p>

              <p className="footer-p">
                <strong>
                  Email:
                  <br />
                </strong>
                <a className="contact-a"
                  href="mailto:corporate@moneywide.com"
                  style={{ textDecoration: "none", color: "white" }}
                >corporate@moneywide.com</a>
              </p>

              <p className="footer-p">
                <strong>
                  Contact Us:
                  <br />
                </strong>
                <a style={{ color: "white", textDecoration: "none" }} href="tel:9911862226">+91-9911862226</a><br />
                {/* +91-9911862226<br /> */}
                (Monday to Friday- 10 AM to 6:30 PM & Saturday-10 AM to 5:00 PM)
              </p>

              {/* <div className="google-play">
                <p style={{lineHeight:"20px"}}>
                  <strong>Download the App</strong>
                </p>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.app.moneywide&amp;hl=en_IN&amp;gl=US"
                >
                  <img
                    width="160"
                    height="45"
                    src={google}
                    alt="moneywide playstore app link"
                  />
                </a>
              </div> */}
              <p style={{ lineHeight: "20px" }}>
                <strong>Connect with us</strong>
              </p>
              <div className="social-media">
                <a href={`https://www.facebook.com/MoneyWide1/${window.location.search}`} target="_blank" >
                  <img src={facebook} alt="NA" />
                </a>
                <a href={`https://twitter.com/MoneyWide_MW${window.location.search}`} target="_blank" >
                  <img src={twitter} alt="NA" />
                </a>
                <a href={`https://www.linkedin.com/company/moneywidemw/${window.location.search}`} target="_blank" >
                  <img src={linkedin} alt="NA" />
                </a>
                <a href={`https://www.instagram.com/moneywide_official/${window.location.search}`} target="_blank" >
                  <img src={instagram} alt="NA" />
                </a>
                <a href={`https://www.youtube.com/channel/UCukwkykwr9wqb3a9yICRC0Q${window.location.search}`} target="_blank" >
                  <img src={youtube} alt="NA" />
                </a>
              </div>
            </div>
            <div className="footer-box">
              <span>MoneyWide</span>
              <ul>
                <Link
                  className="link-li"
                  to={`/${window.location.search}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li
                    onClick={() =>
                      dispatch(
                        setHeaderNavigation({
                          home: false,
                          pl: true,
                          sr: true,
                          cu: true,
                        })
                      )
                    }
                  >
                    Home
                  </li>
                </Link>
                <Link
                  className="link-li"
                  to={`/about-us/${window.location.search}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li
                    onClick={() =>
                      dispatch(
                        setHeaderNavigation({
                          home: true,
                          pl: true,
                          sr: true,
                          cu: true,
                        })
                      )
                    }
                  >
                    About Us
                  </li>
                </Link>
                <Link
                  className="link-li"
                  to={`/contact-us/${window.location.search}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li
                    onClick={() =>
                      dispatch(
                        setHeaderNavigation({
                          home: true,
                          pl: true,
                          sr: true,
                          cu: false,
                        })
                      )
                    }
                  >
                    Contact Us
                  </li>
                </Link>
                <Link
                  className="link-li"
                  to={`/service-request/${window.location.search}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li
                    onClick={() =>
                      dispatch(
                        setHeaderNavigation({
                          home: true,
                          pl: true,
                          sr: false,
                          cu: true,
                        })
                      )
                    }
                  >
                    Service Request
                  </li>
                </Link>
                <Link
                  className="link-li"
                  to={`/our-partner/${window.location.search}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li
                    onClick={() =>
                      dispatch(
                        setHeaderNavigation({
                          home: true,
                          pl: true,
                          sr: false,
                          cu: true,
                        })
                      )
                    }
                  >
                    Our Partners
                  </li>
                </Link>
                <Link
                  className="link-li"
                  to={`/policies/${window.location.search}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li
                    onClick={() =>
                      dispatch(
                        setHeaderNavigation({
                          home: true,
                          pl: true,
                          sr: false,
                          cu: true,
                        })
                      )
                    }
                    style={{ whiteSpace: "nowrap" }}
                  >
                    MoneyWide Policies
                  </li>
                </Link>
                <Link
                  className="link-li"
                  to={`/faqs/${window.location.search}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li
                    onClick={() =>
                      dispatch(
                        setHeaderNavigation({
                          home: true,
                          pl: true,
                          sr: true,
                          cu: true,
                        })
                      )
                    }
                    style={{ whiteSpace: "nowrap" }}
                  >
                    FAQs
                  </li>
                </Link>
                <li>
                  <Link
                    className="link-li"
                    to={`/customer-reviews/${window.location.search}`}
                  >

                    Our Happy Customers
                  </Link>
                </li>
                <li onClick={() => dispatch(updateMakePayment({ ...makePayment, show: true }))}
                  style={{ whiteSpace: "nowrap", color: "white", cursor: "pointer" }}
                >
                  Make Payment
                </li>
              </ul>
            </div>
            <div className="footer-box">
              <span>Products</span>
              <ul>
                <Link
                  className="link-li"
                  to={`/personal-loan/${window.location.search}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li
                    onClick={() =>
                      dispatch(
                        setHeaderNavigation({
                          home: true,
                          pl: false,
                          sr: true,
                          cu: true,
                        })
                      )
                    }
                  >
                    Personal Loan
                  </li>
                </Link>
                <li>
                  <Link
                    className="link-li"
                    to={`/instant-personal-loan/${window.location.search}`}
                  >
                    Instant Personal Loan
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-li"
                    to={`/new-credit-personal-loan-mw-first/${window.location.search}`}
                  >
                    New Credit Personal Loan
                  </Link>
                </li>
                <li>
                  <Link className="link-li" to={`/short-term-personal-loan/${window.location.search}`}>
                    Short Term Personal Loan
                  </Link>
                </li>
                <li>
                  <Link className="link-li" to={`/pre-approved-personal-loan/${window.location.search}`}>
                    Pre Approved Personal Loan
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-li"
                    to={`/mw-personal-loan-repayment-options/${window.location.search}`}
                  >
                    Personal Loan Repayment Options
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-li"
                    to={`/travel-loan/${window.location.search}`}
                  >
                    Travel Loan
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-li"
                    to={`/personal-loan-for-home-renovation/${window.location.search}`}
                  >
                    Personal Loan For Home Renovation
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-li"
                    to={`/types-of-personal-loan/${window.location.search}`}
                  >
                    Types Of Personal Loan
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-li"
                    to={`/personal-loan-interest-rate-fees-and-charges/${window.location.search}`}
                  >
                    Personal Loan Interest Rate Fees And Charges
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-li"
                    to={`/instant-personal-loan-without-documents/${window.location.search}`}
                  >
                    Personal Loan Without Documents
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-li"
                    to={`/personal-loan-for-marriage/${window.location.search}`}
                  >
                    Personal loan for Marriage
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-li"
                    to={`/personal-loan-on-aadhar-card/${window.location.search}`}
                  >
                    Personal loan on Aadhar card
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-box">
              <span>Calculator</span>
              <ul>
                <Link
                  className="link-li"
                  to={`/personal-loan-emi-calculator/${window.location.search}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li
                    onClick={() =>
                      dispatch(
                        setHeaderNavigation({
                          home: true,
                          pl: true,
                          sr: true,
                          cu: true,
                        })
                      )
                    }
                  >
                    Personal Loan EMI Calculator
                  </li>
                </Link>
                <Link
                  className="link-li"
                  to={`/personal-loan-eligibility-calculator/${window.location.search}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li
                    onClick={() =>
                      dispatch(
                        setHeaderNavigation({
                          home: true,
                          pl: true,
                          sr: true,
                          cu: true,
                        })
                      )
                    }
                  >
                    Personal Loan Eligibility Calculator
                  </li>
                </Link>
                <Link
                  className="link-li"
                  to={`/personal-loan-foreclosure-calculator/${window.location.search}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li
                    onClick={() =>
                      dispatch(
                        setHeaderNavigation({
                          home: true,
                          pl: true,
                          sr: true,
                          cu: true,
                        })
                      )
                    }
                  >
                    Personal Loan Foreclosure Calculator
                  </li>
                </Link>
                <Link
                  className="link-li"
                  to={`/personal-loan-partpayment-calculator/${window.location.search}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <li>
                    Personal Loan Partpayment Calculator
                  </li>
                </Link>
              </ul>
            </div>
            <div className="footer-box">
              <span>More Links</span>
              <ul>
                <li>
                  <Link
                    className="link-li"
                    to={`/privacy-policy/${window.location.search}`}
                  >
                    Privacy Policy
                  </Link>
                </li>
                {/* <li>
                  <Link className="link-li" to={"/sample-loan-agreement"}>
                    Loan Agreement
                  </Link>
                </li>
                <li>
                  <Link className="link-li" to={"/loan-policy"}>
                    Loan Policy
                  </Link>
                </li>
                <li>
                  <Link className="link-li" to={"/fair-practices-code"}>
                    Fair Practices Code
                  </Link>
                </li>
                <li>
                  <Link className="link-li" to={"/grievance-redressal-policy"}>
                    Grievance Redressal Policy
                  </Link>
                </li> */}
                <li>
                  <Link
                    className="link-li"
                    to={`/terms-and-conditions/${window.location.search}`}
                  >
                    Terms & Conditions
                  </Link>
                </li>

                {/* <li>
                  <Link className="link-li" to={"/interest-rate-policy"}>
                    Interest Rate Policy
                  </Link>
                </li>
                <li>
                  <Link className="link-li" to={"/kyc-and-aml-policy"}>
                    KYC and AML Policy
                  </Link>
                </li> */}
                <li>
                  <Link
                    className="link-li"
                    to={`/cancellation-and-refund-policy/${window.location.search}`}
                  >
                    Cancellation Policy
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-li"
                    to={`/privacy-policy/app/${window.location.search}`}
                  >
                    Privacy Policy App
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-li"
                    to={`/corporate-disclosures/${window.location.search}`}>
                    Corporate Disclosures
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-li"
                    to={`/sitemap.html`}
                  >
                    Sitemap
                  </Link>
                </li>
                {/* <li>
                  <Link className="link-li" to={"/mlc_finotech"}>
                    Investor Relations
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="copy-right">
          <div className="copyright-container">
            <div className="copyright-row">
              <div className="col-1">
                <p className="footer-p">
                  Copyright © 2022. All Rights Reserved. MoneyWide is a registered trademark, CIN- U67100HR2022PTC107092
                </p>
              </div>
              <div className="col-2">
                <img src={IndianFlag} alt="Image not Found" />
                <span>
                  Built with Love <br />
                  Made in India
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
