import React from "react";
import "./congratulationpop.css";
import Money from "../../Icons/Money.svg";
import greentick from "../../Icons/greentick.svg";
import "../Loader/Loader.css";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Base64 from "base-64";
import {
  setPreApprovedOfferDetails,
  setCurrentStep,
  setPreapprovedOfferPopup,
  setSorryPopup,
  updateCkycDetails,
  updateCredentialsState,
  updateFormState,
  updateLoginDetails,
  updateStepBar,
  updatePopUp,
  updateMwApplicationId,
  updateDecisionDemog,
  GenerateToken,
  SetTempId,
  updatePartnerState,
  updatePartnerData,
} from "../../Redux/action";
import {
  base_url,
  client_id,
  client_key,
  source,
  prodbase_url,
  verification_source,
  verified_by,
  verifier_name,
  react_base_url,
} from "../Secret_Credentials/Secret_Credential";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getToken, offerSubmissionApi, offerSubmissionApi2, WebOffer } from "../EverCalledFunctions/EverCalledApis";
import MainLoader from "../MainLoader/MainLoader";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import queryString from "query-string";
import { RelocateUTMCheck, decryptLeadIdStepId, encryptLeadIdStepId, encryptOnlyLeadId, gettingerror } from "../EverCalledFunctions/EverCalledFunctions";
import ErrorCase from "../Journey Form/Form/All_Forms/ErrorCase";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
import { apiInstance } from "../Config/APIConfig";
import AlertIcon from '../../Icons/alert_icon_blue.svg';

function CongratulationPopup({ condition }) {
  const navigate = useNavigate();
  const {
    step_bar,
    loginDetails,
    ckycDetails,
    formStates,
    pre_approved_offer,
    credentialState,
    PopUpState,
    mw_application_id,
    preApprovedOfferDetails,
    choosen_amount,
    decisionDemog,
    partnerStates,
    partnerData,
    token
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [state, setState] = React.useState(true);
  const [manualOffer, setManualOffer] = React.useState(false);
  const [emi, setEmi] = React.useState("");
  const [timer, setTimer] = React.useState();
  const [rocket, setRocket] = React.useState(false);
  const [step, setStep] = React.useState("");
  // const [eligibility, setEligibility] = React.useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [manualFinalOffer, setManualFinalOffer] = React.useState("");
  const elg = searchParams.get("eligibility");
  const [loanName, setLoanName] = React.useState("");

  const qry = queryString.parse(window.location.search);

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }


  const ste = async () => {
    const details = await decryptLeadIdStepId();
    setStep(details[1]);
  };

  React.useEffect(() => {
    if (elg) {
      // setEligibility(2);
      dispatch(updateCredentialsState({ ...credentialState, eligibility: 2 }))
    }
    if (token == "") {
      myToken();
    }
    if (qry.fid) {
      ste();
    }
    else {
      setTimer(30000);
    }
  }, []);

  React.useEffect(() => {
    if (step) {
      if (step.toString() === "3") {
        setTimer(5000);
      } else {
        setTimer(30000);
      }
    }
  }, [step]);

  const EligibilityBSA = async (eligibilityData) => {
    let decisionDec = await apiInstance.post(`decision-epfo-api`, {
      "customer_id": credentialState.cust_id,
      "product_id": 56,
      "username": eligibilityData.customer_full_name ? eligibilityData.customer_full_name : "",
      "mw_application_id": mw_application_id,
      "mobile_no": eligibilityData.custPhoneNo ? eligibilityData.custPhoneNo : "",
      "company_name": eligibilityData.company_name ? eligibilityData.company_name : "",
      "pancard": eligibilityData.pancard ? eligibilityData.pancard : "",
      "source": source,
      "verification_source": verification_source,
      "verified_by": verified_by,
      "verifier_name": verifier_name
    }, {
      "token": token
    })
    // .then((res) => {
    gettingerror('epfo hit web', mw_application_id)
    if ((decisionDec.status == 1) && (decisionDec.status_code == 200)) {
      let url = eligibilityData.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test"
      let offerDec = await apiInstance.post(
        `${url}`,
        {
          "source": source,
          "mw_application_id": mw_application_id,
          "product_id": 56,
          "customer_id": credentialState.cust_id,
          "offer_type": 1,
          "level_id": 1,
          "verified_by": verified_by,
          "verifier_name": verifier_name,
          "verification_source": verification_source,
          "stage_name": "Employment Check"
        },
        {
          "token": token,
          "log": 1
        }
      )
      // .then((res) => {
      // if ((res.data.status == 1) && (res.data.status_code == 200)) {
      if (offerDec.status_code === "200") {

      }
      // }


      // });
    }
    // else {
    //   setState(false);
    //   setRocket(true);
    // }
    // })
  }

  const OfferEligibilityTest = async (flag, id) => {

    const payLoad = {
      "source": source,
      "mw_application_id": mw_application_id,
      "product_id": 56,
      "customer_id": credentialState.cust_id,
      "offer_type": 1,
      "level_id": 1,
      "verified_by": verified_by,
      "verifier_name": verifier_name,
      "verification_source": verification_source,
      "is_eligibility_check": credentialState.eligibility ? credentialState.eligibility : 6
    }
    let url = flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";

    let decOfferTest = await apiInstance.post(`${url}`, payLoad, {
      "token": token,
      "log": 1
    })
    // .then((res) => {
    if ((decOfferTest.status == "1") && (decOfferTest.status_code == "200")) {
      gettingerror('offer hit web', mw_application_id)
      if (decOfferTest?.data['bsa_capture'] == 1) {
        let qryStr = encryptOnlyLeadId(mw_application_id);
        RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
      }
      else if (decOfferTest?.data?.final_data.length) {
        dispatch(
          setPreApprovedOfferDetails({
            ...preApprovedOfferDetails,
            loan_name: decOfferTest.data.final_data[0].program_name,
            final_data: decOfferTest.data.final_data,
          })
        );
        setLoanName(decOfferTest.data.loan_name);
        if (elg == null) {
          EligibilityBSA(id)
        }
        // if(credentialState.eligibility == 2){
        //   if(id.fetch_epfo_flag == 1){
        //     EligibilityBSA(id)
        //   }
        // }
        // else{
        //   EligibilityBSA(id)
        // }
      }
      // else if ((decOfferTest.data["cust_eligible"] == 0) && (decOfferTest.data["template_Id"] != "")) {
      //   let qry = Base64.encode(`${mw_application_id}`);
      //   qry = Base64.encode(qry);
      //   RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=${decOfferTest.data.template_Id}`);

      // }
      else if (decOfferTest.data["cust_eligible"] == 0) {
        let qry = Base64.encode(`${mw_application_id}`);
        qry = Base64.encode(qry);
        RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=5`);
      }
      else {
        dispatch(setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: false, }))
        dispatch(updateFormState({ ...formStates, StartJourney: true, LoanDetails: false, HunterHold: true }))
        setState(false);
      }
    }
    else {
      gettingerror('offer eligibility api error web', mw_application_id)
      setRocket(true);
    }
    // }).catch((err) => {
    //   setRocket(true);
    // })
  }

  // const OfferEligibilityAtEmployement = () => {
  // axios.post(
  //   `${prodbase_url}offer-eligibility-test`,
  //   {
  //     "source": source,
  //     "mw_application_id": mw_application_id,
  //     "product_id": 56,
  //     "customer_id": credentialState.cust_id,
  //     "offer_type": 1,
  //     "level_id": 1,
  //     "verified_by": verified_by,
  //     "verifier_name": verifier_name,
  //     "verification_source": verification_source,
  //     "stage_name": "Employment Check"
  //   },
  //   {
  //     headers: {
  //       "token": token,
  //     },
  //   }
  // )
  //   .then((res) => {
  //     // console.log(res);
  //     gettingerror('employment check web',mw_application_id)
  //     OfferEligibilityTest()
  //   });
  // }

  const Offer = async (obj, flag, id) => {
    const qry = queryString.parse(window.location.search);
    if (
      (decisionDemog.pan_status === "OK") && (decisionDemog.is_junk1 !== 1) && (decisionDemog.is_junk2 !== 1)
    ) {
      if (((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))) {
        if ((credentialState.eligibility) && (credentialState.eligibility == 2)) {
          OfferEligibilityTest(flag, id);
        }
        else {
          // OfferEligibilityAtEmployement();
          OfferEligibilityTest(flag, id);
        }

      }
      else {
        const OfferApi = await WebOffer(obj, token);
        if ((OfferApi.status === "1") && (OfferApi.status_code === "200")) {
          if (OfferApi.data.final_data.length !== 0) {
            if ((OfferApi?.data?.final_data[0]?.program_name) && (OfferApi?.data?.final_data) && (OfferApi?.data?.final_data[0]?.program_id) && (OfferApi?.data?.final_data[0]?.max_loan_amt) && (OfferApi?.data?.final_data[0]?.program_features)) {
              dispatch(
                setPreApprovedOfferDetails({
                  ...preApprovedOfferDetails,
                  loan_name: OfferApi.data.final_data[0].program_name,
                  final_data: OfferApi.data.final_data,
                })
              );
              setLoanName(OfferApi.data.loan_name);
            }
            else {
              setRocket(true);
            }

          }
          else {
            setState(false);
            let qry = Base64.encode(`${mw_application_id}`);
            qry = Base64.encode(qry);
            RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=16`);
          }

        }
        else {
          dispatch(setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: false, }))
          dispatch(updateFormState({ ...formStates, StartJourney: true, LoanDetails: false, HunterHold: true }))
          setState(false);

        }
      }

    }
    else if (qry.fid) {
      if (((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))) {
        if ((credentialState.eligibility) && (credentialState.eligibility == 2)) {
          OfferEligibilityTest(flag, id);
        }
        else {
          // OfferEligibilityAtEmployement();
          OfferEligibilityTest(flag, id);
        }
      }
      else {
        const OfferApi = await WebOffer(obj, token);
        if (OfferApi.message === "Success") {
          if (OfferApi.data.final_data.length !== 0) {
            if ((OfferApi?.data?.final_data[0]?.program_name) && (OfferApi?.data?.final_data) && (OfferApi?.data?.final_data[0]?.program_id) && (OfferApi?.data?.final_data[0]?.max_loan_amt) && (OfferApi?.data?.final_data[0]?.program_features)) {
              dispatch(
                setPreApprovedOfferDetails({
                  ...preApprovedOfferDetails,
                  loan_name: OfferApi.data.final_data[0].program_name,
                  final_data: OfferApi.data.final_data,
                })
              );
              setLoanName(OfferApi.data.loan_name);
            }
            else {
              setRocket(true);
            }

          }
          else {
            setState(false);
            let qry = Base64.encode(`${mw_application_id}`);
            qry = Base64.encode(qry);
            RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=16`);
          }
        }
        else {
          dispatch(setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: false, }))
          dispatch(updateFormState({ ...formStates, StartJourney: true, LoanDetails: false, HunterHold: true }))
          setState(false);

        }
      }

    }
    else {
      setState(false);
      let qry = Base64.encode(`${mw_application_id}`);
      qry = Base64.encode(qry);
      RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=16`);
    }
  };

  const fetchCustomer = async () => {
    let decFetch = await apiInstance
      .post(
        `fetch-customer-details`,
        {
          mw_application_id,
          step: 3,
          verifier_name,
          verification_source,
          verified_by,
          source,
          page_url: window.location.href
        },
        {
          token
        }
      )
    // .then((res) => {
    if ((decFetch.status == 1) && (decFetch.status_code == 200)) {
      dispatch(updateDecisionDemog({ ...decisionDemog, offer_journey_change_flag: decFetch.data.offer_journey_change_flag }));
      if ((decFetch?.data?.lead_source_flag) && (decFetch?.data?.lead_source_flag == 1)) {
        if (decFetch?.data["final_offer_generate_type"] == 2) {
          dispatch(updatePartnerData({ ...partnerData, finaloffer: decFetch.data.aipManual_offer }))
        }
        dispatch(updateFormState({ ...formStates, StartJourney: true, OfferNew: true }))
        dispatch(
          setPreApprovedOfferDetails({
            ...preApprovedOfferDetails,
            state: false,
          })
        );
      }
      else if (decFetch.data.aip_generate_type == 2) {
        if ((decFetch?.data?.aipManual_offer?.data?.final_data[0]?.program_name) && (decFetch?.data?.aipManual_offer?.data?.final_data) && (decFetch?.data?.aipManual_offer?.data?.final_data[0]?.program_id)) {
          setManualOffer(true);
          dispatch(
            setPreApprovedOfferDetails({
              ...preApprovedOfferDetails,
              loan_name: decFetch.data.aipManual_offer.data.final_data[0].program_name,
              final_data: decFetch.data.aipManual_offer.data.final_data,
            })
          );
        }
        else {
          setRocket(true);
        }

      }
      else if (decFetch.data.final_offer_generate_type == 2) {
        if ((decFetch?.data?.aipManual_offer?.data?.final_data[0]?.program_name) && (decFetch?.data?.aipManual_offer?.data?.final_data) && (decFetch?.data?.aipManual_offer?.data?.final_data[0]?.program_id)) {
          setManualFinalOffer(decFetch.data.final_offer_generate_type);
          dispatch(
            setPreApprovedOfferDetails({
              ...preApprovedOfferDetails,
              loan_name: decFetch.data.aipManual_offer.data.final_data[0].program_name,
              final_data: decFetch.data.aipManual_offer.data.final_data,
            })
          );
        }
        else {
          setRocket(true);
        }
      }
      else if (
        decFetch.data.is_ntc_check == 1 ||
        decFetch.data.is_ntc_check == 2
      ) {
        const offerpayload2 = {
          mw_application_id,
          customer_id: credentialState.cust_id,
          is_ntc_check: decFetch.data.is_ntc_check,
          product_id: 56,
          offer_type: 1,
          level_id: 1,
          is_eligibility_check: 1,
        };
        setTimeout(() => {
          Offer(offerpayload2, decFetch.data.offer_journey_change_flag, decFetch.data);
        }, timer)
      } else {
        const offerpayload1 = {
          mw_application_id,
          customer_id: credentialState.cust_id,
          product_id: 56,
          offer_type: 1,
          level_id: 1,
          is_eligibility_check: 1,
        };
        setTimeout(() => {
          Offer(offerpayload1, decFetch.data.offer_journey_change_flag, decFetch.data);
        }, timer)
      }
    }
    else {
      dispatch(updateFormState({ ...formStates, StartJourney: true, AipOffer: true, error: true }));
      dispatch(
        setPreApprovedOfferDetails({
          ...preApprovedOfferDetails,
          final_data: [],
          state: false,
        })
      )
    }

    // });
  }

  React.useEffect(() => {

    if ((token !== "") && (timer !== undefined) && (timer !== "")) {
      fetchCustomer();

    }

  }, [token, timer]);

  const calculateEmi = () => {
    let roi = (+(preApprovedOfferDetails.final_data[0].applicable_roi) / (12 * 100));
    let prog_id;
    if (preApprovedOfferDetails?.final_data[0]?.program_id) {
      prog_id = "prog_" + preApprovedOfferDetails.final_data[0].program_id.toString()
    }
    let tnr = (preApprovedOfferDetails.final_data[0].max_tenure)
    if (tnr.includes("Months")) {
      tnr = tnr.replace("Months", "").trim();
    }
    tnr = +tnr;
    // let tenure = +(preApprovedOfferDetails.final_data[0][prog_id][0].tenure);
    let Emi =
      (((Math.floor(+preApprovedOfferDetails.final_data[0].max_loan_amt / 1000)) * 1000) * roi * Math.pow(1 + roi, tnr)) /
      (Math.pow(1 + roi, tnr) - 1);
    Emi = Math.round(Emi);
    setEmi(Emi);
  }

  React.useEffect(() => {
    if (preApprovedOfferDetails.final_data.length !== 0) {
      setState(false);
      calculateEmi();
    }
  }, [preApprovedOfferDetails]);

  const handleClick = async () => {
    let prog_id = "";
    if (preApprovedOfferDetails?.final_data[0]?.program_id) {
      prog_id = "prog_" + preApprovedOfferDetails.final_data[0].program_id.toString()
    }
    let tenure = (preApprovedOfferDetails.final_data[0][prog_id][0].tenure).toString();
    if (tenure.includes("days")) {
      tenure = tenure.replace("days", "").trim();
      tenure = (+tenure / 30)
    }

    if (manualOffer === true) {
      const payLoad = {
        lead_id: mw_application_id,
        customer_id: credentialState.cust_id,
        selected_loan_amount: ((Math.floor(+preApprovedOfferDetails.final_data[0].max_loan_amt / 1000)) * 1000),
        // selected_tenure: +(preApprovedOfferDetails.final_data[0][prog_id][0].tenure),
        selected_tenure: +tenure,
        emi_calculated_applicable_roi: emi ? emi.toString() : ((Math.floor(+preApprovedOfferDetails.final_data[0].max_loan_amt / 1000)) * 1000).toString(),
        applicable_roi: preApprovedOfferDetails.final_data[0].applicable_roi,
        selected_program: preApprovedOfferDetails.final_data[0].program_id
      }

      const ApiData = await offerSubmissionApi(payLoad, token);

      if (ApiData.status_code === "200" && ApiData.status === "1") {
        gettingerror('manual offer submit web', mw_application_id)
        if (((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))) {
          if (ApiData?.data?.thankyou_flag == 1) {
            let qry = encryptOnlyLeadId(mw_application_id);
            window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=16`
          }
          else if (ApiData?.data?.banking_req == 1) {
            let qryStr = encryptOnlyLeadId(mw_application_id);
            // RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
            window.location.href = `${react_base_url}analyzer/?fid=${qryStr}`
          }
          else {
            let qry = encryptLeadIdStepId(mw_application_id, 4);
            RelocateUTMCheck(`${react_base_url}personal-loan/?fid=${qry}`);
          }
        }
        else {
          let qryStr = encryptOnlyLeadId(mw_application_id);
          window.sessionStorage.setItem("cust_id", credentialState.cust_id);
          RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
        }

      } else {
        dispatch(updateFormState({ ...formStates, error: true }));
      }
    }
    else if (manualFinalOffer == 2) {
      if (preApprovedOfferDetails.final_data[0].incomedoc_req == 6) {
        let qryStr = encryptOnlyLeadId(mw_application_id);
        RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
      }
      else {
        const payLoad = {
          lead_id: mw_application_id,
          customer_id: credentialState.cust_id,
          final_offer_generate_type: manualFinalOffer,
          selected_loan_amount: ((Math.floor(+preApprovedOfferDetails.final_data[0].max_loan_amt / 1000)) * 1000),
          // selected_tenure: +(preApprovedOfferDetails.final_data[0][prog_id][0].tenure),
          selected_tenure: +tenure,
          emi_calculated_applicable_roi: emi ? emi.toString() : ((Math.floor(+preApprovedOfferDetails.final_data[0].max_loan_amt / 1000)) * 1000).toString(),
          applicable_roi: preApprovedOfferDetails.final_data[0].applicable_roi,
          selected_program: preApprovedOfferDetails.final_data[0].program_id
        }

        const ApiData = await offerSubmissionApi2(payLoad, token);

        if (ApiData.status_code === "200" && ApiData.status === "1") {
          gettingerror('manual final offer web', mw_application_id)
          if (((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))) {
            if (ApiData?.data?.thankyou_flag == 1) {
              let qry = encryptOnlyLeadId(mw_application_id);
              window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=16`
            }
            else if (ApiData?.data?.banking_req == 1) {
              let qryStr = encryptOnlyLeadId(mw_application_id);
              // RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
              window.location.href = `${react_base_url}analyzer/?fid=${qryStr}`
            }
            else {
              let qry = encryptLeadIdStepId(mw_application_id, 4);
              RelocateUTMCheck(`${react_base_url}personal-loan/?fid=${qry}`);
            }
          }
          else {
            let qryStr = encryptOnlyLeadId(mw_application_id);
            window.sessionStorage.setItem("cust_id", credentialState.cust_id);
            RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
          }

        } else {
          dispatch(updateFormState({ ...formStates, error: true }));
        }
      }
    }
    else {
      dispatch(updateStepBar({ ...step_bar, profile: true, offer: true }));
      if (preApprovedOfferDetails.state === 1) {
        if (+preApprovedOfferDetails?.final_data[0]?.applicable_roi == 0) {
          if (preApprovedOfferDetails.final_data[0].incomedoc_req == 6) {
            let qryStr = encryptOnlyLeadId(mw_application_id);
            RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
          }
          else {
            dispatch(
              updateFormState({
                ...formStates,
                StartJourney: true,
                LoanDetails: false,
                offernot: true,
              })
            );
            dispatch(
              setPreApprovedOfferDetails({
                ...preApprovedOfferDetails,
                loan_name: loanName,
                state: false,
              })
            );
            dispatch(updatePartnerState({ ...partnerStates, PreApp: preApprovedOfferDetails?.final_data[0] }));
          }
        }
        else {
          if (preApprovedOfferDetails.final_data[0].incomedoc_req == 6) {
            let qryStr = encryptOnlyLeadId(mw_application_id);
            RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
          }
          else {
            dispatch(
              updateFormState({
                ...formStates,
                StartJourney: true,
                LoanDetails: false,
                AipOffer: true,
              })
            );
            dispatch(
              setPreApprovedOfferDetails({
                ...preApprovedOfferDetails,
                loan_name: loanName,
                state: false,
              })
            );
          }
        }


        var data = {
          source,
          mw_application_id,
          customer_id: credentialState.cust_id,
          level_id: 1,
          product_id: 0,
        };
      }
      else {
        if (+preApprovedOfferDetails?.final_data[0]?.applicable_roi == 0) {
          if (preApprovedOfferDetails.final_data[0].incomedoc_req == 6) {
            let qryStr = encryptOnlyLeadId(mw_application_id);
            RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
          }
          else {
            dispatch(
              updateFormState({
                ...formStates,
                StartJourney: true,
                LoanDetails: false,
                offernot: true,
              })
            );
            dispatch(
              setPreApprovedOfferDetails({
                ...preApprovedOfferDetails,
                loan_name: loanName,
                state: false,
              })
            );
            dispatch(updatePartnerState({ ...partnerStates, PreApp: preApprovedOfferDetails?.final_data[0] }));
          }

        }
        else {
          if (preApprovedOfferDetails.final_data[0].incomedoc_req == 6) {
            let qryStr = encryptOnlyLeadId(mw_application_id);
            RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
          }
          else {
            dispatch(
              updateFormState({
                ...formStates,
                StartJourney: true,
                LoanDetails: false,
                AipOffer: true,
              })
            );
            dispatch(
              setPreApprovedOfferDetails({
                ...preApprovedOfferDetails,
                loan_name: loanName,
                state: false,
              })
            );
          }

        }
      }

    }
  };

  const handleRocket = (e) => {
    fetchCustomer();
    setRocket(false)
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleRocket(e)} />}
      {state ? (
        <CKYCWaiting heading={"We are Generating an Offer For You."} time={30} />
      ) : (
        <div className="loaderbackground">
          <div className="loadertransparent">
            <div className="congratspopup login-area-popup">
              <div className="congratspopup-main">
                <h2>Congratulations!</h2>
                <img className="moneyearth" src={Money} alt="Money" />
                <div className="amount-box">
                  <p>
                    {(preApprovedOfferDetails?.final_data[0]?.incomedoc_req == 6) ? "You are eligible for " : "Your Personal Loan is approved for amount "}
                    {/* {!condition && (preApprovedOfferDetails?.final_data[0]?.incomedoc_req != 6) && <>upto</>} */}
                  </p>
                  <span>
                    <strong>₹ </strong>
                    {/* {!condition
                      ? (preApprovedOfferDetails?.final_data[0]?.max_loan_amt ? ((Math.floor(+preApprovedOfferDetails.final_data[0].max_loan_amt / 1000)) * 1000).toLocaleString("en-IN") : "Not Found")
                      : choosen_amount && choosen_amount.amount && choosen_amount.amount.toLocaleString("en-IN")} */}
                    {
                      (preApprovedOfferDetails?.final_data[0]?.max_loan_amt ? ((Math.floor(+preApprovedOfferDetails.final_data[0].max_loan_amt / 1000)) * 1000).toLocaleString("en-IN") : "")
                    }
                  </span>
                  {/* {(
                    <p>
                      {(preApprovedOfferDetails && preApprovedOfferDetails.loan_name)
                        ? preApprovedOfferDetails.loan_name
                        : ""}
                    </p>
                  )} */}
                </div>
                {condition && (+preApprovedOfferDetails.final_data[0].applicable_roi == 0) ? (<div className="tenour-case">
                  <div className="tenour-Box">
                    <div className="tenour-head">Tenure</div>
                    <div className="tenour-content">
                      {preApprovedOfferDetails.final_data[0].max_tenure}
                    </div>
                  </div>
                  {/* <div className="tenour-Box">
                      <div className="tenour-head">ROI(P.M.)</div>
                      <div className="tenour-content">
                        {(+preApprovedOfferDetails.final_data[0].applicable_roi / 12).toFixed(2)} %
                      </div>
                    </div> */}
                  {/* <div className="tenour-Box">
                      <div className="tenour-head">EMI</div>
                      <div className="tenour-content">
                        <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                        <b>₹</b> {emi ? emi.toLocaleString() : ""}
                      </div>
                    </div> */}
                </div>) : (
                  (preApprovedOfferDetails?.final_data[0]?.incomedoc_req == 6) ? (<div className="tenour-case-new">
                    <div className="tenour-Box">
                      <div className="tenour-head">Tenure</div>
                      <div className="tenour-content">
                        {preApprovedOfferDetails.final_data[0].max_tenure}
                      </div>
                    </div>
                    <div className="tenour-Box">
                      <div className="tenour-head">EMI</div>
                      <div className="tenour-content">
                        <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                        <b>₹</b> {emi ? emi.toLocaleString() : ""}
                      </div>
                    </div>
                  </div>) :
                    (<div className="tenour-case">
                      <div className="tenour-Box">
                        <div className="tenour-head">Tenure</div>
                        <div className="tenour-content">
                          {preApprovedOfferDetails.final_data[0].max_tenure}
                        </div>
                      </div>
                      <div className="tenour-Box">
                        <div className="tenour-head">ROI(P.M.)</div>
                        <div className="tenour-content">
                          {(+preApprovedOfferDetails.final_data[0].applicable_roi / 12).toFixed(2)} %
                        </div>
                      </div>
                      <div className="tenour-Box">
                        <div className="tenour-head">EMI</div>
                        <div className="tenour-content">
                          <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                          <b>₹</b> {emi ? emi.toLocaleString() : ""}
                        </div>
                      </div>
                    </div>)
                )}
                {preApprovedOfferDetails?.final_data[0]?.program_features.length ? <div className="require-doc-box">
                  <div className="cont-box">
                    <img src={greentick} alt="green-tick" />
                    <p>
                      {
                        preApprovedOfferDetails.final_data[0].program_features[0].feature
                      }
                    </p>
                  </div>
                  <div className="cont-box">
                    <img src={greentick} alt="green-tick" />
                    <p>
                      {
                        preApprovedOfferDetails.final_data[0].program_features[1].feature
                      }
                    </p>
                  </div>
                  <div className="cont-box">
                    <img src={greentick} alt="green-tick" />
                    <p>
                      {
                        preApprovedOfferDetails.final_data[0].program_features[2].feature
                      }
                    </p>
                  </div>
                </div> : ((preApprovedOfferDetails?.final_data[0]?.incomedoc_req == 6) ? <div className="subject-online">
                  <img src={AlertIcon} alt="Alert Icon" />
                  <p>subject to online Income verification</p>
                </div> : <div></div>)}
                <div className="congrats-offer-button">
                  <button onClick={() => handleClick()}>{(preApprovedOfferDetails?.final_data[0]?.incomedoc_req == 6) ? "Proceed to Verify" : "Avail Now"}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CongratulationPopup;