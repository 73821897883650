// Base URL

export const base_url = process.env.REACT_APP_BASE_URL;

export const prodbase_url = process.env.REACT_APP_PROD_URL;

export const aa_prodbase_url = process.env.REACT_APP_PROD_URL_AA;

export const doc_upload_url = process.env.REACT_APP_DOC_UPLOAD_URL;

export const prodbase_blog_url = process.env.REACT_APP_BLOG_BASE_URL;


// All Credentials
export const mixid = process.env.REACT_APP_PANEL;
export const client_id = process.env.REACT_APP_CLIENT_ID;
export const client_key = process.env.REACT_APP_CLIENT_KEY;
export const source = process.env.REACT_APP_SOURCE;
export const verified_by = +(process.env.REACT_APP_VERIFIED_BY);
export const verification_source = +(process.env.REACT_APP_VERIFICATION_SOURCE);
export const verifier_name = process.env.REACT_APP_VERIFIER_NAME;
export const react_base_url = process.env.REACT_APP_UAT ;
export const AccessKeyId = process.env.REACT_APP_ACCESS_KEY_ID;
export const SecretAcceessKey = process.env.REACT_APP_SECRET_ACCESS_KEY;
// Environment

export const environment = process.env.REACT_APP_ENV;
