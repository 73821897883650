import React, { useEffect, useState } from 'react';
import '../Form.css';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepBar, updateFormState, GenerateToken, updateSuggestion_DropDown } from '../../../../Redux/action';
import bank from './../../../../Icons/bank.svg';
import FormHead from '../FormHead';
import { source, verification_source, verified_by, verifier_name, react_base_url } from '../../../Secret_Credentials/Secret_Credential';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../../../assests/lootie-files/continue.json';
import { encryptAGRID, encryptLeadIdStepId, encryptOnlyLeadId, gettingerror, RelocateUTMCheck } from '../../../EverCalledFunctions/EverCalledFunctions';
import { getToken, getMasterData } from '../../../EverCalledFunctions/EverCalledApis';
import NewErrorCase from './NewErrorCase';
import { FetchCustomerPayload } from '../../../EverCalledFunctions/EverCalledPayload';
import CKYCWaiting from '../../../LoginPopUp/CKYCWaiting';
import { apiInstance } from '../../../Config/APIConfig';


const BankDetails = () => {
  const [resp, setResp] = useState(false)
  const [show, setShow] = useState(false);
  const [submit, setSubmit] = useState(false)
  const [name, setName] = useState("");
  const [currentSaving, setCurrentSaving] = useState(0)
  const [bankName, setBankName] = useState({ show: false, data: [] })
  const [selected, setSelected] = useState(-1);
  const [masterData, setMasterData] = useState([])
  const [qryStr, setQryStr] = useState("");
  const [rocket, setRocket] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const { step_bar, mw_application_id, credentialState, formStates, token, suggestionDropDown } = useSelector(state => state)
  const { register, handleSubmit, formState: { errors }, watch, setValue, setError, clearErrors } = useForm({
    defaultValues: {
      ifsc_code: "",
      account_number: "",
      bank_name: "",
      account_holder_name: "",
      account_type: ""
    },
    mode: "all"
  })

  const data = watch();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

  useEffect(() => {

    dispatch(updateStepBar({ ...step_bar, profile: true, offer: true, verification: true, disbursal: true }))

    if (token == "") {
      myToken();
    }

    const qry = encryptOnlyLeadId(mw_application_id);
    setQryStr(qry);
  }, [])

  const openFormOptions = async () => {
    if (masterData.length === 0) {
      const APIDATA = await getMasterData("bank_name", token)
      setMasterData(APIDATA)
    }
  }

  const hitFetchCustomer = async () => {
    let decFetch = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 22), {
      token
    })
    // .then((res) => {
      if (decFetch.status_code === "200" && decFetch.status === "1") {
        let resData = decFetch.data;
        if (decFetch?.data?.email_verified) {
          if (decFetch.data.email_verified.toString() === "1") {
            setEmailVerified(true);
          }
        }
        if ((!resData.account_no.toLowerCase().includes("xxx")) && (resData.bank_name !== "") && (resData.ifsc_code !== "") && (resData.accountHolderName !== "") && ((resData.accountType.toLowerCase() === "current") || (resData.accountType.toLowerCase() === "saving") || resData.accountType.toLowerCase() === "savings")) {

          if (decFetch.data.email_verified.toString() === "1") {
            let qry = encryptAGRID(mw_application_id);
            RelocateUTMCheck(`${react_base_url}loan-agreement/?agrid=${qry}`)
          } else {
            const qry = encryptLeadIdStepId(mw_application_id, 23);
            RelocateUTMCheck(`${react_base_url}personal-loan/?fid=${qry}`)
          }

        }
        else {
          setLoader(false);
        }
        if (decFetch.data.account_no && decFetch.data.account_no !== "") {
          if (!(decFetch.data.account_no.includes('XX') || decFetch.data.account_no.includes('xx'))) {
            setValue("account_number", decFetch.data.account_no);
          }
        }
        if (decFetch.data.bank_name && decFetch.data.bank_name !== "") {
          setValue("bank_name", decFetch.data.bank_name);
          setShow(true)
        }
        if (decFetch.data.ifsc_code && decFetch.data.ifsc_code !== "") {
          setValue("ifsc_code", decFetch.data.ifsc_code);
        }
        if (decFetch.data.accountHolderName && decFetch.data.accountHolderName !== "") {
          setName(decFetch.data.accountHolderName);
          setValue('account_holder_name', decFetch.data.accountHolderName);
        }
        if (decFetch.data.accountType) {
          var type = (decFetch.data.accountType.toLowerCase() === "current") ? 1 : (((decFetch.data.accountType.toLowerCase() === "saving") || (decFetch.data.accountType.toLowerCase() === "savings")) ? 2 : "")
          setCurrentSaving(type)
        }
        openFormOptions()
      }
      else if (decFetch.status_code === "429" && decFetch.status === "0") {
        openFormOptions()
      }
      else {
        dispatch(updateFormState({ ...formStates, error: true }))
      }
    // }).catch((err) => {
    //   setRocket(true);
    // })
  }

  useEffect(() => {
    if (token !== "") {
      hitFetchCustomer();

      // })
    }

    



  }, [token])



  {/* -------------------------------Submit Function---------------------------------------- */ }
  const onSubmit = async (result) => {
    setResp(true)
    let decBank = await apiInstance.post(`web/bank-details-submit`, {
      "source": source,
      "bank_name": result.bank_name,
      "account_number": result.account_number.toUpperCase(),
      "beneficiary_name": result.account_holder_name,
      "ifsc_code": result.ifsc_code,
      "mw_application_id": mw_application_id,
      "verification_source": verification_source,
      "verifier_name": verifier_name,
      "verified_by": verified_by,
      "account_type": currentSaving === 1 ? "current" : currentSaving ? "saving" : ""
    }, {
      token:""
    })
      // .then((res) => {
        if (decBank.status_code === "200" && decBank.status === "1") {
          gettingerror('bank details submit web', mw_application_id)
          if (emailVerified) {
            let qry = encryptAGRID(mw_application_id);
            RelocateUTMCheck(`${react_base_url}loan-agreement/?agrid=${qry}`)
          } else {
            const qry = encryptLeadIdStepId(mw_application_id, 23);
            RelocateUTMCheck(`${react_base_url}personal-loan/?fid=${qry}`)
          }
        }
         else {
          dispatch(updateFormState({ ...formStates, error: true }))
        }
      // }).catch((err) => {
      //   setRocket(true);
      // })
  }


  const handleUpKeyNumber = (value, field) => {
    var regexp = /[^0-9a-zA-Z]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""))
    }
  }

  const SpaceManage = (field, value) => {
    if (value) {
      setValue(field, value.trimStart());
    }
  }
  const handleArrows = (e) => {
    if (bankName.show) {
      var key = e.keyCode;
      if (key != 40 && key != 38 && key != 13) {
        return;
      }
      if (key == 13) {
        e.stopPropagation()
        if (selected > -1 && selected < bankName.data.length) {
          setThisValue(bankName.data[selected])
          setSelected(-1)
        } else {
          setSelected(-1)
        }
      }
      else if (key == 38) {
        if (selected > 0) {
          setSelected(selected - 1)
        }
      }
      else if (key == 40) {
        if (selected < bankName.data.length - 1) {
          setSelected(selected + 1)
        }
      }
    }
  }


  const setThisValue = (data) => {
    setValue('bank_name', data.value)
    clearErrors('bank_name')
    setBankName({ ...bankName, show: false })
    dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: false }))
  }



  const AlphaNum = (value, field) => {
    var newVal = "";
    if (value) {
      newVal = value.replace(/[^a-z0-9]/gi, "");
      setValue(field, newVal);
    }
    SpaceManage(field, newVal);
  }


  const handleAlphaOnly = (value, field) => {
    var regexp = /[^a-zA-Z ]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ''))
    } else {
      setValue(field, value.replace(/^\s+/, ''));
    }
  }

  const Blured = (field, value) => {
    // var newVal = value.trimEnd();
    var newVal = value.replace(/\s+$/, '');
    setValue(field, newVal);
    if (newVal.length < 3) {
      setError(field)
    } else {
      clearErrors([field])
    }
  }

  const handleAccountType = (e, value) => {
    e.preventDefault();
    setCurrentSaving(value)

  }

  useEffect(() => {
    if (data.account_number.length > 5 && data.account_holder_name && data.bank_name && data.ifsc_code && Object.keys(errors).length === 0 && currentSaving) {
      setSubmit(true)
    } else {
      setSubmit(false)
    }
  }, [data], [currentSaving])

  const handleError = (e) => {
    window.location.reload();
  }

  const handleBankName = (e) => {
    if (data.bank_name && e.keyCode !== 13) {
      if (masterData.length > 0) {
        var newData = masterData.filter((row) => {
          let bnk_name = row.value.toLowerCase();
          return bnk_name.includes(data.bank_name.toLowerCase());
        })
        if (newData.length !== 0) {
          if(newData.length == 1){
            if(newData[0].value.toLowerCase() == data.bank_name.toLowerCase()){
              setBankName({ ...bankName, data: [], show: false })
              dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: false }))
              return;
            }
          }
          setBankName({ ...bankName, data: newData, show: true })
          dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: true }))
        } else {
          setBankName({ ...bankName, data: newData, show: false })
        }
      }
    }
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      {loader && <CKYCWaiting heading={"We are loading your bank details"} />}
      <FormHead formName="Bank Details" formSubHead={"Add your Salary bank details"} />
      <div className="tag-for-input">
        {/* -------------------------------Form Fields---------------------------------------------------- */}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>



          {show ?
            <div className="without-input">
              <p>Enter account details of</p>
              <span>{data.bank_name}</span>
            </div> :
            <div className={errors.bank_name ? "input-tag error-border" : data.bank_name ? "input-tag add-border" : "input-tag "}>
              <img className="tag-icon" src={bank} alt=''/>
              <input disabled={resp} className="input-tag-box " type="text" placeholder={"Bank Name"}
                {...register('bank_name', { required: true })} autoComplete="off" onKeyDown={(e) => handleArrows(e)} onKeyUp={(e) => handleBankName(e)} />
              {suggestionDropDown.show && bankName.show && data.bank_name && bankName.data.length !== 0 && bankName.data.length && <div className="dropDownBox">
                {bankName.data.map((row, ind) => {
                  return <div className={'dropDownValue' + (selected === ind ? " select" : "")} key={ind} onClick={() => setThisValue(row)}>{row.value}</div>
                })}
              </div>}
            </div>
          }

          {/* --------------------IFSC Code input Field----------------------------- */}
          <div className={'input-tag remove-bottom' + (errors.account_holder_name ? " error-border" : data.account_holder_name ? " add-border" : "")}>
            <img className="tag-icon" src={bank} alt=''/>
            <input disabled={resp} className="input-tag-box " minLength={3} type="text" placeholder={"Account Holder Name"}
              {...register('account_holder_name', { required: true, minLength: 3 })} autoComplete="off" onKeyUp={() => handleAlphaOnly(data.account_holder_name, "account_holder_name")} onBlur={() => Blured("account_holder_name", data.account_holder_name)} />
          </div>
          {errors.account_holder_name && <div className='err-text'> Enter At least 3 Characters </div>}

          {/* --------------------Account Number input Field----------------------------- */}
          <div className={'input-tag remove-bottom' + (errors.account_number ? " error-border" : data.account_number ? " add-border" : "")}>
            <img className="tag-icon" src={bank} alt=''/>
            <input disabled={resp} className={"input-tag-box" + (data.account_number ? " capLetter" : "")} type="tel" placeholder={"Enter Account Number"} minLength={6} maxLength={20}
              {...register('account_number', { required: true, minLength: 6, maxLength: 20 })} autoComplete="off"
              onKeyUp={() => handleUpKeyNumber(data.account_number, "account_number")} />
          </div>
          {errors.account_number && <div className='err-text'> Invalid Account Number </div>}


          {/* --------------------IFSC Code input Field----------------------------- */}
          <div className={'input-tag remove-bottom' + (errors.ifsc_code ? " error-border" : data.ifsc_code ? " add-border" : "")}>
            <img className="tag-icon" src={bank} alt=''/>
            <input disabled={resp} className={"input-tag-box" + (data.ifsc_code && " capLetter")} minLength={11} maxLength={11} type="text" placeholder={"IFSC Code"}
              {...register('ifsc_code', {
                required: true, minLength: 11, maxLength: 11, pattern: {
                  value: /([a-zA-Z]){4}([0-9]){1}([0-9a-zA-Z]){6}$/
                }
              })} autoComplete="off" onKeyUp={() => AlphaNum(data.ifsc_code, "ifsc_code")} />
          </div>
          {errors.ifsc_code && <div className='err-text'> Invalid IFSC Code </div>}



          {/* --------------------Account Type input Field----------------------------- */}
          <div className="account-type-option">
            Select Account Type
          </div>
          <div className="options-confirm">
            <button className={"no-option-confirm " + (currentSaving === 2 ? " activated-option" : "")} onClick={(e) => handleAccountType(e, 2)}><input onClick={(e) => handleAccountType(e, 2)} readOnly checked={currentSaving === 2} type="radio" />Saving</button>
            <button className={"yes-option-confirm" + (currentSaving === 1 ? " activated-option" : "")} onClick={(e) => handleAccountType(e, 1)}><input onClick={(e) => handleAccountType(e, 1)} readOnly checked={currentSaving === 1} type="radio" />Current</button>
          </div>


          {/* -------------------------------------------Submit button----------------------------------------- */}
          <button type='submit' disabled={resp || !submit} className={'footer-btn Tmargin Bmargin' + (submit ? ' footer-btn-activate' : "")} >
            {!resp ?
              "Continue" :
              <div className="continue-lottie">
                <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
              </div>}
          </button>
        </form>
      </div>
    </>
  )
}


export default BankDetails