import React from 'react';
import { useEffect, useState } from 'react';
import '../Form.css';
import { useDispatch, useSelector } from 'react-redux'
import { GenerateToken, updateFormState } from '../../../../Redux/action';
import { react_base_url,source, verification_source, verified_by, verifier_name } from '../../../Secret_Credentials/Secret_Credential';
import CKYCWaiting from '../../../LoginPopUp/CKYCWaiting';
import { getToken, LoanAgreementFetch } from '../../../EverCalledFunctions/EverCalledApis';
import Base64 from "base-64";
import ctaAnimation from '../../../assests/lootie-files/continue.json';
import Lottie from 'react-lottie-player';
import axios from 'axios';
import { apiInstance } from '../../../Config/APIConfig';
import NewErrorCase from './NewErrorCase';

const LoanAgreementPartnerForm = () => {
  const [device_id, setDevice_id] = useState("");
  const [pdfCode, setPdfCode] = useState("");
  const [resp, setResp] = useState(false);
  const [ip, setIP] = useState('');
  const { token } = useSelector(state => state)
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(true)
  const [parnerId, setpartnerId] = useState("")
  const [ userId, setUserId] = useState("")
  const [rocket, setRocket] = useState(false);

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

//   useEffect(()=>{
//     window.addEventListener('popstate', function(event) {
//         window.location.reload();
//       });
//   })

  useEffect(() => {
    if (token == "") {
      myToken();
    }
    knowdevice();
    getData();
  }, [])

  useEffect(()=>{
    const urlString = window.location.href;
    const parsedUrl = new URL(urlString);
    const searchParams = parsedUrl.searchParams;
    const agridValue = searchParams.get('agrid');
    const dcrypt_partner_id=  Base64.decode(agridValue);
    const final_partner_id = Base64.decode(dcrypt_partner_id)
    setpartnerId(final_partner_id)
    if(final_partner_id && ip !== "" && device_id !== "" && token !== "" ){
        if (pdfCode.length === 0) {
            ApiCallPdf(final_partner_id);
          }     
    }
  },[ip, device_id, token])


  const moveAhead = () => {
    setResp(true)
    let qry = Base64.encode(`${parnerId}`);
    qry = Base64.encode(qry); 
    let userid =  Base64.encode(`${userId}`); 
    userid = Base64.encode(`${userid}`)    
    // window.location.href = `${react_base_url}e-sign-partner-form/?fid=${qry}`;
    window.location.href = `${react_base_url}e-sign-partner-form/?fid=${qry}&user_id=${userid}`;
    
  }

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://api.ipify.org?format=json')
    setIP(res.data.ip);
  }

  function knowdevice() {
    if (window.innerWidth > 1024) {
      setDevice_id("1");
    }
    else if (window.innerWidth > 480 && window.innerWidth < 1024) {
      setDevice_id("3")
    }
    else {
      setDevice_id("2");
    }
  }

  const ApiCallPdf = async (final_partner_id) => {
    const payload = {
      "devicetype": +(device_id),
      "ip_address": ip,
      "partner_id": final_partner_id,
      "source": source,
      "esign_flag": 0,
      "verification_source":verification_source,
      "verified_by":verified_by,
      "verifier_name":verifier_name
    }
    const APIDATA = await apiInstance.post('partner-agreement', payload, { token });
    if ((APIDATA.status == "1") && (APIDATA.status_code == "200")) {
      setUserId(APIDATA.data.mobile_no)
      if ((APIDATA.data.redirect_url) && (APIDATA.data.redirect_url !== "") ) {
        window.location.href = APIDATA.data.redirect_url
      } 
      else{
        if ((APIDATA.data.pdfLink) && (APIDATA.data.pdfLink !== "")) {
          setPdfCode(APIDATA.data.pdfLink)
          appendRestCode(APIDATA.data.pdfLink)
        }
      } 
    } 
    else {
        setRocket(true)
    }

  }

  const appendRestCode = (baseCode) => {
    var doc = document.getElementById("pdf-viewer-user");

    var div = document.createElement('div')
    div.innerHTML = decodeURIComponent(escape(window.atob(baseCode)));
    doc.append(div)
    setLoader(false)
  }

  const handleError = (e) => {
    window.location.reload();
  }


  return (
    <>
      {loader && <CKYCWaiting heading={"We are generating Agreement"} />}
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      <div className='journey-part'>
      <div className='loan-agreement-open'>

        <div>
          <div className='loan-agree-back' style={{marginTop:"80px"}}>
            <span >
             Loan Agreement
            </span>
          </div>
          <div className="entire-reader" id='pdf-viewer-user' ></div>
          <button onClick={moveAhead} className="footer-btn footer-btn-activate loan-btn">{!resp ?
            "Continue" :
            <div className="continue-lottie">
              <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
            </div>}</button>
        </div>
      </div>
      </div>
    </>
  )
}

export default LoanAgreementPartnerForm;
