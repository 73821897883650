import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./otp.css";
import { useSelector, useDispatch } from "react-redux";
import {
  updateCredentialsState, updateFormState, change_personal_loan_page, updateLoginDetails, setPreApprovedOfferDetails, updateMwApplicationId, GenerateToken
} from "../../../Redux/action";
import { react_base_url } from "../../Secret_Credentials/Secret_Credential";
import {
  getToken,
  ResumeJourneyApi,
  SendOTP,
  VerifyOTP,
} from "../../EverCalledFunctions/EverCalledApis";
import {
  encryptAGRID,
  encryptLeadIdStepId,
  encryptOnlyLeadId,
  encryptQueryIdOfferId,
  gettingerror,
  handleKeyPress,
} from "../../EverCalledFunctions/EverCalledFunctions";
import Lottie from "react-lottie-player";
import ctaAnimation from "../../assests/lootie-files/continue.json";
import WrongOtp from "../../NotFound/WrongOtp";
import NewErrorCase from "./All_Forms/NewErrorCase";
import { useSearchParams } from "react-router-dom";

const OTP = () => {
  const [timer, setTimer] = useState(30);
  const [show, setShow] = useState(true);
  const [fetchAPI, setFetchAPI] = useState(false);
  const {
    formStates,
    loginDetails,
    credentialState,
    preApprovedOfferDetails,
    personal_loan,
    token,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const s = searchParams.get("s");
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [resp, setResp] = useState(false);
  const [ip, setIP] = useState("");
  const [rocket, setRocket] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    },
  });
  const data = watch();

  const [device_id, setDevice_id] = useState();

  function knowdevice() {
    if (window.innerWidth > 1024) {
      setDevice_id("1");
    } else if (window.innerWidth > 480 && window.innerWidth < 1024) {
      setDevice_id("3");
    } else {
      setDevice_id("2");
    }
  }

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  useEffect(() => {
    if (token == "") {
      myToken();
    }
    knowdevice();
    console.log(s)
  }, []);

  useEffect(() => {
    if (data.otp4) {
      document.getElementById("forth-tag").blur();
    }
    if (fetchAPI) {
      if (data.otp1 && data.otp2 && data.otp3 && data.otp4) {
        SubmitIt();
        setFetchAPI(false);
      }
    }
    if (!data.otp1 || !data.otp2 || !data.otp3 || !data.otp4) {
      setFetchAPI(true);
    } else {
      setFetchAPI(false);
    }
  }, [data]);

  const SubmitIt = async () => {
    const otp = data.otp1 + data.otp2 + data.otp3 + data.otp4;
    const result = {
      otpId: credentialState.otpId,
      otp,
      mobile: loginDetails.mobile,
      source_from: 1,
      whatsapp_consent: loginDetails.whatsapp.toString(),
      device_type: +device_id,
      IP: ip,
      lead_id: 0,
      customer_id: 0,
      login_from: 1,
    };
    const APIDATA = await VerifyOTP(result, "");

    if (APIDATA.status_code === "200" && APIDATA.status === "1") {
      let cust_id = "";
      if (APIDATA.data.cust_id) {
        cust_id = APIDATA.data.cust_id;
        dispatch(updateCredentialsState({ ...credentialState, cust_id }));
      }
      gettingerror("verify otp web", loginDetails.mobile)
      const resumeJourney = await ResumeJourneyApi(cust_id, token, loginDetails.mobile);
      if (resumeJourney.status_code === "200" && resumeJourney.status === "1") {
        if (resumeJourney.data) {
          if (resumeJourney.data.mw_application_id && resumeJourney.data.mw_application_id !== "") {
            dispatch(updateMwApplicationId(resumeJourney.data.mw_application_id));
          }
          if (resumeJourney.data.display_dashboard_flag == 1) {
            if ((resumeJourney.data.dashboard_redirectUrl) && (resumeJourney.data.dashboard_redirectUrl !== "")) {
              let red_url = resumeJourney.data.dashboard_redirectUrl;
              if ((red_url.includes("?")) && (window.location.search)) {
                let s_qry = window.location.search;
                s_qry = s_qry.split("?");
                s_qry = s_qry[1];
                window.location.href = `${red_url + "&" + s_qry}`;
                return;
              }
              else {
                window.location.href = red_url;
                return;
              }
            } else {
              dispatch(updateLoginDetails({ ...loginDetails, preVal: resumeJourney.data, empData: resumeJourney.data, preData: resumeJourney }));
              dispatch(updateFormState({ ...formStates, StartJourney: true, YourProfile: true }))
              return;
            }
          } else if (resumeJourney.data.redirectUrl && resumeJourney.data.redirectUrl !== "") {
            window.location.href = `${resumeJourney.data.redirectUrl}`;
            return;
          } else {
            switch (resumeJourney.data.step.toString()) {
              case '1': {
                if (resumeJourney.data.mw_application_id && resumeJourney.data.mw_application_id !== "") {
                  let qry = encryptLeadIdStepId(resumeJourney.data.mw_application_id, 1);
                  window.location.href = `${react_base_url}personal-loan/?fid=${qry}`;
                } else {
                  dispatch(updateLoginDetails({ ...loginDetails, preVal: resumeJourney.data, empData: resumeJourney.data, preData: resumeJourney, }));
                  dispatch(updateFormState({ ...formStates, StartJourney: true, YourProfile: true, }));
                }
                break;
              }
              case '2': {
                dispatch(updateFormState({ ...formStates, StartJourney: true, EmployeeDetails: true, }));
                break;
              }
              case '3': {
                dispatch(setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: 1, }));
                break;
              }
              case '19': {
                dispatch(updateFormState({ ...formStates, StartJourney: true, LoanDetails: true, }));
                break;
              }
              case '4': {
                dispatch(updateFormState({ ...formStates, StartJourney: true, KycVerification: true, }));
                break;
              }
              case '5': {
                const lead_id = resumeJourney.data.mw_application_id;
                const qry = encryptOnlyLeadId(lead_id);
                window.location.href = `${react_base_url}analyzer/?fid=${qry}`;
                break;
              }
              case '7': {
                const lead_id = resumeJourney.data.mw_application_id;
                const qry = encryptQueryIdOfferId(lead_id, 1);
                window.location.href = `${react_base_url}final-offer/?fid=${qry}`;
                break;
              }
              case '20': {
                dispatch(updateFormState({ ...formStates, StartJourney: true, OfficeAddress: true, }));
                break;
              }
              case '24': {
                dispatch(updateFormState({ ...formStates, StartJourney: true, AdditionalDetails: true, }));
                break;
              }
              case '21': {
                dispatch(updateFormState({ ...formStates, StartJourney: true, ReferenceDetails: true, }));
              }
              case '22': {
                dispatch(updateFormState({ ...formStates, StartJourney: true, BankDetails: true, }));
                break;
              }
              case '15': {
                const lead_id = resumeJourney.data.mw_application_id;
                const qry = encryptOnlyLeadId(lead_id);
                window.location.href = `${react_base_url}emandate-setup/?fid=${qry}`;
                break;
              }
              case '23': {
                dispatch(updateFormState({ ...formStates, StartJourney: true, Email: true, }));
                break;
              }
              case '8': {
                const lead_id = resumeJourney.data.mw_application_id;
                let qry = encryptAGRID(lead_id);
                window.location.href = `${react_base_url}loan-agreement/?agrid=${qry}`;
                break;
              }
              case '16': {
                dispatch(updateFormState({ ...formStates, StartJourney: true, congo: true, }));
                break;
              }
              default: {
                if (resumeJourney.data.mw_application_id && resumeJourney.data.mw_application_id !== "") {
                  let qry = encryptLeadIdStepId(resumeJourney.data.mw_application_id, 1);
                  window.location.href = `${react_base_url}personal-loan/?fid=${qry}`;
                } else {
                  dispatch(updateLoginDetails({ ...loginDetails, preVal: resumeJourney.data, empData: resumeJourney.data, preData: resumeJourney, }));
                  dispatch(updateFormState({ ...formStates, StartJourney: true, YourProfile: true, }));
                }
                break;
              }
            }
          }
          var journeyStart = document.getElementsByClassName("journey-start")[0];
          var journeyStop = document.getElementsByClassName("journey-stop")[0];
          if (journeyStop && journeyStart) {
            journeyStop.style.display = "none";
            journeyStart.style.display = "block";
          }
        } else {
          dispatch(updateFormState({ ...formStates, StartJourney: true, YourProfile: true, }));
        }
      } else {
        setRocket(true);
      }
    } else {
      setFirstSubmit(true);
      setResp(false);
    }
  };

  const onSubmit = (data) => {
    setResp(true);
  };

  const handleChange = (nextLocation, prevLocation, event, value) => {
    setValue(value, event.target.value);
    if (event.target.value.length > 0) {
      document.getElementById(nextLocation).focus();
    }
  };

  const handleKeyDown = (event, prevLocation) => {
    const key = event.keyCode || event.charCode;
    if (key == 8 || key == 46) {
      if (event.target.value.length === 0) {
        document.getElementById(prevLocation).focus();
      }
    }
  };

  const handleClick = (e) => {
    if (firstSubmit) {
      setValue("otp1", "");
      setValue("otp2", "");
      setValue("otp3", "");
      setValue("otp4", "");
      setFirstSubmit(false);
      document.getElementById("first-tag").focus();
    }
  };

  const handleotp = async () => {
    gettingerror("resend otp web", loginDetails.mobile)
    setShow(false);
    const resendotp = {
      mobile: loginDetails.mobile,
      login_type: "OTP",
      source_from: 1,
      api_mode: "LIVE",
      login_from: 1,
      IP: ip,
      device_type: +device_id,
      customer_id: 0,
      lead_id: 0,
    };
    const APIDATA = await SendOTP(resendotp, "");
    dispatch(change_personal_loan_page({ ...personal_loan, otp: true }));
    dispatch(
      updateCredentialsState({
        ...credentialState,
        otpId: APIDATA.data.otp_id,
        count: APIDATA.data.count,
      })
    );

    setValue("otp1", "");
    setValue("otp2", "");
    setValue("otp3", "");
    setValue("otp4", "");
    setFirstSubmit(false);
    document.getElementById("first-tag").focus();
  };

  // const handleKeyPress = (event) => {
  //   if (!/[0-9]/.test(event.key)) {
  //     event.preventDefault();
  //   }
  // };

  const handleNavigation = () => {
    dispatch(
      change_personal_loan_page({
        ...personal_loan,
        otp: false,
        changemobile: false,
      })
    );
  };

  useEffect(() => {
    timer > 0 &&
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
  }, [timer]);

  const handleError = (e) => {
    window.location.reload();
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      {credentialState.otpId ? (
        <div className="login-right">
          <form
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
            autoCorrect="false"
          >
            <div className="otp-form-body">
              <div className="otp-form-top">
                <p className="otp-head">Enter OTP</p>
                <p className="otp-head-p">
                  Please type the verification code sent on <br />
                  +91-{loginDetails.mobile}
                  {personal_loan.changemobile && (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNavigation()}
                      className="sp-otp-content"
                    >
                      (Change)
                    </span>
                  )}
                </p>
              </div>

              <div className="enter-otp ">
                <div
                  className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
                >
                  <input
                    maxLength={1}
                    readOnly={!credentialState.otpId || resp}
                    id="first-tag"
                    type="tel"
                    {...register("otp1", { required: true })}
                    onKeyPress={(event) => handleKeyPress(event)}
                    onChange={(event) =>
                      handleChange("second-tag", "first-tag", event, "otp1")
                    }
                    onKeyDown={(event) => handleKeyDown(event, "first-tag")}
                    onClick={(e) => handleClick(e)}
                    autoFocus
                  />
                </div>
                <div
                  className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
                >
                  <input
                    maxLength={1}
                    readOnly={!credentialState.otpId || resp}
                    id="second-tag"
                    type="tel"
                    {...register("otp2", { required: true })}
                    onKeyPress={(event) => handleKeyPress(event)}
                    onChange={(event) =>
                      handleChange("third-tag", "first-tag", event, "otp2")
                    }
                    onKeyDown={(event) => handleKeyDown(event, "first-tag")}
                    onClick={(e) => handleClick(e)}
                  />
                </div>
                <div
                  className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
                >
                  <input
                    maxLength={1}
                    readOnly={!credentialState.otpId || resp}
                    id="third-tag"
                    type="tel"
                    {...register("otp3", { required: true })}
                    onKeyPress={(event) => handleKeyPress(event)}
                    onChange={(event) =>
                      handleChange("forth-tag", "second-tag", event, "otp3")
                    }
                    onKeyDown={(event) => handleKeyDown(event, "second-tag")}
                    onClick={(e) => handleClick(e)}
                  />
                </div>
                <div
                  className={"otp-boxes " + (firstSubmit ? "otp-invalid" : "")}
                >
                  <input
                    maxLength={1}
                    readOnly={!credentialState.otpId || resp}
                    id="forth-tag"
                    type="tel"
                    {...register("otp4", { required: true })}
                    onKeyPress={(event) => handleKeyPress(event)}
                    onChange={(event) =>
                      handleChange("forth-tag", "third-tag", event, "otp4")
                    }
                    onKeyDown={(event) => handleKeyDown(event, "third-tag")}
                    onClick={(e) => handleClick(e)}
                  />
                </div>
              </div>
              {credentialState.otpId ? (
                <>
                  {firstSubmit ? (
                    <div className="otp-timer-slot err">
                      <span>Invalid OTP </span>
                    </div>
                  ) : (
                    show &&
                    credentialState.count < 5 && (
                      <div className="otp-timer-slot">
                        {timer !== 0 && <span>RESEND OTP : </span>}
                        {timer !== 0 ? (
                          <span className="resend-option">{timer} Seconds</span>
                        ) : (
                          <span
                            className="resend-option"
                            id="pointer-cur"
                            onClick={handleotp}
                          >
                            RESEND OTP
                          </span>
                        )}
                      </div>
                    )
                  )}
                </>
              ) : (
                <div className="limit-over err-text">
                  You have exhausted daily OTP limit. <br />
                  Please try after 24 hours.
                </div>
              )}
              <div>
                <button
                  id="last"
                  disabled={resp || firstSubmit}
                  className={
                    "footer-btn remove-bottom" +
                    (data.otp1 &&
                      data.otp2 &&
                      data.otp3 &&
                      data.otp4 &&
                      !firstSubmit
                      ? " activate-btn"
                      : "")
                  }
                >
                  {!resp ? (
                    "Submit"
                  ) : (
                    <div className="continue-lottie">
                      <Lottie
                        loop
                        animationData={ctaAnimation}
                        play
                        className="ctn-loader"
                      />
                    </div>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="login-right">
          <WrongOtp />
        </div>
      )}
    </>
  );
};

export default OTP;
