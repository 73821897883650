import React, { useEffect } from "react";
import AboutMoney from "../Aboutmoneywidehomepage/AboutMoney";
import BlogSlider from "../BlogSlider/BlogSlider";
import MwAnnouncement from "../MoneyWideAnnounceMent/MwAnnouncement";
import NotFound from "../NotFound/NotFound";
import PartnerSlider from "../PartnerSlider/PartnerSlider";
import ReviewSlider from "../ReviewSlider/ReviewSlider";
import RightSidePopUp from "../Right Side PopUp/RightSidePopUp";
import Right from "../whatsrighthomepage/Right";
import First from "./First";
import GroupBrands from "./GroupBrands";
import FullPageSlider from "./FullPageSlider/FullPageSlider";
import MWMobileSlider from "./MWMobileSlider/MWMobileSlider";
import Slider from "./Slider";
import Step1 from "../assests/profile.webp";
import Step2 from "../assests/offer.webp";
import Step3 from "../assests/kycverification.webp";
import Step4 from "../assests/disbursal.webp";

const HomePage = () => {
  const [y, sety] = React.useState(true);
  const [show, setShow] = React.useState(false);
  window.addEventListener("scroll", (event) => {
    if (window.scrollY >= 5350) {
      sety(false);
    }
    else {
      sety(true);
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.innerWidth < 480) {
      setShow(true);
    }
  }, [window.innerWidth]);

  const Da = [
    {
      "step": "STEP-1",
      "heading": "Submit loan application with necessary documents.",
      "context": "Completing the loan application with accurate and up-to-date information and supporting documents can help expedite the loan approval process.",
      "img": Step1
    },
    {
      "step": "STEP-2",
      "heading": "Get instant in-principle approval based on the details.",
      "context": "Based on the details you can receive preliminary or provisional approval for a loan application within a very short amount of time, typically within minutes or even seconds.",
      "img": Step2
    },
    {
      "step": "STEP-3",
      "heading": "Complete video KYC and eSign process.",
      "context": "Complete Video KYC and eSign process to complete the entire onboarding process for Personal Loan remotely, with our digital process.",
      "img": Step3
    },
    {
      "step": "STEP-4",
      "heading": "Successful loan disbursement in your bank account.",
      "context": "Once you successfully set up e-mandate the funds will be disbursed into your bank account within the same day.",
      "img": Step4
    }
  ]

  return (
    <>
      <First />
      {/* <Right /> */}
      {y && <RightSidePopUp />}
      {/* <Slider /> */}
      {show && <MWMobileSlider />}
      {!show && <FullPageSlider givenData={Da} />}
      <MwAnnouncement />
      <PartnerSlider />
      <BlogSlider />
      <ReviewSlider />
      <AboutMoney />
      <GroupBrands />
    </>
  );
};
export default HomePage;
