import React from "react";
import Clipboard from "../../Icons/Clipboard.webp";
import Slider from "react-slick";
import axios from "axios";
import {
  prodbase_blog_url,
  react_base_url, verification_source, verified_by, verifier_name, source
} from "../Secret_Credentials/Secret_Credential";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken } from "../../Redux/action";
import { useForm } from "react-hook-form";
import familyPlus from '../../Icons/man.svg';
import FormHead from "../Journey Form/Form/FormHead";
import { publicKey, privateKey, encryptWithHybrid, decryptWithHybrid } from "../../Keys";

const BlogRightSide = ({ replace}) => {
  const [post, setPost] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [first, setFirst] = React.useState(false);
  const HeadUrl = "https://blog.moneywide.com/assets/images/blog/";
  const { token, blogData } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [submit, setSubmit] = React.useState(false);
  const [show,setShow] =  React.useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      Name: "",
      comment: "",
    },
    mode: "all",
  });

  const data = watch();


  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
    if((window.location.pathname !== '/blog') && (window.location.pathname !== '/blog/personal-loan') && (window.location.pathname !== '/blog/kyc') && (window.location.pathname !== '/blog/others') && (!window.location.href.includes('pageno'))){
      setShow(true);
    }
  }, []);

  const handleAlphaOnly = (value, field) => {
    var regexp = /[^a-zA-Z ]/g;
    if (value.match(regexp)) {
      setValue(field, value.replace(regexp, ""));
    } else {
      setValue(field, value.replace(/^\s+/, ''));
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const Blured = (field, value) => {
    // var newVal = value.trimEnd();
    var newVal = value.replace(/\s+$/, '');
    setValue(field, newVal);
    if (newVal.length < 3) {
      setError(field);
    } else {
      clearErrors([field]);
    }
  };

  const SpaceManage = (field, value) => {
    setValue(field, value.replace(/^\s+/, ''));
  };

  const recent = () => {

    let payload = {
      source: "MWide",
      blog_id: 0,
      category_id: 0,
      verification_source: 2,
      verifier_name: "system",
      verified_by: 2,
    }

    payload = encryptWithHybrid(JSON.stringify(payload));

    axios
      .post(
        `${prodbase_blog_url}recent-blog-articles`,
        // {
        //   source: "MWide",
        //   blog_id: 0,
        //   category_id: 0,
        //   verification_source: 2,
        //   verifier_name: "system",
        //   verified_by: 2,
        // }
        payload,
        {
          headers: {
            token,
          },
        }
      )
      .then((res) => {
        let dec_data = decryptWithHybrid(res.data.encryptedMessage, res.data.encryptedAesKey, res.data.iv)
        setPost(dec_data.data.recentpost);
        window.sessionStorage.setItem(
          "blog_recent_post",
          JSON.stringify(dec_data.data.recentpost)
        );
      });
  };

  React.useEffect(() => {
    if (token !== "") {
      setTimeout(() => {
        if (
          window.sessionStorage.getItem("blog_recent_post") &&
          window.sessionStorage.getItem("blog_recent_post") != "undefined" &&
          window.sessionStorage.getItem("blog_recent_post") != "null"
        ) {
          setPost(
            JSON.parse(window.sessionStorage.getItem("blog_recent_post"))
          );
        } else {
          recent();
        }
      }, 500);
    }
  }, [token]);

  const setthis = (pin) => {
    setCount(pin);
    setFirst(false);
  }

  const onSubmit = async () => {

    let payLoad = {
      source,
      "blog_id": blogData[2].blog_id,
      "rating": count,
      "customer_name": data.Name,
      "comment": data.comment,
      verification_source,
      verifier_name,
      verified_by
    }

    let payload = await encryptWithHybrid(JSON.stringify(payLoad));

      axios.post(`${prodbase_blog_url}review-update`,
      payload, {
        headers: {
          token
        }
      }).then((res) => {
        let dec_data = decryptWithHybrid(res.data.encryptedMessage, res.data.encryptedAesKey, res.data.iv)
        if ((dec_data.status === "1") && (dec_data.status_code === "200")) {
          reset();
        }
      })
  }

  var sliderblog = {
    infinite: true,
    autoplay: true,
    cssEase: "ease-in-out",
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  React.useEffect(() => {
    if (
      data.Name  &&
      data.comment && 
      count
    ) {
      if (Object.keys(errors).length === 0) {
        setSubmit(true);
      } else {
        setSubmit(false);
      }
    }
     else {
      setSubmit(false);
    }
  }, [data]);

  const handleclick = ()=>{
    if(count == 0){
      setFirst(true);
    }
  }

  return (
    <>
      <div className="blog-main-container-one">
      <div className="blog-main-container-two">
      <div className="blog-loan">
        <span className="open-get-loan-heading">
          Apply for Right Personal Loan
        </span>
        <div className="get-loan-displaying-row blog-displaying-row">
          <div className="left-content-1">
            <ul>
              <li>Same Day Disbursement</li>
              <li>No Document Upload</li>
            </ul>
            <div className="open-get-loan-btn blog_widget_apply_now" style={{ marginTop: "20px" }}>
              <a className="blog_widget_apply_now" href={"/personal-loan"}>Apply Now</a>
            </div>
          </div>
          <div className="right-popUp-image">
            <img src={Clipboard} alt="" />
          </div>
        </div>
      </div>
      {/* APPLY LOAN END*/}
      {/* SLIDER START */}
      <div className="blog-card-blog">
        <p className="blog-card-blog-heading">Recent Posts</p>
        <Slider {...sliderblog}>
          {post.map((el, index) => (
            <div key={`${index}-nesting`} className="nesting">
              <a style={{ textDecoration: "none" }} href={react_base_url + "blog/" + el.url}>
                <div className="main-blog-card">
                  <img
                    className="main-blog-img"
                    src={`${HeadUrl}${el.image_path}`}
                  />
                  <p className="main-blog-p">{el.heading}</p>
                  <button>
                    Read More
                  </button>
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </div>
      </div>
      {show &&  <div className="blog-main-container-two">
        <div className="right-side">
        <div className="form-box blog-main-form-box">
          <div className="form-body">
            <FormHead
              formName={"How would you rate this Article?"}
              progressBar={true}
            />
            <div className="tag-for-input">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                <div
                  className={
                    errors.Name
                      ? "input-tag error-border remove-bottom"
                      : data.Name
                        ? "input-tag  add-border remove-bottom"
                        : "input-tag  remove-bottom"
                  }
                >
                  <img className="tag-icon" src={familyPlus} />
                  <input
                    type="text"
                    placeholder="Your Name"
                    {...register("Name", { required: true, minLength: 3 })}
                    autoComplete="off"
                    onKeyUp={() => handleAlphaOnly(data.Name, "Name")}
                    onBlur={() => Blured("Name", data.Name)}
                  />
                  {/* {errors.Name && <img src={ThisIsAnError} alt="" id="pointer-cur" />} */}
                </div>
                {errors.Name && (
                  <div className="err-text">Enter At least 3 Characters</div>
                )}

                <p className="star-error-blog-not"><strong>Rate Us</strong></p>
                <div className="rating-star-1">
                  <div className="givestar-rating">
                    <div className="rate large-latestart-star">
                      <input type="radio" id="starfavorite star.svg" name="ratng_mlc" value="5" onClick={() => setthis(5)} />
                      <label htmlFor="starfavorite star.svg" title="text"></label>
                      <input type="radio" id="star4" name="ratng_mlc" value="4" onClick={() => setthis(4)} />
                      <label htmlFor="star4" title="text"></label>
                      <input type="radio" id="star3" name="ratng_mlc" value="3" onClick={() => setthis(3)} />
                      <label htmlFor="star3" title="text"></label>
                      <input type="radio" id="star2" name="ratng_mlc" value="2" onClick={() => setthis(2)} />
                      <label htmlFor="star2" title="text"></label>
                      <input type="radio" id="star1" name="ratng_mlc" value="1" onClick={() => setthis(1)} />
                      <label htmlFor="star1" title="text"></label>
                    </div>
                    <span id="rtng_mlc_error" className="error"></span>
                  </div>
                </div>
                {first && count ===0 && <p className="feed-error">Please Select Rating</p>}

                <div
                  className={
                    "input-tag" +
                    (errors.comment
                      ? " error-border"
                      : data.comment
                        ? " add-border"
                        : "")
                  }
                >
                  {/* <input className="input-tag-box" type="text-area" style={{ marginBottom: "2px" }} placeholder='Write your comment'
                        {...register('comment', { required: true })} autoComplete="off" /> */}
                  <textarea
                    className="input-tag-box blog-comment-text-area"
                    type="text"
                    onBlur={() => Blured("comment", data.comment)}
                    onKeyUp={() => SpaceManage("comment", data.comment)}
                    placeholder="Write your comment"
                    {...register("comment", { required: true, minLength: 3 })}
                    autoComplete="off"
                  ></textarea>
                </div>
                {errors.comment && (
                  <div className="err-text">Enter At least 3 Characters</div>
                )}

                <button onClick={handleclick}
                  className={
                    "footer-btn Tmargin manage-nomine-bottom" +
                    (submit ? " footer-btn-activate" : "")
                  }
                >
                  Rate Article
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
        </div>}
      </div>
      {/* SLIDER END */}
      {/* SUBSCRIBER LIST */}
      {/* <div className="subscriber-list">
            <p className="subs-p">NEWSLETTER</p>
            <p className="subs-p-1">Subscribe to Maillist!</p>
            <span>
              Subscribe to the Beasbe newsletter to receive the
              latest-improvement article insights, strategies and promotions
              straight to your inbox.
            </span>
            <div className="subs-subs">
              <input type="text" placeholder="Email Address" />
              <button>Subscribe</button>
            </div>
          </div> */}
    </>
  );
};

export default BlogRightSide;
