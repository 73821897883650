import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { storeEmi } from "../../Redux/action";

function ForeclosureCalculation() {
    const dispatch = useDispatch();
    const {pa, lt, roi,emiPaid,PartPayAmt} = useSelector((state) => state);
    const [emi, setEmi] = React.useState(0);
    const [ti, setTi] = React.useState(0);
    const [state, setState] = React.useState([])
    const [stateInt, setStateint] = React.useState([])
    const [showint,setShowint] = React.useState('');
    const[showintsaved, setShowintsaved]= React.useState('');
    const[showsetintbeforefore, setIntBeforeFore]= React.useState('');

    // Total Payable Amount Calculation
    // roi = rate of interest
    // pa = principal amount
    // lt = loan tenure
    //t_p_a= total payable amount
    //t_i= total interest
    //rem_per= remaining percentage
    //int_per = interest percentage
    //PartPayAmt=part payment amt
    //emiPaid= no. of emi's paid
    //Rev_Emi = revisedemi
     
    const TotalPayableAmount = () => {
        let r = roi / (12 * 100);
        let Emi = (pa * r * Math.pow((1 + r), lt)) / (Math.pow((1 + r), lt) - 1);
        let t_p_a = Emi * lt;
        let t_i = t_p_a - pa;
        let interest_before_fore = Emi * lt - pa;
        //let int_per = Math.round((t_i * 100) / t_p_a);
        dispatch(storeEmi(Emi));
        setEmi(Emi);
        setTi(Math.round(t_i).toLocaleString('hi-IN'));
        setIntBeforeFore(Math.round(interest_before_fore));
    }

    React.useEffect(() => {
        let prin_cf = pa;
        let arr = [];
        for (let i = 0; i < emiPaid; i++) {
            let int_payable = (roi * prin_cf / 1200);
            let principal_repaid = (emi - int_payable);
            prin_cf = (prin_cf - principal_repaid);
            let payload = {
                key: i + 1,
                interest: int_payable,
                principal: Math.round(principal_repaid),
                balance: Math.abs(Math.round(prin_cf))
                //balance: prin_cf
            }
            arr = [...arr, payload];
        }
        setState(arr)
    }, [pa, lt, roi, emi,emiPaid])

    React.useEffect(() => {
        TotalPayableAmount();

    }, [pa, lt, roi,emiPaid,PartPayAmt])

    React.useEffect(() => {
        let prin_cff = +pa;
        let arry = [];
        for (let j = 0; j < +lt; j++) {
            let int_payablee = (+roi * prin_cff / 1200);
            let payloadd = {
                key: j + 1,
                interest: int_payablee,
            }
            arry = [...arry, payloadd];
        }
        setStateint(arry)
    }, [pa, lt, roi, emi,emiPaid])

    React.useEffect(() => {
        TotalPayableAmount();

    }, [pa, lt, roi,emiPaid,PartPayAmt])

    React.useEffect(()=>{
        let count=0;
        for(let i=0;i<state.length;i++){
            count=count+state[i].interest;
            // console.log(count);
        }
        setShowint(count);          
    },[state])

    React.useEffect(()=>{
        let countInt=0;
        for(let i=0;i<stateInt.length;i++){
            countInt=countInt+stateInt[i].interest;
        }
        setShowintsaved(countInt);
            
    },[stateInt])

    return (
        <div>
            <div className="calculation-main">
                <div className="calculation-first-half">
                    <div className="calculation-first">
                        <p>Foreclosure amount</p>
                        {state.length ? <h1>₹ {state[state.length-1].balance.toLocaleString('hi-IN')}</h1> : <h1>₹ 0</h1>}
                    </div>
                    <div className="calculation-second">
                        <p>Interest Paid</p>
                        {showint ?<h1>₹{Math.round(showint).toLocaleString('hi-IN')}</h1>:<h1>₹ 0</h1>}
                    </div>
                    <div className="calculation-first new-one">
                        <p>Interest before Foreclosure</p>
                        { showsetintbeforefore ?<h1>₹{(showsetintbeforefore).toLocaleString('hi-IN')}</h1>:<h1>₹ 0</h1>}

                        
                    </div>
                </div>
                
                <div className="calculation-first-half">
                    <div className="calculation-first">
                        <p>Monthly EMI Paid:</p>
                        <h1>₹ {Math.round(emi).toLocaleString('hi-IN')} </h1>
                    </div>
                    <div className="calculation-second">
                        <p>Interest Saved </p>
                        {showint && showsetintbeforefore?<h1>₹{(showsetintbeforefore - Math.round(showint)).toLocaleString('hi-IN')}</h1>:<h1>₹ 0</h1>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForeclosureCalculation;