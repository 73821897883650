import React, { useState, useEffect } from 'react'
import "./partnerwithus.css";
import logo from "../../Icons/new_moneywide_logo.svg"
import greenticknew from "../../Icons/greenticknew.svg"
import PartnerLogin from './PartnerLogin';
// import PartnerOffer from './PartnerProfile';
import PartnerProfile from './PartnerProfile';
import PartnerOTP from './PartnerOtp';
import EmployerDetailsThreeTag from '../Journey Form/Form/All_Forms/EmployerDetailsThreeTag';
// import PANCARDPopUP from '../Popups/PANCARDPopUP/PANCARDPopUP';
// import UrbanMoneyOffer from '../OfferSection/UrbanMoneyOffer';
import WrongOtp from '../NotFound/WrongOtp';
import { useDispatch, useSelector } from 'react-redux';
import PartnerPanCard from './PartnerPanCard';
import PartnerOfferSelection from './PartnerOfferSelection';
import queryString from "query-string";
import Base64 from 'base-64';
import axios from 'axios';
import { prodbase_url, react_base_url, source, verification_source, verified_by, verifier_name } from '../Secret_Credentials/Secret_Credential';
import { getToken } from '../EverCalledFunctions/EverCalledApis';
import { GenerateToken, updateCredentialsState, updateLoginDetails, updateMwApplicationId, updatePartnerData, updatePartnerState } from '../../Redux/action';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import UrbanMoneyOfferOld from './UrbanMoneyOfferOld.jsx';
import {  RelocateUTMLP } from '../EverCalledFunctions/EverCalledFunctions';
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';
import NumberVerification from '../Journey Form/Form/All_Forms/NumberVerification';
import NumberverificationOtp from '../Journey Form/Form/All_Forms/NumberverificationOtp';

function PartnerWithUs() {
    let dispatch = useDispatch();
    const { partnerStates, token, partnerData, credentialState,mw_application_id,loginDetails } = useSelector(state => state)
    const [flag, setFlag] = useState('');
    const [details, setDetails] = useState([]);
    const [loader, setLoader] = useState(true)
    const [step, setStep] = useState('');
    const [state, setState] = useState(false);
    const [utm,setUtm] = useState('');

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }

    useEffect(() => {
        if (token == "") {
            myToken();
        }
        let stamp = queryString.parse(window.location.search);
        if (stamp && stamp.reference_id) {
            try {
                let fl = stamp.reference_id
                fl = Base64.decode(fl)
                fl = Base64.decode(fl)
                setFlag(fl)
            }
            catch {
                window.location.href = `${react_base_url}`
            }

        }
        if(stamp && stamp.utm_source){
            setUtm(stamp.utm_source)
            dispatch(updatePartnerState({...partnerStates,PartnerUtm:true,utm:stamp.utm_source}));
        }
        if (stamp && stamp.fid) {
            try {
                let id = stamp.fid;
                id = Base64.decode(id);
                id = Base64.decode(id);
                if (id.includes("&")) {
                    id = id.split("&");

                    let mw_app_id = id[0];
                    if (mw_app_id.includes("lead_id")) {
                        mw_app_id = mw_app_id.split("lead_id=");
                        mw_app_id = mw_app_id[1];
                        mw_app_id = Base64.decode(mw_app_id);
                        mw_app_id = Base64.decode(mw_app_id);

                        let cust_id = id[1];
                        cust_id = cust_id.split("step_id=");
                        cust_id = cust_id[1];
                        cust_id = Base64.decode(cust_id)
                        cust_id = Base64.decode(cust_id)
                        setStep(cust_id);
                        dispatch(updateMwApplicationId(mw_app_id));
                        // setLoader(false)

                    }
                    else {
                        window.location.href = `${react_base_url}`;
                    }

                }
                else {
                    window.location.href = `${react_base_url}`;
                }
            }
            catch {
                window.location.href = `${react_base_url}`
            }
        }
    }, [])

    useEffect(() => {
        if ((step !== "") && (mw_application_id !== '') && (token !== "")) {
            axios.post(`${prodbase_url}web/fetch-customer-lpdata`, {
                mw_application_id,
                "step": step,
                verifier_name,
                verification_source,
                verified_by,
                source,
                "page_url": window.location.href
            }, {
                headers: {
                    token
                }
            }).then((res) => {
                // console.log(res.data);
                if ((res.data.status == 1) && (res.data.status_code == 200)) {
                    setLoader(false);
                    dispatch(updateLoginDetails({ ...loginDetails, preVal: res.data.data, empData: res.data.data, preData: res.data.data ,mobile: res.data.data.phone_no , equifax_no: res.data.data.equifax_no}));
                    if (res.data.data.customer_id !== "") {
                        dispatch(updateCredentialsState({ ...credentialState, cust_id: res.data.data.customer_id }));
                    }
                    if (res.data.data.thank_you_flag == 1) {
                        if (res.data.data.thank_you_url !== "") {
                            window.location.href = res.data.data.thank_you_url;
                            return
                        }
                        else {
                            let qry = Base64.encode(`${mw_application_id}`);
                            qry = Base64.encode(qry);
                            window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18`;
                            return
                        }
                    }
                    if (res.data.data.redirection_url !== "") {
                        window.location.href = res.data.data.redirection_url;
                    }
                    else if (step == 1) {
                        dispatch(updatePartnerState({ ...partnerStates, PartnerLogin: false, PartnerProfile: true, Show: true }))
                    }
                    else if (step == 2) {
                        dispatch(updatePartnerState({ ...partnerStates, PartnerLogin: false, PartnerEmployer: true, Show: true }))
                    }
                    else if (step == 3) {
                        if((((res.data.data.final_offer_generate_type == 1) || (res.data.data.final_offer_generate_type == 2)))){
                            if(res.data.data.aipManual_offer.length !== 0){
                                dispatch(updatePartnerData({ ...partnerData, finaloffer: res.data.data.aipManual_offer }))
                            }
                        }
                        dispatch(updatePartnerState({...partnerStates,PartnerLogin:false,PartnerSelection:true,Show:true}))
                    }
                    else {
                        dispatch(updatePartnerState({ ...partnerStates, PartnerLogin: false, PartnerProfile: true, Show: true }))
                    }
                }
                else {
                    setState(true);
                }
            })
        }
    }, [step, mw_application_id, token])

    useEffect(() => {
        if (((flag !== "") || (utm !== "")) && (token !== "")) {
            axios.post(`${prodbase_url}web/pa-screen-details`, {
                "reference_id": flag ? flag : "",
                verification_source,
                verified_by,
                verifier_name,
                source,
                'utm_source':utm ? utm : ""
            }, {
                headers: {
                    token
                }
            }).then((res) => {
                if ((res.data.status_code == 200) && (res.data.status == 1)) {
                    setDetails(res.data.data);
                    if(step == ''){
                        setLoader(false);
                    }
                    dispatch(updatePartnerData({ ...partnerData, dashData: res.data.data }));
                }
                else {
                    RelocateUTMLP(`${react_base_url}personal-loan`);
                }
            })
        }
    }, [token, flag , utm,step])

    const handleError = (e) => {
        setState(false);
        window.location.reload();
    }

    return (
        <>
            {state && <NewErrorCase handleClick={(e) => handleError(e)} />}
            {loader ? <CKYCWaiting /> : <div className="partner-top-main">
                <div className="partners-all-main">
                    <div className="partners-sub-div">
                        <div className="mw-muthot-logo">
                        {
                                details.lead_source_flag == 1 ? <>
                                <div className={(details.lead_source_flag == 1) ? "mw-logo-right mw-logo-right-none ":"mw-logo-right"}>
                                <img src={logo} alt='moneywide'/>
                            </div>
                                </>:<>
                                <div className='mw-logo-right'>
                                <img src={logo} alt='moneywide' />
                                {details.partner_logo ? <img src={`https://blog.moneywide.com/assets/images/our-partner/${details.partner_logo}`}
                                    alt='muthoot' /> : <p>{details.partner_name}</p>}
                            </div>
                                </>
                            }
                        </div>
                        <div className="partners-form-div">
                            <div className="partners-left-cont">
                                <div className="partners-left-cont-head">
                                {
                                        details.lead_source_flag == 1 ? <span>
                                            Welcome to MoneyWide pre-approved <br /> program.
                                        </span>:<span>
                                        Welcome to MoneyWide pre-approved <br /> program with {details.partner_name}.
                                        </span>
                                    }
                                </div>
                                <div className="partners-keyfeatures partner-key-web">
                                    <div className="partners-keyfeatures-heading">
                                        <span>Key <b>Features</b></span>
                                    </div>
                                    <div className="partners-keypoints">
                                        <div className="partner-sub-key">
                                            <div className="partner-sub-new">
                                                <img src={greenticknew} alt=''/>
                                                <p>Fully digital pre-approved instant personal loan</p>
                                            </div>
                                            <div className="partner-sub-new">
                                                <img src={greenticknew} alt=''/>
                                                <p>Same day disbursement</p>
                                            </div>
                                            <div className="partner-sub-new">
                                                <img src={greenticknew} alt=''/>
                                                <p>No income documents required.</p>
                                            </div>
                                            <div className="partner-sub-new">
                                                <img src={greenticknew} alt=''/>
                                                <p>Online prepayment or foreclosure facility after 1st EMI</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="partner-right-cont">
                                {/* <div className="partner-login">
                                    <PartnerLogin />
                                </div> */}
                                <div className="right-side-partner-profile">
                                    <div className="right-side">
                                        <div>
                                            <div className="form-box">
                                                <div className="form-body">
                                                    {partnerStates.PartnerLogin && <PartnerLogin />}
                                                    {partnerStates.PartnerProfile && <PartnerProfile />}
                                                    {partnerStates.PartnerOtp && <PartnerOTP />}
                                                    {partnerStates.PartnerEmployer && <EmployerDetailsThreeTag step_enable={true} />}
                                                    {partnerStates.PartnerPan && <PartnerPanCard />}
                                                    {partnerStates.PartnerOffer && <UrbanMoneyOfferOld />}
                                                    {partnerStates.PartnerWrong && <WrongOtp />}
                                                    {partnerStates.PartnerSelection && <PartnerOfferSelection />}
                                                    {partnerStates.numberVerification && <NumberVerification />}
                                                    {partnerStates.Partnerverification && <NumberverificationOtp/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!partnerStates.Show && <div className="partners-keyfeatures partner-key-mob">
                                    <div className="partners-keyfeatures-heading">
                                        <span>Key <b>Features</b></span>
                                    </div>
                                    <div className="partners-keypoints">
                                        <div className="partner-sub-key">
                                            <div className="partner-sub-new">
                                                <img src={greenticknew} alt=''/>
                                                <p>Fully digital pre-approved instant personal loan</p>
                                            </div>
                                            <div className="partner-sub-new">
                                                <img src={greenticknew} alt=''/>
                                                <p>Same day disbursement</p>
                                            </div>
                                            <div className="partner-sub-new">
                                                <img src={greenticknew} alt=''/>
                                                <p>No income documents required.</p>
                                            </div>
                                            <div className="partner-sub-new">
                                                <img src={greenticknew} alt=''/>
                                                <p>Online prepayment or foreclosure facility after 1st EMI</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>



                        </div>
                    </div>
                </div>

            </div>}

        </>
    )
}

export default PartnerWithUs