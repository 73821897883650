import React, { useState } from "react";
import "./Analyzer.css";
import queryString from "query-string";
import Base64 from "base-64";
import FinbitScreen from "../Journey Form/AipOffer/FinbitScreen";
import { useSelector, useDispatch } from "react-redux";
import {
  GenerateToken,
  setSorryPopup,
  updateAppStatus,
  updateCredentialsState,
  updateFormState,
  updateMwApplicationId,
  updateResumeJourney,
} from "../../Redux/action";
import { Check_utm_source, RelocateUTMCheck, decryptOnlyLeadId, gettingerror } from "../EverCalledFunctions/EverCalledFunctions";
import axios from "axios";
import {
  prodbase_url,
  source,
  client_id,
  client_key,
  verification_source,
  verified_by,
  verifier_name,
  react_base_url,
} from "../Secret_Credentials/Secret_Credential";
import {
  getCustId,
  getToken,
  ResumeJourneyApi,
} from "../EverCalledFunctions/EverCalledApis";
import { useNavigate } from "react-router-dom";
import MainLoader from "../MainLoader/MainLoader";
import CKYCWaiting from "../LoginPopUp/CKYCWaiting";
import NewErrorCase from "../Journey Form/Form/All_Forms/NewErrorCase";
import { AxiosThirty } from "../AxiosConfiguration/AxiosConfig";
import { FetchCustomerPayload } from "../EverCalledFunctions/EverCalledPayload";

function Analyzer() {
  const {
    mw_application_id,
    credentialState,
    resumeJourney,
    token,
    formStates,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [app, setApp] = useState(false);
  const [state, setState] = React.useState({
    loading: true,
    finbit: false,
  });
  const [rocket, setRocket] = React.useState(false);
  const navigate = useNavigate();

  const OfferEligibilityAtEmployement = () => {

    axios.post(
      `${prodbase_url}offer-eligibility-test`,
      {
        "source": source,
        "mw_application_id": mw_application_id,
        "product_id": 56,
        "customer_id": credentialState.cust_id,
        "offer_type": 1,
        "level_id": 1,
        "verified_by": verified_by,
        "verifier_name": verifier_name,
        "verification_source": verification_source,
        "stage_name": "Employment Check"
      },
      {
        headers: {
          "token": "",
        },
      }
    )
      .then((res) => {
        console.log(res);
        // OfferEligibilityTest()
      });
  }

  const FinalEligibility = (mw_app_id, cust_id) => {

    if (credentialState?.step_5) {
      if (credentialState.step_5.is_customer_eligible === 1) {
        if (credentialState.step_5.is_customer_eligible === 1 &&
          credentialState.step_5.uan_no.length !== 0 && ((credentialState.step_5.new_journey_lead_source_flag == 1) || (credentialState.step_5.new_journey_customer_id_flag == 1))) {
          setState({ ...state, loading: false, finbit: true });
        }
        else if (
          credentialState.step_5.is_customer_eligible === 1 &&
          credentialState.step_5.uan_no.length !== 0 && ((credentialState.step_5.new_journey_lead_source_flag != 1) || (credentialState.step_5.new_journey_customer_id_flag != 1))) {
          let id = ""
          id = credentialState.step_5.selected_program_id;
          window.sessionStorage.setItem("selected_program_id", id);
          axios
            .post(
              `${prodbase_url}offer-eligibility`,
              {
                "customer_id": cust_id,
                "mw_application_id": mw_application_id,
                "offer_type": 1,
                "verifier_name": verifier_name,
                "verification_source": verification_source,
                "verified_by": verified_by,
                "product_id": 0,
                "stage_name": "Employment Check",
                "program_id": id,
                "level_id": 1,
                "source": source,
              },
              {
                headers: {
                  "token": "",
                },
              }
            )
            .then((res) => {
              console.log(res);
            });
        }
        setState({ ...state, loading: false, finbit: true });
      } else {
        gettingerror('not eligible for BSA web',mw_application_id)
        let checkCondition = Check_utm_source()
        if (checkCondition && checkCondition.length != 0) {
          let qry = Base64.encode(`${mw_application_id}`);
          qry = Base64.encode(qry);
          window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18${checkCondition}`;
        }
        else {
          let qry = Base64.encode(`${mw_application_id}`);
          qry = Base64.encode(qry);
          RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=18`);
        }
      }
    }
    else {
      window.location.reload()
    }
  };

  const getStepId = async () => {
    const qry = queryString.parse(window.location.search);
    if (qry.app) {
      dispatch(updateAppStatus(true));
      setApp(true);
      window.sessionStorage.setItem("count", 1);
    }
  };

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, []);

  React.useEffect(() => {
    if (
      credentialState.cust_id !== "" &&
      mw_application_id !== "" &&
      credentialState.cust_id !== undefined
    ) {
      FinalEligibility(mw_application_id, credentialState.cust_id);
    }
  }, [credentialState, mw_application_id]);

  const handleError = (e) => {
    window.location.reload();
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      {state.loading && (
        <CKYCWaiting
          heading={
            "We are initiating the process of income verification."
          }
        />
      )}
      {state.finbit && (
        <div className={app ? "sdk-mobile" : "sdk"}>
          <div className="sdk-boxing">
            <div className="sdk-pre-box">
              <p>
                Please share your salary bank statement for latest 6 months. We
                will only access banking transaction data. Your funds remain
                100% safe with no access to any third party, including us.
              </p>
            </div>
          </div>
          <FinbitScreen />
        </div>
      )}
    </>
  );
}

export default Analyzer;