import axios from "axios";
import { aa_prodbase_url, doc_upload_url, prodbase_blog_url, prodbase_url } from "../Secret_Credentials/Secret_Credential";
import { LogApi, getToken } from "../EverCalledFunctions/EverCalledApis";
import { decryptWithHybrid, encryptWithHybrid } from "../../Keys";
import { getSecret } from "../Secret_Credentials/SecretManager";

export const apiInstance = axios.create({
    baseURL: prodbase_url,
    timeout: 30000
})

let api_details = []

const EndPointList = [
    // , 'token'
    // , 'thankyou-screen-message'
    // , 'generate-otp'
    // , 'verify-otp'
    // , 'get-pincode'
    // , 'check-whatsapp-subscription'
    // , 'get-pincode-city'
    // , 'decision-pan-api'
    // , 'decision-epfo-api'
    // , 'offer-eligibility'
    // , 'offer-eligibility-test'
    // , 'mobile-detection'
    // , 'confirm-pan-submit'
    // , 'decision-demog-api'
    // , 'get-company'
    // , 'email-fraud'
    // , 'offer-submission'
    // , 'kyc/get-address-data'
    // , 'get-selfie-details'
    // , 'ovd-ocr-master'
    // , 'email-otp-verify'
    // , 'email-otp-send'
    // , 'get-profile-personal-details'
    // , 'email-update'
    // , 'create-selfie'
    // , 'send-kfs'
    // , 'digio-create-order'
    // , 'master'
    // , 'loan-agreement-t-c'
    // , 'get-dashboard-data'
    // , 'fetch-customer-details'
    // , 'finprodecrypturl'
    // , 'check-utm-source'
    // , 'web/employment-details'
    // , 'web/loan-details-submit'
    // , 'web/push-los'
    // , 'finbit-token'
    // , 'bsadigi-generate-url'
    // , 'bsadigi-getstatus'
    // , 'web/web-address-details'
    // , 'web/bank-verification-penny-drop'
    // , 'web/employer'
    // , 'web/additional-details'
    // , 'web/reference-details'
    // , 'web/loan-agreement-kfs'
    // , 'web/bank-details-submit'
    // , 'pg-create-customer'
    // , 'pg-create-order'
    // , 'web/submit-okyc-data'
    // , "web/loan-agreement-t-c"
    // , 'digilocker/get-customer-details'
    // , 'web/vkyc-user-activate'
    // , 'digilocker/fetch-details'
    // , 'cms-ticket-status'
    // , 'equifax-phone-submit'
    // , 'digio-vkyc-create-request'
    // , 'vkyc/get-transactionid-v2'
    // , 'digio-vkyc-request-details'
    // , 'web/fetch-customer-lpdata'
    // , 'web/pa-screen-details'
    // , 'web/get-dashboard-lpdata'
    // , 'api/pa-basic-details'


    // Blog Urls
    // , 'recent-blog-articles'
    // , 'fetch-blog-category'
    // , 'fetch-blog-wise'
    // , 'fetch-blog-category-wise',
    // , 'search-blog-data'
    // , 'review-update'
    // , 'fetch-blog-articles'

    // Login Area apis
    // , 'myaccount/dynamic-header-updated'
    // , 'myaccount/make-payment'
    // , 'myaccount/profile-details'
    // , 'myaccount/fetch-loan-details'
    // , 'myaccount/fetch-repayment-details'
    // , 'myaccount/get-track-application'
    // , 'myaccount/fetch-account-statement'
    // , 'myaccount/fetch-foreclosure-loan'
    // , 'myaccount/fetch-foreclosure-link'
    // , 'myaccount/get-missed-emi'
    // , 'myaccount/get-overlink-due'
    // , 'myaccount/fetch-part-payment-simulation'
    // , 'myaccount/fetch-part-payment-link'
    // , 'myaccount/fetch-transactions'
    // , 'web/comm-preferences-submit'
    // , 'web/comm-preferences-label'
    // , 'submit-deletion-reason'
    // , 'submit-deletion-response'
    //    Career apis
    // , 'fetch-function'
    // , 'fetch-function-details'
    // , 'fetch-position-data'
    // , 'career-form-submit'

    // Extra Apis    
    // , 'faq'
    // , 'announcement'
    // , 'web/meta-tags'
    // , 'insert-partner-details'
    // , 'apr-details'
    // , 'customer-reviews'
    // , 'web/terms-privacy'
    // , 'fetch-htmlsitemap-detail'
    // , 'web/our-partner'
    // , 'api/fetch-infographic'
    // , 'api/fetch-htmlsitemap-detail'


    //service-request
    // , 'cms-customer'
    // , 'cms-products'
    // , 'cms-dropdown'
    // , 'complaint-submission',


    // 'v2/myaccount/profile-details',
    // 'v2/myaccount/fetch-loan-details',
    // 'v2/myaccount/fetch-repayment-details',
    // 'v2/myaccount/fetch-account-statement',
    // 'v2/myaccount/get-track-application',
    // 'v2/myaccount/get-missed-emi',
    // 'v2/myaccount/get-overlink-due',
    // 'v2/myaccount/fetch-foreclosure-loan',
    // 'v2/myaccount/fetch-foreclosure-link',
    // 'v2/myaccount/fetch-part-payment-simulation',
    // 'v2/myaccount/fetch-part-payment-link',
    // 'v2/myaccount/fetch-part-payment',
    // 'v2/myaccount/update-profile-details',
    // 'v2/myaccount/make-payment',
    // 'v2/myaccount/fetch-transactions',
    // 'v2/myaccount/partial-payment',
    // 'v2/myaccount/partial-payment-webhook',
    // 'v2/myaccount/dynamic-header',
    // 'v2/myaccount/dynamic-header-updated',
    // 'v2/myaccount/fetch-repayment-cron',
]

apiInstance.interceptors.request.use(
    async (config) => {
        let APIEndPoint = EndPointList.indexOf(config.url)
        // let APIEndPoint;
        // if (sessionStorage.getItem('allApiEndPoints')) {
        //     APIEndPoint = sessionStorage.getItem('allApiEndPoints');
        //     APIEndPoint = JSON.parse(APIEndPoint);
        //     APIEndPoint = APIEndPoint.indexOf(config.url);
        // }
        // else {
        //     APIEndPoint = await getSecret('ApiEndPoints');
        //     APIEndPoint = JSON.parse(APIEndPoint);
        //     APIEndPoint = APIEndPoint.indexOf(config.url)
        // }
        if (APIEndPoint != -1) {
            config.data = encryptWithHybrid(JSON.stringify(config.data), { key1: config['key1'], value1: config['value1'], key2: config['key2'], value2: config['value2'] })
        } else {
            config.data = config.data
        }
        config.headers.token = config?.token ? config?.token : await getToken()
        return config;
    },
    (error) => {
        return (error);
    }
);

// Response interceptor
apiInstance.interceptors.response.use(
    // Modify the response data or perform additional logic
    async (response) => {
        let APIEndPoint = EndPointList.indexOf(response.config.url)
        // let APIEndPoint;
        // if (sessionStorage.getItem('allApiEndPoints')) {
        //     APIEndPoint = sessionStorage.getItem('allApiEndPoints');
        //     APIEndPoint = JSON.parse(APIEndPoint);
        //     APIEndPoint = APIEndPoint.indexOf(response.config.url);
        // }
        // else {
        //     APIEndPoint = await getSecret('ApiEndPoints')
        //     APIEndPoint = JSON.parse(APIEndPoint);
        //     APIEndPoint = APIEndPoint.indexOf(response.config.url);
        // }
        if (APIEndPoint != -1) {
            try {
                const decrypt = decryptWithHybrid(response.data?.encryptedMessage, response.data?.encryptedAesKey, response.data?.iv)
                api_details.push({ "api_url": response?.config?.url ? response.config.url : "", "api_status": decrypt?.status_code ? decrypt.status_code : "" })
                // console.log(decrypt);
                if(response.config.log){
                    const res = await LogApi({api_details},response.config.token)
                    if(res.status_code){
                        api_details = []
                        return decrypt
                    }else{
                        return decrypt
                    }
                }else{
                    return decrypt
                }
                // return decrypt;
            } catch {
                // console.log(response);
                return response;
            }
        } else {
            // console.log(response);
            // return response.data
            api_details.push({ "api_url": response?.config?.url ? response.config.url : "", "api_status": response?.data?.status_code ? response.data.status_code : "" })
            if(response.config.log){
                const res = await LogApi({api_details},response.config.token)
                if(res.status_code){
                    api_details = []
                    return response.data
                }else{
                    return response.data
                }
            }else{
                return response.data
            }
        }

    },
    (error) => {
        return {}
    }
);

export const BlogAPIInstance = async ({ data, url, token }) => {
    return await apiInstance({ baseURL: prodbase_blog_url, url: url, method: "POST", data, token })
}

export const DocAPIInstance = async ({ data, url, token }) => {
    return await apiInstance({ baseURL: doc_upload_url, url: url, method: "POST", data, token })
}

export const AAAPIInstance= async ({ data, url, token }) => {
    return await apiInstance({ baseURL: aa_prodbase_url, url: url, method: "POST", data, token })
}

export const DocUpload= async ({ data, url, token }) => {
    return await apiInstance({ baseURL: 'https://doc-api.moneywide.com/', url: url, method: "POST", data, token })
}