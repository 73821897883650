import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GenerateToken } from '../../Redux/action';
import { getCustId, getToken } from '../EverCalledFunctions/EverCalledApis';
import { decryptMandateId } from '../EverCalledFunctions/EverCalledFunctions';
import { setPreApprovedOfferDetails, updateCredentialsState, updateFormState, updateMwApplicationId, updateChoosenAmount } from '../../Redux/action';
import axios from 'axios';
import { prodbase_url, verification_source, verified_by, verifier_name, source, react_base_url } from '../Secret_Credentials/Secret_Credential';
import Base64 from "base-64";
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import queryString from 'query-string';
import { FetchCustomerPayload } from '../EverCalledFunctions/EverCalledPayload';
import { apiInstance } from '../Config/APIConfig';


function PartnerMandate() {
    const { credentialState, mw_application_id, step_bar, token, formStates } = useSelector(state => state);
    const dispatch = useDispatch();
    const [auth, setAuth] = useState("");
    const [state, setState] = useState(true);
    const navigate = useNavigate();

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    }


    React.useEffect(() => {
        if (token == "") {
            myToken();
        }

    }, []);

    const getCustIdHere = async (mw_app_id) => {
        if (window.sessionStorage.getItem("cust_id") && (window.sessionStorage.getItem("cust_id") !== undefined)) {
            dispatch(updateCredentialsState({ ...credentialState, cust_id: window.sessionStorage.getItem("cust_id") }));
        }
        else {
            const APIDATA = await getCustId(mw_app_id, token);
            window.sessionStorage.setItem("cust_id", APIDATA);
            dispatch(updateCredentialsState({ ...credentialState, cust_id: APIDATA }))
        }
    };

    const decryptFid = async () => {
        const details = await decryptMandateId();
        dispatch(updateMwApplicationId(details[0]));
        getCustIdHere(details[0]);
        setAuth(details[1]);
    }

    React.useEffect(() => {
        if (token !== "") {
            decryptFid();
        }
    }, [token])

    const fetchCustomerDetails = async () => {
        let decFetch = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 15), {
            token
        })
            // .then((res) => {
                if ((decFetch.status == 1) && (decFetch.status_code == 200)) {
                    if (decFetch.data.thank_you_flag == 1) {
                        if (decFetch.data.thank_you_url !== "") {
                            window.location.href = decFetch.data.thank_you_url;
                            return
                        }
                        else {
                            let qry = Base64.encode(`${mw_application_id}`);
                            qry = Base64.encode(qry);
                            window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=18`;
                            return
                        }

                    }
                    if (decFetch.data.redirect_url !== "") {
                        let qry = queryString.parse(window.location.search);
                        if (qry.redirect_url) {
                            let red_url = Base64.decode(qry.redirect_url);
                            window.location.href = red_url;
                            return;
                        }

                    }
                    const phone = decFetch.data.phone_no;
                    let pg_method = decFetch.data.pg_mode_dis;
                    let red_url = "";
                    const qry = queryString.parse(window.location.search);
                    if (qry.redirect_url) {
                        red_url = `${react_base_url}partnermandate/pat/?pat_id=${qry.redirect_url}`
                    }
                    if (qry.mandate_user) {
                        pg_method = Base64.decode(qry.mandate_user);
                        pg_method = Base64.decode(pg_method);
                        if (pg_method == "1") {
                            pg_method = "Digio";
                        }
                        else {
                            pg_method = "Razorepay"
                        }
                    }
                    if (pg_method === "Razorepay") {
                        let decCustomer = await apiInstance
                            .post(
                                `pg-create-customer`,
                                {
                                    "partner_id": 7,
                                    "level_id": 1,
                                    "lead_id": +(mw_application_id),
                                    "customer_id": credentialState.cust_id,
                                    "verified_by": verified_by,
                                    "verifier_name": verifier_name,
                                    "verification_source": verification_source,
                                    "source": source,
                                },
                                {
                                    token
                                }
                            )
                            // .then((res) => {
                                if (decCustomer.status_code === "200") {
                                    const cut_id = decCustomer.data.id;
                                    const authKey = decCustomer.data.authKey;
                                    let decOrder = await apiInstance
                                        .post(
                                            `pg-create-order`,
                                            {
                                                "partner_id": 7,
                                                "level_id": 1,
                                                "lead_id": +(mw_application_id),
                                                "customer_id": credentialState.cust_id,
                                                "verified_by": verified_by,
                                                "verifier_name": verifier_name,
                                                "verification_source": verification_source,
                                                "mandate_auth_type": ((auth == "1") ? "netbanking" : "debitcard"),
                                                "source": source,
                                            },
                                            {
                                                "token": token,
                                                "log": 1
                                              }
                                        )
                                        // .then((res) => {

                                            if (decOrder.status_code == 200) {
                                                setState(false);
                                                var options = {
                                                    "key": authKey,
                                                    "order_id": decOrder.data.id,
                                                    "customer_id": cut_id,
                                                    "recurring": "1",
                                                    "handler": function (response) {
                                                        window.location.href = red_url;

                                                    },
                                                    "notes": {
                                                        "note_key 1": "",
                                                        "note_key 2": "",
                                                    },
                                                    "theme": {
                                                        "color": "#F37254",
                                                    },
                                                };
                                                var rzp1 = new window.Razorpay(options);
                                                rzp1.on('payment.failed', function (response) {

                                                });
                                                rzp1.open()
                                            }
                                            else {
                                                dispatch(updateFormState({ ...formStates, enashSorry: true, Emandate: false, ReferenceDetails: false }));
                                                navigate("/personal-loan");
                                            }


                                        // });
                                }
                                else {
                                    dispatch(updateFormState({ ...formStates, enashSorry: true, Emandate: false, ReferenceDetails: false }));
                                    navigate("/personal-loan");
                                }
                            // });
                    }
                    else if (pg_method === "Digio") {
                        let decDigioOrder = await apiInstance.post(`digio-create-order`, {
                            "customer_id": credentialState.cust_id,
                            "source": "MWide",
                            "lead_id": mw_application_id,
                            "verified_by": 1,
                            "verifier_name": "System",
                            "verification_source": 1,
                            "partner_id": 1,
                            "level_id": 1
                        }, {
                            "token": token,
                            "log": 1
                          })
                            // .then((res) => {
                                if ((decDigioOrder.status == 1) && (decDigioOrder.status_code == 200)) {
                                    window.sessionStorage.setItem("mw_app_id", mw_application_id);
                                    setState(false);
                                    var options = {
                                        "environment": "production",
                                        "redirect_url": red_url,
                                        "is_redirection_approach": true,
                                        "is_iframe": false,
                                        callback: function (t) {
                                            if (t.error_code != undefined)
                                                alert("failed to register with error :" + t.message);
                                            else alert("register successful for " + t.digio_doc_id);
                                        },
                                        "logo": "https://www.moneywide.com/assets/images/logo.webp",
                                        "dg_preferred_auth_type": ((auth == "1") ? "net_banking" : "debit"), // debit or net_banking // 
                                    };
                                    var digio = new window.Digio(options);
                                    digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
                                    digio.submit(decDigioOrder.data.id, phone);
                                    // Get the documentId by calling Digio upload APIs. Email/Mobile is signer’s identifier
                                    //In case of failure to retrieve DocumentId call digio.cancel();
                                    //   };
                                }
                                else {
                                    dispatch(updateFormState({ ...formStates, enashSorry: true, Emandate: false, ReferenceDetails: false }));
                                    navigate("/personal-loan");
                                }

                            // })
                    }
                }
                else {
                    dispatch(updateFormState({ ...formStates, enashSorry: true, Emandate: false, ReferenceDetails: false }));
                    navigate("/personal-loan");
                }
            // })
    }

    React.useEffect(() => {
        if ((token !== "") && (mw_application_id !== "") && (credentialState.cust_id !== "") && (auth !== "") && (auth !== undefined)) {
            fetchCustomerDetails();

        }
    }, [token, mw_application_id, credentialState, auth])

    return (

        <>
            <Helmet>
                <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
                <script src="https://app.digio.in/sdk/v10/digio.js"></script>
            </Helmet>
            {state ? <CKYCWaiting heading={"we are initiating your enach process."} /> : <div style={{ marginTop: "180px" }}>

            </div>}
        </>
    )
}

export default PartnerMandate;