import React, { useRef, useState } from "react";
import "./blog.css";
import axios from "axios";
import {
  prodbase_blog_url,
  react_base_url,
} from "../Secret_Credentials/Secret_Credential";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../EverCalledFunctions/EverCalledApis";
import { GenerateToken, updateBlogData } from "../../Redux/action";
import BlogCard from "./BlogCard";
import Slider from "react-slick";
import mobile from "../../Icons/BlogMobile.svg";
import bg from "../../Icons/BlogBg.svg";
import blogsearchnew from "../../Icons/blogsearchnew.svg";
import { useNavigate } from "react-router";
import BlogRightSide from "./BlogRightSide";
import Pagination from "./Pagination";
import BlogInnerCard from "./BlogInnerCard";
import { Link, useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { NextArr } from "./NextArr";
import { Previous } from "./Previous";
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import { publicKey, privateKey, encryptWithHybrid, decryptWithHybrid } from "../../Keys";

const BlogMain = () => {
  const { token, blogData } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [change, setChange] = useState(1);
  const [data, setData] = React.useState([]);
  const [dataslider, setDataslider] = React.useState([]);
  const [post, setPost] = React.useState([]);
  const [localBlogData, setLocalBlogData] = React.useState([]);
  const headUrl = useRef("https://blog.moneywide.com/assets/images/blog/");
  const HeadUrl = "https://blog.moneywide.com/assets/images/blog/";
  const [count, setCount] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState([]);
  const [catId, setCatId] = React.useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchData, setSearchData] = React.useState([]);
  const [timer, setTimer] = React.useState("");
  const [showBanner, setShowBanner] = React.useState(true);
  const [arrowCount, setArrowCount] = React.useState(-1);
  const [y, sety] = React.useState(false);
  const pageno = searchParams.get("pageno");
  const [sub, setSub] = React.useState("");
  const navigate = useNavigate();

  var sliderblog = {
    infinite: true,
    autoplay: false,
    cssEase: "ease-in-out",
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    // nextArrow: <NextArr />,
    // prevArrow: <Previous />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  window.addEventListener("scroll", (event) => {
    // console.log(window.scrollY);
    if ((window.scrollY >= 620) && (window.scrollY <= 2900)) {
      sety(true);
    }
    else if (window.scrollY >= 2900) {
      sety(false);
    }
    else {
      sety(false);
    }
  });

  // const HeadUrl = "https://www.moneywide.com/assets/images/blog/";

  // All Functions

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  };

  const category = async () => {

    let payLoad = {
      source: "MWide",
      verifier_name: "System",
      verification_source: 1,
      verified_by: 2,
    }

    let payload = await encryptWithHybrid(JSON.stringify(payLoad));

    axios
      .post(
        `${prodbase_blog_url}fetch-blog-category`,
        payload,
        {
          headers: {
            token,
          },
        }
      )
      .then((res) => {
        let dec_data = decryptWithHybrid(res.data.encryptedMessage, res.data.encryptedAesKey, res.data.iv)
        setData(dec_data.data.category);
        setDataslider(dec_data.data.banner);
        // window.sessionStorage.setItem(
        //   "blog_cat_data",
        //   JSON.stringify(res.data.data.category)
        // );
      });
  };

  const searchBlogByKeyword = (keyword) => {

    let payload = {
      search_word: keyword,
      source: "MWide",
      verifier_name: "System",
      verification_source: 1,
      verified_by: 2,
    }

    payload = encryptWithHybrid(JSON.stringify(payload));

    axios
      .post(
        `${prodbase_blog_url}search-blog-data`,
        // {
        //   search_word: keyword,
        //   source: "MWide",
        //   verifier_name: "System",
        //   verification_source: 1,
        //   verified_by: 2,
        // }
        payload,
        {
          headers: {
            token,
          },
        }
      )
      .then((res) => {
        let dec_data = decryptWithHybrid(res.data.encryptedMessage, res.data.encryptedAesKey, res.data.iv)
        if (dec_data.status == "1" && dec_data.status_code == "200") {
          if (dec_data.data !== {}) {
            setSearchData(dec_data.data);
          }
        } else {
          setSearchData([]);
          setArrowCount(-1);
        }
      });
  };

  const blogCatWise = async (cat_id, pg_no) => {

    let payLoad = {
      "category_id": cat_id,
      "source": "MWide",
      "verifier_name": "System",
      "verification_source": 1,
      "verified_by": 2,
      "page_no": pg_no
    }

    let payload = await encryptWithHybrid(JSON.stringify(payLoad));

    axios.post(`${prodbase_blog_url}fetch-blog-category-wise`,
    payload, {
      headers: {
        token
      }
    }).then((res) => {
      let dec_data = decryptWithHybrid(res.data.encryptedMessage, res.data.encryptedAesKey, res.data.iv)
      if ((dec_data.status == "1") && (dec_data.status_code == "200")) {
        if (dec_data.data?.blog_data) {
          setLocalBlogData(dec_data.data.blog_data);
          for (let i = 0; i <= dec_data.data.total_page + 1; i++) {
            setTotalPages(totalPages => [...totalPages, i]);
          }
        }
        else {
          window.location.href = `${react_base_url}blog`;
        }

      }
    })
  };

  const blogUsingUrl = async (url) => {

    let payLoad = {
      "source": "MWide",
      "url": url,
      "verification_source": 2,
      "verified_by": 2,
      "verifier_name": "system"
    }

    let payload = await encryptWithHybrid(JSON.stringify(payLoad));

    axios.post(`${prodbase_blog_url}fetch-blog-wise`,
    payload, {
      headers: {
        token
      }
    }).then((res) => {
      let dec_data = decryptWithHybrid(res.data.encryptedMessage, res.data.encryptedAesKey, res.data.iv)
      if ((dec_data.status == "1") && (dec_data.status_code == "200")) {
        dispatch(updateBlogData(dec_data.data));
      }
      else {
        window.location.href = `${react_base_url}blog`;
      }

    })
  }

  const handleClick = (e, index) => {
    if (index == 0) {
      window.location.href = `${react_base_url}blog`;
    } else {
      window.location.href = `${react_base_url}blog/${data[index].category_url}`;
    }
    e.stopPropagation();
  };

  const handlePagination = (e, no) => {
    let cur_url = window.location.href;
    let qry = queryString.parse(window.location.search);
    if ((qry.pageno) && (no != "0") && (no != `${totalPages.length - 1}`)) {
      let pgno = qry.pageno;
      cur_url = cur_url.replace(`pageno=${pgno}`, `pageno=${no}`);
      window.location.href = cur_url;
    }
    else {
      if (no == 0) {
        if (pageno) {
          let pg_no = +pageno - 1;
          no = pg_no;
          cur_url = cur_url.replace(`pageno=${pageno}`, `pageno=${no}`);
          window.location.href = cur_url;
        }
        else {
          let pg_no = +pageno - 1;
          no = pg_no;
          window.location.href = `${cur_url}/?pageno=${no}`;
        }
      }
      else if (no == totalPages.length - 1) {
        if (pageno) {
          let pg_no = +pageno + 1;
          no = pg_no;
          cur_url = cur_url.replace(`pageno=${pageno}`, `pageno=${no}`);
          window.location.href = cur_url;
        }
        else {
          no = "2";
          window.location.href = `${cur_url}/?pageno=${no}`;
        }
      }
      else {
        window.location.href = `${cur_url}/?pageno=${no}`;
      }

    }
    e.stopPropagation();
  };

  const handleChange = (e) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          searchBlogByKeyword(e.target.value);
        }, 500)
      );
    } else {
      setTimer(
        setTimeout(() => {
          searchBlogByKeyword(e.target.value);
        }, 500)
      );
    }
    e.stopPropagation();
  };

  const handleSearch = (e, url) => {
    window.location.href = `${react_base_url}blog/${url}`;
    e.stopPropagation();
  };

  const handleKeyPress = (e, no) => {
    if (searchData.length > 0) {
      if (e.keyCode === 38) {
        setArrowCount(arrowCount => arrowCount - no);
      }
      else if (e.keyCode === 40) {
        setArrowCount(arrowCount => arrowCount + no);
      }
      else if (e.keyCode === 13) {
        window.location.href = `${react_base_url}blog/${searchData[arrowCount].url}`
        // blogUsingUrl(searchData[arrowCount].url);
      }
    }
  }

  const closeSearchDiv = (e) => {
    setSearchData([]);
    e.stopPropagation();
  }

  // All UseEffects
  // ----------------Unconditional UseEffect------------------

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, []);

  // ----------------Token dependent UseEffect------------------

  React.useEffect(() => {
    if (token !== "") {
      if (
        window.sessionStorage.getItem("blog_cat_data") &&
        window.sessionStorage.getItem("blog_cat_data") != "undefined" &&
        window.sessionStorage.getItem("blog_cat_data") != "null"
      ) {
        setData(JSON.parse(window.sessionStorage.getItem("blog_cat_data")));
      } else {
        category();
      }
    }
  }, [token]);

  // ----------------Data dependent UseEffect------------------

  React.useEffect(() => {
    if (data.length) {
      let cur_url = window.location.href;
      if (cur_url.includes("blog")) {
        for (let i = 1; i < data.length; i++) {
          if (pageno) {
            if (cur_url.includes(`${data[i].category_url}`)) {
              document
                .getElementById(`${i}-heading`)
                .setAttribute("class", "active-class");
              blogCatWise(i, pageno);
            }
          } else if (
            cur_url === `${react_base_url}blog/${data[i].category_url}`
          ) {
            document
              .getElementById(`${i}-heading`)
              .setAttribute("class", "active-class");
            blogCatWise(i, 1);
          } else {
            // setShowBanner(false);
            setCount((count) => count + 1);
            document
              .getElementById(`${i}-heading`)
              .removeAttribute("class", "active-class");
          }
        }
        if (pageno) {
          cur_url = cur_url.replace(`/?pageno=${pageno}`, "");
          if (`${react_base_url}blog` === cur_url) {
            document
              .getElementById("0-heading")
              .setAttribute("class", "active-class");
            blogCatWise(0, pageno);
          }
        } else if (`${react_base_url}blog` === window.location.href) {
          document
            .getElementById("0-heading")
            .setAttribute("class", "active-class");
          blogCatWise(0, 1);
        }
      }
    }
  }, [data]);

  // ----------------totalPages dependent UseEffect------------------

  React.useEffect(() => {
    if (totalPages.length) {
      if (pageno && pageno != 1) {
        if (pageno == totalPages.length - 2) {
          document.getElementById(`next-btn`).setAttribute("style", "display: none");
        }
        if ((+pageno >= 5) && (totalPages.length > 7)) {
          for (let i = 1; i < +pageno - 2; i++) {
            document.getElementById(`${i}-pagination`).setAttribute("style", "display: none")
          }
          for (let i = +pageno + 3; i < totalPages.length - 1; i++) {
            document.getElementById(`${i}-pagination`).setAttribute("style", "display: none");
          }
        }
        if ((+pageno < 5) && (totalPages.length > 7)) {
          for (let i = 1; i < +pageno - 2; i++) {
            document.getElementById(`${i}-pagination`).setAttribute("style", "display: none")
          }
          for (let i = +pageno + 3; i < totalPages.length - 1; i++) {
            document.getElementById(`${i}-pagination`).setAttribute("style", "display: none");
          }
        }
        document.getElementById(`${pageno}-pagination`).setAttribute("class", "active-pagination");
        document.getElementById("1-pagination").classList.remove("active-pagination");
      }
      else {
        document.getElementById("1-pagination").setAttribute("class", "active-pagination");
        document.getElementById("prev-btn").setAttribute("style", "display: none");
        if (totalPages.length >= 6) {
          for (let i = 6; i < totalPages.length - 1; i++) {
            document.getElementById(`${i}-pagination`).setAttribute("style", "display: none");
          }
        }
        if (totalPages.length == 3) {
          document.getElementById(`next-btn`).setAttribute("style", "display: none");
        }

      }
      // document.getElementById(`${totalPages.length-1}-pagination`).setAttribute("style", "")
      // if(+pageno >= 3) {
      //   let ele = document.querySelector(".blog-card-blog");
      //   ele.setAttribute("style", "top: 9%");
      // }

    }
  }, [totalPages]);

  // ----------------Count dependent UseEffect------------------

  React.useEffect(() => {
    if (data.length) {
      if (!pageno) {
        if (count == data.length - 1) {
          if (window.location.href !== `${react_base_url}blog`) {
            let cd = document.getElementsByClassName("blog-description");
            cd[0].setAttribute("style", "margin-top: 90px");
            setShowBanner(false);
            let url = window.location.href;
            url = url.replace(`${react_base_url}blog/`, "");
            let qry = queryString.parse(window.location.search);
            if (qry) {
              url = url.split("?");
              url = url[0];
              blogUsingUrl(url);
            }
            else {
              blogUsingUrl(url);
            }

          }
        }
      }
    }
  }, [count]);

  React.useEffect(() => {
    if (!showBanner) {
      let virat = document.getElementsByClassName("blog-card-blog");
      virat[0].setAttribute("style", "position:inherit")
    }
  }, [showBanner])

  React.useEffect(() => {
    if (blogData.length) {
      document.getElementById("inner-blog").innerHTML = blogData[0].content;
    }
  }, [blogData]);

  React.useEffect(() => {
    if (arrowCount >= 0) {
      let dynamicEle = document.getElementsByClassName("blog-search-div-inner-main");

      if ((arrowCount >= 0) && (arrowCount < searchData.length)) {
        for (let i = 0; i < searchData.length; i++) {
          dynamicEle[i].classList.remove("additional-css-blog");

        }
        if (arrowCount == 0) {
          dynamicEle[arrowCount].classList.add("additional-css-blog");
          document.querySelector(".blog-search-div").scrollTo(0, 0);
        }
        else {
          dynamicEle[arrowCount].classList.add("additional-css-blog");
        }

        document.getElementById("search-blog").value = searchData[arrowCount].blog_main_heading;
        let pos1 = window.scrollY + dynamicEle[0].getBoundingClientRect().top;
        let pos2 = window.scrollY + document.querySelector(".additional-css-blog").getBoundingClientRect().top;
        let result = Math.round(pos2 - pos1);
        setSub(result);
        if ((result >= 370) && (result > sub)) {
          document.getElementsByClassName("blog-search-div")[0].scrollBy(0, 55);
        }
        else if (result < sub) {
          document.getElementsByClassName("blog-search-div")[0].scrollBy(0, -55);
        }
      }
      else {
        setArrowCount(0);
      }

    }
    else if (searchData.length > 0) {
      setArrowCount(0);
    } else {
      setArrowCount(-1);
    }
  }, [arrowCount]);

  return (
    <>
      {y && <RightSidePopUp />}
      <div style={{ position: "relative" }}>
        {showBanner && (
          <div className="sliderMain">
            <div className="slider-main-head">
              <div className="slider-main-head-first">
                <div className="left-slider-blog-cont">
                  <h3>MoneyWide Blog</h3>
                  <p>Latest and trending financial topics related to Personal Loans, Digital Banking, EPF, Aadhaar, and more.
                    Want to stay at the top of the financial market? Browse our blogs below.</p>
                </div>
              </div>
            </div>
            {/* Blog Head */}

            {/* <Slider {...sliderblog}>
              {dataslider.map((el, idx) => {
                return (
                  <>
                  <a key={`${idx}-blog-main-slider`} style={{textDecoration:"none"}} href={react_base_url + "blog/" + el.url}>
                    <div className="Blog-main-slider">
                      <div className="left-top">
                        <Link to='/'>Home</Link>  &gt;{" "}
                        <span>Blog</span>
                      </div>
                      <div className="sliderContainerLeft">
                        <div  className="sliderContainer">
                          <h2>{el.blog_main_heading}</h2>
                          <p>{el.banner_heading2}</p>
                          <a>{el.button_text}</a>
                        </div>
                      </div>
                      <div className="sliderImage">
                        <img src={`${HeadUrl}${el.banner_image}`} />
                      </div>
                    </div>
                    </a>
                  </>
                )
              })}
            </Slider> */}

          </div>
        )}
        {/* Blog Head End */}

        {/* Search Bar and Category */}
        {data ? (
          <div className="blog-search-new-main">
            <div className="blog-search">
              <div className="blog-search-scroll">
                <div className="blog-category">
                  {data.map((el, index) => {
                    return (
                      <p
                        key={`${index}-heading-p`}
                        id={`${index}-heading`}
                        onClick={(e) => handleClick(e, index)}
                      >
                        {el.category}
                      </p>
                    );
                  })}
                </div>
              </div>

              <div className="blog-search-body">
                <div className="blog-search-main">
                  <img src={blogsearchnew} alt=""/>
                  <input
                    type="text"
                    autoComplete="off"
                    id="search-blog"
                    placeholder="Search by keywords"
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => handleKeyPress(e, 1)}
                  />
                </div>
                {searchData.length > 0 && (
                  <div className="blog-search-div">
                    {searchData &&
                      searchData.map((row, idx) => (
                        <div key={`${idx}-blog-search-div`} className="blog-search-div-inner-main">
                          <p onClick={(e) => handleSearch(e, row.url)}>
                            {row.blog_main_heading}
                          </p>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Search Bar and Category End*/}

        <div className="blog-description" onClick={(e) => closeSearchDiv(e)}>
          {localBlogData.length ? (
            <div className="blog-display-main">
              {localBlogData.map((row, idx) => {
                return (
                  <BlogCard
                    key={`${idx}-blog-index`}
                    imagepath={headUrl.current + row.image_path}
                    heading={row.blog_main_heading}
                    content={row.content}
                    url={react_base_url + "blog/" + row.url}
                    date={row.date}
                    time={row.time_to_read}
                    views={row.views}
                  />
                );
              })}
            </div>
          ) : blogData.length ? (
            <div>
              {
                <BlogInnerCard
                  id={"inner-blog"}
                  blogid={blogData[2]?.blog_id}
                  heading={blogData[0]?.blog_main_heading}
                  time={blogData[0]?.time_to_read}
                  views={blogData[0]?.views}
                  image={headUrl.current + blogData[0]?.main_image_path}
                  content={blogData[0]?.content}
                  author={blogData[1]?.author}
                  bio={blogData[1]?.author_bio}
                  title={blogData[0]?.title}
                  desc={blogData[0]?.description}
                  keyword={blogData[0]?.keyword}
                />
              }
            </div>
          ) : (
            <div></div>
          )}
          {/* RIGHT SIDE DESCRIPTION */}
          <div>
            <BlogRightSide replace={change} />
          </div>
        </div>
        {totalPages ? <div className="pagination-main">{totalPages.map((row, idx) => {
          return (
            <Pagination key={row} id={ idx == 0 ? "prev-btn" : ( idx == totalPages.length - 1 ? "next-btn" : (row + "-pagination"))} pg={(idx == 0) ? "Prev" : ((idx == totalPages.length - 1) ? "Next" : row)} handlePagination={(e) => handlePagination(e, row)} />
          )
        })}</div> : <div></div>}
      </div>
    </>
  );
};

export default BlogMain;
