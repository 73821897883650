import React, { useState,useEffect } from "react";
import StepsliderContentLeft_1 from "./StepsliderContentLeft_1";
import StepsliderContentRight_1 from "./StepsliderContentRight_1";
import "./slider_1.css";
import Step1   from "../assests/Step1.webp";
import Step2   from "../assests/Step2.webp";
import Step3 from "../assests/Step3.webp";
import Step4 from "../assests/Step4.webp";
import Step5 from "../assests/Step5.webp";
function StepSlider_1 () {
    const [step,updateStep]=useState([true,false,false,false,false]);
    const [page,updatePage]=useState(0); 
    


    useEffect(()=>{
        var newStep = Array(5).fill(false);
        newStep[page]=true;
        updateStep(newStep);
    },[page])


    const changeStep=(index)=>{
        updatePage(index);
    }

    const nextPage=()=>{
        if(page<4){
            updatePage(page+1);
        }
    }
    
    const previousPage=()=>{
        if(page>0){
            updatePage(page-1);
        }
    }
    return (
        <section className="howtoapply stepslider">
             <div> <h1>How to Use MoneyWide Personal Loan <b>Eligibility Calculator?</b></h1></div>
			<div className="container">
				<div className="row">
					<div className="col-md-6">
						<div id="stepsbox">
							<StepsliderContentLeft_1 
                                className={`step ${step[0] ? "active-step" : ""}`}
                                onClick={()=>changeStep(0)}
                                id="s1"
                                stepnumber="Step 1"
                                steppera="Calculate Personal Loan Eligibility Online"
                            />
                            <StepsliderContentLeft_1 
                                className={`step ${step[1] ? "active-step" : ""}`}
                                onClick={()=>changeStep(1)}
                                id="s2"
                                stepnumber="Step 2"
                                steppera="Open Personal Loan Eligibility Calculator"
                            />
                            <StepsliderContentLeft_1 
                                className={`step ${step[2] ? "active-step" : ""}`}
                                onClick={()=>changeStep(2)}
                                id="s3"
                                stepnumber="Step 3"
                                steppera="Enter Required Details in the Calculator"
                            />
                            <StepsliderContentLeft_1 
                                className={`step ${step[3] ? "active-step" : ""}`}
                                onClick={()=>changeStep(3)}
                                id="s4"
                                stepnumber="Step 4"
                                steppera="Calculate Personal Loan Eligibility"
                            />
                            <StepsliderContentLeft_1 
                                className={`step ${step[4] ? "active-step" : ""}`}
                                onClick={()=>changeStep(4)}
                                id="s5"
                                stepnumber="Step 5"
                                steppera="Apply for Personal Loan Online"
                            />
						</div>
					</div>
					<div className="col-md-6 content-right">
						<div className="slidestepbox">
							<div id="step-slider">
                                {step[0] && 
                                    <StepsliderContentRight_1 
                                        // id="sr1"
                                        // sheading="Calculate Personal Loan Eligibility Online"
                                        // spera="Visit the MoneyWide website to Calculate Personal Loan eligibility."
                                        simg={Step1}
                                    />
                                }
                                {step[1] && 
                                    <StepsliderContentRight_1 
                                        // id="sr2"
                                        // sheading="Open Personal Loan Eligibility Calculator"
                                        // spera="Click on personal loan and visit Personal Loan Eligibility Calculator."
                                        simg={Step2}
                                    />
                                }
                                {step[2] && 
                                    <StepsliderContentRight_1 
                                        // id="sr3"
                                        // sheading="Enter Required Details in the Calculator"
                                        // spera="Provide details like current residence, date of birth, and net monthly income"
                                        simg={Step3}
                                    />
                                }
                                {step[3] && 
                                    <StepsliderContentRight_1 
                                        id="sr4"
                                        // sheading="Calculate Personal Loan Eligibility"
                                        // spera="Once you enter the required data, you can know the eligible amount for your Personal Loan"
                                        simg={Step4}
                                    />
                                }
                                {step[4] && 
                                    <StepsliderContentRight_1 
                                        // id="sr5"
                                        // sheading="Apply for Personal Loan Online"
                                        // spera="You can apply for the same amount and get instant approval."
                                        simg={Step5}
                                    />
                                }
							</div>
							{/* <div id="step-previous" className={step[0]?"disable":""} onClick={()=>previousPage()}>prev</div>
							<div id="step-next" className={step[4]?"disable":""} onClick={()=>nextPage()}>next</div> */}
						</div>
					</div>
				</div>
			</div>
		</section>	
    );
}
export default StepSlider_1;