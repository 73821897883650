import React, { useEffect, useState } from "react";
import "./Pancardpopup.css";
import Lottie from "react-lottie-player";
import animationData from "../../assests/lootie-files/pan-confirm";
import "../../Loader/Loader.css";
import { useDispatch, useSelector } from "react-redux";
import {
  checkModify,
  GenerateToken,
  updateDecisionDemog,
  updateFormState,
  updateLoginDetails,
  updateCkycDetails
} from "../../../Redux/action";
import DropDownIcon from "../../../Icons/DropDownIcon.svg";
import {
  source,
  verification_source,
  verified_by,
  verifier_name,
} from "../../Secret_Credentials/Secret_Credential";
// import axios from "axios";
import { getToken } from "../../EverCalledFunctions/EverCalledApis";
// import { AxiosThirty } from "../../AxiosConfiguration/AxiosConfig";
import NewErrorCase from "../../Journey Form/Form/All_Forms/NewErrorCase";
import { DecisionDemogOffer } from "../../EverCalledFunctions/EverCalledPayload";
import { gettingerror } from "../../EverCalledFunctions/EverCalledFunctions";
import { apiInstance } from "../../Config/APIConfig";
import ctaAnimation from '../../assests/lootie-files/continue.json';

function PANCARDPopUP() {
  const {
    loginDetails,
    modify,
    formStates,
    credentialState,
    mw_application_id,
    decisionDemog,
    token,
    ckycDetails
  } = useSelector((state) => state);

  const [panData, setPanData] = useState({
    pancard: loginDetails.pan,
    name: loginDetails.name,
    dob: loginDetails.dob,
    gender: loginDetails.gender ? loginDetails.gender : "",
  });
  const [genderOptions, setGenderOptions] = useState({
    Val: [],
    show: false,
    values: {
      value: "",
      id: "",
    },
  });
  const [rocket, setRocket] = useState(false);
  const [resp, setResp] = useState(false);

  const dispatch = useDispatch();
  const [continueNextSection, setcontinueNextSection] = useState(false);

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, [])

  useEffect(() => {
    if (panData.gender === "") {
      setTimeout(async () => {
        let decMaster = await apiInstance
          .post(
            `master`,
            {
              "api_type": "gender",
              "source": source,
              "verification_source": verification_source,
              "verified_by": verified_by,
              "verifier_name": verifier_name,
            },
            {
              "token": token
            }
          )
        // .then((res) => {
        if (decMaster.status_code === "200") {
          setGenderOptions({
            ...genderOptions,
            Val: decMaster.data,
            show: false,
          });
        }
        // console.log(res.data.data);
        // });
      }, 500)
      // });
    }
  }, [panData]);

  
  const EligibleBSA = async () => {
    let decisionDec = await apiInstance.post(`decision-epfo-api`, {
      "customer_id": credentialState.cust_id,
      "product_id": 56,
      "username": loginDetails.name ? loginDetails.name : "",
      "mw_application_id": mw_application_id,
      "mobile_no": loginDetails.mobile ? loginDetails.mobile : "",
      "company_name": loginDetails.company_name ? loginDetails.company_name : "",
      "pancard": loginDetails.pan ? loginDetails.pan : "",
      "source": source,
      "verification_source": verification_source,
      "verified_by": verified_by,
      "verifier_name": verifier_name
    }, {
      "token": token
    })
    // .then((res) => {
    gettingerror('epfo hit web', mw_application_id)
    if ((decisionDec.status == 1) && (decisionDec.status_code == 200)) {
      let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test"
      let offerDec = await apiInstance.post(
        `${url}`,
        {
          "source": source,
          "mw_application_id": mw_application_id,
          "product_id": 56,
          "customer_id": credentialState.cust_id,
          "offer_type": 1,
          "level_id": 1,
          "verified_by": verified_by,
          "verifier_name": verifier_name,
          "verification_source": verification_source,
          "stage_name": "Employment Check"
        },
        {
          "token": token,
          "log": 1
        }
      )
      // .then((res) => {
      // if ((res.data.status == 1) && (res.data.status_code == 200)) {
      if (offerDec.status_code === "200") {

      }
      // }


      // });
    }
    // else {
    //   setState(false);
    //   setRocket(true);
    // }
    // })
  }

  const handleClick = async () => {
    let cur_year = new Date().getFullYear();
    let user_dob = loginDetails.dob;
    user_dob = user_dob.split("-");
    user_dob = +user_dob[0];
    let user_age = cur_year - user_dob;
    // console.log(user_age);
    if (user_age >= 21 && user_age <= 60) {
      const updatedGender = loginDetails.gender ? loginDetails.gender : genderOptions.values.value;
      setResp(true)
      let decConfirmPan = await apiInstance.post(`confirm-pan-submit`, {
        "mw_application_id": mw_application_id,
        "customer_id": credentialState.cust_id,
        "verified_by": verified_by,
        "verification_source": verification_source,
        "verifier_name": verifier_name,
        "source": source,
        "dob": loginDetails.dob,
        "gender": updatedGender === "Male" ? 1 : (updatedGender === "Female" ? 2 : 3),
      }, {
        "token": ""
      })
      // .then((res) => {
      if (decConfirmPan?.data?.income_as_per_rule === "pass") {
        // console.log(loginDetails);
        if(loginDetails?.journeyAPI == 2){
          dispatch(updateLoginDetails({ ...loginDetails, isPan: false }));
          dispatch(updateFormState({ ...formStates, YourProfile: false, EmployeeDetails: false, numberVerification: true }));
        }
       else{
        gettingerror('confirm pan web', mw_application_id)
        const updatedGender = loginDetails.gender ? loginDetails.gender : genderOptions.values.value;
        let decDecisionDemog = await apiInstance
          .post(
            `decision-demog-api`,
            {
              customer_id: credentialState.cust_id,
              product_id: 56,
              gender:
                updatedGender === "Male"
                  ? 1
                  : updatedGender === "Female"
                    ? 2
                    : 3,
              username: loginDetails.name,
              dob: loginDetails.dob,
              mw_application_id: mw_application_id,
              mobile_no: loginDetails.equifax_no ? loginDetails.equifax_no : loginDetails.mobile,
              pincode: loginDetails.pin.toString(),
              city_id: decisionDemog.city_id ? decisionDemog.city_id : "",
              pancard: loginDetails.pan,
              email: loginDetails.email,
              source,
              verification_source,
              verified_by,
              verifier_name,
            },
            {
              "token": token
            }
          )
        // .then((res) => {
        // console.log(res);
        if ((decDecisionDemog.status == 1) && (decDecisionDemog.status_code == 200)) {
          if (decDecisionDemog.data.Response.BUREAU.is_phone_update_required == 1) {
            if (decDecisionDemog.data.Response.BUREAU.hint.includes("|")) {
              let pipe = decDecisionDemog.data.Response.BUREAU.hint.split("|");
              dispatch(updateLoginDetails({ ...loginDetails, equifax_phone_no: pipe, isPan: false }));
              dispatch(updateFormState({ ...formStates, YourProfile: false, EmployeeDetails: false, numberVerification: true }));
            }
            else {
              let pipe = [];
              pipe.push(decDecisionDemog.data.Response.BUREAU.hint)
              dispatch(updateLoginDetails({ ...loginDetails, equifax_phone_no: pipe, isPan: false }));
              dispatch(updateFormState({ ...formStates, YourProfile: false, EmployeeDetails: false, numberVerification: true }));
            }
          }
          else if (decDecisionDemog.data && decDecisionDemog.data.Response && decDecisionDemog.data.Response.BUREAU && (decDecisionDemog.data.Response.BUREAU.is_phone_update_required != 2)) {
            dispatch(
              updateFormState({
                ...formStates,
                YourProfile: false,
                EmployeeDetails: true,
              })
            )
            gettingerror('hit demog web', mw_application_id)
            if (((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))) {
              dispatch(updateDecisionDemog({ ...decisionDemog, is_ntc: decDecisionDemog.data.Response.BUREAU.is_ntc_revised, empverification: decDecisionDemog.data.Response.empverification, pan_status: decDecisionDemog.data.Response.pan_status }));
              // dispatch(updateLoginDetails({ ...loginDetails, isPan: false }));
            }
            else {
              dispatch(updateDecisionDemog({ ...decisionDemog, is_ntc: decDecisionDemog.data.Response.BUREAU.is_ntc_revised, empverification: decDecisionDemog.data.Response.empverification, pan_status: decDecisionDemog.data.Response.pan_status }));
              // dispatch(updateLoginDetails({ ...loginDetails, isPan: false }));
            }
            if (((decDecisionDemog.data.Response.BUREAU.is_ntc_revised == 1) && (decDecisionDemog.data.Response.BUREAU.is_ntc == 1)) || ((decDecisionDemog.data.Response.BUREAU.is_ntc_revised == 0) && (decDecisionDemog.data.Response.BUREAU.is_ntc == 1))) {
              setTimeout(async () => {
                if (((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))) {
                  let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
                  let decOfferTest = await apiInstance.post(`${url}`, DecisionDemogOffer(mw_application_id, "CIBIL Check", credentialState.cust_id), {
                    "token": token,
                    "log": 1
                  })
                  // .then((res) => {
                  if ((decOfferTest.status == 1) && (decOfferTest.status_code == 200)) {
                    dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOfferTest.data.cust_eligible }));
                    if(decOfferTest.data.cust_eligible == 1){
                      EligibleBSA();
                    }
                    gettingerror('cibil check web', mw_application_id)
                  }
                  // })
                }
                else {
                  let decOffer = await apiInstance.post(`offer-eligibility`, DecisionDemogOffer(mw_application_id, "CIBIL Check", credentialState.cust_id), {
                    "token": token,
                    "log": 1
                  })
                  // .then((res) => {
                  if ((decOffer.status == 1) && (decOffer.status_code == 200)) {
                    dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOffer.data.cust_eligible }));
                    if(decOffer.data.cust_eligible == 1){
                      EligibleBSA();
                    }
                  }
                  // })
                }
              }, 10000)
              dispatch(updateLoginDetails({ ...loginDetails, isPan: false }));
            }
            else if ((decDecisionDemog.data.Response.BUREAU.is_ntc_revised == 1) && (decDecisionDemog.data.Response.BUREAU.is_ntc == 0)) {
              setTimeout(async () => {
                if (((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))) {
                  let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
                  let decOfferTest = await apiInstance.post(`${url}`, DecisionDemogOffer(mw_application_id, "CIBIL Check NTC", credentialState.cust_id), {
                    "token": token,
                    "log": 1
                  })
                  // .then((res) => {
                  if ((decOfferTest.status == 1) && (decOfferTest.status_code == 200)) {
                    dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOfferTest.data.cust_eligible }));
                    if(decOfferTest.data.cust_eligible == 1){
                      EligibleBSA();
                    }
                    gettingerror('cibil check NTC web', mw_application_id)
                  }
                  // })
                }
                else {
                  let decOffer = await apiInstance.post(`offer-eligibility`, DecisionDemogOffer(mw_application_id, "CIBIL Check NTC", credentialState.cust_id), {
                    "token": token,
                    "log": 1
                  })
                  // .then((res) => {
                  if ((decOffer.status == 1) && (decOffer.status_code == 200)) {
                    dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOffer.data.cust_eligible }));
                    if(decOffer.data.cust_eligible == 1){
                      EligibleBSA();
                    }
                  }
                  // })
                }
              }, 10000)
              dispatch(updateLoginDetails({ ...loginDetails, isPan: false }));
            }
            else if ((decDecisionDemog.data.Response.BUREAU.is_ntc_revised == 0) && (decDecisionDemog.data.Response.BUREAU.is_ntc == 0)) {
              setTimeout(async () => {
                if (((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))) {
                  let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";
                  let decOfferTest = await apiInstance.post(`${url}`, DecisionDemogOffer(mw_application_id, "Soft Bureau Check", credentialState.cust_id), {
                    "token": token,
                    "log": 1
                  })
                  // .then((res) => {
                  if ((decOfferTest.status == 1) && (decOfferTest.status_code == 200)) {
                    dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOfferTest.data.cust_eligible }));
                    if(decOfferTest.data.cust_eligible == 1){
                      EligibleBSA();
                    }
                    gettingerror('soft bureau web', mw_application_id)
                  }
                  // })
                }
                else {
                  let decOffer = await apiInstance.post(`offer-eligibility`, DecisionDemogOffer(mw_application_id, "Soft Bureau Check", credentialState.cust_id), {
                    "token": token,
                    "log": 1
                  })
                  // .then((res) => {
                  if ((decOffer.status == 1) && (decOffer.status_code == 200)) {
                    dispatch(updateCkycDetails({ ...ckycDetails, cust_eligible: decOffer.data.cust_eligible }));
                    if(decOffer.data.cust_eligible == 1){
                      EligibleBSA();
                    }
                  }
                  // })
                }
              }, 10000)
              dispatch(updateLoginDetails({ ...loginDetails, isPan: false }));
            }
          }

        }

        let decMobileDetection = await apiInstance
          .post(
            `mobile-detection`,
            {
              "customer_id": credentialState.cust_id,
              "product_id": 56,
              "partner_id": 5,
              "level_id": 1,
              "mw_application_id": mw_application_id,
              "source": source,
              "verified_by": verified_by,
              "verifier_name": verifier_name,
              "verification_source": verification_source,
              "mobile_no": loginDetails.mobile,
            },
            {
              "token": token,
              "log": 1
            }
          )
       }
        // .then((res) => {
        // console.log(res);
        // });
        // }).catch((err) => {
        //   setRocket(true);
        // });

      }
      // }).catch((err) => {
      //   setRocket(true);
      // })
    } else {
      setResp(false)
      // console.log(user_age);
    }

    setcontinueNextSection(true);
    
  };
  const handleModify = () => {
    if (modify < 1) {
      dispatch(
        updateFormState({
          ...formStates,
          YourProfile: true,
          EmployeeDetails: false,
        })
      );
      dispatch(checkModify());
      setcontinueNextSection(true);
    }
    dispatch(updateLoginDetails({ ...loginDetails, isPan: false }));
  };

  const openGenderOption = () => {
    setGenderOptions({ ...genderOptions, show: true });
  };

  const ReturnThisValue = (row) => {
    setGenderOptions({ ...genderOptions, show: false, values: row });
  };

  const handleError = (e) => {
    window.location.reload();
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      <div
        className={continueNextSection ? "loaderbackground remove-block" : "loaderbackground"}
      >
        <div className="loadertransparent">
          <div className="pancardpopup login-area-popup">
            <div className="pancardcontent">
              <h2>Please confirm your details as per PAN record</h2>
              <Lottie
                loop
                animationData={animationData}
                play
                style={{
                  width: "50%",
                  marginLeft: "26%",
                  marginTop: "-5%",
                  marginBottom: "-30px",
                }}
              />
              <div className="Pandetails">

                <div className="pancarddata">
                  <p className="pan-name">PAN Number :</p>
                  <span className="pan-content">{panData.pancard}</span>
                </div>

                <div className="pancarddata">
                  <p className="pan-name">Name :</p>
                  <span className="pan-content">{panData.name}</span>
                </div>

                <div className="pancarddata">
                  <p className="pan-name">Date Of Birth :</p>
                  <span className="pan-content">{panData.dob}</span>
                </div>

                <div className="pancarddata">
                  <p className="pan-name">Gender :</p>
                  {panData.gender && (
                    <span className="pan-content">{panData.gender}</span>
                  )}
                  {!panData.gender && (
                    <div className="input-tag add-border pan-input">
                      <input
                        type="text"
                        style={{ cursor: "pointer" }}
                        value={genderOptions.values.value}
                        readOnly
                        placeholder="Select Gender"
                        onClick={() => openGenderOption()}
                      />
                      <img
                        src={DropDownIcon}
                        style={{ cursor: "pointer" }}
                        onClick={() => openGenderOption()}
                        className="small-the-icon"
                        alt=""
                      />
                      {genderOptions.show && (
                        <div className="drop-option-box ">
                          {genderOptions.Val.map((row, ind) => {
                            return (
                              <div
                                key={ind}
                                className="drop-option"
                                onClick={() => ReturnThisValue(row)}
                              >
                                {row.value}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="pancard-button">
                {modify < 1 && (
                  <button onClick={() => handleModify()} className="modifybuton">
                    Modify PAN
                  </button>
                )}
                <button
                disabled={resp ? true : false}
                  onClick={() => handleClick()} style={{ position: "relative" }}
                  className={panData.gender || genderOptions.values.value ? "contbuton activate-ctn-btn" : "contbuton"}
                >
                  {!resp ?
                    "Confirm" :
                    <div className="continue-lottie">
                      <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                    </div>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default PANCARDPopUP;