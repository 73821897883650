import React, { useEffect, useState } from 'react'
import "./Privacy.css";
import "../footermorelinkspages.css";
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { prodbase_url, source, verification_source, verified_by, verifier_name } from '../Secret_Credentials/Secret_Credential';
import { getToken } from '../EverCalledFunctions/EverCalledApis';
import { GenerateToken } from '../../Redux/action';
import translatelang from "../../Icons/translatelang.svg";
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';

const Privacypolicy = () => {
  const [y, sety] = React.useState(true);
  const { App_status, token } = useSelector(state => state);
  const dispatch = useDispatch();
  const [functionValues, setFunctionValues] = useState([])
  const [value, setValue] = useState([]);
  const [getid, setGetid] = useState(1);
  const [loader, setLoader] = useState(true);
  window.addEventListener("scroll", (event) => {
    if (window.scrollY >= 3168) {
      sety(false);
    }
    else {
      sety(true);
    }
  });

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  useEffect(() => {
    if (token == "") {
      myToken();
    }
  }, [])


  useEffect(() => {
    if (token !== "") {
      axios
        .post(
          `${prodbase_url}master`,
          {
            source,
            api_type: "language",
            verification_source: verification_source,
            verified_by: verified_by,
            verifier_name: "system"
          },
          {
            headers: {
              token
            },

          }
        )
        .then((res) => {
          // console.log(res)
          if ((res.data.status == 1) && (res.data.status_code == 200)) {
            setFunctionValues(res.data.data);
          }

        })
    }
  }, [token])

  useEffect(() => {
    if (token !== "") {
      axios.post(`${prodbase_url}web/terms-privacy`, {
        source,
        "type_terms_privacy": "1",
        "language": getid.toString(),
        verification_source,
        verified_by,
        verifier_name
      }, {
        headers: {
          token
        }
      }).then((res) => {
        setLoader(false)
        if ((res.data.status == 1) && (res.data.status_code == 200)) {
          setValue(res.data.data)
        }
      })
    }
  }, [token, getid])

  useEffect(() => {
    if (value.length !== 0) {
      const container = document.getElementById('content-container-privacy');
      container.innerHTML = value[0].content;
    }
  }, [value])

  const handleSelect = (event) => {
    // console.log(event.id);
    const selectedRow = JSON.parse(event.target.value);
    setGetid(selectedRow.id)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      {y && !App_status && <RightSidePopUp />}
      {/* <div><div  className={App_status ? 'policymain-container-head-app policymain-container-head' : 'policymain-container-head'}> <h2 className='sachhead'>Privacy Policy</h2></div></div> */}
      {/* <div className='sachmain'>
     <b><h2 style={{color:"black",fontSize:"32px"}}>Introduction</h2></b>
     <p className='sachp'>MoneyWide (hereinafter referred to as “MoneyWide” or we/ our and including&nbsp;www.moneywide.com&nbsp;and MoneyWide mobile app) understands the privacy and confidentiality of its customers who have shared their personal information with us. It’s our topmost priority to keep the information secure and avoid misuse of customer’s personal details. </p>
     <p className='sachp'>Please find below the type of information we may collect from you and its usage for the services offered to you via our platform, our lending partners  and our business partners. This privacy policy is applicable to our current and existing and future customers. By visiting on MoneyWide  and registering  with us, you agree to this privacy policy.</p>
     <p className='sachp'>By registering online at MoneyWide or using our mobile app, you allow MoneyWide, its lending partners and its business partners to get in touch with you via phone call, email or sms. MoneyWide collects personal information from its customers including first name, last name, email address, date  of  birth,  residence city and mobile number and other details as is required to check your eligibility for a loan, assess your credit worthiness, comply with KYC and AML guidelines of the lending partners and to ensure that there is  no  fraud  or fraudulent activity involved. MoneyWide and/or its NBFC and other partners can and may, subject to applicable law, also extract information from third parties or public shared profiles which are available in your social accounts and with data registries such as but not limited to NSDL, GSTIN, banks, EPFO, UIDAI, credit bureaus, other lenders, participants in Account Aggregator ecosystem, anti-fraud databases, anti-money laundering databases,  politically  exposed  persons list and others. The collected information is stored at MoneyWide only and may be shared with third parties subject to your consent and with regulators as required under law or under instructions from appropriate authority under law.</p>
     <p className='sachp'><b>THE DATA WE COLLECT ABOUT YOU</b></p>
     <p className='sachp'>We collect, use, store, and transfer your personal data in connection with, the Services. Such personal data includes:</p>
     <b style={{color:"#18375f"}}>Personal Information</b>
     <p className='sachp'><b>a. Identity, Employment, KYC and Financial data :</b>  MoneyWide collects personal information from its customers, including but not limited to name, email address, date of birth, residence city, pin code, employer details, PAN, Aadhaar number, EPFO UAN number, bank details, bank statements, references, income details and mobile number etc. MoneyWide may also extract, subject to applicable law, information from credit bureaus, government/ public databases such as but not limited to NSDL, GSTIN, banks, EPFO, UIDAI, credit bureaus, other lenders, participants in Account Aggregator ecosystem, anti-fraud databases, anti-money laundering databases, politically exposed persons list and others and from third parties or public shared profiles in your social media accounts.We neither collect nor store your biometric information.</p>
     <p className='sachp'><b>b. Location Permission :</b> To make your loan application serviceable, to lower the risk involved with it, and to provide pre-approved, customised loan offers, we need information about the location of your device. Additionally, this helps us verify addresses, assess credit risks more accurately, and quicken the KYC process.</p>
     <p className='sachp'><b>c. Camera Permission :</b> We need camera access so you can easily scan or capture required kyc documents and save time by allowing us to auto-fill relevant data or initiate an audio/video call for kyc purposes. This ensures that you have a consistent experience while using the application.</p>
     <p className='sachp'><b>d. Microphone :</b> Our app requires microphone permissions as a critical component of the KYC process to enable two-way audio communication between you and our verification team. This real-time interaction allows us to effectively confirm your identity and ensure the accuracy of the information you've provided. In accordance with applicable laws and our data protection policies, the recorded audio communication may be saved and stored for future verification purposes in order to maintain a high level of security and compliance.</p>
     <p className='sachp'><b>e. Storage :</b> Our app includes a feature that enables users to upload proof of residency as part of the loan application process. This ensures that we can verify the user's residency information and provide a seamless loan application experience.</p>
     <p className='sachp'><b>f. Read Phone State :</b> To access device-specific information such as your phone number, device ID, and cellular network information, our app requires phone state permission. This data assists us in providing you with a consistent user experience and improving the overall functionality of our app. This information may be used to troubleshoot problems, verify your identity, and provide personalised support.
</p>
<p className='sachp'><b>g. Collection of SMS Data :</b> After obtaining your consent, we access your phone's SMS inbox to extract relevant financial information. This may include transaction details from banks, credit card providers, digital wallets, and other financial institutions.
</p>
<p className='sachp'><b>h. Transmission of Data :</b> The gathered SMS data is securely transmitted to our MoneyWide server using encryption to protect your privacy and ensure data integrity during the transfer process.
</p>
<p className='sachp'><b>i.  Analysis of Financial Situation :</b> Our proprietary algorithms analyze your SMS data to identify patterns and trends in your cash flow, credit history, income, and spending habits. This enables us to provide you with a comprehensive financial overview and personalized recommendations.
</p>
     <p className='sachp'><b>j. Marketing and communications data :</b> This includes your preferences in receiving marketing messages from us and our third parties.</p>
     <p className='sachp'><b>k. Device data :</b> This includes storing your IP addresses, browser versions, and your mobile device information, including a unique identifier for your device.</p>
     <p className='sachp'><b>l. Usage data :</b> We extract information about how you use the Services.</p>
     <p className='sachp'><b>m. Permissions :</b> The permissions include access to your photos, media, and files during the KYC process. You are also asked for various permissions including record audio, video and phone calls.</p>
     <p className='sachp'><b>n. App data :</b> We collect information from the customers' devices from the date they register on our App or website.</p>
     <p className='sachp'>We may also receive and store any information you enter on our Website or give us in any other way. You have the option to choose not to provide us certain information and this may impact your ability to avail our product and services and use of various features on our Website.</p>
     <p className='sachp'>We may also receive information about you from other sources and add it to our account information.</p>
     <b>Purpose</b>
     <p className='sachp'>MoneyWide collects information from you when you register online with us or need some information regarding our products and services, you visit our website and apply for a loan or service. We also collect specific information as part of the loan underwriting process pursuant to your specific consent, which you may choose not to provide. After registering with us or sharing personal details with us, you authorize us to contact you via email, call or sms and send you promotional offers.</p>

     <b style={{color:"#18375f"}}>HOW WE COLLECT DATA ABOUT YOU ?</b>
     <p className='sachp'>We use different methods to collect and process personal data about you. This includes:</p>
     <ol type='a'>
    <li style={{margin:"20px 0px"}}><b>Information you provide us:</b>At the time of using our services, you consent to provide us the information (including your identity, KYC, financial, and device data) or the information you provide while interacting with us through either email, or App. The information includes your details that you provide at the time of onboarding, share data through our App, or when you report a problem with us. If you contact us through email or call, we will store the information shared during the correspondence.</li>
    <li><b>Information we collect about you and your device:</b>We will also collect personal data through the use of tools like cookies and obtain certain types of information when your web browser accesses the Website or advertisements and other content served by or on behalf of the Website and/ or on other websites.</li>
     </ol>
     <p className='sachp'><b>HOW WE USE YOUR PERSONAL DATA AND FOR WHAT PURPOSE ?</b></p>
     <p className='sachp'>MoneyWide needs your personal information for the following purposes –</p>
       <ul className='sach'>
       <li className='sachin'>to comply with statutory and other requirements under law with respect to KYC and AML as per the KYC and AML policy of our lending partners</li>
       <li className='sachin'>to comply with loan policy of our lending partners when you apply for or avail a loan from or through us to assess your creditworthiness for our loans and services</li>
       <li className='sachin'>to reply to your queries being submitted by you</li>
       <li className='sachin'>process applications submitted by you</li>
       <li className='sachin'>to resolve problems with any services provided to you</li>
       <li className='sachin'>to send you promotional offers, new products or services available on our website or third parties with whom we have a tie up</li>
       <li className='sachin'>manage or achieve our duties with subject to any contract with our business partners</li>
       <li className='sachin'>to make our website and services better</li>
       <li className='sachin'>to send out communication regarding the payment and repayment on your loans or any other charges</li>
       <li className='sachin'>in case of borrowers, with our internal collections team and authorized collections agency partners for the purpose of collection of your dues to us or our lending partners or co-lenders, whose list is available on our website</li>
       <li className='sachin'>to help investigate, prevent or take action regarding unlawful and illegal activities; suspected fraud, potential threat to the safety or security of any person, violations of MoneyWide's Terms & Conditions, or as defence against legal claims;</li>
       <li className='sachin'>provide information in case of special circumstances such as compliance with court orders, requests/order, notices from legal authorities or law enforcement agencies which require us to make such disclosure; and</li>
       <li className='sachin'>Other use cases as mentioned in this privacy policy</li>
       </ul>
       <b style={{color:"#18375f"}}>Controller of Personal Information</b>
       <p className='sachp'>Your Personal Information will be collected by and / or stored by MLC Finotech Private Limited including its authorized representatives, affiliates and business partners in its capacity as loan service provider and also by the respective lending partners with whom it is shared as per your consent pursuant to the terms of this policy.</p>
       <b style={{color:"#18375f"}}>HOW WE SHARE YOUR PERSONAL DATA ?</b>
       <p className='sachp'>MoneyWide does not sell/ rent or disclose your personal information with anyone as described in this policy.</p>
       <p className='sachp'>a. You agree and acknowledge that we may share your personal information with our business partners or with another entity in case of business merger or sale of MoneyWide's business. We restrict the collection and use of your personal information. We may disclose your personal information in a non-personally identifiable manner that does not reveal your identity. MoneyWide does not let anyone use your account information and any other personal identifiable information except some specific domestic procedures in order to manage, expand and improve our services. Third parties, including LSP’s and collection agencies, who have tie up with us can contact you for specific purposes and tell you about our services but with a limited access and they are required to maintain the confidentiality of the information as mentioned in the contract with us.</p>
       <p className='sachp'>b. You agree and acknowledge that we may share your personal information to the government agencies under the law in case of identity verification, investigation of cyber incidents, hearings and punishment of offences, or where disclosure of information is required for the legal purpose. We may also need to disclose the information to third parties under the law. MoneyWide has various types of products like loans, credit cards offered by authorized lenders, banks, LSPs and DLAs. If you apply for these products or services and while availing these, we disclose your information to these providers and thereafter the use of your information is dependent on their privacy policies for which MoneyWide is not responsible.</p>
       <b style={{color:"#18375f"}}>Tracking via Cookies </b>
       <p className='sachp'>MoneyWide and its partners use cookies to analyze trends, manage the website, improve marketing, track user’s preferences around the website and collect demographic figures for overall use. You can control the use of cookies at an individual browser stage by disabling these cookies that may restrict your use of certain features or services on our website.</p>
       <b style={{color:"#18375f"}}>Log Files </b>
       <p className='sachp'>We use log files that include internet protocol (IP) address, internet service provider (ISP), device details, browsing history including browser type, exit or referred pages, date/time stamp, operating system to analyze trends and user’s movements while visiting the website. This information is combined with the other information we collect about you to improve our services, site functionality and marketing.</p>
       <b style={{color:"#18375f"}}>Unsubscribe and Revocation of Consent</b>
       <p className='sachp'>In case you feel that we do not require the retention of your personal information or in case you ask us to delete or remove your personal data where you think we do not have the right to process it, subject to this policy, we shall destroy or delete such Customer information. You may, at any time while availing of our services or otherwise, withdraw the consent given earlier to us to collect and use your sensitive personal data or information by writing to us at care@moneywide.com. Alternatively you may use unsubscribe link available at the bottom of our page. However, in case of your withdrawing such consent, MoneyWide shall have the option to stop providing you the services for which the information was sought. You will not be eligible for refund of any fees paid for any service in such event and you agree that the Website shall not be liable to you for the same in any manner whatsoever.</p>
       <p className='sachp'><b>Option to Unsubscribe:</b> The consent provided by you is effective until you unsubscribe or discontinue all use of our service. An “unsubscribe” facility is provided on the Website to provide you with an option to unsubscribe from our services relating to the products opted by you. By selecting it, we will discontinue to provide you with our services related to your products opted through MoneyWide and you shall not receive future services on such products and you will be able to view only historic information, if any, “as is” related to your products opted on MoneyWide account section of “My Account”. It may take up to 10 working days for us to process the request but, subject to provisions of this policy and applicable law, once you are unsubscribed by us, you will not receive emails or any other communication from us unless you opt in for the same or register with us again.</p>
       <p className='sachp'><b>Deletion of Information:</b> If you wish to delete your account or personal information, please send an email request to us care@moneywide.com for deletion of your information. On receiving your request for deletion, we will erase your data, discontinue to send you any further communications in context to the products opted by you. Please note that we or our Partners would not be able to provide you services relating to products opted by you in case your information is not available with us.</p>
       <p className='sachp'><b style={{fontFamily:"poppinssemibold"}}>PLEASE NOTE THAT WHERE YOU HAVE A LIVE PRODUCT OR SERVICE WITH US OR WHERE IT IS REQUIRED UNDER ANY APPLICABLE LAWS, WE MAY NOT BE ABLE TO DELETE ANY OR ALL OF YOUR INFORMATION AS THE SAME MAY BE REQUIRED BY US OR OUR BUSINESS PARTNERS IN CASE YOU REQUIRE ANY ASSISTANCE WITH RESPECT TO THAT PRODUCT/ SERVICE IN FUTURE OR FOR COMPLIANCE WITH THE APPLICABLE LAWS. WE ASSURE THAT SUCH RETAINED INFORMATION SHALL BE STORED FOLLOWING APPLICABLE CYBER SECURUTY NORMS. OPT-OUT DOES NOT ERASE INFORMATION THAT IS REQUIRED TO BE RETAINED BY US FOR COMPLYING WITH REGULATORY REQUIREMENTS INCLUDING BUT NOT LIMITED TO KYC, AML, FRAUD PREVENTION, AND CREDIT ASSESSMENT MEMO REQUIREMENTS. OPT-OUT ALSO DOES NOT ERASE INFORMATION OF PAST OR CURRENT LOANS OR SERVICES THAT YOU MAY HAVE AVAILED FROM US, FOR WHICH WE FOLLOW DATA RETENTION PROTOCOLS AS REQUIRED AND AS PERMISSIBLE UNDER LAW.</b></p>
       <b>Retention of Information</b>
       <p className='sachp'>MoneyWide may retain your Information, including Personal Information, as the same is required to provide our services to you and for as long as it is required for business purpose. Retention of Information is done as per this policy, subject to compliance with applicable law/regulatory requirements in India. In following situation, MoneyWide may retain your Information for an extended period (i) in case of requirement of any investigations under law or as part of any requirement before Courts/ Tribunals/ Forums/ Commissions etc. and (ii) to enhance / improvise our products /services.</p>

       <b style={{color:"#18375f"}}>Data Security</b>
       <p className='sachp'>MoneyWide uses suitable technical and electronic measures to protect the personal information being submitted to it. The information provided by the User is stored in access-controlled facilities with restricted access. User Information transmitted over the internet is protected using encryption, using the Secure Socket Layer (SSL) or equivalent protocols. We use commercially acceptable means as per industry standards to protect user information. However, in today’s time, one cannot guarantee complete security as no electronic system is 100% secure even though we use multiple security measures to protect illegal use, damage, or loss of any kind of information. Further, we cannot warrant that such User information may not be misused in the event our safeguards and protocols are breached by a malicious third-party. Further, we are not liable to nor can we fully control the actions of other users with whom you may choose to share your information. You must also maintain the confidentiality and security of your login id and password and not share these credentials with any third party.</p>
       <p className='sachp'>The collection, usage, and sharing of User information by MoneyWide shall be in compliance with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 and other applicable laws. All our customer data is stored in servers in India. </p>
       <b style={{color:"#18375f"}}>Connection to Other Websites </b>
       <p className='sachp'>MoneyWide provides links to other websites. The privacy of the information provided to other websites is not our liability. MoneyWide is not responsible for any loss of personal information through other sources. We can only suggest you read their privacy policies when you visit their websites.</p>
       <b style={{color:"#18375f"}}>Third Party Advertising </b>
       <p className='sachp'>MoneyWide uses ad agencies or third-party advertising companies to provide ads when you are using our website. These companies do not use your personal information except the details about your visit to our website to offer ads on our websites as well as on other websites about the services that you may like.</p>
       <b style={{color:"#18375f"}}>Link to Third Party Websites</b>
       <p className='sachp'>Our Services may contain services, from time to time, provided by or links to and from the websites of our partner networks, service providers, financial institutions, advertisers, and affiliates (“Third Party Services”). Please note that the services we may use of Third Parties are governed by their own privacy policies. We do not hold any responsibility or liability for the policies or for any personal data that may  be  collected through such Third Party Services. Please check their policies before you submit any personal data to such  websites or use their services. </p>
       <b style={{color:"#18375f"}}>Contact Us with Questions or Concerns or For Grievance Redressal </b>
       <p className='sachp'>If you have grievance or complaint, questions, comments, concerns or feedback in relation to the processing of information or regarding this Privacy and Security Policy or any other privacy or security concern, send an e-mail to&nbsp;care@moneywide.com</p>
       <b style={{color:"#18375f"}}>Name and Contact details of the Grievance Redressal Officer of the Company </b>
       <table className='sachtable'>
             <thead>
                    <tr  >
                    <th className='sachth'>Name of the Officer</th>
                    <th className='sachth'>Office Address</th>
                    <th className='sachth'>Email id</th>
                    </tr></thead>
            <tbody>
                    <tr>
                    <td className='sachtd'><b style={{color:"#18375f"}}>Mr. Anjali Anand </b></td>
                    <td className='sachtd'>Plot no. 131, Ground Floor, Sector-44, Gurgaon-122001</td>
                    <td className='sachtd'>gro@moneywide.com</td>
                    </tr>                          
            </tbody>
        </table>
        <br/>
        <b style={{color:"#18375f"}}>Change in Privacy Policy</b>
        <p className='sachp'>MoneyWide reserves the right to change its privacy policy from time to time. The same will be updated on the website on the date the changes have been made from.</p>
        <b style={{color:"#18375f"}}>Notice</b>
        <p className='sachp'>The effective date of this Policy, as stated below, indicates the last time this Policy was revised or materially changed.</p>
        <b style={{color:"#18375f"}}>Effective Date</b>
        <p className='sachp'>29th October 2022</p>

     </div> */}
      <div>
        {
          loader ? <CKYCWaiting/> :
          <div className="privacy-lang">
            <div className="translate-select translate-area">
              <img src={translatelang} alt='lang' />
              <select className='' onChange={handleSelect}>
                {functionValues && functionValues.length !== 0 && functionValues.map((row, ind) => {
                  return <option key={row.id} value={JSON.stringify(row)}>
                    {row.value}
                  </option>
                })}
              </select>
              {/* <img src={DropDownIcon} alt='drop'/> */}
            </div>
            <div className='sachmain trans-main-lang' id='content-container-privacy'>
            </div>
          </div>
        }
      </div>
    </>

  )
}

export default Privacypolicy