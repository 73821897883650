import React from 'react'
import { useSelector } from 'react-redux';
import './privacyapp.css'

function PrivacyPolicyApp() {
    const {App_status} = useSelector(state => state);
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <>
            <div className={App_status ? "pp-head-app pp-app-head" : "pp-app-head"}>
                    <span>Privacy Policy</span>
            </div>
            <div className="privacyapp-bg">
                <div className="pp-app-main-box">
                    <div className="pp-intro">
                        <span>INTRODUCTION</span>
                        <p>MoneyWide (hereinafter referred to as “MoneyWide” including our MoneyWide mobile app) understands the privacy and confidentiality of its customers who have shared their personal information with us. It’s our topmost priority to keep the information secure and avoid any misuse of customer’s personal details.</p>
                    </div>
                    <div className="pp-data">
                        <span>DATA WE COLLECT ABOUT YOU</span>
                        <p>We collect, use, store and transfer your personal data in connection with the services. Such personal data includes:</p>
                    </div>
                    <div className="pp-contact">
                    <span><b>Personal Details:</b></span><span> This include personal details refer to the collection of information such as name, email address, postal code, and mobile number. These are used for communication purposes and to address any queries.</span>
                    </div>
                    <div className="pp-contact">
                        <span><b>Device Data:</b></span><span> This includes storing your IP address, browser versions and your mobile device information, including a unique identifier for your device.</span>
                    </div>
                    <div className="pp-contact">
                        <span><b>App Data:</b></span><span> We collect information from the customer’s devices from the date they register on our App. We may also receive or store any information you provide on our mobile app. You have the option to choose not to provide us certain information and this may impact your ability to avail our product and services and use of various features on our mobile app.</span>
                    </div>
                    <div className="pp-data">
                        <span>Purpose</span>
                        <p>MoneyWide collects information from you when you register with us, need information regarding our products or services, or visit our mobile app & apply for a loan.</p>
                    </div>
                    <div className="pp-methods">
                        <p>We use different methods to collect and process personal data about you. This includes:</p>
                    </div>
                    <div className="pp-order-list">
                        <ol type="a">
                            <li><b>Information you share with us:</b> At the time of using our services, you consent to provide us your contact number. </li>

                            <li><b>Information we collect about you and your device:</b> We will also collect personal data through the use of tools like cookies and obtain certain types of information when you use our mobile app. </li>

                            <li><b>Information we receive from other sources:</b> We will collect personal data through various third parties both private and public sources including our third parties, google analytics for user analytics purposes and analytics, and other publicly available sources.</li>
                        </ol>
                    </div>
                    <div className="pp-how-to-use">
                        <span>HOW WE USE YOUR PERSONAL DATA AND FOR WHAT PURPOSE ?</span>
                        <p>MoneyWide needs your personal information for the following purposes -</p>
                    </div>
                    <div className="pp-unorder-list">
                        <ul className="list-icon-more">
                            <li>To reply to your queries being submitted by you.</li>
                            <li>Process applications submitted by you.</li>
                            <li>To resolve problems with any services provided to you.</li>
                            <li>To send you promotional offers, new products or services available on our app or third parties with whom we have a tie up.</li>
                            <li>Manage or achieve our duties subject to any contract with our business partners.</li>
                            <li>To make our app and services better.</li>
                            <li>To send out communication regarding the payment and repayment on your loans or any other charges.</li>
                            <li>In case of borrowers, with our internal collections team and authorized collections agency partners for the purpose of collection of your dues to us or our lending partners or co-lenders, whose list is available on our app.</li>
                            <li>To help investigate, prevent or take action regarding unlawful and illegal activities; suspected fraud, potential threat to the safety or security of any person, violations of MoneyWide's Terms &amp; Conditions, or as defence against legal claims;</li>
                            <li>Provide information in case of special circumstances such as compliance with court orders, requests/order, notices from legal authorities or law enforcement agencies which require us to make such disclosure; and</li>
                            <li>Other use cases as mentioned in this privacy policy.</li>
                        </ul>
                    </div>
                    <div className="pp-data">
                        <span>Controller of Personal Information</span>
                        <p>Your Personal Information will be collected by and / or stored by MyLoanCare Ventures Private Limited including its authorized representatives, affiliates and business partners.</p>
                    </div>
                    <div className="pp-data">
                        <span>Tracking via Cookies:</span>
                        <p>MoneyWide and its partners use cookies to analyze trends, manage the app, improve marketing, track user's preferences around the app and collect demographic figures for overall use. You can control the use of cookies at an individual browser stage by disabling these cookies that may restrict your use of certain features or services on our app.</p>
                    </div>
                    <div className="pp-data">
                        <span>Log Files</span>
                        <p>We use log files that include internet protocol (IP) address, internet service provider (ISP),device details, browsing history including browser type, exit or referred pages, date/time stamp, operating system to analyze trends and user’s movements while visiting the app. This information is combined with the other information we collect about you to improve our services, site functionality and marketing.</p>
                    </div>
                    <div className="pp-data">
                        <span>Unsubscribe and Revocation of Consent</span>
                        <p>In case you feel that we do not require the retention of your personal information or in case you ask us to delete or remove your personal data where you think we do not have the right to process it, subject to this policy, we shall destroy or delete such Customer information. You may, at any time while availing of our services or otherwise, withdraw the consent given earlier to us to collect and use your sensitive personal data or information by writing to us at care@moneywide.com. Alternatively, you may use the unsubscribe link available at the bottom of our page. However, in case of your withdrawing such consent, MoneyWide shall have the option to stop providing you the services for which the information was sought. You will not be eligible for refund of any fees paid for any service in such event and you agree that the app shall not be liable to you for the same in any manner whatsoever.</p>
                    </div>
                    <div className="pp-data">
                        <span>Option to Unsubscribe:</span>
                        <p>The consent provided by you is effective until you unsubscribe or discontinue all use of our service. An “unsubscribe” facility is provided on the app to provide you with an option to unsubscribe from our services relating to the products opted by you. By selecting it, we will discontinue to provide you with our services related to your products opted through MoneyWide and you shall not receive future services on such products and you will be able to view only historic information, if any, “as is” related to your products opted on MoneyWide account section of “My Account”. It may take up to 10 working days for us to process the request but, subject to provisions of this policy and applicable law, once you are unsubscribed by us, you will not receive emails or any other communication from us unless you opt in for the same or register with us again.</p>
                    </div>
                    <div className="pp-data">
                        <span>Deletion of Information:</span>
                        <p>If you wish to delete your account or personal information, please send an email request to us care@moneywide.com for deletion of your information. On receiving your request for deletion, we will erase your data, discontinue to send you any further communications in context to the products opted by you. Please note that we or our Partners would not be able to provide you services relating to products opted by you in case your information is not available with us.</p>
                    </div>
                    <div className="pp-please-note">
                        <p>PLEASE NOTE THAT WHERE YOU HAVE A LIVE PRODUCT OR SERVICE WITH US OR WHERE IT IS REQUIRED UNDER ANY APPLICABLE LAWS, WE MAY NOT BE ABLE TO DELETE ANY OR ALL OF YOUR INFORMATION AS THE SAME MAY BE REQUIRED BY US OR OUR BUSINESS PARTNERS IN CASE YOU REQUIRE ANY ASSISTANCE WITH RESPECT TO THAT PRODUCT/ SERVICE IN FUTURE OR FOR COMPLIANCE WITH THE APPLICABLE LAWS. WE ASSURE THAT SUCH RETAINED INFORMATION SHALL BE STORED FOLLOWING APPLICABLE CYBER SECURITY NORMS. OPT-OUT DOES NOT ERASE INFORMATION THAT IS REQUIRED TO BE RETAINED BY US FOR COMPLYING WITH REGULATORY REQUIREMENTS INCLUDING BUT NOT LIMITED TO KYC, AML, FRAUD PREVENTION, AND CREDIT ASSESSMENT MEMO REQUIREMENTS. OPT-OUT ALSO DOES NOT ERASE INFORMATION OF PAST OR CURRENT LOANS OR SERVICES THAT YOU MAY HAVE AVAILED FROM US, FOR WHICH WE FOLLOW DATA RETENTION PROTOCOLS AS REQUIRED AND AS PERMISSIBLE UNDER LAW.</p>
                    </div>
                    <div className="pp-data">
                        <span>Retention of Information</span>
                        <p>MoneyWide may retain your Information, including Personal Information, as the same is required to provide our services to you and for as long as it is required for business purposes.Retention of Information is done as per this policy, subject to compliance with applicablelaw/regulatory requirements in India. In following situation, MoneyWide may retain your Information for an extended period (i) in case of requirement of any investigations under law or as part of any requirement before Courts/ Tribunals/ Forums/ Commissions etc. and (ii) to enhance / improvise our products /services.</p>
                    </div>
                    <div className="pp-data">
                        <span>Data Security</span>
                        <p>MoneyWide uses suitable technical and electronic measures to protect the personal information being submitted to it. The information provided by you is stored in access-controlled facilities with restricted access. User Information transmitted over the internet is protected using encryption, using the Secure Socket Layer (SSL) or equivalent protocols. We use commercially acceptable means as per industry standards to protect your user information. However, in today's time, one cannot guarantee complete security as no electronic system is 100% secure even though we use multiple security measures to protect illegal use, damage, or loss of any kind of information. Further, we cannot warrant that such information may not be misused in the event our safeguards and protocols are breached by a malicious third-party. Further, we are not liable to nor can we fully control the actions of other users with whom you may choose to share your information. You must also maintain the confidentiality and security of your login credentials and not share these with any third party.</p>

                        <p>The collection, usage, and sharing of User information by MoneyWide shall be in compliance with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 and other applicable laws. All our customer data is stored in servers in India.</p>
                    </div>
                    <div className="pp-data">
                        <span>Third Party Advertising:</span>
                        <p>MoneyWide uses ad agencies or third-party advertising companies to provide ads when you are using our app. These companies do not use your personal information except the details about your visit to our app to offer ads on our app as well as on other websites about the services that you may like</p>
                    </div>
                    <div className="pp-data">
                        <span>Link to Third Party Websites:</span>
                        <p>Our Services may contain services, from time to time, provided by or links to and from the websites of our partner networks, service providers, financial institutions, advertisers, and affiliates (“Third Party Services”). Please note that the services we may use of Third Parties are governed by their own privacy policies. We do not hold any responsibility or liability for the policies or for any personal data that may be collected through such Third Party Services. Please check their policies before you submit any personal data to such websites or use their services</p>
                    </div>
                    <div className="pp-data">
                        <span>Contact Us with Questions or Concerns or For Grievance Redressal</span>
                        <p>If you have grievance or complaint, questions, comments, concerns or feedback in relation to the processing of information or regarding this Privacy and Security Policy or any other privacy or security concern, send an e-mail to care@moneywide.com</p>
                    </div>
                    <div className="pp-data">
                        <span>Name and Contact details of the Grievance Redressal Officer of the Company</span>
                        <table className="table table-striped m-auto" border="1">
                            <tbody><tr className="blue-table-head">
                                <th className="th">Name of the Officer</th>
                                <th className="th">Office Address</th>
                                <th className="th">Email id</th>
                            </tr>
                                <tr>
                                    <td><b>Ghanshyam Soni</b></td>
                                    <td>Plot no. 131, Ground Floor, Sector-44, Gurgaon-122003</td>
                                    <td>gro@moneywide.com</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="pp-data">
                        <span>Change in Privacy Policy</span>
                        <p>MoneyWide reserves the right to change its privacy policy from time to time. The same will be updated on the website on the date the changes have been made from.</p>
                    </div>
                    <div className="pp-data">
                        <span>Notice</span>
                        <p>The effective date of this Policy, as stated below, indicates the last time this Policy was revised or materially changed.</p>
                    </div>
                    <div className="pp-data">
                        <span>Effective Date</span>
                        <p>7th September 2022</p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default PrivacyPolicyApp