import React, { useEffect, useState } from 'react';
import "./servicerequest.css";
import Lottie from 'react-lottie-player';
import lottieJson from './animated-girl.json';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import FormLeftPart from '../Journey Form/FormLeftPart/FormLeftPart'
import man from '../../Icons/man-sitting.svg'
import ServiceRequestForm from './ServiceRequestForm';
import serviceRequest from '../../Icons/serviceRequest.svg'
import { useSelector, useDispatch } from 'react-redux';
import SelectedComplainServiceRequest from './SelectedComplainServiceRequest';
import ComplainServiceRequest from './ComplainServiceRequest';
import ServiceRequestOTP from './ServiceRequestOTP';
import ServiceLogin from './ServiceRequestLogin';
import OTP from '../Journey Form/Form/OTP';
import NewServiceRequestForm from './NewServiceRequestForm';
import Thankyou from '../Journey Form/Form/All_Forms/Thankyou';
import { updateserviceshow } from '../../Redux/action';
import WrongOtp from '../NotFound/WrongOtp';
import { Link } from 'react-router-dom';


const NewServiceRequest = ({ title, content }) => {
    const dispatch = useDispatch();
  const { selectedServiceQuery,setService,serviceCred } = useSelector(state => state)
  const handlesubmit = (e) => {
    e.preventDefault();
  }
  const [y, sety] = React.useState(true);
  window.addEventListener("scroll", (event) => {
    if (window.scrollY >= 339) {
      sety(false);
    }
    else {
      sety(true);
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const block = ()=>{
    // console.log("entering");
    dispatch(updateserviceshow({...setService,open:[false,false,false]}))
 }

// useEffect(()=>{
//   // block();
//   if(setService.open === [true,false,false]){
//     block();
//   }
// },[setService.open])

  return (
    <div onClick={block} className="journey-part">
      <div className="journey-content">
        <div className="left-side">
          <div className="left-top">
            <Link to={`/${window.location.search}`}>Home</Link>  &gt;{" "}
            <span>Service Request</span>
          </div>
          <h2 className="journey-heading">{title}</h2>
          <p className="journey-con">{content}</p>
          <div className="left-img"><img src={serviceRequest} alt="" /></div>
        </div>
        <div className="right-side">
          <div className="form-right-form">
            <div className="form-box">
              <div className="form-body">
                {setService.serviceLogin && <ServiceLogin/>}
                {setService.serviceWrong && <WrongOtp/>}
                {setService.serviceOtp && <ServiceRequestOTP/>}
                {/* {setService.serviceOtp && <OTP/>} */}
                {setService.complain && <ComplainServiceRequest/>}
                {setService.selected && <SelectedComplainServiceRequest />}
                {setService.newRequest && <NewServiceRequestForm/>}
                {setService.thanks && <Thankyou heading={"Thanks"}/>}
              </div>
            </div>
          </div>
                </div>
            </div>
        </div>
    )
}

export default NewServiceRequest;
