import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GenerateToken } from '../../Redux/action';
import { getCustId, getToken } from '../EverCalledFunctions/EverCalledApis';
import { Check_utm_source, decryptMandateId, encryptOnlyLeadId, gettingerror, RelocateUTMCheck } from '../EverCalledFunctions/EverCalledFunctions';
import { setPreApprovedOfferDetails, updateCredentialsState, updateFormState, updateMwApplicationId, updateChoosenAmount } from '../../Redux/action';
import axios from 'axios';
import { prodbase_url, verification_source, verified_by, verifier_name, source, react_base_url } from '../Secret_Credentials/Secret_Credential';
import Base64 from "base-64";
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import queryString from 'query-string';
import NewLeftPart from "../Journey Form/FormLeftPart/NewLeftPart";
import { AxiosThirty } from '../AxiosConfiguration/AxiosConfig';
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase';
import { FetchCustomerPayload } from '../EverCalledFunctions/EverCalledPayload';
import { apiInstance } from '../Config/APIConfig';

const EmandateRazDig = () => {
  const { credentialState, mw_application_id, step_bar, token, formStates } = useSelector(state => state);
  const dispatch = useDispatch();
  const [auth, setAuth] = useState("");
  const [state, setState] = useState(true);
  const [rocket, setRocket] = useState(false);
  const navigate = useNavigate();

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.digio.in/sdk/v10/digio.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }

  }, []);

  const decryptFid = async () => {
    const details = await decryptMandateId();
    if (details.length != 0) {
      dispatch(updateMwApplicationId(details[0]));
      setAuth(details[1]);
    }
    else {
      setRocket(true);
    }

  }

  React.useEffect(() => {
    if (token !== "") {
      decryptFid();
    }
  }, [token])

  const fetchCustomerDetails = async () => {
    let fetchDec = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 15), {
      token:""
    })
      // .then((res) => {
        if ((fetchDec.status == 1) && (fetchDec.status_code == 200)) {

          let cust_id = fetchDec.data.customer_id;
          dispatch(updateCredentialsState({ ...credentialState, cust_id: cust_id }));
          if (fetchDec.data.thank_you_flag == 1) {
            gettingerror("get thank you flag", mw_application_id)
            if (fetchDec.data.thank_you_url !== "") {
              window.location.href = fetchDec.data.thank_you_url;
              return
            }
            else {
              let qry = Base64.encode(`${mw_application_id}`);
              qry = Base64.encode(qry);
              RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=18`)
              return
            }

          }

          if (fetchDec.data.redirect_url !== "") {
            window.location.href = fetchDec.data.redirect_url;
            return;
          }

          if (fetchDec.data.is_account_ok.toString() === "0") {
            setState(false)
            dispatch(updateFormState({ ...formStates, StartJourney: true, BankDetails: true, Emandate: false }));
            return;
          }
          const phone = fetchDec.data.phone_no;
          let pg_method = fetchDec.data.pg_mode_dis;
          const qry = queryString.parse(window.location.search);
          if (qry.mandate_user) {
            pg_method = Base64.decode(qry.mandate_user);
            pg_method = Base64.decode(pg_method);
            if (pg_method.toString() === "1") {
              pg_method = "Digio";
            }
            else {
              pg_method = "Razorepay"
            }
          }
          if (pg_method === "Razorepay") {
            let pgCustomer = await apiInstance
              .post(
                `pg-create-customer`,
                {
                  "partner_id": 7,
                  "level_id": 1,
                  "lead_id": +(mw_application_id),
                  "customer_id": cust_id,
                  "verified_by": verified_by,
                  "verifier_name": verifier_name,
                  "verification_source": verification_source,
                  "source": source,
                },
                {
                  token:""
                }
              )
              // .then((res) => {
                if (pgCustomer.status_code === "200") {
                  const cut_id = pgCustomer.data.id;
                  const authKey = pgCustomer.data.authKey;
                  let pgOrder = await apiInstance
                    .post(
                      `pg-create-order`,
                      {
                        "partner_id": 7,
                        "level_id": 1,
                        "lead_id": +(mw_application_id),
                        "customer_id": cust_id,
                        "verified_by": verified_by,
                        "verifier_name": verifier_name,
                        "verification_source": verification_source,
                        "mandate_auth_type": ((auth == "1") ? "netbanking" : "debitcard"),
                        "source": source,
                      },
                      {
                        "token": "",
                        "log": 1
                      }
                    )
                    // .then((res) => {

                      if (pgOrder.status_code == 200) {
                        gettingerror('razor web', mw_application_id)
                        setState(false);
                        var options = {
                          "key": authKey,
                          "order_id": pgOrder.data.id,
                          "customer_id": cut_id,
                          "recurring": "1",
                          // "callback_url": `${react_base_url}personal-loan/`,
                          "handler": function (response) {
                            let qry = Base64.encode(`${mw_application_id}&${credentialState.cust_id}`)
                            qry = Base64.encode(qry);
                            RelocateUTMCheck(`${react_base_url}emandate/redirect/?fid=${qry}`)
                          },
                          "notes": {
                            "note_key 1": "",
                            "note_key 2": "",
                          },
                          "theme": {
                            "color": "#F37254",
                          },
                        };
                        var rzp1 = new window.Razorpay(options);
                        rzp1.open()
                      }
                      else {
                        dispatch(updateFormState({ ...formStates, enashSorry: true, Emandate: false, ReferenceDetails: false }));
                        navigate("/personal-loan");
                      }


                    // }).catch((err) => {
                    //   setRocket(true);
                    // });
                }
                else {
                  dispatch(updateFormState({ ...formStates, StartJourney: true, enashSorry: true, Emandate: false, ReferenceDetails: false }));
                  navigate("/personal-loan");
                }
              // }).catch((err) => {
              //   setRocket(true);
              // });
            // });
          }
          else if (pg_method === "Digio") {
            let digioOrder = await apiInstance.post(`digio-create-order`, {
              "customer_id": cust_id,
              "source": "MWide",
              "lead_id": mw_application_id,
              "verified_by": 1,
              "verifier_name": "System",
              "verification_source": 1,
              "partner_id": 1,
              "level_id": 1
            }, {
              "token": "",
              "log": 1
            })
              // .then((res) => {
                if ((digioOrder.status == 1) && (digioOrder.status_code == 200)) {
                  // console.log(res);
                  gettingerror('digio enach web', mw_application_id)
                  window.sessionStorage.setItem("mw_app_id", mw_application_id);
                  let mandate_id = digioOrder.data.id
                  setState(false);
                  let checkCondition = Check_utm_source()
                  if (checkCondition && checkCondition.length != 0) {
                    sessionStorage.setItem('utm', checkCondition)
                  }
                  var options = {
                    environment: "production",
                    "redirect_url": `${react_base_url}emandate/redirect`,
                    "is_redirection_approach": true,
                    "is_iframe": false,
                    callback: function (t) {
                      if (t.error_code != undefined)
                        alert("failed to register with error :" + t.message);
                      else alert("register successful for " + t.digio_doc_id);
                    },
                    "logo": "https://www.moneywide.com/assets/images/logo.webp",
                    "dg_preferred_auth_type": ((auth == "1") ? "net_banking" : "debit"), // debit or net_banking // 
                  };
                  var digio = new window.Digio(options);
                  digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
                  digio.submit(mandate_id, phone);
                  // Get the documentId by calling Digio upload APIs. Email/Mobile is signer’s identifier
                  //In case of failure to retrieve DocumentId call digio.cancel();
                  //   };
                }
                else {
                  gettingerror('digio create error web', mw_application_id)
                  const qry = encryptOnlyLeadId(mw_application_id)
                  let checkCondition = Check_utm_source()
                  if (checkCondition && checkCondition.length != 0) {
                    navigate(`/thank-you/?fid=${qry}&__temp_id=11${checkCondition}`);
                  } else {
                    navigate(`/thank-you/?fid=${qry}&__temp_id=11`);
                  }
                }

              // }).catch((err) => {
              //   setRocket(true);
              // })
          }
        }
        else {
          dispatch(updateFormState({ ...formStates, enashSorry: true, Emandate: false, ReferenceDetails: false }));
          navigate("/personal-loan");
        }
      // }).catch((err) => {
      //   setRocket(true);
      // })
  }

  React.useEffect(() => {
    if ((token !== "") && (mw_application_id !== "") && (auth !== "") && (auth !== undefined)) {
      fetchCustomerDetails();

    }
  }, [token, mw_application_id, auth])

  const handleError = (e) => {
    window.location.reload();
  }

  return (

    <>
      <Helmet>
        {/* <script src="https://app.digio.in/sdk/v10/digio.js"></script> */}
      </Helmet>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      {state ? <CKYCWaiting heading={"we are initiating your enach process."} /> : <div>
        <NewLeftPart title="Get Instant Loan Using MoneyWide" content="Moneywide is a digital lending app which provides you instant loans through a completely paperless process." />
      </div>
      }
    </>
  )
}

export default EmandateRazDig