import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './Redux/store';
// import reportWebVitals from './reportWebVitals';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import { environment } from './Components/Secret_Credentials/Secret_Credential';

// if (environment === "production") {
//   Sentry.init({
//     dsn: "https://2b28799be3bc4b44b58baf72d8808319@o1312272.ingest.sentry.io/4504531269255168",
//     integrations: [new BrowserTracing(), new Sentry.Replay()],
//     tracesSampleRate: 1.0,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0
//   });
// }



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
