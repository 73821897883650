import React from 'react'
import Slider from "react-slick";
import './MWMobileSlider.css'
import Step1 from "../../assests/profile.webp";
import Step2 from "../../assests/offer.webp";
import Step3 from "../../assests/kycverification.webp";
import Step4 from "../../assests/disbursal.webp";

function MWMobileSlider() {
  const [data, setData] = React.useState([
    {
      img: Step1,
      text:"Submit personal and employment details",
      count:"1"
    },
    {
      img: Step2,
      text:"Get instant Personal loan approval",
      count:"2"
    },
    {
      img: Step3,
      text:"Complete KYC and eSign process",
      count:"3"
    },
    {
      img: Step4,
      text:"Successful disbursement in your bank account",
      count:"4"
    }
  ])

  var sliderblog = {
    infinite: false,
    autoplay: true,
    cssEase: 'ease-in-out',
    speed: 1000,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    
    responsive: [
      {
        breakpoint: 750,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
    ]
  };
  return (
    <div className='main-mob-slider-div'>
      <div className="all-main-heading mob-all-main-heading ">
        <span>How does MoneyWide work?</span>
        <p>MoneyWide offers instant loans in 4 easy steps:</p>
      </div>
      <div className="slider-inner-div-mob">
        <Slider {...sliderblog}>
          {data.map((el, index) => (
            <div Key={index} className="mob-work-slider-main">
              <div className="mob-work-slider-div">
                <div className="mob-count-head">
                  <span>{el.count}</span>
                  <p>{el.text}</p>
                </div>
                <img src={el.img} alt='slider images' loading='lazy' />
              </div>
            </div>
          ))
          }
        </Slider>
      </div>
    </div>
  )
}

export default MWMobileSlider