import Base64 from "base-64";
import queryString from "query-string";
import { mixid, react_base_url } from "../Secret_Credentials/Secret_Credential";
import mixpanel  from "mixpanel-browser";
mixpanel.init(`${mixid}`,{debug:true});

export const gettingerror = (trig,id) =>{
    mixpanel.identify(id.toString())
    mixpanel.track(trig)
}

export const encryptLeadIdStepId = (lead_id, step_id) => {
    const lead = Base64.encode(lead_id);
    const dbl_enc_lead = Base64.encode(lead);
    const step = Base64.encode(step_id);
    const dbl_enc_step = Base64.encode(step);
    const qry = `lead_id=${dbl_enc_lead}&step_id=${dbl_enc_step}`;
    const enc_qry = Base64.encode(qry);
    const dbl_enc_qry = Base64.encode(enc_qry);
    return dbl_enc_qry;

}

export const encryptQueryIdStepId = (lead_id, step_id) => {
    const lead = Base64.encode(lead_id);
    const dbl_enc_lead = Base64.encode(lead);
    const step = Base64.encode(step_id);
    const dbl_enc_step = Base64.encode(step);
    const qry = `query_id=${dbl_enc_lead}&step_id=${dbl_enc_step}`;
    const enc_qry = Base64.encode(qry);
    const dbl_enc_qry = Base64.encode(enc_qry);
    return dbl_enc_qry;

}

export const decryptLeadIdStepId = async () => {
    try {
        const qry_str = queryString.parse(window.location.search);
        if (qry_str.fid) {
            let decodeData = Base64.decode(qry_str.fid);
            decodeData = Base64.decode(decodeData);
            if (decodeData.includes("&")) {
                decodeData = decodeData.split("&");

                let mw_app_id = decodeData[0];
                if (mw_app_id.includes("lead_id")) {
                    mw_app_id = mw_app_id.split("lead_id=");
                    mw_app_id = mw_app_id[1];
                    mw_app_id = Base64.decode(mw_app_id);
                    mw_app_id = Base64.decode(mw_app_id);

                    let step_id = decodeData[1];
                    step_id = step_id.split("step_id=");
                    step_id = step_id[1];
                    step_id = Base64.decode(step_id)
                    step_id = Base64.decode(step_id)

                    const myData = [mw_app_id, step_id];
                    // console.log(myData);

                    return myData;
                }
                else {
                    window.location.href = `${react_base_url}personal-loan`;
                }

            }
            else {
                window.location.href = `${react_base_url}personal-loan`;
            }

        }
    }
    catch {
        window.location.href = `${react_base_url}personal-loan`;
    }
}

export const decryptQueryIdStepId = async () => {
    try {
        const qry_str = queryString.parse(window.location.search);
        if (qry_str.fid) {
            let decodeData = Base64.decode(qry_str.fid);
            decodeData = Base64.decode(decodeData);
            if (decodeData.includes("&")) {
                decodeData = decodeData.split("&");

                let mw_app_id = decodeData[0];
                if (decodeData[0].includes("query_id")) {
                    mw_app_id = mw_app_id.split("query_id=");
                    mw_app_id = mw_app_id[1];
                    mw_app_id = Base64.decode(mw_app_id);
                    mw_app_id = Base64.decode(mw_app_id);

                    let step_id = decodeData[1];
                    step_id = step_id.split("step_id=");
                    step_id = step_id[1];
                    step_id = Base64.decode(step_id)
                    step_id = Base64.decode(step_id)

                    const myData = [mw_app_id, step_id];


                    return myData;
                }
                else {
                    window.location.href = `${react_base_url}personal-loan`;
                }

            }
            else {
                window.location.href = `${react_base_url}personal-loan`;
            }

        }
    }
    catch (err) {
        // alert(err)
        window.location.href = `${react_base_url}personal-loan`;
    }
}

export const encryptOnlyLeadId = (lead_id) => {
    const lead = Base64.encode(lead_id);
    const dbl_enc_lead = Base64.encode(lead);
    return dbl_enc_lead;
}

export const decryptOnlyLeadId = async () => {
    try {
        const qry_str = queryString.parse(window.location.search);
        if (qry_str.fid) {
            let lead_id = Base64.decode(qry_str.fid);
            lead_id = Base64.decode(lead_id);
            return lead_id;
        }
    }
    catch {
        window.location.href = `${react_base_url}personal-loan`;
    }
}

export const encryptQueryIdOfferId = (lead_id, step_id) => {
    const lead = Base64.encode(lead_id);
    const dbl_enc_lead = Base64.encode(lead);
    // const step = Base64.encode(step_id);
    // const dbl_enc_step = Base64.encode(step);
    const qry = `query_id=${dbl_enc_lead}&offer_id=${step_id}`;
    const enc_qry = Base64.encode(qry);
    const dbl_enc_qry = Base64.encode(enc_qry);
    return dbl_enc_qry;

}

export const encryptPhoneAndCustomer = (phone, cust_id) => {
    let enc_data = Base64.encode(`${phone}&${cust_id}`);
    enc_data = Base64.encode(enc_data);
    return enc_data;
}

export const decryptPhoneAndCustomer = () => {
    let all_params = queryString.parse(window.location.search);
    let dec_data = Base64.decode(all_params.jid);
    dec_data = Base64.decode(dec_data);
    let phone = dec_data.split("&");
    let cust_id = phone[1]
    phone = phone[0]
    return [phone, cust_id]
}

export const decryptBsaFid = () => {
    let qry = queryString.parse(window.location.search);
    qry = Base64.decode(qry.fid);
    qry = Base64.decode(qry);
    qry = qry.split("&");

    let lead_id = qry[0];
    lead_id = lead_id.split("query_id=");
    lead_id = lead_id[1];
    lead_id = Base64.decode(lead_id);
    lead_id = Base64.decode(lead_id);

    let cust_id = qry[1];
    cust_id = cust_id.split("cust_id=");
    cust_id = cust_id[1];
    cust_id = Base64.decode(cust_id);
    cust_id = Base64.decode(cust_id);
    console.log(lead_id, cust_id);
    return [lead_id, cust_id];
}

export const encryptMandateId = (lead_id, step_id) => {
    const lead = Base64.encode(lead_id);
    const dbl_enc_lead = Base64.encode(lead);
    const step = Base64.encode(step_id);
    const dbl_enc_step = Base64.encode(step);
    const qry = `lead_id=${dbl_enc_lead}&mandate_type=${dbl_enc_step}`;
    const enc_qry = Base64.encode(qry);
    const dbl_enc_qry = Base64.encode(enc_qry);
    return dbl_enc_qry;

}

export const decryptQueryIdOfferId = async () => {
    try {
        const qry_str = queryString.parse(window.location.search);
        if (qry_str.fid) {
            let decodeData = Base64.decode(qry_str.fid);
            decodeData = Base64.decode(decodeData);
            decodeData = decodeData.split("&");

            let mw_app_id = decodeData[0];
            mw_app_id = mw_app_id.split("query_id=");
            mw_app_id = mw_app_id[1];
            mw_app_id = Base64.decode(mw_app_id);
            mw_app_id = Base64.decode(mw_app_id);

            let step_id = decodeData[1];
            step_id = step_id.split("offer_id=");
            step_id = step_id[1];
            // step_id = Base64.decode(step_id)
            // step_id = Base64.decode(step_id)

            const myData = [mw_app_id, step_id];

            return myData;
        }
    }
    catch {
        window.location.href = `${react_base_url}personal-loan`;
    }
}

export const decryptMandateId = async () => {
    try {
        const qry_str = queryString.parse(window.location.search);
        if (qry_str.fid) {
            let decodeData = Base64.decode(qry_str.fid);
            decodeData = Base64.decode(decodeData);
            decodeData = decodeData.split("&");

            let mw_app_id = decodeData[0];
            mw_app_id = mw_app_id.split("lead_id=");
            mw_app_id = mw_app_id[1];
            mw_app_id = Base64.decode(mw_app_id);
            mw_app_id = Base64.decode(mw_app_id);

            let step_id = decodeData[1];
            step_id = step_id.split("mandate_type=");
            step_id = step_id[1];
            step_id = Base64.decode(step_id)
            step_id = Base64.decode(step_id)

            const myData = [mw_app_id, step_id];

            return myData;
        }
    }
    catch {
        window.location.href = `${react_base_url}personal-loan`;
    }
}

export const encryptLeadIdCustIdLevelId = (lead_id, cust_id, level_id) => {
    const lead = Base64.encode(lead_id);
    const dbl_enc_lead = Base64.encode(lead);
    const cust = Base64.encode(cust_id);
    const dbl_enc_cust = Base64.encode(cust);
    // const qry = `customer_id=${dbl_enc_cust}&level_id=${level_id}&lead_id=${dbl_enc_lead}`;
    const qry = `customer_id=${cust_id}&level_id=${level_id}&lead_id=${lead_id}`;
    const enc_qry = Base64.encode(qry);
    const dbl_enc_qry = Base64.encode(enc_qry);
    return dbl_enc_qry;

}
export const decryptAGRID = async () => {
    try {
        const qry_str = queryString.parse(window.location.search);
        if (qry_str.agrid) {
            let decodeData = Base64.decode(qry_str.agrid);
            decodeData = Base64.decode(decodeData);
            // decodeData = decodeData.split("agrid=");
            // decodeData = Base64.decode(decodeData[1]);
            // decodeData = Base64.decode(decodeData);
            return decodeData;

        }
    }
    catch {
        window.location.href = `${react_base_url}personal-loan`;
    }
}
export const encryptAGRID = (lead_id) => {
    const lead = Base64.encode(lead_id);
    const dbl_enc_lead = Base64.encode(lead);
    // const qry = `agrid=${dbl_enc_lead}`;
    // const enc_qry = Base64.encode(qry);
    // const dbl_enc_qry = Base64.encode(enc_qry);
    return dbl_enc_lead;
}

export const encryptQueryIdCustomerId = (query_id, cust_id, lender_id) => {
    let enc_data = Base64.encode(`${query_id}&${cust_id}&${lender_id}`);
    return enc_data;
}

export const decryptQueryIdCustomerId = () => {
    let qryStr = queryString.parse(window.location.search);
    let fid = qryStr.fid
    fid = Base64.decode(fid);
    fid = fid.split("&");
    return fid;
}

export const encryptQueryIdCustomerIdStepId = (lead_id, cust_id, step_id) => {
    let qry = Base64.encode(`${lead_id}&${cust_id}&${step_id}`);
    return qry;
}

export const decryptQueryIdCustomerIdStepId = () => {
    // console.log(window.location.search);
    let qry = queryString.parse(window.location.search);
    let fid = qry.fid;
    let txnId = "";
    if(fid.includes("?")) {
        fid = fid.split("?");
        txnId = fid[1];
        txnId = txnId.split("txnId=");
        txnId = txnId[1];
        fid = fid[0];
        
    }
    else if(window.location.search.includes("txnId")) {
        txnId = window.location.search;
        txnId = txnId.split("txnId=");
        txnId = txnId[1];
        if(txnId.includes("&")) {
            txnId = txnId.split("&");
            txnId = txnId[0];
        }
    }
    fid = Base64.decode(fid);
    fid = fid.split("&");
    fid.push(txnId);
    // console.log(fid);
    return fid;
}

export const GetProductName = (productId) => {
    var data =
        [
            { id: 11, value: 'Doctor Loan' },
            { id: 21, value: 'Savings Account' },
            { id: 22, value: 'Current Account' },
            { id: 23, value: 'Demat Account' },
            { id: 31, value: 'PPF' },
            { id: 32, value: 'FD' },
            { id: 33, value: 'RD' },
            { id: 34, value: 'Corporate Bonds' },
            { id: 35, value: 'Govt Bonds' },
            { id: 36, value: 'Tax Free Bonds' },
            { id: 41, value: 'CIBIL' },
            { id: 42, value: 'GST' },
            { id: 43, value: 'Base Rate/ MCLR/ PLR' },
            { id: 44, value: 'Income Tax' },
            { id: 45, value: 'Property Tax' },
            { id: 46, value: 'Circle Rate/ Ready Reckoner Rate' },
            { id: 47, value: 'Credit Report' },
            { id: 51, value: 'Home Loan' },
            { id: 52, value: 'Commercial Property Loan' },
            { id: 54, value: 'Loan Against Property' },
            { id: 56, value: 'Personal Loan' },
            { id: 57, value: 'Business Loan' },
            { id: 58, value: 'Top up Loan' },
            { id: 59, value: 'Education Loan' },
            { id: 60, value: 'Gold Loan' },
            { id: 61, value: 'Car Loan' },
            { id: 62, value: 'Two Wheeler Loan' },
            { id: 63, value: 'Professional Loan' },
            { id: 64, value: 'Consumer Loan' },
            { id: 71, value: 'Credit Card' },
            { id: 72, value: 'Debit/ ATM Card' },
            { id: 73, value: 'Forex Card' },
            { id: 81, value: 'Life Term' },
            { id: 82, value: 'Life Endowment/ ULIP' },
            { id: 83, value: 'Car' },
            { id: 84, value: 'Two wheeler' },
            { id: 85, value: 'Health' },
            { id: 86, value: 'House' },
            { id: 87, value: 'Fire' },
            { id: 88, value: 'Travel' },
            { id: 89, value: 'Transit' },
            { id: 91, value: 'Liquid' },
            { id: 92, value: 'Gilt' },
            { id: 93, value: 'Debt' },
            { id: 94, value: 'Equity' },
            { id: 95, value: 'Balanced' },
            { id: 96, value: 'Tax Saver' },
            { id: 97, value: 'EPF' },
            { id: 98, value: 'LOAN AGAINST SECURITIES' },
            { id: 99, value: 'LEASING' },
            { id: 100, value: 'Microfinance' },
            { id: 101, value: 'Construction Equipment Loan' },
            { id: 102, value: 'Tractor Loan' },
            { id: 103, value: 'Overdraft' },
            { id: 998, value: 'Webinar' },
            { id: 999, value: 'Corona Kavach Insurance' },
        ]
    var ans = data.filter((row) => {
        return row.id === productId
    })
    if (ans[0].value) {
        return ans[0].value
    }
    return "";
}

export const Check_utm_source = () => {
    let qry = queryString.parse(window.location.search)
    if (Object.keys(qry).length>0) {
        let str = ""
        for (const key in qry) {
            if ((key != "fid") && (key != "pad")) {
                str += `&${key}=${qry[key]}`
            }
        }
        return str;
    } else {
        return false;
    }
}

export const Check_utm_lp = () => {
    if ((window.location.href).includes('utm') || (window.location.href).includes('tmpld')) {
        let qry = queryString.parse(window.location.search)
        let str = ""
        if (qry.utm_source) {
            str = str + `?utm_source=${qry.utm_source}`
        }
        if (qry.utm_medium) {
            str = str + `&utm_medium=${qry.utm_medium}`
        }
        if (qry.utm_campaign) {
            str = str + `&utm_campaign=${qry.utm_campaign}`
        }
        if (qry.utm_type) {
            str = str + `&utm_type=${qry.utm_type}`
        }
        if (qry.tmpld) {
            str = str + `&tmpld=${qry.tmpld}`
        }
        return str;
    } else {
        return false;
    }
}

export const RelocateUTMLP = (url) => {
    let utmExists = Check_utm_lp()
    if (utmExists && utmExists.length != 0) {
        window.location.href = `${url}${utmExists}`
    } else {
        window.location.href = `${url}`
    }
}



export const RelocateUTMCheck = (url) => {
    let utmExists = Check_utm_source()
    if (utmExists && utmExists.length != 0) {
        window.location.href = `${url}${utmExists}`
    } else {
        window.location.href = `${url}`
    }
}

export const getAnimationData = (val,loop) => {
    let arr = []
    loop = loop?loop:2
    let i = 0;
    while (i < loop) {
        for (var key in val) {
            if (key.includes('firstfold_content')) {
                arr.push(val[key]);
            }
        }
        i++
    }
    return arr;
}

export const handleKeyPress = (event) => {
    // console.log("jjj")
    if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
}