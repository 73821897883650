import React from 'react'
import bankerror from '../../../Icons/bankerror.svg'
import './IncorrectBankDocumentPopUp.css'
import {useDispatch,useSelector} from 'react-redux'
import { updatePopUp } from '../../../Redux/action';

function IncorrectBankDocumentPopUp() {
  const dispatch = useDispatch();
  const {PopUpState} = useSelector(state=>state)

  const ManageIt=()=>{
    console.log("Off it");
    const updateVal = {show:false}
    dispatch(updatePopUp({...PopUpState,InvalidDocument:updateVal}))
  }
  return (
    <div className="loaderbackground">
      <div className="loadertransparent">
        <div className="IncorrectBankDocumentPopUp">
          <div className="wrongcontent">
            <img src={bankerror} alt="bankerror" />
            <h3>Please upload a valid document</h3>
            <p>Uploaded document is not valid or has been expired</p>
            <button onClick={()=>ManageIt()}>Retry</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IncorrectBankDocumentPopUp