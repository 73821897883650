import React, { useEffect, useState } from 'react';
import FormHead from '../Journey Form/Form/FormHead';
import "../PersonalLoan/Calculator.css"
import one from './PAOfferone.svg';
import two from './PAOffertwo.svg';
import ctaAnimation from '../assests/lootie-files/continue.json';
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../EverCalledFunctions/EverCalledApis';
import { GenerateToken, setPreApprovedOfferDetails, updateFormState, updatePartnerState } from '../../Redux/action';
import axios from 'axios';
import Base64 from "base-64";
import { prodbase_url, react_base_url, source, verification_source, verified_by, verifier_name } from '../Secret_Credentials/Secret_Credential';
import { RelocateUTMCheck, encryptOnlyLeadId } from '../EverCalledFunctions/EverCalledFunctions';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting';
import queryString from "query-string";
import { apiInstance } from '../Config/APIConfig';
import { useSearchParams } from 'react-router-dom';
import { FetchCustomerPayload } from '../EverCalledFunctions/EverCalledPayload';

const PartnerOfferSelection = () => {
    const {
        step_bar,
        loginDetails,
        ckycDetails,
        formStates,
        pre_approved_offer,
        credentialState,
        PopUpState,
        mw_application_id,
        preApprovedOfferDetails,
        choosen_amount,
        decisionDemog,
        token,
        partnerStates,
        partnerData,
        Product_Id
    } = useSelector((state) => state);

    const dispatch = useDispatch();
    const [resp, setRes] = useState(false)
    const [selected, setSelected] = useState('')
    const [state, setState] = React.useState(true);
    const [loanName, setLoanName] = React.useState("");
    const [rocket, setRocket] = React.useState(false);
    const [loader, setLoader] = React.useState(true);
    const [first, setFirst] = React.useState([]);
    const [second, setSecond] = React.useState([])
    const [firstclick, setFirstclick] = React.useState(false);
    const [secondclick, setSecondclick] = React.useState(false);
    const [emi1, setEmi1] = useState('');
    const [emi2, setEmi2] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("eligibility");
    const [showroi,setShowRoi] = useState(false);
    const [showtext,setShowText] = useState(true);

    const myToken = async () => {
        const tkn = await getToken();
        dispatch(GenerateToken(tkn));
    };

    useEffect(() => {
        if (token == "") {
            myToken();
        }
    }, [])

    const OfferEligibilityTest = () => {

        const payLoad = {
            "source": source,
            "mw_application_id": mw_application_id,
            "product_id": 56,
            "customer_id": credentialState.cust_id,
            "offer_type": 1,
            "level_id": 1,
            "verified_by": verified_by,
            "verifier_name": verifier_name,
            "verification_source": verification_source,
            "is_eligibility_check": 1,
            "partner_preApproved_offer": 1
        }
        let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";

        axios.post(`${prodbase_url}${url}`, payLoad, {
            headers: {
                "token": ""
            }
        }).then((res) => {
            if ((res.data.status == "1") && (res.data.status_code == "200")) {
                // gettingerror('offer hit web',mw_application_id)
                setLoader(false);
                if (res?.data?.data?.data["bsa_capture"] == 1) {
                    let qryStr = encryptOnlyLeadId(mw_application_id);
                    RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
                }
                else if (res?.data?.data?.data?.final_data.length) {
                    if(userId == null){
                        FetchCustomer();
                    }
                    if(res?.data?.data?.data?.final_data[0]){
                        if(res?.data?.data?.data?.final_data[0].applicable_roi == 0){
                            setShowRoi(true);
                        }
                    }
                    if (res?.data?.data?.data.final_data.length == 2) {
                        if (res.data.data.data.final_data[0].incomedoc_req == 1 || res.data.data.data.final_data[0].incomedoc_req == 6) {
                            setSecond(res.data.data.data.final_data[0])
                        }
                        if (res.data.data.data.final_data[0].incomedoc_req == 0) {
                            setFirst(res.data.data.data.final_data[0])
                        }
                        if (res.data.data.data.final_data[1].incomedoc_req == 1 || res.data.data.data.final_data[1].incomedoc_req == 6) {
                            setSecond(res.data.data.data.final_data[1])
                        }
                        if (res.data.data.data.final_data[1].incomedoc_req == 0) {
                            setFirst(res.data.data.data.final_data[1])
                        }
                    }
                    else if (res?.data?.data?.data.final_data.length == 1) {
                        if (res.data.data.data.final_data[0].incomedoc_req == 1 || res.data.data.data.final_data[0].incomedoc_req == 6) {
                            setSecond(res.data.data.data.final_data[0])
                        }
                        if (res.data.data.data.final_data[0].incomedoc_req == 0) {
                            setFirst(res.data.data.data.final_data[0])
                        }
                    }

                }
                // else if ((res.data.data.data.cust_eligible == 0) && (res?.data?.data?.data?.template_Id) && (res.data.data.data.template_Id != "")) {
                //     let qry = Base64.encode(`${mw_application_id}`);
                //     qry = Base64.encode(qry);
                //     RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=${res.data.data.template_Id}`);

                // }
                else if (res.data.data.data.cust_eligible == 0) {
                    let qry = Base64.encode(`${mw_application_id}`);
                    qry = Base64.encode(qry);
                    RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=5`);
                }
                else {
                    dispatch(setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: false, }))
                    dispatch(updateFormState({ ...formStates, StartJourney: true, OfferNew: false, HunterHold: true }))
                    setState(false);
                }
            }
            else {
                setLoader(false);
                // gettingerror('offer eligibility api error web',mw_application_id)
                setRocket(true);
            }
        }).catch((err) => {
            setRocket(true);
        })
    }


      const FetchCustomer = async()=>{
        let res = await apiInstance.post(`fetch-customer-details`, FetchCustomerPayload(mw_application_id, 3), {
            token
        });
        let decisionDec = await apiInstance.post(`decision-epfo-api`, {
            "customer_id": credentialState.cust_id,
            "product_id": 56,
            "username": res.data.customer_full_name ? res.data.customer_full_name : "",
            "mw_application_id": mw_application_id,
            "mobile_no": res.data.custPhoneNo ? res.data.custPhoneNo : "",
            "company_name": res.data.company_name ? res.data.company_name : "",
            "pancard": res.data.pancard ? res.data.pancard : "",
            "source": source,
            "verification_source": verification_source,
            "verified_by": verified_by,
            "verifier_name": verifier_name
          }, {
            "token": token
          })
          if ((decisionDec.status == 1) && (decisionDec.status_code == 200)) {
            let url = res.data.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test"
            let offerDec = await apiInstance.post(
              `${url}`,
              {
                "source": source,
                "mw_application_id": mw_application_id,
                "product_id": 56,
                "customer_id": credentialState.cust_id,
                "offer_type": 1,
                "level_id": 1,
                "verified_by": verified_by,
                "verifier_name": verifier_name,
                "verification_source": verification_source,
                "stage_name": "Employment Check"
              },
              {
                "token": token,
                "log": 1
              }
            )
            // .then((res) => {
            // if ((res.data.status == 1) && (res.data.status_code == 200)) {
            if (offerDec.status_code === "200") {
      
            }
            // }
      
      
            // });
          }
      }

    const OfferEligibilityTestNew = async () => {
        const payLoad = {
            "source": source,
            "mw_application_id": mw_application_id,
            "product_id": 56,
            "customer_id": credentialState.cust_id,
            "offer_type": 1,
            "level_id": 1,
            "verified_by": verified_by,
            "verifier_name": verifier_name,
            "verification_source": verification_source,
            // "is_eligibility_check": credentialState.eligibility ? credentialState.eligibility : 1
            "is_eligibility_check": userId ? 2 : (decisionDemog.offer_journey_change_flag == 1 ? 1 : 6)
        }
        let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";

        let res = await apiInstance.post(`${url}`, payLoad, {
            "token": token
        });
        if ((res.status == "1") && (res.status_code == "200")) {
            // gettingerror('offer hit web',mw_application_id)
            setLoader(false);
            if (res?.data["bsa_capture"] == 1) {
                let qryStr = encryptOnlyLeadId(mw_application_id);
                RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
            }
            else if (res?.data?.final_data.length) {
                if(userId == null){
                    FetchCustomer();
                }
                if(res?.data?.final_data[0]){
                    if(res?.data?.final_data[0].applicable_roi == 0){
                        setShowRoi(true);
                    }
                }
                if (res?.data?.final_data.length == 2) {
                    if ((res.data.final_data[0].incomedoc_req == 1 || res.data.final_data[0].incomedoc_req == 6)) {
                        setSecond(res.data.final_data[0])
                    }
                    if (res.data.final_data[0].incomedoc_req == 0) {
                        setFirst(res.data.final_data[0])
                    }
                    if ((res.data.final_data[1].incomedoc_req == 1 || res.data.final_data[1].incomedoc_req == 6)) {
                        setSecond(res.data.final_data[1])
                    }
                    if (res.data.final_data[1].incomedoc_req == 0) {
                        setFirst(res.data.final_data[1])
                    }
                    if(res.data.final_data[0].incomedoc_req == 9){
                        setFirst(res.data.final_data[0])
                    }
                    if(res.data.final_data[0].incomedoc_req == 7){
                        setFirst(res.data.final_data[0])
                    }
                }
                else if (res?.data?.final_data.length == 1) {
                    setShowText(false);
                    if ((res.data.final_data[0].incomedoc_req == 1 || res.data.final_data[0].incomedoc_req == 6)) {
                        setSecond(res.data.final_data[0])
                    }
                    if (res.data.final_data[0].incomedoc_req == 0) {
                        setFirst(res.data.final_data[0])
                    }
                    if (res.data.final_data[0].incomedoc_req == 9) {
                        setFirst(res.data.final_data[0])
                    }
                    if(res.data.final_data[0].incomedoc_req == 7){
                        setFirst(res.data.final_data[0])
                    }
                }

            }
            // else if ((res.data.cust_eligible == 0) && (res?.data?.template_Id) && (res.data.template_Id != "")) {
            //     let qry = Base64.encode(`${mw_application_id}`);
            //     qry = Base64.encode(qry);
            //     RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=${res.data.template_Id}`);

            // }
            else if (res.data.cust_eligible == 0) {
                let qry = Base64.encode(`${mw_application_id}`);
                qry = Base64.encode(qry);
                RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=5`);
            }
            else {
                dispatch(setPreApprovedOfferDetails({ ...preApprovedOfferDetails, state: false, }))
                dispatch(updateFormState({ ...formStates, StartJourney: true, OfferNew: false, HunterHold: true }))
                setState(false);
            }
        }
        else {
            setLoader(false);
            // gettingerror('offer eligibility api error web',mw_application_id)
            setRocket(true);
        }
    }

    const calculateEmi = (data) => {
        // console.log(data)
        let roi = (+(data.applicable_roi) / (12 * 100));
        let prog_id;
        if (data?.program_id) {
            prog_id = "prog_" + data.program_id.toString()
        }
        // console.log(prog_id)
        // console.log(roi)
        let tenure = (data.max_tenure);
        if (tenure.includes("Months")) {
            tenure = tenure.replace("Months", "").trim();
            tenure = +tenure;
        }
        // console.log(tenure)
        let Emi =
            (((Math.floor(+data.max_loan_amt / 1000)) * 1000) * roi * Math.pow(1 + roi, tenure)) /
            (Math.pow(1 + roi, tenure) - 1);
        Emi = Math.round(Emi);
        // console.log(Emi);
        return Emi
        // setEmi(Emi);
    }

    useEffect(() => {
        if (first.length != 0) {
            if(first.applicable_roi != 0){
                let emi_1 = calculateEmi(first);
            setEmi1(emi_1);
            }
            
        }

    }, [first])

    useEffect(() => {
        if (second.length != 0) {
            let emi_1 = calculateEmi(second);
            setEmi2(emi_1);
        }

    }, [second])

    useEffect(() => {
        if ((token !== "") && (mw_application_id !== "") && (credentialState.cust_id !== "")) {
            let stamp = queryString.parse(window.location.search);
            if (
                (decisionDemog.pan_status === "OK") && (decisionDemog.is_junk1 !== 1) && (decisionDemog.is_junk2 !== 1)
            ) {
                let url = window.location.pathname;
                if (url.includes('/lp')) {
                    OfferEligibilityTest();
                }
                else {
                    OfferEligibilityTestNew();
                }
            }
            else if (stamp['fid']) {
                if ((partnerData?.finaloffer?.data?.final_data?.length != 0) && (partnerData?.finaloffer?.data?.final_data !== undefined) && (partnerData?.finaloffer?.data?.final_data !== "undefined")) {
                    // console.log(partnerData.finaloffer);
                    if(partnerData.finaloffer.data.final_data[0].applicable_roi == 0){
                        setShowRoi(true);
                    }
                    if (partnerData.finaloffer.data.final_data.length == 2) {
                        setLoader(false);
                        if (partnerData.finaloffer.data.final_data[0].incomedoc_req == 1 || partnerData.finaloffer.data.final_data[0].incomedoc_req == 6) {
                            setSecond(partnerData.finaloffer.data.final_data[0])
                        }
                        if (partnerData.finaloffer.data.final_data[0].incomedoc_req == 0) {
                            setFirst(partnerData.finaloffer.data.final_data[0])
                        }
                        if (partnerData.finaloffer.data.final_data[1].incomedoc_req == 1 || partnerData.finaloffer.data.final_data[1].incomedoc_req == 6) {
                            setSecond(partnerData.finaloffer.data.final_data[1])
                        }
                        if (partnerData.finaloffer.data.final_data[1].incomedoc_req == 0) {
                            setFirst(partnerData.finaloffer.data.final_data[1])
                        }
                        if(partnerData.finaloffer.data.final_data[0].incomedoc_req == 9){
                            setFirst(partnerData.finaloffer.data.final_data[0])
                        }
                        if(partnerData.finaloffer.data.final_data[0].incomedoc_req == 7){
                            setFirst(partnerData.finaloffer.data.final_data[0])
                        }
                        
                    }
                    else if (partnerData.finaloffer.data.final_data.length == 1) {
                        setLoader(false)
                        if (partnerData.finaloffer.data.final_data[0].incomedoc_req == 1 || partnerData.finaloffer.data.final_data[0].incomedoc_req == 6) {
                            setSecond(partnerData.finaloffer.data.final_data[0])
                        }
                        if (partnerData.finaloffer.data.final_data[0].incomedoc_req == 0) {
                            setFirst(partnerData.finaloffer.data.final_data[0])
                        }
                        if(partnerData.finaloffer.data.final_data[0].incomedoc_req == 9){
                            setFirst(partnerData.finaloffer.data.final_data[0])
                        }
                        if(partnerData.finaloffer.data.final_data[0].incomedoc_req == 7){
                            setFirst(partnerData.finaloffer.data.final_data[0])
                        }
                    }
                    else {
                        let url = window.location.pathname;
                        if (url.includes('/lp')) {
                            OfferEligibilityTest();
                        }
                        else {
                            OfferEligibilityTestNew()
                        }
                    }

                }
                else {
                    let url = window.location.pathname;
                    if (url.includes('/lp')) {
                        OfferEligibilityTest();
                    }
                    else {
                        OfferEligibilityTestNew()
                    }

                }
            }
            else {
                let qry = Base64.encode(`${mw_application_id}`);
                qry = Base64.encode(qry);
                RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=18`)
            }
        }
    }, [token, mw_application_id, credentialState.cust_id])

    // const handleClick = () => {
    //     let url = window.location.pathname;
    //     if (selected == 1) {
    //         if (url.includes('/personal-loan')) {
    //             dispatch(updateFormState({ ...formStates, StartJourney: true, OfferNew: false, Offerselection: true }));
    //             dispatch(updatePartnerState({ ...partnerStates, PreApp: first }));
    //         }
    //         else {
    //             dispatch(updatePartnerState({ ...partnerStates, PartnerSelection: false, PartnerOffer: true, PreApp: first }))
    //         }
    //     }
    //     else if (selected == 2) {
    //         let qryStr = encryptOnlyLeadId(mw_application_id);
    //         RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
    //         // window.location.href = ;
    //     }
    // }

    const handlefirst = () => {
        let url =window.location.pathname;
        if (url.includes('/lp')) {
            dispatch(updatePartnerState({ ...partnerStates, PartnerSelection: false, PartnerOffer: true, PreApp: first }))
        }
        else {
            if(showroi){
                if(first.incomedoc_req == 9){
                    let qryStr = encryptOnlyLeadId(mw_application_id);
                  RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
                }
                else{
                    dispatch(updateFormState({ ...formStates, StartJourney: true, OfferNew: false, offernot: true }));
                dispatch(updatePartnerState({ ...partnerStates, PreApp: first }));
                }
                
            }
            else{
                dispatch(updateFormState({ ...formStates, StartJourney: true, OfferNew: false, Offerselection: true }));
                dispatch(updatePartnerState({ ...partnerStates, PreApp: first }));
            }
            
        }
        // setFirstclick(true);
        // setSecondclick(false);
        // setSelected(1);
    }

    const handlesecond = () => {
        let qryStr = encryptOnlyLeadId(mw_application_id);
        RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
    }





    return (
        <>
            {loader && <CKYCWaiting time={30}/>}
            <FormHead formName="Congratulations!!" formSubHead={showtext ? "New offer unlocked for you. Please choose one of the offers given below" : "Here is your eligible offer. Click below to complete application"} progressBar={true} />
            {showroi ? 
             <div className='pa-congrats-main-div'>
             {first.length !== 0 && <div onClick={handlefirst} className='pa-congrats-main-div-one'>
                 <div className='pa-congrats-one part-pay-radio-box'>
                     <div className='pa-congrats-div-main'>
                         <img src={one} alt='' />
                         <p>{first.program_name}<br/></p>

                         {/* <div onClick={() => setSelected(1)}>
                             <input type='radio' checked={firstclick} name='congo' />
                         </div> */}
                     </div>

                 </div>

                 <div className='pa-congrats-one-main'>
                     <div>
                         <p className='pa-congrats-p'>Sanctioned Amount</p>
                         <p className='pa-congrats-ptwo'>₹ {first?.max_loan_amt?.toLocaleString("en-IN")}</p>
                     </div>
                     {/* <div>
                         <p className='pa-congrats-p'>ROI(PM)</p>
                         <p className='pa-congrats-ptwo'>{(first.applicable_roi / 12).toFixed(2)}%</p>
                     </div> */}
                     <div>
                         <p className='pa-congrats-p'>Tenure</p>
                         <p className='pa-congrats-ptwo'>{first.max_tenure}</p>
                     </div>
                     {/* <div>
                         <p className='pa-congrats-p'>EMI as low as</p>
                         <p className='pa-congrats-ptwo'>₹ {emi1 ? emi1.toLocaleString("en-IN") : ''}</p>
                     </div> */}
                 </div>
                 {/* <div> */}
                 {/* <ul>
                     <li>Bank Statement/Income<br/> docs not required</li>
                     <button >Avail Now</button>
                 </ul> */}
                 {/* </div> */}
                 <div className="partner-pre-btn">
                     <p>{first.incomedoc_req == 9 ? <span>subject to online<br/> Income verification</span> : <span>Avail & Proceed<br/>to KYC Verification</span>}</p>
                     <button>{first.incomedoc_req == 9 ? "Proceed To Verify" : "Avail Now"}</button>
                 </div>
             </div>}
             {second.length !== 0 && <div onClick={handlesecond}  className='pa-congrats-main-div-two'>
                 <div className='pa-congrats-one part-pay-radio-box'>
                     <div className='pa-congrats-div-main'>
                         <img src={two} alt='' />
                         <p>{second.program_name}<br/></p>
                         {/* <div onClick={() => setSelected(2)}>
                             <input type='radio' checked={secondclick} name='congo' />
                         </div> */}
                     </div>

                 </div>

                 <div className='pa-congrats-one-main'>
                     <div>
                         <p className='pa-congrats-p'>Loan Amount</p>
                         <p className='pa-congrats-ptwo'>₹ {second?.max_loan_amt?.toLocaleString("en-IN")}</p>
                     </div>
                     {/* <div>
                         <p className='pa-congrats-p'>ROI(PM)</p>
                         <p className='pa-congrats-ptwo'>{(second.applicable_roi / 12).toFixed(2)}%</p>
                     </div> */}
                     <div>
                         <p className='pa-congrats-p'>Tenure</p>
                         <p className='pa-congrats-ptwo'>{second.max_tenure}</p>
                     </div>
                     {/* <div>
                         <p className='pa-congrats-p'>EMI as low as</p>
                         <p className='pa-congrats-ptwo'>₹ {emi2 ? emi2.toLocaleString("en-IN") : ''}</p>
                     </div> */}
                 </div>
                 
                 <div className="partner-pre-btn-unlck">
                     <p>Bank Verification Required<span className='pre-approved-prystine-p'><br/>Over Secure RBI Approved AA</span></p>
                     
                     <button >Unlock Now</button>
                 </div>
             </div>}
             {/* <button id="last" disabled={selected == "" ? true : false} onClick={() => handleClick()} className={"login-button" + (selected ? " login-btn-active" : " ")} style={selected == 2 ? { backgroundColor: "darkblue" } : {}}>
                 {!resp ? (selected == 2 ? "Unlock Now" : 'Avail Now') : <div className='continue-lottie'>
                     <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                 </div>}
             </button> */}
            <p className='pa-offer-note-imp'>NOTE : You cannot change this offer at later stage.</p>
         </div>
            :
             <div className='pa-congrats-main-div'>
             {first.length !== 0 && <div onClick={handlefirst} className='pa-congrats-main-div-one'>
                 <div className='pa-congrats-one part-pay-radio-box'>
                     <div className='pa-congrats-div-main'>
                         <img src={one} alt='' />
                         <p>{first.program_name}<b style={{marginLeft:"20px"}}>₹ {first?.max_loan_amt?.toLocaleString("en-IN")}</b></p>

                         {/* <div onClick={() => setSelected(1)}>
                             <input type='radio' checked={firstclick} name='congo' />
                         </div> */}
                     </div>

                 </div>

                 <div className='pa-congrats-one-main'>
                     {/* <div>
                         <p className='pa-congrats-p'>Loan Amount</p>
                         <p className='pa-congrats-ptwo'>₹ {first[0]?.max_loan_amt?.toLocaleString("en-IN")}</p>
                     </div> */}
                     <div>
                         <p className='pa-congrats-p'>ROI(PM)</p>
                         <p className='pa-congrats-ptwo'>{(first.applicable_roi / 12).toFixed(2)}%</p>
                     </div>
                     <div>
                         <p className='pa-congrats-p'>Tenure</p>
                         <p className='pa-congrats-ptwo'>{first.max_tenure}</p>
                     </div>
                     <div>
                         <p className='pa-congrats-p'>EMI as low as</p>
                         <p className='pa-congrats-ptwo'>₹ {emi1 ? emi1.toLocaleString("en-IN") : ''}</p>
                     </div>
                 </div>
                 {/* <div> */}
                 {/* <ul>
                     <li>Bank Statement/Income<br/> docs not required</li>
                     <button >Avail Now</button>
                 </ul> */}
                 {/* </div> */}
                 <div className="partner-pre-btn">
                     <p>No income proof / Bank statement<br/> required</p>
                     <button >Avail Now</button>
                 </div>
             </div>}
             {second.length !== 0 && <div onClick={handlesecond}  className='pa-congrats-main-div-two'>
                 <div className='pa-congrats-one part-pay-radio-box'>
                     <div className='pa-congrats-div-main'>
                         <img src={two} alt='' />
                         <p>{second.program_name} <b style={{marginLeft:"20px"}}>₹ {second?.max_loan_amt?.toLocaleString("en-IN")}</b></p>
                         {/* <div onClick={() => setSelected(2)}>
                             <input type='radio' checked={secondclick} name='congo' />
                         </div> */}
                     </div>

                 </div>

                 <div className='pa-congrats-one-main'>
                     {/* <div>
                         <p className='pa-congrats-p'>Loan Amount</p>
                         <p className='pa-congrats-ptwo'>₹ {second[0]?.max_loan_amt?.toLocaleString("en-IN")}</p>
                     </div> */}
                     <div>
                         <p className='pa-congrats-p'>ROI(PM)</p>
                         <p className='pa-congrats-ptwo'>{(second.applicable_roi / 12).toFixed(2)}%</p>
                     </div>
                     <div>
                         <p className='pa-congrats-p'>Tenure</p>
                         <p className='pa-congrats-ptwo'>{second.max_tenure}</p>
                     </div>
                     <div>
                         <p className='pa-congrats-p'>EMI as low as</p>
                         <p className='pa-congrats-ptwo'>₹ {emi2 ? emi2.toLocaleString("en-IN") : ''}</p>
                     </div>
                 </div>
                 
                 <div className="partner-pre-btn-unlck">
                     <p>Bank Verification Required<span className='pre-approved-prystine-p'><br/>Over Secure RBI Approved AA</span></p>
                     
                     <button >Unlock Now</button>
                 </div>
             </div>}
             {/* <button id="last" disabled={selected == "" ? true : false} onClick={() => handleClick()} className={"login-button" + (selected ? " login-btn-active" : " ")} style={selected == 2 ? { backgroundColor: "darkblue" } : {}}>
                 {!resp ? (selected == 2 ? "Unlock Now" : 'Avail Now') : <div className='continue-lottie'>
                     <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
                 </div>}
             </button> */}
            <p className='pa-offer-note-imp'>NOTE : You cannot change this offer at later stage.</p>
         </div>
            }
        </>
    )
}

export default PartnerOfferSelection