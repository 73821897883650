import React from 'react';
import '../../../LoginPopUp/LoginPopUp.css';
import ErrorRocket from '../../../../Icons/ErrorRocket.svg';
import { useSelector,useDispatch } from 'react-redux';
import { updateFormState } from '../../../../Redux/action';

const NewErrorCase = ({handleClick}) => {
    return (
        <div className="blur-background" style={{zIndex: "9999"}}>
            <div className="pop-content">
                <div className="main-popUP-form">
                    <div className="logo-img">
                        <img src={ErrorRocket} alt="" />
                    </div>
                    <div className="err-head">
                        <div className="err-heading">Error!</div>
                        <div className="err-content">We are currently facing some technical Issues. Please wait or try again after sometime</div>
                    </div>
                    <button className="refresh" onClick={(e)=>handleClick(e)}>TRY AGAIN</button>
                </div>
            </div>
        </div>
    )
}

export default NewErrorCase;