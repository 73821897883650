import React, { useState } from 'react';
import './LoginArea.css';
import thunder from '../../Icons/thunder.svg';
import playStore from '../../Icons/money-wide-app-google-play-store.webp'
import Mobile from '../LoginPopUp/Mobile';
import LoginOTPScreen from '../LoginPopUp/LoginOTPScreen';
import '../LoginPopUp/LoginPopUp.css';
import WrongOtp from '../NotFound/WrongOtp';
import { react_base_url } from '../Secret_Credentials/Secret_Credential';
import CKYCWaiting from '../LoginPopUp/CKYCWaiting'


const LoginArea = () => {
    const [currentPage, setCurrentPage] = React.useState(1)
    // const [changed, setChanged] = React.useState(true)
    // const [loader, setLoader] = React.useState(false)
    // const [userNo, setUserNo] = React.useState("")
    // const [otpId, setOTPId] = React.useState("")
    // const [whatsappToggel, setWhatsappToggel] = React.useState(true);
    const [state, setState] = useState({ changed: true, loader: false,
        userNo: "", otpId: "", whatsappToggel: true });

    React.useEffect(() => {
        if ((sessionStorage.getItem("isLogin") === '1')) {
            sessionStorage.removeItem('isLogin')
        }
    }, [])

    return (
        <div className='login-area-background'>
            {state.loader && <CKYCWaiting />}
            <div className="login-area-content">
                <div className="login-area-imgbox" >
                    <div className="Left-loginArea-con">
                        <h2 className="login">Hassle-free Personal Loan <br /><span>Within Minutes </span>
                            <img src={thunder} alt="NA" /></h2>
                        <p>Download the App </p>
                        <img width="120px" src={playStore} alt="NA" />
                    </div>
                    <img className='mob' src={`${react_base_url}assets/images/mobile.svg`} alt="NA" />
                </div>
                <div className="main-login-content">
                    {/* {currentPage === 1 && <Mobile setOTPId={setOTPId} setCurrentPage={setCurrentPage} setUserNo={setUserNo} setLoader={setLoader} whatsappToggel={whatsappToggel} setWhatsappToggel={setWhatsappToggel} />} */}
                    {currentPage === 1 && <Mobile state={state} setCurrentPage={setCurrentPage} setState={setState}/>}
                    {/* {currentPage === 2 && otpId && <LoginOTPScreen userNo={userNo} setCurrentPage={setCurrentPage} changed={changed} setLoader={setLoader} setChanged={setChanged} otpId={otpId} setOTPId={setOTPId} whatsappToggel={whatsappToggel} />} */}
                    {currentPage === 2 && state.otpId && <LoginOTPScreen state={state} setCurrentPage={setCurrentPage} setState={setState} />}
                    {currentPage === 2 && !state.otpId && <WrongOtp />}
                </div>
            </div>
        </div>
    )
}

export default LoginArea;