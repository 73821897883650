import React, { useEffect } from 'react';
import './PersonalLoan_Prepayment_Calculator.css';
import PrepaidCal from "../PersonalLoan/images/prepaid-cal.svg";
// import FAQSection from '../FAQSection/FAQSection';
import Calculator from '../PersonalLoan/PrePaymentCalculator';
import RightSidePopUp from '../Right Side PopUp/RightSidePopUp';
import greenticknew from '../../Icons/greenticknew.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductId } from '../../Redux/action';
import HittingMetaTagApi from '../Instant Personal Loan/HittingMetaTagApi';
import { Helmet } from 'react-helmet';
import FAQSectionChk from '../FAQ/FAQ';

const PersonalLoan_Prepayment_Cal = () => {
    var loan_type_id_page = 56;
    const dispatch = useDispatch()
    const { mwpages } = useSelector(state => state);
    const [y, sety] = React.useState(true);
    window.addEventListener("scroll", (event) => {
        if (window.scrollY >= 2237) {
            sety(false);
        }
        else {
            sety(true);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateProductId(loan_type_id_page))
    }, [])
    return (
        <>
            <Helmet>
                <title>{mwpages?.metatagsdata?.page_title ? mwpages.metatagsdata.page_title : ""}</title>
                <meta name="description" content={mwpages?.metatagsdata?.page_description ? mwpages.metatagsdata?.page_description : ""} />
                <meta name="keywords" content={mwpages?.metatagsdata?.page_keywords ? mwpages.metatagsdata.page_keywords : ""} />
            </Helmet>
            <HittingMetaTagApi />
            <div style={{ marginTop: "100px" }}>
                {y && <RightSidePopUp />}
                <section style={{ marginBottom: "100px" }}>
                    <Calculator />
                </section>
                <section className="what-is">
                    <div className="ploan-container">
                        <div className="ploan-content1">
                            <h4 className="heading-h2 bot-6 add-btom-line cetnred">What is a Personal Loan <b> Part-Payment?</b></h4>
                            <p className="heading-p">Personal Loan Prepayment is the process of repaying the outstanding loan amount before the pre-determined loan tenure. This results in either reduction of the EMI amount for the remaining tenure or reducing the loan tenure with the same EMI amount. MoneyWide’s Part Prepayment Calculator helps you calculate the amount you can prepay on your loan. By inputting details such as the loan amount, interest rate, and tenure, the calculator can estimate the amount that could be saved by making part prepayments. This information can help you to make informed decisions about when and how much to prepay, potentially reducing the overall interest paid and shortening the loan tenure.</p>
                        </div>
                    </div>
                </section>

                <section className="eligibility-criteria">
                    <div className="ploan-container">
                        <div className="box-sh">
                            <div className="eligibility-criteria-box">
                                <h2 className="heing bot-6 lefting add-btom-line">How to Use MoneyWide Personal Loan <b>Partpayment Calculator?</b></h2>
                                <div className="ploan-row-criteria">
                                    <div className="colm-2">
                                        <div className="steps-to-loan">
                                            <p>You can calculate the revised EMI, tenure, and EMI savings by entering the following details through the MoneyWide Partpayment calculator.</p>
                                        </div>
                                        <div className="mlc-pl-list mlc-pl-mrgin-top">
                                            <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span>Loan Amount</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span>Loan Tenure</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span>Rate of Interest</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span>Number of EMIs paid</span>
                                            </div>
                                            <div className="mlc-pl-list-cont wd-font elgib-cont-font">
                                                <img src={greenticknew} alt="tick" />
                                                <span>Part-payment amount</span>
                                            </div>
                                        </div>
                                        <div className="steps-to-loan">
                                            <p>The calculator will consider all the factors and demonstrate the revised EMI amount or tenure and EMI savings after the part prepayment.</p>
                                        </div>
                                    </div>
                                    <div className="colm-1">
                                        <img src={PrepaidCal} alt="Prepaid Calculator" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="FAQs">
                    <FAQSection />
                </section> */}
                {mwpages.faqdata.length !== 0 ? <section style={{ margin: "50px 0" }}>
                    <FAQSectionChk data={mwpages.faqdata} />
                </section> : ""}
            </div>
        </>

    )
}

export default PersonalLoan_Prepayment_Cal