import React, { useState, useEffect } from "react";
import loan_icon from "../../../Icons/loan_icon.svg";
import DropDownIcon from "../../../Icons/DropDownIcon.svg";
import rupee from '../../../Icons/rupee.svg'
import { useSelector, useDispatch } from "react-redux";
import "./DesiredLoanAmount.css";
import Base64 from 'base-64'
import {
  updateMasterState,
  setPreApprovedOfferDetails,
  updateFormState,
  GenerateToken,
} from "../../../Redux/action";
import FormHead from "../Form/FormHead";
import { numberToWords } from "amount-to-words";
import axios from "axios";
import {
  react_base_url,
} from "../../Secret_Credentials/Secret_Credential";
import Lottie from "react-lottie-player";
import ctaAnimation from "../../assests/lootie-files/continue.json";
import { getMasterData, getToken, SubmitLoanDetail, WebOffer } from "../../EverCalledFunctions/EverCalledApis";
import CKYCWaiting from "../../LoginPopUp/CKYCWaiting";
import { RelocateUTMCheck, gettingerror } from "../../EverCalledFunctions/EverCalledFunctions";

function DesiredLoanAmount() {
  const [resp, setResp] = useState(false);
  const [amountInText, setAmountInText] = useState("");
  const [showsalary,setShowsalary] = React.useState('');
  const [extraField, setExtraField] = useState(false);
  const [PurposeLoanPopUpData, setPurposeLoanPopUpData] = useState({});
  const [PreData, setPreData] = useState("")

  const {
    master_state,
    credentialState,
    preApprovedOfferDetails,
    mw_application_id,
    formStates,
    decisionDemog,
    loginDetails,
    token,
    ckycDetails
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState({
    loanAmount: "",
    others: "",
    pol: "",
    displayPOL: "",
  });
  const [loader, setLoader] = React.useState(true);

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  useEffect(() => {
    if (token == "") {
      myToken();
    }
    setTimeout(() => {
      setLoader(false);
    },5000)
  }, [])

  useEffect(() => {
    if (PreData !== "") {
      if ((+(PreData?.data?.loan_amount) > 0) && (PreData?.data?.purpose_of_loan !== "")) {
        setFormData({
          ...formData,
          loanAmount: PreData.data.loan_amount,
          pol: PreData.data.purpose_of_loan,
          displayPOL: PreData.data.purpose_of_loan_name,
          others: PreData.data.purpose_name
        })
      } else if ((+(PreData?.data?.loan_amount) > 0) || (PreData?.data?.purpose_of_loan !== "")) {
        if (+(PreData?.data?.loan_amount) > 0) {
          setFormData({
            ...formData,
            loanAmount: PreData.data.loan_amount,
          })
        } else {
          setFormData({
            ...formData,
            pol: PreData.data.purpose_of_loan,
            displayPOL: PreData.data.purpose_of_loan_name,
          })

        }
      }
    }
    else if((token !== "")) {
      if(loginDetails?.preData){
      setPreData(loginDetails.preData)
      }
      setTimeout(() => {
        getPopUpData("purpose_of_loan");
      },500)
      
    }
  }, [PreData, token])


  useEffect(() => {
    if (master_state?.pol) {
      setFormData({
        ...formData,
        displayPOL: master_state.pol.value,
        pol: master_state.pol.id,
      });
    }
    if ((formData?.loanAmount) < 10000) {
      setAmountInText("Minimum amount is 10,000");
    } else {
      setAmountInText(numberToWords(formData.loanAmount));
    }
  }, [formData.loanAmount, master_state]);

  React.useEffect(()=>{
    var str = amountInText;
    var arr =str.split(" ");
    if(arr[arr.length-1] === 'And'){
        str=str.replace("And","");
    }
setShowsalary(str);
  },[amountInText])

  const onSubmit = async (result) => {
    setResp(true);
    result = { ...result, customer_id: credentialState.cust_id, loan_amount: result.loanAmount, purpose_of_loan: result.pol, purpose_name_other: result.others, mw_application_id: mw_application_id }

    const APIDATA = await SubmitLoanDetail(result, "")
    if (APIDATA.status_code === "200" && APIDATA.status === "1") {
      gettingerror('submit loanreq web',mw_application_id)
      if ((decisionDemog?.is_junk1 == 1) || (decisionDemog?.is_junk2 == 1) || (ckycDetails.cust_eligible != 1)) {
        gettingerror('loan rejected web',mw_application_id)
        let qry = Base64.encode(mw_application_id)
        qry = Base64.encode(qry)
        RelocateUTMCheck(`${react_base_url}thank-you/?fid=${qry}&__temp_id=16`);
        // window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=16`;
      }
      else {
        if(decisionDemog.dual_offer_journey_flag == 1){
          dispatch(updateFormState({...formStates,LoanDetails:false,OfferNew:true}))
        }
        else{
          dispatch(setPreApprovedOfferDetails({
            ...preApprovedOfferDetails,
            state: 1
          }))
          setResp(false);
        }
      }

    } else {
      setResp(false)
      dispatch(updateFormState({ ...formStates, error: true }))
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const getPopUpData = async (type) => {
    const APIDATA = await getMasterData(type, token)
    setPurposeLoanPopUpData(APIDATA);
  }


  const showLoanPurposePopUp = () => {
    dispatch(
      updateMasterState({
        ...master_state,
        values: PurposeLoanPopUpData,
        status: true,
        heading: "Select your purpose of loan",
        pol: null,
      })
    );
  };

  const BlurThis = (event) => {
    if (event.target.value > 0) {
      setFormData({
        ...formData,
        loanAmount: +formData?.loanAmount?.toLocaleString("en-IN"),
      });
    } else {
      setFormData({
        ...formData,
        loanAmount: "",
      });
    }
  };

  const handleOnFocusIncome = (e) => {
    setFormData({
      ...formData,
      loanAmount: formData.loanAmount.toString(),
    });
  };

  useEffect(() => {
    if (formData.displayPOL === "Others") {
      setExtraField(true);
    } else {
      setExtraField(false);
      setFormData({ ...formData, others: "" });
    }
  }, [formData.displayPOL]);

  const handleUpKeyNumber = (value) => {
    var regexp = /[^0-9]/g;
    if (value.match(regexp)) {
      setFormData({ ...formData, loanAmount: value.replace(regexp, "") });
    }
  };

  const SpaceManage = () => {
    if(formData.others.length!==0){
      setFormData({ ...formData, others: formData.others.replace(/^\s+/, '') });
    }
  };

  const Blured = (field, value) => {
    var newVal = "";
    newVal = value?value.trimEnd().replace(/  +/g, " "):"";
    setFormData({ ...formData, others: newVal });
  };

  return (
    <div>
      <>
      {loader && <CKYCWaiting/>}
      <FormHead
        formName="Loan Requirement"
        formSubHead={"Let us know your loan requirement"}
      />
      <div className="desired-loan-amount-main" style={{ marginTop: "10%" }}>
        <div
          className={
            formData.loanAmount
              ? formData.loanAmount < 10000
                ? "desired-loan-amount-input1 error-border"
                : "desired-loan-amount-input1 desired-loan-amount-input1-active"
              : "desired-loan-amount-input1"
          }
          style={{ marginBottom: "0" }}
        >
          <img src={loan_icon} alt="Image not found" />
          <input disabled={resp}
            type="tel"
            minLength={5}
            maxLength={9}
            name="loanAmount"
            placeholder="Loan Amount"
            className="pointer-cur"
            onChange={(e) => handleChange(e)}
            value={formData?.loanAmount?.toLocaleString("en-IN")}
            autoComplete="off"
            onKeyUp={() => handleUpKeyNumber(formData.loanAmount)}
            onFocus={(e) => handleOnFocusIncome(e)}
            onBlur={(e) => BlurThis(e)}
          />
        </div>
        {formData.loanAmount && (
          <div
            className={formData.loanAmount < 10000 ? "err-text" : "api-text"}
          >
            {showsalary}
          </div>
        )}

        <div
          className={
            formData.displayPOL
              ? "desired-loan-amount-input1 desired-loan-amount-input1-active"
              : "desired-loan-amount-input1"
          }
        >
          <img src={loan_icon} alt="Image not found" />
          <input disabled={resp}
            type="tel"
            placeholder="Purpose of Loan"
            value={formData.displayPOL}
            onFocus={() => showLoanPurposePopUp()}
            autoComplete="off"
            readOnly
            style={{ cursor: "pointer" }}
          />
          <img
            src={DropDownIcon}
            alt="Image not found"
            className="small-the-icon"
          />
        </div>
        {extraField && (
          <>
            <div
              className={
                formData.others
                  ? formData.others.length < 3
                    ? "desired-loan-amount-input1 desired-loan-amount-input1-active remove-bottom error-border"
                    : "desired-loan-amount-input1 desired-loan-amount-input1-active remove-bottom"
                  : "desired-loan-amount-input1 remove-bottom"
              }
            >
              <img src={loan_icon} alt="Image not found" />
              <input disabled={resp}
                type="text"
                name="others"
                placeholder="Enter Purpose of Loan"
                onChange={(e) => handleChange(e)}
                value={formData.others}
                autoComplete="off"
                onBlur={() => Blured("others", formData.others)}
                onKeyUp={() => SpaceManage()}
              />
            </div>
            {formData.others && formData.others.length < 3 && (
              <div className="err-text">Enter At least 3 Characters</div>
            )}
          </>
        )}
      </div>
      {extraField ? (
        <button
          type="submit"
          style={{ marginTop: "30px" }}
          disabled={
            !formData.displayPOL ||
            formData.loanAmount < 10000 ||
            formData.others.length < 3
          }
          className={
            "footer-btn Tmargin " +
            (formData.displayPOL &&
              formData.loanAmount >= 10000 &&
              formData.others.length > 2
              ? " footer-btn-activate Tmargin"
              : "")
          }
          onClick={() => onSubmit(formData)}
        >
          {!resp ? (
            "Continue"
          ) : (
            <div className="continue-lottie">
              <Lottie
                loop
                animationData={ctaAnimation}
                play
                className="ctn-loader"
              />
            </div>
          )}
        </button>
      ) : (
        <button
          type="submit"
          disabled={!formData.displayPOL || formData.loanAmount < 10000 || resp}
          className={
            "footer-btn Tmargin Bmargin" +
            (formData.displayPOL && formData.loanAmount >= 10000
              ? " footer-btn-activate"
              : "")
          }
          onClick={() => onSubmit(formData)}
        >
          {!resp ? (
            "Continue"
          ) : (
            <div className="continue-lottie">
              <Lottie
                loop
                animationData={ctaAnimation}
                play
                className="ctn-loader"
              />
            </div>
          )}
        </button>
      )}
    </>
    </div>
  );
}

export default DesiredLoanAmount;
