import React,{ useState } from 'react';
import './About.css';
import aboutHand from '../../Icons/about-hand.svg'
import instant from '../../Icons/instant-process.svg'
import digital from '../../Icons/digital.svg'
import flexibleProducts from '../../Icons/flexible-products.svg'
import uilt from '../../Icons/built-in-transparency.svg'
import ConventionalVsMoneywide from './ConventionalVsMoneywide';
import GroupBrands from '../HomePage/GroupBrands';
import MobileFullPageSlider from './MobileFullPageSlider';
import AboutFullPageSlider from './AboutFullPageSlider';
import MobileConventionalSlider from './MobileConventionalSlider';
import timeToProcess from '../../Icons/timeToProcess.svg'
import Disbursal from '../../Icons/Disbursal.svg'
import userKyc from '../../Icons/user-kyc.svg'
import building from '../../Icons/about-building.svg'
import hand from '../../Icons/handMoney.svg'

const About = () => {
    const [show, setShow] = useState(false)
    const data = [
        {
            url: '/instant-personal-loan',
            head1: "Instant",
            head2: "Personal Loan",
            para: "Get quick funds with our instant loan services - no hassle, no long wait times, 100% digital process.",
            background: "#8A6AFD"
        },
        {
            url: '/new-credit-personal-loan-mw-first',
            head1: "New to Credit",
            head2: "Personal Loan",
            para: "Build your financial future with our new-to-credit loan, which requires zero credit history.",
            background: "#FD8104"
        },
        {
            url: '/short-term-personal-loan',
            head1: "Short Term",
            head2: "Personal Loan",
            para: "Get the financial boost you need with a short-term loan - quick, convenient, and tailored to your immediate needs.",
            background: "#FD5B4D"
        }
    ]
    const conven = [
        {
            left: 'Branches, Referrers, Cold Calling',
            right: 'Web, App, Partner APIs',
            img: building,
            imgtext: "Origination"
        },
        {
            left: 'Bureau Consent, Wet Sign',
            right: 'Web, App, ekyc',
            img: userKyc,
            imgtext: "Apply, KYC & Bureau"
        },
        {
            left: 'By Credit Manager',
            right: 'System Generated',
            img: hand,
            imgtext: "Offer Generation"
        },
        {
            left: 'Stamp Paper, Agreement, Wet Sign, etc.',
            right: 'e-agreement, e-sign, e-Nach, etc.',
            img: Disbursal,
            imgtext: "Disbursal"
        },
        {
            left: '3 to 10 days',
            right: '3 to 5 minutes',
            img: timeToProcess,
            imgtext: "Time to Process"
        }]
    React.useEffect(() => {
        window.scrollTo(0, 0);
        if (window.innerWidth < 600) {
            setShow(true);
        }
    }, [window.innerWidth]);

    const SliderData = [
        {   
            mainheading1: "Our",
            mainheading2: "Tenets",
            heading: "Instant Process",
            context: "From approval to disbursement, the process is seamless and instant.",
            list: [
                'In-Principle approval within 30 seconds',
                'Quick Processing with same day disbursement'
            ],
            url: "/personal-loan",
            img: instant
        }, {
            heading: "100% Digital",
            context: "Get approval on your personal loan through 100% online paperless application.",
            list: [
                'Nil physical process and paperwork',
                'Disbursement through KYC'
            ],
            url: "/personal-loan",
            img: digital
        }, {
            heading: "Flexible Products",
            context: "Choose the desired personal loan with flexible repayment tenure and loan amount.",
            list: [
                'Choice of term loan and credit line',
                'Multiple programs with flexible tenure and amount'
            ],
            url: "/personal-loan",
            img: flexibleProducts
        }, {
            heading: "Built-in Transparency",
            context: "MoneyWide offers transparent disclosure of loan terms, fees & annual percentage rate.",
            list: [
                'Fully transparent and no hidden charges',
                'Rule-based decisions from the system'
            ],
            url: "/personal-loan",
            img: uilt
        }
    ]

    return (
        <section className='about-section'>
            <div className="main-about">
                <div className="box-sh">
                    <div className="eligibility-criteria-box">
                        <div className="left-top">
                            <a href='/'>Home</a>  &gt;{" "}
                            <span>About Us</span>
                        </div>
                        <h2 className="about-heading">Who <span>we are</span></h2>
                        <div className="about-p">
                            <p>MoneyWide is a digital lending application run by MyLoanCare Ventures Private Limited, a part of the PB Fintech Group that runs India’s leading marketplaces, Policybazaar and Paisabazaar.</p>
                            <p>We currently offer highly differentiated, flexible, convenient, transparent, and fully digital lending solutions. We aim to make financial products affordable and easily accessible to every Indian who needs financial assistance the most. Our paperless and instant financing products are delivered in real time using cutting edge technology over 7,500 pin codes in 125 cities across the length and breadth of India.</p>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="what-we-do">
                <div className="box-sh">
                    <div className="eligibility-criteria-box">
                        <h2 className="about-heading">What <span>we do</span></h2>
                        <div className="product-box">
                            {data.map((row, ind) => {
                                return <a key={ind} className='p-box' style={{ backgroundColor: `${row.background}` }} href={row.url}>
                                    <div className="head">
                                        <img src={aboutHand} alt="na" />
                                        <div className="right-head">
                                            <span>{row.head1}</span>
                                            <h3>{row.head2}</h3>
                                        </div>
                                    </div>
                                    <p>{row.para}</p>
                                </a>
                            })}
                        </div>
                        <p className="we-do-para">We here at MoneyWide offer unsecured Personal loans to fulfill various personal or business-related financial requirements such as unplanned medical emergencies, education, rental deposit, planning a vacation, home renovation, etc. The loan can be availed digitally with instant paperless approval and online KYC. The process takes less than 30 seconds for in-principle approval with same day instant disbursal. We also have specially crafted loans for first time borrowers.</p>
                    </div>
                </div>
            </div>
            <div className="about-slide-show">
                {show && <MobileFullPageSlider givenData={SliderData} />}
                {!show && <AboutFullPageSlider givenData={SliderData} />}
            </div>
            <div className='about-slider' style={{marginTop:"80px"}}>
                <div className="box-sh">
                    <div className="eligibility-criteria-box"></div>
                    <div className="about-heading convvsmw">Conventional NBFC <br/> vs <br/> <span>MoneyWide</span></div>
                    {!show && <div className="second-head">
                        <h2 className="about-heading"><span style={{fontSize:"28px"}}>Conventional NBFC</span></h2>
                        <h2 className="about-heading"><span style={{fontSize:"28px"}}>MoneyWide</span></h2>
                    </div>}
                    {!show && <ConventionalVsMoneywide givenData={conven}/>}
                    {show && <MobileConventionalSlider givenData={conven}/>}
                </div>
            </div>
            <div className="about-grp" style={{marginTop:"50px"}}>
                <GroupBrands />
            </div>
        </section>
    )
}

export default About;