import React from "react"

export const  SlickNext = (props)=> {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        className='mw-announce-arrow-des'
      >
        <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_4484_26403)">
<rect x="30" y="20" width="50" height="50" rx="10" fill="white"/>
<path d="M51.7856 51.4285L58.2142 44.9999L51.7856 38.5713" stroke="#6D57DF" strokeWidth="1.78947" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<filter id="filter0_d_4484_26403" x="0" y="0" width="110" height="110" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="10"/>
<feGaussianBlur stdDeviation="15"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0666667 0 0 0 0 0.207843 0 0 0 0 0.439216 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4484_26403"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4484_26403" result="shape"/>
</filter>
</defs>
</svg>

      </div>
    );
  }