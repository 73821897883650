import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import back from '../../Icons/back.svg'
import { GenerateToken } from '../../Redux/action'
import { getToken } from '../EverCalledFunctions/EverCalledApis'
import CKYCWaiting from '../LoginPopUp/CKYCWaiting'
import { GetRepaymentDetailsAPI, getLoginData } from './MyAccountAPIs'
import LoanDetails from './LoanDetails.jsx'
import PartPaymentForm from './PartPaymentForm'
import yellownotify from '../../Icons/yellownotify.svg'
import pendingIcon from '../../Icons/pendingIcon.svg'
import closeOption from '../../Icons/closeOption.svg'
import NewErrorCase from '../Journey Form/Form/All_Forms/NewErrorCase'

const PartPayment = () => {
  const [data, setData] = useState({})
  const [loader, setLoader] = useState(true)
  const [APIErr, setAPIErr] = useState(false)
  const [partpaymentFound, setPartPaymentFound] = useState(1)
  const { token } = useSelector(state => state)
  const [userName, setUserName] = useState("")
  const [values, setValues] = useState({
    max: "",
    min: ""
  })
  const [loanName, setloanName]= useState("loan")
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleAmountValues = (outstandingPercent, emiPaid, amt) => {
    var out_Val = (amt * outstandingPercent) / 100;
    setValues({ ...values, min: emiPaid, max: out_Val })
  }

  const getUserData = async (mw_app_id) => {
    const APIResponse = await GetRepaymentDetailsAPI(mw_app_id, token);
    if (APIResponse.status_code === "200" && APIResponse.status === "1") {
      setData(APIResponse.data)
      if (APIResponse.data.checkPartpayment == 1) {
        setPartPaymentFound(1);
      } else {
        setPartPaymentFound(3);
      }
      handleAmountValues(APIResponse.data.outstandingPercent, APIResponse.data.emiPaid, APIResponse.data.summary.totalOutstanding)
      setLoader(false)
    } else {
      setLoader(false)
      setAPIErr(true)
    }
  }

  useEffect(() => {
    if (token !== "") {
      let payLoad = getLoginData();
      if (!payLoad && !payLoad.mw_app_id) {
        navigate('/login')
      } else if ((payLoad.mw_app_id !== undefined && payLoad.mw_app_id !== "")) {

        if (payLoad.userName != undefined && payLoad.userName !== "") {
          setUserName(payLoad.userName)
        }
        if(payLoad.lead_source_flag == 1){
          setloanName("healthcare credit")
        }
        getUserData(payLoad.mw_app_id);
      }
    }
  }, [token])


  const mytoken = async () => {
    const T = await getToken();
    dispatch(GenerateToken(T))
  }

  useEffect(() => {
    if (token === "") {
      mytoken()
    }
  }, [])

  const RemoveSessionData = () => {

  }

  const InvalidCase = () => {
    return <div className="foreclose-yellow-box yellow-box-spacing">
      <img src={yellownotify} alt="yellownotify" />
      <span>Dear <b>{userName}</b>, You are not eligible to partpayment your {loanName} today. Please try again after some time.</span>
    </div>
  }

  const NotLink = () => {
    return <div className="foreclose-yellow-box yellow-box-spacing">
      <img src={yellownotify} alt="yellownotify" />
      <span>Dear <b>{userName}</b>, <br /> Sorry for the inconvenience. We are facing some technical error. Please try again after some time.<br />Regards <br />Team MoneyWide</span>
    </div>
  }
  const manageRefresh = () => {
    setLoader(true)
    setAPIErr(false)
    let payLoad = getLoginData();
    if (!payLoad && !payLoad.mw_app_id) {
      navigate('/login')
    }
    else if (payLoad.mw_app_id != undefined && payLoad.mw_app_id !== "") {
      getUserData(payLoad.mw_app_id);
    }
    
  }
  return (
    <>
      {APIErr && !loader && <NewErrorCase handleClick={manageRefresh} />}
      {loader && <CKYCWaiting />}
      {!loader && !APIErr && <div className='transaction-main'>
        <div className='trasaction-head'>
          <a href="/myaccount/myloan" onClick={() => RemoveSessionData()}><img className="" src={back} alt="back-img" /></a>
          <span>Part Payment Request</span>
        </div>
        <LoanDetails data={data} />
      </div>}


      {partpaymentFound === 1 && <PartPaymentForm preData={data} values={values} setPartPaymentFound={setPartPaymentFound} />}
      {partpaymentFound === 2 &&
        <div className="blur-background">
          <div className="pop-content">
            <div className="main-popUP-form manage-myaccount-popup">
              <div className="myc-back-btn foreclosure-back">
                <a href="/myaccount/profile"><img src={closeOption} alt="" /></a>
              </div>
              <img src={pendingIcon} alt="NA" />
              <div className='proceed-popup-content'>
                <span>
                  Dear {userName}
                </span>
                <p>
                  Your <b>{data.programName ? data.programName : '-'} {data.accountNo ? data.accountNo : ""}</b> part payment has been processed. The updated tenure will reflect in your loan account once the payment is successfully received
                </p>
              </div>
            </div>
          </div>
        </div>
      }
      {partpaymentFound === 3 && <InvalidCase />}
      {partpaymentFound === 4 && <NotLink />}
      {/* {partpaymentFound && <PartPaymentForm preData={data} />}
      {!partpaymentFound && <InvalidCase />} */}



    </>
  )
}

export default PartPayment