import React from "react"




export const SlickPrev =(props)=> {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        className="mw-announce-arrow-desscription"
      >
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="49.5" y="49.5" width="49" height="49" rx="9.5" transform="rotate(-180 49.5 49.5)" fill="white"/>
<path d="M28.2143 18.5715L21.7858 25.0001L28.2143 31.4287" stroke="#6D57DF" strokeWidth="1.78947" strokeLinecap="round" strokeLinejoin="round"/>
<rect x="49.5" y="49.5" width="49" height="49" rx="9.5" transform="rotate(-180 49.5 49.5)" stroke="#E4E2F8"/>
</svg>

      </div>
    );
  }