import React, { useEffect, useState } from 'react';
import '../Form.css';
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { updatePopUp, updateMasterState, updateFormState, updateSuggestion_DropDown, updateDecisionDemog, GenerateToken,  updatePartnerState } from '../../../../Redux/action';
import bag from './../../../../Icons/bag.svg';
import email from '../../../../Icons/email.svg';
import ExperienceYearPopup from '../../../Popups/ExperienceYearpopup/ExperienceYearPopup';
import FormHead from '../FormHead';
import Lottie from 'react-lottie-player';
import ctaAnimation from '../../../assests/lootie-files/continue.json';
import { getCompanyName, getMasterData, getToken, SubmitEmploymentDetail } from '../../../EverCalledFunctions/EverCalledApis';
import CKYCWaiting from '../../../LoginPopUp/CKYCWaiting';
import { gettingerror } from '../../../EverCalledFunctions/EverCalledFunctions';


const EmployerDetailsThreeTag = ({step_enable}) => {
  const [submitButton, setSubmitButtom] = useState(false)
  const [nameLength, setNameLength] = useState(0)
  const [resp, setResp] = useState(false)
  const [emailSame, setEmailSame] = useState(false)
  const [currentCheck, setCurrentCheck] = useState(false)
  const [employeName, setEmployeName] = useState(false)
  const [loadingTime, setLoaderTime] = useState(true)
  const [selected, setSelected] = useState(-1);
  const [EmployePopUpData, setEmployePopUpData] = useState({});
  const [paymentModeData, setPaymentModeData] = useState({});
  const [DOBYear, setDOBYear] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [preVal, setPreVal] = useState({})
  const [apiData, setAPIData] = useState({
    show: false,
    data: [],
    selected: false
  })
  const [textData, updateTextData] = useState({
    Current: "",
    Total: ""
  })
  const Todays = {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  }
  const [localStore, updateLocalStore] = useState({
    displayEmployeName: "",
    displayCurrentWorkExperience: "",
    displayTotalWorkExperience: ""
  })
  const dispatch = useDispatch();
  const { step_bar, PopUpState, loginDetails, formStates, credentialState, master_state, OfficeEmailDetails, mw_application_id, suggestionDropDown, decisionDemog, token, ckycDetails,partnerStates } = useSelector(state => state)




  const { register, handleSubmit, setValue, formState: { errors }, watch, setError, clearErrors } = useForm({
    defaultValues: {
      current_work_experience: "",
      total_work_experience: "",
      office_email_id: ""
    }, mode: "all"
  })

  const data = watch();
  const monthNames = ["Jan", "Feb",
    "Mar", "Apr",
    "May", "Jun",
    "Jul", "Aug",
    "Sep", "Oct",
    "Nov", "Dec"
  ];

  function toMonthName(monthNumber) {
    return monthNames[monthNumber]
  }

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn))
  }

  const ManageField = (val) => {
    if (val) {
      var year = val.slice(0, 4)
      var month = val.slice(5, 7)
      return monthNames[+(month) - 1] + " " + year
    }
  }

  useEffect(() => {
    if (loginDetails?.empData) {
      if (loginDetails?.empData?.email_id) {
        setUserEmail(loginDetails.empData.email_id);
      }
      if (loginDetails?.empData?.office_email_id) {
        setValue('office_email_id', loginDetails.empData.office_email_id)
      }
      if ((loginDetails?.empData?.twe?.length === 7) && (loginDetails?.empData?.cwe?.length === 7)) {
        setValue('current_work_experience', loginDetails.empData.cwe)
        setValue('total_work_experience', loginDetails.empData.twe)
        updateLocalStore({
          ...localStore,
          displayCurrentWorkExperience: ManageField(loginDetails.empData.cwe),
          displayTotalWorkExperience: ManageField(loginDetails.empData.twe)
        })
      }
      else if ((loginDetails?.empData?.cwe?.length === 7) || (loginDetails?.empData?.twe?.length === 7)) {
        if (loginDetails?.empData?.twe?.length === 7) {
          setValue('total_work_experience', loginDetails.empData.twe)
          updateLocalStore({
            ...localStore,
            displayTotalWorkExperience: ManageField(loginDetails.empData.twe)
          })
        } else if (loginDetails?.empData?.cwe?.length === 7) {
          setValue('current_work_experience', loginDetails.empData.cwe)
          updateLocalStore({
            ...localStore,
            displayCurrentWorkExperience: ManageField(loginDetails.empData.cwe)
          })
        }
      } if (loginDetails?.empData?.company_name) {
        setTimeout(() => {
          setValue('employer_name', loginDetails.empData.company_name)
        }, 100)
      }
    }
    // else {
    //   getData();
    // }
  }, [loginDetails])

  useEffect(() => {
    if (token == "") {
      myToken();
    }
    setTimeout(() => {
      setLoaderTime(false);
    }, 20000)
  }, [])

  useEffect(() => {
    if (loginDetails?.email) {
      setUserEmail(loginDetails.email)
    }

  }, [token])





  useEffect(() => {



    if (PopUpState?.WorkExperiencePopUp?.firstClick) {
      setValue('current_work_experience', PopUpState.WorkExperiencePopUp.year + "-" + (PopUpState.WorkExperiencePopUp.month + 1 < 10 ? "0" + (PopUpState.WorkExperiencePopUp.month + 1) : PopUpState.WorkExperiencePopUp.month + 1))
      updateLocalStore({ ...localStore, displayCurrentWorkExperience: toMonthName(PopUpState.WorkExperiencePopUp.month) + " " + PopUpState.WorkExperiencePopUp.year })
      const updatedWorkExperiencePopUp = { ...PopUpState.WorkExperiencePopUp, firstClick: false }
      dispatch(updatePopUp({ ...PopUpState, WorkExperiencePopUp: updatedWorkExperiencePopUp }))
    }


    if (PopUpState?.TotalWorkExperiencePopUp?.firstClick) {
      setValue('total_work_experience', PopUpState.TotalWorkExperiencePopUp.year + "-" + (PopUpState.TotalWorkExperiencePopUp.month + 1 < 10 ? "0" + (PopUpState.TotalWorkExperiencePopUp.month + 1) : PopUpState.TotalWorkExperiencePopUp.month + 1))
      updateLocalStore({ ...localStore, displayTotalWorkExperience: toMonthName(PopUpState.TotalWorkExperiencePopUp.month) + " " + PopUpState.TotalWorkExperiencePopUp.year })
      const updatedWorkExperiencePopUp = { ...PopUpState.TotalWorkExperiencePopUp, firstClick: false }
      dispatch(updatePopUp({ ...PopUpState, TotalWorkExperiencePopUp: updatedWorkExperiencePopUp }))


    }


  }, [PopUpState, master_state])

  useEffect(() => {
    if (data?.current_work_experience?.length > 7) {

      var str = data.current_work_experience.slice(0, 3);
      str = +(monthNames.indexOf(str)) + 1;
      str = str < 10 ? "0" + str : str;
      var newVal = data.current_work_experience.slice(4, 8) + "-" + str;
      setValue('current_work_experience', newVal)
    }
  }, [data.current_work_experience])

  useEffect(() => {
    if (data?.total_work_experience?.length > 7) {

      var str = data.total_work_experience.slice(0, 3);
      str = +(monthNames.indexOf(str)) + 1;
      str = str < 10 ? "0" + str : str;
      var newVal = data.total_work_experience.slice(4, 8) + "-" + str;
      setValue('total_work_experience', newVal)
    }
  }, [data.total_work_experience])


  useEffect(() => {
    if (localStore?.displayCurrentWorkExperience && localStore?.displayTotalWorkExperience) {
      var TotalTime = new Date(localStore.displayTotalWorkExperience);
      var CurrentTime = new Date(localStore.displayCurrentWorkExperience);
      if (CurrentTime < TotalTime) {
        setValue('total_work_experience', "")
        updateLocalStore({ ...localStore, displayTotalWorkExperience: "" })
        setCurrentCheck(true)
      } else {
        setCurrentCheck(false)
      }
    }
  }, [localStore])


  useEffect(() => {
    var TotatText = "";
    var CurrentText = ""

    if (localStore?.displayCurrentWorkExperience) {
      var time = Math.abs(new Date() - new Date(localStore.displayCurrentWorkExperience));
      var numberOfDays = Math.ceil(Math.abs(time / (1000 * 60 * 60 * 24)));

      var years = Math.floor(numberOfDays / 365);
      var months = Math.floor(numberOfDays % 365 / 30);
      if (years === 0) {
        CurrentText = months + " month of current work experience";
      }
      else if (months === 0) {
        CurrentText = years + " year of current work experience";
      } else {
        CurrentText = years + " year & " + months + " month of current work experience";
      }
    }


    if (localStore?.displayTotalWorkExperience) {
      var time = Math.abs(new Date() - new Date(localStore.displayTotalWorkExperience));
      var numberOfDays = Math.ceil(Math.abs(time / (1000 * 60 * 60 * 24)));

      var years = Math.floor(numberOfDays / 365);
      var months = Math.floor(numberOfDays % 365 / 30);
      if (years === 0) {
        TotatText = months + " month of total work experience";
      }
      else if (months === 0) {
        TotatText = years + " year of total work experience";
      } else {
        TotatText = years + " year & " + months + " month of total work experience"
      }
    }
    updateTextData({ ...textData, Current: CurrentText, Total: TotatText })

  }, [localStore])



  useEffect(() => {
    if (OfficeEmailDetails?.value === 2) {
      onSubmit(data);
    }
  }, [OfficeEmailDetails])





  const onSubmit = async (result) => {
    setResp(true)
    delete result.display_employer_type;


    result = { ...result, mw_application_id, customer_id: credentialState.cust_id, do_you_have_office_email: result.office_email_id ? 1 : 2 }

    const APIDATA = await SubmitEmploymentDetail(result, "")
    if (APIDATA?.status === "1" && APIDATA?.status_code === "200") {
      gettingerror('employment submit web',mw_application_id)
      dispatch(updateDecisionDemog({ ...decisionDemog, is_junk2: APIDATA.data.is_junk }))
      let url = window.location.pathname;
      if(url.includes('/lp')){
        dispatch(updatePartnerState({...partnerStates,PartnerEmployer:false,PartnerSelection:true}))
      }else{
        dispatch(updateFormState({ ...formStates, EmployeeDetails: false, LoanDetails: true }))
      }
    } else {
      dispatch(updateFormState({ ...formStates, error: true }))
      setResp(false)
    }
  }

  useEffect(() => {
    if (loginDetails?.dob) {
      setDOBYear(loginDetails.dob.slice(0, 4))
    } else {
      setDOBYear(new Date().getFullYear() - 25)
    }
  }, [])


  {/* -------------------------------Email Validation Function--------------------------- */ }
  const emailValidator = () => {
    if (!data.office_email_id) return false;
    var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!data.office_email_id.match(validRegex)) {
      return false
    }
    return true;
  }

  const showExpCalendar = () => {
    const updatedWorkExperiencePopUp = { ...PopUpState.WorkExperiencePopUp, show: true, isBubbling: true }
    const updatedOther = { ...PopUpState.TotalWorkExperiencePopUp, show: false, isBubbling: false }
    dispatch(updatePopUp({ ...PopUpState, WorkExperiencePopUp: updatedWorkExperiencePopUp, TotalWorkExperiencePopUp: updatedOther }))
    dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: false }))
  }

  const showToalExpCalendar = () => {
    const updatedWorkExperiencePopUp = { ...PopUpState.TotalWorkExperiencePopUp, show: true, isBubbling: true }
    const updatedOther = { ...PopUpState.WorkExperiencePopUp, show: false, isBubbling: false }
    dispatch(updatePopUp({ ...PopUpState, TotalWorkExperiencePopUp: updatedWorkExperiencePopUp, WorkExperiencePopUp: updatedOther }))
    dispatch(updateSuggestion_DropDown({ ...suggestionDropDown, show: false }))
  }

  const CloseTheCalender = () => {
    const updatedone = { ...PopUpState.TotalWorkExperiencePopUp, show: false, isBubbling: false }
    const updatedOther = { ...PopUpState.WorkExperiencePopUp, show: false, isBubbling: false }
    dispatch(updatePopUp({ ...PopUpState, TotalWorkExperiencePopUp: updatedone, WorkExperiencePopUp: updatedOther }))
  }

  useEffect(() => {
    if (data?.office_email_id === userEmail) {
      setError('office_email_id')
      setEmailSame(true)
    }
    else {
      setEmailSame(false)
      clearErrors(['office_email_id'])
    }
  }, [data.office_email_id])


  useEffect(() => {
    if (!(data?.display_employer_type == "Defence/ Paramilitary" || data?.display_employer_type == "Police")) {
      setValue('employer_name', "")
      setEmployeName(true)
    } else {
      setEmployeName(false)
    }
  }, [data.employer_type])


  useEffect(() => {
    if (data?.current_work_experience && data?.total_work_experience) {
        if (!data?.office_email_id || (data?.office_email_id && !errors.office_email_id && !emailSame)) {
          setSubmitButtom(true)
        } else {
          setSubmitButtom(false)
        }
    }
     else {
      setSubmitButtom(false)
    }
  }, [data])


  const SpaceManage = (field, value) => {
    if (value.length !== 0 && typeof (value) === 'string') {
      setValue(field, String(value).replace(/^\s+|\s+$/g, ""));
    }

  }

  return (
    <>

      {loadingTime && <CKYCWaiting time={20}/>}
      <FormHead formName="Employment Details" formSubHead={"Provide employment details to get the best offer"} progressBar={step_enable} />
      <div className="tag-for-input">
        {/* -------------------------------------------Form Field----------------------------------------- */}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} >
          {/* ---------------------Current Working Experience Field--------------------------------- */}
          <div className={"remove-bottom input-tag this-has-calendar" + ((!data.current_work_experience && errors.current_work_experience) ? " error-border" : data.current_work_experience ? " add-border" : "")}>
            <img className="tag-icon" src={bag} />
            <input disabled={resp} type="text" placeholder='Current Work Experience' value={localStore?.displayCurrentWorkExperience ? localStore.displayCurrentWorkExperience : ""} {...register('current_work_experience', { required: true })} readOnly onFocus={() => showExpCalendar()} id="make" />
            {PopUpState.WorkExperiencePopUp.show && <ExperienceYearPopup DOBYear={DOBYear} />}
          </div>
          {!data.current_work_experience && errors.current_work_experience && <div className='err-text'>Select Current Work Experience</div>}
          {data.current_work_experience && <div className='api-text'>{textData.Current}</div>}


          {/* ---------------------Total Working Experience Field--------------------------------- */}
          <div className={"input-tag this-has-calendar remove-bottom" + ((!data.total_work_experience && errors.total_work_experience) || currentCheck ? " error-border" : data.total_work_experience ? " add-border" : "")}>
            <img className="tag-icon" src={bag} />
            <input disabled={resp} type="text" placeholder='Total Work Experience' value={localStore?.displayTotalWorkExperience ? localStore.displayTotalWorkExperience : ""} {...register('total_work_experience', { required: true })} readOnly onFocus={() => showToalExpCalendar()} id="make" />
            {PopUpState.TotalWorkExperiencePopUp.show && <ExperienceYearPopup DOBYear={DOBYear} />}
            {/* {errors.total_work_experience && <img src={ThisIsAnError} alt="" />} */}
          </div>
          {!data.total_work_experience && errors.total_work_experience && !currentCheck && <div className='err-text'>Select Total Work Experience</div>}
          {data.total_work_experience && <div className='api-text'>{textData.Total}</div>}
          {currentCheck && <div className='err-text'>Total Experience can't be lower than Current Experience</div>}



          {/* -----------------------------------office_email_id Input Field------------------------------- */}

          <div className={"input-tag" + ((errors.office_email_id && data.office_email_id) || (emailSame && data.office_email_id) ? " error-border" : data.office_email_id ? " add-border" : "")} style={{ marginBottom: "0", marginTop: "30px" }}>
            <img className="tag-icon" src={email} />
            <input disabled={resp} className="input-tag-box" type="text" placeholder='Office Email Address'
              {...register('office_email_id', {
                pattern: {
                  value: /^([a-zA-Z0-9_.+-]{3,64})+\@(([a-zA-Z0-9-]{2,255})+\.)+([a-zA-Z0-9]{2,8})+$/
                }
              })} autoComplete="off"
              onFocus={() => CloseTheCalender()}
              onKeyUp={() => SpaceManage("office_email_id", data.office_email_id)}
            />
          </div>
          {errors.office_email_id && data.office_email_id && !emailSame && <div className='err-text' style={{ margin: "0" }}>Invalid Email Address</div>}
          {data.office_email_id && emailSame && <div className='err-text'>Office email & personal email can't be the same </div>}

          {/* -------------------------------------------Submit button----------------------------------------- */}

          <button type='submit' disabled={resp || !submitButton} className={'footer-btn Tmargin Bmargin' + (submitButton ? " footer-btn-activate" : "")} >{!resp ? "Continue" :
            <div className='continue-lottie'>
              <Lottie loop animationData={ctaAnimation} play className="ctn-loader" />
            </div>
          }
          </button>

        </form>
      </div>
    </>
  )
}


export default EmployerDetailsThreeTag;