import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./AipOffer.css";
import {
  GenerateToken,
  setPreApprovedOfferDetails,
  setPreapprovedOfferPopup,
  setSorryPopup,
  updateChoosenAmount,
  updateFormState,
  updateStepBar,
} from "../../../Redux/action";
import FormHead from "../Form/FormHead";
import axios from "axios";
import {
  client_id,
  client_key,
  source,
  prodbase_url,
  verification_source,
  verified_by,
  verifier_name,
  react_base_url,
} from "../../Secret_Credentials/Secret_Credential";
import Helmet from "react-helmet";
import { useState } from "react";
import Lottie from "react-lottie-player";
import ctaAnimation from "../../assests/lootie-files/continue.json";
import { useNavigate, useSearchParams } from "react-router-dom";
import Base64 from "base-64";
import { Check_utm_source, RelocateUTMCheck, encryptLeadIdStepId, encryptOnlyLeadId, gettingerror } from "../../EverCalledFunctions/EverCalledFunctions";
import MainLoader from "../../MainLoader/MainLoader";
import {
  getToken,
  offerSubmissionApi,
  WebOffer,
} from "../../EverCalledFunctions/EverCalledApis";
import CKYCWaiting from "../../LoginPopUp/CKYCWaiting";
import { AxiosThirty } from "../../AxiosConfiguration/AxiosConfig";
import NewErrorCase from "../Form/All_Forms/NewErrorCase";
import queryString from "query-string";
import { FetchCustomerPayload } from "../../EverCalledFunctions/EverCalledPayload";
import { apiInstance } from "../../Config/APIConfig";

function PreApprovedOffer1() {
  // const [tempState, setTempState] = React.useState(false);
  const [resp, setResp] = useState(false);
  const [checkBox, setCheckBox] = useState(true);
  const [addClassName, setAddClassName] = useState(false);
  const [qryStr, setQryStr] = useState("");
  const [defaultFromFocus, setDefaultFromFocus] = useState(-1);
  const {
    pre_approved_offer,
    PopUpState,
    preApprovedOfferDetails,
    choosen_amount,
    mw_application_id,
    credentialState,
    formStates,
    resumeJourney,
    step_bar,
    token,
    decisionDemog
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [emi_arr, setEmiArr] = React.useState([]);
  const [state, setState] = React.useState(true);
  const [range, setRange] = React.useState({});
  const [val, setVal] = React.useState(range.min);
  const [fetchData, setFetchData] = React.useState([]);
  const [flyData, setFlyData] = React.useState({});
  const [data, setData] = React.useState([]);
  const [defaultValue, setDefaultValue] = React.useState(-1);
  const [timer, setTimer] = React.useState("");
  const [time, setTime] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [ntc, setNtc] = useState();
  const [email, setEmail] = useState("");
  const [emailcount, setEmailcount] = useState();
  const [ip, setIp] = useState("");
  const [rocket, setRocket] = useState(false);
  const [approi, setApproi] = useState('');
  const [roiToMap, setRoiToMap] = useState([]);
  const navigate = useNavigate();
  let percentage = 0;

  const OfferEligibilityTest = async () => {

    const payLoad = {
      "source": source,
      "mw_application_id": mw_application_id,
      "product_id": 56,
      "customer_id": credentialState.cust_id,
      "offer_type": 1,
      "level_id": 1,
      "verified_by": verified_by,
      "verifier_name": verifier_name,
      "verification_source": verification_source,
      "is_eligibility_check": credentialState.eligibility ? credentialState.eligibility : 1
    }
    let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-eligibility-3O" : "offer-eligibility-test";

    let decOfferTest = await apiInstance.post(`${url}`, payLoad, {
      "token": ""
    })
    // .then((res) => {
    if ((decOfferTest.status == "1") && (decOfferTest.status_code == "200")) {
      if (decOfferTest?.data?.final_data.length) {
        dispatch(
          setPreApprovedOfferDetails({
            ...preApprovedOfferDetails,
            loan_name: decOfferTest.data.final_data[0].program_name,
            final_data: decOfferTest.data.final_data,
          })
        );
      }
      else if (decOfferTest?.data?.bsa_capture == 1) {
        let qryStr = encryptOnlyLeadId(mw_application_id);
        RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
      }
      else {
        dispatch(updateFormState({ ...formStates, StartJourney: true, AipOffer: false, HunterHold: true }))
      }
    }
    // })
  }

  const Offer = async (obj) => {

    const OfferApi = await WebOffer(obj, token);
    if (OfferApi.status_code === "200" && OfferApi.status === "1") {
      dispatch(
        setPreApprovedOfferDetails({
          ...preApprovedOfferDetails,
          loan_name: OfferApi.data.loan_name,
          final_data: OfferApi.data.final_data,
        })
      );
    }
    else if (OfferApi.data.final_data.length == 0) {
      dispatch(setSorryPopup(true));
    }
    else {
      setResp(true);
      dispatch(updateFormState({ ...formStates, error: true }));
    }
  };

  const CalculateEmi = () => {
    if (defaultValue >= 0) {
      if (timer) {
        clearTimeout(timer);
        setTimer(setTimeout(() => {
          let emiArray = [];
          for (let i = 0; i < data.length; i++) {
            let min_amount = +data[i].min_loan_amt;
            let max_amount = (Math.floor(+data[i].max_loan_amt / 1000) * 1000);
            let selected_amt = +val;
            if ((selected_amt >= min_amount) && (selected_amt <= max_amount)) {
              let r = data[i].applicable_roi / (12 * 100);
              let Emi = (+val * r * Math.pow(1 + r, data[i].tenure)) / (Math.pow(1 + r, data[i].tenure) - 1);
              emiArray.push(Math.round(Emi));
            }
            else {
              emiArray.push("--");
            }
          }
          setEmiArr(emiArray)
        }, 500))
      }
      else {
        setTimer(setTimeout(() => {
          let emiArray = [];
          for (let i = 0; i < data.length; i++) {
            let min_amount = +data[i].min_loan_amt;
            let max_amount = (Math.floor(+data[i].max_loan_amt / 1000) * 1000);
            let selected_amt = +val;
            if ((selected_amt >= min_amount) && (selected_amt <= max_amount)) {
              let r = data[i].applicable_roi / (12 * 100);
              let Emi = (+val * r * Math.pow(1 + r, data[i].tenure)) / (Math.pow(1 + r, data[i].tenure) - 1);
              emiArray.push(Math.round(Emi));
            }
            else {
              emiArray.push("--");
            }
          }
          setEmiArr(emiArray)
        }, 500))
      }
    }
    else {
      let emiArray = [];
      for (let i = 0; i < data.length; i++) {
        let r = data[i].applicable_roi / (12 * 100);
        let Emi = (+val * r * Math.pow(1 + r, data[i].tenure)) / (Math.pow(1 + r, data[i].tenure) - 1);
        emiArray.push(Math.round(Emi));
      }
      setEmiArr(emiArray)
    }
  }

  const CalculateAnotherEmi = () => {
    if (defaultValue >= 0) {
      if (timer) {
        clearTimeout(timer);
        setTimer(setTimeout(() => {
          let emiArray = [];
          let roi_arr = [];
          for (let i = 0; i < data.length; i++) {
            let min_amount = +data[i].min_loan_amt;
            // let r = data[i].applicable_roi / (12 * 100);
            let r;

            if (data[i].amuntbasedtenroi.length > 0) {
              for (let j = 0; j < data[i].amuntbasedtenroi.length; j++) {
                if (((val >= +data[i].amuntbasedtenroi[j].min_loan_amount) && (val <= +data[i].amuntbasedtenroi[j].max_loan_amount))) {
                  r = +data[i].amuntbasedtenroi[j].approi / (12 * 100);
                  roi_arr.push(r);
                  // setApproi(r*12*100)
                  // break;
                }
                if (((val >= +data[i].amuntbasedtenroi[j].min_loan_amount) && (val <= +data[i].amuntbasedtenroi[j].max_loan_amount)) && (flyData.tenure === data[i].tenure)) {
                  setApproi(r * 12 * 100)
                  roi_arr[roi_arr.length - 1] = r;
                  // break;
                }
              }

            }
            else {
              r = +data[i].applicable_roi / (12 * 100);
              setApproi(r * 12 * 100)
            }
            // setApproi(r*12*100)
            console.log(r)
            let max_amount = (Math.floor(+data[i].max_loan_amt / 1000) * 1000);
            let selected_amt = +val;
            if ((selected_amt >= min_amount) && (selected_amt <= max_amount)) {
              // let r = data[i].applicable_roi / (12 * 100);
              let Emi = (+val * r * Math.pow(1 + r, data[i].tenure)) / (Math.pow(1 + r, data[i].tenure) - 1);
              emiArray.push(Math.round(Emi));
            }
            else {
              emiArray.push("--");
            }
          }
          setRoiToMap(roi_arr);
          setEmiArr(emiArray)
        }, 500))
      }
      else {
        setTimer(setTimeout(() => {
          let emiArray = [];
          let roi_arr = [];
          for (let i = 0; i < data.length; i++) {
            let min_amount = +data[i].min_loan_amt;
            // let r = data[i].applicable_roi / (12 * 100);
            let r;
            if (data[i].amuntbasedtenroi.length > 0) {
              for (let j = 0; j < data[i].amuntbasedtenroi.length; j++) {
                if (((val >= +data[i].amuntbasedtenroi[j].min_loan_amount) && (val <= +data[i].amuntbasedtenroi[j].max_loan_amount))) {
                  r = +data[i].amuntbasedtenroi[j].approi / (12 * 100);
                  roi_arr.push(r);
                  // setApproi(r*12*100)
                  // break;
                }
                if (((val >= +data[i].amuntbasedtenroi[j].min_loan_amount) && (val <= +data[i].amuntbasedtenroi[j].max_loan_amount)) && (flyData.tenure === data[i].tenure)) {
                  setApproi(r * 12 * 100)
                  roi_arr[roi_arr.length - 1] = r;
                  // break;
                }
              }
            }
            else {
              r = +data[i].applicable_roi / (12 * 100);
              setApproi(r * 12 * 100)
            }
            // setApproi(r*12*100)
            // console.log(r)
            let max_amount = (Math.floor(+data[i].max_loan_amt / 1000) * 1000);
            let selected_amt = +val;
            if ((selected_amt >= min_amount) && (selected_amt <= max_amount)) {
              // let r = data[i].applicable_roi / (12 * 100);
              let Emi = (+val * r * Math.pow(1 + r, data[i].tenure)) / (Math.pow(1 + r, data[i].tenure) - 1);
              emiArray.push(Math.round(Emi));
            }
            else {
              emiArray.push("--");
            }
          }
          setRoiToMap(roi_arr);
          setEmiArr(emiArray)
        }, 500))
      }
    }
    else {
      let emiArray = [];
      for (let i = 0; i < data.length; i++) {
        let r = data[i].applicable_roi / (12 * 100);
        let Emi = (+val * r * Math.pow(1 + r, data[i].tenure)) / (Math.pow(1 + r, data[i].tenure) - 1);
        emiArray.push(Math.round(Emi));
      }
      setEmiArr(emiArray)

    }
  }

  const NewEmiFunction = () => {
    let emiArray = []; let roi_arr = [];
    for (let i = 0; i < data.length; i++) {
      let min_amount = +data[i].min_loan_amt;
      // let r = data[i].applicable_roi / (12 * 100);
      let r;
      if (data[i].amuntbasedtenroi.length > 0) {
        for (let j = 0; j < data[i].amuntbasedtenroi.length; j++) {
          if (((val >= +data[i].amuntbasedtenroi[j].min_loan_amount) && (val <= +data[i].amuntbasedtenroi[j].max_loan_amount))) {
            r = +data[i].amuntbasedtenroi[j].approi / (12 * 100);
            roi_arr.push(r);
            // setApproi(r*12*100)
            // break;
          }
          if (((val >= +data[i].amuntbasedtenroi[j].min_loan_amount) && (val <= +data[i].amuntbasedtenroi[j].max_loan_amount)) && (flyData.tenure === data[i].tenure)) {
            setApproi(r * 12 * 100)
            roi_arr[roi_arr.length - 1] = r;
            // break;
          }
        }
      }
      else {
        r = +data[i].applicable_roi / (12 * 100);
        setApproi(r * 12 * 100)
      }
      // console.log(r)
      // setApproi(r*12*100)
      let max_amount = (Math.floor(+data[i].max_loan_amt / 1000) * 1000);
      let selected_amt = +val;
      if ((selected_amt >= min_amount) && (selected_amt <= max_amount)) {
        // let r = data[i].applicable_roi / (12 * 100);
        let Emi = (+val * r * Math.pow(1 + r, data[i].tenure)) / (Math.pow(1 + r, data[i].tenure) - 1);
        emiArray.push(Math.round(Emi));
      }
      else {
        emiArray.push("--");
      }
    }
    setRoiToMap(roi_arr);
    setEmiArr(emiArray)
  }

  useEffect(() => {
    if (data.length > 0) {
      // if(decisionDemog.offer_journey_change_flag == 1){
      //   const ele = document.getElementById(data.length - 1);
      //   ele.click();
      // }
      const ele = document.getElementById(data.length - 1);
      ele.click();

    }

  }, [data])

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIp(res.data.IPv4)
  }

  const calculateInitialEmi = () => {
    let emiArray = [];
    for (let i = 0; i < data.length; i++) {
      let r = data[i].applicable_roi / (12 * 100);
      let Emi = (+val * r * Math.pow(1 + r, data[i].tenure)) / (Math.pow(1 + r, data[i].tenure) - 1);
      emiArray.push(Math.round(Emi));
    }
    setEmiArr(emiArray)
  }

  const myToken = async () => {
    const tkn = await getToken();
    dispatch(GenerateToken(tkn));
  }

  React.useEffect(() => {
    if (token == "") {
      myToken();
    }
    getData();
    dispatch(updateStepBar({ ...step_bar, profile: true, offer: true }));
    const qry = encryptOnlyLeadId(mw_application_id);
    const db_qry = Base64.encode(qry);
    setQryStr(qry);
  }, []);

  const hitFetchCustomer = async () => {
    let decFetch = await apiInstance
      .post(
        `fetch-customer-details`,
        FetchCustomerPayload(mw_application_id, 3),
        {
          token
        }
      )
    // .then((res) => {
    setEmailcount(decFetch.data.email_fraud_count);
    if (decFetch.data.email_id) {
      setEmail(decFetch.data.email_id);
    }
    if (decFetch.data.is_ntc_check) {
      setNtc(decFetch.data.is_ntc_check);
    }
    if (
      decFetch.data.is_ntc_check == 1 ||
      decFetch.data.is_ntc_check == 2
    ) {
      if (((decFetch.data.new_journey_lead_source_flag == 1) || (decFetch.data.new_journey_customer_id_flag == 1))) {
        OfferEligibilityTest();
      }
      else {
        const offerpayload2 = {
          mw_application_id,
          customer_id: credentialState.cust_id,
          is_ntc_check: decFetch.data.is_ntc_check ? decFetch.data.is_ntc_check : "",
          product_id: 56,
          offer_type: 1,
          level_id: 1,
          is_eligibility_check: 1,
        };
        Offer(offerpayload2);
      }


    }
    else {
      if (((decFetch.data.new_journey_lead_source_flag == 1) || (decFetch.data.new_journey_customer_id_flag == 1))) {
        OfferEligibilityTest();
      }
      else {
        const offerpayload1 = {
          mw_application_id,
          customer_id: credentialState.cust_id,
          product_id: 56,
          offer_type: 1,
          level_id: 1,
          is_eligibility_check: 1,
        };
        Offer(offerpayload1);
      }

    }
    // }).catch(() => {
    //   setRocket(true);
    // });
  }

  React.useEffect(() => {
    if ((token !== "") && (preApprovedOfferDetails.final_data.length == 0)) {

      hitFetchCustomer()

    }
  }, [token])

  React.useEffect(() => {
    if (preApprovedOfferDetails.final_data.length !== 0) {
      let prog_id = preApprovedOfferDetails.final_data[0].program_id;
      var progval = 'prog_' + prog_id;
      setFetchData(preApprovedOfferDetails.final_data[0][progval])
      setRange({
        ...range,
        min: +preApprovedOfferDetails.final_data[0].min_loan_amt,
        max: ((Math.floor(+preApprovedOfferDetails.final_data[0].max_loan_amt / 1000)) * 1000),
      });
    }
  }, [preApprovedOfferDetails]);

  React.useEffect(() => {


    if (preApprovedOfferDetails.final_data.length !== 0) {
      if (defaultValue === -1) {
        setVal(range.max);
      }

      setState(false);
    }
  }, [range]);

  React.useEffect(() => {
    if (!state) {
      document.querySelector(
        ".aip-offer-body-input input"
      ).style.background = `linear-gradient(to right, #50299c, #7a00ff 100%, #d3edff 100%, #dee1e2 100%`;
    }
  }, [state]);

  React.useEffect(() => {
    let arr = [];
    for (let i = 0; i < fetchData.length; i++) {
      let obj = fetchData[i];
      let r = +fetchData[i].applicable_roi / (12 * 100);
      let Emi =
        ((Math.floor(fetchData[i].max_loan_amt / 1000) * 1000) * r * Math.pow(1 + r, fetchData[i].tenure)) /
        (Math.pow(1 + r, fetchData[i].tenure) - 1);
      obj = { ...obj, emi: Math.round(Emi) };
      arr.push(obj);
      setFlyData(obj);
    }
    if (arr.length) {
      setData(arr);
    }
  }, [fetchData]);

  React.useEffect(() => {
    CalculateEmi();
  }, [data])

  React.useEffect(() => {
    if (!state) {
      if (time) {
        clearTimeout(time);
        setTime(setTimeout(() => {
          // if(decisionDemog.offer_journey_change_flag == 1){
          //   CalculateAnotherEmi();
          // }
          // else{
          //   CalculateEmi();
          // }
          CalculateAnotherEmi();
        }, 500))
      }
      else {
        // Current Changes Here ---------------------------------------------

        setTime(setTimeout(() => {
          // if(decisionDemog.offer_journey_change_flag == 1){
          //   CalculateAnotherEmi();
          // }
          // else{
          //   CalculateEmi();
          // }
          CalculateAnotherEmi();
        }, 500))
      }
    }
  }, [val])


  const handleClick = async () => {
    if (defaultValue > 0) {
      setResp(true);
    }
    setDefaultValue(-1);

    const payLoad = {
      lead_id: mw_application_id,
      customer_id: credentialState.cust_id,
      selected_loan_amount: +val,
      selected_tenure: flyData.tenure,
      emi_calculated_applicable_roi: emi_arr[defaultValue] ? emi_arr[defaultValue].toString() : "",
      // applicable_roi: decisionDemog.offer_journey_change_flag == 1 ? approi.toString() : flyData.applicable_roi,
      applicable_roi: approi.toString(),
      selected_program: preApprovedOfferDetails.final_data[0].program_id
    };
    let url = decisionDemog.offer_journey_change_flag == 1 ? "offer-submission-3O" : "offer-submission";
    const ApiData = await offerSubmissionApi(payLoad, token, url);
    let email_fraud_eligibility = 0;
    if (ApiData.status_code === "200" && ApiData.status === "1") {
      gettingerror('selected roi web', mw_application_id)
      if (((decisionDemog.new_journey_lead_source_flag == 1) || (decisionDemog.new_journey_customer_id_flag == 1))) {
        if(ApiData?.data?.thankyou_flag == 1){
          let qry = encryptOnlyLeadId(mw_application_id);
          window.location.href = `${react_base_url}thank-you/?fid=${qry}&__temp_id=16`
        }
        else if (ApiData?.data?.banking_req == 1) {
          let qryStr = encryptOnlyLeadId(mw_application_id);
          // RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
          window.location.href = `${react_base_url}analyzer/?fid=${qryStr}`
        }
        else {
          let qry = encryptLeadIdStepId(mw_application_id, 4);
          RelocateUTMCheck(`${react_base_url}personal-loan/?fid=${qry}`);
        }
      }
      else {
        if (emailcount === 0) {
          let decEmailFraud = await apiInstance.post(`email-fraud`, {
            "customer_id": credentialState.cust_id,
            verification_source,
            verified_by,
            "lead_id": mw_application_id,
            verifier_name,
            "level_id": 1,
            "partner_id": 1,
            "product_id": 0,
            source,
            "email": email ? email : "",
            "consent": "Y",
            "ip_address": ip,
            "first_name": "",
            "last_name": ""
          }, {
            "token": token,
            "log": 1
          })
          // .then((res) => {
          if ((decEmailFraud.status === "1") && (decEmailFraud.status_code === "200")) {
            email_fraud_eligibility = 1;
          }
          // })
        }
        else {
          email_fraud_eligibility = 1;
        }
        setTimeout(async () => {
          if (email_fraud_eligibility === 1) {
            let decOffer = await apiInstance.post(`offer-eligibility`, {
              "customer_id": credentialState.cust_id,
              "mw_application_id": mw_application_id,
              "offer_type": 1,
              verifier_name,
              verification_source,
              verified_by,
              "product_id": 56,
              "stage_name": "Email Fraud",
              "program_id": preApprovedOfferDetails.final_data[0].program_id,
              "level_id": 1,
              source
            }, {
              "token": token,
              "log": 1
            })
            // .then((res) => {
            if (decOffer.status_code) {
              let checkCondition = Check_utm_source()
              if (checkCondition && checkCondition.length != 0) {
                window.sessionStorage.setItem("cust_id", credentialState.cust_id);
                window.location.href = `${react_base_url}analyzer/?fid=${qryStr}${checkCondition}`;
              } else {
                window.sessionStorage.setItem("cust_id", credentialState.cust_id);
                RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
              }
            }
            else {
              setRocket(true);
            }
            // }).catch((err) => {
            //   setRocket(true);
            // })
          }
          else {
            let checkCondition = Check_utm_source()
            if (checkCondition && checkCondition.length != 0) {
              window.sessionStorage.setItem("cust_id", credentialState.cust_id);
              window.location.href = `${react_base_url}analyzer/?fid=${qryStr}${checkCondition}`;
            } else {
              window.sessionStorage.setItem("cust_id", credentialState.cust_id);
              RelocateUTMCheck(`${react_base_url}analyzer/?fid=${qryStr}`);
            }
          }
        }, 3000);
      }

    } else {
      dispatch(updateFormState({ ...formStates, error: true }));
      setResp(false);
      for (let i = 0; i < data.length; i++) {
        document.getElementById(i).checked = false;
      }
    }
  };

  const handleSliderBar = (e) => {
    if (typeof (val) === "number") {
      percentage = ((val - range.min) / (range.max - range.min)) * 100;
      document.querySelector(
        ".aip-offer-body-input input"
      ).style.background = `linear-gradient(to right, #50299c, #7a00ff ${percentage}%, #d3edff ${percentage}%, #dee1e2 100%`;
    } else {
      if (+e.target.value === 0) {
        percentage = 50;
        document.querySelector(
          ".aip-offer-body-input input"
        ).style.background = `linear-gradient(to right, #50299c, #7a00ff ${percentage}%, #d3edff ${percentage}%, #dee1e2 100%`;
      } else {
        percentage = Math.abs(
          ((+e.target.value - range.min) / (range.max - range.min)) * 100
        );
        document.querySelector(
          ".aip-offer-body-input input"
        ).style.background = `linear-gradient(to right, #50299c, #7a00ff ${percentage}%, #d3edff ${percentage}%, #dee1e2 100%`;
      }
    }
  };

  const handleSliderBar1 = (e) => {
    if (+e.target.value < range.min) {
      setVal(range.min);
      percentage = Math.abs(
        ((range.min - range.min) / (range.max - range.min)) * 100
      );
      document.querySelector(
        ".aip-offer-body-input input"
      ).style.background = `linear-gradient(to right, #50299c, #7a00ff ${percentage}%, #d3edff ${percentage}%, #dee1e2 100%`;
    } else if (+e.target.value > range.max) {
      setVal(range.max);
      percentage = Math.abs(
        ((range.max - range.min) / (range.max - range.min)) * 100
      );
      document.querySelector(
        ".aip-offer-body-input input"
      ).style.background = `linear-gradient(to right, #50299c, #7a00ff ${percentage}%, #d3edff ${percentage}%, #dee1e2 100%`;
    } else {
      percentage = Math.abs(
        ((+e.target.value - range.min) / (range.max - range.min)) * 100
      );
      document.querySelector(
        ".aip-offer-body-input input"
      ).style.background = `linear-gradient(to right, #50299c, #7a00ff ${percentage}%, #d3edff ${percentage}%, #dee1e2 100%`;
    }
  };

  const handleChange = (e) => {
    setVal(+e.target.value);
    handleSliderBar(e);
  };

  const handleChange1 = (e) => {
    if (e.target.value <= range.max) {
      handleSliderBar(e);
      setVal(e.target.value);
    }
  };

  const CalculateNewEmi = () => {
    let emiArray = [];
    for (let i = 0; i < data.length; i++) {
      let min_amount = +data[i].min_loan_amt;
      let max_amount = (Math.floor(+data[i].max_loan_amt / 1000) * 1000);
      let selected_amt = +val;
      if ((selected_amt >= min_amount) && (selected_amt <= max_amount)) {
        let r = data[i].applicable_roi / (12 * 100);
        let Emi = (+val * r * Math.pow(1 + r, data[i].tenure)) / (Math.pow(1 + r, data[i].tenure) - 1);
        emiArray.push(Math.round(Emi));
      }
      else {
        emiArray.push("--");
      }
    }
    setEmiArr(emiArray)
  }

  const handleChange2 = (e, obj, idx) => {
    setDefaultValue(idx);
    setRange({ ...range, min: +obj.min_loan_amt, max: ((Math.floor(+obj.max_loan_amt / 1000)) * 1000) });
    // if((+val >= +obj.min_loan_amt) || (+val <= +obj.max_loan_amt)) {
    if ((+val < +obj.min_loan_amt) || (+val > (Math.floor(+obj.max_loan_amt / 1000) * 1000))) {
      setVal((Math.floor(+obj.max_loan_amt / 1000) * 1000));
      percentage = 100;
      document.querySelector(
        ".aip-offer-body-input input"
      ).style.background = `linear-gradient(to right, #50299c, #7a00ff ${percentage}%, #d3edff ${percentage}%, #dee1e2 100%`;
    }
    else if (typeof (val) === "number") {
      percentage = ((val - (+obj.min_loan_amt)) / (((Math.floor(+obj.max_loan_amt / 1000) * 1000)) - (+obj.min_loan_amt))) * 100;
      document.querySelector(
        ".aip-offer-body-input input"
      ).style.background = `linear-gradient(to right, #50299c, #7a00ff ${percentage}%, #d3edff ${percentage}%, #dee1e2 100%`;
    }

    setFlyData(obj);

    NewEmiFunction();
    // if(decisionDemog.offer_journey_change_flag == 1){
    //   NewEmiFunction();
    // }
    // else{
    //   CalculateNewEmi();
    // }
  };

  const handleFocus = (e) => {
    setVal(val.toString());
    if (defaultValue !== -1) {
      document.getElementById(defaultValue).checked = false;
      setDefaultValue(-1);
    }
  };

  const handleBlur = (e) => {
    let num = +e.target.value;
    num = Math.floor(num / 1000);
    num = num * 1000;
    setVal(num);
    handleSliderBar1(e);
  };

  const handleError = (e) => {
    window.location.reload();
  }

  return (
    <>
      {rocket && <NewErrorCase handleClick={(e) => handleError(e)} />}
      {state ? (
        <CKYCWaiting heading={"We are Generating an Offer For You."} />
      ) : (
        <div>
          <FormHead
            formName="Loan Details"
            formSubHead={"Choose your loan amount & loan tenure"}
          />
          <div className="aip-offer-main">
            <div className="aip-offer-head">
              <p>Approved Loan Amount</p>
              <div style={{ borderBottom: "2px solid #D5D7DD" }}>
                <i
                  className="fa-solid fa-indian-rupee-sign"
                  style={{
                    marginLeft: "40px",
                    fontSize: "22px",
                    marginBottom: "2px",
                    marginRight: "2px",
                  }}
                ></i>
                <input
                  disabled={resp}
                  id="loan-min_loan_amt"
                  type="tel"
                  min={range.min}
                  maxLength={7}
                  max={range.max}
                  onFocus={(e) => handleFocus(e)}
                  onBlur={(e) => handleBlur(e)}
                  value={val && val?.toLocaleString("en-IN")}
                  onChange={(e) => handleChange1(e)}
                />
              </div>
            </div>
            <div className="aip-offer-body">
              <div className="aip-offer-body-input">
                <input
                  disabled={resp}
                  type="range"
                  min={range.min}
                  max={range.max}
                  value={val}
                  step="1000"
                  onClick={(e) => handleChange(e)}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="aip-offer-body-p">
                <p>{"₹ " + range?.min?.toLocaleString("en-IN")}</p>
                <p>{"₹ " + range?.max?.toLocaleString("en-IN")}</p>
              </div>
              <div className="aip-offer-body-tbl-div">
                <table className="aip-offer-body-tbl">
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        Tenure
                        <br />
                        <span className="offer-below-text">(in months)</span>
                      </th>
                      <th>
                        ROI
                        <br />
                        <span className="offer-below-text">(per month)</span>
                      </th>



                      <th>
                        EMI
                        <br />
                        <span className="offer-below-text">(in ₹)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, idx) => (
                      <tr
                        key={idx}
                      // className={
                      //   ((+val >= data[idx].min_loan_amt) && (+val <= data[idx].max_loan_amt)) ? "" : "addedClass"
                      // }
                      >
                        <td>
                          <input
                            disabled={resp}
                            type="radio"
                            name="radio"
                            id={idx}
                            value={{
                              emi: item.emi,
                              min_loan_amt: item.min_loan_amt,
                              tenure: item.tenure,
                              applicable_roi: item.applicable_roi,
                            }}
                            onClick={(e) =>
                              handleChange2(
                                e,
                                {
                                  emi: emi_arr[idx],
                                  min_loan_amt: item.min_loan_amt,
                                  tenure: item.tenure,
                                  applicable_roi: item.applicable_roi,
                                  max_loan_amt: item.max_loan_amt,
                                  // amuntbasedtenroi:decisionDemog.offer_journey_change_flag == 1 ? item.amuntbasedtenroi : ""
                                  amuntbasedtenroi: item.amuntbasedtenroi
                                },
                                idx
                              )
                            }
                          />
                        </td>
                        <td>{item.tenure}</td>
                        {/* <td>{(((+val >= data[idx].min_loan_amt) && (+val <= data[idx].max_loan_amt)) || defaultValue < 0) ? (((item.applicable_roi) / 12).toFixed(2) + "%") : "--"}</td> */}
                        <td>{roiToMap.length > 0 ? (typeof roiToMap[idx] === "number" ? (roiToMap[idx] * 100).toFixed(2) : "--") : (((+val >= data[idx].min_loan_amt) && (+val <= data[idx].max_loan_amt)) || defaultValue < 0) ? (((item.applicable_roi) / 12).toFixed(2) + "%") : "--"}</td>
                        <td>
                          {emi_arr.length &&
                            emi_arr[idx]?.toLocaleString("en-IN")}
                        </td>
                        {/* <td>{item.tenure}</td>
                                    <td>{item.applicable_roi}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="aip-offer-body-tbl-bottom">
                  <div className="aip-offer-body-tbl-bottom-p">
                    {defaultValue >= 0 &&
                      <>
                        <p>
                          Monthly EMI ₹{" "}
                          {defaultValue >= 0 &&
                            emi_arr.length &&
                            emi_arr[defaultValue]?.toLocaleString("en-IN")}
                        </p>
                        <p>
                          Interest Rate{" "}
                          {/* {defaultValue >= 0 &&
                            data[defaultValue].applicable_roi}
                          % */}
                          {defaultValue >= 0 &&
                            (approi ? +approi / 12 : data[defaultValue].applicable_roi / 12).toFixed(2)}
                          % (p.m.)
                        </p>
                      </>
                    }
                  </div>
                  <div className="aip-offer-body-tbl-bottom-div">
                    <div>
                      <input
                        disabled={resp}
                        type="checkbox"
                        checked={(checkBox)}
                        className="pointer-cur checkbox-field"
                        onChange={() => setCheckBox(!checkBox)}
                      />
                    </div>
                    <div>
                      <span className="pointer-cur" onClick={() => setCheckBox(!checkBox)}>
                        I authorise MoneyWide, its lending partners to access my
                        credit report, download C-KYC records, share these with
                        loan application
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              disabled={(defaultValue >= 0) && (!checkBox)}
              onClick={() => handleClick()}
              className={
                "footer-btn " + (((defaultValue !== -1) && (checkBox)) ? " footer-btn-activate" : "")
              }
            >
              {!resp ? (
                "Continue"
              ) : (
                <div className="continue-lottie">
                  <Lottie
                    loop
                    animationData={ctaAnimation}
                    play
                    className="ctn-loader"
                  />
                </div>
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default PreApprovedOffer1;